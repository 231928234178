import { concat } from 'lodash';
import {
  Control,
  Controller,
  FieldValues,
  ValidationRule,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { BalTextField } from './bal-text-field';

export const PhoneInput = ({
  name,
  control,
  value,
  setValue,
  className,
  title,
  required,
}: {
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
  value: string;
  setValue: (value: string) => void;
  className?: string;
  placeholder?: string;
  title?: string;
  required?: string | ValidationRule<boolean>;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name ?? 'phoneNumber'}
      control={control}
      defaultValue={value}
      rules={{
        required: required,
        validate: (value: string) => {
          const rawNumber = value ? value.replaceAll(' ', '') : '';
          return isValidPhoneNumber(rawNumber) || (!required && !rawNumber)
            ? true
            : t('validators.invalidPhoneNumber');
        },
      }}
      render={({ field, fieldState }) => (
        <BalTextField
          {...field}
          className={className ?? 'column is-full'}
          placeholder={
            required === undefined || required !== false
              ? concat([t('general.phone'), '*']).join(' ')
              : t('general.phone')
          }
          title={title ?? t('general.phone')}
          value={value}
          onChange={(e: CustomEvent<string | undefined>) => {
            if (e.detail || e.detail === '') {
              const phoneNumber = e.detail;
              if (
                phoneNumber.charAt(0) == '0' &&
                (phoneNumber.length == 10 || phoneNumber.length == 13)
              ) {
                field.onChange(
                  formatPhoneNumberIntl(`+41${phoneNumber.substring(1)}`),
                );
                setValue(
                  formatPhoneNumberIntl(`+41${phoneNumber.substring(1)}`),
                );
              } else if (isValidPhoneNumber(phoneNumber)) {
                field.onChange(formatPhoneNumberIntl(phoneNumber));
                setValue(formatPhoneNumberIntl(phoneNumber));
              } else {
                field.onChange(phoneNumber);
                setValue(phoneNumber);
              }
            }
          }}
          message={fieldState.error && fieldState.error.message}
        />
      )}
    />
  );
};
