import { ColumnApi, SortChangedEvent, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Dispatch, useState, useEffect } from 'react';
import { InvestigationTask, ISortingState } from '../../../types/types';
import '../../../styles/general.scss';
import {
  getExpenseColDefs,
  getExpenseGridOptions,
} from './expense-search-form-table-grid-options';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../data';
import CreateEditShowExpensesButton from './expenses-buttons/create-edit-show-expense';
import {
  deleteExpense,
  getInvestigationTask,
  patchInvestigationTask,
  putExpense,
} from '../data/requests';
import { ExpenseDto } from '../../../types/resource-models';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../components/toast-notification';
import produce from 'immer';
import { isJournalAndExpansesDisabled } from '../../../utils';

const ExpenseSearchFormTable = ({
  task,
  setInvestigationTask,
}: {
  task: InvestigationTask;
  setInvestigationTask: Dispatch<RequestResult<InvestigationTask>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<ISortingState | undefined>(undefined);

  const getSorting = (columnApi: ColumnApi): ISortingState | undefined => {
    const columns = columnApi.getAllDisplayedColumns();
    const sortingColumn = columns.find((c) => c.getSort());
    if (sortingColumn) {
      const colDef = sortingColumn.getColDef();
      return {
        field: colDef.field ?? '',
        direction: sortingColumn.getSort() ?? 'asc',
      };
    } else {
      return undefined;
    }
  };
  const updateExpense = (
    expense: ExpenseDto,
    created: boolean,
    deleted: boolean,
    token: string,
  ) => {
    if (deleted) {
      deleteExpense(token, task.id, expense.id).then(
        (result: RequestResult<void>) => {
          if (result.status === 'success-no-value') {
            getInvestigationTask(token, task.id).then((response) => {
              if (response.status == 'success') {
                setInvestigationTask(response);
              } else if (response.status === 'error') {
                toast(ErrorToast(response.errorValue));
              }
            });
          } else if (result.status === 'error') {
            toast(ErrorToast(result.errorValue));
          }
        },
      );
    } else {
      if (created) {
        patchInvestigationTask(
          task,
          produce(task, (draftState) => {
            if (created) {
              draftState.expenses = [...draftState.expenses, expense];
            }
          }),
          token,
        ).then((result: RequestResult<InvestigationTask>) => {
          if (result.status === 'success') {
            setInvestigationTask(result);
          } else if (result.status === 'error') {
            toast(ErrorToast(result.errorValue));
          }
        });
      } else {
        // update
        putExpense(token, task.id, expense).then(
          (result: RequestResult<InvestigationTask>) => {
            if (result.status === 'success') {
              setInvestigationTask(result);
            } else if (result.status === 'error') {
              toast(ErrorToast(result.errorValue));
            }
          },
        );
      }
    }
  };

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  useEffect(() => {
    if (gridApi && task) {
      gridApi.setColumnDefs(
        getExpenseColDefs(
          t,
          updateExpense,
          task.id,
          isJournalAndExpansesDisabled(task.status),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, task]);

  return (
    <>
      <div className="is-flex is-flex-direction-column">
        <div className="flex-float-right pr-3">
          <CreateEditShowExpensesButton
            updateExpense={updateExpense}
            create
            disabled={isJournalAndExpansesDisabled(task.status)}
          />
        </div>
      </div>
      <div className="task-table-detail">
        <AgGridReact
          suppressColumnMoveAnimation={true}
          suppressCellFocus={true}
          rowSelection={'single'}
          rowData={task.expenses}
          rowHeight={40}
          onGridReady={(e) => {
            setGridApi(e.api);
          }}
          gridOptions={getExpenseGridOptions(
            t,
            task.id,
            updateExpense,
            isJournalAndExpansesDisabled(task.status),
          )}
          onSortChanged={(event: SortChangedEvent) => {
            if (sorting) {
              setSorting(undefined);
              return;
            }
            // get order column and direction.
            if (event.columnApi) {
              const currentSorting = getSorting(event.columnApi);
              setSorting(currentSorting);
              // Avoid unfocus header problems in aggrid when changing filter.
              const focused = document.activeElement;
              if (focused) {
                (focused as HTMLInputElement).blur();
              }
            }
          }}
        />
      </div>
    </>
  );
};

export default ExpenseSearchFormTable;
