import {
  BalButton,
  BalFileUpload,
} from '@baloise/design-system-components-react';
import React, { useCallback, useEffect, useState } from 'react';
import { RequestResult } from '../../../data';
import { AttachmenEntity, AttachmentEntityType } from '../../../types/types';
import { formatDateTime } from '../../../utils/date';
import { useToken } from '../../../hooks';
import { ErrorToast } from '../../../components/toast-notification';
import { toast } from 'react-toastify';
import { concat, convertBytesToMB } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ToastNotification } from '../../../components/toast-notification';
import produce from 'immer';
import { Icon } from '../../../components/ui';
import iconPasteFromClipboard from '../../../assets/icons/icon-clipboard.svg';
import { balModalController } from '../../../controller/controllers';
import { deleteFile, postFile, RemoveFileConfirmationModal } from '..';
import { AttachmentDto } from '../../../types/resource-models';

type FileUploadProps<T extends AttachmenEntity> = {
  className?: string;
  entity: RequestResult<T>;
  entityType: AttachmentEntityType;
  setEntity: React.Dispatch<RequestResult<T>>;
  copyFromClipboard?: boolean;
  hasFileList?: boolean;
  maximumTotalMB?: number;
  disabled?: boolean;
  beforePostFile?: () => Promise<void>;
};

export const FileUpload = <T extends AttachmenEntity>({
  className,
  entity,
  entityType,
  setEntity,
  copyFromClipboard,
  hasFileList,
  maximumTotalMB,
  disabled,
  beforePostFile,
}: FileUploadProps<T>): JSX.Element => {
  const getFilesFromEntity = useCallback((entity: RequestResult<T>) => {
    if (entity.status !== 'success') return [];
    return entity.localValue.attachments.map(
      (attachment) =>
        new File([new Uint8Array(attachment.size)], `${attachment.fileName}`, {
          type: attachment.contentType,
          lastModified: Date.parse(attachment.savedOn),
        }),
    );
  }, []);

  const [previousFiles, setPreviousFiles] = useState<File[]>(
    getFilesFromEntity(entity),
  );

  useEffect(() => {
    if (entity.status === 'success') {
      setPreviousFiles(getFilesFromEntity(entity));
      let currentSize = 0;
      entity.localValue.attachments.forEach((attachment: AttachmentDto) => {
        currentSize += attachment.size;
      });

      setFilesCurrentSize(convertBytesToMB(currentSize));
    }
  }, [entity, getFilesFromEntity]);

  const [loading, setLoading] = useState<boolean>(false);
  const [maximumTotalSize] = useState<number>(maximumTotalMB ?? 20); // Max total size 20MB by default;
  const maximumfilesTotalNumber = 50;
  const [filesCurrentSize, setFilesCurrentSize] = useState<number>(0);

  const token = useToken();
  const { t } = useTranslation();

  const allowedFileTypes = [
    'image/jpeg',
    'image/png',
    'image/bmp',
    'image/gif',
    'application/pdf',
  ];

  const openModal = async (
    fileName: string,
    removeFile: (fileName: string) => Promise<void>,
  ) => {
    const modal = await balModalController.create({
      component: RemoveFileConfirmationModal,
      componentProps: { fileName, removeFile },
      cssClass: 'center-modal',
    });
    return await modal.present();
  };

  // Modal callback.
  const removeFile = async (fileName: string): Promise<void> => {
    if (entity.status === 'success') {
      const attachmentToDelete = entity.localValue.attachments.find((file) => {
        return file.fileName === fileName;
      });

      if (attachmentToDelete) {
        const result = await deleteFile(token, attachmentToDelete);

        if (
          result.status === 'success-no-value' ||
          result.status === 'success'
        ) {
          setEntity(
            produce(entity, (draft) => {
              draft.localValue.attachments =
                entity.localValue.attachments.filter(
                  (a) => a.id !== attachmentToDelete.id,
                );
            }),
          );
        }

        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      }
    }
  };

  const onRemovedFile = async (uploadFiles: File[]) => {
    if (entity.status === 'success') {
      let removedFile: File | undefined = undefined as File | undefined;
      previousFiles.every((file: File) => {
        const index = uploadFiles.findIndex((f: File) => f.name === file.name);
        if (index === -1) {
          removedFile = file;
          return false;
        }
        return true;
      });

      if (removedFile) {
        uploadFiles.length = 0;
        previousFiles.forEach((file) => uploadFiles.push(file));
        await openModal(removedFile?.name, removeFile); // Confirmation request.
      }
    }
  };

  const fixDuplicatedFileNames = (newFile: File) => {
    const fileNameToUpload = newFile.name;
    const fileNameExtension = fileNameToUpload.split('.').pop();
    const fileNameOriginal = fileNameToUpload.substring(
      0,
      fileNameToUpload.lastIndexOf('.'),
    );
    let fileNameToUploadFixed = `${fileNameToUpload}`;

    //add suffix if file with same name already stored
    for (
      let count = 1;
      previousFiles.some(
        (x) => x.name === fileNameToUploadFixed && count < 100,
      );
      count++
    ) {
      fileNameToUploadFixed = `${fileNameOriginal}_${count}.${fileNameExtension}`;
    }
    if (fileNameToUploadFixed != `${fileNameOriginal}.${fileNameExtension}`) {
      const fileBefore = newFile;

      return new File([fileBefore], fileNameToUploadFixed, {
        type: fileBefore.type,
      });
    }
    return newFile;
  };

  const checkIfIsOverTotalSizeThreshold = (file: File): boolean => {
    const invalid =
      (file ? convertBytesToMB(file.size) : 0) + filesCurrentSize >
      maximumTotalSize;

    if (invalid) {
      toast(
        ToastNotification({
          message: t('general.attachmentsSizeExcededError'),
          color: 'warning',
        }),
      );
    }

    return invalid;
  };

  const checkIfIsOverTotalFilesNumberThreshold = (
    currentFilesLength: number,
  ): boolean => {
    const invalid = currentFilesLength > maximumfilesTotalNumber;

    if (invalid) {
      toast(
        ToastNotification({
          message: t('general.attachmentsFilestTotalNumberExceededError'),
          color: 'warning',
        }),
      );
    }

    return invalid;
  };

  return (
    <>
      {entity.status === 'success' && (
        <div className="columns is-multiline">
          <BalFileUpload
            disabled={disabled}
            loading={loading}
            hasFileList={!loading && hasFileList}
            onBalRejectedFile={(FileUploadRejectedFile) => {
              if (
                FileUploadRejectedFile.detail.reasons[0] != 'DUPLICATED_FILE'
              ) {
                toast(
                  ToastNotification({
                    message: t('error.fileTypeError'),
                    color: 'danger',
                  }),
                );
              }
            }}
            accept={allowedFileTypes.join()}
            className={concat(['column is-full', className])}
            onBalChange={async (event: CustomEvent<File[]>) => {
              if (entity.status === 'success') {
                if (previousFiles.length <= event.detail.length) {
                  // Adding files
                  if (
                    checkIfIsOverTotalSizeThreshold(
                      event.detail[event.detail.length - 1],
                    ) ||
                    checkIfIsOverTotalFilesNumberThreshold(event.detail.length)
                  ) {
                    event.detail.splice(-1);
                    return;
                  }
                  const fixedFile = fixDuplicatedFileNames(
                    event.detail[event.detail.length - 1],
                  );

                  // If it's required adding twice the same filename from the same filepath.
                  event.detail.pop();

                  setLoading(true);
                  if (beforePostFile) {
                    await beforePostFile();
                  }
                  const result = await postFile(
                    token,
                    fixedFile,
                    entity.localValue.id,
                    entityType,
                  );

                  setLoading(false);
                  if (result.status === 'success') {
                    setEntity(
                      produce(entity, (draft) => {
                        draft.localValue.attachments.push(result.value);
                      }),
                    );
                  } else if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                    event.detail.splice(-1);
                  }
                } else {
                  // Removing files.
                  onRemovedFile(event.detail);
                }
              }
            }}
            label={`${t(
              'general.uploadFiles',
            )} ${filesCurrentSize}/${maximumTotalSize}MB`}
            value={getFilesFromEntity(entity)}
            multiple={false}
            subTitle={(file: File) =>
              concat([
                file.type
                  .substring(file.type.lastIndexOf('/') + 1)
                  .toUpperCase(),
                `(${convertBytesToMB(file.size)} MB)`,
                concat(
                  [
                    '(',
                    formatDateTime(new Date(file.lastModified).toISOString()),
                    ')',
                  ],
                  'none',
                ),
              ])
            }
          />
          {copyFromClipboard && (
            <BalButton
              disabled={disabled}
              className="column is-narrow"
              elementType="button"
              size="small"
              color="info"
              outlined
              onClick={async (event) => {
                if (entity.status === 'success' && event.detail == 1) {
                  try {
                    const clipboardItems = await navigator.clipboard.read();
                    if (beforePostFile && clipboardItems.length > 0) {
                      await beforePostFile();
                    }
                    if (
                      checkIfIsOverTotalFilesNumberThreshold(
                        entity.localValue.attachments.length +
                          clipboardItems.length,
                      )
                    ) {
                      return;
                    }
                    for (const clipboardItem of clipboardItems) {
                      for (const itemType of clipboardItem.types) {
                        if (
                          allowedFileTypes.some((element) =>
                            itemType.includes(element),
                          )
                        ) {
                          const blob = await clipboardItem.getType(itemType);
                          const file = new File(
                            [blob],
                            `${DateTime.utc().toString()}.${
                              blob.type.split('/').slice(-1)[0]
                            }`,
                            {
                              type: blob.type,
                              lastModified: Date.parse(
                                DateTime.utc().toString(),
                              ),
                            },
                          );
                          if (checkIfIsOverTotalSizeThreshold(file)) {
                            return;
                          }
                          setLoading(true);
                          const result = await postFile(
                            token,
                            file,
                            entity.localValue.id,
                            entityType,
                          );
                          setLoading(false);
                          if (result.status === 'success') {
                            setEntity(
                              produce(entity, (draftState) => {
                                draftState.localValue.attachments.push(
                                  result.value,
                                );
                              }),
                            );
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        } else {
                          throw new Error('Invalid file type.');
                        }
                      }
                    }
                  } catch (err) {
                    toast(
                      ToastNotification({
                        message: t('error.clipboardError'),
                        color: 'danger',
                      }),
                    );
                  }
                }
              }}
            >
              <div className="is-flex is-align-items-center is-justify-content-center">
                <div className="mr-2">
                  <Icon iconPath={iconPasteFromClipboard} />
                </div>
                {t('general.pasteFromClipboard')}
              </div>
            </BalButton>
          )}
        </div>
      )}
    </>
  );
};
