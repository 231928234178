import { BalTabItem, BalTabs } from '@baloise/design-system-components-react';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVehicleTask } from '../features/vehicle-task';
import { getDefaultCompanyDto, Utilities } from '../utils/utilities';
import { APIRoutes } from '../router/router';
import { useToken, useCallbackPrompt } from '../hooks';
import { toast } from 'react-toastify';
import { ErrorToast } from '../components/toast-notification';
import { WithCapabilities } from '../components/authorization';
import { BlockNavigation } from '../components/navigation';
import { TaskWizardButtons } from '../components/ui';
import {
  getVehicleTask,
  patchVehicleTask,
  VehicleTaskClaimDamageForm,
  VehicleTaskOverlay,
} from '../features/vehicle-task';
import {
  ClaimNumberInputCard,
  TaskInformationCards,
} from '../features/base-task';
import produce from 'immer';
import { FetchSuccess, RequestResult } from '../data/fetch/result';
import { TabType, TaskTypes, VehicleTask } from '../types/types';
import { getTask, postVehicleTask } from '../features/base-task/data/requests';
import { apiUrl } from '../data/fetch/base';
import { BaseTaskListDto, CompanyDto } from '../types/resource-models';
import { validateAffectedPartnerVehicle } from '../features/services';
import { VehicleTaskCompleteTaskCards } from '../features/vehicle-task/components/vehicle-task-complete-task-cards';
import { useSearchParams } from 'react-router-dom';
import { VehicleTaskWizardContactsCards } from '../features/vehicle-task/components/vehicle-task-wizard-contacts-cards';
import { useForm } from 'react-hook-form';
import { balModalController } from '../controller/controllers';
import { BaseTaskManualCreationModalForm } from '../features/base-task/components/base-task-manual-creation-modal-form';
import { RequestError } from '../data';
import { BadRequestError } from '../data/fetch/errors';

const VehicleBonusCorrectionTaskWizardView = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const taskIdQueryParam = searchParams.get('taskId');
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabVehicleClaimNumber = useRef<HTMLBalTabItemElement>(null);
  const refTabContactInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabCompleteVehicleClaim = useRef<HTMLBalTabItemElement>(null);

  const [vehicleTask, setVehicleTask] = useVehicleTask();
  const [tab, setTab] = useState<TabType>('vehicleClaimNumber');
  const [selectedPartner] = useState<CompanyDto>(getDefaultCompanyDto());
  const [selectedCraftsmanId] = useState<string | undefined>(undefined);
  const vehicleClaimInformationMethods = useForm({ mode: 'onChange' });

  const token = useToken();
  const patchTask = (
    tab: TabType,
    refNextTab: RefObject<HTMLBalTabItemElement>,
  ) => {
    setVehicleTask({ status: 'loading' });
    if (vehicleTask.status === 'success') {
      patchVehicleTask(vehicleTask.value, vehicleTask.localValue, token).then(
        (inspectionTaskResponse) => {
          setVehicleTask(inspectionTaskResponse);
          if (inspectionTaskResponse.status === 'success') {
            refNextTab.current
              ?.getOptions()
              .then((options) => refTabs.current?.select(options));
            setTab(tab);
          } else if (inspectionTaskResponse.status === 'error') {
            toast(ErrorToast(inspectionTaskResponse.errorValue));
          }
        },
      );
    }
  };

  const onCreateTaskSuccess = (response: FetchSuccess<VehicleTask>) => {
    setVehicleTask(response);
    if (refTabContactInformation) {
      refTabContactInformation.current
        ?.getOptions()
        .then((options) => refTabs.current?.select(options));
    }
    setTab('contactInformation');
  };

  const onCreateTaskCancel = () => {
    setVehicleTask({
      status: 'initial',
    });
    setTab('vehicleClaimNumber');
  };

  const openManualCreationModal = async (claimNumber: string) => {
    const modal = await balModalController.create({
      component: BaseTaskManualCreationModalForm,
      modalWidth: 750,
      componentProps: {
        claimNumber,
        taskType: TaskTypes.VehicleTask,
        garage: selectedPartner,
        kind: 'VehicleBonusCorrection',
        onCreateTaskSuccess,
        onCancel: onCreateTaskCancel,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  const createTask = async (claimNumber: string) => {
    setVehicleTask({ status: 'loading' });
    const taskResponse = await postVehicleTask(
      {
        claimNumber: claimNumber,
        kind: 'VehicleBonusCorrection',
        garage: selectedPartner,
        assigneeGarageCraftsmanId: selectedCraftsmanId,
      },
      token,
      `${apiUrl}${APIRoutes.POST_VEHICLE_TASK.create({})}`,
    );

    if (taskResponse.status === 'success') {
      setVehicleTask(taskResponse);
      setTab('contactInformation');
    } else if (taskResponse.status === 'error') {
      if (
        (taskResponse.errorValue as RequestError).errorType ===
          'bad-request-error' &&
        (taskResponse.errorValue as BadRequestError).errorKey ===
          'CloneClaimClosedError'
      ) {
        toast(ErrorToast(taskResponse.errorValue));
        onCreateTaskCancel();
      } else {
        openManualCreationModal(claimNumber);
      }
    }
  };

  const onEditTask = async (baseTaskid: string) => {
    setVehicleTask({ status: 'loading' });
    const taskResponse = await getTask<VehicleTask>(
      token,
      baseTaskid,
      TaskTypes.VehicleTask,
    );
    setVehicleTask(taskResponse);
  };

  const createOrFetchTaskCallback = useCallback(
    (taskId: string) => {
      if (token) {
        setVehicleTask({ status: 'loading' });
        getTask<VehicleTask>(token, taskId, TaskTypes.VehicleTask).then(
          (taskResponse) => {
            if (taskResponse.status === 'success') {
              setVehicleTask(taskResponse);
              refTabContactInformation.current
                ?.getOptions()
                .then((options) => refTabs.current?.select(options));
              setTab('contactInformation');
            } else if (taskResponse.status === 'error') {
              toast(ErrorToast(taskResponse.errorValue));
              setVehicleTask({ status: 'initial' });
            }
          },
        );
      }
    },
    [setVehicleTask, token],
  );

  useEffect(() => {
    if (taskIdQueryParam) {
      createOrFetchTaskCallback(taskIdQueryParam);
    }
  }, [taskIdQueryParam, createOrFetchTaskCallback, setVehicleTask]);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    tab !== 'vehicleClaimNumber' && tab !== 'none',
  );
  const policyHolderId =
    vehicleTask.status === 'success' &&
    vehicleTask.localValue.claim.policyHolder
      ? vehicleTask.localValue.claim.policyHolder.type === 'person'
        ? vehicleTask.localValue.claim.policyHolder.person.id
        : vehicleTask.localValue.claim.policyHolder.company.id
      : undefined;

  const policyHolder =
    vehicleTask.status === 'success'
      ? vehicleTask.localValue.claim.affectedPartners.find((p) => {
          return p.partner.type === 'person'
            ? p.partner.person.id === policyHolderId
            : p.partner.company.id === policyHolderId;
        })
      : undefined;

  return (
    <WithCapabilities
      requiredCapabilities={['CreateVehicleTask']}
      showErrorToast
    >
      {vehicleTask.status === 'success' ? (
        <>
          <VehicleTaskOverlay
            task={vehicleTask.value as unknown as BaseTaskListDto}
            tab={tab}
            getFullTask={getVehicleTask}
            hideLinkButtons
          />
          <BlockNavigation
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            taskType={TaskTypes.VehicleTask}
            taskId={vehicleTask.value.id}
          />
        </>
      ) : (
        <BlockNavigation
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
      )}
      <div className="columns wizard is-gapless is-full-height is-full-width scroll-bar-minimal-width-adjust">
        <div className="column is-11 is-flex wizard-max-width-1280px mr-3">
          <BalTabs
            interface="o-steps"
            ref={refTabs}
            value={tab}
            clickable={false}
          >
            <BalTabItem
              value="vehicleClaimNumber"
              label={t('vehicleTask.vehicleBonusCorrection.tabTitle')}
              ref={refTabVehicleClaimNumber}
            >
              <ClaimNumberInputCard
                task={vehicleTask}
                setTask={setVehicleTask}
                refTabs={refTabs}
                refNextTab={refTabContactInformation}
                setTab={() => setTab('contactInformation')}
                title={t('vehicleTask.vehicleBonusCorrection.cardTitle')}
                text={t('vehicleTask.vehicleBonusCorrection.cardText')}
                pattern={/^[1345679]\d\d{8}[[0-9X]{1}$/}
                taskButtons={
                  <TaskWizardButtons refTabs={refTabs} hasBack={false} />
                }
                isVehicleTask
                isBonusCorrection
                placeholder={Utilities.maskClaimNumber}
                onSearchAction={async (claimNumber: string) => {
                  await createTask(claimNumber);
                }}
                onEditTask={onEditTask}
                onCreateNew={async (
                  task?: RequestResult<VehicleTask>,
                  claimNumber?: string,
                ) => {
                  // Create a new task
                  if (claimNumber) {
                    await createTask(claimNumber);
                  }
                  if (task) {
                    if (task.status === 'success') {
                      patchVehicleTask(
                        task.value,
                        produce(task.value, (draftState) => {
                          if (task.value.status === 'New') {
                            draftState.deletedAt = new Date().toISOString();
                          } else {
                            draftState.status = 'Closed';
                          }
                        }),
                        token,
                      ).then((result) => {
                        if (result.status === 'success') {
                          setTab('contactInformation');
                        } else if (result.status === 'error') {
                          toast(ErrorToast(result.errorValue));
                        }
                      });
                    }
                  } else {
                    setTab('contactInformation');
                  }
                }}
                taskType={TaskTypes.VehicleTask}
              />
            </BalTabItem>

            <BalTabItem
              value="contactInformation"
              label={t('vehicleTask.vehicleContact.contact')}
              ref={refTabContactInformation}
            >
              <VehicleTaskWizardContactsCards
                task={vehicleTask}
                setTask={setVehicleTask}
                patchTask={patchVehicleTask}
              />
              <TaskWizardButtons
                refTabs={refTabs}
                hasBack={false}
                onClickNext={() =>
                  patchTask('claimInformation', refTabInformation)
                }
                disabled={
                  policyHolder &&
                  !validateAffectedPartnerVehicle(
                    policyHolder,
                    'VehicleBonusCorrection',
                  )
                }
              />
            </BalTabItem>
            <BalTabItem
              value="claimInformation"
              label={t('vehicleTask.vehicleClaimInformation.tabTitle')}
              ref={refTabInformation}
            >
              <VehicleTaskClaimDamageForm
                vehicleTask={vehicleTask}
                setVehicleTask={setVehicleTask}
                methodsProp={vehicleClaimInformationMethods}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                patchTask={async () => {}}
              />
              <TaskInformationCards
                methodsProp={vehicleClaimInformationMethods}
                taskProps={{
                  task: vehicleTask,
                  setTask: setVehicleTask,
                  type: TaskTypes.VehicleTask,
                }}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabContactInformation}
                    setPrevTab={() => {
                      setTab('contactInformation');
                    }}
                  />
                }
                patchTask={async () => {
                  await patchTask(
                    'completeVehicleClaim',
                    refTabCompleteVehicleClaim,
                  );
                }}
                title={t('vehicleTask.vehicleBonusCorrection.cardTitle')}
              />
            </BalTabItem>
            <BalTabItem
              value="completeVehicleClaim"
              label={t('vehicleTask.completeVehicleClaim.tabTitle')}
              ref={refTabCompleteVehicleClaim}
            >
              <VehicleTaskCompleteTaskCards
                task={vehicleTask}
                setTask={setVehicleTask}
                patchTask={patchVehicleTask}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabInformation}
                    setPrevTab={() => setTab('claimInformation')}
                    nextText={t(
                      'vehicleTask.completeVehicleClaim.submitInspectionButton',
                    )}
                  />
                }
                inspectorAppCapabilitiy={[
                  'EditVehicleTask',
                  'ReadOwnedVehicleTask',
                ]}
                setTab={setTab as Dispatch<SetStateAction<string>>}
              />
            </BalTabItem>
          </BalTabs>
        </div>
      </div>
    </WithCapabilities>
  );
};

export default VehicleBonusCorrectionTaskWizardView;
