import { BalButton, BalText } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';
import {
  InvestigationTaskStatus,
  BaseTaskStatus,
  VehicleTaskStatus,
} from '../../../types/resource-models';
import { concat } from '../../../utils';
import { DisabableButton } from '../../../components/authorization';
import { AssignInspectorConfirmation } from './assign-inspector-confirmation';

type InfoEditOriginExpertProps = {
  title: string;
  text: string;
  className?: string;
  onEdit: () => void;
  onAssignOriginExpert: () => void;
  taskStatus: BaseTaskStatus | InvestigationTaskStatus | VehicleTaskStatus;
};

export const InfoEditOriginExpert = ({
  title,
  text,
  className,
  onEdit,
  onAssignOriginExpert,
  taskStatus,
}: InfoEditOriginExpertProps): JSX.Element => {
  const { t } = useTranslation();
  const showReassingButton =
    text != undefined &&
    text.trim().length > 0 &&
    taskStatus == 'SentToArchive';

  const openModal = async (inspectorName: string) => {
    const modal = await balModalController.create({
      component: AssignInspectorConfirmation,
      componentProps: {
        inspectorName: inspectorName,
        assignInspector: onAssignOriginExpert,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return await modal.present();
  };

  return (
    <div
      className={concat([
        'columns is-gapless bottom-blue-line  is-flex is-justify-content-space-between',
        className,
      ])}
    >
      <BalText bold className={'column is-4 mt-3'} color="info" size="small">
        {title}
      </BalText>
      <BalText className={'column  mb-1 mt-2'}>{text}</BalText>
      {showReassingButton && (
        <DisabableButton
          onClick={(event) => {
            event.detail == 1 && openModal(text);
          }}
          size="small"
          color="info"
          className="mt-1 mr-1"
        >
          {t('general.buttons.reassign')}
        </DisabableButton>
      )}
      <BalButton
        className="is-multiline mt-1 "
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && onEdit()}
      ></BalButton>
    </div>
  );
};
