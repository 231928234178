import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BalTabItem, BalTabs } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { useToken } from '../../../../hooks';
import { WithCapabilities } from '../../../../components/authorization';
import { useParams } from 'react-router-dom';
import {
  CustomRecipient,
  PropertyInspectionTaskRecipientCard,
} from './property-inspection-task-recipient-card';
import {
  AffectedPartner,
  PropertyInspectionTask,
  TaskTypes,
} from '../../../../types/types';
import { getTask } from '../../../base-task/data/requests';
import { PropertyInspectionTaskSummaryCard } from './property-inspection-task-summary-card';
import { PropertyInspectionTaskReviewCard } from './property-inspection-task-review-card';
import {
  InspectionListDto,
  PropertyInspectionTaskRecipientDto,
} from '../../../../types/resource-models';
import { RequestResult } from '../../../../data';
import { usePropertyInspectionTask } from '../../data/hooks';
import { guid } from '../../../../utils';
import { PropertyInspectionTaskInspectionCard } from './property-inspection-task-inspection-card';

type PropertyTaskDetailId = {
  taskId: string;
  inspectionId: string;
};

type WizardTabType =
  | 'inspectionPhotos'
  | 'recipients'
  | 'summary'
  | 'message'
  | 'review';

export interface PropertyInspectionTaskSendingWizardViewContextType {
  recipients: PropertyInspectionTaskRecipientDto[];
  setRecipients?: Dispatch<
    SetStateAction<PropertyInspectionTaskRecipientDto[]>
  >;
  customRecipients: CustomRecipient[];
  setCustomRecipients?: Dispatch<SetStateAction<CustomRecipient[]>>;
  selectedCustomRecipients: CustomRecipient[];
  setSelectedCustomRecipients?: Dispatch<SetStateAction<CustomRecipient[]>>;
  message: string;
  setMessage?: Dispatch<SetStateAction<string>>;
  signature: string;
  setSignature?: Dispatch<SetStateAction<string>>;
  propertyInspectionTask: RequestResult<PropertyInspectionTask>;
  setPropertyInspectionTask?: Dispatch<RequestResult<PropertyInspectionTask>>;
  inspection: InspectionListDto;
  setInspection?: Dispatch<SetStateAction<InspectionListDto>>;
  token: string;
  inspectionId: string;
}

export const PropertyInspectionTaskSendingWizardViewContext =
  createContext<PropertyInspectionTaskSendingWizardViewContextType>({
    recipients: [],
    customRecipients: [],
    selectedCustomRecipients: [],
    message: '',
    signature: '',
    propertyInspectionTask: { status: 'initial' },
    inspection: {
      id: '',
      changedAt: '',
      filesAreUpdated: false,
    },
    token: '',
    inspectionId: '',
  });

const PropertyInspectionTaskSendingWizardView = (): JSX.Element => {
  const { taskId, inspectionId } = useParams<PropertyTaskDetailId>();
  const [inspectionTask, setInspectionTask] = usePropertyInspectionTask();
  const [tab, setTab] = useState<WizardTabType>('inspectionPhotos');
  const { t } = useTranslation();
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabClaimNumber = useRef<HTMLBalTabItemElement>(null);
  const refTabContactInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabClaimInformation = useRef<HTMLBalTabItemElement>(null);
  const token = useToken();

  useEffect(() => {
    if (taskId && token && inspectionTask.status === 'initial') {
      setInspectionTask({ status: 'loading' });
      getTask<PropertyInspectionTask>(
        token,
        taskId,
        TaskTypes.PropertyInspectionTask,
      ).then((taskResponse) => {
        setInspectionTask(taskResponse);
        if (taskResponse.status === 'success') {
          setCustomRecipients(
            getRecipientsFromAffectedPartner(taskResponse.localValue),
          );
          setInspection(
            taskResponse.value.inspections.find(
              (inspection) => inspection.id === inspectionId,
            ) ?? { id: '', changedAt: '', filesAreUpdated: false },
          );
        }
        setTab('inspectionPhotos');
      });
    }
  }, [taskId, inspectionTask, token, setInspectionTask, inspectionId]);

  const [recipients, setRecipients] = useState<
    PropertyInspectionTaskRecipientDto[]
  >([]);

  const getRecipientsFromAffectedPartner = (
    task: PropertyInspectionTask,
  ): CustomRecipient[] => {
    const result: CustomRecipient[] = [];
    task.claim.affectedPartners.map((affectedPartner: AffectedPartner) => {
      const partner =
        affectedPartner.partner.type === 'company'
          ? affectedPartner.partner.company
          : affectedPartner.partner.person;
      if (partner.email) {
        const customRecipient: CustomRecipient = {
          id: guid(),
          email: partner.email,
          name: partner.displayName,
          type: 'TaskContact',
        };
        result.push(customRecipient);
      }
    });
    return result;
  };

  const [customRecipients, setCustomRecipients] = useState<CustomRecipient[]>(
    inspectionTask.status === 'success'
      ? getRecipientsFromAffectedPartner(inspectionTask.localValue)
      : [],
  );

  const [selectedCustomRecipients, setSelectedCustomRecipients] = useState<
    CustomRecipient[]
  >([]);

  const [inspection, setInspection] = useState<InspectionListDto>({
    id: '',
    changedAt: '',
    filesAreUpdated: false,
  });
  const [message, setMessage] = useState<string>('');
  const [signature, setSignature] = useState<string>('');

  return (
    <WithCapabilities
      requiredCapabilities={['CreatePropertyInspectionTask']}
      showErrorToast
    >
      {inspectionTask.status === 'success' && (
        <>
          <div className="columns is-full-height scroll-bar-minimal-fullwidth-adjust">
            <div className="column is-flex mr-3">
              <PropertyInspectionTaskSendingWizardViewContext.Provider
                value={{
                  recipients,
                  setRecipients,
                  customRecipients,
                  setCustomRecipients,
                  selectedCustomRecipients,
                  setSelectedCustomRecipients,
                  message,
                  setMessage,
                  signature,
                  setSignature,
                  propertyInspectionTask: inspectionTask,
                  setPropertyInspectionTask: setInspectionTask,
                  inspection: inspection,
                  setInspection: setInspection,
                  token,
                  inspectionId: inspectionId ?? '',
                }}
              >
                {PropertyInspectionTaskSendingWizardViewContext && (
                  <BalTabs
                    interface="o-steps"
                    ref={refTabs}
                    value={tab}
                    id="task-tabs"
                  >
                    <BalTabItem
                      value="inspectionPhotos"
                      label={t('inspectionTask.photos.entityName')}
                      ref={refTabClaimNumber}
                      disabled={tab && tab !== 'inspectionPhotos'}
                      done={tab && tab !== 'inspectionPhotos'}
                    >
                      <PropertyInspectionTaskInspectionCard
                        onNext={() => {
                          setTab('recipients');
                        }}
                      />
                    </BalTabItem>
                    <BalTabItem
                      value="recipients"
                      label={t('inspectionTask.recipient.entityName')}
                      ref={refTabClaimNumber}
                      disabled={tab && tab !== 'recipients'}
                      done={
                        tab &&
                        tab !== 'recipients' &&
                        tab !== 'inspectionPhotos'
                      }
                    >
                      <PropertyInspectionTaskRecipientCard
                        onNext={() => {
                          setTab('summary');
                        }}
                        onPrevious={() => {
                          setTab('inspectionPhotos');
                        }}
                      />
                    </BalTabItem>
                    <BalTabItem
                      value="summary"
                      label={t('inspectionTask.summary')}
                      ref={refTabContactInformation}
                      done={
                        inspectionTask.status === 'success' &&
                        (tab === 'review' || tab == 'message')
                      }
                      disabled={tab && tab !== 'summary'}
                    >
                      <PropertyInspectionTaskSummaryCard
                        onNext={() => {
                          setTab('review');
                        }}
                        onPrevious={() => {
                          setTab('recipients');
                        }}
                      />
                    </BalTabItem>
                    <BalTabItem
                      value="review"
                      label={t('inspectionTask.review')}
                      ref={refTabClaimInformation}
                      disabled={tab && tab !== 'review'}
                      done={
                        inspectionTask.status === 'success' &&
                        inspectionTask.localValue.summary != undefined &&
                        inspectionTask.localValue.recommendation != undefined &&
                        tab === 'review'
                      }
                    >
                      <PropertyInspectionTaskReviewCard
                        onPrevious={() => {
                          setTab('summary');
                        }}
                      />
                    </BalTabItem>
                  </BalTabs>
                )}
              </PropertyInspectionTaskSendingWizardViewContext.Provider>
            </div>
          </div>
        </>
      )}
    </WithCapabilities>
  );
};

export default PropertyInspectionTaskSendingWizardView;
