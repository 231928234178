import { BalHeading } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';

const BaseTaskInternalNotes = ({ internalNote }: { internalNote: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <BalHeading level="h5" space="none" className="mt-5">
        {t('baseTaskDtoColumns.internalNote')}
      </BalHeading>
      <div
        dangerouslySetInnerHTML={{
          __html: internalNote ?? '',
        }}
      ></div>
    </>
  );
};
export default BaseTaskInternalNotes;
