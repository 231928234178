import { TaskType, TaskTypes } from '../../../types/types';
import { balModalController } from '../../../controller/controllers';
import {
  SelectUserModal,
  SelectUserModalProps,
} from '../../user/components/select-user-modal';
import {
  InvestigationTaskStatus,
  BaseTaskStatus,
  UserDto,
  VehicleTaskStatus,
} from '../../../types/resource-models';
import { WithCapabilities } from '../../../components/authorization';
import { getDisplayNameFromUser } from '../../../utils/utilities';
import { InfoEditOriginExpert } from './info-edit-origin-expert';

type EditClaimOriginExpertProps = {
  user: UserDto;
  title: string;
  onEditSuccess: (user: UserDto) => void;
  onAssignOriginExpert: () => void;
  taskType: TaskType;
  taskStatus: BaseTaskStatus | InvestigationTaskStatus | VehicleTaskStatus;
};

export const SelectUserModalButton = (
  props: EditClaimOriginExpertProps,
): JSX.Element => {
  const openModal = async () => {
    const modalProps: SelectUserModalProps = {
      initialSelected: props.user,
      onSelectedSuccess: props.onEditSuccess,
      appCapabilityNames:
        props.taskType === TaskTypes.PropertyInspectionTask
          ? ['EditPropertyInspectionTask']
          : props.taskType === TaskTypes.VehicleTask
          ? ['EditVehicleTask', 'ReadOwnedVehicleTask']
          : props.taskType === TaskTypes.InvestigationTask
          ? ['EditFraudInspectionTask']
          : ['EditAccidentInspectionTask'],
    };
    const modal = await balModalController.create({
      component: SelectUserModal,
      componentProps: modalProps,
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={[
        props.taskType === TaskTypes.PropertyInspectionTask
          ? 'EditPropertyInspectionTask'
          : props.taskType === TaskTypes.VehicleTask
          ? 'EditVehicleTask'
          : props.taskType === TaskTypes.InvestigationTask
          ? 'EditFraudInspectionTask'
          : 'EditAccidentInspectionTask',
      ]}
    >
      <InfoEditOriginExpert
        title={props.title}
        text={`${(props.user && getDisplayNameFromUser(props.user)) ?? ''}`}
        onEdit={async () => await openModal()}
        onAssignOriginExpert={props.onAssignOriginExpert}
        taskStatus={props.taskStatus}
        className="pb-1"
      />
    </WithCapabilities>
  );
};
