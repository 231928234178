import { BalButton, BalHeading } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { InfoDisplay } from '../../../components/ui';
import { AffectedPartner, Partner } from '../../../types/types';
import {
  concat,
  concatAddress,
  getAffectedPartnerFunctionOrRole,
} from '../../../utils';
import { formatDateTime } from '../../../utils/date';

type ContactDetailInformationProps = {
  contactPartner: AffectedPartner;
  className?: string;
  hideRole?: boolean;
  editComponent?: JSX.Element;
  onDelete?: (partner: Partner) => void;
  hasGender?: boolean;
  hasPreferedContact?: boolean;
  hasLanguage?: boolean;
  hasAddress?: boolean;
};

export const ContactDetailInformation = ({
  contactPartner,
  className,
  hideRole,
  editComponent,
  onDelete,
  hasGender = false,
  hasPreferedContact = false,
  hasLanguage = false,
  hasAddress = true,
}: ContactDetailInformationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className={concat([
        'columns is-multiline is-full-width is-gapless ',
        className,
      ])}
    >
      {!hideRole && (
        <div className="mb-2 is-flex">
          {(contactPartner.partner.type == 'person'
            ? contactPartner.partner.person.function
            : contactPartner.partner.company.function) ||
          contactPartner.role ? (
            <BalHeading
              className="pt-3"
              space="none"
              level="h5"
              color="info"
              subtitle
            >
              {getAffectedPartnerFunctionOrRole(
                contactPartner,
                t(`partnerRole.${contactPartner.role ?? 'None'}`),
              )}
            </BalHeading>
          ) : (
            <BalHeading
              className="pt-3"
              space="none"
              level="h5"
              color="info"
              subtitle
            >
              {t('general.contact.label')}
            </BalHeading>
          )}
          {editComponent && <div className="pl-6">{editComponent}</div>}
          {onDelete && (
            <BalButton
              className={'is-align-self-center pl-2 mt-1'}
              square
              outlined
              size="small"
              color="danger"
              icon="trash"
              onClick={(event) =>
                event.detail == 1 && onDelete(contactPartner.partner)
              }
            ></BalButton>
          )}
        </div>
      )}
      {hasGender && contactPartner.partner.type === 'person' && (
        <InfoDisplay
          title={t('genders.label')}
          text={t(`genders.gender.${contactPartner.partner.person.gender}`)}
          className="pb-1 column is-full"
        />
      )}
      {((contactPartner.partner.type === 'person' &&
        contactPartner.partner.person.partnerNumber != '') ||
        (contactPartner.partner.type === 'company' &&
          contactPartner.partner.company.partnerNumber != '')) && (
        <div className="column is-full">
          <InfoDisplay
            title={t('general.partnerNumber')}
            text={
              contactPartner.partner.type === 'person'
                ? contactPartner.partner.person.partnerNumber
                : contactPartner.partner.company.partnerNumber
            }
            className="pb-1"
          />
        </div>
      )}
      <div className="column is-full">
        <InfoDisplay
          title={t('general.name')}
          text={
            contactPartner.partner.type === 'person'
              ? contactPartner.partner.person.displayName
              : contactPartner.partner.company.displayName
          }
          className="pb-1"
        />
      </div>
      {contactPartner.partner.type === 'person' &&
        contactPartner.partner.person.birthday != null && (
          <div className="column is-full">
            <InfoDisplay
              title={t('general.birthday')}
              text={formatDateTime(contactPartner.partner.person.birthday)}
              className="pb-1"
            />
          </div>
        )}
      {((contactPartner.partner.type === 'person' &&
        contactPartner.partner.person.address) ||
        (contactPartner.partner.type !== 'person' &&
          contactPartner.partner.company.address)) &&
        hasAddress && (
          <InfoDisplay
            title={t('general.address')}
            text={
              contactPartner.partner.type === 'person'
                ? concatAddress(contactPartner.partner.person.address)
                : concatAddress(contactPartner.partner.company.address)
            }
            className="pb-1 column is-full"
          />
        )}
      {(contactPartner.partner.type === 'person'
        ? contactPartner.partner.person.phoneNumber
        : contactPartner.partner.company.phoneNumber) && (
        <InfoDisplay
          title={t('general.phone')}
          text={
            contactPartner.partner.type === 'person'
              ? contactPartner.partner.person.phoneNumber
              : contactPartner.partner.company.phoneNumber
          }
          className="pb-1 column is-full"
        />
      )}
      {contactPartner.partner.type === 'person' &&
        contactPartner.partner.person.mobileNumber && (
          <InfoDisplay
            title={t('general.mobile')}
            text={contactPartner.partner.person.mobileNumber}
            className="pb-1 column is-full"
          />
        )}
      {contactPartner.partner.type === 'person' &&
        contactPartner.partner.person.companyNumber && (
          <InfoDisplay
            title={t('general.phoneBusiness')}
            text={contactPartner.partner.person.companyNumber}
            className="pb-1 column is-full"
          />
        )}
      {(contactPartner.partner.type === 'person'
        ? contactPartner.partner.person.email
        : contactPartner.partner.company.email) && (
        <InfoDisplay
          title={t('general.email')}
          text={
            contactPartner.partner.type === 'person'
              ? contactPartner.partner.person.email ?? ''
              : contactPartner.partner.company.email ?? ''
          }
          className="pb-1 column is-full"
        />
      )}
      {hasLanguage && (
        <InfoDisplay
          title={t('language.label')}
          text={t(
            `language.languages.${
              contactPartner.partner.type === 'person'
                ? contactPartner.partner.person.language
                : contactPartner.partner.company.language
            }`,
          )}
          className="pb-1 column is-full"
        />
      )}
      {hasPreferedContact && (
        <InfoDisplay
          title={t('networkPartners.preferredContactMethod.label')}
          text={t(
            `networkPartners.preferredContactMethod.preferredContact.${
              contactPartner.partner.type === 'person'
                ? contactPartner.partner.person.preferedContact
                : contactPartner.partner.company.preferedContact
            }`,
          )}
          className="pb-1 column is-full"
        />
      )}
    </div>
  );
};
