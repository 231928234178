import { TFunction } from 'i18next';
import { UserDto, UserMenuItemDto } from '../../../types/resource-models';
import { TaskTypes } from '../../../types/types';
import { Filter } from '../../view-menu/view-menu';
import { MenuItem } from '../../view-menu/view-menu-item';

const getBaseMenuItems = (t: TFunction, user?: UserDto): MenuItem[] => {
  const draftLabel = t('taskOverview.draftTasks');
  const menuItems: MenuItem[] = [
    {
      isCustomFilter: false,
      type: 'InvestigationTask',
      label: t('taskOverview.yourNewTasks'),
      filter: {
        assigneeOrCreatorUserId: user?.id,
        status: ['InvestigationNew'],
        orderField: 'CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'InvestigationTask',
      label: t('taskOverview.yourOpenTasks'),
      filter: {
        status: ['InProgress'],
        assigneeOrCreatorUserId: user?.id,
        orderField: 'CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'InvestigationTask',
      label: t('taskOverview.yourInvestigationCompletedTasks'),
      filter: {
        status: ['InvestigationCompleted'],
        assigneeOrCreatorUserId: user?.id,
        orderField: 'CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'InvestigationTask',
      label: t('taskOverview.yourCompletedTasks'),
      filter: {
        status: ['Closed'],
        assigneeOrCreatorUserId: user?.id,
        orderField: 'ClosedAt',
        orderBy: 'desc',
      },
    },
    {
      isCustomFilter: false,
      type: 'InvestigationTask',
      label: t('taskOverview.yourAllTasks'),
      filter: {
        assigneeOrCreatorUserId: user?.id,
        orderField: 'AssignedAt',
        orderBy: 'desc',
      },
    },
    {
      isCustomFilter: false,
      type: 'InvestigationTask',
      label: t('taskOverview.allTasks'),
      filter: {
        orderField: 'Status,CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'InvestigationTask',
      label: t('taskOverview.allInvestigationNewTasks'),
      filter: {
        status: ['InvestigationNew'],
        assigneeUserId: ['00000000-0000-0000-0000-000000000000'],
        orderField: 'Status,CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'InvestigationTask',
      label: draftLabel,
      filter: {
        status: ['New'],
        orderField: 'CreatedAt',
        orderBy: 'desc',
      },
    },
  ];

  const editFraudCapability = user?.appCapabilities.find(
    (c) => c === 'EditFraudInspectionTask',
  );
  if (editFraudCapability) {
    return menuItems;
  } else {
    return menuItems.filter((m) => m.label == draftLabel);
  }
};

export const getMenuItems = (t: TFunction, user?: UserDto): MenuItem[] => {
  const baseMenuItems = getBaseMenuItems(t, user);
  // Add custom menu items.
  if (user) {
    user.userMenuItems.forEach((userMenuItemDto: UserMenuItemDto) => {
      if (userMenuItemDto.type === TaskTypes[TaskTypes.InvestigationTask]) {
        const filter: Filter = JSON.parse(userMenuItemDto.filter);
        // Custom menu items sort info is not compatible with user setting sort info.
        filter.orderBy = undefined;
        filter.orderField = undefined;
        const menuItem: MenuItem = { ...userMenuItemDto, filter: filter };
        baseMenuItems.push(menuItem);
      }
    });
  }
  return baseMenuItems;
};

export const getDefaultMenuItem = (t: TFunction, user?: UserDto): MenuItem => {
  return getBaseMenuItems(t, user)[0];
};
