import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToken } from '../../../hooks';
import { Globals } from '../../../utils';
import { RequestResult } from '../../../data';
import { InvestigationTask } from '../../../types/types';
import {
  getInvestigationTasks,
  getInvestigationTask,
  getInvestigationTaskAllHistory,
} from './requests';
import { UserContext } from '../../../context';
import { getMenuItems } from '../components/investigation-task-view-menu-items';
import {
  BaseTaskChangeDto,
  BaseTaskFilterCriteria,
  SortOrder,
} from '../../../types/resource-models';
import { CombinedState, CombinedStateProps } from '../../base-task';
import { initialCombinedState } from '../../base-task/data/hooks';

export function useInvestigationTask(): [
  RequestResult<InvestigationTask>,
  React.Dispatch<RequestResult<InvestigationTask>>,
] {
  const [investigationTaskResponse, setInvestigationTaskResponse] = useState<
    RequestResult<InvestigationTask>
  >({
    status: 'initial',
  });

  return [investigationTaskResponse, setInvestigationTaskResponse];
}

export function useInvestigationTasksFromApi(): CombinedStateProps {
  const token = useToken();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [combinedState, setCombinedState] =
    useState<CombinedState>(initialCombinedState);

  useEffect(() => {
    if (
      (user.status === 'success' || user.status === 'loading-with-value') &&
      combinedState.tasks.status === 'initial'
    ) {
      let customOrderField: string | undefined = undefined;
      let customOrderBy: SortOrder | undefined = undefined;
      if (user.status === 'success') {
        const userTableSetting = user.localValue.userTableSettings.find(
          (x) => x.type === 'InvestigationTask',
        );
        if (userTableSetting?.orderField) {
          customOrderField = userTableSetting?.orderField;
          customOrderBy = userTableSetting?.orderBy;
        }
      }
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.tasks = { status: 'loading' };
          // apply first quick filter from menu as default filter
          const menuItems = getMenuItems(t, user.value);
          const emptyFilter = Globals.filterDefault;
          draftState.defaultFilter = {
            ...emptyFilter,
            ...(menuItems[0].filter as Partial<BaseTaskFilterCriteria>),
          };
          draftState.filter = customOrderField
            ? {
                ...draftState.defaultFilter,
                orderField: customOrderField,
                orderBy: customOrderBy ?? 'asc',
              }
            : draftState.defaultFilter;
          draftState.label = menuItems[0].label;
        }),
      );
    }
  }, [user, combinedState, t]);

  useEffect(() => {
    if (
      token !== '' &&
      (combinedState.tasks.status === 'loading' ||
        combinedState.tasks.status === 'loading-with-value')
    ) {
      getInvestigationTasks(
        token,
        combinedState.filter as BaseTaskFilterCriteria,
      ).then((result) =>
        setCombinedState(
          produce(combinedState, (draftState) => {
            draftState.tasks = result;
          }),
        ),
      );
    }
  }, [token, combinedState]);

  return { combinedState, setCombinedState };
}

export function useInvestigationTaskFromApi(
  taskId: string,
): [
  RequestResult<InvestigationTask>,
  React.Dispatch<RequestResult<InvestigationTask>>,
] {
  const token = useToken();
  const [investigationTaskResponse, setInvestigationTaskResponse] = useState<
    RequestResult<InvestigationTask>
  >({
    status: 'loading',
  });

  useEffect(() => {
    setInvestigationTaskResponse({ status: 'loading' });
    if (token !== '' && taskId !== '') {
      getInvestigationTask(token, taskId).then(setInvestigationTaskResponse);
    }
  }, [token, taskId]);

  return [investigationTaskResponse, setInvestigationTaskResponse];
}

export function useInvestigationTaskHistoryFromApi(
  taskId: string,
): [
  RequestResult<BaseTaskChangeDto[]>,
  React.Dispatch<RequestResult<BaseTaskChangeDto[]>>,
] {
  const token = useToken();
  const [investigationTaskChanges, setInvestigationTaskChanges] = useState<
    RequestResult<BaseTaskChangeDto[]>
  >({
    status: 'initial',
  });
  useEffect(() => {
    if (token !== '' && investigationTaskChanges.status !== 'success') {
      if (investigationTaskChanges.status !== 'loading-with-value')
        setInvestigationTaskChanges({ status: 'loading' });
      getInvestigationTaskAllHistory(token, taskId).then((result) => {
        if (result.status === 'success') {
          setInvestigationTaskChanges({
            status: 'success',
            value: result.value,
            localValue: result.localValue,
          });
        } else if (result.status === 'error') {
          setInvestigationTaskChanges(result);
        }
      });
    }
  }, [taskId, token, investigationTaskChanges.status]);
  return [investigationTaskChanges, setInvestigationTaskChanges];
}
