import produce from 'immer';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { RequestResult } from '../../../data';
import {
  AppCapabilityNames,
  BaseTaskListDto,
  BaseTaskStatus,
} from '../../../types/resource-models';
import { Utilities } from '../../../utils/utilities';
import { EBRoutes } from '../../../router/router';
import {
  BaseTaskSearchFormFields,
  BaseTaskSearchFormTable,
  CombinedState,
} from '../../base-task';
import {
  BaseTask,
  BaseTaskStatusGroupedFilter,
  TaskTypes,
} from '../../../types/types';
import { AccidentInspectionTaskOverlay } from './accident-inspection-task-overlay';
import { getAccidentInspectionTask } from '../data/requests';
import { useGridOptions } from './accident-inspection-task-search-form-table-grid-options';
import React from 'react';

export interface AccidentInspectionTaskSearchForm {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  task: RequestResult<BaseTaskListDto>;
  setTask: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>;
  editFilter: string | undefined;
}

const AccidentInspectionTaskSearchForm = ({
  combinedState,
  setCombinedState,
  task,
  setTask,
  editFilter,
}: AccidentInspectionTaskSearchForm): JSX.Element => {
  const inProgressStates: BaseTaskStatus[] = ['InProgress'];
  const creatorsAppCapabilityNames: AppCapabilityNames[] = [
    'CreateAccidentInspectionTask',
  ];
  const editorAccidentInspectionTask: AppCapabilityNames[] = [
    'EditAccidentInspectionTask',
  ];
  const [, setGridApi] = useState<GridApi>();
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const allStates: BaseTaskStatusGroupedFilter[] = [
    'New',
    'Assigned',
    'InProgress',
    'Rejected',
    'Closed',
  ];

  const refTaskOverlay = useRef<HTMLBalCardElement>(null);
  const replaceTaskInList = (newTask: RequestResult<BaseTask>) => {
    if (
      newTask.status === 'success' &&
      combinedState.tasks.status === 'success'
    ) {
      const index = combinedState.tasks.value.entities.findIndex(
        (task: BaseTaskListDto) => newTask.value.id === task.id,
      );

      setCombinedState(
        produce(combinedState, (draftState) => {
          if (draftState.tasks.status === 'success') {
            if (!newTask.value.deletedAt) {
              draftState.tasks.value.entities.splice(
                index,
                1,
                newTask.value as unknown as BaseTaskListDto,
              );
            } else {
              draftState.tasks.value.entities =
                draftState.tasks.value.entities.filter(
                  (selectedTask) => selectedTask.id !== newTask.value.id,
                );
              task.status = 'initial';
            }
          }
        }),
      );
    }
  };
  const [gridOptions] = useGridOptions(setTask);

  useEffect(() => {
    if (task) {
      replaceTaskInList(task as unknown as RequestResult<BaseTask>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <>
      <div className="is-full-height has-background-grey-1 is-flex is-flex-direction-column pl-5 pr-7 pb-3">
        <BaseTaskSearchFormFields
          combinedState={combinedState}
          setCombinedState={setCombinedState}
          inProgressStates={inProgressStates}
          editorAppCapabilityNames={editorAccidentInspectionTask}
          creatorsAppCapabilityNames={creatorsAppCapabilityNames}
          claimNumberPlaceHolder={Utilities.maskAccidentClaimNumber}
          createWizardRoute={EBRoutes.ACCIDENT_INSPECTION_TASK_WIZARD}
          allStates={allStates}
          allBranches={['Accident', 'Health']}
          taskType={TaskTypes.AccidentInspectionTask}
          editFilter={!!editFilter}
        />
        <BaseTaskSearchFormTable
          combinedState={combinedState}
          setCombinedState={setCombinedState}
          onGridReady={onGridReady}
          task={task}
          setTask={setTask}
          refTaskOverlay={refTaskOverlay}
          gridOptions={gridOptions}
          userTableType={'AccidentInspectionTask'}
        />
        {task.status === 'success' && (
          <AccidentInspectionTaskOverlay
            refTaskOverlay={refTaskOverlay}
            task={task.value}
            slideIn
            replaceTaskInList={replaceTaskInList}
            getFullTask={getAccidentInspectionTask}
          />
        )}
      </div>
    </>
  );
};

export default AccidentInspectionTaskSearchForm;
