import { BalButton } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';
import { concat } from '../../../utils';
// import _ from 'lodash';
import CreateEditContactModal, {
  CreateEditContactModalProps,
} from './create-edit-contact-modal';

export const CreateEditContactButton = ({
  onSave,
  edit,
  affectedPartnerToEdit,
  addressOpen,
  className,
  textClassName,
  onDelete,
  requiredPhoneOrMail,
  customRoles,
  detailSwitch,
  hasPartnerNr,
  hasGender,
  hasPreferedContact,
  hasLanguage,
  hasAddress,
  hasMobile,
  hasCompanyNr,
  hasBirthday,
  hasRole,
  hasFunction,
  policyHolderId,
}: CreateEditContactModalProps): JSX.Element => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: CreateEditContactModal,
      componentProps: {
        onSave: onSave,
        edit: edit,
        addressOpen: addressOpen,
        affectedPartnerToEdit: affectedPartnerToEdit,
        className: className,
        onDelete: onDelete,
        requiredPhoneOrMail: requiredPhoneOrMail,
        customRoles: customRoles,
        detailSwitch: detailSwitch,
        hasPartnerNr: hasPartnerNr,
        hasGender: hasGender,
        hasPreferedContact: hasPreferedContact,
        hasLanguage: hasLanguage,
        hasAddress: hasAddress,
        hasMobile: hasMobile,
        hasCompanyNr: hasCompanyNr,
        hasBirthday: hasBirthday,
        hasRole: hasRole,
        hasFunction: hasFunction,
        policyHolderId: policyHolderId,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };
  return edit ? (
    <BalButton
      className={concat(['is-align-self-center', className])}
      square
      outlined
      size="small"
      color="primary-light"
      icon="edit"
      onClick={(event) => event.detail == 1 && openModal()}
    ></BalButton>
  ) : (
    <BalButton
      className={textClassName}
      outlined
      elementType="button"
      icon="plus"
      color="primary-light"
      onClick={(event) => event.detail == 1 && openModal()}
    >
      {t('general.contact.createContact')}
    </BalButton>
  );
};
