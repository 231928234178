import {
  BalButton,
  BalCheckbox,
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { ColDef, GridApi } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import {
  UserDto,
  UserTableSettingDto,
  UserTableTypes,
} from '../../../types/resource-models';
import { guid } from '../../../utils';
import {
  getSortingState,
  postUserTableSetting,
} from '../../user/data/requests';
import { CancelButton } from '../../../components/ui';

export type TableSettingsFormProps = {
  gridApi: GridApi | undefined;
  user: RequestResult<UserDto>;
  userTableType: UserTableTypes;
  onSelectedColumns?: (userTableSettingDto: UserTableSettingDto) => void;
  defaultColDefs: ColDef[];
  className?: string;
};

export const TableSettingsForm = ({
  gridApi,
  user,
  userTableType,
  onSelectedColumns,
  defaultColDefs,
  className,
}: TableSettingsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();

  const [allColumns, setAllColums] = useState<ColDef[] | undefined>(
    gridApi ? gridApi.getColumnDefs() : [],
  );

  useEffect(() => {
    if (allColumns) {
      if (allColumns.filter((x) => x.hide).length == 0) setToggleButton(true);
    }
  }, [allColumns]);

  const [toggleButton, setToggleButton] = useState(false);

  const displayColumns = (allColumns: ColDef[] | undefined) => {
    if (!allColumns) return;
    return allColumns.map((colDef: ColDef) => {
      if (colDef.headerName) {
        return (
          <div className="column is-half" key={colDef.headerName}>
            <BalCheckbox
              key={colDef.headerName}
              id={colDef.headerName}
              checked={!colDef.hide}
              onBalChange={(event) => {
                if (allColumns) {
                  const colDef = allColumns.find(
                    (x) => x.headerName === event.target?.id,
                  );
                  if (colDef) {
                    colDef.hide = !event.detail;
                  }
                }
              }}
            >
              {colDef.headerName}
            </BalCheckbox>
          </div>
        );
      }
    });
  };

  const getUserTableSettingDto = (
    allColumns: ColDef[] | undefined,
  ): UserTableSettingDto => {
    const tableSetting: UserTableSettingDto = {
      id: guid(),
      userId: user.status === 'success' ? user.localValue.id : '',
      type: userTableType,
      columnsToHide: '[]',
      columnsToShow: '[]',
      columnWidths: [],
      showFilters: false,
    };

    if (user.status === 'success') {
      // Maintain current colum widths and order.
      const userTableSetting = user.localValue.userTableSettings.find(
        (x) => x.type === userTableType,
      );
      tableSetting.columnWidths = userTableSetting?.columnWidths ?? [];
      tableSetting.columnsOrder = userTableSetting?.columnsOrder;
    }

    const hiddenColumnHeaderNames: string[] = [];
    const hiddenColumns = allColumns?.filter((x) => x.hide);
    if (hiddenColumns) {
      hiddenColumns.forEach((colDef: ColDef) => {
        if (colDef.headerName) {
          hiddenColumnHeaderNames.push(colDef.headerName);
        }
      });
    }
    tableSetting.columnsToHide = JSON.stringify(hiddenColumnHeaderNames);

    const shownColumnHeaderNames: string[] = [];
    const shownColumns = allColumns?.filter((x) => !x.hide);
    if (shownColumns) {
      shownColumns.forEach((colDef: ColDef) => {
        if (colDef.headerName) {
          shownColumnHeaderNames.push(colDef.headerName);
        }
      });
    }
    tableSetting.columnsToShow = JSON.stringify(shownColumnHeaderNames);

    const sortingState = getSortingState(user, userTableType);
    if (sortingState) {
      const orderFieldIsShown = sortingState.field
        ? shownColumns?.find((sc) => sc.field === sortingState.field) !==
          undefined
        : false;

      tableSetting.orderField = orderFieldIsShown
        ? sortingState.field
        : undefined;
      tableSetting.orderBy = orderFieldIsShown
        ? sortingState.direction
        : undefined;
    }

    return tableSetting;
  };

  return (
    <div className={className}>
      <BalModalHeader>
        <BalHeading space="none" level="h3" subtitle>
          {t('tableSettingsForm.headingText')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div className="columns is-multiline is-gapless scroll-bar-minimal pl-5">
          {displayColumns(allColumns)}
        </div>
        <div className="is-flex is-flex-direction-column ">
          <div className="is-flex mb-2 is-justify-content-flex-end">
            <BalButton
              className="mr-1"
              color="primary"
              onClick={async () => {
                if (allColumns) {
                  const newColumns: ColDef[] = [];
                  allColumns.forEach((colDef: ColDef) => {
                    if (colDef.headerName) {
                      colDef.hide = toggleButton;
                    }
                    newColumns.push(colDef);
                  });
                  setAllColums(newColumns);
                }
                setToggleButton(!toggleButton);
              }}
            >
              {t('tableSettingsForm.toggleAllFieldsButton')}
            </BalButton>
            <BalButton //
              color="primary"
              onClick={async () => {
                if (allColumns) {
                  const newColumns: ColDef[] = [];
                  allColumns?.forEach((colDef: ColDef) => {
                    if (colDef.headerName) {
                      const column = defaultColDefs.find(
                        (c) => c.headerName === colDef.headerName,
                      );
                      if (column) {
                        colDef.hide = column.hide ?? false;
                        newColumns.push(colDef);
                      }
                    } else {
                      newColumns.push(colDef);
                    }
                  });
                  setAllColums(newColumns);
                }
              }}
            >
              {t('tableSettingsForm.resetFieldsButton')}
            </BalButton>
          </div>
          <div className="is-flex is-justify-content-flex-end">
            <CancelButton />
            <BalButton
              color="info"
              onClick={async () => {
                if (allColumns) {
                  const userTableSettingDto =
                    getUserTableSettingDto(allColumns);
                  const userTableSettingResult = await postUserTableSetting(
                    userTableSettingDto,
                    token,
                  );
                  if (userTableSettingResult.status === 'success') {
                    gridApi?.setColumnDefs(allColumns);
                    if (onSelectedColumns) {
                      onSelectedColumns(userTableSettingResult.value);
                    }
                    balModalController.dismiss();
                  }
                }
              }}
            >
              {t('general.buttons.save')}
            </BalButton>
          </div>
        </div>
      </BalModalBody>
    </div>
  );
};
