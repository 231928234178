import { BalIcon } from '@baloise/design-system-components-react';
import i18n from '../../../i18n/config';
import { concat } from '../../../utils';
import { PropertyInspectionTaskJournalChangeDto } from '../../../types/resource-models';
import TextProtocolModal from '../../../components/input/text-protocol-modal';
import { ProtocolMessage } from './entity-protocol';
import { ReactComponent as AttachmentAdd } from '../../../assets/icons/attachment-add.svg';
import { ReactComponent as AttachmentDelete } from '../../../assets/icons/attachment-delete.svg';
import { balIconEyeClosed, balIconAccount } from '@baloise/design-system-icons';

export const getPropertyInspectionTaskJournalMessage = (
  change: PropertyInspectionTaskJournalChangeDto,
  changes: PropertyInspectionTaskJournalChangeDto[],
): ProtocolMessage | undefined => {
  const t = i18n.t;
  if (change.newValue)
    switch (change.changeType) {
      case 'Created':
        return {
          message: t('craftsmanHistory.Created'),
          icon: (
            <div className="icon-container">
              <BalIcon name="check" color="success" />
            </div>
          ),
        };
      case 'JournalType':
        return {
          message: concat([
            t('inspectionTask.journal.type'),
            t(`inspectionTask.journal.journalTypes.${change.newValue}`),
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon name="edit" color="info" />
            </div>
          ),
        };
      case 'OrderNumber':
        return {
          message: concat([
            t('inspectionTask.journal.orderNumber'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon name="edit" color="info" />
            </div>
          ),
        };
      case 'Confidential':
        return {
          message: concat([
            t('inspectionTask.journal.confidential'),
            t(`tristate.${change.newValue}`),
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconEyeClosed} color="info" />
            </div>
          ),
        };
      case 'Note':
        const translatedChange = {
          ...change,
          propertyName: t('inspectionTask.journal.note'),
        };
        changes.push(translatedChange);
        return {
          message: concat([t('journal.history.note'), change.newValue]),
          change: { ...change, propertyName: t('journal.history.note') },
          icon: (
            <div className="icon-container">
              <BalIcon
                name="edit"
                onClick={(event) =>
                  event.detail == 1 &&
                  TextProtocolModal({
                    label: t('taskHistory.Remarks'),
                    changes: changes,
                  })
                }
                className="icon-clickable"
              />
            </div>
          ),
        };
      case 'CreatedAt':
        return {
          message: concat([
            t('journal.history.date'),
            change.newValue.slice(0, 10),
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon name="date" color="info" />
            </div>
          ),
        };
      case 'AttachmentAdded':
        return {
          message: concat([t('taskHistory.AttachmentAdded'), change.newValue]),
          icon: (
            <div className="icon-container is-info pl-2">
              <AttachmentAdd />
            </div>
          ),
        };
      case 'AttachmentRemoved':
        return {
          message: concat([
            t('taskHistory.AttachmentRemoved'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container is-info pl-2">
              <AttachmentDelete />
            </div>
          ),
        };
      case 'ContactPersonName':
        return {
          message: concat([
            t('journal.history.contactPersonName'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container is-info pl-2">
              <BalIcon svg={balIconAccount} color="info" />
            </div>
          ),
        };
      default:
        return undefined;
    }
};
