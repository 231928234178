import { BalButton } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { BaseTask } from '../../../../types/types';
import { balModalController } from '../../../../controller/controllers';
import { AppCapabilityNames } from '../../../../types/resource-models';
import { WithCapabilities } from '../../../../components/authorization';
import BaseTaskPrioritySelectorModalForm, {
  BaseTaskPrioritySelectorModalFormProps,
} from '../base-task-priority-selector-modal-form';

export interface BaseTaskPrioritySelectorButton<BaseTaskType extends BaseTask>
  extends BaseTaskPrioritySelectorModalFormProps<BaseTaskType> {
  requiredCapabilities: AppCapabilityNames[];
  className?: string;
  outlined?: boolean;
  showEditIcon?: boolean;
}

const BaseTaskPrioritySelectorButton = <BaseTaskType extends BaseTask>(
  props: BaseTaskPrioritySelectorButton<BaseTaskType>,
): JSX.Element => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: BaseTaskPrioritySelectorModalForm,
      componentProps:
        props as BaseTaskPrioritySelectorModalFormProps<BaseTaskType>,
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={props.requiredCapabilities}
      passWithCapabilitiesPropsToChildren
    >
      {props.showEditIcon ? (
        <BalButton
          className={props.className}
          square
          outlined
          size="small"
          color="primary-light"
          icon="edit"
          onClick={(event) => event.detail == 1 && openModal()}
        />
      ) : (
        <BalButton
          className={props.className}
          onClick={(event) => event.detail == 1 && openModal()}
          outlined={props.outlined}
          size="small"
          color="info"
        >
          {t('baseTaskListDtoColums.priority')}
        </BalButton>
      )}
    </WithCapabilities>
  );
};

export default BaseTaskPrioritySelectorButton;
