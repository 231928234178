import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import { InvestigationTaskJournalListDto } from '../../../../types/resource-models';
import DeleteConfirmationDialog from '../../../dialogs/delete-confirmation-dialog';
import { capitalize } from 'lodash';
export interface JournalDeleteButtonProps {
  currentJournal: InvestigationTaskJournalListDto | undefined;
  disabled?: boolean;
  onDeleteJournal: (journal: InvestigationTaskJournalListDto) => Promise<void>;
  textButton?: boolean;
}

const JournalDeleteButton = ({
  currentJournal,
  disabled,
  onDeleteJournal,
  textButton,
}: JournalDeleteButtonProps): JSX.Element => {
  const openDeleteModal = async () => {
    if (currentJournal) {
      const modal = await balModalController.create({
        component: DeleteConfirmationDialog,
        componentProps: {
          title: `${t('general.buttons.delete')} ${t('journal.entityName')}`,
          text: t('journal.deleteConfirmationQuestion'),
          onDelete: async () => {
            await onDeleteJournal(currentJournal);
          },
        },
        cssClass: 'center-modal',
      });
      return modal.present();
    }
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      {textButton ? (
        <BalButton
          className="ml-4"
          color="danger"
          onClick={async () => await openDeleteModal()}
        >
          {capitalize(t('general.buttons.delete'))}
        </BalButton>
      ) : (
        <BalButton
          disabled={disabled}
          className="ml-2"
          outlined
          square
          size="small"
          color="danger"
          icon="minus"
          onClick={async () => await openDeleteModal()}
        />
      )}
    </WithCapabilities>
  );
};

export default JournalDeleteButton;
