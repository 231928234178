import {
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalFieldMessage,
  BalSelect,
  BalSelectOption,
} from '@baloise/design-system-components-react';
import { Props } from '@baloise/design-system-components/dist/types/props';
import '../../styles/general.scss';
import { concat } from '../../utils';
import React from 'react';
import { TranslatedSelection } from '../../types/types';

type BalSelectFieldProps = {
  className?: string;
  titleClassName?: string;
  title?: string;
  placeholder?: string;
  value?: string;
  values: TranslatedSelection[];
  onChange?: (e: CustomEvent<string | undefined>) => void;
  onInput?: (e: CustomEvent<string | undefined>) => void;
  onFocus?: () => void;
  onKeyPress?: (e: CustomEvent<KeyboardEvent>) => void;
  message?: string;
  type?: Props.BalInputInputType;
  disabled?: boolean;
  alphabeticallySorted?: boolean;
};

const BalSelectField = React.forwardRef(
  (
    {
      className,
      titleClassName,
      title,
      placeholder,
      value,
      values,
      onChange,
      onInput,
      onFocus,
      onKeyPress,
      message,
      disabled,
      alphabeticallySorted,
    }: BalSelectFieldProps,
    ref: React.Ref<HTMLBalSelectElement>,
  ): JSX.Element => {
    const sortedValues = alphabeticallySorted
      ? values.sort((a: TranslatedSelection, b: TranslatedSelection) => {
          if (a.translatedValue > b.translatedValue) return 1;
          else return -1;
        })
      : values;

    return (
      <BalField
        className={concat(['is-gapless', className])}
        disabled={disabled}
      >
        {title && (
          <BalFieldLabel className={concat(['m-0', titleClassName])}>
            {title}
          </BalFieldLabel>
        )}
        <BalFieldControl className="m-0 p-0">
          <BalSelect
            ref={ref}
            placeholder={placeholder}
            value={value}
            onBalInput={onInput}
            onFocus={onFocus}
            onBalKeyPress={onKeyPress}
            onBalChange={(e: CustomEvent) => {
              if (e.detail && onChange) {
                onChange(e);
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {sortedValues.map((option: TranslatedSelection) => (
                <BalSelectOption
                  key={option.value}
                  value={option.value}
                  label={option.translatedValue}
                >
                  {option.translatedValue}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
        </BalFieldControl>
        <div className="m-0">
          {message && (
            <BalFieldMessage color="danger">{message}</BalFieldMessage>
          )}
        </div>
      </BalField>
    );
  },
);
BalSelectField.displayName = 'BalSelectField';

export default BalSelectField;
