import { APIRoutes } from '../../../router/router';
import { apiUrl } from '../../../data/fetch/base';
import { jsonGet, jsonReq, RequestResult } from '../../../data';
import {
  fileReq,
  makeAuthHeaders,
  makeAuthFileHeaders,
  typeFromApi,
} from '../../../data/fetch/requests';
import { diff, jsonPatchPathConverter } from 'just-diff';
import { t } from 'i18next';
import {
  UserMenuItemDto,
  ResponseOfUserDto,
  UserDto,
  UserFilterCriteria,
  UserTableSettingDto,
  UserTableTypes,
  SortOrder,
} from '../../../types/resource-models';
import { ColDef, Column } from 'ag-grid-community';
import { getTaskTypeFromEntityName, guid } from '../../../utils';
import { getBaseTaskDefaultColDefs } from '../../base-task/components/base-task-search-form/base-task-search-form-table-grid-options';
import { getVehicleDefaultColDefs } from '../../vehicle-task/components/vehicle-task-search-form-table-grid-options';
import { toast } from 'react-toastify';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import { ISortingState, TaskTypes } from '../../../types/types';
import produce from 'immer';
import { getCraftsmanDefaultColDefs } from '../../../views/network-partners/network-table-grid-options';

export const getUserProfile = (
  bearerToken: string,
): Promise<RequestResult<UserDto>> =>
  jsonGet<UserDto>(
    `${apiUrl}${APIRoutes.GET_USER_PROFILE.create({})}`,
    bearerToken,
  );

export const getUsers = (
  bearerToken: string,
  filter: UserFilterCriteria,
): Promise<RequestResult<ResponseOfUserDto>> => {
  const { firstName, ...rest } = filter;
  return jsonGet(
    `${apiUrl}${APIRoutes.GET_USERS.create({
      query: {
        ...rest,
        FirstName: firstName,
      },
    })}`,
    bearerToken,
    undefined,
  );
};

export const getUserPhoto = async (
  userId: string,
  bearerToken: string,
): Promise<RequestResult<string>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.GET_USER_PICTURE.create({
      userId: userId,
    })}`,
    {
      method: 'GET',
      headers: await makeAuthFileHeaders(bearerToken),
    },
  );
  return fileReq(request, true);
};

export const getUsersExportFile = async (
  bearerToken: string,
): Promise<RequestResult<string>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.GET_USER_EXPORT.create({})}`,
    {
      method: 'GET',
      headers: await makeAuthFileHeaders(bearerToken),
    },
  );
  return fileReq(request);
};

export const getUserById = async (
  userId: string,
  bearerToken: string,
): Promise<RequestResult<UserDto>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.GET_USER_BY_ID.create({
      userId: userId,
    })}`,
    {
      method: 'GET',
      headers: await makeAuthFileHeaders(bearerToken),
    },
  );
  return await jsonReq<UserDto>(request, typeFromApi);
};

export const patchUser = async (
  user: UserDto,
  userNew: UserDto,
  bearerToken: string,
): Promise<RequestResult<UserDto>> => {
  const patch = JSON.stringify(diff(user, userNew, jsonPatchPathConverter));
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_USER.create({
      userId: user.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<UserDto>(request, typeFromApi);
};

export const postUserMenuItem = async (
  menuItem: UserMenuItemDto,
  bearerToken: string,
): Promise<RequestResult<UserMenuItemDto>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.POST_USER_MENUITEM.create({})}`,
    {
      method: 'POST',
      headers: await makeAuthHeaders(bearerToken),
      body: JSON.stringify(menuItem),
    },
  );
  return await jsonReq<UserMenuItemDto>(request, typeFromApi);
};

export const deleteUserMenuItem = async (
  userMenuItemId: string,
  bearerToken: string,
): Promise<RequestResult<UserMenuItemDto>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.DELETE_USER_MENUITEM.create({
      userMenuItemId: userMenuItemId,
    })}`,
    {
      method: 'DELETE',
      headers: await makeAuthHeaders(bearerToken),
    },
  );
  return await jsonReq<UserMenuItemDto>(request, typeFromApi);
};

export const patchUserMenuItem = async (
  userMenuItemOld: UserMenuItemDto,
  userMenuItemNew: UserMenuItemDto,
  bearerToken: string,
): Promise<RequestResult<UserMenuItemDto>> => {
  const patch = JSON.stringify(
    diff(userMenuItemOld, userMenuItemNew, jsonPatchPathConverter),
  );
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_USER_MENUITEM.create({
      menuItemPatchOperations: userMenuItemOld.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<UserMenuItemDto>(request, typeFromApi);
};

export const postUserTableSetting = async (
  menuItem: UserTableSettingDto,
  bearerToken: string,
): Promise<RequestResult<UserTableSettingDto>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.POST_USER_TABLE_SETTING.create({})}`,
    {
      method: 'POST',
      headers: await makeAuthHeaders(bearerToken),
      body: JSON.stringify(menuItem),
    },
  );
  return await jsonReq<UserTableSettingDto>(request, typeFromApi);
};

const getDefaultUserTableSetting = (
  userId: string,
  userTableType: UserTableTypes,
) => {
  return {
    id: guid(),
    userId: userId,
    type: userTableType,
    columnsToHide: '[]',
    columnsToShow: '[]',
    columnWidths: [],
    showFilters: false,
  };
};

export const postSortUserTableSettings = async (
  orderField: string | undefined,
  orderBy: SortOrder | undefined,
  userTableType: UserTableTypes,
  user: RequestResult<UserDto>,
  setUser: React.Dispatch<RequestResult<UserDto>>,
  bearerToken: string,
): Promise<void> => {
  if (user.status === 'success') {
    let userTableSetting = user.localValue.userTableSettings.find(
      (x) => x.type === userTableType,
    );
    if (!userTableSetting) {
      userTableSetting = getDefaultUserTableSetting(
        user.localValue.id,
        userTableType,
      );
    }
    const result = await postUserTableSetting(
      { ...userTableSetting, orderField, orderBy },
      bearerToken,
    );
    if (result.status === 'success') {
      setUser(
        produce(user, (draft) => {
          draft.localValue.userTableSettings =
            draft.localValue.userTableSettings.filter(
              (ts) => ts.type !== userTableType,
            );
          draft.localValue.userTableSettings.push(result.localValue);
          draft.value = draft.localValue;
        }),
      );
    } else if (result.status === 'error') {
      toast(ErrorToast(result.errorValue));
    }
    //return user;
  }
};

export const postColumnsWidthUserTableSettings = async (
  name: string,
  width: number,
  userTableType: UserTableTypes,
  user: RequestResult<UserDto>,
  setUser: React.Dispatch<RequestResult<UserDto>>,
  bearerToken: string,
): Promise<void> => {
  if (user.status === 'success') {
    let userTableSetting = user.localValue.userTableSettings.find(
      (x) => x.type === userTableType,
    );
    if (!userTableSetting) {
      userTableSetting = userTableSetting = getDefaultUserTableSetting(
        user.localValue.id,
        userTableType,
      );
    }
    const newColumnWidths = userTableSetting.columnWidths.filter(
      (x) => x.name !== name,
    );
    newColumnWidths.push({ name, width });
    const result = await postUserTableSetting(
      { ...userTableSetting, columnWidths: newColumnWidths },
      bearerToken,
    );
    if (result.status === 'success') {
      setUser(
        produce(user, (draft) => {
          draft.localValue.userTableSettings =
            draft.localValue.userTableSettings.filter(
              (ts) => ts.type !== userTableType,
            );
          draft.localValue.userTableSettings.push(result.localValue);
          draft.value = draft.localValue;
        }),
      );
    } else if (result.status === 'error') {
      toast(ErrorToast(result.errorValue));
    }
  }
};

export const postColumnsOrderUserTableSettings = async (
  columns: Column[],
  userTableType: UserTableTypes,
  user: RequestResult<UserDto>,
  setUser: React.Dispatch<RequestResult<UserDto>>,
  bearerToken: string,
): Promise<void> => {
  if (user.status === 'success') {
    let userTableSetting = user.localValue.userTableSettings.find(
      (x) => x.type === userTableType,
    );
    if (!userTableSetting) {
      userTableSetting = getDefaultUserTableSetting(
        user.localValue.id,
        userTableType,
      );
    }
    const columnsNames = columns.map((c) => c.getColId());
    const result = await postUserTableSetting(
      { ...userTableSetting, columnsOrder: columnsNames.join('|') },
      bearerToken,
    );
    if (result.status === 'success') {
      setUser(
        produce(user, (draft) => {
          draft.localValue.userTableSettings =
            draft.localValue.userTableSettings.filter(
              (ts) => ts.type !== userTableType,
            );
          draft.localValue.userTableSettings.push(result.localValue);
          draft.value = draft.localValue;
        }),
      );
    } else if (result.status === 'error') {
      toast(ErrorToast(result.errorValue));
    }
  }
};

export const postShowFiltersUserTableSettings = async (
  showFilters: boolean,
  userTableType: UserTableTypes,
  user: RequestResult<UserDto>,
  setUser: React.Dispatch<RequestResult<UserDto>>,
  bearerToken: string,
): Promise<void> => {
  if (user.status === 'success') {
    let userTableSetting = user.localValue.userTableSettings.find(
      (x) => x.type === userTableType,
    );
    if (!userTableSetting) {
      userTableSetting = getDefaultUserTableSetting(
        user.localValue.id,
        userTableType,
      );
    }
    const result = await postUserTableSetting(
      { ...userTableSetting, showFilters },
      bearerToken,
    );
    if (result.status === 'success') {
      setUser(
        produce(user, (draft) => {
          draft.localValue.userTableSettings =
            draft.localValue.userTableSettings.filter(
              (ts) => ts.type !== userTableType,
            );
          draft.localValue.userTableSettings.push(result.localValue);
          draft.value = draft.localValue;
        }),
      );
    } else if (result.status === 'error') {
      toast(ErrorToast(result.errorValue));
    }
  }
};

const getUserTableSettingDto = (
  userId: string,
  userTableType: UserTableTypes,
  allColumns: ColDef[],
  showFilters: boolean,
): UserTableSettingDto => {
  return {
    id: guid(),
    userId: userId,
    type: userTableType,
    columnsToHide: getHiddenColumns(allColumns),
    columnsToShow: getShownColumns(allColumns),
    columnWidths: [],
    showFilters,
  };
};

const getHiddenColumns = (allColumns: ColDef[]): string => {
  const hiddenColumnHeaderNames: string[] = [];
  const hiddenColumns = allColumns?.filter((x) => x.hide);
  if (hiddenColumns) {
    hiddenColumns.forEach((colDef: ColDef) => {
      if (colDef.headerName) {
        hiddenColumnHeaderNames.push(colDef.headerName);
      }
    });
  }
  return JSON.stringify(hiddenColumnHeaderNames);
};

const getShownColumns = (allColumns: ColDef[]): string => {
  const shownColumnHeaderNames: string[] = [];
  const shownColumns = allColumns?.filter((x) => !x.hide);
  if (shownColumns) {
    shownColumns.forEach((colDef: ColDef) => {
      if (colDef.headerName) {
        shownColumnHeaderNames.push(colDef.headerName);
      }
    });
  }

  return JSON.stringify(shownColumnHeaderNames);
};

let allColumns = getBaseTaskDefaultColDefs(t, TaskTypes.PropertyInspectionTask);

export const postDefaultColumnsProperty = async (
  token: string,
  userId: string,
  userTableType: UserTableTypes,
  oldUserTableSetting: UserTableSettingDto | undefined,
): Promise<UserTableSettingDto> => {
  const taskType = getTaskTypeFromEntityName(userTableType);
  if (
    userTableType == 'PropertyInspectionTask' ||
    userTableType == 'InvestigationTask' ||
    userTableType == 'AccidentInspectionTask'
  )
    allColumns = getBaseTaskDefaultColDefs(t, taskType);
  else if (userTableType == 'VehicleTask')
    allColumns = getVehicleDefaultColDefs(t);
  else if (userTableType == 'Craftsman')
    allColumns = getCraftsmanDefaultColDefs(t);

  const userTableSettingDto = getUserTableSettingDto(
    userId,
    userTableType,
    allColumns,
    oldUserTableSetting?.showFilters ?? false,
  );
  // Maintain user order field if the column is maintained.
  const shownColumns = allColumns?.filter((x) => !x.hide);
  const orderFieldIsShown = oldUserTableSetting?.orderField
    ? shownColumns.find(
        (sc) => sc.field === oldUserTableSetting?.orderField,
      ) !== undefined
    : false;
  userTableSettingDto.orderField = orderFieldIsShown
    ? oldUserTableSetting?.orderField
    : undefined;
  userTableSettingDto.orderBy = orderFieldIsShown
    ? oldUserTableSetting?.orderBy
    : undefined;

  // Maintain user width configuration.
  if (oldUserTableSetting?.columnWidths) {
    userTableSettingDto.columnWidths = oldUserTableSetting.columnWidths;
  }

  // Maintain user colums order.
  if (oldUserTableSetting?.columnsOrder) {
    userTableSettingDto.columnsOrder = oldUserTableSetting.columnsOrder;
  }

  await postUserTableSetting(userTableSettingDto, token).then((result) => {
    if (result.status === 'success') {
      toast(
        ToastNotification({
          message: t('tableSettings.defaultedColumnsSuccess', {
            module: t(`tableSettingType.${userTableSettingDto.type}`),
          }),
          color: 'success',
        }),
      );
    } else if (result.status === 'error') {
      toast(
        ToastNotification({
          message: t('tableSettings.defaultedColumnsError', {
            module: t(`tableSettingType.${userTableSettingDto.type}`),
          }),
          color: 'danger',
        }),
      );
    }
  });
  return userTableSettingDto;
};

export const getSortingState = (
  user: RequestResult<UserDto>,
  userTableType: UserTableTypes,
): ISortingState | undefined => {
  if (user.status === 'success') {
    const userTableSetting = user.localValue.userTableSettings.find(
      (x) => x.type === userTableType,
    );
    if (userTableSetting && userTableSetting.orderField) {
      return {
        field: userTableSetting.orderField,
        direction: userTableSetting.orderBy ?? 'asc',
      };
    }
  } else return undefined;
};
