import {
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalText,
  BalButtonGroup,
  BalButton,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../../controller/controllers';

type RemoveInspectionPhotoConfirmationProps = {
  inspectionId: string;
  inspectionPhotoId: string;
  removeInspectionPhoto: (
    inspectionId: string,
    inspectionPhotoId: string,
  ) => Promise<void>;
};
export const RemoveInspectionPhotoConfirmationModal = ({
  inspectionId,
  inspectionPhotoId,
  removeInspectionPhoto,
}: RemoveInspectionPhotoConfirmationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">
          {t('inspectionTask.photos.deleteTitle')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>{t('inspectionTask.photos.deleteBody')}</BalText>
        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>

          <BalButton
            color="danger"
            onClick={async (event) => {
              if (removeInspectionPhoto && event.detail == 1) {
                await removeInspectionPhoto(inspectionId, inspectionPhotoId);
                balModalController.dismiss();
              }
            }}
          >
            {t('general.buttons.delete')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};
