import { BalButton } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import { BaseTask } from '../../../../types/types';
import { BaseTaskDelete, BaseTaskDeleteProps } from './base-task-delete';

export const BaseTaskDeleteButton = <BaseTaskType extends BaseTask>(
  props: BaseTaskDeleteProps<BaseTaskType>,
): JSX.Element => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: BaseTaskDelete,
      componentProps: {
        ...props,
      },
      cssClass: 'center-modal',
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={props.requiredCapabilities}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        className={`${props.className}`}
        onClick={(event) => event.detail == 1 && openModal()}
        size="small"
        color="danger"
      >
        {t('general.buttons.delete')}
      </BalButton>
    </WithCapabilities>
  );
};
