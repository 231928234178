import {
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalList,
  BalListItem,
  BalListItemIcon,
  BalIcon,
  BalListItemContent,
  BalListItemTitle,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';
import { EBRoutes } from '../../../router/router';
import { getEnabledModules } from '../../../utils/utilities';

interface VehicleTaskWizardSelectProps {
  navigateTo: (url: string) => void;
}
const VehicleTaskWizardSelect = ({
  navigateTo,
}: VehicleTaskWizardSelectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <BalModalHeader className="px-5 pb-0">
        <BalHeading className="pt-0 mt-0" space="none" level="h3">
          {t('general.buttons.createTask')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div>{t('vehicleTask.selectKindOfVehicleTask')}</div>
        <div className="mt-5">
          <BalList className="column py-0">
            {getEnabledModules().vehicleBonusCorrection && (
              <BalListItem
                clickable
                onClick={() => {
                  navigateTo(
                    EBRoutes.VEHICLE_BONUS_CORRECTION_TASK_WIZARD.template(),
                  );
                  balModalController.dismiss();
                }}
              >
                <BalListItemIcon className="min-height-0px">
                  <BalIcon name="plus" size="small" />
                </BalListItemIcon>
                <BalListItemContent>
                  <BalListItemTitle>
                    {t('home.createVehicleBonusCorrection')}
                  </BalListItemTitle>
                </BalListItemContent>
              </BalListItem>
            )}
            {getEnabledModules().riskAssessment && (
              <BalListItem
                clickable
                onClick={() => {
                  navigateTo(
                    EBRoutes.VEHICLE_RISK_ASSESSMENT_WIZARD.template(),
                  );
                  balModalController.dismiss();
                }}
              >
                <BalListItemIcon className="min-height-0px">
                  <BalIcon name="plus" size="small" />
                </BalListItemIcon>
                <BalListItemContent>
                  <BalListItemTitle>
                    {t('home.createVehicleRiskAssessment')}
                  </BalListItemTitle>
                </BalListItemContent>
              </BalListItem>
            )}
            <BalListItem
              clickable
              onClick={() => {
                navigateTo(EBRoutes.VEHICLE_INSPECTION_TASK_WIZARD.template());
                balModalController.dismiss();
              }}
            >
              <BalListItemIcon className="min-height-0px">
                <BalIcon name="plus" size="small" />
              </BalListItemIcon>
              <BalListItemContent>
                <BalListItemTitle>
                  {t('home.createMotorVehicleInspection')}
                </BalListItemTitle>
              </BalListItemContent>
            </BalListItem>
          </BalList>
        </div>
      </BalModalBody>
    </>
  );
};

export default VehicleTaskWizardSelect;
