import { TFunction } from 'i18next';
import { UserDto, UserMenuItemDto } from '../../../types/resource-models';
import { TaskTypes } from '../../../types/types';
import { Filter } from '../../view-menu/view-menu';
import { MenuItem } from '../../view-menu/view-menu-item';

const getVehicleMenuItems = (t: TFunction, user?: UserDto): MenuItem[] => {
  return [
    {
      isCustomFilter: false,
      type: 'VehicleTask',
      label: t('taskOverview.yourAssignedTasks'),
      filter: {
        status: ['Assigned'],
        assigneeOrCreatorUserId: user?.id,
        orderField: 'AppointmentEarliest',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'VehicleTask',
      label: t('taskOverview.yourInProgressTasks'),
      filter: {
        status: [
          'InProgressLocal',
          'InProgressVideo',
          'InProgressOffice',
          'InProgressWithPartner',
        ],
        assigneeOrCreatorUserId: user?.id,
        orderField: 'AppointmentEarliest,Status',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'VehicleTask',
      label: t('taskOverview.yourInProgressWithIndependentTasks'),
      filter: {
        status: ['InProgressWithIndependent'],
        assigneeOrCreatorUserId: user?.id,
        orderField: 'AppointmentEarliest',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'VehicleTask',
      label: t('taskOverview.garageAssignmentTasks'),
      filter: {
        status: ['Closed'],
        assigneeUserId: ['00000000-0000-0000-0000-000000000000'],
        orderField: 'CreatedAt,Status',
        orderBy: 'desc',
      },
    },
    {
      isCustomFilter: false,
      type: 'VehicleTask',
      label: t('taskOverview.allOpenTasks'),
      filter: {
        status: [
          'Assigned',
          'InProgressLocal',
          'InProgressVideo',
          'InProgressOffice',
          'InProgressWithPartner',
        ],
        orderField: 'CreatedAt,Status',
        orderBy: 'desc',
      },
    },
    {
      isCustomFilter: false,
      type: 'VehicleTask',
      label: t('taskOverview.draftTasks'),
      filter: {
        status: ['New'],
        orderField: 'CreatedAt',
        orderBy: 'desc',
      },
    },
    {
      isCustomFilter: false,
      type: 'VehicleTask',
      label: t('taskOverview.allTasks'),
      filter: {
        creatorUserId: [],
        assigneeUserId: [],
        orderField: 'CreatedAt,Status',
        orderBy: 'desc',
      },
    },
    {
      isCustomFilter: false,
      type: 'VehicleTask',
      label: t('taskOverview.archivedTasks'),
      filter: {
        status: ['Closed', 'Rejected'],
        orderField: 'CreatedAt,Status',
        orderBy: 'desc',
      },
    },
  ];
};

export const getUserVehicleMenuItems = (
  t: TFunction,
  user?: UserDto,
): MenuItem[] => {
  const baseMenuItems = getVehicleMenuItems(t, user);
  // Add custom menu items.
  if (user) {
    user.userMenuItems.forEach((userMenuItemDto: UserMenuItemDto) => {
      if (userMenuItemDto.type === TaskTypes[TaskTypes.VehicleTask]) {
        const filter: Filter = JSON.parse(userMenuItemDto.filter);
        // Custom menu items sort info is not compatible with user setting sort info.
        filter.orderBy = undefined;
        filter.orderField = undefined;
        const menuItem: MenuItem = { ...userMenuItemDto, filter: filter };
        baseMenuItems.push(menuItem);
      }
    });
  }
  return baseMenuItems;
};

export const getDefaultVehicleMenuItem = (
  t: TFunction,
  user?: UserDto,
): MenuItem => {
  return getVehicleMenuItems(t, user)[0];
};
