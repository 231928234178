import { useContext, useState } from 'react';
import { UserContext } from '../context';
import { AppModuleVisibility } from '../types/types';
import { UserLanguageChangeCard } from '../features/user';
import { WithCapabilities } from '../components/authorization';
import { Avatar } from '../components/ui';
import { UserManagementCard } from '../features/admin-tools/components/admin-user-management-card';
import { getDisplayNameFromUser } from '../utils/utilities';
import { InvestigationTaskManagementCard } from '../features/admin-tools/components/admin-investigation-task-management-card';
import { PropertyCraftsmanManagementCard } from '../features/admin-tools/components/admin-property-craftsman-management-card';
import { GarageCraftsmanManagementCard } from '../features/admin-tools/components/admin-garage-craftsman-management-card';

type ProfileProps = {
  appModuleVisability: AppModuleVisibility;
};

const ProfileView = ({
  appModuleVisability: appModuleVisability,
}: ProfileProps): JSX.Element => {
  const { user, setUser } = useContext(UserContext);
  const [exportButtonLoadingState, setExportButtonLoadingState] =
    useState(false);

  if (user.status === 'success' || user.status === 'loading-with-value') {
    return (
      <div className="is-full-width is-flex is-justify-content-center">
        <div className="container is-fullhd mt-4 mb-4 columns is-full-width is-multiline is-vcentered">
          <div className="column is-full is-flex">
            <Avatar user={user.value} size="180" />
            <div className="ml-6">
              <h1 className="title is-1 has-text-black">
                {getDisplayNameFromUser(user.value)}
              </h1>
            </div>
          </div>
          <div className="columns is-multiline column is-full">
            <div className="column is-half">
              <UserLanguageChangeCard user={user} setUser={setUser} />
            </div>
            <WithCapabilities
              requiredCapabilities={['BulkOperationsPropertyInspectionTask']}
              capabilityEnabled={appModuleVisability.userManagement}
            >
              <div className="column is-half">
                <UserManagementCard
                  exportButtonLoadingState={exportButtonLoadingState}
                  setExportButtonLoadingState={setExportButtonLoadingState}
                />
              </div>
            </WithCapabilities>
          </div>
          <div className="columns is-multiline column is-full">
            <WithCapabilities
              requiredCapabilities={['CreateNetworkPartner']}
              capabilityEnabled={appModuleVisability.networkPartner}
            >
              <div className="column is-half">
                <GarageCraftsmanManagementCard />
              </div>
              <div className="column is-half">
                <PropertyCraftsmanManagementCard />
              </div>
            </WithCapabilities>
          </div>
          <div className="columns is-multiline is-full column">
            <WithCapabilities
              requiredCapabilities={['CreateFraudInspectionTask']}
              capabilityEnabled={appModuleVisability.investigationInspection}
            >
              <div className="column is-half">
                <InvestigationTaskManagementCard language={'De'} />
              </div>
            </WithCapabilities>
            <WithCapabilities
              requiredCapabilities={['CreateFraudInspectionTask']}
              capabilityEnabled={appModuleVisability.investigationInspection}
            >
              <div className="column is-half">
                <InvestigationTaskManagementCard language={'Fr'} />
              </div>
            </WithCapabilities>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};

export default ProfileView;
