import { AffectedPartner } from '../../../../types/types';
import { Translation } from 'react-i18next';
import { Globals } from '../../../../utils';
import { InfoDisplay } from '../../../../components/ui';
import { BalHeading } from '@baloise/design-system-components-react';

type ContactInformationProps = {
  contact: AffectedPartner;
};

export const BaseTaskContactInformation = ({
  contact,
}: ContactInformationProps): JSX.Element => {
  const hasRole = contact.role !== Globals.noneValue;
  const partnerFunction =
    !hasRole &&
    (contact.partner.type === 'person'
      ? contact.partner.person.function
      : contact.partner.company.function);

  return (
    <Translation>
      {(t) => {
        return (
          contact && (
            <>
              <BalHeading level="h5" space="none" className="mt-5">
                {t('general.contact.contactInformation')}
              </BalHeading>
              {hasRole ? (
                <InfoDisplay
                  title={t('general.role')}
                  text={t(`partnerRole.${contact.role}`)}
                  className="pb-1"
                />
              ) : (
                partnerFunction && (
                  <InfoDisplay
                    title={t('general.function')}
                    text={partnerFunction}
                    className="pb-1"
                  />
                )
              )}
              <InfoDisplay
                title={t('general.name')}
                text={
                  contact.partner.type === 'person'
                    ? contact.partner.person.displayName
                    : contact.partner.company.displayName
                }
                className="pb-1"
              />
              <InfoDisplay
                title={t('general.email')}
                text={
                  contact.partner.type === 'person'
                    ? contact.partner.person.email ?? ''
                    : contact.partner.company.email ?? ''
                }
                className="pb-1"
              />
              <InfoDisplay
                title={t('general.phone')}
                text={
                  contact.partner.type === 'person'
                    ? contact.partner.person.phoneNumber ?? 'no-availability?'
                    : contact.partner.company.phoneNumber ?? 'no-availability?'
                }
                className="pb-1"
              />
            </>
          )
        );
      }}
    </Translation>
  );
};
