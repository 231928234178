import { Link } from 'react-router-dom';
import { EBRoutes } from '../../router/router';
import { BalText } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from '../../context';
import { Avatar } from '../ui/avatar';
import { getDisplayNameFromUser } from '../../utils/utilities';

export const NavbarUser = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  if (user.status === 'success' || user.status === 'loading-with-value') {
    const name = getDisplayNameFromUser(user.value);
    return (
      <>
        <a className="navbar-link">
          <strong>{name}</strong>
          <Avatar user={user.value} className="ml-2 mr-2" size="40" />
        </a>
        <div className="navbar-dropdown navbar">
          <Link className="navbar-item" to={EBRoutes.PROFILE.create({})}>
            <BalText>{t('appBar.myProfile')}</BalText>
          </Link>
          <hr className="navbar-divider" />
          <Link className="navbar-item" to={EBRoutes.LOGOUT.create({})}>
            <BalText>{t('appBar.logout')}</BalText>
          </Link>
        </div>
      </>
    );
  } else {
    return (
      <>
        <a className="navbar-link">
          <div>
            <BalText
              className="is-flex is-justify-content-flex-end"
              size="small"
            ></BalText>
            <BalText
              className="is-flex is-justify-content-flex-end"
              size="small"
            ></BalText>
          </div>
        </a>
        <div className="navbar-dropdown">
          <Link className="navbar-item" to={EBRoutes.PROFILE.create({})}>
            <BalText>{t('appBar.myProfile')}</BalText>
          </Link>
          <Link className="navbar-item" to={EBRoutes.HOME.create({})}>
            <BalText>{t('appBar.settings')}</BalText>
          </Link>
          <hr className="navbar-divider" />
          <Link className="navbar-item" to={EBRoutes.LOGOUT.create({})}>
            <BalText>{t('appBar.logout')}</BalText>
          </Link>
        </div>
      </>
    );
  }
};
