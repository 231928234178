import React, { useContext, useEffect, useState } from 'react';
import { useToken } from '../../../hooks';
import i18n from '../../../i18n/config';
import { RequestResult } from '../../../data';
import {
  AppCapabilityNames,
  BaloiseCodeDto,
  ResponseOfUserDto,
  UserDto,
} from '../../../types/resource-models';
import {
  getUserProfile,
  getUserPhoto,
  getUsers,
  getUserById,
} from './requests';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../components/toast-notification';
import { cachedKey, Globals } from '../../../utils';
import produce from 'immer';
import { UserFilterCriteria } from '../../../types/resource-models';
import { UserContext } from '../../../context';
import { CachedData } from '../../../types/types';

export type CombinedState = {
  users: RequestResult<ResponseOfUserDto>;
  filter: UserFilterCriteria;
};

export function useUsers(
  filter?: UserFilterCriteria,
): [
  CombinedState,
  React.Dispatch<React.SetStateAction<CombinedState>>,
  number,
  React.Dispatch<React.SetStateAction<number>>,
] {
  const token = useToken();

  const [combinedState, setCombinedState] = useState<CombinedState>({
    users: { status: 'initial' },
    filter: filter ?? Globals.filterDefault,
  });
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (
      token !== '' &&
      (combinedState.users.status === 'initial' ||
        combinedState.users.status === 'loading-with-value')
    ) {
      getUsers(token, combinedState.filter).then((result) =>
        setCombinedState(
          produce(combinedState, (draftState) => {
            draftState.users = result;
          }),
        ),
      );
    }
  }, [token, page, combinedState]);

  return [combinedState, setCombinedState, page, setPage];
}

export function useUser(): [
  RequestResult<UserDto>,
  React.Dispatch<RequestResult<UserDto>>,
] {
  const token = useToken();
  const [user, setUser] = useState<RequestResult<UserDto>>({
    status: 'initial',
  });

  useEffect(() => {
    async function getUserAndSetLanguage() {
      if ((user.status === 'initial' || user.status === 'error') && token) {
        const result = await getUserProfile(token);
        if (
          result.status === 'success' &&
          i18n.language !== result.value.language.toLowerCase()
        ) {
          await i18n.changeLanguage(result.value.language.toLowerCase());
        } else if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
        setUser(result);
      }
    }
    getUserAndSetLanguage();
  }, [user.status, token]);

  return [user, setUser];
}

export function useAvatar(userId?: string): RequestResult<string> {
  const token = useToken();
  const [avatar, setAvatar] = useState<RequestResult<string>>({
    status: 'initial',
  });

  useEffect(() => {
    if (
      (avatar.status === 'initial' || avatar.status === 'error') &&
      token &&
      userId
    ) {
      getUserPhoto(userId, token).then((photo) => {
        setAvatar(photo);
      });
    }
  }, [avatar, setAvatar, userId, token]);

  return avatar;
}

export function useDropdownUsers({
  appCapabilityNames,
}: {
  appCapabilityNames?: AppCapabilityNames[];
}): RequestResult<UserDto[]> | undefined {
  const token = useToken();
  const [users, setUsers] = useState<RequestResult<UserDto[]> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (token !== '' && !users) {
      setUsers({ status: 'loading' });
      const filter = {
        ...Globals.filterDefault,
        // TODO: Pagination on filter for maxitemcount reassignview
        maxItemCount: 1000,
        appCapabilities: appCapabilityNames,
        orderField: 'DisplayName',
      };

      // Before asking api for the users look for them in the local storage.
      let loadedFromCache = false;
      const localStorageKey: string = cachedKey(filter);
      const stringUsers: string | null = localStorage.getItem(localStorageKey);
      if (stringUsers) {
        const cachedUsers: CachedData<UserDto> = JSON.parse(stringUsers);
        if (
          new Date(cachedUsers.expiration).getTime() >= new Date().getTime()
        ) {
          setUsers({
            status: 'success',
            value: cachedUsers.items,
            localValue: cachedUsers.items,
          });
          loadedFromCache = true;
        }
      }

      if (!loadedFromCache) {
        getUsers(token, filter).then((result) => {
          if (result.status === 'success') {
            // Store the users in the cache that will expire in 1 hour.
            localStorage.setItem(
              localStorageKey,
              JSON.stringify(
                new CachedData(
                  result.value.entities,
                  new Date(new Date().getTime() + 3600000),
                ),
              ),
            );

            setUsers({
              status: 'success',
              value: result.value.entities,
              localValue: result.localValue.entities,
            });
          } else if (result.status === 'error') {
            setUsers(result);
            toast(ErrorToast(result.errorValue));
          }
        });
      }
    }
  }, [appCapabilityNames, token, users]);

  return users;
}

export function useUserById(userId: string): RequestResult<UserDto> {
  const token = useToken();
  const [user, setUser] = useState<RequestResult<UserDto>>({
    status: 'initial',
  });

  if (
    token !== '' &&
    (user.status === 'initial' || user.status === 'loading-with-value')
  ) {
    getUserById(userId, token).then((result) => {
      if (result.status === 'success') {
        setUser({
          status: 'success',
          value: result.value,
          localValue: result.localValue,
        });
      } else if (result.status === 'error') {
        setUser(result);
      }
    });
  }
  return user;
}

export const useDescriptionOfBaloiseCode = (
  baloiseCode?: BaloiseCodeDto,
): string => {
  const { user } = useContext(UserContext);
  if (!baloiseCode) return '';
  const language = user.status === 'success' ? user.value.language : 'De';
  switch (language) {
    case 'De':
      return baloiseCode.de;
    case 'Fr':
      return baloiseCode.fr ?? '';
    case 'It':
      return baloiseCode.it ?? '';
    default:
      return baloiseCode.de;
  }
};
