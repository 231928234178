import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import { useToken } from '../../../hooks';
import { Globals } from '../../../utils';
import {
  CombinedState,
  CombinedStateProps,
  initialCombinedState,
} from '../../base-task/data/hooks';
import { RequestResult } from '../../../data';
import {
  BaseTaskChangeDto,
  BaseTaskFilterCriteria,
  InspectionDto,
  PropertyInspectionTaskJournalFilterCriteria,
  ResponseOfPropertyInspectionTaskJournalListDto,
  SortOrder,
  UserDto,
} from '../../../types/resource-models';
import {
  PropertyInspectionTask,
  PropertyInspectionTaskFilter,
} from '../../../types/types';
import {
  getInspectionTasks,
  getPropertyInspectionTask,
  getPropertyInspectionTaskAllHistory,
} from './requests';
import { getMenuItems } from '../components/property-inspection-task-view-menu-items';
import { convertUtcToLocalWithoutTime } from '../../../utils/date';
import { t } from 'i18next';

export type PropertyInspectionTaskJournalCombinedState = {
  journals: RequestResult<ResponseOfPropertyInspectionTaskJournalListDto>;
  filter: PropertyInspectionTaskJournalFilterCriteria;
  defaultFilter: PropertyInspectionTaskJournalFilterCriteria;
  label: string;
};

export function usePropertyInspectionTask(): [
  RequestResult<PropertyInspectionTask>,
  React.Dispatch<RequestResult<PropertyInspectionTask>>,
] {
  const [propertyInspectionTaskResponse, setPropertyInspectionTaskResponse] =
    useState<RequestResult<PropertyInspectionTask>>({
      status: 'initial',
    });

  return [propertyInspectionTaskResponse, setPropertyInspectionTaskResponse];
}

export const filterYourOpenTasks = (
  user: UserDto,
): Partial<PropertyInspectionTaskFilter> => ({
  status: [
    'Assigned',
    'InProgressLocal',
    'InProgressOffice',
    'InProgressVideo',
    'InProgressWithPartner',
  ],
  assignee: user,
  orderField: 'Status,CreatedAt',
  orderBy: 'asc',
});

export function useInspectionTasksFromApi(): CombinedStateProps {
  const { t } = useTranslation();
  const token = useToken();
  const { user } = useContext(UserContext);

  const [combinedState, setCombinedState] =
    useState<CombinedState>(initialCombinedState);

  useEffect(() => {
    if (
      (user.status === 'success' || user.status === 'loading-with-value') &&
      combinedState.tasks.status === 'initial'
    ) {
      let customOrderField: string | undefined = undefined;
      let customOrderBy: SortOrder | undefined = undefined;
      if (user.status === 'success') {
        const userTableSetting = user.localValue.userTableSettings.find(
          (x) => x.type === 'PropertyInspectionTask',
        );
        if (userTableSetting?.orderField) {
          customOrderField = userTableSetting?.orderField;
          customOrderBy = userTableSetting?.orderBy;
        }
      }
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.tasks = { status: 'loading' };
          // apply first quick filter from menu as default filter
          const menuItems = getMenuItems(t, user.value);
          const emptyFilter = Globals.filterDefault;
          draftState.defaultFilter = {
            ...emptyFilter,
            ...(menuItems[0].filter as Partial<BaseTaskFilterCriteria>),
          };
          draftState.filter = customOrderField
            ? {
                ...draftState.defaultFilter,
                orderField: customOrderField,
                orderBy: customOrderBy ?? 'asc',
              }
            : draftState.defaultFilter;
          draftState.label = menuItems[0].label;
        }),
      );
    }
  }, [user, combinedState, t]);

  useEffect(() => {
    if (
      token !== '' &&
      (combinedState.tasks.status === 'loading' ||
        combinedState.tasks.status === 'loading-with-value')
    ) {
      getInspectionTasks(
        token,
        combinedState.filter as BaseTaskFilterCriteria,
      ).then((result) =>
        setCombinedState(
          produce(combinedState, (draftState) => {
            draftState.tasks = result;
          }),
        ),
      );
    }
  }, [user, token, combinedState]);

  return { combinedState, setCombinedState };
}

export function usePropertyInspectionTaskFromApi(
  taskId: string,
): [
  RequestResult<PropertyInspectionTask>,
  React.Dispatch<RequestResult<PropertyInspectionTask>>,
] {
  const token = useToken();
  const [propertyInspectionTaskResponse, setPropertyInspectionTaskResponse] =
    useState<RequestResult<PropertyInspectionTask>>({
      status: 'loading',
    });

  useEffect(() => {
    setPropertyInspectionTaskResponse({ status: 'loading' });
    if (token !== '' && taskId !== '') {
      getPropertyInspectionTask(token, taskId).then(
        setPropertyInspectionTaskResponse,
      );
    }
  }, [token, taskId]);

  return [propertyInspectionTaskResponse, setPropertyInspectionTaskResponse];
}

export function usePropertyInspectionTaskHistoryFromApi(
  taskId: string,
): [
  RequestResult<BaseTaskChangeDto[]>,
  React.Dispatch<RequestResult<BaseTaskChangeDto[]>>,
] {
  const token = useToken();
  const [propertyInspectionTaskChanges, setPropertyInspectionTaskChanges] =
    useState<RequestResult<BaseTaskChangeDto[]>>({
      status: 'initial',
    });
  useEffect(() => {
    if (token !== '' && propertyInspectionTaskChanges.status !== 'success') {
      if (propertyInspectionTaskChanges.status !== 'loading-with-value')
        setPropertyInspectionTaskChanges({ status: 'loading' });
      getPropertyInspectionTaskAllHistory(token, taskId).then((result) => {
        if (result.status === 'success') {
          setPropertyInspectionTaskChanges({
            status: 'success',
            value: result.value,
            localValue: result.localValue,
          });
        } else if (result.status === 'error') {
          setPropertyInspectionTaskChanges(result);
        }
      });
    }
  }, [taskId, token, propertyInspectionTaskChanges.status]);
  return [propertyInspectionTaskChanges, setPropertyInspectionTaskChanges];
}

export const initialInvestigationTaskJournalCombinedState: PropertyInspectionTaskJournalCombinedState =
  {
    journals: { status: 'initial' },
    defaultFilter: { ...Globals.filterDefault, maxItemCount: 10 },
    filter: { ...Globals.filterDefault, maxItemCount: 10 },
    label: '-',
  };

export const getInitialPropertyInspectionTaskJournalCombinedState = (
  propertyInspectionTaskId: string,
): PropertyInspectionTaskJournalCombinedState => {
  const filter: PropertyInspectionTaskJournalFilterCriteria = {
    ...Globals.filterDefault,
    maxItemCount: 10,
    ...({
      propertyInspectionTaskId,
    } as Partial<PropertyInspectionTaskJournalFilterCriteria>),
  };

  return produce(initialInvestigationTaskJournalCombinedState, (draftState) => {
    draftState.defaultFilter = {
      ...Globals.filterDefault,
      maxItemCount: 10,
      ...(filter as Partial<PropertyInspectionTaskJournalFilterCriteria>),
    };
    draftState.filter = draftState.defaultFilter;
    draftState.label = '-';
  });
};

export const getInspectionDescription = (inspection?: InspectionDto) => {
  if (!inspection) return '';
  const date = inspection.inspectionDate
    ? convertUtcToLocalWithoutTime(inspection.inspectionDate)
    : '';
  const status = inspection.status
    ? t(`inspectionTask.inspection.inspectionStates.${inspection.status}`)
    : '';
  const id = inspection.id.substring(0, 8);
  return `${date} ${status} (${id})`;
};
