import {
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalButtonGroup,
  BalTextarea,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { useToken } from '../../../../hooks';
import { balModalController } from '../../../../controller/controllers';
import produce from 'immer';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BaseTask } from '../../../../types/types';

type BaseTaskRejectProps = {
  task: BaseTask;
  onRejectSuccess?: (task: RequestResult<BaseTask>) => void;
  patchBaseTask: (
    baseTask: BaseTask,
    baseTaskNew: BaseTask,
    bearerToken: string,
  ) => Promise<RequestResult<BaseTask>>;
};

export const BaseTaskRejectModal = ({
  task,
  onRejectSuccess,
  patchBaseTask,
}: BaseTaskRejectProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const [remarks, setRemarks] = useState<string | undefined>(task.remarks);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' });
  const [submitting, setSubmitting] = useState(false);

  return (
    <form
      onSubmit={handleSubmit(() => {
        if (!submitting) {
          setSubmitting(true);
          onRejectSuccess && onRejectSuccess({ status: 'loading' });
          patchBaseTask(
            task,
            produce(task, (draftState) => {
              draftState.status = 'Rejected';
              draftState.remarks = remarks ?? '';
            }),
            token,
          ).then((task: RequestResult<BaseTask>) => {
            if (task.status === 'success' && onRejectSuccess) {
              onRejectSuccess(task);
            }
            balModalController.dismiss();
            setSubmitting(false);
          });
        }
      })}
    >
      <BalModalHeader>
        <BalHeading level="h3">{t('reject.taskRejectionTitle')}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div className="columns">
          <div className="column pr-0"></div>
          <div className="column is-narrow pl-0 pt-0"></div>
        </div>
        <div className="columns is-3 is-multiline px-4">
          {t('reject.taskRejectionNotice')}
        </div>
        <div className="mb-2 mt-5">
          <BalHeading space="none" level="h4" color="info" subtitle>
            {t('general.claim.remarks')}
          </BalHeading>
          <BalTextarea
            {...register('remarks', {
              required: true,
              value: remarks,
            })}
            className="mt-5"
            placeholder={t('general.claim.remarks')}
            value={remarks}
            rows={8}
            onBalChange={(e: CustomEvent<string | undefined>) => {
              if (e.detail || e.detail === '') {
                setRemarks(e.detail);
              }
            }}
          />
          {errors.remarks && (
            <BalText className="ml-4" color="danger" size="small" bold>
              {t('error.mandatoryField')}
            </BalText>
          )}
        </div>
        <BalButtonGroup position="right" className="mt-8">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton color="warning" elementType="submit" disabled={submitting}>
            {t('general.buttons.reject')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </form>
  );
};
