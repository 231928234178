import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { AccidentInspectionTask } from '../../../../types/types';
import { balModalController } from '../../../../controller/controllers';
import {
  DisabableButton,
  WithCapabilities,
} from '../../../../components/authorization';
import { BaseTaskCompletion } from '../../../base-task';
import { patchAccidentInspectionTask } from '../../data/requests';

type AccidentInspectionTaskCompletionProps = {
  task: AccidentInspectionTask;
  onChangeSuccess: (task: RequestResult<AccidentInspectionTask>) => void;
};

const AccidentInspectionTaskCompletion = (
  props: AccidentInspectionTaskCompletionProps,
): JSX.Element => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: BaseTaskCompletion,
      componentProps: { ...props, patchBaseTask: patchAccidentInspectionTask },
      cssClass: 'center-modal',
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditAccidentInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <DisabableButton
        onClick={(event) => event.detail == 1 && openModal()}
        className="ml-3"
        size="small"
        color="success"
      >
        {t('general.buttons.close')}
      </DisabableButton>
    </WithCapabilities>
  );
};

export default AccidentInspectionTaskCompletion;
