import {
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalText,
  BalButtonGroup,
  BalButton,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';

type AssignInspectorConfirmationProps = {
  inspectorName: string;
  assignInspector: () => void;
};
export const AssignInspectorConfirmation = (
  props: AssignInspectorConfirmationProps,
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">{t('general.buttons.reassign')}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>
          {t('general.assignInspectorConfirmation.body', {
            inspectorName: props.inspectorName,
          })}
        </BalText>
        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>

          <BalButton
            color="danger"
            onClick={(event) => {
              if (event.detail == 1) {
                if (props.assignInspector) {
                  props.assignInspector();
                }
                balModalController.dismiss();
              }
            }}
          >
            {t('general.buttons.reassign')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};
