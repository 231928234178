import produce from 'immer';
import { Dispatch } from 'react';
import { Translation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  EditCheckboxModal,
  EditNumberModal,
  EditTextModal,
} from '../../../components/input';
import { EditSelectionModal } from '../../../components/input/edit-selection-modal';
import { ErrorToast } from '../../../components/toast-notification';
import { InfoDisplay } from '../../../components/ui';
import { balModalController } from '../../../controller/controllers';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import { TranslatedSelection, VehicleTask } from '../../../types/types';
import { IsNullOrUndefined } from '../../../utils/utilities';
import { patchVehicleTask } from '../data/requests';
import { Compensation } from '../../../types/resource-models';
import { Globals } from '../../../utils';

export const PolicyInfo = ({
  task,
  setTask,
}: {
  task: VehicleTask;
  setTask?: Dispatch<RequestResult<VehicleTask>>;
}): JSX.Element => {
  const token = useToken();
  const hideForMotorVehicleLiability =
    task.claim.branch == 'MotorVehicleLiability';
  return (
    <Translation>
      {(t) => {
        const compensation: TranslatedSelection[] = Globals.compensation.map(
          (compensation) => ({
            value: compensation,
            translatedValue: t(`compensationType.${compensation}`),
          }),
        );
        return (
          <div>
            <InfoDisplay
              title={t('vehicleTask.expertiseOrderReport.insuranceConditions')}
              text={task.claim.policy.conditions}
              editComponent={
                setTask && (
                  <EditTextModal
                    isRequired={true}
                    label={t(
                      'vehicleTask.expertiseOrderReport.insuranceConditions',
                    )}
                    placeholder={t(
                      'vehicleTask.expertiseOrderReport.insuranceConditions',
                    )}
                    value={task.claim.policy.conditions}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.claim.policy.conditions = value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            {!hideForMotorVehicleLiability && (
              <>
                <InfoDisplay
                  title={t('vehicleTask.expertiseOrderReport.leasing')}
                  text={
                    IsNullOrUndefined(task.claim.policy.leasing)
                      ? ' '
                      : t(`tristate.${task.claim.policy.leasing ?? false}`)
                  }
                  editComponent={
                    setTask && (
                      <EditCheckboxModal
                        label={t('vehicleTask.expertiseOrderReport.leasing')}
                        text={t('vehicleTask.expertiseOrderReport.leasing')}
                        value={task.claim.policy.leasing ?? false}
                        requiredCapabilities="EditVehicleTask"
                        onSave={(value: boolean) => {
                          patchVehicleTask(
                            task,
                            produce(task, (draftState) => {
                              draftState.claim.policy.leasing = value;
                            }),
                            token,
                          ).then((result) => {
                            if (result.status === 'success') {
                              setTask(result);
                              balModalController.dismiss();
                            } else if (result.status === 'error') {
                              toast(ErrorToast(result.errorValue));
                            }
                          });
                        }}
                      />
                    )
                  }
                />
                <InfoDisplay
                  title={t('vehicleTask.expertiseOrderReport.blanketSign')}
                  text={
                    IsNullOrUndefined(task.claim.policy.blanketSign)
                      ? ' '
                      : t(`tristate.${task.claim.policy.blanketSign ?? false}`)
                  }
                  editComponent={
                    setTask && (
                      <EditCheckboxModal
                        label={t(
                          'vehicleTask.expertiseOrderReport.blanketSign',
                        )}
                        text={t('vehicleTask.expertiseOrderReport.blanketSign')}
                        value={task.claim.policy.blanketSign ?? false}
                        requiredCapabilities="EditVehicleTask"
                        onSave={(value: boolean) => {
                          patchVehicleTask(
                            task,
                            produce(task, (draftState) => {
                              draftState.claim.policy.blanketSign = value;
                            }),
                            token,
                          ).then((result) => {
                            if (result.status === 'success') {
                              setTask(result);
                              balModalController.dismiss();
                            } else if (result.status === 'error') {
                              toast(ErrorToast(result.errorValue));
                            }
                          });
                        }}
                      />
                    )
                  }
                />
                <InfoDisplay
                  title={t('vehicleTask.expertiseOrderReport.parkingDamage')}
                  text={
                    IsNullOrUndefined(task.claim.policy.parkingDamage)
                      ? ' '
                      : t(
                          `tristate.${
                            task.claim.policy.parkingDamage ?? false
                          }`,
                        )
                  }
                  editComponent={
                    setTask && (
                      <EditCheckboxModal
                        label={t(
                          'vehicleTask.expertiseOrderReport.parkingDamage',
                        )}
                        text={t(
                          'vehicleTask.expertiseOrderReport.parkingDamage',
                        )}
                        value={task.claim.policy.parkingDamage ?? false}
                        requiredCapabilities="EditVehicleTask"
                        onSave={(value: boolean) => {
                          patchVehicleTask(
                            task,
                            produce(task, (draftState) => {
                              draftState.claim.policy.parkingDamage = value;
                            }),
                            token,
                          ).then((result) => {
                            if (result.status === 'success') {
                              setTask(result);
                              balModalController.dismiss();
                            } else if (result.status === 'error') {
                              toast(ErrorToast(result.errorValue));
                            }
                          });
                        }}
                      />
                    )
                  }
                />
                <InfoDisplay
                  title={t('vehicleTask.expertiseOrderReport.replacementCarYN')}
                  text={
                    IsNullOrUndefined(task.replacementVehicleInsured)
                      ? ' '
                      : t(`tristate.${task.replacementVehicleInsured ?? false}`)
                  }
                  editComponent={
                    setTask && (
                      <EditCheckboxModal
                        label={t(
                          'vehicleTask.expertiseOrderReport.replacementCarYN',
                        )}
                        text={t(
                          'vehicleTask.expertiseOrderReport.replacementCarYN',
                        )}
                        value={task.replacementVehicleInsured ?? false}
                        requiredCapabilities="EditVehicleTask"
                        onSave={(value: boolean) => {
                          patchVehicleTask(
                            task,
                            produce(task, (draftState) => {
                              draftState.replacementVehicleInsured = value;
                            }),
                            token,
                          ).then((result) => {
                            if (result.status === 'success') {
                              setTask(result);
                              balModalController.dismiss();
                            } else if (result.status === 'error') {
                              toast(ErrorToast(result.errorValue));
                            }
                          });
                        }}
                      />
                    )
                  }
                />
              </>
            )}
            <InfoDisplay
              title={t('vehicleTask.expertiseOrderReport.compensation')}
              text={t(`compensationType.${task.claim.policy.compensation}`)}
              editComponent={
                setTask && (
                  <EditSelectionModal
                    label={t('vehicleTask.expertiseOrderReport.compensation')}
                    placeholder={t(
                      'vehicleTask.expertiseOrderReport.compensation',
                    )}
                    value={{
                      value: task.claim.policy.compensation,
                      translatedValue: t(
                        `compensationType.${task.claim.policy.compensation}`,
                      ),
                    }}
                    values={compensation}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: Compensation) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.claim.policy.compensation = value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            <InfoDisplay
              title={t('vehicleTask.expertiseOrderReport.deduction')}
              text={task.claim.policy.deduction?.toString() ?? ''}
              editComponent={
                setTask && (
                  <EditNumberModal
                    isRequired={true}
                    label={t('vehicleTask.expertiseOrderReport.deduction')}
                    placeholder={t(
                      'vehicleTask.expertiseOrderReport.deduction',
                    )}
                    value={task.claim.policy.deduction?.toString()}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.claim.policy.deduction =
                              value as unknown as number;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
          </div>
        );
      }}
    </Translation>
  );
};
