import {
  BalButton,
  BalButtonGroup,
  BalDatepicker,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AppCapabilityNames } from '../../types/resource-models';
import { WithCapabilities } from '../authorization/with-capabilities';

type EditDateModalProps = {
  label: string;
  placeholder: string;
  value?: string;
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  isRequired: boolean;
  onSave(value: string | undefined, reportToAdos: boolean): void;
};

const EditDateModalContent = (props: EditDateModalProps): JSX.Element => {
  const { t } = useTranslation();

  const [dateValue, setDateValue] = useState<string | undefined>(props.value);

  const [requiredError, setRequiredError] = useState<boolean>(false);
  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {props.label}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalDatepicker
          className="no-icon"
          placeholder={
            props.isRequired ? `${props.placeholder} *` : props.placeholder
          }
          value={dateValue}
          onBalChange={(event: CustomEvent<string | undefined>) => {
            const value = event.detail;
            if (!props.isRequired || (value && value.length > 0)) {
              setRequiredError(false);
            } else {
              setRequiredError(true);
            }
            setDateValue(value);
          }}
        />
        {requiredError && (
          <BalText className="mt-2" color="danger" size="small" bold>
            {t('error.mandatoryField')}
          </BalText>
        )}
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            color="info"
            onClick={(event) => {
              if (dateValue == props.value && event.detail == 1) {
                balModalController.dismiss();
              } else if (!requiredError && event.detail == 1) {
                props.onSave(dateValue, false);
              }
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export const EditDateModal = (props: EditDateModalProps): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditDateModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
};
