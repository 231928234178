import {
  BalButton,
  BalCheckbox,
  BalField,
  BalFieldMessage,
  BalHeading,
  BalIcon,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { balModalController } from '../../../controller/controllers';
import { RequestResult } from '../../../data';
import { Craftsman } from '../../../types/types';
import { useToken } from '../../../hooks';
import { concat, capitalize } from '../../../utils';
import { archiveCraftsman } from '../data/requests';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import { BalTextField } from '../../../components/input';
import { WithCapabilities } from '../../../components/authorization';
import { refreshCraftsmanList } from '../data/hooks';
import { balIconMenuDots } from '@baloise/design-system-icons';

type PartnerProps = {
  className?: string;
  partner: RequestResult<Craftsman>;
  setPartner: React.Dispatch<RequestResult<Craftsman>>;
};

const DeletePartnerModal = ({
  partner,
  setPartner,
}: PartnerProps): JSX.Element => {
  const { handleSubmit, control } = useForm({ mode: 'onChange' });
  const { t } = useTranslation();
  const token = useToken();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);
  if (partner.status === 'success') {
    const craftsman =
      partner.localValue.type === 'propertyCraftsman'
        ? partner.localValue.propertyCraftsman
        : partner.localValue.vehicleCraftsman;

    const partnerName =
      craftsman.company.additionalName == undefined
        ? craftsman.company.name
        : concat([craftsman.company.name, craftsman.company.additionalName]);
    return (
      <>
        <BalModalHeader>
          <BalHeading level="h4" subtitle space="none" inverted>
            {concat([
              t('networkPartners.partner'),
              concat(['«', partnerName, '»'], 'none'),
              t('general.buttons.delete'),
            ])}
          </BalHeading>
        </BalModalHeader>
        <BalModalBody>
          <form
            className="columns is-multiline "
            onSubmit={handleSubmit(async (data) => {
              if (!submitting) {
                if (data.deletionConfirmation) {
                  setSubmitting(true);
                  const result = await archiveCraftsman(token, craftsman.id);
                  setSubmitting(false);
                  if (result.status === 'success-no-value') {
                    refreshCraftsmanList();
                    toast(
                      ToastNotification({
                        message: t('networkPartners.delete.successMessage'),
                        color: 'success',
                      }),
                    );
                  } else if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                  setPartner({ status: 'loading' });
                }
                balModalController.dismiss();
              }
            })}
          >
            <BalText className="has-text-white ml-3 mt-5 mr-3">
              {t('networkPartners.delete.text')}
            </BalText>
            <Controller
              name="deletionConfirmation"
              control={control}
              defaultValue={confirm}
              rules={{ required: t('error.mandatoryField') }}
              render={({ field, fieldState }) => (
                <div className="columns">
                  <BalField>
                    <BalCheckbox
                      className="ml-5 mt-2"
                      {...field}
                      checked={confirm}
                      onBalChange={(e) => {
                        setConfirm(e.detail);
                        field.onChange(e.detail);
                      }}
                    >
                      {t('networkPartners.delete.confirm')}
                    </BalCheckbox>
                    <BalFieldMessage className="ml-5">
                      {fieldState.error && fieldState.error.message}
                    </BalFieldMessage>
                  </BalField>
                </div>
              )}
            />
            <BalTextField
              className="column is-full"
              title={t('general.claim.remarks')}
              placeholder={t('general.claim.remarks')}
              value={
                partner.localValue.type === 'propertyCraftsman'
                  ? partner.localValue.propertyCraftsman.remarks
                  : partner.localValue.vehicleCraftsman.remarks
              }
              onChange={(e) => {
                if (e.detail || e.detail === '') {
                  setPartner(
                    produce(partner, (draftState) => {
                      if (draftState.localValue.type === 'propertyCraftsman') {
                        draftState.localValue.propertyCraftsman.remarks =
                          e.detail;
                      } else {
                        draftState.localValue.vehicleCraftsman.remarks =
                          e.detail;
                      }
                    }),
                  );
                }
              }}
            />
            <div className="column is-full is-flex is-justify-content-flex-end pt-2">
              <BalButton
                className="white-link mr-2"
                color="primary"
                onClick={(event) =>
                  event.detail == 1 && balModalController.dismiss()
                }
              >
                {t('general.buttons.cancel')}
              </BalButton>
              <BalButton
                color="danger"
                elementType="submit"
                disabled={submitting}
              >
                {capitalize(t('general.buttons.delete'))}
              </BalButton>
            </div>
          </form>
        </BalModalBody>
      </>
    );
  } else {
    return <></>;
  }
};

export const NetworkPartnerEditButtonGroup = (
  props: PartnerProps,
): JSX.Element => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: DeletePartnerModal,
      componentProps: props,
      cssClass: 'center-modal deletion',
    });
    return modal.present();
  };

  return (
    <div
      className={`is-flex is-flex-direction-column is-align-items-flex-end ml-4 ${props.className}`}
    >
      <BalButton
        className="mr-4"
        size="small"
        color="primary-light"
        square
        outlined
        onClick={(event) => {
          if (event.detail == 1) {
            const element = document.getElementById('display-buttons');
            element?.classList.contains('display-buttons-hidden')
              ? element.classList.remove('display-buttons-hidden')
              : element?.classList.add('display-buttons-hidden');
          }
        }}
      >
        <div className="dropdown px-1 m-1">
          <BalIcon svg={balIconMenuDots} size="small" color="info" />
        </div>
      </BalButton>
      <div
        id="display-buttons"
        className="dropdown-menu-position-craftsman is-flex is-flex-direction-column display-buttons-hidden"
      >
        {props.partner.status === 'success' &&
          ((props.partner.value.type == 'propertyCraftsman' &&
            !props.partner.value.propertyCraftsman.isDeleted) ||
            (props.partner.value.type == 'vehicleCraftsman' &&
              !props.partner.value.vehicleCraftsman.isDeleted)) && (
            <WithCapabilities requiredCapabilities={['EditNetworkPartner']}>
              <BalButton
                className="is-full-width"
                size="small"
                color="danger"
                onClick={(event) => event.detail == 1 && openModal()}
              >
                {t('networkPartners.delete.label')}
              </BalButton>
            </WithCapabilities>
          )}
      </div>
    </div>
  );
};
