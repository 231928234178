import {
  BalHeading,
  BalButton,
  BalButtonGroup,
  BalText,
  BalCard,
} from '@baloise/design-system-components-react';
import {
  GridOptions,
  ICellRendererParams,
  ILoadingCellRendererParams,
  RowNode,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Avatar, ResultRenderer } from '../../../../components/ui';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { earlybyteTableTextRenderer, guid } from '../../../../utils/utilities';
import { getPropertyInspectionTaskRecipients } from '../../data/requests';
import {
  CraftsmanListDto,
  PropertyInspectionTaskRecipientDto,
  RecipientType,
} from '../../../../types/resource-models';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { ResponseOfPropertyInspectionTaskRecipientDto } from '../../../../../../bat-shared/resource-models';
import PropertyInspectionTaskRecipientAddButton from './property-inspection-task-recipient-add-button';
import PropertyInspectionTaskRecipientDeleteButton from './property-inspection-task-recipient-delete-button';
import PropertyInspectionTaskRecipientEditButton from './property-inspection-task-recipient-edit-button';
import PropertyInspectionTaskCraftsmanRecipientsAddButton from './property-inspection-task-craftsman-recipient-add-button';
import { PropertyInspectionTaskSendingWizardViewContext } from './property-inspection-task-sending-wizard-view';
import { toast } from 'react-toastify';
import { ToastNotification } from '../../../../components/toast-notification';

export type CustomRecipientType = RecipientType | 'Craftsman' | 'TaskContact';
export interface CustomRecipient {
  id: string;
  type?: CustomRecipientType;
  name: string;
  email?: string;
}

export const PropertyInspectionTaskRecipientCard = ({
  onNext,
  onPrevious,
}: {
  onNext: () => void;
  onPrevious: () => void;
}): JSX.Element => {
  const {
    token,
    propertyInspectionTask,
    setRecipients,
    setCustomRecipients,
    customRecipients,
    selectedCustomRecipients,
    setSelectedCustomRecipients,
  } = useContext(PropertyInspectionTaskSendingWizardViewContext);

  const { t } = useTranslation();

  const [combinedState, setCombinedState] = useState<
    RequestResult<ResponseOfPropertyInspectionTaskRecipientDto>
  >({ status: 'initial' });

  const [recipientsGridApi, setRecipientsGridApi] = useState<
    GridApi | undefined
  >();
  const [selectedRecipients, setSelectedRecipients] = useState<
    PropertyInspectionTaskRecipientDto[]
  >([]);

  const [customRecipientsGridApi, setCustomRecipientsGridApi] = useState<
    GridApi | undefined
  >();

  useEffect(() => {
    if (
      token &&
      combinedState.status === 'initial' &&
      propertyInspectionTask.status === 'success'
    ) {
      setCombinedState({ status: 'loading' });
      getPropertyInspectionTaskRecipients(
        token,
        propertyInspectionTask.localValue.id,
      ).then((response) => {
        setCombinedState(response);
      });
    }
  }, [token, combinedState, propertyInspectionTask]);

  useEffect(() => {
    if (
      combinedState?.status === 'success' &&
      selectedRecipients.length > 0 &&
      recipientsGridApi
    ) {
      recipientsGridApi.forEachNode((node) => {
        if (
          node.data &&
          selectedRecipients.find((x) => x.id === node.data.id)
        ) {
          node.setSelected(true);
        }
      });
    }
  }, [combinedState, recipientsGridApi, selectedRecipients]);

  const refreshRecipients = () => {
    setCombinedState({ status: 'initial' });
  };

  const getRecipientGridOptions = (): GridOptions => {
    return {
      onGridReady(event) {
        setRecipientsGridApi(event.api);
      },
      onGridSizeChanged(event) {
        if (event.clientWidth > 0) {
          event.api.sizeColumnsToFit();
        }
      },
      defaultColDef: {
        sortable: true,
        filter: false,
        autoHeight: false,
      },
      overlayNoRowsTemplate: '&nbsp;',
      rowHeight: 40,
      columnDefs: [
        {
          field: '',
          headerName: '',
          width: 50,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          headerName: t('inspectionTask.recipient.name'),
          width: 150,
          cellRenderer: 'avatarRenderer',
          valueGetter: (params) => {
            const recipient = params.data as PropertyInspectionTaskRecipientDto;
            return `${recipient.name ?? ''}`;
          },
          comparator: (valueA: string, valueB: string) => {
            if (valueA.toLowerCase() == valueB.toLowerCase()) return 0;
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
          },
        },
        {
          field: 'email',
          headerName: t('inspectionTask.recipient.email'),
          width: 150,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          field: 'recipientType',
          headerName: t('inspectionTask.recipient.type'),
          width: 150,
          valueGetter: (params) => {
            const recipient = params.data as PropertyInspectionTaskRecipientDto;
            if (recipient.recipientType) {
              return t(
                `inspectionTask.recipient.customRecipientTypes.${recipient.recipientType}`,
              );
            } else {
              return '';
            }
          },
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          width: 100,
          cellRenderer: function renderEdit(params: ICellRendererParams) {
            return (
              <div className="mt-3">
                <PropertyInspectionTaskRecipientEditButton
                  currentRecipient={params.data}
                  onEditRecipient={refreshRecipients}
                />
                <PropertyInspectionTaskRecipientDeleteButton
                  currentRecipient={params.data}
                  onDeleteRecipient={refreshRecipients}
                />
              </div>
            );
          },
        },
      ],
      components: { avatarRenderer: renderRecipientAvatarName },
    };
  };

  const renderRecipientAvatarName = (
    params: ILoadingCellRendererParams,
  ): JSX.Element => {
    const name = `${
      (params.data as PropertyInspectionTaskRecipientDto).name ?? ''
    }`;
    if (name) {
      return (
        <div className="is-flex is-align-items-center">
          <Avatar
            user={undefined}
            userName={name}
            size="32"
            className="is-flex-shrink-0"
          />
          <span className="bal-table-cell-text ml-2">
            <BalText space="none">{name}</BalText>
          </span>
        </div>
      );
    } else {
      return (
        <span className="bal-table-cell-text">
          <BalText space="none">
            <>{t('taskOverview.unassigned')}</>
          </BalText>
        </span>
      );
    }
  };

  const getCustomRecipientGridOptions = (): GridOptions => {
    return {
      onGridReady(event) {
        setCustomRecipientsGridApi(event.api);
      },
      onGridSizeChanged(event) {
        if (event.clientWidth > 0) {
          event.api.sizeColumnsToFit();
        }
      },
      defaultColDef: {
        sortable: true,
        filter: false,
        autoHeight: false,
      },
      overlayNoRowsTemplate: '&nbsp;',
      rowHeight: 40,
      columnDefs: [
        {
          field: '',
          headerName: '',
          width: 50,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          headerName: t('inspectionTask.recipient.name'),
          width: 150,
          cellRenderer: 'customAvatarRenderer',
          valueGetter: (params) => {
            const recipient = params.data as CustomRecipient;
            if (recipient) {
              return `${recipient.name}`;
            } else {
              return '';
            }
          },
          comparator: (valueA: string, valueB: string) => {
            if (valueA.toLowerCase() == valueB.toLowerCase()) return 0;
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
          },
        },
        {
          field: 'email',
          headerName: t('inspectionTask.recipient.email'),
          width: 150,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          field: 'type',
          headerName: t('inspectionTask.recipient.type'),
          width: 100,
          valueGetter: (params) => {
            const recipient = params.data as CustomRecipient;
            if (recipient.type) {
              return t(
                `inspectionTask.recipient.customRecipientTypes.${recipient.type}`,
              );
            } else {
              return '';
            }
          },
          cellRenderer: earlybyteTableTextRenderer,
        },
      ],
      components: { customAvatarRenderer: renderCustomRecipientAvatarName },
    };
  };

  const renderCustomRecipientAvatarName = (
    params: ILoadingCellRendererParams,
  ): JSX.Element => {
    const name = `${(params.data as CustomRecipient).name}`;
    if (name) {
      return (
        <div className="is-flex is-align-items-center">
          <Avatar
            user={undefined}
            userName={name}
            size="32"
            className="is-flex-shrink-0"
          />
          <span className="bal-table-cell-text ml-2">
            <BalText space="none">{name}</BalText>
          </span>
        </div>
      );
    } else {
      return (
        <span className="bal-table-cell-text">
          <BalText space="none">
            <>{t('taskOverview.unassigned')}</>
          </BalText>
        </span>
      );
    }
  };

  const onAddCraftsmanRecipient = (
    craftsman: CraftsmanListDto | undefined,
  ): void => {
    if (craftsman) {
      if (customRecipients.find((x) => x.email === craftsman.company.email)) {
        toast(
          ToastNotification({
            message: t('inspectionTask.recipient.duplicatedRecipient'),
            color: 'warning',
          }),
        );
      } else {
        setCustomRecipients &&
          setCustomRecipients([
            ...customRecipients,
            {
              id: guid(),
              email: craftsman.company.email,
              name: craftsman.company.displayName,
              type: 'Craftsman',
            },
          ]);
      }
    }
  };

  useEffect(() => {
    if (selectedCustomRecipients.length > 0 && customRecipientsGridApi) {
      customRecipientsGridApi.forEachNode((node) => {
        if (
          node.data &&
          selectedCustomRecipients.find((x) => x.id === node.data.id)
        ) {
          node.setSelected(true);
        }
      });
    }
  }, [customRecipientsGridApi, customRecipients, selectedCustomRecipients]);

  return (
    <>
      {propertyInspectionTask.status === 'success' && (
        <BalCard className="my-5 p-5">
          <BalHeading level="h3">
            {t('inspectionTask.recipient.recipientsSelection')}
          </BalHeading>
          <div>
            <div className="is-flex is-flex-direction-column">
              <div className="  flex-float-right pr-3">
                <PropertyInspectionTaskRecipientAddButton
                  taskId={propertyInspectionTask?.localValue.id ?? ''}
                  onAddRecipient={refreshRecipients}
                />
              </div>
            </div>
            <div className="columns is-gapless is-multiline">
              {/*Manual recipients*/}
              <div className="task-table-wizard mb-5">
                <ResultRenderer
                  result={combinedState}
                  defaultValue={{
                    totalPages: 1,
                    totalItems: 0,
                    entities: [],
                  }}
                  loaded={(tasksResponse) => {
                    return (
                      <>
                        <p>
                          <BalText space="none" color="info" bold size="small">
                            {t(
                              'inspectionTask.recipient.manualRecipientsTitle',
                            )}
                          </BalText>
                        </p>
                        <AgGridReact
                          suppressCellFocus={true}
                          suppressRowClickSelection={true}
                          rowSelection={'multiple'}
                          rowData={tasksResponse.entities}
                          gridOptions={getRecipientGridOptions()}
                          onRowSelected={() => {
                            if (recipientsGridApi) {
                              const rowNodes: RowNode[] =
                                recipientsGridApi.getSelectedNodes();
                              const recipientsAux: PropertyInspectionTaskRecipientDto[] =
                                [];
                              rowNodes.map((rowNode) => {
                                recipientsAux.push(
                                  rowNode.data as PropertyInspectionTaskRecipientDto,
                                );
                              });
                              setSelectedRecipients(recipientsAux);
                            }
                          }}
                        />
                      </>
                    );
                  }}
                />
              </div>
              {/*Contacts and craftsman*/}
              <div className="task-table-wizard mt-5">
                <div className="is-flex is-flex-direction-column">
                  <div className="  flex-float-right pr-3">
                    <PropertyInspectionTaskCraftsmanRecipientsAddButton
                      onAddRecipient={onAddCraftsmanRecipient}
                    />
                  </div>
                </div>
                <p>
                  <BalText space="none" color="info" bold size="small">
                    {t(
                      'inspectionTask.recipient.contactAndCraftsmanRecipientsTitle',
                    )}
                  </BalText>
                </p>
                <AgGridReact
                  suppressCellFocus={true}
                  suppressRowClickSelection={true}
                  suppressHorizontalScroll={true}
                  rowSelection={'multiple'}
                  rowData={customRecipients}
                  gridOptions={getCustomRecipientGridOptions()}
                  onRowSelected={() => {
                    if (customRecipientsGridApi) {
                      const rowNodes: RowNode[] =
                        customRecipientsGridApi.getSelectedNodes();
                      const recipientsAux: CustomRecipient[] = [];
                      rowNodes.map((rowNode) => {
                        recipientsAux.push(rowNode.data as CustomRecipient);
                      });
                      setSelectedCustomRecipients &&
                        setSelectedCustomRecipients(recipientsAux);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pt-6">
            <BalButtonGroup position="right" className="mt-6">
              <BalButton
                elementType="button"
                color="primary-light"
                onClick={(event) => event.detail == 1 && onPrevious()}
              >
                {t('general.buttons.back')}
              </BalButton>
              <BalButton
                color="info"
                onClick={(event) => {
                  if (event.detail == 1) {
                    setRecipients && setRecipients(selectedRecipients);
                    setSelectedCustomRecipients &&
                      setSelectedCustomRecipients(selectedCustomRecipients);
                    onNext();
                  }
                }}
              >
                {t('general.buttons.next')}
              </BalButton>
            </BalButtonGroup>
          </div>
        </BalCard>
      )}
    </>
  );
};
