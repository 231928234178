import { BalText } from '@baloise/design-system-components-react';
import { InvestigationTaskMeasureDto } from '../../../../types/resource-models';
import { concat, getPartnerName, getPolicyHolder } from '../../../../utils';
import { getValueOrWhitespace } from '../../../../utils/utilities';
import {
  AffectedPartner,
  Claim,
  InvestigationTask,
} from '../../../../types/types';
import { useTranslation } from 'react-i18next';
import { anyNameToShow } from './display-measures';

interface DisplayMeasureProps {
  measure: InvestigationTaskMeasureDto;
  investigationTask: InvestigationTask;
  title: string;
  className?: string;
  editComponent?: JSX.Element;
}

const DisplayMeasure = ({
  measure,
  investigationTask,
  title,
  className,
  editComponent,
}: DisplayMeasureProps): JSX.Element => {
  const { t } = useTranslation();
  const getAffectedPartners = (
    additionalPartnersId: string[],
    claim: Claim,
  ): AffectedPartner[] => {
    const affectedPartners: AffectedPartner[] = [];
    additionalPartnersId.forEach((partnerId) => {
      const partner = claim.affectedPartners.find((p) => p.id === partnerId);
      if (partner) {
        affectedPartners.push(partner);
      }
    });
    return affectedPartners;
  };

  const affectedPartners = getAffectedPartners(
    measure.additionalPartnersId,
    investigationTask.claim,
  );
  const policyHolder = getPolicyHolder(investigationTask.claim);

  if (editComponent || anyNameToShow(measure)) {
    return (
      <div
        className={concat([
          'mt-1 mb-1 columns is-gapless bottom-blue-line is-flex is-justify-content-space-between',
          className,
          editComponent ? 'mt-0 mb-0' : 'mt-0 mb-1',
        ])}
      >
        <BalText
          bold
          size="small"
          className="column is-4 is-flex is-flex-direction-column"
          color="info"
        >
          {title}
        </BalText>
        <div className="column is-flex is-flex-direction-column is-justify-content-center">
          {anyNameToShow(measure) && (
            <BalText bold size="small" color="info" className="m-0">
              {t('general.name')}
            </BalText>
          )}
          {measure.unknownPartner && (
            <BalText
              className={concat(['is-flex is-flex-direction-column m-0'])}
            >
              {t('investigationMeasure.unknownPartner')}
            </BalText>
          )}
          {measure.policyHolder && policyHolder && (
            <BalText
              className={concat(['is-flex is-flex-direction-column m-0'])}
            >
              {getValueOrWhitespace(getPartnerName(policyHolder.partner))}
            </BalText>
          )}
          {affectedPartners.map((partner) => (
            <BalText
              key={partner.id}
              className={concat([
                'is-flex is-flex-direction-column is-justify-content-center m-0',
              ])}
            >
              {getValueOrWhitespace(getPartnerName(partner.partner))}
            </BalText>
          ))}
        </div>
        {editComponent && <div className="mt-1 mb-1">{editComponent}</div>}
      </div>
    );
  } else return <div></div>;
};

export default DisplayMeasure;
