import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import lang from 'suneditor-react/dist/types/lang';
import { useRef } from 'react';

interface TextEditorProps {
  disabled?: boolean;
  height?: string;
  language?: lang;
  placeholder?: string;
  value?: string;
  onChange: (text: string) => void;
}

const TextEditor = ({
  disabled,
  height,
  language,
  placeholder,
  value,
  onChange,
}: TextEditorProps): JSX.Element => {
  const editor = useRef<SunEditorCore>();
  const getCleanHtml = (text?: string): string | undefined => {
    if (!text) return text;

    // Fixes in the tables to allow printing.
    let cleanText = text.replaceAll('<td><div>', '<td>');
    cleanText = cleanText.replaceAll('</div></td>', '</td>');

    // Fixes error IndexSizeError: Failed to execute 'setStart' on 'Range': There is no child at offset 1.
    cleanText = cleanText.replaceAll('<br></li>', '</li>');
    cleanText = cleanText.replaceAll('<br></p>', '</p>');

    // Images and figures not allowed.
    cleanText = removeTag('img', cleanText);
    cleanText = removeTag('figure', cleanText);

    return cleanText;
  };

  const removeTag = (tag: string, text?: string): string => {
    if (!text) return '';
    const doc = new DOMParser().parseFromString(text, 'text/html');
    const tags = doc.getElementsByTagName(tag);
    let cleanText = text;
    for (let i = 0; i < tags.length; i++) {
      cleanText = cleanText.replace(tags[i].outerHTML, '');
    }
    return cleanText;
  };

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  return (
    <SunEditor
      getSunEditorInstance={getSunEditorInstance}
      disable={disabled}
      lang={language ?? 'de'}
      defaultValue={getCleanHtml(value)}
      placeholder={placeholder}
      onPaste={(event, cleanData) => {
        event.stopPropagation();
        return getCleanHtml(cleanData);
      }}
      onDrop={(event) => {
        event.preventDefault();
        editor.current?.wysiwyg.disable();
        editor.current?.wysiwyg.enable();
      }}
      onChange={(text) => {
        const regex = /href="(https?:\/\/)?(.+?)"/g;
        const replacedStr = text.replaceAll(regex, (match, p1, p2) => {
          if (match.includes('mailto:')) return match;
          if (!p1) {
            return `href="//${p2}" alt="${p2}"`;
          } else {
            return match;
          }
        });
        let cleanText = getCleanHtml(replacedStr) ?? '';
        // Empty content.
        if (cleanText === '<p></p>') cleanText = '';
        onChange(cleanText);
      }}
      height={height}
      width={'100%'}
      setOptions={{
        linkTargetNewWindow: true,
        linkNoPrefix: true,
        className: 'text-editor',
        colorList: [
          '#ADD8E6', //lightblue
          '#F08080', //light coral
          '#FFA500', //orange
          '#FFFF00', //yellow
          '#90EE90', // light green
          '#D2B48C', //tan
          '#C0C0C0', //silver
          '#FFB6C1', //lightPink
        ],
        defaultStyle: 'font-family: Arial; font-size: 16px;',
        imageResizing: false,
        imageUrlInput: false,
        imageAccept: '.jpg, .png, .bmp',
        imageMultipleFile: false,
        imageFileInput: true,
        imageUploadSizeLimit: 10240000,
        formats: ['p', 'h2', 'h3', 'h4'],
        font: ['Arial', 'sans-serif'],
        fontSize: [12],
        buttonList: [
          ['undo', 'redo', 'formatBlock'],
          [
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'removeFormat',
            'link',
          ],
          ['hiliteColor', 'list', 'table'],
          ['fullScreen', 'preview', 'print'],
        ],
        tagsBlacklist: 'img|figure',
        pasteTagsBlacklist: 'img|figure',
      }}
    />
  );
};

export default TextEditor;
