import '../../../../styles/dropdown-button.scss';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { InvestigationTask } from '../../../../types/types';
import {
  AppCapabilityNames,
  BaseTaskStatus,
  InvestigationTaskStatus,
} from '../../../../types/resource-models';
import { useToken } from '../../../../hooks';
import produce from 'immer';
import { patchInvestigationTask } from '../../data/requests';
import {
  DisabableButton,
  WithCapabilities,
} from '../../../../components/authorization';

type InvestigationTaskOpenButtonProps = {
  task: InvestigationTask;
  requiredCapabilities: AppCapabilityNames[];
  onChangeSuccess?: (task: RequestResult<InvestigationTask>) => void;
};

const InvestigationTaskOpenButton = ({
  task,
  requiredCapabilities,
  onChangeSuccess,
}: InvestigationTaskOpenButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const onChangeState = async (state: BaseTaskStatus) => {
    const result = await patchInvestigationTask(
      task,
      produce(task, (draftState) => {
        draftState.status = state as InvestigationTaskStatus;
      }),
      token,
    );
    if (result.status === 'success' && onChangeSuccess) {
      onChangeSuccess(result);
    }
  };

  return (
    <WithCapabilities
      requiredCapabilities={requiredCapabilities}
      passWithCapabilitiesPropsToChildren
    >
      <DisabableButton
        color="warning"
        className="ml-3"
        size="small"
        onClick={async (event) => {
          if (event.detail == 1) {
            onChangeState('InProgress');
          }
        }}
      >
        {t('general.buttons.backToInProgress')}
      </DisabableButton>
    </WithCapabilities>
  );
};

export default InvestigationTaskOpenButton;
