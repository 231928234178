import {
  BalButton,
  BalCard,
  BalCheckbox,
  BalHeading,
  BalSpinner,
  BalText,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../components/toast-notification';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import { PartnerDto } from '../../../types/resource-models';
import {
  AffectedPartner,
  Partner,
  TaskTypes,
  VehicleTask,
} from '../../../types/types';
import { getDefaultAddressWithPartnerId } from '../../../utils/utilities';
import { patchPartner, postPartner } from '../../contacts';
import CreateEditContactForm from '../../contacts/components/create-edit-contact-form';
import { validateAffectedPartnerVehicle } from '../../services';

const VehicleRiskAssessmentWizardContactCards = ({
  task,
  setTask,
  saveAndNavigate,
}: {
  task: RequestResult<VehicleTask>;
  setTask: React.Dispatch<RequestResult<VehicleTask>>;
  saveAndNavigate: () => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();

  // Initial information got from claim
  const [policyHolder, setPolicyHolder] = useState<AffectedPartner | undefined>(
    task.status === 'success'
      ? task.localValue.claim.affectedPartners.find(
          (ap) => ap.role === 'PolicyHolder',
        )
      : undefined,
  );

  // Generated partner by CreateEditContactForm
  const [partner, setParner] = useState<Partner | undefined>(undefined);

  const token = useToken();
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (task.status === 'success') {
      const pHolder = task.localValue.claim.affectedPartners.find(
        (ap) => ap.role === 'PolicyHolder',
      );
      setPolicyHolder(pHolder);
    }
  }, [task]);

  const onAdd = async (partner: AffectedPartner) => {
    if (task.status === 'success') {
      const result = await postPartner(
        token,
        task.value.id,
        partner,
        TaskTypes.VehicleTask,
      );
      if (result.status === 'success') {
        setTask(
          produce(task, (draftState) => {
            draftState.localValue.claim.affectedPartners = [
              ...task.localValue.claim.affectedPartners,
              result.value,
            ];
            draftState.value.claim.affectedPartners = [
              ...task.localValue.claim.affectedPartners,
              result.value,
            ];
            if (result.value.role === 'PolicyHolder') {
              draftState.localValue.claim.policyHolderId =
                result.value.partner.type === 'person'
                  ? result.value.partner.person.id
                  : result.value.partner.company.id;
            }
          }),
        );
      } else if (result.status === 'error') {
        toast(ErrorToast(result.errorValue));
      }
    }
  };

  const onChange = async (partner: AffectedPartner) => {
    if (task.status === 'success' && policyHolder) {
      const result = await patchPartner(token, policyHolder, partner);
      if (result.status === 'success') {
        saveAndNavigate();
      } else if (result.status === 'error') {
        toast(ErrorToast(result.errorValue));
      }
    }
  };

  const onSave = async (partner: Partner) => {
    if (task.status === 'success') {
      if (policyHolder) {
        // Updating
        await onChange({
          id:
            task.localValue.claim.affectedPartners.find((p) => {
              p.role === 'PolicyHolder';
              // life hack 1
            })?.id ?? '00000000-0000-0000-0000-000000000000',
          partner: partner,
          role: 'PolicyHolder',
        });
      } else {
        // Adding
        const partnerUpdated =
          partner.type === 'person'
            ? {
                ...partner,
                person: {
                  ...partner.person,
                  address:
                    JSON.stringify(partner.person.address) ==
                    JSON.stringify(
                      getDefaultAddressWithPartnerId(
                        (partner as PartnerDto).id,
                      ),
                    )
                      ? undefined
                      : partner.person.address,
                },
              }
            : {
                ...partner,
                company: {
                  ...partner.company,
                  address:
                    JSON.stringify(partner.company.address) ==
                    JSON.stringify(
                      getDefaultAddressWithPartnerId(
                        (partner as PartnerDto).id,
                      ),
                    )
                      ? undefined
                      : partner.company.address,
                },
              };
        await onAdd({
          id: '00000000-0000-0000-0000-000000000000',
          partner: partnerUpdated,
          role: 'PolicyHolder',
        });
      }
    }
  };

  useEffect(() => {
    if (task.status === 'success') {
      if (
        task.localValue.claim.policyHolderId !== task.value.claim.policyHolderId
      ) {
        saveAndNavigate().catch();
      }
    }
  }, [saveAndNavigate, task]);

  return (
    <div>
      {(task.status === 'initial' || task.status === 'loading') && (
        <BalSpinner />
      )}
      {task.status === 'error' && <></>}
      {task.status === 'success' && (
        <div>
          <BalCard className={'p-5 mt-3'}>
            <div className="rows">
              <BalHeading
                color={''}
                space="none"
                level="h4"
                className="row pb-3"
                subtitle
              >
                {t('claimDetail.policyHolder')}
              </BalHeading>
              <CreateEditContactForm
                className="pl-6"
                edit={policyHolder ? true : false}
                addressOpen
                requiredPhoneOrMail
                affectedPartnerToEdit={policyHolder}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSave={() => {}}
                methodsProp={methods}
                setPartnerProps={setParner}
              />
            </div>
            <div className="pt-2">
              <BalCheckbox
                checked={
                  task.status === 'success' &&
                  task.localValue.visitationAtPolicyHolder
                }
                onBalChange={(e: CustomEvent<boolean>) =>
                  setTask(
                    produce(task, (draftState) => {
                      draftState.localValue.visitationAtPolicyHolder =
                        e.detail as boolean;
                    }),
                  )
                }
              >
                {t('vehicleTask.vehicleCompany.visitationAtPolicyHolder')}
              </BalCheckbox>
            </div>
            {policyHolder && (
              <>
                {!validateAffectedPartnerVehicle(
                  policyHolder,
                  'VehicleRiskAssessment',
                ) && (
                  <BalText color="danger">
                    {t('validators.missingInformation')}
                  </BalText>
                )}
              </>
            )}
          </BalCard>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(async () => {
                if (partner) {
                  await onSave(partner); // Saving affected partner
                }
              })}
            >
              <div className="is-pulled-right mt-5">
                <BalButton
                  elementType="submit"
                  className="ml-3"
                  color="primary"
                  disabled={
                    (!methods.formState.isValid &&
                      Object.keys(methods.formState.errors).length !== 0) ||
                    (partner?.type === 'person' &&
                      !partner.person.email &&
                      !partner.person.phoneNumber) ||
                    (partner?.type === 'company' &&
                      !partner.company.email &&
                      !partner.company.phoneNumber)
                  }
                >
                  {t('general.buttons.next')}
                </BalButton>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
};

export default VehicleRiskAssessmentWizardContactCards;
