import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  ILoadingCellRendererParams,
} from 'ag-grid-community';
import { TFunction } from 'i18next';
import { Props } from '@baloise/design-system-components/dist/types/props';
import { BalTag, BalText } from '@baloise/design-system-components-react';
import {
  InvestigationTaskJournalListDto,
  InvestigationTaskStatus,
} from '../../../types/resource-models';
import { dateComparator, formatDateTime } from '../../../utils/date';
import {
  earlybyteTableTextRenderer,
  getDisplayNameFromUser,
  renderBaseTaskDate,
} from '../../../utils/utilities';
import { Avatar } from '../../../components/ui';
import PropertyInspectionTaskJournalDeleteButton, {
  PropertyInspectionTaskJournalDeleteButtonProps,
} from './journal-toolbar/property-inspection-task-journal-delete-button';
import PropertyInspectionTaskJournalEditButton, {
  PropertyInspectionTaskJournalEditButtonProps,
} from './journal-toolbar/property-inspection-task-journal-edit-button';

export const getDefaultColDefs = (
  t: TFunction,
  journalEditButtonProps: PropertyInspectionTaskJournalEditButtonProps,
  journalDeleteButtonProps: PropertyInspectionTaskJournalDeleteButtonProps,
): ColDef[] => {
  return [
    {
      cellRenderer: earlybyteTableTextRenderer,
      headerName: t('inspectionTask.journal.orderNumber'),
      field: 'orderNumber',
      width: 64,
      minWidth: 64,
    },
    {
      cellRenderer: 'createdByRenderer',
      headerName: t('inspectionTask.journal.responsible'),
      field: 'creator.displayName',
      minWidth: 250,
    },
    {
      valueGetter: (params) => {
        const journal = params.data as InvestigationTaskJournalListDto;
        return t(`inspectionTask.journal.journalTypes.${journal.journalType}`);
      },
      headerName: t('inspectionTask.journal.type'),
      field: 'journalType',
      minWidth: 200,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        return (params.data as InvestigationTaskJournalListDto).createdAt;
      },
      valueFormatter: (params) => {
        const date = params.value;
        return formatDateTime(date);
      },
      comparator: dateComparator,
      field: 'createdAt',
      headerName: t('inspectionTask.journal.date'),
      minWidth: 150,
      cellRenderer: renderBaseTaskDate,
    },
    {
      valueGetter: (params) => {
        const journal = params.data as InvestigationTaskJournalListDto;
        return t(`tristate.${journal.confidential}`);
      },
      field: 'confidential',
      headerName: t('inspectionTask.journal.confidential'),
      minWidth: 25,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      cellRenderer: function renderEdit(params: ICellRendererParams) {
        return (
          <div className="mt-3">
            <PropertyInspectionTaskJournalEditButton
              currentJournal={params.data}
              onEditJournal={journalEditButtonProps.onEditJournal}
              readonly={journalEditButtonProps.readonly}
              onDeleteJournal={journalDeleteButtonProps.onDeleteJournal}
            />
            <PropertyInspectionTaskJournalDeleteButton
              currentJournal={params.data}
              onDeleteJournal={journalDeleteButtonProps.onDeleteJournal}
              disabled={journalDeleteButtonProps.disabled}
            />
          </div>
        );
      },
      sortable: false,
      width: 95,
      minWidth: 95,
      resizable: false,
    },
  ];
};

export const getAllGridOptions = (
  t: TFunction,
  journalEditButtonProps: PropertyInspectionTaskJournalEditButtonProps,
  journalDeleteButtonProps: PropertyInspectionTaskJournalDeleteButtonProps,
): GridOptions => {
  return {
    defaultColDef: {
      sortable: true,
      filter: false,
      width: 110,
      flex: 1,
      minWidth: 110,
      resizable: true,
    },
    //ag grid inserts default message if is either undefined or empty string, and gives error if is just a space
    // '&nbsp;' also just equals empty space, but works in this case
    overlayNoRowsTemplate: '&nbsp;',
    columnDefs: getDefaultColDefs(
      t,
      journalEditButtonProps,
      journalDeleteButtonProps,
    ),
    components: {
      createdByRenderer: function renderCreatedByAvatar(
        params: ILoadingCellRendererParams,
      ) {
        if (params.data) {
          const name = getDisplayNameFromUser(
            (params.data as InvestigationTaskJournalListDto).creator,
          );
          return (
            <span className="bal-table-cell-text">
              {params.data.creator && (
                <Avatar
                  user={params.data.creator}
                  size="32"
                  className="is-flex-shrink-0"
                />
              )}
              <BalText space="none">
                <p className="ml-2">{name}</p>
              </BalText>
            </span>
          );
        } else {
          return t('taskOverview.unassigned');
        }
      },

      tagRenderer: function renderTag(params: ILoadingCellRendererParams) {
        const status: InvestigationTaskStatus = params.data.status;
        let color: Props.BalTagColor | undefined;
        if (status === 'Closed') {
          color = 'info';
        } else if (params.data.status === 'Rejected') {
          color = 'danger';
        } else if (params.data.status === 'OnHold') {
          color = 'warning';
        } else {
          color = 'primary';
        }
        return (
          <div className="is-flex is-align-items-center">
            <BalTag color={color} size="small">
              {t(`baseTaskStatus.${status}`) as string}
            </BalTag>
          </div>
        );
      },
    },
  };
};
