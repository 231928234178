import {
  BalButton,
  BalButtonGroup,
  BalDatepicker,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
  BalTimeInput,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AppCapabilityNames } from '../../types/resource-models';
import { WithCapabilities } from '../authorization/with-capabilities';
import { concat } from '../../utils';
import { CancelButton } from '../ui';

type EditDateTimeModalProps = {
  label: string;
  value?: string;
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  isRequired: boolean;
  isUtc: boolean;
  onSave(value: string | undefined, reportToAdos: boolean): void;
};

export const EditDateTimeModalContent = (
  props: EditDateTimeModalProps,
): JSX.Element => {
  const { t } = useTranslation();

  const [dateValue, setDateValue] = useState<string | undefined>(
    props.value?.substring(0, 10),
  );
  const [timeValue, setTimeValue] = useState<string | undefined>(
    props.value?.substring(11, 16),
  );
  const [totalValue, setTotalValue] = useState<string | undefined>(props.value);
  const [requiredError, setRequiredError] = useState<boolean>(false);

  useEffect(() => {
    if (dateValue && timeValue)
      setTotalValue(concat([dateValue, 'T', timeValue, ':00.000000'], 'none'));
    else if (dateValue)
      setTotalValue(concat([dateValue, 'T00:00:00.000000'], 'none'));
    else setTotalValue(undefined);
  }, [timeValue, dateValue, setTotalValue]);

  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {props.label}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalDatepicker
          placeholder={
            props.isRequired
              ? `${t('claimDetail.date')} *`
              : t('claimDetail.date')
          }
          value={dateValue}
          onBalChange={(event: CustomEvent<string | undefined>) => {
            const value = event.detail;
            if (!props.isRequired || (value && value.length > 0)) {
              setRequiredError(false);
            } else {
              setRequiredError(true);
            }
            setDateValue(value);
          }}
        />
        <BalTimeInput
          placeholder={
            props.isRequired
              ? `${t('claimDetail.time')} *`
              : t('claimDetail.time')
          }
          value={timeValue}
          onBalChange={(event: CustomEvent<string | undefined>) => {
            const value = event.detail;
            if (!props.isRequired || (value && value.length > 0)) {
              setRequiredError(false);
            } else {
              setRequiredError(true);
            }
            setTimeValue(value);
          }}
        />
        {requiredError && (
          <BalText className="mt-2" color="danger" size="small" bold>
            {t('error.mandatoryField')}
          </BalText>
        )}
        <BalButtonGroup position="right">
          <CancelButton />
          <BalButton
            color="info"
            onClick={(event) => {
              if (dateValue == props.value && event.detail == 1) {
                balModalController.dismiss();
              } else if (!requiredError && event.detail == 1) {
                let value = totalValue;
                if (props.isUtc && value) {
                  const dateValue = new Date(value);
                  value = dateValue.toISOString();
                }
                props.onSave(value, false);
              }
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export const EditDateTimeModal = (
  props: EditDateTimeModalProps,
): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditDateTimeModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
};
