import { APIRoutes } from '../../../router/router';
import { jsonGet, RequestResult } from '../../../data';
import { ImportStateDto } from '../../../types/resource-models';
import { apiUrl } from '../../../data/fetch/base';

export const getImportStates = async (
  bearerToken: string,
  importType: string,
): Promise<RequestResult<ImportStateDto[]>> =>
  jsonGet<ImportStateDto[]>(
    `${apiUrl}${APIRoutes.GET_IMPORT_STATE.create({
      query: {
        importType: importType,
      },
    })}`,
    bearerToken,
  );
