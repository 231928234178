import { useContext } from 'react';
import { UserContext } from '../../../context';
import '../../../styles/general.scss';
import { EBRoutes } from '../../../router/router';
import { useTranslation } from 'react-i18next';
import { getMenuItems } from './accident-inspection-task-view-menu-items';
import { ViewMenu, Filter } from '../../view-menu/view-menu';
import { CombinedState } from '../../base-task';

const AccidentInspectionTaskViewMenu = ({
  setLabelAndFilter,
  combinedState,
  editFilter,
  setEditFilter,
}: {
  setLabelAndFilter: (label: string, filter: Filter) => void;
  combinedState: CombinedState;
  editFilter: string | undefined;
  setEditFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
}): JSX.Element => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  return user.status === 'success' ? (
    <ViewMenu
      setLabelAndFilter={setLabelAndFilter}
      menuItems={getMenuItems(t, user.value)}
      route={EBRoutes.ACCIDENT_INSPECTION_TASK.create({})}
      combinedState={combinedState}
      editFilter={editFilter}
      setEditFilter={setEditFilter}
    ></ViewMenu>
  ) : (
    <></>
  );
};

export default AccidentInspectionTaskViewMenu;
