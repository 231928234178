import { BalIcon } from '@baloise/design-system-components-react';
import i18n from '../../../i18n/config';
import { concat, getLabelValueFromBkpNumber } from '../../../utils';
import { CraftsmanChangeDto } from '../../../types/resource-models';
import TextProtocolModal from '../../../components/input/text-protocol-modal';
import { ProtocolMessage } from './entity-protocol';
import { ReactComponent as AttachmentAdd } from '../../../assets/icons/attachment-add.svg';
import { ReactComponent as AttachmentDelete } from '../../../assets/icons/attachment-delete.svg';
import { ReactComponent as ContactDelete } from '../../../assets/icons/contact-delete.svg';
import {
  balIconCheck,
  balIconAccount,
  balIconConsultant,
  balIconCall,
  balIconCopy,
  balIconEdit,
} from '@baloise/design-system-icons';

export const getCraftsmanMessage = (
  change: CraftsmanChangeDto,
  changes: CraftsmanChangeDto[],
): ProtocolMessage | undefined => {
  const t = i18n.t;

  const getPropertyName = (propertyName: string): string => {
    const lowerPropertyName = `${propertyName[0].toLowerCase()}${propertyName.slice(
      1,
    )}`;
    let translatedPropertyName = t(`networkPartners.${lowerPropertyName}`);
    if (translatedPropertyName === `networkPartners.${lowerPropertyName}`) {
      translatedPropertyName = change.propertyName ?? '';
    }
    return translatedPropertyName;
  };

  const getPropertyValue = (propertyName: string, value: string): string => {
    if (propertyName === 'IsIndependentVehicleCraftsman') {
      return `«${t(`tristate.${value}`)}»`;
    }
    return `: ${value}`;
  };

  if (change.newValue)
    switch (change.changeType) {
      case 'AddedBrand':
        return {
          message: concat([t('craftsmanHistory.AddedBrand'), change.newValue]),
          icon: (
            <div className="icon-container is-info pl-2">
              <AttachmentAdd />
            </div>
          ),
        };
      case 'DeletedBrand':
        return {
          message: concat([
            t('craftsmanHistory.DeletedBrand'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container is-info pl-2">
              <AttachmentDelete />
            </div>
          ),
        };
      case 'AddedNewContact':
        return {
          message: concat([
            t('craftsmanHistory.AddedNewContact'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCall} />
            </div>
          ),
        };
      case 'EditContact':
        return {
          message: concat([t('taskHistory.EditContact'), change.newValue]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCopy} />
            </div>
          ),
        };
      case 'RemoveContact':
        return {
          message: concat([
            t('craftsmanHistory.RemoveContact'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container is-info pr-2">
              <ContactDelete />
            </div>
          ),
        };
      case 'AddedSpecialty':
        return {
          message: concat([
            t('craftsmanHistory.AddedSpecialty'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container is-info pl-2">
              <AttachmentAdd />
            </div>
          ),
        };
      case 'DeletedSpecialty':
        return {
          message: concat([
            t('craftsmanHistory.DeletedSpecialty'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container is-info pl-2">
              <AttachmentDelete />
            </div>
          ),
        };
      case 'AddedWorkCategory':
        return {
          message: concat([
            t('craftsmanHistory.AddedWorkCategory'),
            getLabelValueFromBkpNumber(change.newValue),
          ]),
          icon: (
            <div className="icon-container is-info pl-2">
              <AttachmentAdd />
            </div>
          ),
        };
      case 'DeletedWorkCategory':
        return {
          message: concat([
            t('craftsmanHistory.DeletedWorkCategory'),
            getLabelValueFromBkpNumber(change.newValue),
          ]),
          icon: (
            <div className="icon-container is-info pl-2">
              <AttachmentDelete />
            </div>
          ),
        };
      case 'Company':
        return {
          message: concat([
            t('craftsmanHistory.Company'),
            t(`craftsmanHistory.${change.propertyName}`),
            change.newValue,
          ]),
          change: { ...change, propertyName: t('craftsmanHistory.Company') },
          icon: (
            <div className="icon-container">
              <BalIcon
                svg={balIconEdit}
                onClick={(event) => {
                  event.detail == 1 &&
                    TextProtocolModal({
                      label: t('craftsmanHistory.Company'),
                      changes: [change],
                    });
                }}
                className="icon-clickable"
              />
            </div>
          ),
        };
      case 'Created':
        return {
          message: t('craftsmanHistory.Created'),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCheck} color="success" />
            </div>
          ),
        };
      case 'GlassOnly':
        return {
          message: concat([
            t('networkPartners.onlyGlass'),
            `«${t(`tristate.${change.newValue}`)}»`,
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCopy} />
            </div>
          ),
        };
      case 'Headquarters':
        return {
          message: concat([
            t('networkPartners.headquarters'),
            `«${t(`tristate.${change.newValue}`)}»`,
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCopy} />
            </div>
          ),
        };
      case 'IsExternalPartner':
        return {
          message: concat([
            t('networkPartners.isExternalPartner'),
            `«${t(`tristate.${change.newValue}`)}»`,
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCopy} />
            </div>
          ),
        };
      case 'Latitude':
        return {
          message: concat([t('networkPartners.latitude'), change.newValue]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCopy} />
            </div>
          ),
        };
      case 'Longitude':
        return {
          message: concat([t('networkPartners.longitude'), change.newValue]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCopy} />
            </div>
          ),
        };
      case 'PartnerNumber':
        return {
          message: concat([
            t('networkPartners.partnerNumber'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconAccount} />
            </div>
          ),
        };
      case 'PrimaryContact':
        return {
          message: concat([
            t('craftsmanHistory.PrimaryContact'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconConsultant} />
            </div>
          ),
        };
      case 'Remarks': {
        const translatedPropertyName = t('taskHistory.Remarks');
        const translatedChange = {
          ...change,
          propertyName: translatedPropertyName,
        };
        changes.push(translatedChange);
        return {
          message: concat([
            translatedPropertyName,
            t('taskHistory.changedTo'),
            change.newValue,
          ]),
          change: translatedChange,
          icon: (
            <div className="icon-container">
              <BalIcon
                svg={balIconEdit}
                onClick={(event) => {
                  event.detail == 1 &&
                    TextProtocolModal({
                      label: t('taskHistory.Remarks'),
                      changes: changes,
                    });
                }}
                className="icon-clickable"
              />
            </div>
          ),
        };
      }
      case 'Region':
        return {
          message: concat([t('networkPartners.region'), change.newValue]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconAccount} />
            </div>
          ),
        };
      case 'Responsible':
        return {
          message: concat([
            t('networkPartners.responsibility'),
            change.newValue,
          ]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconAccount} />
            </div>
          ),
        };
      default:
        return {
          message: `${getPropertyName(
            change.propertyName ?? '',
          )} ${getPropertyValue(change.propertyName ?? '', change.newValue)}`,
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconCopy} />
            </div>
          ),
        };
    }
};
