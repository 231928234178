import { diff, jsonPatchPathConverter } from 'just-diff';
import { RequestResult } from '../../../data';
import { apiUrl } from '../../../data/fetch/base';
import {
  fileReq,
  jsonGet,
  jsonReq,
  makeAuthFileHeaders,
  makeAuthHeaders,
  typeFromApi,
} from '../../../data/fetch/requests';
import { APIRoutes, PropertiesToString } from '../../../router/router';
import {
  BaseFilterCriteria,
  BaseTaskFilterCriteria,
  BaseTaskManualPostDto,
  BaseTaskPostDto,
  PropertyInspectionTaskFilterCriteriaDto,
  VehicleTaskPostDto,
} from '../../../types/resource-models';
import { BaseTasksListResponse, VehicleTask } from '../../../types/types';
import { BaseTask, TaskTypes } from '../../../types/types';

export async function postTask<T extends BaseTask>(
  task: BaseTaskPostDto,
  bearerToken: string,
  route: string,
): Promise<RequestResult<T>> {
  const request = new Request(route, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(task),
  });
  return jsonReq<T>(request, typeFromApi);
}

export const postTaskManual = async (
  baseTaskManualPost: BaseTaskManualPostDto,
  taskType: TaskTypes,
  bearerToken: string,
): Promise<RequestResult<BaseTask>> => {
  const path = `${TaskTypes[taskType]}s/manual`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK.create({
    path: path,
  })}`;
  const request = new Request(url, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(baseTaskManualPost),
  });
  return jsonReq<BaseTask>(request, typeFromApi);
};

export async function postVehicleTask(
  task: VehicleTaskPostDto,
  bearerToken: string,
  route: string,
): Promise<RequestResult<VehicleTask>> {
  const request = new Request(route, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(task),
  });
  return jsonReq<VehicleTask>(request, typeFromApi);
}

export async function getTask<T extends BaseTask>(
  bearerToken: string,
  baseTaskId: string,
  taskType: TaskTypes,
): Promise<RequestResult<T>> {
  const path = `${TaskTypes[taskType]}s/${baseTaskId}`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK.create({
    path: path,
  })}`;

  return jsonGet(url, bearerToken);
}

export const getBaseTasksExport = async (
  bearerToken: string,
  filter: BaseTaskFilterCriteria,
  taskType: TaskTypes,
): Promise<RequestResult<string>> => {
  const path = `${TaskTypes[taskType]}s/export`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK_EXPORT.create({
    path: path,
    query: { ...filter, maxItemCount: 49999 } as Partial<
      PropertiesToString<PropertyInspectionTaskFilterCriteriaDto> &
        PropertiesToString<BaseFilterCriteria>
    >,
  })}`;
  const request = new Request(url, {
    method: 'GET',
    headers: await makeAuthFileHeaders(bearerToken),
  });

  return fileReq(request);
};

export const getBaseTask = async (
  bearerToken: string,
  baseTaskId: string,
  taskType: TaskTypes,
): Promise<RequestResult<BaseTask>> => {
  const path = `${TaskTypes[taskType]}s/${baseTaskId}`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK.create({
    path: path,
  })}`;

  return jsonGet(url, bearerToken);
};

export async function getBaseTasksList(
  bearerToken: string,
  filter: BaseTaskFilterCriteria,
  taskType: TaskTypes,
): Promise<RequestResult<BaseTasksListResponse>> {
  const path = `${TaskTypes[taskType]}s`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK_EXPORT.create({
    path: path,
    query:
      filter as unknown as PropertiesToString<PropertyInspectionTaskFilterCriteriaDto> &
        PropertiesToString<BaseFilterCriteria>,
  })}`;
  return await jsonGet(url, bearerToken);
}

export const updateBaseTaskToAdos = async (
  bearerToken: string,
  taskId: string,
  taskType: TaskTypes,
): Promise<RequestResult<BaseTask>> => {
  const patchOperations = [
    { op: 'add', path: 'updateToAdos', value: undefined },
  ];
  const patch = JSON.stringify(patchOperations);

  const path = `${TaskTypes[taskType]}s/${taskId}`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK.create({
    path: path,
  })}`;

  const request = new Request(url, {
    method: 'PATCH',
    headers: await makeAuthHeaders(bearerToken),
    body: patch,
  });
  return await jsonReq<BaseTask>(request, typeFromApi);
};

export const sendBaseTaskToComet = async (
  bearerToken: string,
  taskId: string,
  taskType: TaskTypes,
): Promise<RequestResult<BaseTask>> => {
  const patchOperations = [
    { op: 'add', path: 'updateToComet', value: undefined },
  ];
  const patch = JSON.stringify(patchOperations);

  const path = `${TaskTypes[taskType]}s/${taskId}`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK.create({
    path: path,
  })}`;

  const request = new Request(url, {
    method: 'PATCH',
    headers: await makeAuthHeaders(bearerToken),
    body: patch,
  });
  return await jsonReq<BaseTask>(request, typeFromApi);
};

export const deleteBaseTask = async <BaseTaskType extends BaseTask>(
  bearerToken: string,
  taskId: string,
  taskType: TaskTypes,
): Promise<RequestResult<BaseTaskType>> => {
  const patchOperations = [
    { op: 'replace', path: 'deletedAt', value: new Date().toISOString() },
  ];
  const patch = JSON.stringify(patchOperations);

  const path = `${TaskTypes[taskType]}s/${taskId}`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK.create({
    path: path,
  })}`;

  const request = new Request(url, {
    method: 'PATCH',
    headers: await makeAuthHeaders(bearerToken),
    body: patch,
  });
  return await jsonReq<BaseTaskType>(request, typeFromApi);
};

export const updateBaseTaskField = async <BaseTaskType extends BaseTask>(
  bearerToken: string,
  taskId: string,
  taskType: TaskTypes,
  field: string,
  value?: string,
): Promise<RequestResult<BaseTaskType>> => {
  const patchOperations = [{ op: 'replace', path: field, value }];
  const patch = JSON.stringify(patchOperations);

  const path = `${TaskTypes[taskType]}s/${taskId}`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK.create({
    path: path,
  })}`;

  const request = new Request(url, {
    method: 'PATCH',
    headers: await makeAuthHeaders(bearerToken),
    body: patch,
  });
  return await jsonReq<BaseTaskType>(request, typeFromApi);
};

export const updateBaseTask = async <BaseTaskType extends BaseTask>(
  task: BaseTaskType,
  taskNew: BaseTaskType,
  taskType: TaskTypes,
  bearerToken: string,
  updateToAdos?: boolean,
): Promise<RequestResult<BaseTaskType>> => {
  const patchOperations = diff(task, taskNew, jsonPatchPathConverter);

  if (updateToAdos) {
    patchOperations.push({
      op: 'add',
      path: 'updateToAdos',
      value: undefined,
    });
  }
  const patch = JSON.stringify(patchOperations);

  const path = `${TaskTypes[taskType]}s/${task.id}`;
  const url = `${apiUrl}${APIRoutes.GET_BASE_TASK.create({
    path: path,
  })}`;

  const request = new Request(url, {
    method: 'PATCH',
    headers: await makeAuthHeaders(bearerToken),
    body: patch,
  });

  return await jsonReq<BaseTaskType>(
    request,
    typeFromApi,
    (result) => (result.type = taskType),
  );
};
