import '../../../../styles/dropdown-button.scss';
import { DisabableButton } from '../../../../components/authorization';
import { useTranslation } from 'react-i18next';
import { BaseTaskStatus } from '../../../../types/resource-models';
import { BalButton } from '@baloise/design-system-components-react';

type State = {
  title: string;
  state: BaseTaskStatus;
  onClick?: () => void;
};

type BaseTaskInProgressProps = {
  states: State[];
  onChangeState: (state: BaseTaskStatus) => void;
  text?: string;
};

export const BaseTaskInProgressButton = ({
  states,
  onChangeState,
  text,
}: BaseTaskInProgressProps): JSX.Element => {
  const { t } = useTranslation();

  if (states.length != 1)
    return (
      <div className="dropdown-accept-menu">
        <DisabableButton className="mr-1" size="small" color="success">
          {text ? text : t('general.buttons.accept')}
        </DisabableButton>
        <div className="dropdown-content dropdown-menu-position-accept">
          {states.map((state: State) => {
            return (
              <BalButton
                key={state.title}
                size="small"
                color="success"
                onClick={() => {
                  if (state.onClick) {
                    state.onClick();
                  } else {
                    onChangeState(state.state);
                  }
                }}
              >
                {state.title}
              </BalButton>
            );
          })}
        </div>
      </div>
    );
  else
    return (
      <DisabableButton
        className="mr-1"
        size="small"
        color="success"
        onClick={(event) => event.detail == 1 && onChangeState(states[0].state)}
      >
        {t('general.buttons.accept')}
      </DisabableButton>
    );
};
