import { BalButton } from '@baloise/design-system-components-react';
import { AppCapabilityNames } from '../../../../bat-shared/resource-models';
import { WithCapabilities } from '../authorization';
import { balModalController } from '../../controller/controllers';
import AddressEditFormModal, {
  AddressEditFormModalProps,
} from './address-edit-form-modal';

interface AddressEditButtonProps extends AddressEditFormModalProps {
  requiredCapabilities: AppCapabilityNames[];
}
export const AddressEditButton = ({
  requiredCapabilities,
  initialValue,
  onSave,
}: AddressEditButtonProps): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: AddressEditFormModal,
      componentProps: { initialValue, onSave },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={requiredCapabilities}>
      <BalButton
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
};
