import {
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  BalNotification,
  BalTabItem,
  BalTabs,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { APIRoutes, EBRoutes } from '../router/router';
import { useToken, useCallbackPrompt } from '../hooks';
import {
  getInvestigationTask,
  patchInvestigationTask,
  InvestigationTaskOverlay,
  useInvestigationTask,
} from '../features/investigation-task';
import {
  ErrorToast,
  ToastNotification,
} from '../components/toast-notification';
import { WithCapabilities } from '../components/authorization';
import { BlockNavigation } from '../components/navigation';
import { TaskWizardButtons } from '../components/ui';
import {
  ClaimNumberInputCard,
  CompleteTaskCards,
  TaskInformationCards,
} from '../features/base-task';
import { RequestResult } from '../data/fetch/result';
import {
  InvestigationTask,
  TaskTypes,
  TabType,
  BaseTask,
} from '../types/types';
import { apiUrl } from '../data/fetch/base';
import { getTask, postTask } from '../features/base-task/data/requests';
import { toast } from 'react-toastify';
import { BaseTaskListDto } from '../types/resource-models';
import { validateAffectedPartnerInvestigation } from '../features/services/partner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InvestigationTaskContactsCards from '../features/investigation-task/components/investigation-task-contacts-cards';
import { UserContext } from '../context';
import produce from 'immer';
import { ReactComponent as WarningImage } from '../assets/icons/warning-ados.svg';

const checkTabType = (tab: string): boolean => {
  switch (tab) {
    case 'claimNumber':
      return true;
    case 'contactInformation':
      return true;
    case 'claimInformation':
      return true;
    case 'completeClaim':
      return true;
    case 'none':
      return true;
    default:
      return false;
  }
};

const createTask = <BaseTaskType extends BaseTask>(
  claimNumber: string,
  setBaseTask: (value: RequestResult<BaseTaskType>) => void,
  setTab: (value: SetStateAction<TabType>) => void,
  refTabs: RefObject<HTMLBalTabsElement>,
  token: string,
  refNextTab?: RefObject<HTMLBalTabItemElement> | null,
) => {
  setBaseTask({ status: 'loading' });
  postTask<BaseTaskType>(
    { claimNumber: claimNumber },
    token,
    `${apiUrl}${APIRoutes.POST_INVESTIGATION_TASK.create({})}`,
  ).then((taskResponse) => {
    if (taskResponse.status === 'success' && refNextTab) {
      setBaseTask(taskResponse);
      refNextTab.current
        ?.getOptions()
        .then((options) => refTabs.current?.select(options));
      setTab('contactInformation');
    } else if (taskResponse.status === 'error' && refNextTab) {
      toast(ErrorToast(taskResponse.errorValue));
      setBaseTask({ status: 'initial' });
    }
  });
};

const InvestigationTaskWizardView = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const taskIdQueryParam = searchParams.get('taskId');
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabInvestigationNumber = useRef<HTMLBalTabItemElement>(null);
  const refTabContactInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabInvestigationInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabCompleteInvestigation = useRef<HTMLBalTabItemElement>(null);

  const { user } = useContext(UserContext);
  const userFetchSuccess =
    user.status === 'success' || user.status === 'loading-with-value';
  const userCapabilities = userFetchSuccess ? user.value.appCapabilities : [];
  const hasEditFraudCapability = userCapabilities.includes(
    'EditFraudInspectionTask',
  );

  const [investigationTask, setInvestigationTask] = useInvestigationTask();
  const [tab, setTab] = useState<TabType>('claimNumber');
  const token = useToken();
  const patchTask = (
    tab: TabType,
    refNextTab: RefObject<HTMLBalTabItemElement>,
  ) => {
    setInvestigationTask({ status: 'loading' });
    if (investigationTask.status === 'success') {
      patchInvestigationTask(
        investigationTask.value,
        investigationTask.localValue,
        token,
      ).then((investigationTaskResponse) => {
        setInvestigationTask(investigationTaskResponse);
        if (investigationTaskResponse.status === 'success') {
          refNextTab.current
            ?.getOptions()
            .then((options) => refTabs.current?.select(options));
          setTab(tab);
        } else if (investigationTaskResponse.status === 'error') {
          toast(ErrorToast(investigationTaskResponse.errorValue));
        }
      });
    }
  };

  const onSearchAction = (
    claimNumber: string,
    refNextTab?: RefObject<HTMLBalTabItemElement> | null,
  ) => {
    createTask(
      claimNumber,
      setInvestigationTask,
      setTab,
      refTabs,
      token,
      refNextTab,
    );
  };

  const onEditTask = async (baseTaskid: string) => {
    setInvestigationTask({ status: 'loading' });
    const taskResponse = await getTask<InvestigationTask>(
      token,
      baseTaskid,
      TaskTypes.InvestigationTask,
    );
    setInvestigationTask(taskResponse);
  };

  useEffect(() => {
    if (taskIdQueryParam) {
      setInvestigationTask({ status: 'loading' });
      getTask<InvestigationTask>(
        token,
        taskIdQueryParam,
        TaskTypes.InvestigationTask,
      ).then((taskResponse) => {
        if (taskResponse.status === 'success') {
          setInvestigationTask(taskResponse);
          setTab('contactInformation');
        } else if (taskResponse.status === 'error') {
          toast(ErrorToast(taskResponse.errorValue));
          setInvestigationTask({ status: 'initial' });
        }
      });
    }
  }, [setInvestigationTask, taskIdQueryParam, token]);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    tab !== 'none' &&
      tab !== 'claimNumber' &&
      investigationTask.status === 'success' &&
      investigationTask.value.status === 'New',
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (investigationTask.status === 'success') {
      if (investigationTask.value.status !== 'New') {
        toast(
          ToastNotification({
            message: t('general.taskNotInNewState'),
            color: 'warning',
          }),
        );
        navigate(
          EBRoutes.INVESTIGATION_TASK_DETAIL.create({
            taskId: investigationTask.value.id,
          }),
        );
      }
    }
    // when adding navigate the naviation block is not working anymore.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investigationTask, t]);
  return (
    <WithCapabilities
      requiredCapabilities={['CreateFraudInspectionTask']}
      showErrorToast
    >
      {investigationTask.status === 'success' && (
        <>
          <InvestigationTaskOverlay
            task={investigationTask.value as unknown as BaseTaskListDto}
            getFullTask={getInvestigationTask}
            hideLinkButtons
          />
          <BlockNavigation
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            taskType={TaskTypes.InvestigationTask}
            taskId={investigationTask.value.id}
          />
        </>
      )}
      <div className="columns wizard is-gapless is-full-height is-full-width scroll-bar-minimal-width-adjust">
        <div className="column is-11 is-flex wizard-max-width-1280px mr-3">
          <BalTabs
            interface="o-steps"
            ref={refTabs}
            value={tab}
            onBalChange={(e) => {
              if (checkTabType(e.detail)) setTab(e.detail as TabType);
            }}
          >
            <BalTabItem
              value="claimNumber"
              label={t('investigationTask.capture')}
              ref={refTabInvestigationNumber}
              done={investigationTask.status === 'success'}
              disabled={tab && tab !== 'claimNumber'}
            >
              <ClaimNumberInputCard
                task={investigationTask}
                setTask={setInvestigationTask}
                refTabs={refTabs}
                refTabContactInformation={refTabContactInformation}
                refNextTab={refTabContactInformation}
                setTab={() => setTab('contactInformation')}
                title={t('investigationService.cardTitle')}
                text={t('investigationService.cardText')}
                pattern={/^[A-Z0-9./]+$/}
                patternErrorMessage={t(
                  'investigationTask.claimPolicyNumberError',
                )}
                taskButtons={
                  <TaskWizardButtons refTabs={refTabs} hasBack={false} />
                }
                placeholder={t('investigationService.claimOrPolicyNumberHint')}
                isInvestigation
                onSearchAction={onSearchAction}
                onCreateNew={(task: RequestResult<InvestigationTask>) => {
                  if (task.status === 'success') {
                    patchInvestigationTask(
                      task.value,
                      produce(task.value, (draftState) => {
                        if (task.value.status === 'New') {
                          draftState.deletedAt = new Date().toISOString();
                        } else {
                          draftState.status = 'Closed';
                        }
                      }),
                      token,
                    ).then((result) => {
                      if (result.status === 'success') {
                        onSearchAction(
                          task.value.claim.claimNumber,
                          refTabContactInformation,
                        );
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    });
                  }
                }}
                taskType={TaskTypes.InvestigationTask}
                onEditTask={onEditTask}
              />
            </BalTabItem>
            <BalTabItem
              value="contactInformation"
              label={t('investigationTask.baseInformation')}
              ref={refTabContactInformation}
              done={
                investigationTask.status === 'success' &&
                investigationTask.value.what.length > 0
              }
            >
              <InvestigationTaskContactsCards
                task={investigationTask}
                setTask={setInvestigationTask}
                patchTask={patchInvestigationTask}
              />
              <TaskWizardButtons
                refTabs={refTabs}
                refPrevTab={refTabContactInformation}
                hasBack={false}
                onClickNext={() =>
                  patchTask('claimInformation', refTabInvestigationInformation)
                }
                disabled={
                  investigationTask.status === 'success' &&
                  (investigationTask.localValue.claim.affectedPartners.some(
                    (affectedPartner) => {
                      return !validateAffectedPartnerInvestigation(
                        affectedPartner,
                      );
                    },
                  ) ||
                    investigationTask.localValue.claim.affectedPartners.filter(
                      (x) => x.role == 'PolicyHolder',
                    ).length > 1)
                }
              />
            </BalTabItem>
            <BalTabItem
              value="claimInformation"
              label={t('investigationTask.caseInformation')}
              ref={refTabInvestigationInformation}
              done={
                investigationTask.status === 'success' &&
                investigationTask.value.what.length > 0
              }
            >
              <TaskInformationCards
                taskProps={{
                  task: investigationTask,
                  setTask: setInvestigationTask,
                  type: TaskTypes.InvestigationTask,
                }}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabContactInformation}
                    setPrevTab={() => {
                      setTab('contactInformation');
                    }}
                  />
                }
                patchTask={() =>
                  patchTask('completeClaim', refTabCompleteInvestigation)
                }
                title={t('inspectionTask.claimInformation.cardTitle')}
              />
            </BalTabItem>
            <BalTabItem
              value="completeClaim"
              label={t('investigationTask.conclusion')}
              ref={refTabCompleteInvestigation}
            >
              <CompleteTaskCards
                task={investigationTask}
                setTask={setInvestigationTask}
                patchTask={patchInvestigationTask}
                taskType={TaskTypes.InvestigationTask}
                submitStatus="InvestigationNew"
                nextLocation={
                  hasEditFraudCapability ? 'INVESTIGATION_TASK' : 'HOME'
                }
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabInvestigationInformation}
                    setPrevTab={() => setTab('claimInformation')}
                    nextText={t(
                      'investigationTask.completeInvestigation.submitButton',
                    )}
                  />
                }
                title={t('investigationTask.completeInvestigation.cardTitle')}
                text={t('investigationTask.completeInvestigation.cardText')}
                inspectorAppCapabilitiy={['EditFraudInspectionTask']}
                canChangeAgent
                setTab={setTab as Dispatch<SetStateAction<string>>}
                quickLinkRegionAssignment
                warning={
                  <>
                    <div className="column is-full pl-2 pt-0">
                      <BalNotification color="warning">
                        <WarningImage className="warning-img mr-2" />
                        <BalText>
                          {t(
                            'investigationTask.completeInvestigation.cardWarningText',
                          )}
                        </BalText>
                      </BalNotification>
                    </div>
                    <BalText className="column is-full mb-0">
                      {t(
                        'investigationTask.completeInvestigation.cardThanksText',
                      )}
                    </BalText>
                    <BalText className="column is-full new-lines">
                      {t(
                        'investigationTask.completeInvestigation.cardFarewell',
                      )}
                    </BalText>
                  </>
                }
              />
            </BalTabItem>
          </BalTabs>
        </div>
      </div>
    </WithCapabilities>
  );
};

export default InvestigationTaskWizardView;
