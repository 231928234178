import {
  BalHeading,
  BalButton,
  BalButtonGroup,
  BalCard,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { useContext, useState } from 'react';
import { PropertyInspectionTask } from '../../../../types/types';
import { useForm } from 'react-hook-form';
import {
  BalDatepickerFormField,
  BalTextareaFormField,
} from '../../../../components/input';
import produce from 'immer';
import { patchInspection } from '../../data/requests';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../../components/toast-notification';
import { PropertyInspectionTaskSendingWizardViewContext } from './property-inspection-task-sending-wizard-view';

interface PropertyInspectionTaskSummaryCardProps {
  onPrevious: () => void;
  onNext: () => void;
}

export const PropertyInspectionTaskSummaryCard = ({
  onPrevious,
  onNext,
}: PropertyInspectionTaskSummaryCardProps): JSX.Element => {
  const propertyInspectionTaskSendingWizardViewContext = useContext(
    PropertyInspectionTaskSendingWizardViewContext,
  );
  const token = propertyInspectionTaskSendingWizardViewContext.token;
  const task =
    propertyInspectionTaskSendingWizardViewContext.propertyInspectionTask;
  const setTask =
    propertyInspectionTaskSendingWizardViewContext.setPropertyInspectionTask;
  const inspection = propertyInspectionTaskSendingWizardViewContext.inspection;
  const setInspection =
    propertyInspectionTaskSendingWizardViewContext.setInspection;

  const { inspectionId } = useContext(
    PropertyInspectionTaskSendingWizardViewContext,
  );

  const { t } = useTranslation();
  useState<RequestResult<PropertyInspectionTask>>(task);
  const methods = useForm({
    mode: 'onChange',
  });

  const [nextSteps, setNextSteps] = useState<string | undefined>(
    inspection.nextSteps,
  );

  const [inspectionDate, setInspectionDate] = useState<string | undefined>(
    inspection.inspectionClaim?.inspectionDate?.substring(0, 10),
  );

  return task.status === 'success' ? (
    <BalCard className="my-5 p-5">
      <BalHeading level="h3">{t('inspectionTask.summary')}</BalHeading>
      <div className="columns is-gapless is-multiline">
        <form className="rows is-multiline is-gapless is-full-width mt-1">
          <BalTextareaFormField
            controllerName="nextSteps"
            methods={methods}
            required={false}
            className="row"
            placeholder={t('inspectionTask.inspection.nextSteps')}
            title={t('inspectionTask.inspection.nextSteps')}
            rows={20}
            value={nextSteps}
            onBalChange={(event) => {
              if (event.detail !== null && event.detail !== undefined) {
                setNextSteps(event.detail as string);

                setInspection &&
                  setInspection(
                    produce(inspection, (draft) => {
                      draft.nextSteps = event.detail as string;
                      draft.changedAt = new Date().toISOString();
                    }),
                  );
              }
            }}
          />
          <BalDatepickerFormField
            title={t('inspectionTask.scheduledInspectionDate')}
            className="mb-4"
            controllerName={'inspectionDate'}
            methods={methods}
            placeholder={t('inspectionTask.scheduledInspectionDate')}
            value={inspectionDate}
            onBalChange={(event: CustomEvent<null | string | undefined>) => {
              if (event.detail !== null && event.detail !== undefined) {
                setInspectionDate(event.detail);

                setInspection &&
                  setInspection(
                    produce(inspection, (draft) => {
                      const inspectionClaim = draft.inspectionClaim;
                      if (inspectionClaim) {
                        inspectionClaim.inspectionDate = event.detail as string;
                        draft.changedAt = new Date().toISOString();
                      }
                    }),
                  );
              }
            }}
          />
        </form>
      </div>
      <BalButtonGroup position="right">
        <BalButton
          elementType="button"
          color="primary-light"
          onClick={(event) => event.detail == 1 && onPrevious()}
        >
          {t('general.buttons.back')}
        </BalButton>
        <BalButton
          color="info"
          onClick={async (event) => {
            if (
              event.detail == 1 &&
              inspectionDate != null &&
              inspectionDate != ''
            ) {
              const originalInspection = task.localValue.inspections.find(
                (inspection) => inspection.id && inspection.id == inspectionId,
              );

              if (originalInspection) {
                const result = await patchInspection(
                  originalInspection,
                  inspection,
                  token,
                );

                if (result.status === 'success') {
                  //Update next steps, inspectiondate and changedat in the local task with inspectionid
                  setTask &&
                    setTask(
                      produce(task, (draft) => {
                        const inspectionIndex =
                          draft.localValue.inspections.findIndex(
                            (inspection) => inspection.id === inspectionId,
                          );

                        if (inspectionIndex !== -1) {
                          draft.localValue.inspections[
                            inspectionIndex
                          ].changedAt = inspection.changedAt;
                          draft.localValue.inspections[
                            inspectionIndex
                          ].nextSteps = inspection.nextSteps;
                          const inspectionClaim =
                            draft.localValue.inspections[inspectionIndex]
                              .inspectionClaim;
                          if (inspectionClaim) {
                            inspectionClaim.inspectionDate =
                              inspection.inspectionClaim?.inspectionDate;
                          }
                        }
                      }),
                    );

                  onNext();
                } else if (result.status === 'error') {
                  toast(ErrorToast(result.errorValue));
                }
              }
            }
          }}
        >
          {t('general.buttons.next')}
        </BalButton>
      </BalButtonGroup>
    </BalCard>
  ) : (
    <></>
  );
};
