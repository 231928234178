import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import { useToken } from '../../../hooks';
import {
  CombinedState,
  initialCombinedState,
} from '../../base-task/data/hooks';
import { RequestResult } from '../../../data';
import {
  BaseTaskChangeDto,
  BaseTaskFilterCriteria,
  SortOrder,
} from '../../../types/resource-models';
import { AccidentInspectionTask } from '../../../types/types';
import {
  getAccidentInspectionTasks,
  getAccidentInspectionTask,
  getAccidentInspectionTaskAllHistory,
} from './requests';
import { Globals } from '../../../utils';
import { getMenuItems } from '../components/accident-inspection-task-view-menu-items';

export function useAccidentInspectionTask(): [
  RequestResult<AccidentInspectionTask>,
  React.Dispatch<RequestResult<AccidentInspectionTask>>,
] {
  const [accidentInspectionTaskResponse, setAccidentInspectionTaskResponse] =
    useState<RequestResult<AccidentInspectionTask>>({
      status: 'initial',
    });

  return [accidentInspectionTaskResponse, setAccidentInspectionTaskResponse];
}

export type CombinedStateProps = {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
};

export function useAccidentInspectionTasksFromApi(): CombinedStateProps {
  const { t } = useTranslation();
  const token = useToken();
  const { user } = useContext(UserContext);

  const [combinedState, setCombinedState] =
    useState<CombinedState>(initialCombinedState);

  useEffect(() => {
    if (
      (user.status === 'success' || user.status === 'loading-with-value') &&
      !combinedState.filter.assigneeOrCreatorUserId &&
      combinedState.tasks.status === 'initial'
    ) {
      let customOrderField: string | undefined = undefined;
      let customOrderBy: SortOrder | undefined = undefined;
      if (user.status === 'success') {
        const userTableSetting = user.localValue.userTableSettings.find(
          (x) => x.type === 'AccidentInspectionTask',
        );
        if (userTableSetting?.orderField) {
          customOrderField = userTableSetting?.orderField;
          customOrderBy = userTableSetting?.orderBy;
        }
      }
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.tasks = { status: 'loading' };
          // apply first quick filter from menu as default filter
          const menuItems = getMenuItems(t, user.value);
          const emptyFilter = Globals.filterDefault;
          draftState.defaultFilter = {
            ...emptyFilter,
            ...(menuItems[0].filter as Partial<BaseTaskFilterCriteria>),
          };
          draftState.filter = customOrderField
            ? {
                ...draftState.defaultFilter,
                orderField: customOrderField,
                orderBy: customOrderBy ?? 'asc',
              }
            : draftState.defaultFilter;
          draftState.label = menuItems[0].label;
        }),
      );
    }
  }, [user, combinedState, t]);

  useEffect(() => {
    if (
      token !== '' &&
      (combinedState.tasks.status === 'loading' ||
        combinedState.tasks.status === 'loading-with-value')
    ) {
      getAccidentInspectionTasks(
        token,
        combinedState.filter as BaseTaskFilterCriteria,
      ).then((result) =>
        setCombinedState(
          produce(combinedState, (draftState) => {
            draftState.tasks = result;
          }),
        ),
      );
    }
  }, [user, token, combinedState]);

  return { combinedState, setCombinedState };
}

export function useAccidentInspectionTaskFromApi(
  taskId: string,
): [
  RequestResult<AccidentInspectionTask>,
  React.Dispatch<RequestResult<AccidentInspectionTask>>,
] {
  const token = useToken();
  const [accidentInspectionTaskResponse, setAccidentInspectionTaskResponse] =
    useState<RequestResult<AccidentInspectionTask>>({
      status: 'loading',
    });

  useEffect(() => {
    setAccidentInspectionTaskResponse({ status: 'loading' });
    if (token !== '' && taskId !== '') {
      getAccidentInspectionTask(token, taskId).then(
        setAccidentInspectionTaskResponse,
      );
    }
  }, [token, taskId]);

  return [accidentInspectionTaskResponse, setAccidentInspectionTaskResponse];
}

export function useAccidentInspectionTaskHistoryFromApi(
  taskId: string,
): [
  RequestResult<BaseTaskChangeDto[]>,
  React.Dispatch<RequestResult<BaseTaskChangeDto[]>>,
] {
  const token = useToken();
  const [accidentInspectionTaskChanges, setAccidentInspectionTaskChanges] =
    useState<RequestResult<BaseTaskChangeDto[]>>({
      status: 'initial',
    });
  useEffect(() => {
    if (token !== '' && accidentInspectionTaskChanges.status !== 'success') {
      if (accidentInspectionTaskChanges.status !== 'loading-with-value')
        setAccidentInspectionTaskChanges({ status: 'loading' });
      getAccidentInspectionTaskAllHistory(token, taskId).then((result) => {
        if (result.status === 'success') {
          setAccidentInspectionTaskChanges({
            status: 'success',
            value: result.value,
            localValue: result.localValue,
          });
        } else if (result.status === 'error') {
          setAccidentInspectionTaskChanges(result);
        }
      });
    }
  }, [taskId, token, accidentInspectionTaskChanges.status]);
  return [accidentInspectionTaskChanges, setAccidentInspectionTaskChanges];
}
