import {
  BalIcon,
  BalText,
  BalButton,
  BalHeading,
} from '@baloise/design-system-components-react';
import { AttachmentDto } from '../../../../types/resource-models';
import { formatDateTime } from '../../../../utils/date';
import { useToken } from '../../../../hooks';
import { concat, convertBytesToMB } from '../../../../utils';
import { toast } from 'react-toastify';
import { balModalController } from '../../../../controller/controllers';
import { ErrorToast } from '../../../../components/toast-notification';
import { RemoveFileConfirmationModal, getAttachment } from '../../../files';
import { Translation } from 'react-i18next';
import { balIconDownload } from '@baloise/design-system-icons';
import { FileViewModal } from '../../../../components/ui';
type AttachmentProps = {
  attachments: AttachmentDto[];
  disabled?: boolean;
  withScrollbar?: boolean;
  maximumHeight?: string;
  removeFile?: (fileName: string) => void;
};

const AttachmentDownloadViewButtons = ({ file }: { file: AttachmentDto }) => {
  const token = useToken();
  const downloadAttachment = (view: boolean) => {
    getAttachment(token, file.id).then((fileResponse) => {
      if (fileResponse.status === 'success' && !view) {
        const a = document.createElement('a');
        a.href = fileResponse.value;
        a.download = file.fileName;
        a.target = '_blank';
        a.click();
      } else if (fileResponse.status === 'success' && view) {
        const fileUrl = fileResponse.value;
        const windowName = file.fileName;
        const newWindow = window.open(fileUrl, windowName);
        setTimeout(() => {
          if (newWindow) {
            newWindow.document.title = file.fileName;
          }
        }, 400);
      } else if (fileResponse.status === 'error') {
        toast(ErrorToast(fileResponse.errorValue));
      }
    });
  };
  return (
    <div className="is-flex is-align-items-center">
      <FileViewModal
        fileId={file.id}
        label={file.fileName}
        contentType={file.contentType}
      />
      <BalButton
        className="ml-3 mr-1"
        color="primary"
        size="small"
        square
        onClick={() => {
          downloadAttachment(false);
        }}
      >
        <BalIcon svg={balIconDownload} size="small" />
      </BalButton>
    </div>
  );
};

type AttachmentDeleteButtonProps = {
  fileName: string;
  removeFile: (fileName: string) => void;
  disabled?: boolean;
};
const AttachmentDeleteButton = (props: AttachmentDeleteButtonProps) => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: RemoveFileConfirmationModal,
      componentProps: {
        fileName: props.fileName,
        removeFile: props.removeFile,
      },
      cssClass: 'center-modal',
    });
    return await modal.present();
  };

  return (
    <BalButton
      disabled={props.disabled}
      className="ml-3 mr-1"
      color="primary"
      icon="trash"
      onClick={(event) => event.detail == 1 && openModal()}
      size="small"
      square
    />
  );
};

export const BaseTaskAttachments = ({
  attachments,
  disabled,
  withScrollbar,
  maximumHeight,
  removeFile,
}: AttachmentProps): JSX.Element => {
  const mapAttachments = attachments.map((file: AttachmentDto) => (
    <div
      key={file.fileName}
      className="mt-1 mx-1 is-flex is-align-items-center"
    >
      <BalIcon className="mr-4" name="document" size="small" />
      <div className="mb-1 bottom-blue-line is-flex is-justify-content-space-between is-full-width">
        <div>
          <BalText bold size="small" color="info">
            {file.fileName}
          </BalText>
          <BalText size="small" color="primary-light">
            {concat([
              file.contentType
                .substring(file.contentType.lastIndexOf('/') + 1)
                .toUpperCase(),
              `(${convertBytesToMB(file.size)} MB)`,
              concat(['(', formatDateTime(file.savedOn), ')'], 'none'),
            ])}
          </BalText>
        </div>
        <div className="is-flex is-flex-direction-row my-auto">
          {!disabled && <AttachmentDownloadViewButtons file={file} />}
          {removeFile && !disabled && (
            <AttachmentDeleteButton
              fileName={file.fileName}
              removeFile={removeFile}
            />
          )}
        </div>
      </div>
    </div>
  ));

  return (
    <Translation>
      {(t) => {
        return (
          <>
            <BalHeading level="h5" space="none" className="my-5">
              {t('general.claim.attachments')}
            </BalHeading>
            <div
              className={`${withScrollbar ? 'scroll-bar-minimal' : ''} my-3`}
              style={{ maxHeight: maximumHeight ?? '' }}
            >
              {mapAttachments}
            </div>
          </>
        );
      }}
    </Translation>
  );
};
