import { MenuItem } from '../../view-menu/view-menu-item';
import { TFunction } from 'i18next';
import { UserDto, UserMenuItemDto } from '../../../types/resource-models';
import { TaskTypes } from '../../../types/types';
import { Filter } from '../../view-menu/view-menu';

export const getBaseMenuItems = (t: TFunction, user?: UserDto): MenuItem[] => {
  return [
    {
      isCustomFilter: false,
      type: 'PropertyInspectionTask',
      label: t('taskOverview.yourOpenTasks'),
      filter: {
        status: [
          'Assigned',
          'InProgressLocal',
          'InProgressOffice',
          'InProgressVideo',
          'InProgressWithPartner',
        ],
        assigneeOrCreatorUserId: user?.id,
        orderField: 'Status,CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'PropertyInspectionTask',
      label: t('taskOverview.allOpenTasks'),
      filter: {
        status: [
          'Assigned',
          'InProgressLocal',
          'InProgressOffice',
          'InProgressVideo',
          'InProgressWithPartner',
        ],
        orderField: 'Status,CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'PropertyInspectionTask',
      label: t('taskOverview.draftTasks'),
      filter: {
        status: ['New'],
        orderField: 'Status,CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'PropertyInspectionTask',
      label: t('taskOverview.allTasks'),
      filter: {
        creatorUserId: [],
        assigneeUserId: [],
        orderField: 'Status,CreatedAt',
        orderBy: 'asc',
      },
    },
    {
      isCustomFilter: false,
      type: 'PropertyInspectionTask',
      label: t('taskOverview.archivedTasks'),
      filter: {
        status: ['Closed', 'Rejected', 'SentToArchive'],
        orderField: 'Status,CreatedAt',
        orderBy: 'desc',
      },
    },
  ];
};

export const getMenuItems = (t: TFunction, user?: UserDto): MenuItem[] => {
  const baseMenuItems = getBaseMenuItems(t, user);
  // Add custom menu items.
  if (user) {
    user.userMenuItems.forEach((userMenuItemDto: UserMenuItemDto) => {
      if (
        userMenuItemDto.type === TaskTypes[TaskTypes.PropertyInspectionTask]
      ) {
        const filter: Filter = JSON.parse(userMenuItemDto.filter);
        // Custom menu items sort info is not compatible with user setting sort info.
        filter.orderBy = undefined;
        filter.orderField = undefined;
        const menuItem: MenuItem = { ...userMenuItemDto, filter: filter };
        baseMenuItems.push(menuItem);
      }
    });
  }
  return baseMenuItems;
};

export const getDefaultMenuItem = (t: TFunction, user?: UserDto): MenuItem => {
  return getBaseMenuItems(t, user)[0];
};
