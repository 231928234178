import {
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useState, useEffect } from 'react';
import lang from 'suneditor-react/dist/types/lang';
import { balModalController } from '../../../../../controller/controllers';
import { RequestResult } from '../../../../../data';
import {
  InsuranceSurveyDto,
  InsuranceSurveyListDto,
} from '../../../../../types/resource-models';
import { InsuranceSurveyEditForm } from './insurance-survey-edit-form';

export interface InsuranceSurveyEditFormModalProps {
  title: string;
  initialValue: RequestResult<InsuranceSurveyDto>;
  initialAddingState: boolean;
  language: lang;
  readonly?: boolean;
  onSave: (insuranceSurveyId?: string) => void;
  onDeleteInsuranceSurvey: (
    insuranceSurvey: InsuranceSurveyListDto,
  ) => Promise<void>;
}

export const InsuranceSurveyEditFormModal = ({
  title,
  initialValue,
  initialAddingState,
  language,
  readonly,
  onSave,
  onDeleteInsuranceSurvey,
}: InsuranceSurveyEditFormModalProps): JSX.Element => {
  const [saved, setSaved] = useState<boolean>(false);
  useEffect(() => {
    if (saved) {
      balModalController.dismiss();
    }
  }, [saved]);

  return (
    <>
      <BalModalHeader className="is-full-width create-contacts-header">
        <BalHeading level="h3">{title}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <InsuranceSurveyEditForm
          initialValue={initialValue}
          initialAddingState={initialAddingState}
          language={language}
          readonly={readonly}
          onSave={(insuranceSurveyId?: string) => {
            onSave(insuranceSurveyId);
            setSaved(true);
          }}
          onDeleteInsuranceSurvey={onDeleteInsuranceSurvey}
        ></InsuranceSurveyEditForm>
      </BalModalBody>
    </>
  );
};
