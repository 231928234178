import produce from 'immer';
import { RequestResult } from '../../../../../data';
import {
  InsuranceSurveyListDto,
  InsuranceSurveyFilterCriteriaDto,
} from '../../../../../types/resource-models';

export type InsuranceSurveyCombinedState = {
  items: RequestResult<InsuranceSurveyListDto[]>;
  filter: InsuranceSurveyFilterCriteriaDto;
  defaultFilter: InsuranceSurveyFilterCriteriaDto;
  label: string;
};

export const initialInsuranceSurveyCombinedState: InsuranceSurveyCombinedState =
  {
    items: { status: 'initial' },
    defaultFilter: { orderBy: 'asc' },
    filter: { orderBy: 'asc' },
    label: '-',
  };

export const getInitialInsuranceSurveyCombinedState = (
  investigationTaskId: string,
): InsuranceSurveyCombinedState => {
  const filter: InsuranceSurveyFilterCriteriaDto = {
    orderBy: 'asc',
    ...({
      investigationTaskId: investigationTaskId,
    } as Partial<InsuranceSurveyFilterCriteriaDto>),
  };

  return produce(initialInsuranceSurveyCombinedState, (draftState) => {
    draftState.defaultFilter = {
      orderBy: 'asc',
      ...(filter as Partial<InsuranceSurveyFilterCriteriaDto>),
    };
    draftState.filter = draftState.defaultFilter;
    draftState.label = '-';
    draftState.items.status = 'loading';
  });
};
