import './i18n/config';
import App from './app/app';
import './index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { authProvider } from './data/auth';
import React from 'react';
import ReactDOM from 'react-dom/client';

const container = document.getElementById('root') as Element;
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <MsalProvider instance={authProvider}>
        <App />
      </MsalProvider>
    </Router>
  </React.StrictMode>,
);
