import React from 'react';
import { createRoot } from 'react-dom/client';
import { FrameworkDelegate } from '@baloise/design-system-components';
import { MsalProvider } from '@azure/msal-react';
import { authProvider } from '../data/auth';

export const ReactDelegate = (): FrameworkDelegate => {
  let div: HTMLElement;

  const attachViewToDom = (
    container: HTMLElement,
    component: React.FunctionComponent,
    componentProps: React.InputHTMLAttributes<HTMLInputElement> &
      React.ClassAttributes<HTMLInputElement> & { modal?: unknown } = {},
    classes?: string[],
  ) => {
    /**
     * Ionic Framework passes in modal and popover element
     * refs as props, but if these are not defined
     * on the React component instance as props, React will
     * warn the user.
     */
    delete componentProps['modal'];

    div = document.createElement('div');
    classes && div.classList.add(...classes);
    container.appendChild(div);

    const componentElement = React.createElement(component, componentProps);
    const root = createRoot(div);
    root.render(
      <MsalProvider instance={authProvider}>{componentElement}</MsalProvider>,
    );

    return Promise.resolve(div);
  };

  const removeViewFromDom = (container: HTMLElement) => {
    try {
      div && container.removeChild(div);
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.warn(
          `Catched excpetion while removing view from dom: ${e.message}`,
        );
      } else {
        console.warn(
          'Catched excpetion while removing view from dom without message',
        );
      }
    }
    return Promise.resolve();
  };

  return { attachViewToDom, removeViewFromDom };
};
