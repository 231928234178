import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import { CraftsmanListDto } from '../../../../types/resource-models';
import { NetworkPartnerSelector } from '../../../network-partners/components';

export interface PropertyInspectionTaskCraftsmanRecipientsAddButtonsProps {
  onAddRecipient: (craftsman: CraftsmanListDto | undefined) => void;
}

const PropertyInspectionTaskCraftsmanRecipientsAddButton = ({
  onAddRecipient,
}: PropertyInspectionTaskCraftsmanRecipientsAddButtonsProps): JSX.Element => {
  const openCraftsmanSelectorModal = async () => {
    const modal = await balModalController.create({
      component: NetworkPartnerSelector,
      modalWidth: 1024,
      componentProps: {
        onSelectedSuccess: onAddRecipient,
        title: t('networkPartners.selectorTitle'),
        disableNoChoice: true,
        craftsmanFilter: {
          propertyCraftsmanOnly: true,
        },
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditPropertyInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        className="ml-2"
        square
        outlined
        size="small"
        color="info"
        icon="plus"
        onClick={(event) => event.detail == 1 && openCraftsmanSelectorModal()}
      />
    </WithCapabilities>
  );
};

export default PropertyInspectionTaskCraftsmanRecipientsAddButton;
