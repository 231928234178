import {
  BalHeading,
  BalButtonGroup,
  BalCard,
  BalRadio,
  BalText,
  BalRadioGroup,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import {
  AccidentInspectionTask,
  AffectedPartner,
  BaseTask,
  InvestigationTask,
  PropertyInspectionTask,
  TaskType,
  VehicleTask,
} from '../../../types/types';
import { concat, concatAddress, Globals, StringCap } from '../../../utils';
import { getDefaultAddressWithPartnerId } from '../../../utils/utilities';
import { deletePartner, patchPartner, postPartner } from '../data/requests';
import produce from 'immer';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import { toast } from 'react-toastify';
import { CreateEditContactButton } from './create-edit-contact-button';
import { ErrorToast } from '../../../components/toast-notification';
import { PartnerDto } from '../../../types/resource-models';

export const ContactSelector = <T extends BaseTask>({
  className,
  card = true,
  addressDetailSwitch = true,
  addressOpen,
  task,
  setTask,
  affectedPartnersProp,
  patchTask,
  taskType,
}: {
  className?: string;
  card?: boolean;
  addressDetailSwitch?: boolean;
  addressOpen?: boolean;
  task: RequestResult<T>;
  setTask: React.Dispatch<RequestResult<T>>;
  affectedPartnersProp?: AffectedPartner[];
  patchTask(
    task: T,
    taskNew: T,
    bearerToken: string,
  ):
    | Promise<RequestResult<AccidentInspectionTask>>
    | Promise<RequestResult<PropertyInspectionTask>>
    | Promise<RequestResult<VehicleTask>>
    | Promise<RequestResult<InvestigationTask>>;
  taskType: TaskType;
}): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const onAdd = (partner: AffectedPartner) => {
    if (task.status === 'success') {
      postPartner(token, task.value.id, partner, taskType).then(
        (result: RequestResult<AffectedPartner>) => {
          if (result.status === 'success') {
            setTask(
              produce(task, (draftState) => {
                draftState.localValue.claim.affectedPartners = [
                  ...task.localValue.claim.affectedPartners,
                  result.value,
                ];
                draftState.value.claim.affectedPartners = [
                  ...task.localValue.claim.affectedPartners,
                  result.value,
                ];
                draftState.localValue.contactId = result.value.id;
              }),
            );
          } else if (result.status === 'error') {
            toast(ErrorToast(result.errorValue));
          }
        },
      );
    }
  };
  const onChange = (partner: AffectedPartner) => {
    if (task.status === 'success') {
      const oldPartner = task.localValue.claim.affectedPartners.find(
        (affectedPartner) => {
          return affectedPartner.id === partner.id;
        },
      );
      if (oldPartner && oldPartner !== partner) {
        patchPartner(token, oldPartner, partner).then((result) => {
          if (result.status === 'success') {
            const affectedPartners = task.localValue.claim.affectedPartners.map(
              (affectedPartner) => {
                return affectedPartner.id !== oldPartner.id
                  ? affectedPartner
                  : { ...oldPartner, partner: result.value };
              },
            );
            setTask(
              produce(task, (draftState) => {
                draftState.localValue.claim.affectedPartners = affectedPartners;
                draftState.localValue.contactId = oldPartner.id;
                draftState.value.claim.affectedPartners = affectedPartners;
              }),
            );
          } else if (result.status === 'error') {
            toast(ErrorToast(result.errorValue));
          }
        });
      } else {
        setTask(
          produce(task, (draftState) => {
            draftState.localValue.contactId = partner.id;
          }),
        );
      }
    }
  };
  const onDelete = async (partner: AffectedPartner) => {
    if (task.status === 'success' && task.localValue.contactId === partner.id) {
      const policyHolder = task.localValue.claim.affectedPartners.find(
        (partner) => partner.role === 'PolicyHolder',
      )?.id;
      const otherContacts = task.localValue.claim.affectedPartners.filter(
        (ap) => ap.id !== partner.id,
      );
      const otherContact =
        otherContacts.length > 0 ? otherContacts[0].id : undefined;
      const newContact = policyHolder ? policyHolder : otherContact ?? '';
      patchTask(
        task.value,
        produce(task, (draftState) => {
          draftState.localValue.contactId = newContact;
        }).localValue,
        token,
      ).then((result) => {
        if (result.status === 'success') {
          deletePartner(
            token,
            partner.partner.type === 'person'
              ? partner.partner.person.id
              : partner.partner.company.id,
          ).then((data) => {
            if (data.status === 'success-no-value') {
              const partners = task.localValue.claim.affectedPartners.filter(
                (affectedPartner) => affectedPartner.id !== partner.id,
              );
              setTask(
                produce(task, (draftState) => {
                  draftState.localValue.claim.affectedPartners = partners;
                  draftState.value.claim.affectedPartners = partners;
                  draftState.localValue.contactId = newContact;
                }),
              );
            } else if (data.status === 'error') {
              toast(ErrorToast(data.errorValue));
            }
          });
        } else if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      });
    } else
      deletePartner(
        token,
        partner.partner.type === 'person'
          ? partner.partner.person.id
          : partner.partner.company.id,
      ).then((result: RequestResult<AffectedPartner>) => {
        if ((result.status === 'success-no-value', task.status === 'success')) {
          const partners = task.localValue.claim.affectedPartners.filter(
            (affectedPartner) => affectedPartner.id !== partner.id,
          );
          setTask(
            produce(task, (draftState) => {
              draftState.localValue.claim.affectedPartners = partners;
              draftState.value.claim.affectedPartners = partners;
            }),
          );
        } else if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      });
  };
  const ContactSelectorChildren = () => {
    if (task.status === 'success') {
      const affectedPartners =
        affectedPartnersProp ?? task.localValue.claim.affectedPartners;
      return (
        <>
          <BalModalHeader>
            <BalHeading space="none" level="h3" className="ml-2 mb-5 is-full">
              {t('general.contact.contactInformation')}
            </BalHeading>
          </BalModalHeader>
          <BalText className="pt-0 column is-full is-multiline">
            {t('general.contact.instruction')}
          </BalText>
          {affectedPartners.length <= 0 && (
            <BalText className="ml-4" color="danger">
              {t('general.contact.pleaseAddContact')}
            </BalText>
          )}
          <div className="ml-2 pr-3 is-full-width">
            <BalRadioGroup value={task.localValue.contactId}>
              <div className="is-full-width bal-radio-checkbox-group__inner">
                {affectedPartners.map((affectedPartner) => {
                  if (affectedPartner.partner.type === 'person') {
                    const person = affectedPartner.partner.person;
                    const userRole = t(
                      `partnerRole.${affectedPartner.role ?? ''}`,
                    );
                    return (
                      <div
                        className="is-full-width is-flex is-justify-content-space-between is-align-items-center"
                        key={affectedPartner.id}
                      >
                        <BalRadio
                          className="mb-2"
                          key={affectedPartner.id}
                          value={affectedPartner.id}
                          onBalClick={() => {
                            onChange(affectedPartner);
                          }}
                        ></BalRadio>
                        <div className="columns is-gapless is-vcentered mb-0">
                          {(affectedPartner.role !== Globals.noneValue ||
                            person.function) && (
                            <BalText className="column is-4">
                              {concat(
                                [
                                  userRole !== '-' ? userRole : undefined,
                                  person.function,
                                ],
                                userRole !== '-'
                                  ? 'space-slash-space'
                                  : 'space',
                              )}
                            </BalText>
                          )}
                          <BalText color="primary-light" className="column">
                            {StringCap(
                              concat(
                                [
                                  concat(
                                    [
                                      concat([
                                        person.gender != 'Undisclosed'
                                          ? t(`genders.gender.${person.gender}`)
                                          : '',
                                        person.displayName,
                                      ]),
                                      person.phoneNumber,
                                      person.email,
                                    ],
                                    'comma-space',
                                  ),
                                  concatAddress(person.address),
                                ],
                                'comma-space',
                              ),
                              80,
                            )}
                          </BalText>
                        </div>
                        <CreateEditContactButton
                          policyHolderId={task.localValue.claim.policyHolderId}
                          edit
                          detailSwitch={addressDetailSwitch}
                          addressOpen={addressOpen}
                          affectedPartnerToEdit={affectedPartner}
                          requiredPhoneOrMail
                          hasFunction
                          onSave={(partner, role) => {
                            onChange({
                              id: affectedPartner.id,
                              partner: partner,
                              role: role ?? affectedPartner.role,
                            });
                          }}
                          onDelete={(partner) => {
                            onDelete({
                              id: affectedPartner.id,
                              partner: partner,
                              role: affectedPartner.role,
                            });
                          }}
                          hasAddress
                        />
                      </div>
                    );
                  } else if (affectedPartner.partner.type === 'company') {
                    const company = affectedPartner.partner.company;
                    const userRole = t(
                      `partnerRole.${affectedPartner.role ?? ''}`,
                    );
                    return (
                      <div
                        className="is-full-width is-flex is-justify-content-space-between is-align-items-center  bal-radio-checkbox-group_inner"
                        key={affectedPartner.id}
                      >
                        <BalRadio
                          className="mb-2"
                          key={affectedPartner.id}
                          value={affectedPartner.id}
                          onBalClick={() => {
                            onChange(affectedPartner);
                          }}
                        ></BalRadio>
                        <div className="columns is-gapless is-vcentered mb-0">
                          {(affectedPartner.role !== Globals.noneValue ||
                            company.function) && (
                            <BalText className="column is-4">
                              {concat(
                                [
                                  userRole !== '-' ? userRole : undefined,
                                  company.function,
                                ],
                                userRole !== '-'
                                  ? 'space-slash-space'
                                  : 'space',
                              )}
                            </BalText>
                          )}
                          <BalText color="primary-light" className="column">
                            {StringCap(
                              concat(
                                [
                                  company.name,
                                  company.phoneNumber,
                                  company.email,
                                  concatAddress(company.address),
                                ],
                                'comma-space',
                              ),
                              80,
                            )}
                          </BalText>
                        </div>
                        <CreateEditContactButton
                          policyHolderId={task.localValue.claim.policyHolderId}
                          edit
                          detailSwitch={addressDetailSwitch}
                          affectedPartnerToEdit={affectedPartner}
                          requiredPhoneOrMail
                          hasFunction
                          onSave={(partner, role) => {
                            onChange({
                              id: affectedPartner.id,
                              partner: partner,
                              role: role ?? affectedPartner.role,
                            });
                          }}
                          onDelete={(partner) => {
                            onDelete({
                              id: affectedPartner.id,
                              partner: partner,
                              role: affectedPartner.role,
                            });
                          }}
                          hasAddress
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </BalRadioGroup>
          </div>
          <BalText
            size="small"
            color="primary-light"
            className="column is-12 ml-4 mt-4"
          >
            {t('general.contact.createContactHint')}
          </BalText>
          <BalButtonGroup className="px-3 column is-12">
            <CreateEditContactButton
              policyHolderId={task.localValue.claim.policyHolderId}
              detailSwitch={addressDetailSwitch}
              key="createContact"
              hasFunction
              requiredPhoneOrMail
              onSave={(partner, role) => {
                const partnerUpdated =
                  partner.type === 'person'
                    ? {
                        ...partner,
                        person: {
                          ...partner.person,
                          address:
                            JSON.stringify(partner.person.address) ==
                            JSON.stringify(
                              getDefaultAddressWithPartnerId(
                                (partner as PartnerDto).id,
                              ),
                            )
                              ? undefined
                              : partner.person.address,
                        },
                      }
                    : {
                        ...partner,
                        company: {
                          ...partner.company,
                          address:
                            JSON.stringify(partner.company.address) ==
                            JSON.stringify(
                              getDefaultAddressWithPartnerId(
                                (partner as PartnerDto).id,
                              ),
                            )
                              ? undefined
                              : partner.company.address,
                        },
                      };

                onAdd({
                  id: '00000000-0000-0000-0000-000000000000',
                  partner: partnerUpdated,
                  role: role ?? Globals.noneValue,
                });
              }}
              hasAddress
            />
          </BalButtonGroup>
        </>
      );
    } else return <></>;
  };
  return card ? (
    <BalCard className={concat(['p-5 columns is-multiline', className])}>
      {ContactSelectorChildren()}
    </BalCard>
  ) : (
    ContactSelectorChildren()
  );
};
