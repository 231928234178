import { APIRoutes } from '../../../router/router';
import { apiUrl } from '../../../data/fetch/base';
import { jsonReq, RequestResult } from '../../../data';
import {
  AttachmentDto,
  BaseTaskDto,
  ImportStateDto,
} from '../../../types/resource-models';
import {
  fileReq,
  makeAuthHeaders,
  makeAuthFileHeaders,
  typeFromApi,
} from '../../../data/fetch/requests';
import { AttachmentEntityType, TaskTypes } from '../../../types/types';
import imageCompression from 'browser-image-compression';

export const deleteFile = async (
  bearerToken: string,
  file: AttachmentDto,
): Promise<RequestResult<void>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.DELETE_ATTACHMENT.create({ fileId: file.id })}`,
    {
      method: 'DELETE',
      headers: await makeAuthHeaders(bearerToken),
    },
  );
  return jsonReq(request, typeFromApi);
};

export const getAttachment = async (
  bearerToken: string,
  fileId: string,
): Promise<RequestResult<string>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.GET_ATTACHMENT.create({ fileId: fileId })}`,
    {
      method: 'GET',
      headers: await makeAuthFileHeaders(bearerToken),
    },
  );
  return fileReq(request);
};

export const getPropertyReport = async (
  bearerToken: string,
  taskId: string,
  fullReport?: boolean,
): Promise<RequestResult<string>> => {
  const url = `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_REPORT.create({
    taskId: taskId,
  })}${fullReport ? '/full' : ''}`;
  const request = new Request(url, {
    method: 'GET',
    headers: await makeAuthFileHeaders(bearerToken),
  });
  return fileReq(request);
};

export const getInspectionReport = async (
  bearerToken: string,
  taskId: string,
  inspectionId: string,
): Promise<RequestResult<string>> => {
  const url = `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_INSPECTION_REPORT.create(
    {
      taskId: taskId,
      inspectionId: inspectionId,
    },
  )}`;
  const request = new Request(url, {
    method: 'GET',
    headers: await makeAuthFileHeaders(bearerToken),
  });
  return fileReq(request);
};

export const getAccidentInspectionTaskReport = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<string>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.GET_ACCIDENT_INSPECTION_TASK_REPORT.create({
      taskId: taskId,
    })}`,
    {
      method: 'GET',
      headers: await makeAuthFileHeaders(bearerToken),
    },
  );
  return fileReq(request);
};

export const getVehicleTaskReport = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<string>> => {
  const route: string = APIRoutes.GET_VEHICLE_TASK_REPORT.create({
    taskId: taskId,
  });

  const request = new Request(`${apiUrl}${route}`, {
    method: 'GET',
    headers: await makeAuthFileHeaders(bearerToken),
  });
  return fileReq(request);
};

export const getVehicleXml = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<string>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.GET_VEHICLE_INSPECTION_TASK_XML.create({
      taskId: taskId,
    })}`,
    {
      method: 'GET',
      headers: await makeAuthFileHeaders(bearerToken),
    },
  );
  return fileReq(request);
};

export const getInvestigationReport = async (
  bearerToken: string,
  taskId: string,
  fullReport?: boolean,
): Promise<RequestResult<string>> => {
  const url = `${apiUrl}${APIRoutes.GET_INVESTIGATION_TASK_REPORT.create({
    taskId: taskId,
  })}${fullReport ? '/investigation' : ''}`;
  const request = new Request(url, {
    method: 'GET',
    headers: await makeAuthFileHeaders(bearerToken),
  });
  return fileReq(request);
};

export const getInvestigationJournalReport = async (
  bearerToken: string,
  journalId: string,
): Promise<RequestResult<string>> => {
  const url = `${apiUrl}${APIRoutes.GET_INVESTIGATION_TASK_JOURNAL_REPORT.create(
    {
      journalId,
    },
  )}`;
  const request = new Request(url, {
    method: 'GET',
    headers: await makeAuthFileHeaders(bearerToken),
  });
  return fileReq(request);
};

export const getInsuranceSurveyReport = async (
  bearerToken: string,
  insuranceSurveyId: string,
): Promise<RequestResult<string>> => {
  const url = `${apiUrl}${APIRoutes.GET_INSURANCE_SURVEY_REPORT.create({
    insuranceSurveyId,
  })}`;
  const request = new Request(url, {
    method: 'GET',
    headers: await makeAuthFileHeaders(bearerToken),
  });
  return fileReq(request);
};

export const postFile = async (
  bearerToken: string,
  file: File,
  entityId: string,
  attachmentType: AttachmentEntityType,
): Promise<RequestResult<AttachmentDto>> => {
  const formData = new FormData();
  formData.append('file', file);
  const route =
    attachmentType === TaskTypes.PropertyInspectionTask
      ? APIRoutes.POST_PROPERTY_INSPECTION_TASK_ATTACHMENT.create({
          inspectionTaskId: entityId,
        })
      : attachmentType === TaskTypes.AccidentInspectionTask
      ? APIRoutes.POST_ACCIDENT_INSPECTION_TASK_ATTACHMENT.create({
          accidentInspectionTaskId: entityId,
        })
      : attachmentType === TaskTypes.InvestigationTask
      ? APIRoutes.POST_INVESTIGATION_TASK_ATTACHMENT.create({
          investigationTaskId: entityId,
        })
      : attachmentType === TaskTypes.VehicleTask
      ? APIRoutes.POST_VEHICLE_TASK_ATTACHMENT.create({
          vehicleTaskId: entityId,
        })
      : attachmentType === 'investigationTaskJournal'
      ? APIRoutes.POST_INVESTIGATION_TASK_JOURNAL_ATTACHMENT.create({
          journalId: entityId,
        })
      : attachmentType === 'insuranceSurvey'
      ? APIRoutes.POST_INSURANCE_SURVEY_ATTACHMENT.create({
          insuranceSurveyId: entityId,
        })
      : APIRoutes.POST_PROPERTY_INSPECTION_TASK_JOURNAL_ATTACHMENT.create({
          journalId: entityId,
        });
  const request = new Request(`${apiUrl}${route}`, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken, false),
    body: formData,
  });
  return jsonReq<AttachmentDto>(request, typeFromApi);
};

export const postFileBaseTaskDto = async (
  bearerToken: string,
  file: File,
  task: BaseTaskDto,
): Promise<RequestResult<AttachmentDto>> => {
  const formData = new FormData();
  formData.append('file', file);
  const route = APIRoutes.POST_PROPERTY_INSPECTION_TASK_ATTACHMENT.create({
    inspectionTaskId: task.id,
  });
  const request = new Request(`${apiUrl}${route}`, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken, false),
    body: formData,
  });
  return jsonReq<AttachmentDto>(request, typeFromApi);
};

export const postImportFile = async (
  route: string,
  bearerToken: string,
  file: File,
): Promise<RequestResult<ImportStateDto>> => {
  const formData = new FormData();
  formData.append('file', file);
  const request = new Request(`${apiUrl}${route}`, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken, false),
    body: formData,
  });
  return jsonReq<ImportStateDto>(request, typeFromApi);
};

export const getInspectionPhoto = async (
  bearerToken: string,
  inspectionId: string,
  inspectionPhotoId: string,
): Promise<RequestResult<string>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_PHOTO_BY_ID.create({
      inspectionId: inspectionId,
      inspectionPhotoId: inspectionPhotoId,
    })}`,
    {
      method: 'GET',
      headers: await makeAuthFileHeaders(bearerToken),
    },
  );
  return fileReq(request, true);
};

export const postInspectionPhotosDto = async (
  bearerToken: string,
  files: File[],
  inspectionId: string,
): Promise<RequestResult<AttachmentDto>> => {
  const formData = new FormData();

  const compressedFiles = await Promise.all(
    files.map(async (file) => {
      const options = {
        maxSizeMB: 1, // Maximum size in MB
        maxWidthOrHeight: 1400, // Maximum width or height
        useWebWorker: true,
      };
      if (file.size > 1000000) {
        // compress if over limit
        try {
          const compressedFile = await imageCompression(file, options);
          const newFile = new File([compressedFile], file.name, {
            type: file.type,
          });
          return newFile;
        } catch (error) {
          console.error('Error compressing file:', error);
          return file; // Return the original file if compression fails
        }
      } else return file;
    }),
  );

  compressedFiles.forEach((file) => {
    formData.append('files', file);
  });
  const route =
    APIRoutes.POST_PROPERTY_INSPECTION_TASK_FAST_REPORT_PHOTO_BULK.create({
      inspectionId: inspectionId,
    });
  const request = new Request(`${apiUrl}${route}`, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken, false),
    body: formData,
  });
  return jsonReq<AttachmentDto>(request, typeFromApi);
};
