import {
  CompanyDto,
  PersonDto,
  VehicleTaskKind,
} from '../../types/resource-models';
import { AffectedPartner } from '../../types/types';

export const validatePartnerDto = (
  partnerDto: PersonDto | CompanyDto,
  requiredPhoneOrMail: boolean,
): boolean => {
  return (
    !!partnerDto.address?.city &&
    !!partnerDto.address?.zipCode &&
    !!partnerDto.address?.street &&
    (!requiredPhoneOrMail || !!partnerDto.phoneNumber || !!partnerDto.email)
  );
};

export const validateAffectedPartnerVehicle = (
  affectedPartner: AffectedPartner,
  kind?: VehicleTaskKind,
): boolean => {
  const partner =
    affectedPartner.partner.type === 'person'
      ? affectedPartner.partner.person
      : affectedPartner.partner.company;
  const nameValidation =
    affectedPartner.partner.type === 'person'
      ? !!affectedPartner.partner.person.firstName &&
        !!affectedPartner.partner.person.lastName
      : !!affectedPartner.partner.company.name;
  return (
    validatePartnerDto(partner, kind !== 'VehicleBonusCorrection' ?? true) &&
    affectedPartner.role !== 'None' &&
    nameValidation
  );
};

export const validateAffectedPartnerInvestigation = (
  affectedPartner: AffectedPartner,
): boolean => {
  const partner =
    affectedPartner.partner.type === 'person'
      ? affectedPartner.partner.person
      : affectedPartner.partner.company;
  const nameValidation =
    affectedPartner.partner.type === 'person'
      ? affectedPartner.partner.person.firstName !== null &&
        affectedPartner.partner.person.lastName !== null
      : affectedPartner.partner.company.name !== null;
  return (
    validatePartnerDto(partner, false) &&
    (affectedPartner.role !== 'None' || (partner.function ?? '').length > 0) &&
    nameValidation
  );
};
