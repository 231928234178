import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToken } from '../../../hooks';
import { Globals } from '../../../utils';
import { RequestResult } from '../../../data';
import {
  BaseTaskChangeDto,
  BaseTaskFilterCriteria,
  SortOrder,
  UserDto,
} from '../../../types/resource-models';
import { VehicleTask, VehicleTaskFilter } from '../../../types/types';
import {
  getVehicleTasks,
  getVehicleTask,
  getVehicleTaskAllHistory,
} from './requests';
import { UserContext } from '../../../context';
import { getUserVehicleMenuItems } from '../components/vehicle-task-view-menu-items';
import {
  CombinedState,
  initialCombinedState,
} from '../../base-task/data/hooks';

export function useVehicleTask(): [
  RequestResult<VehicleTask>,
  React.Dispatch<RequestResult<VehicleTask>>,
] {
  const [vehicleTaskResponse, setVehicleTaskResponse] = useState<
    RequestResult<VehicleTask>
  >({
    status: 'initial',
  });

  return [vehicleTaskResponse, setVehicleTaskResponse];
}

export type CombinedStateProps = {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
};

export function useVehicleTasksFromApi(): CombinedStateProps {
  const { t } = useTranslation();
  const token = useToken();
  const { user } = useContext(UserContext);

  const [combinedState, setCombinedState] =
    useState<CombinedState>(initialCombinedState);

  useEffect(() => {
    if (
      (user.status === 'success' || user.status === 'loading-with-value') &&
      !combinedState.filter.assigneeOrCreatorUserId &&
      combinedState.tasks.status === 'initial'
    ) {
      let customOrderField: string | undefined = undefined;
      let customOrderBy: SortOrder | undefined = undefined;
      if (user.status === 'success') {
        const userTableSetting = user.localValue.userTableSettings.find(
          (x) => x.type === 'VehicleTask',
        );
        if (userTableSetting?.orderField) {
          customOrderField = userTableSetting?.orderField;
          customOrderBy = userTableSetting?.orderBy;
        }
      }
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.tasks = { status: 'loading' };
          // apply first quick filter from menu as default filter
          const menuItems = getUserVehicleMenuItems(t, user.value);
          const emptyFilter = Globals.filterDefault;
          draftState.defaultFilter = {
            ...emptyFilter,
            ...(menuItems[0].filter as Partial<BaseTaskFilterCriteria>),
          };
          draftState.filter = customOrderField
            ? {
                ...draftState.defaultFilter,
                orderField: customOrderField,
                orderBy: customOrderBy ?? 'asc',
              }
            : draftState.defaultFilter;
          draftState.label = menuItems[0].label;
        }),
      );
    }
  }, [user, combinedState, t]);

  useEffect(() => {
    if (
      token !== '' &&
      (combinedState.tasks.status === 'loading' ||
        combinedState.tasks.status === 'loading-with-value')
    ) {
      getVehicleTasks(
        token,
        combinedState.filter as BaseTaskFilterCriteria,
      ).then((result) =>
        setCombinedState(
          produce(combinedState, (draftState) => {
            draftState.tasks = result;
          }),
        ),
      );
    }
  }, [user, token, combinedState]);

  return { combinedState, setCombinedState };
}

export function useVehicleTaskFromApi(
  taskId: string,
): [RequestResult<VehicleTask>, React.Dispatch<RequestResult<VehicleTask>>] {
  const { t } = useTranslation();
  const token = useToken();
  const { user } = useContext(UserContext);
  const [vehicleTaskResponse, setVehicleTaskResponse] = useState<
    RequestResult<VehicleTask>
  >({
    status: 'loading',
  });

  const [combinedState, setCombinedState] =
    useState<CombinedState>(initialCombinedState);

  useEffect(() => {
    if (
      (user.status === 'success' || user.status === 'loading-with-value') &&
      !combinedState.filter.assigneeOrCreatorUserId &&
      combinedState.tasks.status === 'initial'
    ) {
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.tasks = { status: 'loading' };
          // apply first quick filter from menu as default filter
          const menuItems = getUserVehicleMenuItems(t, user.value);
          const emptyFilter = Globals.filterDefault;
          draftState.defaultFilter = {
            ...emptyFilter,
            ...(menuItems[0].filter as Partial<BaseTaskFilterCriteria>),
          };
          draftState.filter = draftState.defaultFilter;
          draftState.label = menuItems[0].label;
        }),
      );
    }
  }, [user, combinedState, t]);

  useEffect(() => {
    setVehicleTaskResponse({ status: 'loading' });
    if (token !== '' && taskId !== '') {
      getVehicleTask(token, taskId).then(setVehicleTaskResponse);
    }
  }, [token, taskId]);

  return [vehicleTaskResponse, setVehicleTaskResponse];
}

export const filterYourOpenTasks = (
  user: UserDto,
): Partial<VehicleTaskFilter> => ({
  status: [
    'Assigned',
    'InProgressLocal',
    'InProgressOffice',
    'InProgressVideo',
  ],
  assigneeOrCreator: user,
  orderField: 'Status,CreatedAt',
  orderBy: 'asc',
});
export function useVehicleTaskHistoryFromApi(
  taskId: string,
): [
  RequestResult<BaseTaskChangeDto[]>,
  React.Dispatch<RequestResult<BaseTaskChangeDto[]>>,
] {
  const token = useToken();
  const [vehicleTaskChanges, setVehicleTaskChanges] = useState<
    RequestResult<BaseTaskChangeDto[]>
  >({
    status: 'initial',
  });
  useEffect(() => {
    if (token !== '' && vehicleTaskChanges.status !== 'success') {
      if (vehicleTaskChanges.status !== 'loading-with-value')
        setVehicleTaskChanges({ status: 'loading' });
      getVehicleTaskAllHistory(token, taskId).then((result) => {
        if (result.status === 'success') {
          setVehicleTaskChanges({
            status: 'success',
            value: result.value,
            localValue: result.localValue,
          });
        } else if (result.status === 'error') {
          setVehicleTaskChanges(result);
        }
      });
    }
  }, [taskId, token, vehicleTaskChanges.status]);
  return [vehicleTaskChanges, setVehicleTaskChanges];
}
