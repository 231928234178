import {
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../../controller/controllers';
import { RequestResult } from '../../../../data';
import { BaseTask } from '../../../../types/types';
import {
  BaseTaskMemoFieldsForm,
  BaseTaskMemoFieldsFormProps,
} from './base-task-memo-fields-form';

export type BaseTaskMemoFieldsFormProp<BaseTaskType extends BaseTask> = Omit<
  BaseTaskMemoFieldsFormProps<BaseTaskType>,
  'onClose'
>;

export const BaseTaskMemoFieldsModal = <BaseTaskType extends BaseTask>({
  task,
  setTask,
  taskType,
  onSave,
  showSaveAndSendButton,
  includeWhatField,
}: BaseTaskMemoFieldsFormProp<BaseTaskType>): JSX.Element => {
  const [saved, setSaved] = useState<boolean>(false);
  useEffect(() => {
    if (saved) {
      balModalController.dismiss();
    }
  }, [saved]);
  const { t } = useTranslation();
  return (
    <>
      <BalModalHeader className="is-full-width">
        <BalHeading className="mb-5" space="none" level="h3">
          {t('general.taskDescription')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BaseTaskMemoFieldsForm
          task={task}
          setTask={setTask}
          taskType={taskType}
          onSave={(task: RequestResult<BaseTaskType>) => {
            if (onSave) {
              onSave(task);
            }
            setSaved(true);
          }}
          showSaveAndSendButton={showSaveAndSendButton}
          includeWhatField={includeWhatField}
        ></BaseTaskMemoFieldsForm>
      </BalModalBody>
    </>
  );
};
