import {
  BalHeading,
  BalNotification,
  BalSpinner,
  BalText,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../components/toast-notification';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import { PartnerDto, VehicleTaskKind } from '../../../types/resource-models';
import { AffectedPartner, TaskTypes, VehicleTask } from '../../../types/types';
import { Globals } from '../../../utils';
import { getDefaultAddressWithPartnerId } from '../../../utils/utilities';
import {
  ContactDetailInformation,
  deletePartner,
  patchPartner,
  postPartner,
} from '../../contacts';
import { CreateEditContactButton } from '../../contacts/components/create-edit-contact-button';
import { validateAffectedPartnerVehicle } from '../../services';

const VehicleTaskDetailContactsCards = ({
  task,
  setTask,
  detailView,
}: {
  task: RequestResult<VehicleTask>;
  setTask: React.Dispatch<RequestResult<VehicleTask>>;
  patchTask: (
    vehicleTask: VehicleTask,
    vehicleTaskNew: VehicleTask,
    bearerToken: string,
    updateToAdos?: boolean,
  ) => Promise<RequestResult<VehicleTask>>;
  detailView?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const policyHolderId =
    task.status === 'success' && task.localValue.claim.policyHolder
      ? task.localValue.claim.policyHolder.type === 'person'
        ? task.localValue.claim.policyHolder.person.id
        : task.localValue.claim.policyHolder.company.id
      : undefined;
  const policyHolder =
    task.status === 'success'
      ? task.localValue.claim.affectedPartners.find((p) => {
          return p.partner.type === 'person'
            ? p.partner.person.id === policyHolderId
            : p.partner.company.id === policyHolderId;
        })
      : undefined;

  const token = useToken();
  const onAdd = (partner: AffectedPartner) => {
    if (task.status === 'success') {
      postPartner(token, task.value.id, partner, TaskTypes.VehicleTask).then(
        (result: RequestResult<AffectedPartner>) => {
          if (result.status === 'success') {
            setTask(
              produce(task, (draftState) => {
                draftState.localValue.claim.affectedPartners = [
                  ...task.localValue.claim.affectedPartners,
                  result.value,
                ];
                draftState.value.claim.affectedPartners = [
                  ...task.localValue.claim.affectedPartners,
                  result.value,
                ];
                if (result.value.role === 'PolicyHolder') {
                  draftState.localValue.claim.policyHolder =
                    result.value.partner;
                }
              }),
            );
          } else if (result.status === 'error') {
            toast(ErrorToast(result.errorValue));
          }
        },
      );
    }
  };
  const onChange = (partner: AffectedPartner) => {
    if (task.status === 'success') {
      const oldPartner = task.localValue.claim.affectedPartners.find(
        (affectedPartner) => {
          return affectedPartner.id === partner.id;
        },
      );
      if (oldPartner && oldPartner !== partner) {
        patchPartner(token, oldPartner, partner).then((result) => {
          if (result.status === 'success') {
            const affectedPartners = task.localValue.claim.affectedPartners.map(
              (affectedPartner) => {
                return affectedPartner.id !== oldPartner.id
                  ? affectedPartner
                  : { ...oldPartner, partner: result.value };
              },
            );
            setTask(
              produce(task, (draftState) => {
                draftState.localValue.claim.affectedPartners = affectedPartners;
                draftState.value.claim.affectedPartners = affectedPartners;
                if (partner.role === 'PolicyHolder') {
                  draftState.localValue.contactId = partner.id;
                  draftState.value.contactId = partner.id;
                  draftState.localValue.claim.policyHolder = result.localValue;
                  draftState.value.claim.policyHolder = result.localValue;
                }
              }),
            );
          } else if (result.status === 'error') {
            toast(ErrorToast(result.errorValue));
          }
        });
      } else {
        const partnerId =
          partner.partner.type === 'person'
            ? partner.partner.person.id
            : partner.partner.company.id;
        const partnerIndex = task.localValue.claim.affectedPartners.findIndex(
          (p) => {
            const pId =
              p.partner.type === 'person'
                ? p.partner.person.id
                : p.partner.company.id;
            return pId === partnerId;
          },
        );
        setTask(
          produce(task, (draftState) => {
            draftState.localValue.claim.affectedPartners[partnerIndex].partner =
              partner.partner;
          }),
        );
      }
    }
  };
  const onDelete = async (partner: AffectedPartner) => {
    if (task.status === 'success') {
      deletePartner(
        token,
        partner.partner.type === 'person'
          ? partner.partner.person.id
          : partner.partner.company.id,
      ).then((result: RequestResult<AffectedPartner>) => {
        if (result.status === 'success-no-value') {
          const partners = task.localValue.claim.affectedPartners.filter(
            (affectedPartner) => affectedPartner.id !== partner.id,
          );
          setTask(
            produce(task, (draftState) => {
              draftState.localValue.claim.affectedPartners = partners;
              draftState.value.claim.affectedPartners = partners;
            }),
          );
        } else if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      });
    }
  };

  const policyHolderCardChildren = (kind: VehicleTaskKind) => {
    return task.status === 'success' ? (
      <>
        <div className="is-flex is-align-items-center mb-2 mt-1">
          <BalHeading
            color={'info'}
            space="none"
            level="h4"
            className="pb-3"
            subtitle
          >
            {t('claimDetail.policyHolder')}
          </BalHeading>
          <CreateEditContactButton
            policyHolderId={task.value.claim.policyHolderId}
            className="pl-6"
            edit
            addressOpen
            hasMobile={task.localValue.kind === 'VehicleInspectionTask'}
            hasCompanyNr={task.localValue.kind === 'VehicleInspectionTask'}
            requiredPhoneOrMail
            affectedPartnerToEdit={task.localValue.claim.affectedPartners.find(
              (partner) => {
                const policyHolder = { ...partner, role: 'PolicyHolder' };
                return policyHolder.partner.type === 'person'
                  ? policyHolder.partner.person.id === policyHolderId
                  : policyHolder.partner.company.id === policyHolderId;
              },
            )}
            onSave={(partner, role) => {
              onChange({
                id:
                  task.localValue.claim.affectedPartners.find((p) => {
                    return p.partner.type === 'person'
                      ? p.partner.person.id === policyHolderId
                      : p.partner.company.id === policyHolderId;
                    // life hack 1
                  })?.id ?? '00000000-0000-0000-0000-000000000000',
                partner: partner,
                role: role ?? Globals.noneValue,
              });
            }}
          />
        </div>
        {policyHolder && (
          <>
            <ContactDetailInformation hideRole contactPartner={policyHolder} />
            {!validateAffectedPartnerVehicle(policyHolder, kind) && (
              <BalText color="danger">
                {t('validators.missingInformation')}
              </BalText>
            )}
          </>
        )}
      </>
    ) : (
      <></>
    );
  };

  const affectedPartnerCardChildren = () => {
    const tooManyAffectedPartners =
      task.status === 'success' &&
      task.localValue.claim.affectedPartners.filter(
        (x) => x.role == 'AffectedThirdParty',
      ).length > 1;
    return task.status === 'success' ? (
      <>
        <BalHeading
          color={detailView ? 'info' : ''}
          space="none"
          level="h4"
          subtitle
          className="pb-3 mt-1"
        >
          {t('general.additionalContacts')}
        </BalHeading>
        {tooManyAffectedPartners && (
          <BalNotification color="warning">
            <BalText bold>
              {t('validators.tooManyAffectedThirdPartyPartners')}
            </BalText>
          </BalNotification>
        )}
        <CreateEditContactButton
          policyHolderId={task.value.claim.policyHolderId}
          addressOpen
          key="createContact"
          hasRole
          hasMobile={task.localValue.kind === 'VehicleInspectionTask'}
          hasCompanyNr={task.localValue.kind === 'VehicleInspectionTask'}
          requiredPhoneOrMail
          onSave={(partner, role) => {
            const partnerUpdated =
              partner.type === 'person'
                ? {
                    ...partner,
                    person: {
                      ...partner.person,
                      address:
                        JSON.stringify(partner.person.address) ==
                        JSON.stringify(
                          getDefaultAddressWithPartnerId(
                            (partner as PartnerDto).id,
                          ),
                        )
                          ? undefined
                          : partner.person.address,
                    },
                  }
                : {
                    ...partner,
                    company: {
                      ...partner.company,
                      address:
                        JSON.stringify(partner.company.address) ==
                        JSON.stringify(
                          getDefaultAddressWithPartnerId(
                            (partner as PartnerDto).id,
                          ),
                        )
                          ? undefined
                          : partner.company.address,
                    },
                  };

            onAdd({
              id: '00000000-0000-0000-0000-000000000000',
              partner: partnerUpdated,
              role: role ?? Globals.noneValue,
            });
          }}
          customRoles={Globals.VehicleTaskPartnerRoles}
        />
        {task.localValue.claim.affectedPartners.map((affectedPartner) => {
          if (affectedPartner.id !== policyHolder?.id) {
            return (
              <div
                className="columns is-multiline is-gapless"
                key={affectedPartner.id}
              >
                <ContactDetailInformation
                  contactPartner={affectedPartner}
                  editComponent={
                    <CreateEditContactButton
                      policyHolderId={task.value.claim.policyHolderId}
                      className="mt-2"
                      edit
                      addressOpen
                      hasRole
                      hasMobile={
                        task.localValue.kind === 'VehicleInspectionTask'
                      }
                      hasCompanyNr={
                        task.localValue.kind === 'VehicleInspectionTask'
                      }
                      requiredPhoneOrMail
                      affectedPartnerToEdit={affectedPartner}
                      onSave={(partner, role) => {
                        onChange({
                          id: affectedPartner.id,
                          partner: partner,
                          role: role ?? affectedPartner.role,
                        });
                      }}
                      onDelete={(partner) => {
                        onDelete({
                          id: affectedPartner.id,
                          partner: partner,
                          role: affectedPartner.role,
                        });
                      }}
                      customRoles={Globals.VehicleTaskPartnerRoles}
                    />
                  }
                  onDelete={
                    tooManyAffectedPartners
                      ? (partner) => {
                          onDelete({
                            id: affectedPartner.id,
                            partner: partner,
                            role: affectedPartner.role,
                          });
                        }
                      : undefined
                  }
                />
                {!validateAffectedPartnerVehicle(
                  affectedPartner,
                  task.localValue.kind,
                ) && (
                  <BalText color="danger">
                    {t('validators.missingInformation')}
                  </BalText>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </>
    ) : (
      <></>
    );
  };
  return (
    <div>
      {(task.status === 'initial' || task.status === 'loading') && (
        <BalSpinner />
      )}
      {task.status === 'error' && <></>}
      {task.status === 'success' && (
        <div>
          <div className="my-4">
            {policyHolderCardChildren(task.value.kind)}
          </div>
          {task.value.kind === 'VehicleInspectionTask' &&
            affectedPartnerCardChildren()}
        </div>
      )}
    </div>
  );
};

export default VehicleTaskDetailContactsCards;
