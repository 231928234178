import {
  ColDef,
  GridOptions,
  ILoadingCellRendererParams,
} from 'ag-grid-community';
import React, { useContext, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { BalIcon } from '@baloise/design-system-components-react';
import {
  WithCapabilities,
  DisabableLink,
} from '../../components/authorization';
import { UserContext } from '../../context';
import { balModalController } from '../../controller/controllers';
import { TableSettingsForm } from '../../features/user/components/table-settings-form';
import { EBRoutes } from '../../router/router';
import {
  UserTableSettingDto,
  UserTableTypes,
} from '../../types/resource-models';
import {
  Craftsman,
  PropertyCraftsman,
  VehicleCraftsman,
} from '../../types/types';
import { concatAddress } from '../../utils';
import { buildGridOptions } from '../../features/base-task/components/base-task-search-form/base-task-search-form-table-grid-options';
import { balIconSettings } from '@baloise/design-system-icons';
import {
  earlybyteTableTextRenderer,
  noComparingComparator,
} from '../../utils/utilities';

export const getCraftsmanDefaultColDefs = (t: TFunction): ColDef[] => {
  return [
    {
      field: 'tableSettingsButton',
      cellRenderer: 'overviewButton',
      width: 50,
      headerComponent: 'tableSettingsButton',
      maxWidth: 50,
      minWidth: 50,
      resizable: false,
      sortable: false,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        return craftsman.type === 'propertyCraftsman'
          ? craftsman.propertyCraftsman.partnerNumber
          : craftsman.vehicleCraftsman.partnerNumber;
      },
      headerName: t('networkPartners.partnerNumber'),
      field: 'partnerNumber',
      minWidth: 64,
      width: 165,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        return craftsman.type === 'propertyCraftsman'
          ? craftsman.propertyCraftsman.company.displayName
          : craftsman.vehicleCraftsman.company.displayName;
      },
      headerName: t('general.company'),
      field: 'company.name,company.additionalName',
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        const address =
          craftsman.type === 'propertyCraftsman'
            ? craftsman.propertyCraftsman.company.address
            : craftsman.vehicleCraftsman.company.address;
        return concatAddress(address);
      },
      field:
        'company.address.street,company.address.houseNumber,company.address.zipCode',
      headerName: t('general.address'),
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        const address =
          craftsman.type === 'propertyCraftsman'
            ? craftsman.propertyCraftsman.company.address
            : craftsman.vehicleCraftsman.company.address;
        return address?.addressStreetString;
      },
      headerName: t('general.streetAndNumber'),
      field: 'company.address.street,company.address.houseNumber',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        const address =
          craftsman.type === 'propertyCraftsman'
            ? craftsman.propertyCraftsman.company.address
            : craftsman.vehicleCraftsman.company.address;
        return address?.zipCode;
      },
      headerName: t('general.zipCode'),
      field: 'company.address.zipCode',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        const address =
          craftsman.type === 'propertyCraftsman'
            ? craftsman.propertyCraftsman.company.address
            : craftsman.vehicleCraftsman.company.address;
        return address?.city;
      },
      headerName: t('general.city'),
      field: 'company.address.city',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        const contact =
          craftsman.type === 'propertyCraftsman'
            ? craftsman.propertyCraftsman.primaryContact
            : craftsman.vehicleCraftsman.primaryContact;
        return contact ? contact.displayName : '';
      },
      field: 'primaryContact.displayName',
      headerName: t('general.contact.label'),
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        const contact =
          craftsman.type === 'propertyCraftsman'
            ? craftsman.propertyCraftsman.primaryContact
            : craftsman.vehicleCraftsman.primaryContact;
        return contact ? contact.phoneNumber : '';
      },
      headerName: t('general.phone'),
      field: 'primaryContact.phoneNumber',
      hide: true,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        const contact =
          craftsman.type === 'propertyCraftsman'
            ? craftsman.propertyCraftsman.primaryContact
            : craftsman.vehicleCraftsman.primaryContact;
        return contact ? contact.email : '';
      },
      headerName: t('general.email'),
      field: 'primaryContact.email',
      hide: true,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        return craftsman.type === 'vehicleCraftsman'
          ? t(
              `tristate.${
                (craftsman as VehicleCraftsman).vehicleCraftsman.headquarters
              }`,
            )
          : '';
      },
      headerName: t('networkPartners.headquarters'),
      field: 'headquarters',
      hide: true,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        return craftsman.type === 'vehicleCraftsman'
          ? t(
              `tristate.${
                (craftsman as VehicleCraftsman).vehicleCraftsman.glassOnly
              }`,
            )
          : '';
      },
      headerName: t('networkPartners.onlyGlass'),
      field: 'glassOnly',
      hide: true,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        return craftsman.type === 'vehicleCraftsman'
          ? t(
              `tristate.${
                (craftsman as VehicleCraftsman).vehicleCraftsman
                  ?.isExternalPartner
              }`,
            )
          : t(
              `tristate.${
                (craftsman as PropertyCraftsman).propertyCraftsman
                  ?.isExternalPartner
              }`,
            );
      },
      headerName: t('networkPartners.isExternalPartner'),
      field: 'isExternalPartner',
      hide: true,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        return craftsman.type === 'vehicleCraftsman'
          ? t(
              `tristate.${
                (craftsman as VehicleCraftsman).vehicleCraftsman
                  ?.isIndependentVehicleCraftsman
              }`,
            )
          : '';
      },
      headerName: t('networkPartners.isIndependentVehicleCraftsman'),
      field: 'isIndependentVehicleCraftsman',
      hide: true,
      sortable: false,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        return craftsman.type === 'propertyCraftsman'
          ? t(
              `tristate.${
                (craftsman as PropertyCraftsman).propertyCraftsman
                  ?.isIndependentPropertyCraftsman
              }`,
            )
          : '';
      },
      headerName: t('networkPartners.isIndependentPropertyCraftsman'),
      field: 'isIndependentPropertyCraftsman',
      hide: true,
      sortable: false,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        if (craftsman.type === 'propertyCraftsman') {
          return craftsman.propertyCraftsman.region
            ? t(`region.${craftsman.propertyCraftsman.region}`)
            : '';
        } else {
          return '';
        }
      },
      field: 'region',
      headerName: t('networkPartners.region'),
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const craftsman = params.data as Craftsman;
        return t(
          `branch.${
            craftsman.type === 'propertyCraftsman' ? 'Property' : 'MotorVehicle'
          }`,
        );
      },
      headerName: t('general.branch'),
      field: 'branch',
      sortable: false,
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
  ];
};

const getDefaultColDefsClone = (t: TFunction): ColDef[] => {
  return JSON.parse(JSON.stringify(getCraftsmanDefaultColDefs(t)));
};

const getAllBaseGridOptions = (
  t: TFunction,
  onSelectedColumns: (userTableSettingDto: UserTableSettingDto) => void,
  userTableType: UserTableTypes,
): GridOptions => {
  return {
    defaultColDef: {
      sortable: true,
      filter: false,
      width: 165,
      minWidth: 64,
      resizable: true,
    },
    overlayNoRowsTemplate: '&nbsp;',
    columnDefs: getCraftsmanDefaultColDefs(t),
    components: {
      overviewButton: function renderOverviewButton(
        params: ILoadingCellRendererParams,
      ) {
        return (
          <WithCapabilities
            className="is-flex is-align-items-center"
            requiredCapabilities={['ReadNetworkPartner']}
            passWithCapabilitiesPropsToChildren
          >
            <DisabableLink
              to={EBRoutes.NETWORK_PARTNER_DETAIL.create({
                networkPartnerId:
                  params.data.type === 'propertyCraftsman'
                    ? params.data.propertyCraftsman.id
                    : params.data.vehicleCraftsman.id,
              })}
            >
              <BalIcon name="nav-go-right" size="small" />
            </DisabableLink>
          </WithCapabilities>
        );
      },
      tableSettingsButton: function useRenderTableSettings(
        params: ILoadingCellRendererParams,
      ) {
        const [modalOpened, setModalOpened] = useState(false);
        const { user } = useContext(UserContext);
        const openSettingsModal = async () => {
          const modal = await balModalController.create({
            component: TableSettingsForm,
            componentProps: {
              gridApi: params.api,
              user,
              userTableType: userTableType,
              onSelectedColumns: onSelectedColumns,
              defaultColDefs: getDefaultColDefsClone(t),
            },
            cssClass: 'center-modal',
          });
          modal.onDidDismiss().then(() => {
            setModalOpened(false);
          });
          return modal.present();
        };
        return (
          <div
            onClick={() => {
              if (!modalOpened) {
                setModalOpened(true);
                openSettingsModal();
              }
            }}
          >
            <BalIcon svg={balIconSettings} size="small" />
          </div>
        );
      },
    },
  };
};

export const useGridOptions = (): [
  GridOptions,
  React.Dispatch<GridOptions>,
] => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const userTableType = 'Craftsman';

  const [gridOptions, setGridOptions] = useState<GridOptions>(
    buildGridOptions(user, setUser, t, userTableType, getAllBaseGridOptions),
  );

  useEffect(() => {
    setGridOptions(
      buildGridOptions(user, setUser, t, userTableType, getAllBaseGridOptions),
    );
  }, [user, setUser, t, userTableType]);

  return [gridOptions, setGridOptions];
};
