import '../../../../styles/dropdown-button.scss';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { AccidentInspectionTask } from '../../../../types/types';
import {
  BaseTaskStatus,
  AccidentInspectionTaskStatus,
} from '../../../../types/resource-models';
import { useToken } from '../../../../hooks';
import produce from 'immer';
import { patchAccidentInspectionTask } from '../../data/requests';
import { BaseTaskInProgressButton } from '../../../base-task';
type State = {
  title: string;
  state: BaseTaskStatus;
};

type AccidentInspectionTaskInProgressProps = {
  task: AccidentInspectionTask;
  onChangeSuccess?: (task: RequestResult<AccidentInspectionTask>) => void;
};

const AccidentInspectionTaskInProgress = ({
  task,
  onChangeSuccess,
}: AccidentInspectionTaskInProgressProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const states: State[] = [
    {
      state: 'InProgress',
      title: t('baseTaskStatus.InProgress'),
    },
  ];

  const onChangeState = async (state: BaseTaskStatus) => {
    const result = await patchAccidentInspectionTask(
      task,
      produce(task, (draftState) => {
        draftState.status = state as AccidentInspectionTaskStatus;
      }),
      token,
    );
    if (result.status === 'success' && onChangeSuccess) {
      onChangeSuccess(result);
    }
  };

  return (
    <BaseTaskInProgressButton states={states} onChangeState={onChangeState} />
  );
};

export default AccidentInspectionTaskInProgress;
