import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  BalCard,
  BalCheckbox,
  BalCheckboxGroup,
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalFieldMessage,
  BalHeading,
  BalIcon,
  BalList,
  BalListItem,
  BalListItemContent,
  BalListItemIcon,
  BalListItemSubtitle,
  BalListItemTitle,
  BalRadio,
  BalRadioGroup,
  BalSpinner,
  BalText,
  BalTextarea,
} from '@baloise/design-system-components-react';
import { RequestResult } from '../../../data';
import { useDropdownUsers } from '../../user/data/hooks';
import { EBRoutes } from '../../../router/router';
import { useToken } from '../../../hooks';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import produce from 'immer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import _ from 'lodash';
import { BalTypeAheadField } from '../../../components/input';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import {
  AppCapabilityNames,
  PolicyHolderContactMethod,
} from '../../../types/resource-models';
import { getDisplayNameFromUser } from '../../../utils/utilities';
import { InfoDisplay } from '../../../components/ui';
import { concat, Globals } from '../../../utils';
import { VehicleTask } from '../../../types/types';

export const VehicleTaskCompleteTaskCards = ({
  task,
  setTask,
  taskButtons,
  patchTask,
  inspectorAppCapabilitiy,
  setTab,
  quickLinkRegionAssignment,
}: {
  task: RequestResult<VehicleTask>;
  setTask: React.Dispatch<RequestResult<VehicleTask>>;
  taskButtons?: JSX.Element;
  patchTask(
    task: VehicleTask,
    taskNew: VehicleTask,
    bearerToken: string,
  ): Promise<RequestResult<VehicleTask>>;
  canChangeCreator?: boolean;
  inspectorAppCapabilitiy?: AppCapabilityNames[];
  clerksAppCapabilitiy?: AppCapabilityNames[];
  setTab: Dispatch<SetStateAction<string>>;
  quickLinkRegionAssignment?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });
  const token = useToken();
  const inspectors = useDropdownUsers({
    appCapabilityNames: inspectorAppCapabilitiy,
  });
  const navigate = useNavigate();
  const [damageManagementOnly, setDamageManagementOnly] =
    useState<boolean>(false);
  const canSelectDamageOnly: boolean =
    task.status === 'success' &&
    task.localValue.assigneeGarageCraftsmanId !== null &&
    task.localValue.assigneeGarageCraftsmanId !== undefined;

  useEffect(() => {
    // In case the user decides to handle damage only, the assignee is not longer required.
    if (damageManagementOnly) {
      methods.unregister('assignee');
    }
  }, [damageManagementOnly, methods]);

  const contactMethodIsCoherentWithRemarks = () => {
    if (task.status === 'success') {
      return task.localValue.assigneeGarageCraftsmanContactMethod
        ? true // If contact method is assigned the remarks are not required.
        : !task.localValue.assigneeGarageCraftsmanRemarks; // If contact method is not assigned, the remarks must be empty.
    }
    return false;
  };

  return (
    <div>
      {(task.status === 'initial' || task.status === 'loading') && (
        <BalSpinner />
      )}
      {task.status === 'success' && inspectors?.status === 'success' && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(() => {
              if (contactMethodIsCoherentWithRemarks()) {
                setTab('none');
                setTask({ status: 'loading' });
                patchTask(
                  task.value,
                  {
                    ...task.localValue,
                    status:
                      damageManagementOnly ||
                      task.localValue.kind == 'VehicleBonusCorrection'
                        ? 'Closed'
                        : 'Assigned',
                    assignee: damageManagementOnly
                      ? undefined
                      : task.localValue.assignee,
                  },
                  token,
                ).then((inspectionTaskResponse) => {
                  if (inspectionTaskResponse.status === 'success') {
                    toast(
                      ToastNotification({
                        message: t(
                          'inspectionTask.completeClaim.successMessage',
                        ),
                        color: 'success',
                      }),
                    );
                    navigate(EBRoutes['VEHICLE_INSPECTION_TASK'].create({}));
                  } else if (inspectionTaskResponse.status === 'error') {
                    toast(ErrorToast(inspectionTaskResponse.errorValue));
                  }
                });
              }
            })}
          >
            <BalCard className="mt-5 p-5 columns is-multiline">
              <BalHeading
                space="none"
                level="h4"
                className="pb-3 column is-full"
              >
                {task.localValue.kind == 'VehicleBonusCorrection'
                  ? t('vehicleTask.vehicleBonusCorrection.complete')
                  : task.localValue.kind == 'VehicleRiskAssessment'
                  ? t('vehicleTask.vehicleRiskAssessment.complete')
                  : t('vehicleTask.completeVehicleClaim.cardTitle')}
              </BalHeading>
              <div className="column is-full columns is-multiline">
                {canSelectDamageOnly && (
                  <div className="pl-4 pb-3 pt-3 is-gapless is-full-width">
                    <BalField>
                      <BalFieldControl>
                        <BalCheckboxGroup>
                          <BalCheckbox
                            interface="switch"
                            checked={damageManagementOnly}
                            onBalChange={(event: CustomEvent) => {
                              setDamageManagementOnly(event.detail);
                              if (event.detail == false) {
                                setTask(
                                  produce(task, (draftState) => {
                                    draftState.localValue.assigneeGarageCraftsmanContactMethod =
                                      undefined;
                                    draftState.localValue.assigneeGarageCraftsmanRemarks =
                                      undefined;
                                  }),
                                );
                                methods.resetField('policyHolderContactMethod');
                                methods.resetField(
                                  'assigneeGarageCraftsmanRemarks',
                                );
                              }
                            }}
                          >
                            {t(
                              'vehicleTask.completeVehicleClaim.damageManagementOnlyLabel',
                            )}
                          </BalCheckbox>
                        </BalCheckboxGroup>
                      </BalFieldControl>
                      <BalFieldMessage>
                        {damageManagementOnly
                          ? t(
                              'vehicleTask.completeVehicleClaim.damageManagementOnlyText',
                            )
                          : t(
                              'vehicleTask.completeVehicleClaim.notDamageManagementOnlyText',
                            )}
                      </BalFieldMessage>
                    </BalField>
                  </div>
                )}
                {damageManagementOnly && (
                  <div className="column columns is-multiline is-gapless is-full-width is-vcentered">
                    <div className="column is-full">
                      <InfoDisplay
                        title={t('vehicleTask.vehicleCompany.company')}
                        text={concat([
                          task.localValue.assigneeGarage.name,
                          task.localValue.assigneeGarage.additionalName,
                        ])}
                        className="pb-1"
                      />
                    </div>
                    <div className="column is-full">
                      <BalField className="is-gapless column is-full-width">
                        <BalFieldLabel className="my-3">
                          {t('vehicleTask.policyHolderContactMethod')}
                        </BalFieldLabel>
                        <Controller
                          name="policyHolderContactMethod"
                          rules={{ required: t('error.mandatoryField') }}
                          control={methods.control}
                          render={({ field, fieldState }) => (
                            <>
                              <BalRadioGroup
                                {...field}
                                interface="select-button"
                                value={
                                  task.localValue
                                    .assigneeGarageCraftsmanContactMethod
                                }
                                onBalChange={(e) => {
                                  setTask(
                                    produce(task, (draftState) => {
                                      draftState.localValue.assigneeGarageCraftsmanContactMethod =
                                        e.detail as PolicyHolderContactMethod;
                                    }),
                                  );
                                  field.onChange(e.detail);
                                }}
                              >
                                {Globals.policyHolderContactMethod.map(
                                  (contactMethod) => {
                                    return (
                                      <BalRadio
                                        key={contactMethod}
                                        value={contactMethod}
                                      >
                                        <span className="pl-2">
                                          {t(
                                            `policyHolderContactMethodType.${contactMethod}`,
                                          )}
                                        </span>
                                      </BalRadio>
                                    );
                                  },
                                )}
                              </BalRadioGroup>
                              <div className="m-0">
                                {fieldState && (
                                  <BalFieldMessage color="danger">
                                    {fieldState.error &&
                                      fieldState.error.message}
                                  </BalFieldMessage>
                                )}
                              </div>
                            </>
                          )}
                        />
                      </BalField>
                    </div>
                    <div className="column is-full">
                      <BalField className="is-gapless column is-full-width">
                        <BalFieldLabel className="my-3">
                          {t('vehicleTask.assigneeGarageCraftsmanRemarks')}
                        </BalFieldLabel>
                        <BalTextarea
                          id="assigneeGarageCraftsmanRemarks'"
                          placeholder={t('general.claim.remarks')}
                          rows={4}
                          value={task.localValue.assigneeGarageCraftsmanRemarks}
                          onBalInput={(e: CustomEvent<string | undefined>) => {
                            if (e.detail || e.detail === '') {
                              setTask(
                                produce(task, (draftState) => {
                                  draftState.localValue.assigneeGarageCraftsmanRemarks =
                                    e.detail as string;
                                }),
                              );
                            }
                          }}
                        />
                      </BalField>
                    </div>
                  </div>
                )}
                {task.localValue.kind == 'VehicleBonusCorrection' && (
                  <BalText className="column is-full">
                    {t('vehicleTask.vehicleBonusCorrection.completeDisclaimer')}
                  </BalText>
                )}
                {!damageManagementOnly &&
                  task.localValue.kind != 'VehicleBonusCorrection' && (
                    <div className="column columns is-multiline is-gapless is-full-width is-vcentered">
                      <BalTypeAheadField
                        className="column is-8 pb-0"
                        id="assignee"
                        label={t('assignee.assigneeVehicle')}
                        result={inspectors.value}
                        valueFormatter={(value) =>
                          getDisplayNameFromUser(value)
                        }
                        hasBkey
                        bKeyFormatter={(value) => value?.personalNumber}
                        keyFormatter={(value) => value?.id ?? ''}
                        value={task.localValue.assignee}
                        formValidation={{
                          register: methods.register('assignee', {
                            required:
                              task.localValue.kind ===
                                'VehicleInspectionTask' ||
                              task.localValue.kind === 'VehicleRiskAssessment'
                                ? t('error.mandatoryField')
                                : false,
                            value: task.localValue.assignee,
                          }),
                          formState: methods.formState,
                        }}
                        onChange={(choice) => {
                          if (
                            !_.isEqual(task.localValue.assignee, choice) &&
                            choice !== undefined
                          ) {
                            methods.setValue('assignee', choice);
                            methods.clearErrors();
                          }
                          setTask(
                            produce(task, (draftState) => {
                              draftState.localValue.assignee = choice;
                            }),
                          );
                        }}
                      />
                    </div>
                  )}
                {quickLinkRegionAssignment && (
                  <BalList className="py-0 column is-full">
                    <BalListItem
                      clickable
                      target="_blank"
                      href={window.extended.BAT_APP_REGION_ASSIGNMENT}
                    >
                      <BalListItemContent>
                        <BalListItemTitle>
                          {t('home.quickLinks.regionAssignment')}
                        </BalListItemTitle>
                        <BalListItemSubtitle>
                          {t('home.quickLinks.regionAssignmentHint')}
                        </BalListItemSubtitle>
                      </BalListItemContent>
                      <BalListItemIcon right>
                        <BalIcon name="nav-go-right" size="xsmall" />
                      </BalListItemIcon>
                    </BalListItem>
                  </BalList>
                )}
              </div>
            </BalCard>
            {taskButtons && taskButtons}
          </form>
        </FormProvider>
      )}
    </div>
  );
};
