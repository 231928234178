import {
  BalButton,
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalHeading,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { capitalize, getLabelValueFromBkpNumber, Globals } from '../../utils';
import filterIcon from '../../assets/icons/filter.svg';
import mapIcon from '../../assets/icons/map.svg';
import listIcon from '../../assets/icons/list.svg';
import {
  Branch,
  CraftsmanFilterCriteria,
  Region,
  UserDto,
  UserMenuItemDto,
  VehicleBrandNames,
  VehicleSpecialtyNames,
} from '../../types/resource-models';
import React, { useContext, useEffect, useState } from 'react';
import { useDropdownUsers } from '../../features/user';
import produce from 'immer';
import { Icon } from '../../components/ui';
import {
  CreateEditNetworkPartner,
  CombinedState,
  useWorkCategoriesFromApi,
} from '../../features/network-partners';
import {
  RadiusSearch,
  BalTypeAheadField,
  BalTextField,
} from '../../components/input';
import { UserContext } from '../../context';
import { balModalController } from '../../controller/controllers';
import CreateCustomUserMenuItem from '../../features/user/components/create-custom-user-menu-item';
import { getDisplayNameFromUser } from '../../utils/utilities';
import { postShowFiltersUserTableSettings } from '../../features/user/data/requests';
import { useToken } from '../../hooks';
import { diff } from 'just-diff';

interface TimedSearchFields {
  companyName?: string;
  streetAndNumber?: string;
}

const NetworkHeader = ({
  showMap,
  setShowMap,
  combinedState,
  setCombinedState,
  editFilter,
}: {
  showMap: boolean;
  setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  setInitialMenuItem: () => void;
  editFilter: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  // Timed filters management.
  const [defaultFilter, setDefaultFilter] = useState<CraftsmanFilterCriteria>(
    combinedState.defaultFilter,
  );
  const [timedSearchFields, setTimedSearchField] = useState<TimedSearchFields>({
    companyName: defaultFilter.companyName,
    streetAndNumber: defaultFilter.streetAndNumber,
  });
  useEffect(() => {
    if (diff(defaultFilter, combinedState.defaultFilter).length > 0) {
      setDefaultFilter(combinedState.defaultFilter);
      const newTimedSearchField = {
        companyName: combinedState.defaultFilter.companyName,
        streetAndNumber: combinedState.defaultFilter.streetAndNumber,
      };
      setTimedSearchField(newTimedSearchField);
    }
  }, [combinedState, defaultFilter]);
  // Timed filters management End.

  const responsibles = useDropdownUsers({
    appCapabilityNames: ['EditPropertyInspectionTask', 'EditVehicleTask'],
  });
  const workCategories = useWorkCategoriesFromApi();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (user.status === 'success') {
      const userTableSetting = user.localValue.userTableSettings.find(
        (x) => x.type === 'Craftsman',
      );
      if (userTableSetting) {
        setShowFilters(userTableSetting.showFilters);
      }
    }
  }, [user]);

  const onCreatedMenuItem = (userMenuItemDto: UserMenuItemDto) => {
    if (user.status === 'success') {
      setUser(
        produce(user, (draftState) => {
          draftState.value.userMenuItems.push(userMenuItemDto);
          draftState.localValue.userMenuItems.push(userMenuItemDto);
        }),
      );
    }
  };

  const openFilterSaveDialog = async () => {
    const modal = await balModalController.create({
      component: CreateCustomUserMenuItem,
      componentProps: {
        filter: combinedState.filter,
        type: 'Craftsman',
        onCreated: onCreatedMenuItem,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  const [responsible, setResponsible] = useState<UserDto | undefined>(
    undefined,
  );
  useEffect(() => {
    if (responsibles?.status === 'success') {
      const currentResponsible = responsibles.localValue.find(
        (x) =>
          x.id === combinedState.defaultFilter.responsibleUserId ||
          x.id === combinedState.filter.responsibleUserId,
      );
      setResponsible(currentResponsible);
    }
  }, [responsibles, combinedState]);

  const [allBranches] = useState<Branch[]>(
    Globals.allBranches.filter(
      (branch) =>
        branch === Globals.noneValue ||
        branch === 'MotorVehicle' ||
        branch === 'MotorVehicleLiability' ||
        branch === 'Technical' ||
        branch === 'Property',
    ),
  );
  const [branch, setBranch] = useState<Branch | undefined>(undefined);
  useEffect(() => {
    if (allBranches) {
      setBranch(combinedState.filter.branch);
    }
  }, [allBranches, combinedState]);

  const [region, setRegion] = useState<Region | undefined>(undefined);
  useEffect(() => {
    const currentRegion =
      combinedState.defaultFilter.region ?? combinedState.filter.region;
    setRegion(currentRegion);
  }, [combinedState]);

  const [workCategoryBKPNumber, setWorkCategoryBKPNumber] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    if (workCategories.status === 'success') {
      const currentRegion =
        combinedState.defaultFilter.workCategoryBKPNumber ??
        combinedState.filter.workCategoryBKPNumber;
      setWorkCategoryBKPNumber(currentRegion);
    }
  }, [workCategories, combinedState]);

  const [vehicleSpecialty, setVehicleSpecialty] = useState<
    VehicleSpecialtyNames | undefined
  >(undefined);
  useEffect(() => {
    const currentVehicleSpecialty =
      combinedState.defaultFilter.vehicleSpecialty ??
      combinedState.filter.vehicleSpecialty;
    setVehicleSpecialty(currentVehicleSpecialty);
  }, [combinedState]);

  Globals.vehicleBrands;

  const [vehicleBrand, setVehicleBrand] = useState<
    VehicleBrandNames | undefined
  >(undefined);
  useEffect(() => {
    const currentVehicleBrandName =
      combinedState.defaultFilter.vehicleBrand ??
      combinedState.filter.vehicleBrand;
    setVehicleBrand(currentVehicleBrandName);
  }, [combinedState]);

  const [glassOnly, setGlassOnly] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    const currentGlassOnly =
      combinedState.defaultFilter.glassOnly ?? combinedState.filter.glassOnly;
    setGlassOnly(currentGlassOnly);
  }, [combinedState]);

  const [isExternalPartner, setIsExternalPartner] = useState<
    boolean | undefined
  >(undefined);
  useEffect(() => {
    const current =
      combinedState.defaultFilter.isExternalPartner ??
      combinedState.filter.isExternalPartner;
    setIsExternalPartner(current);
  }, [combinedState]);

  const [isIndependentVehicleCraftsman, setIsIndependentVehicleCraftsman] =
    useState<boolean | undefined>(undefined);
  useEffect(() => {
    const current =
      combinedState.defaultFilter.isIndependentVehicleCraftsman ??
      combinedState.filter.isIndependentVehicleCraftsman;
    setIsIndependentVehicleCraftsman(current);
  }, [combinedState]);

  const [isIndependentPropertyCraftsman, setIsIndependentPropertyCraftsman] =
    useState<boolean | undefined>(undefined);
  useEffect(() => {
    const current =
      combinedState.defaultFilter.isIndependentPropertyCraftsman ??
      combinedState.filter.isIndependentPropertyCraftsman;
    setIsIndependentPropertyCraftsman(current);
  }, [combinedState]);

  const [headquarters, setHeadquarters] = useState<boolean | undefined>(
    undefined,
  );
  useEffect(() => {
    const currentHeadquarters =
      combinedState.defaultFilter.headquarters ??
      combinedState.filter.headquarters;
    setHeadquarters(currentHeadquarters);
  }, [combinedState]);

  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const onCompanyNameSearchDelay = (value: string) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.craftsmans.status === 'success') {
          draftState.craftsmans.status = 'loading-with-value';
        }
        draftState.filter.companyName = value;
        draftState.filter.page = 0;
      }),
    );
  };

  const onStreetAndNumberSearchDelay = (value: string) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.craftsmans.status === 'success') {
          draftState.craftsmans.status = 'loading-with-value';
        }
        draftState.filter.streetAndNumber = value;
        draftState.filter.page = 0;
      }),
    );
  };

  const token = useToken();

  return (
    <div className="p-5 mb-4">
      <div className="columns is-vcentered">
        <BalHeading
          space="none"
          className="column has-text-black pt-5 pb-0"
          level="h1"
        >
          {combinedState.label}
        </BalHeading>
        <BalButton
          color="primary-light"
          size="small"
          onClick={(event) => event.detail == 1 && setShowMap(!showMap)}
          square
          outlined
        >
          <Icon iconPath={showMap ? listIcon : mapIcon} />
        </BalButton>
        <BalButton
          color="primary-light"
          size="small"
          square
          outlined
          className="ml-2"
          onClick={async (event) => {
            if (showFilters && event.detail == 1) {
              const element = document.getElementById('filters');
              element?.classList.add('filters-menu-out-426px');
              element?.classList.remove('filters-menu-426px');
              setTimeout(async () => {
                setShowFilters(false);
                await postShowFiltersUserTableSettings(
                  false,
                  'Craftsman',
                  user,
                  setUser,
                  token,
                );
              }, 350);
            } else if (event.detail == 1) {
              setShowFilters(true);
              await postShowFiltersUserTableSettings(
                true,
                'Craftsman',
                user,
                setUser,
                token,
              );
            }
          }}
        >
          <Icon iconPath={filterIcon} />
        </BalButton>
        <CreateEditNetworkPartner
          key="newPartner"
          action="create"
          filterBranch={combinedState.defaultFilter.branch}
        />
      </div>
      {showFilters && (
        <div
          className="filters-menu-426px columns is-gapless is-multiline pr-4"
          id="filters"
        >
          <div className="column is-10">
            <div className="grid-auto-fit">
              <BalTextField
                disabled={
                  !!combinedState.defaultFilter.partnerNumber && !editFilter
                }
                className="base-task-search-form-fields"
                titleClassName="mb-1px"
                title={t('networkPartners.partnerNumber')}
                placeholder={t('networkPartners.partnerNumber')}
                value={combinedState.filter.partnerNumber}
                onChange={(e: CustomEvent<string | undefined>) => {
                  if (e.detail || e.detail === '') {
                    setCombinedState(
                      produce(combinedState, (draftState) => {
                        if (combinedState.craftsmans.status === 'success') {
                          draftState.craftsmans.status = 'loading-with-value';
                        }
                        draftState.filter.partnerNumber = e.detail;
                      }),
                    );
                  }
                }}
              />
              <BalTextField
                disabled={
                  !!combinedState.defaultFilter.companyName && !editFilter
                }
                className="base-task-search-form-fields"
                titleClassName="mb-1px"
                title={t('general.company')}
                placeholder={t('general.company')}
                value={timedSearchFields.companyName}
                onInput={(e: CustomEvent<string | undefined>) => {
                  clearTimeout(timer);
                  const newTimer = setTimeout(() => {
                    onCompanyNameSearchDelay(e.detail ?? '');
                  }, 500);
                  setTimer(newTimer);

                  setTimedSearchField({
                    ...timedSearchFields,
                    companyName: e.detail,
                  });
                }}
              />
              <RadiusSearch
                className="base-task-search-form-fields"
                disabled={!!combinedState.defaultFilter.location && !editFilter}
                combinedState={combinedState}
                setCombinedState={setCombinedState}
                timer={timer}
                setTimer={setTimer}
              />
              <BalTextField
                className="base-task-search-form-fields"
                disabled={
                  !!combinedState.defaultFilter.streetAndNumber && !editFilter
                }
                value={timedSearchFields.streetAndNumber}
                title={t('general.streetAndNumber')}
                placeholder={t('general.streetAndNumber')}
                onInput={(e: CustomEvent<string | undefined>) => {
                  clearTimeout(timer);
                  const newTimer = setTimeout(() => {
                    onStreetAndNumberSearchDelay(e.detail ?? '');
                  }, 500);
                  setTimer(newTimer);

                  setTimedSearchField({
                    ...timedSearchFields,
                    streetAndNumber: e.detail,
                  });
                }}
              />
              <BalField className="base-task-search-form-fields">
                <BalFieldLabel className="mb-1px">
                  {t('general.branch')}
                </BalFieldLabel>
                <BalFieldControl>
                  <BalTypeAheadField
                    placeholder={capitalize(t('general.branch'))}
                    disabled={
                      !!combinedState.defaultFilter.branch && !editFilter
                    }
                    blocker={
                      !!combinedState.defaultFilter.branch && !editFilter
                    }
                    value={branch}
                    result={allBranches}
                    valueFormatter={(value) => t(`branch.${value}`)}
                    keyFormatter={(value) => value}
                    onChange={(choice) => {
                      setCombinedState(
                        produce(combinedState, (draftState) => {
                          if (combinedState.craftsmans.status === 'success') {
                            draftState.craftsmans.status = 'loading-with-value';
                          }
                          draftState.filter.branch =
                            choice === Globals.noneValue
                              ? undefined
                              : (choice as Branch);
                        }),
                      );
                    }}
                  />
                </BalFieldControl>
              </BalField>
              <BalField className="base-task-search-form-fields">
                <BalFieldLabel className="mb-1px">
                  {capitalize(t('networkPartners.responsibility'))}
                </BalFieldLabel>
                {responsibles && responsibles.status === 'success' && (
                  <BalFieldControl>
                    <BalTypeAheadField
                      placeholder={capitalize(
                        t('networkPartners.responsibility'),
                      )}
                      value={responsible}
                      disabled={
                        !!combinedState.defaultFilter.responsibleUserId &&
                        !editFilter
                      }
                      blocker={
                        !!combinedState.defaultFilter.responsibleUserId &&
                        !editFilter
                      }
                      result={responsibles.value}
                      valueFormatter={(value) => getDisplayNameFromUser(value)}
                      keyFormatter={(value) => value?.id}
                      onChange={(choice) => {
                        setCombinedState(
                          produce(combinedState, (draftState) => {
                            if (combinedState.craftsmans.status === 'success') {
                              draftState.craftsmans.status =
                                'loading-with-value';
                            }
                            draftState.filter.responsibleUserId = choice?.id;
                          }),
                        );
                      }}
                    />
                  </BalFieldControl>
                )}
              </BalField>
              <BalField className="base-task-search-form-fields">
                <BalFieldLabel className="mb-1px">
                  {t('networkPartners.isExternalPartner')}
                </BalFieldLabel>
                <BalFieldControl>
                  <BalTypeAheadField
                    disabled={
                      combinedState.defaultFilter.isExternalPartner !==
                        undefined && !editFilter
                    }
                    blocker={
                      combinedState.defaultFilter.isExternalPartner !==
                        undefined && !editFilter
                    }
                    placeholder={t('networkPartners.isExternalPartner')}
                    value={isExternalPartner}
                    result={[true, false]}
                    valueFormatter={(value) => {
                      return t(`tristate.${value}`);
                    }}
                    keyFormatter={(value) => value?.toString()}
                    onChange={(choice) => {
                      setCombinedState(
                        produce(combinedState, (draftState) => {
                          if (combinedState.craftsmans.status === 'success') {
                            draftState.craftsmans.status = 'loading-with-value';
                          }

                          draftState.filter.isExternalPartner = choice;
                        }),
                      );
                    }}
                  />
                </BalFieldControl>
              </BalField>

              {(combinedState.defaultFilter.branch === undefined ||
                combinedState.defaultFilter.branch === 'Property') && (
                <div className="pl-4 pt-3 column is-12">
                  <BalHeading level="h5" space="none" className="pb-2">
                    {t('networkPartners.PropertyCraftsman')}
                  </BalHeading>
                  <div className="columns">
                    {
                      <BalField className="base-task-search-form-fields">
                        <BalFieldLabel className="mb-1px">
                          {t('networkPartners.region')}
                        </BalFieldLabel>
                        <BalFieldControl>
                          <BalTypeAheadField
                            disabled={
                              combinedState.defaultFilter.region !==
                                undefined && !editFilter
                            }
                            blocker={
                              combinedState.defaultFilter.region !==
                                undefined && !editFilter
                            }
                            placeholder={t('networkPartners.region')}
                            value={region}
                            result={Globals.regions}
                            valueFormatter={(value) => t(`region.${value}`)}
                            keyFormatter={(value) => value}
                            onChange={(choice) => {
                              setCombinedState(
                                produce(combinedState, (draftState) => {
                                  if (
                                    combinedState.craftsmans.status ===
                                    'success'
                                  ) {
                                    draftState.craftsmans.status =
                                      'loading-with-value';
                                  }
                                  draftState.filter.region = choice as Region;
                                }),
                              );
                            }}
                          />
                        </BalFieldControl>
                      </BalField>
                    }
                    {workCategories.status === 'success' && (
                      <BalField className="base-task-search-form-fields">
                        <BalFieldLabel className="mb-1px">
                          {t('networkPartners.workCategories')}
                        </BalFieldLabel>
                        <BalFieldControl>
                          <BalTypeAheadField
                            disabled={
                              combinedState.defaultFilter
                                .workCategoryBKPNumber !== undefined &&
                              !editFilter
                            }
                            blocker={
                              combinedState.defaultFilter
                                .workCategoryBKPNumber !== undefined &&
                              !editFilter
                            }
                            placeholder={t('workCategory.label')}
                            value={workCategoryBKPNumber}
                            result={workCategories.localValue.map(
                              (workCategory) => workCategory.bkpNumber,
                            )}
                            valueFormatter={(value) =>
                              getLabelValueFromBkpNumber(value)
                            }
                            keyFormatter={(value) => value}
                            onChange={(choice) => {
                              setCombinedState(
                                produce(combinedState, (draftState) => {
                                  if (
                                    combinedState.craftsmans.status ===
                                    'success'
                                  ) {
                                    draftState.craftsmans.status =
                                      'loading-with-value';
                                  }
                                  draftState.filter.workCategoryBKPNumber =
                                    choice as string;
                                }),
                              );
                            }}
                          />
                        </BalFieldControl>
                      </BalField>
                    )}
                    <BalField className="base-task-search-form-fields">
                      <BalFieldLabel className="mb-1px">
                        {t('networkPartners.isIndependentPropertyCraftsman')}
                      </BalFieldLabel>
                      <BalFieldControl>
                        <BalTypeAheadField
                          disabled={
                            combinedState.defaultFilter
                              .isIndependentPropertyCraftsman !== undefined &&
                            !editFilter
                          }
                          blocker={
                            combinedState.defaultFilter
                              .isIndependentPropertyCraftsman !== undefined &&
                            !editFilter
                          }
                          placeholder={t(
                            'networkPartners.isIndependentPropertyCraftsman',
                          )}
                          value={isIndependentPropertyCraftsman}
                          result={[true, false]}
                          valueFormatter={(value) => {
                            return t(`tristate.${value}`);
                          }}
                          keyFormatter={(value) => value?.toString()}
                          onChange={(choice) => {
                            setCombinedState(
                              produce(combinedState, (draftState) => {
                                if (
                                  combinedState.craftsmans.status === 'success'
                                ) {
                                  draftState.craftsmans.status =
                                    'loading-with-value';
                                }

                                draftState.filter.isIndependentPropertyCraftsman =
                                  choice;
                              }),
                            );
                          }}
                        />
                      </BalFieldControl>
                    </BalField>
                  </div>
                </div>
              )}
              {(combinedState.defaultFilter.branch === undefined ||
                combinedState.defaultFilter.branch === 'MotorVehicle') && (
                <div className="pl-4 pt-3">
                  <BalHeading level="h5" space="none" className="pb-2">
                    {t('networkPartners.VehicleCraftsman')}
                  </BalHeading>
                  <div className="columns">
                    <BalField className="base-task-search-form-fields">
                      <BalFieldLabel className="mb-1px">
                        {t('networkPartners.specialtyCompany')}
                      </BalFieldLabel>
                      <BalFieldControl>
                        <BalTypeAheadField
                          placeholder={t('networkPartners.specialtyCompany')}
                          disabled={
                            combinedState.defaultFilter.vehicleSpecialty !==
                              undefined && !editFilter
                          }
                          blocker={
                            combinedState.defaultFilter.vehicleSpecialty !==
                              undefined && !editFilter
                          }
                          value={vehicleSpecialty}
                          result={Globals.vehicleSpecialties}
                          valueFormatter={(value) =>
                            t(`vehicleSpecialty.${value}`)
                          }
                          keyFormatter={(value) => value}
                          onChange={(choice) => {
                            setCombinedState(
                              produce(combinedState, (draftState) => {
                                if (
                                  combinedState.craftsmans.status === 'success'
                                ) {
                                  draftState.craftsmans.status =
                                    'loading-with-value';
                                }
                                draftState.filter.vehicleSpecialty =
                                  choice as VehicleSpecialtyNames;
                              }),
                            );
                          }}
                        />
                      </BalFieldControl>
                    </BalField>
                    <BalField className="base-task-search-form-fields">
                      <BalFieldLabel className="mb-1px">
                        {t('networkPartners.carBrands')}
                      </BalFieldLabel>
                      <BalFieldControl>
                        <BalTypeAheadField
                          disabled={
                            combinedState.defaultFilter.vehicleBrand !==
                              undefined && !editFilter
                          }
                          blocker={
                            combinedState.defaultFilter.vehicleBrand !==
                              undefined && !editFilter
                          }
                          placeholder={t('networkPartners.carBrands')}
                          value={vehicleBrand}
                          result={Globals.vehicleBrands}
                          valueFormatter={(value) => value.toString()}
                          keyFormatter={(value) => value}
                          onChange={(choice) => {
                            setCombinedState(
                              produce(combinedState, (draftState) => {
                                if (
                                  combinedState.craftsmans.status === 'success'
                                ) {
                                  draftState.craftsmans.status =
                                    'loading-with-value';
                                }
                                draftState.filter.vehicleBrand =
                                  choice as VehicleBrandNames;
                              }),
                            );
                          }}
                        />
                      </BalFieldControl>
                    </BalField>
                    <BalField className="base-task-search-form-fields">
                      <BalFieldLabel className="mb-1px">
                        {t('networkPartners.onlyGlass')}
                      </BalFieldLabel>
                      <BalFieldControl>
                        <BalTypeAheadField
                          disabled={
                            combinedState.defaultFilter.glassOnly !==
                              undefined && !editFilter
                          }
                          blocker={
                            combinedState.defaultFilter.glassOnly !==
                              undefined && !editFilter
                          }
                          placeholder={t('networkPartners.onlyGlass')}
                          value={glassOnly}
                          result={[true, false]}
                          valueFormatter={(value) => {
                            return t(`tristate.${value}`);
                          }}
                          keyFormatter={(value) => value?.toString()}
                          onChange={(choice) => {
                            setCombinedState(
                              produce(combinedState, (draftState) => {
                                if (
                                  combinedState.craftsmans.status === 'success'
                                ) {
                                  draftState.craftsmans.status =
                                    'loading-with-value';
                                }

                                draftState.filter.glassOnly = choice;
                              }),
                            );
                          }}
                        />
                      </BalFieldControl>
                    </BalField>
                    <BalField className="base-task-search-form-fields">
                      <BalFieldLabel className="mb-1px">
                        {t('networkPartners.isIndependentVehicleCraftsman')}
                      </BalFieldLabel>
                      <BalFieldControl>
                        <BalTypeAheadField
                          disabled={
                            combinedState.defaultFilter
                              .isIndependentVehicleCraftsman !== undefined &&
                            !editFilter
                          }
                          blocker={
                            combinedState.defaultFilter
                              .isIndependentVehicleCraftsman !== undefined &&
                            !editFilter
                          }
                          placeholder={t(
                            'networkPartners.isIndependentVehicleCraftsman',
                          )}
                          value={isIndependentVehicleCraftsman}
                          result={[true, false]}
                          valueFormatter={(value) => {
                            return t(`tristate.${value}`);
                          }}
                          keyFormatter={(value) => value?.toString()}
                          onChange={(choice) => {
                            setCombinedState(
                              produce(combinedState, (draftState) => {
                                if (
                                  combinedState.craftsmans.status === 'success'
                                ) {
                                  draftState.craftsmans.status =
                                    'loading-with-value';
                                }

                                draftState.filter.isIndependentVehicleCraftsman =
                                  choice;
                              }),
                            );
                          }}
                        />
                      </BalFieldControl>
                    </BalField>
                    <BalField className="base-task-search-form-fields">
                      <BalFieldLabel className="mb-1px">
                        {t('networkPartners.headquarters')}
                      </BalFieldLabel>
                      <BalFieldControl>
                        <BalTypeAheadField
                          disabled={
                            combinedState.defaultFilter.headquarters !==
                              undefined && !editFilter
                          }
                          blocker={
                            combinedState.defaultFilter.headquarters !==
                              undefined && !editFilter
                          }
                          placeholder={t('networkPartners.headquarters')}
                          value={headquarters}
                          result={[true, false]}
                          valueFormatter={(value) => t(`tristate.${value}`)}
                          keyFormatter={(value) => value?.toString()}
                          onChange={(choice) => {
                            setCombinedState(
                              produce(combinedState, (draftState) => {
                                if (
                                  combinedState.craftsmans.status === 'success'
                                ) {
                                  draftState.craftsmans.status =
                                    'loading-with-value';
                                }
                                draftState.filter.headquarters = choice;
                              }),
                            );
                          }}
                        />
                      </BalFieldControl>
                    </BalField>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="position-absolute is-flex is-flex-direction-column filter-options mr-2">
            <div className="is-flex is-flex-direction-column mr-4">
              <BalButton
                size="small"
                className="is-full-width"
                onClick={() => {
                  setCombinedState(
                    produce(combinedState, (draftState) => {
                      if (combinedState.craftsmans.status === 'success') {
                        draftState.craftsmans.status = 'loading-with-value';
                      }
                      draftState.filter = combinedState.defaultFilter;
                    }),
                  );
                }}
              >
                {t('general.resetFilter')}
              </BalButton>
              <BalButton
                className="my-4 is-full-width"
                size="small"
                color="primary"
                onClick={() => {
                  openFilterSaveDialog();
                }}
              >
                {t('general.buttons.createCustomFilter')}
              </BalButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NetworkHeader;
