import {
  BalHeading,
  BalButton,
  BalButtonGroup,
  BalText,
  BalCard,
  BalIcon,
  BalFileUpload,
  BalSelect,
  BalSelectOption,
  BalSpinner,
} from '@baloise/design-system-components-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import {
  deleteInspectionPhoto,
  getInspectionPhotos,
  putInspectionPhotoRemarks,
} from '../../data/requests';
import {
  balIconDownload,
  balIconCaretLeft,
  balIconCaretRight,
} from '@baloise/design-system-icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { EBRoutes } from '../../../../router/router';
import { PropertyInspectionTaskSendingWizardViewContext } from './property-inspection-task-sending-wizard-view';
import {
  getInspectionPhoto,
  postInspectionPhotosDto,
} from '../../../files/data/requests';
import { useToken } from '../../../../hooks/useToken';
import { ErrorToast } from '../../../../components/toast-notification/error-toast';
import {
  BalTextareaFormField,
  EditTextModal,
} from '../../../../components/input';
import { useForm } from 'react-hook-form';
import { balModalController } from '../../../../controller/controllers';
import { RemoveInspectionPhotoConfirmationModal } from './remove-inspection-photo-confirmation-modal';
import {
  PropertyInspectionTaskPhotoDto,
  ResponseOfPropertyInspectionTaskPhotoDto,
} from '../../../../types/resource-models';
import { ToastNotification } from '../../../../components/toast-notification';
import { concat, convertBytesToMB } from '../../../../utils';
import { formatDateTime } from '../../../../utils/date';

type RequestFileResults<T> = {
  status: 'initial' | 'loading' | 'success' | 'error';
  files?: T[];
  errorValue?: string;
};

export const PropertyInspectionTaskInspectionCard = ({
  onNext,
}: {
  onNext: () => void;
}): JSX.Element => {
  const { token, propertyInspectionTask, inspectionId } = useContext(
    PropertyInspectionTaskSendingWizardViewContext,
  );

  const { t } = useTranslation();

  const [combinedState, setCombinedState] = useState<
    RequestResult<ResponseOfPropertyInspectionTaskPhotoDto>
  >({ status: 'initial' });

  const [combinedSelectionState, setCombinedSelectionState] = useState<
    RequestResult<ResponseOfPropertyInspectionTaskPhotoDto>
  >({ status: 'initial' });

  const [fileUrls, setFileUrls] = useState<RequestFileResults<string>>({
    status: 'initial',
  });

  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const balSelectRef = useRef<HTMLBalSelectElement>(null);

  useEffect(() => {
    if (
      token &&
      combinedState.status === 'initial' &&
      propertyInspectionTask.status === 'success' &&
      inspectionId != null
    ) {
      setCombinedState({ status: 'loading' });
      getInspectionPhotos(token, inspectionId).then((response) => {
        setCombinedState(response);
        setCombinedSelectionState({ status: 'initial' });
      });
    }
    if (
      combinedState.status === 'success' &&
      combinedState.value.entities.length > 0 &&
      //selectedLocation is not in combinedState.value.entities.location
      !combinedState.value.entities.find(
        (photo) => photo.location === selectedLocation,
      )
    ) {
      setSelectedLocation(combinedState.value.entities[0].location ?? '');
    } else {
      balSelectRef.current?.setAttribute('value', selectedLocation);
    }
  }, [
    token,
    combinedState,
    propertyInspectionTask,
    selectedLocation,
    inspectionId,
  ]);

  useEffect(() => {
    if (
      combinedSelectionState.status === 'initial' &&
      combinedState.status === 'success'
    ) {
      // Filter combinedState by location as RequestResult<ResponseOfPropertyInspectionTaskPhotoDto>
      const filteredCombinedState = {
        entities: combinedState.value.entities.filter(
          (inspectionPhoto) => inspectionPhoto.location === selectedLocation,
        ),
        totalPages: 0,
        totalItems: 0,
      };

      //copy the value of combinedState to combinedSelectionState
      setCombinedSelectionState({
        status: 'success',
        value: filteredCombinedState,
        localValue: filteredCombinedState, // Add the localValue property
      });

      setFileUrls({ status: 'initial' });
    }
  }, [token, combinedSelectionState, combinedState, selectedLocation]);

  useEffect(() => {
    if (fileUrls.status === 'initial') {
      setFileUrls({ status: 'loading' });

      if (
        combinedSelectionState.status === 'success' &&
        combinedSelectionState.value.entities.length > 0
      ) {
        // Map through each inspection photo entity
        Promise.all(
          combinedSelectionState.value.entities.map((inspectionPhoto) =>
            getInspectionPhoto(
              token,
              inspectionPhoto.inspectionId,
              inspectionPhoto.id,
            ).then((fileResponse) => {
              if (fileResponse.status === 'error') {
                toast(ErrorToast(fileResponse.errorValue));
              }
              if (fileResponse.status === 'success') {
                return fileResponse.value;
              }
              return '';
            }),
          ),
        )
          .then((urls) => {
            // Here we handle an array of URLs, one for each photo
            setFileUrls({ status: 'success', files: urls });
          })
          .catch((error) => {
            // Handle case where any of the promises might reject
            setFileUrls({ status: 'error', errorValue: error.toString() });
          });
      }
    }
  }, [combinedSelectionState, fileUrls, token]);

  useEffect(() => {
    balSelectRef.current?.setAttribute('value', selectedLocation);
    setCombinedSelectionState({ status: 'initial' });
  }, [selectedLocation]);

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const methods = useForm({
    mode: 'onChange',
  });

  const inspectionPhotos = {
    entities:
      combinedSelectionState.status === 'success'
        ? combinedSelectionState.value.entities
        : [],
    totalPages: 0,
    totalItems: 0,
  };

  const mapInspectionPhotos = inspectionPhotos.entities.map(
    (inspectionPhoto: PropertyInspectionTaskPhotoDto, index: number) => (
      <div
        key={inspectionPhoto.id}
        className="is-flex is-align-items-center mt-5"
      >
        <div className="mb-1 bottom-blue-line is-justify-content-space-between is-full-width">
          <div className="is-flex is-flex-direction-row my-auto">
            <BalText bold color="info" className="column">
              {`${inspectionPhoto.fileName
                ?.replace(`${inspectionPhoto.inspectionId}-FS-`, '')
                .replace(`${inspectionPhoto.inspectionId}-`, '')}`}
            </BalText>
            <InspectionPhotoButtons
              inspectionPhoto={inspectionPhoto}
              onUpdate={() => {
                setCombinedState({ status: 'initial' });
              }}
            />
          </div>
          <div className="is-flex is-flex-direction-row">
            {fileUrls.status === 'success' &&
            fileUrls.files &&
            fileUrls.files.length > 0 ? (
              <img
                src={fileUrls.files[index]}
                className="column max-height-400px is-4"
                width={500}
              />
            ) : fileUrls.status === 'error' ? (
              <div className="column max-height-400px is-4">
                {t('general.loadingImageError')}
              </div>
            ) : (
              <div className="column max-height-400px is-4">
                {t('general.loadingImage')}
              </div>
            )}

            <BalTextareaFormField
              controllerName="signature"
              methods={methods}
              required={false}
              className="column ml-4"
              placeholder={t('inspectionTask.photos.remarks')}
              title={t('inspectionTask.photos.remarks')}
              value={inspectionPhoto.remarks}
              disabled={true}
              rows={10}
            />
          </div>
        </div>
      </div>
    ),
  );

  return (
    <>
      {propertyInspectionTask.status === 'success' && (
        <BalCard className="my-5 p-5">
          <BalHeading level="h3">
            {t('inspectionTask.photos.inspectionPhotoSelection')}
          </BalHeading>

          {inspectionId != null && (
            <BalFileUpload
              className={'my-5 py-5'}
              onBalRejectedFile={(FileUploadRejectedFile) => {
                if (
                  FileUploadRejectedFile.detail.reasons[0] != 'DUPLICATED_FILE'
                ) {
                  toast(
                    ToastNotification({
                      message: t('error.fileTypeError'),
                      color: 'danger',
                    }),
                  );
                }
              }}
              loading={loading}
              accept={[
                'image/jpeg',
                'image/png',
                'image/bmp',
                'image/gif',
              ].join()}
              onBalChange={async (event: CustomEvent<File[]>) => {
                if (combinedState.status === 'success') {
                  setLoading(true);

                  if (event.detail.length > 25) {
                    toast(
                      ToastNotification({
                        message: t('inspectionTask.photos.maxAmountOfPhotos'),
                        color: 'danger',
                      }),
                    );
                    event.detail.splice(0, event.detail.length);
                    setLoading(false);
                  } else {
                    await postInspectionPhotosDto(
                      token,
                      event.detail,
                      inspectionId,
                    ).then((response) => {
                      setLoading(false);
                      if (response.status === 'success') {
                        event.detail.splice(0, event.detail.length);
                        setCombinedState({ status: 'initial' });
                      } else if (response.status === 'error') {
                        toast(ErrorToast(response.errorValue));
                        event.detail.splice(0, event.detail.length);
                      }
                    });
                  }
                }
              }}
              label={`${t('general.uploadInspectionPhotos')}`}
              multiple={true}
              subTitle={(file: File) =>
                concat([
                  file.type
                    .substring(file.type.lastIndexOf('/') + 1)
                    .toUpperCase(),
                  `(${convertBytesToMB(file.size)} MB)`,
                  concat(
                    [
                      '(',
                      formatDateTime(new Date(file.lastModified).toISOString()),
                      ')',
                    ],
                    'none',
                  ),
                ])
              }
            />
          )}
          <div className="is-flex is-flex-direction-row is-justify-content-center is-full-width ">
            {combinedState.status != 'success' && <BalSpinner></BalSpinner>}
            {combinedState.status == 'success' &&
              combinedState.value.entities.length > 0 && (
                <>
                  <BalButton
                    className="ml-4 mr-1 mt-5"
                    color="primary"
                    size="small"
                    square
                    onClick={() => {
                      // Navigate to previous location
                      let previousLocation = selectedLocation;

                      const locations = [
                        //remove duplicate value by using a set
                        ...new Set(
                          combinedState.value.entities.map(
                            (photo) => photo.location,
                          ),
                        ),
                      ];
                      const currentIndex = locations.findIndex(
                        (location) => location === selectedLocation,
                      );
                      if (currentIndex > 0) {
                        previousLocation =
                          locations[currentIndex - 1] ?? selectedLocation;
                      } else {
                        previousLocation =
                          locations[locations.length - 1] ?? selectedLocation;
                      }
                      setSelectedLocation(previousLocation);
                    }}
                  >
                    <BalIcon svg={balIconCaretLeft} size="small" />
                  </BalButton>
                  <BalSelect
                    className="column is-4"
                    ref={balSelectRef}
                    onBalChange={(e) => {
                      if (e) {
                        if (e.detail) {
                          setSelectedLocation(e.detail.toString());
                        }
                      }
                    }}
                  >
                    <div className="bal-select__inner">
                      {[
                        //remove duplicate value by using a set
                        ...new Set(
                          combinedState.value.entities.map(
                            (photo) => photo.location,
                          ),
                        ),
                      ].map((location) => (
                        <BalSelectOption
                          key={location}
                          value={location}
                          label={location}
                        >
                          {location}
                        </BalSelectOption>
                      ))}
                    </div>
                  </BalSelect>
                  <BalButton
                    className="ml-4 mr-1 mt-5"
                    color="primary"
                    size="small"
                    square
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={() => {
                      // Navigate to next location
                      let nextLocation = selectedLocation;

                      const locations = [
                        //remove duplicate value by using a set
                        ...new Set(
                          combinedState.value.entities.map(
                            (photo) => photo.location,
                          ),
                        ),
                      ];
                      const currentIndex = locations.findIndex(
                        (location) => location === selectedLocation,
                      );
                      if (currentIndex < locations.length - 1) {
                        nextLocation =
                          locations[currentIndex + 1] ?? selectedLocation;
                      } else {
                        nextLocation = locations[0] ?? selectedLocation;
                      }
                      setSelectedLocation(nextLocation);
                    }}
                  >
                    <BalIcon svg={balIconCaretRight} size="small" />
                  </BalButton>
                </>
              )}
          </div>
          <div className={'my-3'}>{mapInspectionPhotos}</div>
          <div className="pt-6">
            <BalButtonGroup position="right" className="mt-6">
              <BalButton
                color="info"
                onClick={(event) =>
                  event.detail == 1 &&
                  navigate(
                    EBRoutes.PROPERTY_INSPECTION_TASK_DETAIL.create({
                      taskId: propertyInspectionTask.localValue.id,
                    }),
                  )
                }
              >
                {t('general.buttons.cancel')}
              </BalButton>
              <BalButton
                color="info"
                onClick={(event) => {
                  if (event.detail == 1) {
                    onNext();
                  }
                }}
              >
                {t('general.buttons.next')}
              </BalButton>
            </BalButtonGroup>
          </div>
        </BalCard>
      )}
    </>
  );
};

const InspectionPhotoButtons = ({
  inspectionPhoto,
  onUpdate,
}: {
  inspectionPhoto: PropertyInspectionTaskPhotoDto;
  onUpdate: () => void;
}) => {
  const token = useToken();
  const { t } = useTranslation();
  const downloadInspectionPhoto = (view: boolean) => {
    getInspectionPhoto(
      token,
      inspectionPhoto.inspectionId,
      inspectionPhoto.id,
    ).then((fileResponse) => {
      if (fileResponse.status === 'success' && !view) {
        const a = document.createElement('a');
        a.href = fileResponse.value;
        a.download =
          inspectionPhoto.fileName
            ?.replace(`${inspectionPhoto.inspectionId}-FS-`, '')
            .replace(`${inspectionPhoto.inspectionId}-`, '') ??
          'inspectionPhoto.jpg';
        a.target = '_blank';
        a.click();
      } else if (fileResponse.status === 'success' && view) {
        const fileUrl = fileResponse.value;
        const windowName = inspectionPhoto.location;
        const newWindow = window.open(fileUrl, windowName);
        setTimeout(() => {
          if (newWindow) {
            newWindow.document.title = inspectionPhoto.location ?? '';
          }
        }, 400);
      } else if (fileResponse.status === 'error') {
        toast(ErrorToast(fileResponse.errorValue));
      }
    });
  };
  return (
    <div className="flex-float-right">
      <EditTextModal
        isRequired={true}
        textArea
        notOutlined
        label={t('general.claim.remarks')}
        placeholder={t('general.claim.remarks')}
        value={inspectionPhoto.remarks}
        requiredCapabilities="EditPropertyInspectionTask"
        onSave={(value: string | undefined) => {
          inspectionPhoto.remarks = value ?? '';
          putInspectionPhotoRemarks(
            token,
            inspectionPhoto.inspectionId,
            inspectionPhoto.id,
            inspectionPhoto,
          ).then((result) => {
            if (result.status === 'success') {
              balModalController.dismiss();
              onUpdate();
            } else if (result.status === 'error') {
              toast(ErrorToast(result.errorValue));
            }
          });
        }}
      />
      <BalButton
        className="ml-4 mr-1"
        color="primary"
        size="small"
        square
        onClick={() => {
          downloadInspectionPhoto(false);
        }}
      >
        <BalIcon svg={balIconDownload} size="small" />
      </BalButton>
      <InspectionPhotoDeleteButton
        inspectionId={inspectionPhoto.inspectionId}
        inspectionPhotoId={inspectionPhoto.id}
        removeInspectionPhoto={async (inspectionId, inspectionPhotoId) => {
          await deleteInspectionPhoto(
            token,
            inspectionId,
            inspectionPhotoId,
          ).then((result) => {
            if (result.status === 'success-no-value') {
              onUpdate();
            } else if (result.status === 'error') {
              toast(ErrorToast(result.errorValue));
            }
          });
        }}
      />
    </div>
  );
};

type InspectionPhotoDeleteButtonProps = {
  inspectionId: string;
  inspectionPhotoId: string;
  removeInspectionPhoto?: (
    inspectionId: string,
    inspectionPhotoId: string,
  ) => void;
  disabled?: boolean;
};

const InspectionPhotoDeleteButton = (
  props: InspectionPhotoDeleteButtonProps,
) => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: RemoveInspectionPhotoConfirmationModal,
      componentProps: {
        inspectionId: props.inspectionId,
        inspectionPhotoId: props.inspectionPhotoId,
        removeInspectionPhoto: props.removeInspectionPhoto,
      },
      cssClass: 'center-modal',
    });
    return await modal.present();
  };

  return (
    <BalButton
      disabled={props.disabled}
      className="ml-3 mr-1"
      color="primary"
      icon="trash"
      onClick={(event) => event.detail == 1 && openModal()}
      size="small"
      square
    />
  );
};
