import { convertUtcToLocal } from '../../../utils/date';
import { concat } from '../../../utils';
import { TFunction, useTranslation } from 'react-i18next';
import { ImportStateDto } from '../../../../../bat-shared/resource-models';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { BalButton, BalIcon } from '@baloise/design-system-components-react';
import { balModalController } from '../../../controller/controllers';
import ImportFailedModal from './import-failed-modal';
import { balIconSearch } from '@baloise/design-system-icons/src';

type ImportStateTableProps = {
  importStates: ImportStateDto[];
};

export const ImportStateTable = ({
  importStates,
}: ImportStateTableProps): JSX.Element => {
  const { t } = useTranslation();
  const getDefaultColDefs = (t: TFunction): ColDef[] => {
    return [
      {
        valueGetter: (params) => {
          const importState = params.data as ImportStateDto;
          return concat([importState.firstName, importState.lastName]);
        },
        headerName: t('importExport.executingUser'),
        field: 'lastName,firstname',
        minWidth: 125,
      },
      {
        valueGetter: (params) => {
          const importState = params.data as ImportStateDto;
          return convertUtcToLocal({ date: importState.date });
        },
        headerName: t('importExport.date'),
        field: 'date',
        minWidth: 80,
      },
      {
        headerName: t('importExport.imported'),
        field: 'imported',
        minWidth: 60,
      },
      {
        headerName: t('importExport.skipped'),
        field: 'skipped',
        minWidth: 60,
      },
      {
        headerName: t('importExport.failed'),
        field: 'failed',
        minWidth: 60,
      },
      {
        cellRenderer: 'showFailedButtonRenderer',
        headerName: '',
        width: 60,
        maxWidth: 60,
      },
    ];
  };

  const getGridOptions = (t: TFunction): GridOptions => {
    return {
      defaultColDef: {
        sortable: true,
        filter: false,
        width: 60,
        flex: 1,
        minWidth: 60,
        resizable: true,
      },
      //ag grid inserts default message if is either undefined or empty string, and gives error if is just a space
      // '&nbsp;' also just equals empty space, but works in this case
      overlayNoRowsTemplate: '&nbsp;',
      columnDefs: getDefaultColDefs(t),
      components: {
        showFailedButtonRenderer: function renderEdit(
          params: ICellRendererParams,
        ) {
          const importState = params.data as ImportStateDto;
          const showFailedModal = async () => {
            const modal = await balModalController.create({
              component: ImportFailedModal,
              componentProps: {
                failedIdentifiers: importState.failedIdentifiers,
              },
              cssClass: 'center-modal',
            });
            return modal.present();
          };
          return (
            <BalButton size="small" square onClick={showFailedModal}>
              <BalIcon svg={balIconSearch}></BalIcon>
            </BalButton>
          );
        },
      },
    };
  };

  return (
    <>
      <div className="task-table-detail-import-state">
        <AgGridReact
          suppressColumnMoveAnimation={true}
          suppressCellFocus={true}
          rowSelection={'single'}
          rowData={importStates}
          rowHeight={38}
          gridOptions={getGridOptions(t)}
        />
      </div>
    </>
  );
};
