import {
  BalClose,
  BalDatepicker,
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalIcon,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils';

export const DateRangePicker = ({
  title,
  fromValue,
  toValue,
  disabled,
  onFromChange,
  onToChange,
  setBothDates,
}: {
  title: string;
  fromValue: string | undefined;
  toValue: string | undefined;
  disabled?: boolean | undefined;
  onFromChange: (e: CustomEvent<null | string | undefined>) => void;
  onToChange: (e: CustomEvent<null | string | undefined>) => void;
  setBothDates?: (
    fromValue: string | undefined,
    toValue: string | undefined,
  ) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <BalField className="base-task-search-form-fields">
      <div className="is-flex is-flex-direction-row is-justify-content-space-between">
        <BalFieldLabel className="mb-1 mr-3">{title}</BalFieldLabel>
        {(toValue || fromValue) && !(disabled ?? false) && (
          <BalClose
            onClick={() => {
              setBothDates && setBothDates(undefined, undefined);
            }}
          />
        )}
      </div>
      <BalFieldControl className="date-range-picker is-align-items-center datepicker-width">
        <BalDatepicker
          className="no-icon bal-type-date-range datepicker-width"
          placeholder={capitalize(t('general.from'))}
          value={fromValue}
          onBalChange={onFromChange}
          disabled={disabled}
        />
        <BalIcon name="nav-go-right" size="small" />
        <BalDatepicker
          className="no-icon datepicker-width"
          placeholder={capitalize(t('general.to'))}
          value={toValue}
          onBalChange={onToChange}
          disabled={disabled}
        />
      </BalFieldControl>
    </BalField>
  );
};
