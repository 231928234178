import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalRadio,
  BalRadioGroup,
  BalText,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WithCapabilities } from '../../../components/authorization';
import { balModalController } from '../../../controller/controllers';
import { AppCapabilityNames, PersonDto } from '../../../types/resource-models';
import { concat, concatAddress, StringCap } from '../../../utils';
import { CancelButton } from '../../../components/ui';

type EditPrimaryContactModalProps = {
  value: string;
  values: PersonDto[];
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  onSave(value: string): void;
};
function EditPrimaryContactModalContent(
  props: EditPrimaryContactModalProps,
): JSX.Element {
  const { t } = useTranslation();
  const [selectedContact, setSelectedContact] = useState<string>(props.value);
  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {t('networkPartners.primaryContact')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalRadioGroup value={selectedContact}>
          {props.values.map((value) => (
            <BalRadio
              key={value.id}
              value={value.id}
              onBalClick={() => {
                setSelectedContact(value.id);
              }}
            >
              <BalText color="primary-light" className="mt-4">
                {StringCap(
                  concat(
                    [
                      concat(
                        [
                          concat([
                            value.gender != 'Undisclosed'
                              ? t(`genders.gender.${value.gender}`)
                              : '',
                            value.displayName,
                          ]),
                          value.phoneNumber,
                          value.email,
                        ],
                        'comma-space',
                      ),
                      concatAddress(value.address),
                    ],
                    'comma-space',
                  ),
                  50,
                )}
              </BalText>
            </BalRadio>
          ))}
        </BalRadioGroup>
        <BalButtonGroup position="right">
          <CancelButton />
          <BalButton
            color="info"
            onClick={(event) => {
              event.detail == 1 && props.onSave(selectedContact);
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
}

export function EditPrimaryContactModal(
  props: EditPrimaryContactModalProps,
): JSX.Element {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditPrimaryContactModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        className="mt-6 ml-3"
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
}
