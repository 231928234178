import {
  BalCard,
  BalHeading,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../data';
import {
  getContact,
  BaseTask,
  PropertyInspectionTask,
} from '../../../types/types';
import '../../../styles/general.scss';
import { RefObject, useEffect, useState } from 'react';
import { formatDateTime } from '../../../utils/date';
import { concat } from '../../../utils';
import { useToken } from '../../../hooks';
import { BaseTaskListDto } from '../../../types/resource-models';
import { PropertyTaskWorkflowButtons } from './property-inspection-task-workflow-buttons/property-inspection-task-workflow-buttons';
import { EBRoutes } from '../../../router/router';
import {
  BaseTaskAttachments,
  BaseTaskAditionalInfo,
  BaseTaskClaimInfo,
  BaseTaskClaimNumber,
  BaseTaskContactInformation,
} from '../../base-task';
import { getDisplayNameFromUser } from '../../../utils/utilities';
import BaseTaskInternalNotes from '../../base-task/components/base-task-internal-notes';

type PropertyInspectionTaskOverlayProps = {
  tab?: string;
  fillSpace?: boolean;
  slideIn?: boolean;
  hideLinkButtons?: boolean;
  refTaskOverlay?: RefObject<HTMLBalCardElement>;
  task: BaseTaskListDto;
  replaceTaskInList?: (task: RequestResult<BaseTask>) => void;
  getFullTask: (
    bearerToken: string,
    taskId: string,
  ) => Promise<RequestResult<PropertyInspectionTask>>;
};

export const PropertyInspectionTaskOverlay = ({
  tab,
  task,
  fillSpace,
  slideIn,
  hideLinkButtons,
  refTaskOverlay,
  replaceTaskInList,
  getFullTask,
}: PropertyInspectionTaskOverlayProps): JSX.Element => {
  const { t } = useTranslation();
  const [fullTask, setFullTask] = useState<PropertyInspectionTask | undefined>(
    undefined,
  );
  const token = useToken();

  useEffect(() => {
    if (token !== '' && task && task.id !== '') {
      getFullTask(token, task.id).then((response) => {
        if (
          response.status == 'success' &&
          response.localValue.status == task.status
        ) {
          setFullTask(response.value);
        }
      });
    }
  }, [task, token, getFullTask]);

  const showButtons = fullTask;
  const contact = fullTask && getContact(fullTask);
  return fullTask ? (
    <BalCard
      ref={refTaskOverlay}
      id="overlay"
      className={slideIn ? 'animated-overlay-in' : ''}
    >
      <>
        <BalHeading level="h4" className="overlay-title p-5">
          <div className="is-flex is-flex-direction-column is-full-width">
            <BaseTaskClaimNumber
              detailButtonRequiredCapabilities={[
                'CreatePropertyInspectionTask',
                'EditPropertyInspectionTask',
              ]}
              detailButtonRoute={
                !hideLinkButtons
                  ? EBRoutes.PROPERTY_INSPECTION_TASK_DETAIL.create({
                      taskId: fullTask.id,
                    })
                  : undefined
              }
              propertyInspectionTaskJournalButtonRoute={
                !hideLinkButtons
                  ? `${EBRoutes.PROPERTY_INSPECTION_TASK_DETAIL.create({
                      taskId: fullTask.id,
                    })}?TabItem=journal`
                  : undefined
              }
              claimNumber={fullTask.claim.claimNumber}
            />
            {fullTask.status !== 'New' && (
              <>
                <BalText size="small" className="mt-1 has-text-black">
                  {concat([
                    formatDateTime(fullTask.createdAt),
                    t('general.from').toLowerCase(),
                    getDisplayNameFromUser(fullTask.creator),
                    fullTask.assignee?.insuranceCenter.name,
                  ])}
                </BalText>
                <div className="is-full-width mb-2 mt-2 bottom-blue-line"></div>
              </>
            )}
            <PropertyTaskWorkflowButtons
              task={fullTask}
              className="is-flex is-align-items-center"
              onChangeSuccess={
                replaceTaskInList
                  ? (task) => replaceTaskInList(task)
                  : undefined
              }
            />
          </div>
        </BalHeading>
        <div
          className={concat([
            'overlay-content mb-5 scroll-bar-minimal',
            showButtons ? 'show-buttons' : '',
          ])}
        >
          {fullTask.claim && <BaseTaskClaimInfo claim={fullTask.claim} />}
          {contact && tab !== 'contactInformation' && (
            <BaseTaskContactInformation contact={contact} />
          )}
          {(fullTask.what || fullTask.remarks) &&
            tab !== 'claimInformation' && (
              <>
                <BalHeading level="h5" space="none" className="mt-5">
                  {t('overlay.additionalInformation')}
                </BalHeading>
                <BaseTaskAditionalInfo task={fullTask} />
              </>
            )}
          {fullTask.internalNote && tab !== 'claimInformation' && (
            <BaseTaskInternalNotes internalNote={fullTask.internalNote} />
          )}
          {fullTask.attachments.length > 0 && tab !== 'claimInformation' && (
            <BaseTaskAttachments attachments={fullTask.attachments} />
          )}
        </div>
      </>
    </BalCard>
  ) : fillSpace ? (
    <div className="overlay slide-in is-pulled-right" />
  ) : (
    <div className="overlay-space-slide" />
  );
};
