import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../../controller/controllers';
import { InfoDisplay } from '../../../../components/ui';
import { convertUtcToLocalWithoutTime } from '../../../../utils/date';
import { useToken } from '../../../../hooks';
import { concat } from '../../../../utils';
import { sendBaseTaskToComet } from '../../../base-task/data/requests';
import { TaskTypes, VehicleTask } from '../../../../types/types';
import { toast } from 'react-toastify';
import {
  ErrorToast,
  ToastNotification,
} from '../../../../components/toast-notification';

export interface VehicleTaskSendCometFormProps {
  task: VehicleTask;
}

export const VehicleTaskSendCometModal = ({
  task,
}: VehicleTaskSendCometFormProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const policyHolder =
    task.claim.policyHolder.type === 'person'
      ? task.claim.policyHolder.person
      : task.claim.policyHolder.company;

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3" className="pb-5">
          {t('overlay.sendTotalLossToComet')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div className="rows is-multiline is-gapless is-full-width mt-1">
          <BalHeading space="none" level="h4" color="info" subtitle>
            {t('general.claim.claimInformation')}
          </BalHeading>
          <InfoDisplay
            title={t('overlay.claimNumber')}
            text={task.claim.claimNumber}
            className="pb-1"
          />
          <InfoDisplay
            title={t('overlay.policyNumber')}
            text={task.claim.policyNumber}
            className="pb-1"
          />
          <InfoDisplay
            title={t('overlay.accidentDate')}
            text={
              task.claim.claimDate &&
              convertUtcToLocalWithoutTime(task.claim.claimDate)
            }
            className="pb-1"
          />
          <InfoDisplay
            title={t('overlay.claimCause')}
            text={task.claim.cause?.de}
            className="pb-1"
          />
          <BalHeading space="none" level="h4" color="info" subtitle>
            {t('claimDetail.policyHolder')}
          </BalHeading>
          <InfoDisplay
            title={t('general.name')}
            text={policyHolder.displayName}
            className="pb-1"
          />
          <InfoDisplay
            title={t('general.streetAndNumber')}
            text={concat([
              policyHolder.address?.street,
              policyHolder.address?.houseNumber,
            ])}
            className="pb-1"
          />
          <InfoDisplay
            title={t('general.zipCodeCity')}
            text={concat([
              policyHolder.address?.zipCode?.toString(),
              policyHolder.address?.city,
            ])}
          ></InfoDisplay>
          <InfoDisplay
            title={t('general.phone')}
            text={policyHolder.phoneNumber}
            className="pb-1"
          />
          <InfoDisplay
            title={t('general.email')}
            text={policyHolder.email}
            className="pb-1"
          />
          <BalHeading space="none" level="h4" color="info" subtitle>
            {t('vehicleTask.overlay.vehicleInformation')}
          </BalHeading>
          <InfoDisplay
            title={t('vehicleTask.vehicle.brand')}
            text={task.affectedVehicle.brand}
            className="pb-1"
          />
          <InfoDisplay
            title={t('vehicleTask.vehicle.model')}
            text={task.affectedVehicle.model}
            className="pb-1"
          />
          <InfoDisplay
            title={t('vehicleTask.vehicle.licensePlate')}
            text={task.affectedVehicle.plate}
            className="pb-1"
          />
          <BalHeading space="none" level="h4" color="info" subtitle>
            {t('vehicleTask.vehicleClaimInformation.cardTitle')}
          </BalHeading>
          <InfoDisplay
            title={t('vehicleTask.reportedBy')}
            text={task.reportedBy}
            className="pb-1"
          />
          <InfoDisplay
            title={t('general.claim.creator')}
            text={task.creator.displayName}
            className="pb-1"
          />
          <InfoDisplay
            title={t('assignee.assigneeVehicle')}
            text={task.assignee?.displayName ?? ''}
            className="pb-1"
          />
          <InfoDisplay
            title={t('taskHistory.AppointmentEarliest')}
            text={
              task.appointmentEarliest &&
              convertUtcToLocalWithoutTime(task.appointmentEarliest)
            }
            className="pb-1"
          />
        </div>
        <div className="mt-5">
          <BalButtonGroup position="right">
            <BalButton
              elementType="button"
              color="primary-light"
              onClick={(event) =>
                event.detail == 1 && balModalController.dismiss()
              }
            >
              {t('general.buttons.cancel')}
            </BalButton>
            <BalButton
              color="info"
              onClick={(event) => {
                if (event.detail == 1) {
                  sendBaseTaskToComet(
                    token,
                    task.id,
                    TaskTypes.VehicleTask,
                  ).then((result) => {
                    if (result.status === 'success') {
                      toast(
                        ToastNotification({
                          message: t('overlay.sendToCometSuccessfully'),
                          color: 'success',
                        }),
                      );
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            >
              {t('general.buttons.send')}
            </BalButton>
          </BalButtonGroup>
        </div>
      </BalModalBody>
    </>
  );
};
