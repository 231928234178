import { BalIcon } from '@baloise/design-system-components-react';
import { balIconArrowRightUp } from '@baloise/design-system-icons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../data';
import { BaseTask } from '../../../types/types';

export interface TaskJumpAdosProps {
  claimNumber?: string;
  className?: string;
  task?: RequestResult<BaseTask>;
}
const TaskJumpAdos = ({
  claimNumber,
  className,
  task,
}: TaskJumpAdosProps): JSX.Element => {
  const { t } = useTranslation();
  const getCleanClaimNumber = useCallback(() => {
    let localClaimNumber = '';
    if (claimNumber) {
      localClaimNumber = claimNumber;
    }
    if (task && task.status === 'success') {
      localClaimNumber = task.localValue.claim.claimNumber;
    }
    if (localClaimNumber) {
      return localClaimNumber.replaceAll('/', '').replace('.', '');
    }
    return localClaimNumber;
  }, [task, claimNumber]);
  return (
    <a
      className={`${className}`}
      style={{ verticalAlign: 'text-bottom' }}
      href={`${
        window.extended.BAT_APP_AODS_BASE_URL
      }/ados/exe/ebmodvbs.exe?doAction=3&LogLevel=0&doAsk=0&program=Ebcalliskv&ToFileName=miibrowserstart.exe%20bereich=${
        window.extended.BAT_APP_AODS_ENV
      }%20param1=${getCleanClaimNumber()}%20param2=2`}
      target="_blank"
      rel="noreferrer"
      title={t('overlay.goToAdos')}
    >
      <BalIcon size="medium" svg={balIconArrowRightUp}></BalIcon>
    </a>
  );
};
export default TaskJumpAdos;
