import { BalHeading, BalText } from '@baloise/design-system-components-react';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import {
  EditCheckboxModal,
  EditMultiSelectionModal,
  EditSelectionModal,
  EditTextModal,
} from '../../components/input';
import { ErrorToast } from '../../components/toast-notification';
import { InfoDisplay } from '../../components/ui';
import { RequestResult } from '../../data';
import { AddressEditButton } from '../../components/input/address-edit-button';
import {
  NetworkMapSmall,
  patchCraftsman,
  useWorkCategoriesFromApi,
} from '../../features/network-partners';
import { useToken } from '../../hooks';
import {
  AddressDto,
  CraftsmanDetailDto,
  PersonDto,
  Region,
  VehicleBrandNames,
  VehicleSpecialtyNames,
  WorkCategoryDto,
} from '../../types/resource-models';
import {
  Craftsman,
  PropertyCraftsman,
  TranslatedSelection,
  VehicleCraftsman,
} from '../../types/types';
import {
  concatAddress,
  concatCommaSpace,
  getLabelValueFromBkpNumber,
  Globals,
} from '../../utils';
import { getDefaultAddressWithPartnerId } from '../../utils/utilities';
import { balModalController } from '../../controller/controllers';
import {
  ContactDetailInformation,
  CreateEditContactButton,
} from '../../features/contacts';
import { useDropdownUsers } from '../../features/user';
import { EditPrimaryContactModal } from '../../features/network-partners/components';
import { BalValidators } from '@baloise/web-app-validators';
import { WithCapabilities } from '../../components/authorization';
type NetworkPartnerProps = {
  partner: RequestResult<Craftsman>;
  setPartner: React.Dispatch<React.SetStateAction<RequestResult<Craftsman>>>;
};

const PartnerIdentification = ({
  partner,
  setPartner,
}: NetworkPartnerProps): JSX.Element => {
  const token = useToken();
  const { t } = useTranslation();
  const inspectors = useDropdownUsers({
    appCapabilityNames:
      partner.status === 'success'
        ? partner.localValue.type === 'propertyCraftsman'
          ? ['EditVehicleTask', 'ReadOwnedVehicleTask']
          : ['EditPropertyInspectionTask']
        : undefined,
  });
  const getNameFromInspectorId = (inspectorId: string | undefined) => {
    if (inspectors?.status === 'success') {
      const inspector = inspectors?.value.find((x) => x.id === inspectorId);
      if (inspector === null) {
        return '';
      } else {
        return inspector?.displayName ?? '';
      }
    }
    return '';
  };
  if (partner.status === 'success') {
    return (
      <div className="columns is-multiline is-gapless pr-6">
        <BalHeading level="h4" color="info" subtitle>
          {t('networkPartners.partnerIdentification')}
        </BalHeading>
        <InfoDisplay
          title={t('networkPartners.partnerNumber')}
          text={
            partner.localValue.type === 'propertyCraftsman'
              ? partner.localValue.propertyCraftsman.partnerNumber
              : partner.localValue.vehicleCraftsman.partnerNumber
          }
          className="column is-full pb-1"
          editComponent={
            <EditTextModal
              isRequired={true}
              label={t('networkPartners.partnerNumber')}
              placeholder={t('networkPartners.partnerNumber')}
              value={
                partner.localValue.type === 'propertyCraftsman'
                  ? partner.localValue.propertyCraftsman.partnerNumber
                  : partner.localValue.vehicleCraftsman.partnerNumber
              }
              requiredCapabilities="EditNetworkPartner"
              onSave={(value: string | undefined) => {
                if (value) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'propertyCraftsman') {
                        draftState.propertyCraftsman.partnerNumber = value;
                      } else {
                        draftState.vehicleCraftsman.partnerNumber = value;
                      }
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            />
          }
        />
        <InfoDisplay
          title={t('networkPartners.responsibility')}
          text={getNameFromInspectorId(
            partner.localValue.type === 'propertyCraftsman'
              ? (partner.localValue as CraftsmanDetailDto).propertyCraftsman
                  ?.responsibleId
              : (partner.localValue as CraftsmanDetailDto).vehicleCraftsman
                  ?.responsibleId,
          )}
          className="column is-full pb-1"
          editComponent={
            inspectors?.status === 'success' ? (
              <EditSelectionModal
                label={t('networkPartners.responsibility')}
                value={{
                  value:
                    partner.localValue.type === 'propertyCraftsman'
                      ? (partner.localValue as CraftsmanDetailDto)
                          .propertyCraftsman?.responsibleId ?? ''
                      : (partner.localValue as CraftsmanDetailDto)
                          .vehicleCraftsman?.responsibleId ?? '',
                  translatedValue: getNameFromInspectorId(
                    partner.localValue.type === 'propertyCraftsman'
                      ? (partner.localValue as CraftsmanDetailDto)
                          .propertyCraftsman?.responsibleId
                      : (partner.localValue as CraftsmanDetailDto)
                          .vehicleCraftsman?.responsibleId,
                  ),
                }}
                values={inspectors.value.map((i) => {
                  return { value: i.id, translatedValue: i.displayName ?? '' };
                })}
                placeholder={t('networkPartners.responsibility')}
                requiredCapabilities={'EditNetworkPartner'}
                onSave={function (value: string): void {
                  if (value) {
                    patchCraftsman(
                      token,
                      partner.localValue,
                      produce(partner.localValue, (draftState) => {
                        if (draftState.type === 'propertyCraftsman') {
                          draftState.propertyCraftsman.responsibleId = value;
                        } else {
                          draftState.vehicleCraftsman.responsibleId = value;
                        }
                      }),
                    ).then((result) => {
                      if (result.status === 'success') {
                        setPartner({ status: 'loading' });
                        balModalController.dismiss();
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    });
                  }
                }}
              />
            ) : (
              <></>
            )
          }
        />
      </div>
    );
  } else {
    return <></>;
  }
};

const PartnerCompanyFields = ({
  partner,
  setPartner,
}: NetworkPartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  if (partner.status === 'success') {
    const craftsman =
      partner.localValue.type === 'propertyCraftsman'
        ? partner.localValue.propertyCraftsman
        : partner.localValue.vehicleCraftsman;

    return (
      <div className="columns is-multiline is-gapless pr-6">
        <BalHeading level="h4" color="info" subtitle>
          {t('general.company')}
        </BalHeading>
        <InfoDisplay
          title={t('general.company')}
          text={craftsman.company.name}
          className="column is-full pb-1"
          editComponent={
            <EditTextModal
              isRequired={true}
              label={t('general.company')}
              placeholder={t('general.company')}
              value={craftsman.company.name}
              requiredCapabilities="EditNetworkPartner"
              onSave={(value: string | undefined) => {
                if (value) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'propertyCraftsman') {
                        draftState.propertyCraftsman.company.name = value;
                      } else {
                        draftState.vehicleCraftsman.company.name = value;
                      }
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            />
          }
        />
        <InfoDisplay
          title={t('general.address')}
          className="column is-full pb-1"
          text={concatAddress(craftsman.company.address)}
          editComponent={
            <AddressEditButton
              requiredCapabilities={['EditNetworkPartner']}
              initialValue={
                craftsman.company.address ??
                getDefaultAddressWithPartnerId(craftsman.company.id)
              }
              onSave={async (address: AddressDto) => {
                if (address) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'propertyCraftsman') {
                        draftState.propertyCraftsman.company.address = {
                          partnerId: draftState.propertyCraftsman.company.id,
                          ...address,
                        };
                      } else {
                        draftState.vehicleCraftsman.company.address = {
                          partnerId: draftState.vehicleCraftsman.company.id,
                          ...address,
                        };
                      }
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            />
          }
        />
        <InfoDisplay
          title={t('networkPartners.latitude')}
          className="column is-full pb-1"
          text={
            partner.value.type === 'propertyCraftsman'
              ? partner.value.propertyCraftsman.latitude.toString()
              : partner.value.vehicleCraftsman.latitude.toString()
          }
          editComponent={
            <EditTextModal
              isRequired
              requiredCapabilities={'EditNetworkPartner'}
              label={t('networkPartners.latitude')}
              placeholder={t('networkPartners.latitude')}
              pattern={{
                value: /^[+-]?([0-9]+.?[0-9]*|.[0-9]+)$/,
                message: t('validators.decimalsOnly'),
              }}
              value={
                partner.value.type === 'propertyCraftsman'
                  ? partner.value.propertyCraftsman.latitude.toString()
                  : partner.value.vehicleCraftsman.latitude.toString()
              }
              onSave={(value: string) => {
                if (value) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'propertyCraftsman')
                        draftState.propertyCraftsman.latitude = Number(value);
                      else draftState.vehicleCraftsman.latitude = Number(value);
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      balModalController.dismiss();
                      setPartner({ status: 'loading' });
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            />
          }
        />
        <InfoDisplay
          title={t('networkPartners.longitude')}
          className="column is-full pb-1"
          text={
            partner.value.type === 'propertyCraftsman'
              ? partner.value.propertyCraftsman.longitude.toString()
              : partner.value.vehicleCraftsman.longitude.toString()
          }
          editComponent={
            <EditTextModal
              isRequired
              requiredCapabilities={'EditNetworkPartner'}
              label={t('networkPartners.longitude')}
              placeholder={t('networkPartners.longitude')}
              pattern={{
                value: /^[+-]?([0-9]+.?[0-9]*|.[0-9]+)$/,
                message: t('validators.decimalsOnly'),
              }}
              value={
                partner.value.type === 'propertyCraftsman'
                  ? partner.value.propertyCraftsman.longitude.toString()
                  : partner.value.vehicleCraftsman.longitude.toString()
              }
              onSave={(value: string) => {
                if (value) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'propertyCraftsman')
                        draftState.propertyCraftsman.longitude = Number(value);
                      else
                        draftState.vehicleCraftsman.longitude = Number(value);
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            />
          }
        />
        <InfoDisplay
          title={t('networkPartners.telephoneCompany')}
          text={craftsman.company.phoneNumber}
          className="column is-full pb-1"
          editComponent={
            <EditTextModal
              isRequired={true}
              label={t('general.phone')}
              placeholder={t('general.phone')}
              value={craftsman.company.phoneNumber}
              requiredCapabilities="EditNetworkPartner"
              onSave={(value: string | undefined) => {
                if (value) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'propertyCraftsman') {
                        draftState.propertyCraftsman.company.phoneNumber =
                          value;
                      } else {
                        draftState.vehicleCraftsman.company.phoneNumber = value;
                      }
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
              onValidate={(value: string) => {
                const rawNumber = value.replaceAll(' ', '');
                return isValidPhoneNumber(rawNumber)
                  ? true
                  : t('validators.invalidPhoneNumber');
              }}
            />
          }
        />
        <InfoDisplay
          title={t('general.email')}
          text={craftsman.company.email}
          className="column is-full pb-1"
          editComponent={
            <EditTextModal
              isRequired={true}
              label={t('general.email')}
              placeholder={t('general.email')}
              value={craftsman.company.email}
              requiredCapabilities="EditNetworkPartner"
              onSave={(value: string | undefined) => {
                if (value) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'propertyCraftsman') {
                        draftState.propertyCraftsman.company.email = value;
                      } else {
                        draftState.vehicleCraftsman.company.email = value;
                      }
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
              onValidate={(value: string) => {
                if (BalValidators.isEmail()(value)) return true;
                else return t('validators.invalidEmail');
              }}
            />
          }
        />
        {craftsman.company.website && (
          <InfoDisplay
            title={t('networkPartners.website')}
            text={craftsman.company.website}
            className="column is-full pb-1"
            editComponent={
              <EditTextModal
                isRequired={true}
                label={t('networkPartners.website')}
                placeholder={t('networkPartners.website')}
                value={craftsman.company.website}
                requiredCapabilities="EditNetworkPartner"
                onSave={(value: string | undefined) => {
                  if (value) {
                    patchCraftsman(
                      token,
                      partner.localValue,
                      produce(partner.localValue, (draftState) => {
                        if (draftState.type === 'propertyCraftsman') {
                          draftState.propertyCraftsman.company.website = value;
                        } else {
                          draftState.vehicleCraftsman.company.website = value;
                        }
                      }),
                    ).then((result) => {
                      if (result.status === 'success') {
                        setPartner({ status: 'loading' });
                        balModalController.dismiss();
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    });
                  }
                }}
              />
            }
          />
        )}
        <NetworkMapSmall partner={partner} />
      </div>
    );
  } else {
    return <></>;
  }
};

const PartnerContactFields = ({
  partner,
  setPartner,
}: NetworkPartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const PartnerEditInfo = ({
    contact,
    newValue,
  }: {
    contact: PersonDto;
    newValue: (contact: PersonDto) => Craftsman;
  }) => {
    if (partner.status === 'success')
      return (
        <ContactDetailInformation
          hasGender
          hasPreferedContact
          hasLanguage
          hasAddress={false}
          contactPartner={{
            partner: { type: 'person', person: contact },
            role: 'None',
            id: '',
          }}
          editComponent={
            <WithCapabilities requiredCapabilities={['EditNetworkPartner']}>
              <CreateEditContactButton
                className="mt-2"
                edit
                addressOpen
                hasGender
                hasPreferedContact
                hasLanguage
                hasAddress={false}
                hasMobile
                hasFunction
                affectedPartnerToEdit={{
                  partner: { type: 'person', person: contact },
                  role: 'None',
                  id: '',
                }}
                onSave={(newContact) => {
                  if (newContact.type === 'person') {
                    patchCraftsman(
                      token,
                      partner.localValue,
                      newValue(newContact.person),
                    ).then((result) => {
                      if (result.status === 'success') {
                        setPartner({ status: 'loading' });
                        balModalController.dismiss();
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    });
                  }
                }}
                onDelete={(removedContact) => {
                  if (removedContact.type === 'person') {
                    patchCraftsman(
                      token,
                      partner.localValue,
                      produce(partner.localValue, (draftState) => {
                        if (draftState.type === 'propertyCraftsman') {
                          draftState.propertyCraftsman.contacts =
                            draftState.propertyCraftsman.contacts.filter(
                              (c) => c.id !== removedContact.person.id,
                            );
                        } else {
                          draftState.vehicleCraftsman.contacts =
                            draftState.vehicleCraftsman.contacts.filter(
                              (c) => c.id !== removedContact.person.id,
                            );
                        }
                      }),
                    ).then((result) => {
                      if (result.status === 'success') {
                        setPartner({ status: 'loading' });
                        balModalController.dismiss();
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    });
                  }
                }}
              />
            </WithCapabilities>
          }
        />
      );
    else return null;
  };
  if (partner.status === 'success') {
    const craftsman = partner.localValue;
    const primaryContact =
      craftsman.type === 'propertyCraftsman'
        ? craftsman.propertyCraftsman.contacts.find(
            (contact) =>
              contact.id === craftsman.propertyCraftsman.primaryContactId,
          )
        : craftsman.vehicleCraftsman.contacts.find(
            (contact) =>
              contact.id === craftsman.vehicleCraftsman.primaryContactId,
          );
    return (
      <>
        <div className="columns is-multiline is-gapless pr-6">
          <BalHeading
            className="column is-full"
            level="h4"
            color="info"
            subtitle
          >
            {t('networkPartners.primaryContact')}
            <EditPrimaryContactModal
              values={
                craftsman.type === 'propertyCraftsman'
                  ? craftsman.propertyCraftsman.contacts
                  : craftsman.vehicleCraftsman.contacts
              }
              value={primaryContact?.id ?? ''}
              requiredCapabilities="EditNetworkPartner"
              onSave={(value) => {
                if (value) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'propertyCraftsman') {
                        draftState.propertyCraftsman.primaryContactId = value;
                      } else {
                        draftState.vehicleCraftsman.primaryContactId = value;
                      }
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            />
          </BalHeading>
          {primaryContact && (
            <PartnerEditInfo
              contact={primaryContact}
              newValue={(contact: PersonDto) =>
                produce(partner.localValue, (draftState) => {
                  if (draftState.type === 'propertyCraftsman') {
                    const contactIndex =
                      draftState.propertyCraftsman.contacts.findIndex(
                        (partnerContact) => partnerContact.id === contact.id,
                      );
                    if (contactIndex >= 0) {
                      draftState.propertyCraftsman.contacts[contactIndex] =
                        contact;
                    }
                  } else {
                    const contactIndex =
                      draftState.vehicleCraftsman.contacts.findIndex(
                        (partnerContact) => partnerContact.id === contact.id,
                      );
                    if (contactIndex >= 0) {
                      draftState.vehicleCraftsman.contacts[contactIndex] =
                        contact;
                    }
                  }
                })
              }
            />
          )}
        </div>
        <BalHeading level="h4" space="top" color="info" subtitle>
          {t('networkPartners.furtherContacts')}
        </BalHeading>
        <WithCapabilities requiredCapabilities={['EditNetworkPartner']}>
          <CreateEditContactButton
            addressOpen
            hasGender
            hasPreferedContact
            hasLanguage
            hasFunction
            onSave={(newContact) => {
              if (newContact.type === 'person') {
                patchCraftsman(
                  token,
                  partner.localValue,
                  produce(partner.localValue, (draftState) => {
                    if (draftState.type === 'propertyCraftsman') {
                      draftState.propertyCraftsman.contacts =
                        draftState.propertyCraftsman.contacts.concat(
                          newContact.person,
                        );
                    } else {
                      draftState.vehicleCraftsman.contacts =
                        draftState.vehicleCraftsman.contacts.concat(
                          newContact.person,
                        );
                    }
                  }),
                ).then((result) => {
                  if (result.status === 'success') {
                    setPartner({ status: 'loading' });
                    balModalController.dismiss();
                  } else if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                });
              }
            }}
          />
        </WithCapabilities>
        {craftsman.type === 'propertyCraftsman'
          ? craftsman.propertyCraftsman.contacts
              .filter(
                (contact) =>
                  contact.id !== craftsman.propertyCraftsman.primaryContactId,
              )
              .map((contact: PersonDto) => (
                <div
                  className="columns is-multiline is-gapless pr-6"
                  key={contact.id}
                >
                  {contact && (
                    <PartnerEditInfo
                      contact={contact}
                      newValue={(contact: PersonDto) =>
                        produce(partner.localValue, (draftState) => {
                          if (draftState.type === 'propertyCraftsman') {
                            const contactIndex =
                              draftState.propertyCraftsman.contacts.findIndex(
                                (partnerContact) =>
                                  partnerContact.id === contact.id,
                              );
                            if (contactIndex >= 0) {
                              draftState.propertyCraftsman.contacts[
                                contactIndex
                              ] = contact;
                            }
                          } else {
                            const contactIndex =
                              draftState.vehicleCraftsman.contacts.findIndex(
                                (partnerContact) =>
                                  partnerContact.id === contact.id,
                              );
                            if (contactIndex >= 0) {
                              draftState.vehicleCraftsman.contacts[
                                contactIndex
                              ] = contact;
                            }
                          }
                        })
                      }
                    />
                  )}
                </div>
              ))
          : craftsman.vehicleCraftsman.contacts
              .filter(
                (contact) =>
                  contact.id !== craftsman.vehicleCraftsman.primaryContactId,
              )
              .map((contact: PersonDto) => (
                <div
                  className="columns is-multiline is-gapless pr-6"
                  key={contact.id}
                >
                  {contact && (
                    <PartnerEditInfo
                      contact={contact}
                      newValue={(contact: PersonDto) =>
                        produce(partner.localValue, (draftState) => {
                          if (draftState.type === 'propertyCraftsman') {
                            const contactIndex =
                              draftState.propertyCraftsman.contacts.findIndex(
                                (partnerContact) =>
                                  partnerContact.id === contact.id,
                              );
                            if (contactIndex >= 0) {
                              draftState.propertyCraftsman.contacts[
                                contactIndex
                              ] = contact;
                            }
                          } else {
                            const contactIndex =
                              draftState.vehicleCraftsman.contacts.findIndex(
                                (partnerContact) =>
                                  partnerContact.id === contact.id,
                              );
                            if (contactIndex >= 0) {
                              draftState.vehicleCraftsman.contacts[
                                contactIndex
                              ] = contact;
                            }
                          }
                        })
                      }
                    />
                  )}
                </div>
              ))}
      </>
    );
  } else {
    return <></>;
  }
};

const OperationalInformation = ({
  partner,
  setPartner,
}: NetworkPartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const workCategories = useWorkCategoriesFromApi();
  const regionsTranslatedSelections: TranslatedSelection[] =
    Globals.regions.map((region) => ({
      value: region,
      translatedValue: t(`region.${region}`),
    }));

  if (partner.status === 'success') {
    const isExternalPartner: boolean =
      partner.localValue.type === 'propertyCraftsman'
        ? partner.localValue.propertyCraftsman.isExternalPartner
        : partner.localValue.vehicleCraftsman.isExternalPartner;
    return (
      <div className="pr-6">
        <BalHeading level="h4" color="info" subtitle>
          {t('networkPartners.operationalInformation')}
        </BalHeading>
        {partner.localValue.type === 'propertyCraftsman' ? (
          <div className="columns is-multiline is-full-width is-gapless">
            {partner.localValue.propertyCraftsman.region && (
              <InfoDisplay
                title={t('networkPartners.region')}
                text={t(
                  `region.${partner.localValue.propertyCraftsman.region}`,
                )}
                className="column is-full pb-1"
                editComponent={
                  <EditSelectionModal
                    value={{
                      value: partner.localValue.propertyCraftsman.region,
                      translatedValue: t(
                        `region.${partner.localValue.propertyCraftsman.region}`,
                      ),
                    }}
                    values={regionsTranslatedSelections}
                    label={t('networkPartners.region')}
                    placeholder={t('networkPartners.region')}
                    requiredCapabilities="EditNetworkPartner"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchCraftsman(
                          token,
                          partner.localValue,
                          produce(partner.localValue, (draftState) => {
                            if (draftState.type === 'propertyCraftsman') {
                              draftState.propertyCraftsman.region =
                                value as Region;
                            }
                          }),
                        ).then((result) => {
                          if (result.status === 'success') {
                            setPartner({ status: 'loading' });
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                }
              />
            )}
            {partner.localValue.propertyCraftsman.workCategories && (
              <InfoDisplay
                title={t('workCategory.label')}
                text={concatCommaSpace(
                  partner.localValue.propertyCraftsman.workCategories.map(
                    (workCategory) =>
                      getLabelValueFromBkpNumber(workCategory.bkpNumber),
                  ),
                )}
                className="column is-full pb-1"
                editComponent={
                  <EditMultiSelectionModal
                    label={t('workCategory.label')}
                    value={partner.localValue.propertyCraftsman.workCategories.map(
                      (value) => value.bkpNumber,
                    )}
                    selectionValues={
                      workCategories.status === 'success'
                        ? workCategories.value.map(
                            (workCategory) => workCategory.bkpNumber,
                          )
                        : []
                    }
                    tFunction={(key: string) => {
                      return getLabelValueFromBkpNumber(key);
                    }}
                    placeholder={t('workCategory.label')}
                    requiredCapabilities={'EditNetworkPartner'}
                    onSave={(value: string[]) => {
                      if (value) {
                        patchCraftsman(
                          token,
                          partner.localValue,
                          produce(partner.localValue, (draftState) => {
                            (
                              draftState as PropertyCraftsman
                            ).propertyCraftsman.workCategories = (
                              value as string[]
                            ).map((workCategoryBkpNumber) => {
                              if (workCategories.status === 'success') {
                                return (
                                  workCategories.value.find(
                                    (workCategory: WorkCategoryDto) =>
                                      workCategoryBkpNumber ===
                                      workCategory.bkpNumber,
                                  ) ?? { id: '', bkpNumber: '' }
                                );
                              }
                              return { id: '', bkpNumber: '' };
                            });
                          }),
                        ).then((result) => {
                          if (result.status === 'success') {
                            setPartner({ status: 'loading' });
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                    valueFormatter={(key) => key}
                    keyFormatter={(key) => key}
                  />
                }
              />
            )}
            <InfoDisplay
              title={t('networkPartners.isIndependentPropertyCraftsman')}
              text={
                partner.localValue.propertyCraftsman
                  .isIndependentPropertyCraftsman
                  ? t('general.true')
                  : t('general.false')
              }
              className="column is-full pb-1"
              editComponent={
                <EditCheckboxModal
                  label={t('networkPartners.isIndependentPropertyCraftsman')}
                  text={t('networkPartners.isIndependentPropertyCraftsman')}
                  value={
                    partner.localValue.propertyCraftsman
                      .isIndependentPropertyCraftsman
                  }
                  requiredCapabilities={'EditNetworkPartner'}
                  onSave={(value) => {
                    if (value !== undefined) {
                      patchCraftsman(
                        token,
                        partner.localValue,
                        produce(partner.localValue, (draftState) => {
                          (
                            draftState as PropertyCraftsman
                          ).propertyCraftsman.isIndependentPropertyCraftsman =
                            value;
                        }),
                      ).then((result) => {
                        if (result.status === 'success') {
                          setPartner({ status: 'loading' });
                          balModalController.dismiss();
                        } else if (result.status === 'error') {
                          toast(ErrorToast(result.errorValue));
                        }
                      });
                    }
                  }}
                />
              }
            />
          </div>
        ) : (
          <div className="columns is-multiline is-full-width is-gapless">
            {partner.localValue.vehicleCraftsman.vehicleBrands && (
              <InfoDisplay
                title={t('networkPartners.carBrands')}
                text={concatCommaSpace(
                  partner.localValue.vehicleCraftsman.vehicleBrands,
                )}
                className="column is-full pb-1"
                editComponent={
                  <EditMultiSelectionModal
                    label={t('networkPartners.carBrands')}
                    value={partner.localValue.vehicleCraftsman.vehicleBrands}
                    selectionValues={Globals.vehicleBrands}
                    tFunction={(key: string) => key}
                    placeholder={t('networkPartners.carBrands')}
                    requiredCapabilities={'EditNetworkPartner'}
                    onSave={(value: string[]) => {
                      if (value) {
                        patchCraftsman(
                          token,
                          partner.localValue,
                          produce(partner.localValue, (draftState) => {
                            (
                              draftState as VehicleCraftsman
                            ).vehicleCraftsman.vehicleBrands =
                              value as VehicleBrandNames[];
                          }),
                        ).then((result) => {
                          if (result.status === 'success') {
                            setPartner({ status: 'loading' });
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                    valueFormatter={(key) => key}
                    keyFormatter={(key) => key}
                  />
                }
              />
            )}
            {partner.localValue.vehicleCraftsman.vehicleSpecialties && (
              <InfoDisplay
                title={t('networkPartners.specialtyCompany')}
                text={concatCommaSpace(
                  partner.localValue.vehicleCraftsman.vehicleSpecialties.map(
                    (key: VehicleSpecialtyNames) =>
                      t(`vehicleSpecialty.${key}`),
                  ),
                )}
                className="column is-full pb-1"
                editComponent={
                  <EditMultiSelectionModal
                    label={t('networkPartners.specialtyCompany')}
                    value={
                      partner.localValue.vehicleCraftsman.vehicleSpecialties
                    }
                    selectionValues={Globals.vehicleSpecialties}
                    valueFormatter={(key) => key}
                    tFunction={(key: VehicleSpecialtyNames) =>
                      t(`vehicleSpecialty.${key}`)
                    }
                    placeholder={t('networkPartners.specialtyCompany')}
                    requiredCapabilities={'EditNetworkPartner'}
                    onSave={(value: string[]) => {
                      if (value) {
                        patchCraftsman(
                          token,
                          partner.localValue,
                          produce(partner.localValue, (draftState) => {
                            (
                              draftState as VehicleCraftsman
                            ).vehicleCraftsman.vehicleSpecialties =
                              value as VehicleSpecialtyNames[];
                          }),
                        ).then((result) => {
                          if (result.status === 'success') {
                            setPartner({ status: 'loading' });
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                    keyFormatter={(key) => key}
                  />
                }
              />
            )}
            <InfoDisplay
              title={t('networkPartners.isIndependentVehicleCraftsman')}
              text={
                partner.localValue.vehicleCraftsman
                  .isIndependentVehicleCraftsman
                  ? t('general.true')
                  : t('general.false')
              }
              className="column is-full pb-1"
              editComponent={
                <EditCheckboxModal
                  label={t('networkPartners.isIndependentVehicleCraftsman')}
                  text={t('networkPartners.isIndependentVehicleCraftsman')}
                  value={
                    partner.localValue.vehicleCraftsman
                      .isIndependentVehicleCraftsman
                  }
                  requiredCapabilities={'EditNetworkPartner'}
                  onSave={(value) => {
                    if (value !== undefined) {
                      patchCraftsman(
                        token,
                        partner.localValue,
                        produce(partner.localValue, (draftState) => {
                          (
                            draftState as VehicleCraftsman
                          ).vehicleCraftsman.isIndependentVehicleCraftsman =
                            value;
                        }),
                      ).then((result) => {
                        if (result.status === 'success') {
                          setPartner({ status: 'loading' });
                          balModalController.dismiss();
                        } else if (result.status === 'error') {
                          toast(ErrorToast(result.errorValue));
                        }
                      });
                    }
                  }}
                />
              }
            />
            <InfoDisplay
              title={t('networkPartners.headquarters')}
              text={
                partner.localValue.vehicleCraftsman.headquarters
                  ? t('general.true')
                  : t('general.false')
              }
              className="column is-full pb-1"
              editComponent={
                <EditCheckboxModal
                  label={t('networkPartners.headquarters')}
                  text={t('networkPartners.headquarters')}
                  value={partner.localValue.vehicleCraftsman.headquarters}
                  requiredCapabilities={'EditNetworkPartner'}
                  onSave={(value) => {
                    if (value !== undefined) {
                      patchCraftsman(
                        token,
                        partner.localValue,
                        produce(partner.localValue, (draftState) => {
                          (
                            draftState as VehicleCraftsman
                          ).vehicleCraftsman.headquarters = value;
                        }),
                      ).then((result) => {
                        if (result.status === 'success') {
                          setPartner({ status: 'loading' });
                          balModalController.dismiss();
                        } else if (result.status === 'error') {
                          toast(ErrorToast(result.errorValue));
                        }
                      });
                    }
                  }}
                />
              }
            />
            <InfoDisplay
              title={t('networkPartners.onlyGlass')}
              text={
                partner.localValue.vehicleCraftsman.glassOnly
                  ? t('general.true')
                  : t('general.false')
              }
              className="column is-full pb-1"
              editComponent={
                <EditCheckboxModal
                  label={t('networkPartners.onlyGlass')}
                  text={t('networkPartners.onlyGlass')}
                  value={partner.localValue.vehicleCraftsman.glassOnly}
                  requiredCapabilities={'EditNetworkPartner'}
                  onSave={(value) => {
                    if (value !== undefined) {
                      patchCraftsman(
                        token,
                        partner.localValue,
                        produce(partner.localValue, (draftState) => {
                          (
                            draftState as VehicleCraftsman
                          ).vehicleCraftsman.glassOnly = value;
                        }),
                      ).then((result) => {
                        if (result.status === 'success') {
                          setPartner({ status: 'loading' });
                          balModalController.dismiss();
                        } else if (result.status === 'error') {
                          toast(ErrorToast(result.errorValue));
                        }
                      });
                    }
                  }}
                />
              }
            />
          </div>
        )}
        <InfoDisplay
          title={t('networkPartners.isExternalPartner')}
          text={isExternalPartner ? t('general.true') : t('general.false')}
          editComponent={
            <EditCheckboxModal
              label={t('networkPartners.isExternalPartner')}
              text={t('networkPartners.isExternalPartner')}
              value={isExternalPartner}
              requiredCapabilities={'EditNetworkPartner'}
              onSave={(value) => {
                if (value !== undefined) {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'vehicleCraftsman')
                        draftState.vehicleCraftsman.isExternalPartner = value;
                      else
                        draftState.propertyCraftsman.isExternalPartner = value;
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            />
          }
        />
      </div>
    );
  } else {
    return <></>;
  }
};
export const PartnerRemarks = ({
  partner,
  setPartner,
}: NetworkPartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const remarks =
    partner.status === 'success'
      ? partner.localValue.type === 'vehicleCraftsman'
        ? partner.localValue.vehicleCraftsman.remarks
        : partner.localValue.propertyCraftsman.remarks
      : undefined;
  return (
    <div className="columns is-multiline is-full-width is-gapless">
      {partner.status === 'success' && (
        <>
          <div className="is-flex is-align-items-center mb-2 mt-4">
            <BalHeading space="none" level="h4" color="info" subtitle>
              {t('general.claim.remarks')}
            </BalHeading>
            <div className="pl-6">
              <EditTextModal
                isRequired={false}
                label={t('general.claim.remarks')}
                placeholder={t('general.claim.remarks')}
                value={
                  partner.localValue.type === 'vehicleCraftsman'
                    ? partner.localValue.vehicleCraftsman.remarks
                    : partner.localValue.propertyCraftsman.remarks
                }
                requiredCapabilities="EditNetworkPartner"
                onSave={(value: string | undefined) => {
                  patchCraftsman(
                    token,
                    partner.localValue,
                    produce(partner.localValue, (draftState) => {
                      if (draftState.type === 'vehicleCraftsman')
                        draftState.vehicleCraftsman.remarks = value;
                      else draftState.propertyCraftsman.remarks = value;
                    }),
                  ).then((result) => {
                    if (result.status === 'success') {
                      setPartner({ status: 'loading' });
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }}
                textArea
              />
            </div>
          </div>
          <div className="column is-full mt-3 mb-1">
            {remarks && (
              <BalText className="enable-line-break">
                {partner.localValue.type === 'vehicleCraftsman'
                  ? partner.localValue.vehicleCraftsman.remarks
                  : partner.localValue.propertyCraftsman.remarks}
              </BalText>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export const PartnerDetail = ({
  partner,
  setPartner,
}: NetworkPartnerProps): JSX.Element => {
  return (
    <>
      <div className="columns is-multiline is-gapless scroll-bar-minimal">
        <div className="column is-gapless pr-6">
          <PartnerIdentification partner={partner} setPartner={setPartner} />
          <PartnerCompanyFields partner={partner} setPartner={setPartner} />
          <OperationalInformation partner={partner} setPartner={setPartner} />
        </div>
        <div className="column is-gapless pr-6">
          <PartnerContactFields partner={partner} setPartner={setPartner} />
          <PartnerRemarks partner={partner} setPartner={setPartner} />
        </div>
      </div>
    </>
  );
};
