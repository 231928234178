import { TFunction } from 'react-i18next';
import { RequestError } from '../../data/fetch/errors';
import i18n from '../../i18n/config';
import { BalToast } from '@baloise/design-system-components-react';
import { getCurrentVersion, getEnv } from '../ui/env-display';
const getErrorMessage = (error: RequestError, t: TFunction): string => {
  switch (error.errorType) {
    case 'internal-server-error':
      return t(`error.requestErrorType.${error.errorKey}`);
    case 'connection-error':
      return t('error.requestError.connectionError');
    case 'conversion-error':
      return error.errorMessage;
    case 'json-parsing-error':
      return t('error.requestError.jsonParsingError');
    case 'missing-body-error':
      return t('error.requestError.missingBodyError');
    case 'error-parsing-error':
      return t('error.requestError.errorParsingError');
    case 'unauthorised-error':
      return t('error.requestError.unauthorisedError');
    case 'bad-request-error':
      return t(`error.requestErrorType.${error.errorKey}`);
    case 'not-found-error':
      return t(`error.requestErrorType.${error.errorKey}`);
    case 'not-implemented-error':
      return t(`error.requestErrorType.${error.errorKey}`);
    case 'service-unavailable-error':
      return t('error.requestError.serviceUnavaiableError');
    case 'request-time-out-error':
      return t('error.requestError.requestTimeOutError');
    case 'bad-gateway-error':
      return t('error.requestError.badGatewayError');
  }
};
const getB3 = (error: RequestError): string | undefined => {
  console.log(error);
  switch (error.errorType) {
    case 'internal-server-error':
    case 'bad-request-error':
    case 'not-found-error':
    case 'not-implemented-error':
      return error.b3;
    default:
      return undefined;
  }
};

export const ErrorToast = (error: RequestError): JSX.Element => {
  const t = i18n.t;
  const message = getErrorMessage(error, t);
  const b3 = getB3(error);
  return (
    <div className="toast">
      {b3 ? (
        <BalToast color="danger" duration={60000}>
          <p>
            <b>{message}</b>
          </p>
          <p>
            <small>
              <b>{`${t('error.tracingInformation')}:`}</b> <br /> {b3} <br />
              {`${t('error.version')}: ${getCurrentVersion()}`} <br />
              {`${t('error.environment')}: ${getEnv()}`}
            </small>
          </p>
        </BalToast>
      ) : (
        <BalToast color="danger" duration={30000}>
          <p>
            <b>{message}</b>
          </p>
          <p>
            <small>
              <b>{`${t('error.tracingInformation')}:`}</b> <br />
              {`${t('error.version')}: ${getCurrentVersion()}`} <br />
              {`${t('error.environment')}: ${getEnv()}`}
            </small>
          </p>
        </BalToast>
      )}
    </div>
  );
};
