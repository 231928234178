import {
  BalCard,
  BalHeading,
  BalList,
  BalListItem,
  BalListItemContent,
  BalListItemTitle,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { CardModules, CardModulesProps } from '../components/ui';

const HomeView = ({
  appModuleVisability: appModuleVisability,
}: CardModulesProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="pl-6 mt-5 columns">
      <div className="column is-6">
        <div className="columns is-multiline">
          <CardModules appModuleVisability={appModuleVisability} />
        </div>
      </div>
      <div className="column is-4">
        <div className="columns is-multiline">
          <div className="column is-full">
            <BalCard className="p-5">
              <div className="columns is-gapless p-0">
                <div className="column">
                  <BalHeading className="p-0" space="none" level="h4">
                    {t('home.quickLinks.title')}
                  </BalHeading>
                </div>
              </div>
              <BalList className="mt-5 p-0" border>
                <BalListItem
                  clickable
                  href={window.extended.BAT_APP_REGION_ASSIGNMENT}
                  target="_blank"
                >
                  <BalListItemContent>
                    <BalListItemTitle>
                      {t('home.quickLinks.regionAssignment')}
                    </BalListItemTitle>
                  </BalListItemContent>
                </BalListItem>
                <BalListItem
                  clickable
                  href={window.extended.BAT_APP_VEHICLE_EXPERTS_INTERNAL}
                  target="_blank"
                >
                  <BalListItemContent>
                    <BalListItemTitle>
                      {t('home.quickLinks.internalVehicleExperts')}
                    </BalListItemTitle>
                  </BalListItemContent>
                </BalListItem>
                <BalListItem
                  clickable
                  href={window.extended.BAT_APP_VEHICLE_EXPERTS_EXTERNAL}
                  target="_blank"
                >
                  <BalListItemContent>
                    <BalListItemTitle>
                      {t('home.quickLinks.externalVehicleExperts')}
                    </BalListItemTitle>
                  </BalListItemContent>
                </BalListItem>
              </BalList>
            </BalCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeView;
