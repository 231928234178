import { BalCheckbox, BalText } from '@baloise/design-system-components-react';
import { AffectedPartner } from '../../../../types/types';
import { getPartnerName } from '../../../../utils';
import { useTranslation } from 'react-i18next';

type EditMeasureContactProps = {
  value: boolean;
  setValue: (value: boolean) => void;
  displayText?: string;
  bold?: boolean;
  affectedPartner?: AffectedPartner;
};

export const EditMeasureContact = ({
  value,
  setValue,
  displayText,
  bold,
  affectedPartner,
}: EditMeasureContactProps): JSX.Element => {
  const { t } = useTranslation();
  const getAddress = (affectedPartner: AffectedPartner) => {
    return affectedPartner.partner.type === 'company'
      ? `${affectedPartner.partner.company.address?.addressStreetString}, ${affectedPartner.partner.company.address?.zipCode} ${affectedPartner.partner.company.address?.city}`
      : `${affectedPartner.partner.person.address?.addressStreetString}, ${affectedPartner.partner.person.address?.zipCode} ${affectedPartner.partner.person.address?.city}`;
  };
  return (
    <div className="columns column is-full bottom-blue-line">
      <BalCheckbox
        className="column is-1 is-flex is-flex-direction-column"
        checked={value}
        onBalChange={(e) => {
          if (e.detail != value) {
            setValue(e.detail);
          }
        }}
      ></BalCheckbox>
      {affectedPartner && (
        <div className="columns is-multiline column is-11">
          <BalText
            bold={bold}
            color="black"
            className="column is-full m-0 pb-0 pl-4"
          >
            {t(`partnerRole.${affectedPartner.role ?? ''}`)}
          </BalText>
          <BalText color="black" className="column is-full m-0 p-0 pl-4">
            {getPartnerName(affectedPartner.partner)}
          </BalText>
          <BalText color="black" className="column is-full m-0 p-0 pb-4 pl-4">
            {getAddress(affectedPartner)}
          </BalText>
        </div>
      )}
      {!affectedPartner && displayText && (
        <BalText
          bold={bold}
          color="black"
          className="column is-full m-0 pb-0 is-11 pl-4"
        >
          {displayText}
        </BalText>
      )}
    </div>
  );
};
