import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalIcon,
  BalListItem,
  BalListItemContent,
  BalListItemIcon,
  BalListItemTitle,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AppCapabilityNames } from '../../types/resource-models';
import { WithCapabilities } from '../authorization/with-capabilities';

type EditTextModalProps = {
  buttonText: string;
  title: string;
  subTitle: string;
  text: string;
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  listItem?: boolean;
};

const ShowTextModalContent = (props: EditTextModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading space="none" level="h3">
          {props.title}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalHeading space="none" subtitle level="h4">
          {props.subTitle}
        </BalHeading>
        <div
          className="flex is-flex-direction-column is-full-width mt-5"
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        ></div>
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.back')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export const ShowTextModal = (props: EditTextModalProps): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: ShowTextModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      modalWidth: 750,
    });
    return modal.present();
  };

  return props.listItem ? (
    <BalListItem clickable onClick={() => openModal()}>
      <BalListItemIcon className="min-height-0px">
        <BalIcon name="document" size="small" />
      </BalListItemIcon>
      <BalListItemContent>
        <BalListItemTitle>{props.buttonText}</BalListItemTitle>
      </BalListItemContent>
    </BalListItem>
  ) : (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        icon="document"
        onClick={(event) => event.detail == 1 && openModal()}
      >
        {props.buttonText}
      </BalButton>
    </WithCapabilities>
  );
};
