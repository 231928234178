import { APIRoutes, PropertiesToString } from '../../../router/router';
import { apiUrl } from '../../../data/fetch/base';
import { jsonGet, jsonReq, RequestResult } from '../../../data';
import {
  BaseFilterCriteria,
  BaseTaskChangeDto,
  BaseTaskFilterCriteria,
  BaseTaskPostDto,
  ExpenseDto,
  InvestigationTaskFilterCriteriaDto,
  InvestigationTaskForNonExpertsFilterCriteriaDto,
  ResponseOfInvestigationTaskForNonExpertsDto,
} from '../../../types/resource-models';
import {
  InvestigationTask,
  InvestigationTasksResponse,
  TaskTypes,
} from '../../../types/types';
import { diff, jsonPatchPathConverter } from 'just-diff';
import { makeAuthHeaders, typeFromApi } from '../../../data/fetch/requests';

export const postInvestigationTask = async (
  investigationTaskPost: BaseTaskPostDto,
  bearerToken: string,
): Promise<RequestResult<InvestigationTask>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.POST_INVESTIGATION_TASK.create({})}`,
    {
      method: 'POST',
      headers: await makeAuthHeaders(bearerToken),
      body: JSON.stringify(investigationTaskPost),
    },
  );
  return jsonReq<InvestigationTask>(request, typeFromApi);
};

export const patchInvestigationTask = async (
  investigationTask: InvestigationTask,
  investigationTaskNew: InvestigationTask,
  bearerToken: string,
  updateToAdos?: boolean,
): Promise<RequestResult<InvestigationTask>> => {
  const patchOperations = diff(
    investigationTask,
    investigationTaskNew,
    jsonPatchPathConverter,
  );

  if (updateToAdos) {
    patchOperations.push({
      op: 'add',
      path: 'updateToAdos',
      value: undefined,
    });
  }
  const patch = JSON.stringify(patchOperations);
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_INVESTIGATION_TASK.create({
      investigationTaskId: investigationTask.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<InvestigationTask>(request, typeFromApi);
};

export const getInvestigationTasks = async (
  bearerToken: string,
  filter: BaseTaskFilterCriteria,
): Promise<RequestResult<InvestigationTasksResponse>> => {
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_INVESTIGATION_TASKS.create({
      query: filter as Partial<
        PropertiesToString<InvestigationTaskFilterCriteriaDto> &
          PropertiesToString<BaseFilterCriteria>
      >,
    })}`,
    bearerToken,
  );
};

export const getNonExpertInvestigationTasks = async (
  bearerToken: string,
  filter: InvestigationTaskForNonExpertsFilterCriteriaDto,
): Promise<RequestResult<ResponseOfInvestigationTaskForNonExpertsDto>> => {
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_NON_EXPERTS_INVESTIGATION_TASKS.create({
      query: filter as Partial<
        PropertiesToString<InvestigationTaskForNonExpertsFilterCriteriaDto> &
          PropertiesToString<BaseFilterCriteria>
      >,
    })}`,
    bearerToken,
  );
};

export const getInvestigationTask = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<InvestigationTask>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_INVESTIGATION_TASK.create({
      investigationTaskId: taskId,
    })}`,
    bearerToken,
    (result) => (result.type = TaskTypes.InvestigationTask),
  );

export const getInvestigationTaskAllHistory = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<BaseTaskChangeDto[]>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_INVESTIGATION_TASK_ALL_HISTORY.create({
      taskId: taskId,
    })}`,
    bearerToken,
  );

export const deleteExpense = async (
  bearerToken: string,
  taskId: string,
  expenseId: string,
): Promise<RequestResult<void>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.DELETE_INVESTIGATION_TASK_EXPENSE.create({
      taskId: taskId,
      expenseId: expenseId,
    })}`,
    {
      method: 'DELETE',
      headers: await makeAuthHeaders(bearerToken),
    },
  );
  return jsonReq(request, typeFromApi);
};

export const putExpense = async (
  bearerToken: string,
  taskId: string,
  expense: ExpenseDto,
): Promise<RequestResult<InvestigationTask>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.UPDATE_INVESTIGATION_TASK_EXPENSE.create({
      taskId: taskId,
    })}`,
    {
      method: 'PUT',
      headers: await makeAuthHeaders(bearerToken),
      body: JSON.stringify(expense),
    },
  );
  return jsonReq(request, typeFromApi);
};
