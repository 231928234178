import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
  BalTextarea,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useForm,
  ValidationRule,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import lang from 'suneditor-react/dist/types/lang';
import { balModalController } from '../../controller/controllers';
import { RequestResult } from '../../data';
import { AppCapabilityNames, UserDto } from '../../types/resource-models';
import { WithCapabilities } from '../authorization/with-capabilities';
import { BalTextField } from './bal-text-field';
import TextEditor from './text-editor';

export type EditTextModalProps = {
  label: string;
  placeholder: string;
  value?: string;
  className?: string;
  buttonClassName?: string;
  requiredCapabilities?: AppCapabilityNames;
  isRequired: boolean;
  onSave(value: string | undefined, reportToAdos: boolean): void;
  showSaveAndSendButton?: boolean;
  onValidate?: (value: string) => string | true;
  textArea?: boolean;
  richText?: boolean;
  formatBeforeEdit?: (value: string) => string;
  formatOnEdit?: (value: string) => string;
  pattern?: ValidationRule<RegExp>;
  user?: RequestResult<UserDto>;
  maxLength?: number;
  notOutlined?: boolean;
};

export const EditTextModalContent = (
  props: EditTextModalProps,
): JSX.Element => {
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState<string | undefined>(
    props.formatBeforeEdit && props.value
      ? props.formatBeforeEdit(props.value)
      : props.value,
  );
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const { control } = useForm({
    mode: 'onChange',
  });
  const onChange = (
    event: CustomEvent<string | undefined>,
    field: ControllerRenderProps<FieldValues, 'editTextController'>,
  ) => {
    let value = event.detail;
    if (!props.isRequired || (value && value.length > 0)) {
      setRequiredError(false);
    } else {
      setRequiredError(true);
    }
    value = value ?? '';
    if (props.formatOnEdit) {
      value = props.formatOnEdit(value);
    }
    setTextValue(value);
    field.onChange(value);
  };

  const [language, setLanguage] = useState<lang>('de');
  useEffect(() => {
    if (props.user && props.user.status === 'success') {
      setLanguage(
        (props.user.localValue.language.toLowerCase() as lang) ?? 'de',
      );
    }
  }, [props.user]);

  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {props.label}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <Controller
          name="editTextController"
          control={control}
          defaultValue={textValue}
          rules={{
            validate: props.onValidate,
            pattern: props.pattern,
          }}
          render={({ field, fieldState }) => (
            <>
              {/* RichText editor */}
              {props.textArea && props.richText && (
                <TextEditor
                  {...field}
                  placeholder={
                    props.isRequired
                      ? `${props.placeholder} *`
                      : props.placeholder
                  }
                  value={textValue}
                  language={language}
                  onChange={(text: string) => {
                    if (!props.isRequired || (text && text.length > 0)) {
                      setRequiredError(false);
                    } else {
                      setRequiredError(true);
                    }

                    setTextValue(text);
                  }}
                />
              )}
              {/* Text area without rich text */}
              {props.textArea && !props.richText && (
                <BalTextarea
                  {...field}
                  placeholder={
                    props.isRequired
                      ? `${props.placeholder} *`
                      : props.placeholder
                  }
                  value={textValue}
                  onBalChange={(event: CustomEvent<string | undefined>) => {
                    onChange(event, field);
                  }}
                  maxLength={props.maxLength}
                />
              )}
              {/* Single line text */}
              {!props.textArea && (
                <BalTextField
                  {...field}
                  placeholder={
                    props.isRequired
                      ? `${props.placeholder} *`
                      : props.placeholder
                  }
                  value={textValue}
                  onChange={(event: CustomEvent<string | undefined>) => {
                    onChange(event, field);
                  }}
                  message={fieldState.error && fieldState.error.message}
                  maxLength={props.maxLength}
                />
              )}
              {requiredError && (
                <BalText className="mt-2" color="danger" size="small" bold>
                  {t('error.mandatoryField')}
                </BalText>
              )}
              <BalButtonGroup position="right">
                <BalButton
                  elementType="button"
                  color="primary-light"
                  onClick={(event) =>
                    event.detail == 1 && balModalController.dismiss()
                  }
                >
                  {t('general.buttons.cancel')}
                </BalButton>
                <BalButton
                  color="info"
                  disabled={fieldState.error !== undefined || requiredError}
                  onClick={(event) => {
                    if (textValue == props.value && event.detail == 1) {
                      balModalController.dismiss();
                    } else if (!requiredError && event.detail == 1) {
                      props.onSave(textValue, false);
                    }
                  }}
                >
                  {t('general.buttons.save')}
                </BalButton>
                {props.showSaveAndSendButton && (
                  <BalButton
                    color="info"
                    disabled={fieldState.error !== undefined || requiredError}
                    onClick={(event) => {
                      if (textValue == props.value && event.detail == 1) {
                        balModalController.dismiss();
                      } else if (!requiredError && event.detail == 1) {
                        balModalController.dismiss();
                        props.onSave(textValue, true);
                      }
                    }}
                  >
                    {t('general.buttons.saveAndSend')}
                  </BalButton>
                )}
              </BalButtonGroup>
            </>
          )}
        />
      </BalModalBody>
    </div>
  );
};

export const EditTextModal = (props: EditTextModalProps): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditTextModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      modalWidth: 750,
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <>
      {props.requiredCapabilities ? (
        <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
          <BalButton
            className={props.buttonClassName}
            square
            outlined={!props.notOutlined}
            size="small"
            color={props.notOutlined ? 'primary' : 'primary-light'}
            icon="edit"
            onClick={(event) => event.detail == 1 && openModal()}
          />
        </WithCapabilities>
      ) : (
        <BalButton
          className={props.className}
          square
          outlined={!props.notOutlined}
          size="small"
          color={props.notOutlined ? 'primary' : 'primary-light'}
          icon="edit"
          onClick={(event) => event.detail == 1 && openModal()}
        />
      )}
    </>
  );
};
