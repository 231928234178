import {
  BalButton,
  BalButtonGroup,
  BalCheckbox,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import BalSelectFormField from '../../../components/input/bal-select-form-field';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import {
  AttachmentDto,
  InvestigationTaskJournalDto,
  InvestigationTaskJournalListDto,
  InvestigationTaskStatus,
  JournalAssessmentType,
  JournalType,
} from '../../../types/resource-models';
import { AttachmenEntity, TranslatedSelection } from '../../../types/types';
import { BaseTaskAttachments } from '../../base-task';
import { deleteFile, FileUpload } from '../../files';
import {
  patchInvestigationTaskJournal,
  postInvestigationTaskJournal,
  sendJournalToAdos,
} from '../data/requests';
import { checkInvestigationJournalType, Globals } from '../../../utils';
import {
  JournalInsuranceSurveyType,
  JournalResearchType,
} from '../../../../../bat-shared/resource-models';
import lang from 'suneditor-react/dist/types/lang';
import {
  BalDatepickerFormField,
  BalTextField,
  BalTextareaFormField,
} from '../../../components/input';
import JournalDeleteButton from './toolbar/journal-delete-button';

import { CancelButton, FileViewModal } from '../../../components/ui';
import { getInvestigationJournalReport } from '../../files/data/requests';

export interface JournalEditProps {
  initialValue: RequestResult<InvestigationTaskJournalDto>;
  initialAddingState: boolean;
  language?: lang;
  readonly?: boolean;
  onSave?: (journalId?: string) => void;
  onDelete: (journal: InvestigationTaskJournalListDto) => Promise<void>;
}
const JournalEditForm = ({
  initialValue,
  initialAddingState,
  language,
  readonly,
  onSave,
  onDelete,
}: JournalEditProps): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [sendingToAdos, setSendingToAdos] = useState<boolean>(false);
  const [journal, setJournal] =
    useState<RequestResult<InvestigationTaskJournalDto>>(initialValue);

  const [note, setNote] = useState<string | undefined>(
    initialValue?.status === 'success'
      ? initialValue.localValue.note
      : undefined,
  );

  const [addingState, setAddingState] = useState<boolean>(initialAddingState);
  const journalTypes: TranslatedSelection[] = Globals.JournalTypes.map(
    (journalType) => ({
      value: journalType,
      translatedValue: t(`journal.journalTypes.${journalType}`),
    }),
  );

  const investigationTaskStatuses: TranslatedSelection[] =
    Globals.InvestigationTaskStatuses.map((journalStatus) => ({
      value: journalStatus,
      translatedValue: t(`baseTaskStatus.${journalStatus}`),
    }));

  const journalAssessmentTypes: TranslatedSelection[] =
    Globals.JournalAssessmentTypes.map((journalAssessmentType) => ({
      value: journalAssessmentType,
      translatedValue: t(
        `journal.journalAssessmentTypes.${journalAssessmentType}`,
      ),
    }));

  const journalInsuranceSurveyTypes: TranslatedSelection[] =
    Globals.JournalInsuranceSurveyTypes.map((journalInsuranceSurveyType) => ({
      value: journalInsuranceSurveyType,
      translatedValue: t(
        `journal.journalInsuranceSurveyTypes.${journalInsuranceSurveyType}`,
      ),
    }));

  const journalResearchTypes: TranslatedSelection[] =
    Globals.JournalResearchTypes.map((journalResearchType) => ({
      value: journalResearchType,
      translatedValue: t(`journal.journalResearchTypes.${journalResearchType}`),
    }));

  const token = useToken();
  const removeFile = async (fileName: string) => {
    if (journal.status == 'success' && journal.localValue.attachments) {
      const attachmentToDelete = journal.localValue.attachments.find(
        (attachment: AttachmentDto) => attachment.fileName == fileName,
      );
      if (attachmentToDelete) {
        const result = await deleteFile(token, attachmentToDelete);
        if (
          result.status === 'success-no-value' ||
          result.status === 'success'
        ) {
          const newAttachments = journal.localValue.attachments.filter(
            (attachment: AttachmentDto) => attachment.fileName !== fileName,
          );
          setJournal(
            produce(journal, (draftState) => {
              draftState.localValue.attachments = newAttachments;
            }),
          );
        }
        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      }
    }
  };

  const postJournal = async () => {
    if (journal.status === 'success') {
      const response = await postInvestigationTaskJournal(
        journal.localValue,
        token,
      );
      if (response.status === 'error') {
        toast(ErrorToast(response.errorValue));
      } else {
        setJournal(response);
        setAddingState(false);
      }
    }
  };

  const patchJournal = async () => {
    if (journal.status === 'success') {
      const response = await patchInvestigationTaskJournal(
        journal.value,
        journal.localValue,
        token,
      );
      if (response.status === 'error') {
        toast(ErrorToast(response.errorValue));
      }
    }
  };

  useEffect(() => {
    if (journal.status === 'success' && journal.localValue.note !== note) {
      setJournal(
        produce(journal, (draft) => {
          draft.localValue.note = note;
        }),
      );
    }
  }, [journal, note]);

  const save = async () => {
    if (addingState) {
      await postJournal();
    } else {
      await patchJournal();
    }
  };

  const isInvalidToSave = (journal: InvestigationTaskJournalDto) => {
    return (
      readonly ||
      !methods.formState.isValid ||
      !checkInvestigationJournalType(journal) ||
      saving
    );
  };

  return (
    <>
      {journal.status === 'success' && (
        <form
          onSubmit={methods.handleSubmit(async () => {
            if (!saving) {
              setSaving(true);
              await save();
              if (onSave) {
                onSave(journal.localValue?.id);
              }
              setSaving(false);
            }
          })}
        >
          <div className="rows is-multiline is-gapless is-full-width mt-1">
            <div className="columns">
              <BalCheckbox
                disabled={readonly}
                className="column is-6 pt-1 pb-4"
                interface="switch"
                checked={journal.localValue.confidential}
                onBalChange={(e) =>
                  setJournal(
                    produce(journal, (draftState) => {
                      draftState.localValue.confidential = e.detail;
                    }),
                  )
                }
              >
                {t('journal.confidential')}
              </BalCheckbox>
              <div className="column is-3 is-full-width">
                <div
                  data-tooltip={t('journal.previewJournalEntryTooltip')}
                  data-tooltip-location="bottom"
                  key="previewJournalEntry"
                  className="tooltip"
                >
                  <FileViewModal
                    notOutlined={true}
                    disabled={isInvalidToSave(journal.localValue)}
                    label={`${t(
                      'journal.entityName',
                    )}_${journal.localValue.createdAt
                      .toLocaleUpperCase()
                      .slice(0, 10)}.pdf`}
                    buttonText={t('journal.showJournalEntry')}
                    fileId={journal.localValue.id}
                    contentType="application/pdf"
                    getFile={async () => {
                      await save();
                      return getInvestigationJournalReport(
                        token,
                        journal.localValue.id,
                      );
                    }}
                    report
                  />
                </div>
              </div>
              <div className="column is-3">
                <div
                  data-tooltip={
                    isInvalidToSave(journal.localValue) ||
                    journal.localValue.confidential
                      ? t('journal.sendJournalEntryToAdosTooltipDeactivated')
                      : t('journal.sendJournalEntryToAdosTooltipActivated')
                  }
                  data-tooltip-location="bottom"
                  key="sendJournalEntryToAdos"
                  className="tooltip"
                >
                  <BalButton
                    className="is-full-width"
                    size="small"
                    color="info"
                    disabled={
                      isInvalidToSave(journal.localValue) ||
                      journal.localValue.confidential
                    }
                    loading={sendingToAdos}
                    onClick={async (event) => {
                      if (event.detail == 1) {
                        setSendingToAdos(true);
                        await save();
                        sendJournalToAdos(token, journal.localValue.id).then(
                          (result) => {
                            if (result.status === 'success') {
                              toast(
                                ToastNotification({
                                  message: t(
                                    'journal.sentJournalEntryToAdosSuccessfully',
                                  ),
                                  color: 'success',
                                }),
                              );
                            } else if (result.status === 'error') {
                              toast(ErrorToast(result.errorValue));
                            }
                            setSendingToAdos(false);
                          },
                        );
                      }
                    }}
                  >
                    {t('journal.sendJournalEntryToAdos')}
                  </BalButton>
                </div>
              </div>
            </div>
            <BalDatepickerFormField
              disabled={readonly}
              title={t('expense.createdAt')}
              className="mb-4"
              controllerName={'createdAt'}
              methods={methods}
              placeholder={t('expense.createdAt')}
              value={journal.localValue.createdAt.slice(0, 10)}
              onBalChange={(e: CustomEvent<null | string | undefined>) => {
                setJournal(
                  produce(journal, (draftState) => {
                    draftState.localValue.createdAt = e.detail ?? '';
                  }),
                );
              }}
            />
            <BalSelectFormField
              disabled={readonly}
              controllerName={'journalType'}
              methods={methods}
              className="row"
              value={journal.localValue.journalType}
              values={journalTypes}
              title={t('journal.type')}
              placeholder={t('general.buttons.select')}
              alphabeticallySorted
              onChange={(e) => {
                setJournal(
                  produce(journal, (draftState) => {
                    if (e && e.detail) {
                      draftState.localValue.journalType =
                        e.detail as JournalType;
                      if (draftState.localValue.journalType != 'Assessment') {
                        draftState.localValue.journalAssessmentType = undefined;
                      }
                      if (
                        draftState.localValue.journalType != 'InsuranceSurvey'
                      ) {
                        draftState.localValue.journalInsuranceSurveyType =
                          undefined;
                      }
                      if (draftState.localValue.journalType != 'Research') {
                        draftState.localValue.journalResearchType = undefined;
                      }
                    }
                  }),
                );
              }}
            />
            {journal.localValue.journalType === 'Assessment' && (
              <BalSelectFormField
                disabled={readonly}
                controllerName={'journalAssessmentType'}
                methods={methods}
                className="row"
                value={journal.localValue.journalAssessmentType}
                values={journalAssessmentTypes}
                title={t('journal.journalTypes.Assessment')}
                placeholder={t('general.buttons.select')}
                required={t('error.mandatoryField')}
                onChange={(e) => {
                  setJournal(
                    produce(journal, (draftState) => {
                      if (e && e.detail) {
                        draftState.localValue.journalAssessmentType =
                          e.detail as JournalAssessmentType;
                      }
                    }),
                  );
                }}
              />
            )}
            {journal.localValue.journalType === 'InsuranceSurvey' && (
              <BalSelectFormField
                disabled={readonly}
                controllerName={'journalInsuranceSurveyType'}
                methods={methods}
                className="row"
                value={journal.localValue.journalInsuranceSurveyType}
                values={journalInsuranceSurveyTypes}
                title={t('journal.journalTypes.InsuranceSurvey')}
                placeholder={t('general.buttons.select')}
                required={t('error.mandatoryField')}
                onChange={(e) => {
                  if (e && e.detail) {
                    setJournal(
                      produce(journal, (draftState) => {
                        if (e && e.detail) {
                          draftState.localValue.journalInsuranceSurveyType =
                            e.detail as JournalInsuranceSurveyType;
                        }
                      }),
                    );
                  }
                }}
              />
            )}
            {journal.localValue.journalType === 'Research' && (
              <BalSelectFormField
                disabled={readonly}
                controllerName={'journalResearchType'}
                methods={methods}
                className="row"
                value={journal.localValue.journalResearchType}
                values={journalResearchTypes}
                title={t('journal.journalTypes.Research')}
                placeholder={t('general.buttons.select')}
                required={t('error.mandatoryField')}
                onChange={(e) => {
                  if (e && e.detail) {
                    setJournal(
                      produce(journal, (draftState) => {
                        draftState.localValue.journalResearchType =
                          e.detail as JournalResearchType;
                      }),
                    );
                  }
                }}
              />
            )}
            {(journal.localValue.journalType === 'Email' ||
              journal.localValue.journalType === 'Meeting' ||
              journal.localValue.journalType === 'Phone') && (
              <BalTextField
                title={t('journal.contactPersonName')}
                value={journal.localValue.contactPersonName}
                onChange={(e) => {
                  if (e && e.detail) {
                    setJournal(
                      produce(journal, (draftState) => {
                        draftState.localValue.contactPersonName =
                          e.detail as JournalResearchType;
                      }),
                    );
                  }
                }}
              ></BalTextField>
            )}
            <BalSelectFormField
              disabled={readonly}
              controllerName={'status'}
              methods={methods}
              className="row"
              value={journal.localValue.status}
              values={investigationTaskStatuses}
              title={t('journal.status')}
              placeholder={t('general.buttons.select')}
              onChange={(e) => {
                if (e && e.detail) {
                  setJournal(
                    produce(journal, (draftState) => {
                      draftState.localValue.status =
                        e.detail as InvestigationTaskStatus;
                    }),
                  );
                }
              }}
            />
            <div className="columns pb-4">
              <div className="column is-8">
                <BalTextareaFormField
                  disabled={readonly}
                  controllerName={'note'}
                  methods={methods}
                  richText
                  richTextHeight="500px"
                  language={language}
                  placeholder={t('journal.note')}
                  title={t('journal.note')}
                  value={note}
                  onChange={(text: string) => {
                    setNote(text);
                  }}
                />
              </div>
              <div className="column is-4">
                <div className="scroll-bar-minimal">
                  <BaseTaskAttachments
                    disabled={readonly}
                    maximumHeight="370px"
                    attachments={journal.localValue.attachments}
                    removeFile={removeFile}
                  />
                </div>
                <div>
                  <FileUpload
                    disabled={readonly || !methods.formState.isValid || saving}
                    entityType="investigationTaskJournal"
                    className="pb-0"
                    entity={journal as RequestResult<AttachmenEntity>}
                    setEntity={
                      setJournal as Dispatch<RequestResult<AttachmenEntity>>
                    }
                    hasFileList={false}
                    copyFromClipboard={true}
                    beforePostFile={async () => {
                      if (addingState) {
                        await postJournal();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <BalButtonGroup position="right">
              {!initialAddingState && (
                <JournalDeleteButton
                  currentJournal={
                    initialValue.status === 'success'
                      ? initialValue.value
                      : undefined
                  }
                  onDeleteJournal={onDelete}
                  textButton
                />
              )}
              <CancelButton />
              <BalButton
                elementType="submit"
                color="info"
                disabled={isInvalidToSave(journal.localValue)}
              >
                {t('general.buttons.save')}
              </BalButton>
            </BalButtonGroup>
          </div>
        </form>
      )}
    </>
  );
};

export default JournalEditForm;
