import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { DiffMethod, StringDiff } from 'react-string-diff';
import { balModalController } from '../../controller/controllers';
import { EntityChange } from '../../types/types';
import { concat } from '../../utils';
import { InfoDisplay } from '../ui';

type TextProtocolModalProps = {
  label: string;
  changes: EntityChange[];
  className?: string;
};

const TextProtocolModalContent = (
  props: TextProtocolModalProps,
): JSX.Element => {
  const { t } = useTranslation();
  const formattedChangeDateTime = new Date(
    props.changes[0]?.dateTime,
  ).toLocaleString();

  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading space="none" level="h3" subtitle>
          {props.label}
        </BalHeading>
      </BalModalHeader>

      <BalModalBody>
        <div
          className={concat([
            'columns is-multiline is-gapless',
            props.className,
          ])}
        >
          <div className="column is-full">
            <InfoDisplay
              title={t('claimDetail.date')}
              text={formattedChangeDateTime}
              className="pb-1"
            />
          </div>
          <div className="column is-full">
            <InfoDisplay
              title={t('taskHistory.ChangedBy')}
              text={props.changes[0]?.userName}
              className="pb-1"
            />
          </div>
          {props.changes.map((change, idx) => {
            return (
              <div className="column is-full" key={idx}>
                <div className="mt-3 columns mb-0 is-gapless bottom-blue-line'">
                  <BalText bold className={'column is-5'} color="info">
                    {change.propertyName ?? ''}
                  </BalText>
                </div>
                <div className="mt-0 columns is-gapless bottom-blue-line'">
                  <StringDiff
                    className="column is-9 mb-0 enable-line-break"
                    method={DiffMethod.Chars}
                    oldValue={change.oldValue ?? ''}
                    newValue={change.newValue ?? ''}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.back')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

const TextProtocolModal = async (
  props: TextProtocolModalProps,
): Promise<void> => {
  const modal = await balModalController.create({
    component: TextProtocolModalContent,
    componentProps: props,
    cssClass: 'center-modal',
  });
  return modal.present();
};

export default TextProtocolModal;
