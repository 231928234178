import { Marker, Popup } from 'react-leaflet';
import { BalHeading, BalText } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { concat, concatAddress } from '../../../utils';
import { Craftsman } from '../../../types/types';
import { RequestResult } from '../../../data';
import MarkerClusterGroup from 'react-leaflet-cluster';

export const MarkerClusterGroups = ({
  partners,
  setPartner,
}: {
  partners: Craftsman[];
  setPartner: React.Dispatch<React.SetStateAction<RequestResult<Craftsman>>>;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <MarkerClusterGroup
      zoomToBoundsOnClick
      showCoverageOnHover={false}
      polygonOptions={{
        weight: 1,
      }}
    >
      {partners.map((partner) => {
        const id =
          partner.type === 'propertyCraftsman'
            ? partner.propertyCraftsman.id
            : partner.vehicleCraftsman.id;
        const company =
          partner.type === 'propertyCraftsman'
            ? partner.propertyCraftsman.company
            : partner.vehicleCraftsman.company;

        const primaryContact =
          partner.type === 'propertyCraftsman'
            ? partner.propertyCraftsman.contacts.find(
                (contact) =>
                  contact.id === partner.propertyCraftsman.primaryContactId,
              )
            : partner.vehicleCraftsman.contacts.find(
                (contact) =>
                  contact.id === partner.vehicleCraftsman.primaryContactId,
              );

        return (
          <Marker
            key={id}
            position={
              partner.type === 'propertyCraftsman'
                ? [
                    partner.propertyCraftsman.latitude,
                    partner.propertyCraftsman.longitude,
                  ]
                : [
                    partner.vehicleCraftsman.latitude,
                    partner.vehicleCraftsman.longitude,
                  ]
            }
            eventHandlers={{
              mousedown: () => {
                setPartner({
                  status: 'success',
                  localValue: partner,
                  value: partner,
                });
              },
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            <Popup closeButton={false}>
              <div className="columns mx-6 my-5 is-gapless is-multiline">
                <div className="column is-full mt-6">
                  <BalText color="primary-light">
                    {t(
                      `branch.${
                        partner.type === 'propertyCraftsman'
                          ? 'Property'
                          : 'MotorVehicle'
                      }`,
                    )}
                  </BalText>
                </div>
                <div className="column is-full">
                  <BalHeading level="h4" space="none" subtitle>
                    {concat([company.name, company.additionalName])}
                  </BalHeading>
                </div>
                <div className="column is-full columns is-gapless is-multiline mt-4">
                  {primaryContact && (
                    <BalText className="column is-full" color="primary-light">
                      {primaryContact.displayName}
                    </BalText>
                  )}
                  <BalText
                    className="column is-full mt-2"
                    color="primary-light"
                  >
                    {concatAddress(company.address)}
                  </BalText>
                  <BalText
                    className="column is-full mt-2"
                    color="primary-light"
                  >
                    {company.address
                      ? concat([
                          company.address.zipCode?.toString(),
                          company.address.city,
                        ])
                      : ''}
                  </BalText>
                </div>
              </div>
            </Popup>
          </Marker>
        );
      })}
    </MarkerClusterGroup>
  );
};
