import {
  BalHeading,
  BalButton,
  BalButtonGroup,
  BalCard,
  BalText,
  BalNotification,
  BalCheckbox,
  BalSpinner,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  ErrorToast,
  ToastNotification,
} from '../../../../components/toast-notification';
import {
  PropertyInspectionTaskRecipientDto,
  PropertyInspectionTaskSendToRecipientsDto,
} from '../../../../types/resource-models';
import { FileViewModal } from '../../../../components/ui';
import { useToken } from '../../../../hooks';
import { sendToRecipients } from '../../data/requests';
import { useNavigate } from 'react-router';
import { EBRoutes } from '../../../../router/router';
import {
  CustomRecipient,
  CustomRecipientType,
} from './property-inspection-task-recipient-card';
import { useContext, useState } from 'react';
import { PropertyInspectionTaskSendingWizardViewContext } from './property-inspection-task-sending-wizard-view';
import { formatDateTime } from '../../../../utils/date';
import { getInspectionReport } from '../../../files/data/requests';

interface PropertyInspectionTaskReviewCardProps {
  onPrevious: () => void;
}

export const PropertyInspectionTaskReviewCard = ({
  onPrevious,
}: PropertyInspectionTaskReviewCardProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    inspectionId,
    recipients,
    selectedCustomRecipients,
    propertyInspectionTask,
    inspection,
  } = useContext(PropertyInspectionTaskSendingWizardViewContext);

  const [createDraft, setCreateDraft] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const task =
    propertyInspectionTask.status === 'success'
      ? propertyInspectionTask.localValue
      : undefined;
  const token = useToken();
  const navigate = useNavigate();
  const joinRecipients = (
    recipients: PropertyInspectionTaskRecipientDto[],
    customRecipients: CustomRecipient[],
  ): CustomRecipient[] => {
    const result = [...customRecipients];
    recipients.map((recipient: PropertyInspectionTaskRecipientDto) => {
      const newRecipient: CustomRecipient = {
        id: recipient.id,
        email: recipient.email ?? '',
        name: `${recipient.name ?? ''}`,
        type: recipient.recipientType,
      };
      result.push(newRecipient);
    });
    return result;
  };

  const allRecipients = joinRecipients(recipients, selectedCustomRecipients);

  const distinctRecipientTypes = [
    ...new Set(
      allRecipients.map((recipient: CustomRecipient) => recipient.type),
    ),
  ];

  return (
    <>
      {task && (
        <BalCard className="mt-5 p-5">
          <BalHeading level="h3">{t('inspectionTask.review')}</BalHeading>
          <div className="columns is-gapless is-multiline">
            <div className="column is-12">
              <BalText
                space="none"
                color="info"
                bold
                size="small"
                className="mt-4"
              >
                {t('inspectionTask.recipient.entityName')}
              </BalText>
              <div className="mt-4">
                {distinctRecipientTypes.map(
                  (recipientType?: CustomRecipientType) => {
                    return (
                      <div
                        key={recipientType ?? 'NoRecipient'}
                        className="ml-4 mt-4"
                      >
                        <span>
                          {t(
                            `inspectionTask.recipient.customRecipientTypes.${
                              recipientType ?? 'NoRecipientType'
                            }`,
                          )}
                        </span>
                        <ul style={{ listStyleType: 'circle' }}>
                          {allRecipients
                            .filter((x) =>
                              recipientType
                                ? x.type === recipientType
                                : !x.type,
                            )
                            .map((recipient) => {
                              return (
                                <li key={recipient.id} className="ml-6">
                                  {`${recipient.name} (${recipient.email})`}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    );
                  },
                )}
              </div>
              <BalText
                space="none"
                color="info"
                bold
                size="small"
                className="mt-4"
              >
                {t('inspectionTask.inspection.nextSteps')}
              </BalText>
              <div className="mt-4 overflow-wrap-anywhere">
                {inspection?.nextSteps}
              </div>
              <BalText
                space="none"
                color="info"
                bold
                size="small"
                className="mt-4"
              >
                {t('inspectionTask.scheduledInspectionDate')}
              </BalText>
              <div className="mt-4 mb-2">
                {formatDateTime(inspection?.inspectionClaim?.inspectionDate)}
              </div>
              <div className="mt-4 mb-2">
                <BalCheckbox
                  checked={createDraft}
                  onBalChange={(e) => setCreateDraft(e.detail)}
                  interface="switch"
                >
                  {t('inspectionTask.recipient.createDraftSwitch')}
                </BalCheckbox>
              </div>
              <div className="is-12" style={{ display: 'inline-flex' }}>
                <BalText
                  space="none"
                  color="info"
                  bold
                  size="small"
                  className="is-4 mt-4"
                >
                  {t('general.report')}
                </BalText>
                <div className="is-4 ml-4 mt-2">
                  <FileViewModal
                    label={`${task.claim.claimNumber}_report.pdf`}
                    fileId={task.id}
                    contentType="application/pdf"
                    getFile={() =>
                      getInspectionReport(token, task.id, inspectionId)
                    }
                    report
                    buttonText={t('inspectionTask.recipient.showReport')}
                  />
                </div>
              </div>
              <BalNotification
                className="mt-4"
                color="warning"
                style={{ textAlign: 'center' }}
              >
                <span>{t('inspectionTask.checkDetailsWarning')}</span>
              </BalNotification>
            </div>
          </div>
          <BalButtonGroup position="right">
            <BalButton
              elementType="button"
              color="primary-light"
              onClick={(event) => event.detail == 1 && onPrevious()}
            >
              {t('general.buttons.back')}
            </BalButton>
            <BalButton
              color="info"
              disabled={submitted}
              onClick={async (event) => {
                if (event.detail == 1) {
                  setSubmitted(true);
                  const sendToRecipientsDto: PropertyInspectionTaskSendToRecipientsDto =
                    {
                      propertyInspectionTaskId: task.id,
                      inspectionId: inspectionId,
                      recipientEmails: [
                        ...new Set(
                          allRecipients
                            .filter((x) => x.email)
                            .map((obj) => obj.email ?? ''),
                        ),
                      ],
                      createDraft: createDraft,
                    };
                  const response = await sendToRecipients(
                    token,
                    sendToRecipientsDto,
                  );
                  if (response.status === 'success') {
                    toast(
                      ToastNotification({
                        message: t(
                          'inspectionTask.recipient.sendToRecipientsSuccess',
                        ),
                        color: 'success',
                      }),
                    );
                    navigate(EBRoutes.PROPERTY_INSPECTION_TASK.create({}));
                  } else if (response.status === 'error') {
                    toast(ErrorToast(response.errorValue));
                  }
                  setSubmitted(false);
                }
              }}
            >
              {t('general.buttons.generateReport')}
            </BalButton>
            {submitted ? <BalSpinner></BalSpinner> : <></>}
          </BalButtonGroup>
        </BalCard>
      )}
    </>
  );
};
