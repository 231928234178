import {
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AddressDto } from '../../types/resource-models';
import AddressEditForm from './address-edit-form';

export interface AddressEditFormModalProps {
  initialValue: AddressDto;
  onSave: (address: AddressDto) => void;
}
const AddressEditFormModal = ({
  initialValue,
  onSave,
}: AddressEditFormModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [saved, setSaved] = useState<boolean>(false);
  useEffect(() => {
    if (saved) {
      balModalController.dismiss();
    }
  }, [saved]);

  return (
    <>
      <BalModalHeader>
        <BalHeading space="none" level="h3">
          {t('general.contact.editContact')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <AddressEditForm
          initialValue={initialValue}
          onSave={(address: AddressDto) => {
            onSave(address);
            setSaved(true);
          }}
        ></AddressEditForm>
      </BalModalBody>
    </>
  );
};

export default AddressEditFormModal;
