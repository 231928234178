import {
  BalButton,
  BalButtonGroup,
  BalClose,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalSelect,
  BalSelectOption,
} from '@baloise/design-system-components-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AppCapabilityNames } from '../../types/resource-models';
import { TranslatedSelection } from '../../types/types';
import { WithCapabilities } from '../authorization/with-capabilities';

type EditSelectionModalProps = {
  label: string;
  value?: TranslatedSelection;
  isNullable?: boolean;
  values: TranslatedSelection[];
  placeholder: string;
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  disabled?: boolean;
  onSave(value?: string): void;
};

function EditSelectionModalContent(
  props: EditSelectionModalProps,
): JSX.Element {
  const { t } = useTranslation();
  const [selectionTextValue, setSelectionTextValue] = useState<string>(
    props.value?.value ?? '',
  );
  const balSelectRef = useRef<HTMLBalSelectElement>(null);

  useEffect(() => {
    const resetValueF = async () => {
      if (balSelectRef.current && !props.value) {
        await balSelectRef.current.clear();
        // After clearing, the object can became null.
        if (balSelectRef.current) {
          balSelectRef.current.setAttribute('value', '');
        }
      }
    };
    resetValueF();
  }, [props.value]);
  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {props.label}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div className="is-flex">
          <BalSelect
            ref={balSelectRef}
            className="column is-10"
            value={selectionTextValue}
            placeholder={props.placeholder}
            onBalChange={(e) => {
              if (e.detail) {
                setSelectionTextValue(e.detail.toString());
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {props.values.map((selection) => (
                <BalSelectOption
                  key={selection.value}
                  value={selection.value}
                  label={selection.translatedValue}
                >
                  {selection.translatedValue}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
          <div className="is-flex is-flex-direction-column is-justify-content-center">
            <div>
              {selectionTextValue !== '' && props.isNullable && (
                <BalClose
                  onClick={async () => {
                    setSelectionTextValue('');
                    if (
                      balSelectRef &&
                      balSelectRef.current &&
                      props.isNullable
                    ) {
                      await balSelectRef.current.clear();
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            color="info"
            onClick={(event) => {
              event.detail == 1 && props.onSave(selectionTextValue);
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
}

export function EditSelectionModal(
  props: EditSelectionModalProps,
): JSX.Element {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditSelectionModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        disabled={props.disabled}
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
}
