import { FieldValues, FormState, UseFormRegisterReturn } from 'react-hook-form';
import { JournalChangeDto } from '../../../bat-shared/resource-models';
import {
  ClaimDto,
  PropertyInspectionTaskDto,
  PropertyInspectionTaskListDto,
  InvestigationTaskDto,
  PartnerDto,
  ResponseOfPropertyInspectionTaskListDto,
  UserDto,
  VehicleTaskDto,
  CraftsmanDto,
  AffectedPartnerDto,
  VehicleSpecialtyNames,
  ResponseOfCraftsmanDto,
  WorkCategoryDto,
  CraftsmanDetailDto,
  VehicleBrandNames,
  ResponseOfPropertyInspectionTaskDto,
  CraftsmanFilterCriteria,
  PropertyInspectionTaskFilterCriteriaDto,
  VehicleTaskFilterCriteriaDto,
  BaseTaskFilterCriteria,
  AccidentInspectionTaskDto,
  AccidentInspectionTaskListDto,
  ResponseOfAccidentInspectionTaskListDto,
  AccidentInspectionTaskFilterCriteriaDto,
  ResponseOfAccidentInspectionTaskDto,
  BaseTaskListDto,
  ResponseOfCraftsmanListDto,
  CraftsmanListDto,
  ResponseOfVehicleTaskListDto,
  VehicleTaskListDto,
  BaseTaskChangeDto,
  CraftsmanChangeDto,
  InvestigationTaskListDto,
  ResponseOfInvestigationTaskListDto,
  ResponseOfInvestigationTaskDto,
  InvestigationTaskJournalDto,
  InsuranceSurveyDto,
  InsuranceSurveyChangeDto,
  PropertyInspectionTaskJournalDto,
  PropertyInspectionTaskJournalChangeDto,
} from './resource-models';

type Person = Required<Pick<PartnerDto, 'person'>> & {
  type: 'person';
};

type Company = Required<Pick<PartnerDto, 'company'>> & {
  type: 'company';
};

export type Partner = Person | Company;

const partnerTypeObject = ({} as Partner).type;

export type PartnerType = typeof partnerTypeObject;

export type PropertyCraftsman = Required<
  Pick<CraftsmanDto, 'propertyCraftsman'>
> & {
  type: 'propertyCraftsman';
};

export type VehicleCraftsman = Required<
  Pick<CraftsmanDto, 'vehicleCraftsman'>
> & {
  type: 'vehicleCraftsman';
};

export type Craftsman = PropertyCraftsman | VehicleCraftsman;

export type PropertyCraftsmanDetail = Required<
  Pick<CraftsmanDetailDto, 'propertyCraftsman'>
> & {
  type: 'propertyCraftsman';
};

export type VehicleCraftsmanDetail = Required<
  Pick<CraftsmanDetailDto, 'vehicleCraftsman'>
> & {
  type: 'vehicleCraftsman';
};

export type CraftsmanDetail = PropertyCraftsmanDetail | VehicleCraftsmanDetail;

export type AffectedPartner = Omit<AffectedPartnerDto, 'partner'> & {
  partner: Partner;
};

export type Claim = Omit<ClaimDto, 'affectedPartners' | 'policyHolder'> & {
  affectedPartners: AffectedPartner[];
  policyHolder: Partner;
};

export type AccidentInspectionTask = Omit<
  AccidentInspectionTaskDto,
  'claim' | 'contact'
> & {
  claim: Claim;
  contact: AffectedPartnerDto;
  type: TaskTypes.AccidentInspectionTask;
};

export type PropertyInspectionTask = Omit<
  PropertyInspectionTaskDto,
  'claim' | 'contact'
> & {
  claim: Claim;
  contact: AffectedPartnerDto;
  type: TaskTypes.PropertyInspectionTask;
};

export interface InvestigationTaskJournal extends InvestigationTaskJournalDto {
  type: 'investigationTaskJournal';
}

export interface PropertyInspectionTaskJournal
  extends PropertyInspectionTaskJournalDto {
  type: 'propertyInspectionTaskJournal';
}

export interface InsuranceSurvey extends InsuranceSurveyDto {
  type: 'insuranceSurvey';
}

export function getContact(task: BaseTask): AffectedPartner | undefined {
  return task.claim.affectedPartners.find(
    (affectedPartner) => affectedPartner.id === task.contactId,
  );
}

export function getDisplayName(
  task: PropertyInspectionTask | InvestigationTask | VehicleTask,
): string {
  let result = '';
  const contact = getContact(task);

  if (contact) {
    result =
      contact.partner.type === 'person'
        ? contact.partner.person.displayName
        : contact.partner.company.displayName;
  }

  return result;
}

export function getNameFromPartner(partner?: PartnerDto): string {
  if (partner) {
    if (partner.type == 'person' && partner.person) {
      return partner.person.displayName;
    } else if (partner.type == 'company' && partner.company) {
      return partner.company.displayName;
    }
  }
  return '';
}

export interface ResponseOfBaseTaskListDto {
  entities: BaseTaskListDto[];
  totalPages: number;
  totalItems: number;
}

export type BaseTasksListResponse = Omit<
  ResponseOfBaseTaskListDto,
  'entities'
> & {
  entities: BaseTaskListDto[];
};

export type AccidentInspectionTasksListResponse = Omit<
  ResponseOfAccidentInspectionTaskListDto,
  'entities'
> & {
  entities: AccidentInspectionTaskListDto[];
};

export type AccidentInspectionTasksResponse = Omit<
  ResponseOfAccidentInspectionTaskDto,
  'entities'
> & {
  entities: AccidentInspectionTaskDto[];
};

export type PropertyInspectionTasksListResponse = Omit<
  ResponseOfPropertyInspectionTaskListDto,
  'entities'
> & {
  entities: PropertyInspectionTaskListDto[];
};

export type PropertyInspectionTasksResponse = Omit<
  ResponseOfPropertyInspectionTaskDto,
  'entities'
> & {
  entities: PropertyInspectionTaskDto[];
};

export type InvestigationTask = Omit<InvestigationTaskDto, 'claim'> & {
  claim: Claim;
  type: TaskTypes.InvestigationTask;
};

export type InvestigationTasksResponse = Omit<
  ResponseOfInvestigationTaskDto,
  'entities'
> & {
  entities: InvestigationTaskDto[];
};

export type InvestigationTasksListResponse = Omit<
  ResponseOfInvestigationTaskListDto,
  'entities'
> & {
  entities: InvestigationTaskListDto[];
};

export type VehicleTasksListResponse = Omit<
  ResponseOfVehicleTaskListDto,
  'entities'
> & {
  entities: VehicleTaskListDto[];
};

export type CraftsmansResponse = Omit<ResponseOfCraftsmanDto, 'entities'> & {
  entities: Craftsman[];
};

export type CraftsmansListResponse = Omit<
  ResponseOfCraftsmanListDto,
  'entities'
> & {
  entities: CraftsmanListDto[];
};

export interface BaseTaskFilterTaskScreen
  extends Partial<BaseTaskFilterCriteria> {
  groupedStatus?: BaseTaskStatusGroupedFilter | undefined;
}

export type BaseTaskStatusGroupedFilter =
  | 'New'
  | 'Assigned'
  | 'SentToArchive'
  | 'InProgress'
  | 'Rejected'
  | 'Closed';

export type AccidentInspectionTaskFilter = Omit<
  AccidentInspectionTaskFilterCriteriaDto,
  'assigneeUserId' | 'creatorUserId' | 'assigneeOrCreatorUserId'
> & {
  assignee?: UserDto;
  creator?: UserDto;
  assigneeOrCreator?: UserDto;
};

export type PropertyInspectionTaskFilter = Omit<
  PropertyInspectionTaskFilterCriteriaDto,
  'assigneeUserId' | 'creatorUserId' | 'assigneeOrCreatorUserId'
> & {
  assignee?: UserDto;
  creator?: UserDto;
  assigneeOrCreator?: UserDto;
};

export type VehicleTaskFilter = Omit<
  VehicleTaskFilterCriteriaDto,
  'assigneeUserId' | 'creatorUserId' | 'assigneeOrCreatorUserId'
> & {
  assignee?: UserDto;
  creator?: UserDto;
  assigneeOrCreator?: UserDto;
};

export type VehicleCraftsmanFilter = Omit<
  CraftsmanFilterCriteria,
  'workCategoryBKPNumber' | 'responsibleUserId'
> & { worCategoryBKPNumber?: WorkCategoryDto; responsible?: UserDto };

export type PropertyCraftsmanFilter = Omit<
  CraftsmanFilterCriteria,
  | 'vehicleSpecialty'
  | 'vehicleBrand'
  | 'glassOnly'
  | 'headquarters'
  | 'responsibleUserId'
> & {
  vehicleSpecialty?: VehicleSpecialtyNames;
  vehicleBrand?: VehicleBrandNames;
  glassOnly?: boolean;
  headquarters?: boolean;
  responsible?: UserDto;
};

export type VehicleTask = Omit<VehicleTaskDto, 'claim'> & {
  claim: Claim;
  type: TaskTypes.VehicleTask;
};

export type AttachmenEntity =
  | AccidentInspectionTask
  | PropertyInspectionTask
  | PropertyInspectionTaskJournal
  | InvestigationTask
  | VehicleTask
  | InvestigationTaskJournal
  | InsuranceSurvey;

const AttachmenEntityTypeObject = ({} as AttachmenEntity).type;

export type AttachmentEntityType = typeof AttachmenEntityTypeObject;

export type BaseTask =
  | AccidentInspectionTask
  | PropertyInspectionTask
  | InvestigationTask
  | VehicleTask;

const baseTaskTypeObject = ({} as BaseTask).type;

export type TaskType = typeof baseTaskTypeObject;

export enum TaskTypes {
  PropertyInspectionTask,
  AccidentInspectionTask,
  VehicleTask,
  InvestigationTask,
}

export type WithNone<T> = 'None' | T;

export type WorkCategories = WorkCategoryDto[];

export type AppModuleVisibility = {
  accidentInspection: boolean;
  propertyInspection: boolean;
  propertyInspectionTaskJournal: boolean;
  networkPartner: boolean;
  investigationInspection: boolean;
  vehicleInspection: boolean;
  riskAssessment: boolean;
  vehicleBonusCorrection: boolean;
  userManagement: boolean;
  resetFilter: boolean;
  investigationInspectionSentToAdos: boolean;
  investigationInspectionJournal: boolean;
  investigationInspectionExpenses: boolean;
  insuranceSurveys: boolean;
};

export type OverviewMenuType =
  | 'PropertyInspectionTask'
  | 'InvestigationTask'
  | 'VehicleTask'
  | 'Networkpartner';

export type TabType =
  | 'claimNumber'
  | 'vehicleClaimNumber'
  | 'vehicleCompanyContactInformation'
  | 'vehicleTaskClaimDamageForm'
  | 'vehicleClaimInformation'
  | 'contactInformation'
  | 'claimInformation'
  | 'completeClaim'
  | 'completeVehicleClaim'
  | 'none';

export interface ExportProperties {
  onExport: () => void;
  exportTranslation: string;
  exportButtonLoadingState: boolean | undefined;
}

export type EntityChange =
  | BaseTaskChangeDto
  | CraftsmanChangeDto
  | JournalChangeDto
  | PropertyInspectionTaskJournalChangeDto
  | InsuranceSurveyChangeDto;

export interface ISortingState {
  field: string;
  direction: 'asc' | 'desc';
}

export type TranslatedSelection = {
  value: string;
  translatedValue: string;
};

export type TranslatedSelectionTwoStage = {
  main: TranslatedSelection;
  sub: TranslatedSelection;
};

export type FormValidation = {
  register: UseFormRegisterReturn;
  formState: FormState<FieldValues>;
};

export class CachedData<T> {
  items: T[];
  expiration: Date;

  constructor(items: T[], expiration: Date) {
    this.items = items;
    this.expiration = expiration;
  }
}
