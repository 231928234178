import {
  BalCard,
  BalHeading,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../data';
import { BaseTask, InvestigationTask } from '../../../types/types';
import '../../../styles/general.scss';
import { RefObject, useEffect, useState } from 'react';
import { formatDateTime } from '../../../utils/date';
import { concat, concatAddress } from '../../../utils';
import { useToken } from '../../../hooks';
import { InvestigationTaskWorkflowButtons } from './investigation-task-workflow-buttons/investigation-task-workflow-buttons';
import { EBRoutes } from '../../../router/router';
import {
  BaseTaskAttachments,
  BaseTaskClaimInfo,
  BaseTaskClaimNumber,
} from '../../base-task';
import { getDisplayNameFromUser } from '../../../utils/utilities';
import { BaseTaskListDto } from '../../../types/resource-models';
import { InfoDisplay } from '../../../components/ui';
import BaseTaskInternalNotes from '../../base-task/components/base-task-internal-notes';
import DisplayMeasures, {
  fullAnyNameToShow,
} from './measures/display-measures';

type InvestigationTaskOverlayProps = {
  tab?: string;
  fillSpace?: boolean;
  slideIn?: boolean;
  hideLinkButtons?: boolean;
  refTaskOverlay?: RefObject<HTMLBalCardElement>;
  task: BaseTaskListDto;
  replaceTaskInList?: (task: RequestResult<BaseTask>) => void;
  getFullTask: (
    bearerToken: string,
    taskId: string,
  ) => Promise<RequestResult<InvestigationTask>>;
};

export const InvestigationTaskOverlay = ({
  tab,
  task,
  fillSpace,
  slideIn,
  hideLinkButtons,
  refTaskOverlay,
  replaceTaskInList,
  getFullTask,
}: InvestigationTaskOverlayProps): JSX.Element => {
  const { t } = useTranslation();
  const [fullTask, setFullTask] = useState<InvestigationTask | undefined>(
    undefined,
  );
  const token = useToken();

  useEffect(() => {
    if (token !== '' && task && task.id !== '') {
      getFullTask(token, task.id).then((response) => {
        if (
          response.status == 'success' &&
          response.localValue.status == task.status
        ) {
          setFullTask(response.value);
        }
      });
    }
  }, [task, token, getFullTask]);

  const showButtons = fullTask && fullTask.status !== 'New';
  const policyHolder = fullTask?.claim.policyHolder;

  return fullTask ? (
    <BalCard
      ref={refTaskOverlay}
      id="overlay"
      className={slideIn ? 'animated-overlay-in' : ''}
    >
      <>
        <BalHeading level="h4" className="overlay-title p-5">
          <div className="is-flex is-flex-direction-column is-full-width">
            <BaseTaskClaimNumber
              detailButtonRequiredCapabilities={[
                'CreateFraudInspectionTask',
                'EditFraudInspectionTask',
              ]}
              detailButtonRoute={
                !hideLinkButtons
                  ? EBRoutes.INVESTIGATION_TASK_DETAIL.create({
                      taskId: fullTask.id,
                    })
                  : undefined
              }
              journalButtonRoute={
                !hideLinkButtons
                  ? `${EBRoutes.INVESTIGATION_TASK_DETAIL.create({
                      taskId: fullTask.id,
                    })}?TabItem=journal`
                  : undefined
              }
              claimNumber={fullTask.claim.claimNumber}
            />
            {fullTask.status !== 'New' && (
              <>
                <BalText size="small" className="mt-1 has-text-black">
                  {concat([
                    formatDateTime(fullTask.createdAt),
                    t('general.from').toLowerCase(),
                    getDisplayNameFromUser(fullTask.creator),
                    fullTask.creator.lastName,
                    fullTask.assignee?.insuranceCenter.name,
                  ])}
                </BalText>
                <div className="is-full-width mb-2 mt-2 bottom-blue-line"></div>
              </>
            )}
            <InvestigationTaskWorkflowButtons
              task={fullTask}
              className="is-flex is-align-items-center"
              onChangeSuccess={replaceTaskInList && replaceTaskInList}
            />
          </div>
        </BalHeading>
        <div
          className={concat([
            'overlay-content mb-5 scroll-bar-minimal',
            showButtons ? 'show-buttons-investigation' : '',
          ])}
        >
          {fullTask.claim && <BaseTaskClaimInfo claim={fullTask.claim} />}
          {policyHolder && (
            <>
              <BalHeading level="h5" space="none" className="mt-5">
                {t('claimDetail.policyHolder')}
              </BalHeading>
              <InfoDisplay
                title={t('general.name')}
                text={
                  policyHolder.type === 'person'
                    ? policyHolder.person.displayName
                    : policyHolder.company.displayName
                }
                className="pb-1"
              />
              <InfoDisplay
                title={t('general.address')}
                text={
                  policyHolder.type === 'person'
                    ? concatAddress(policyHolder.person.address)
                    : concatAddress(policyHolder.company.address)
                }
                className="pb-1"
              />
              {policyHolder.type === 'person' && (
                <InfoDisplay
                  title={t('general.birthday')}
                  text={formatDateTime(policyHolder.person.birthday)}
                  className="pb-1"
                />
              )}
              <InfoDisplay
                title={t('general.email')}
                text={
                  policyHolder.type === 'person'
                    ? policyHolder.person.email
                    : policyHolder.company.email
                }
                className="pb-1"
              />
              <InfoDisplay
                title={t('general.phone')}
                text={
                  policyHolder.type === 'person'
                    ? policyHolder.person.phoneNumber ?? 'no-availability?'
                    : policyHolder.company.phoneNumber ?? 'no-availability?'
                }
                className="pb-1"
              />
            </>
          )}
          {tab !== 'claimInformation' &&
            (fullTask.what ||
              fullTask.remarks ||
              fullTask.investigationTaskType !== undefined) && (
              <BalHeading level="h5" space="none" className="mt-5">
                {t('overlay.additionalInformation')}
              </BalHeading>
            )}
          <InfoDisplay
            title={t('investigationTask.type')}
            text={
              fullTask.investigationTaskType
                ? t(
                    `investigationTask.investigationTaskType.${fullTask.investigationTaskType}`,
                  )
                : ''
            }
            className="pb-1"
          />
          <InfoDisplay
            title={t('investigationTask.suspicion')}
            text={fullTask.what}
          />
          <InfoDisplay
            title={t('general.claim.remarks')}
            text={fullTask.remarks}
          />
          <InfoDisplay
            title={t('investigationTask.estimatedSavings')}
            text={
              fullTask.estimatedSavings
                ? t(`estimatedSavings.${fullTask.estimatedSavings}`)
                : ''
            }
          />
          <InfoDisplay
            title={t('investigationTask.taskAssessment')}
            text={
              fullTask.taskAssessment
                ? t(`taskAssessment.${fullTask.taskAssessment}`)
                : ''
            }
          />
          <InfoDisplay
            title={t('investigationTask.investigationTaskCompletionCode')}
            text={
              fullTask.investigationTaskCompletionCode != null
                ? t(
                    `investigationTaskCompletionCode.${fullTask.investigationTaskCompletionCode}`,
                  )
                : ''
            }
          />
          <InfoDisplay
            title={t('investigationTask.investigationSavings')}
            text={fullTask.investigationSavings?.toString() ?? ''}
          />
          {fullAnyNameToShow(fullTask) && (
            <div className="is-full-width mt-5">
              <BalHeading className="pb-1" space="none" level="h5" color="info">
                {t('investigationTask.measures')}
              </BalHeading>
              <div className="is-full-width mt-3">
                <DisplayMeasures investigationTask={fullTask} />
              </div>
            </div>
          )}

          {fullTask.internalNote && tab !== 'claimInformation' && (
            <BaseTaskInternalNotes internalNote={fullTask.internalNote} />
          )}
          {fullTask.attachments.length > 0 && tab !== 'claimInformation' && (
            <BaseTaskAttachments attachments={fullTask.attachments} />
          )}
        </div>
      </>
    </BalCard>
  ) : fillSpace ? (
    <div className="overlay slide-in is-pulled-right" />
  ) : (
    <div className="overlay-space-slide" />
  );
};
