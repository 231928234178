import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalIcon,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { getAttachment } from '../../features/files';
import { useToken } from '../../hooks';
import {
  balIconCaretLeft,
  balIconCaretRight,
  balIconReadOnly,
} from '@baloise/design-system-icons';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ErrorToast } from '../toast-notification';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.vite';
import { RequestResult } from '../../data';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// We get the pdf worker from local because cdn are forbidden in baloise infrastructure.
// PdfWorker is not declared in the packate, but it is included. Until fixed, we will force the import.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { pdfWorker } from 'react-pdf/dist/esm/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

type FileViewModalProps = {
  contentType: string;
  label: string;
  fileId: string;
  getFile?: () => Promise<RequestResult<string>>;
  report?: boolean;
  fileUrlParam?: string;
  buttonText?: string;
  disabled?: boolean;
  notOutlined?: boolean;
};

const FileViewModalContent = ({
  contentType,
  label,
  fileId,
  getFile,
  fileUrlParam,
}: FileViewModalProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const [fileUrl, setFileUrl] = useState<RequestResult<string>>(
    fileUrlParam
      ? { status: 'success', value: fileUrlParam, localValue: fileUrlParam }
      : { status: 'initial' },
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (fileUrl.status === 'initial') {
      setFileUrl({ status: 'loading' });
      if (getFile === undefined) {
        getAttachment(token, fileId).then((fileResponse) => {
          setFileUrl(fileResponse);
          if (fileResponse.status === 'error') {
            toast(ErrorToast(fileResponse.errorValue));
          }
        });
      } else if (getFile !== undefined) {
        getFile().then((fileResponse) => {
          setFileUrl(fileResponse);
          if (fileResponse.status === 'error') {
            toast(ErrorToast(fileResponse.errorValue));
          }
        });
      }
    }
  }, [fileUrl, getFile, fileId, token]);

  return (
    <div>
      <BalModalHeader>
        <BalHeading level="h3" className="mb-3">
          {label}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        {contentType.includes('image') && fileUrl.status === 'success' ? (
          <img src={fileUrl.value} />
        ) : fileUrl.status === 'error' ? (
          <>{t('general.loadingFileError')}</>
        ) : fileUrl.status !== 'success' ? (
          <>{t('general.loadingFile')}</>
        ) : (
          <>
            <Document
              file={fileUrl.status === 'success' ? fileUrl.value : undefined}
              onLoadSuccess={(pdf) => {
                setTotalPages(pdf._pdfInfo.numPages);
              }}
              loading={t('general.loadingFile')}
            >
              <Page pageNumber={pageNumber} className="pdf-only" />
            </Document>
            {totalPages > 1 && (
              <div className="is-flex is-justify-content-center">
                <BalButton
                  className="ml-3 mr-3"
                  color="primary"
                  size="small"
                  square
                  disabled={pageNumber === 1}
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}
                >
                  <BalIcon svg={balIconCaretLeft} size="small" />
                </BalButton>
                <BalText bold>{`${pageNumber} / ${totalPages}`}</BalText>
                <BalButton
                  className="ml-3 mr-3"
                  color="primary"
                  size="small"
                  square
                  disabled={pageNumber === totalPages}
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}
                >
                  <BalIcon svg={balIconCaretRight} size="small" />
                </BalButton>
              </div>
            )}
          </>
        )}
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={() => balModalController.dismiss()}
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            disabled={fileUrl.status !== 'success'}
            onClick={() => {
              if (fileUrl.status === 'success') {
                const a = document.createElement('a');
                a.href = fileUrl.value;
                a.download = label;
                a.target = '_blank';
                a.click();
              }
            }}
            color="info"
          >
            {t('general.buttons.download')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export const FileViewModal = (props: FileViewModalProps): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: FileViewModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: true,
    });
    return modal.present();
  };
  const token = useToken();
  const { t } = useTranslation();

  return props.report ? (
    <BalButton
      className="is-full-width"
      size="small"
      color="info"
      outlined={!props.notOutlined}
      disabled={props.disabled}
      onClick={() => {
        openModal();
      }}
    >
      {props.buttonText ?? t('overlay.viewReport')}
    </BalButton>
  ) : (
    <BalButton
      className="ml-3 mr-1"
      color="primary"
      size="small"
      outlined={props.notOutlined}
      disabled={props.disabled}
      square
      onClick={() => {
        if (
          props.contentType.includes('image') ||
          props.contentType.includes('pdf')
        )
          openModal();
        else {
          //we do not accept others than images or PDF, but we can keep this safeguard
          getAttachment(token, props.fileId).then((fileResponse) => {
            if (fileResponse.status === 'success') {
              const fileUrl = fileResponse.value;
              const windowName = props.label;
              const newWindow = window.open(fileUrl, windowName);
              setTimeout(() => {
                if (newWindow) {
                  newWindow.document.title = props.label;
                }
              }, 400);
            } else if (fileResponse.status === 'error') {
              toast(ErrorToast(fileResponse.errorValue));
            }
          });
        }
      }}
    >
      <BalIcon svg={balIconReadOnly} size="small" />
    </BalButton>
  );
};
