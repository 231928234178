import {
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalButtonGroup,
  BalPagination,
} from '@baloise/design-system-components-react';
import { GridOptions, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { BalTextField } from '../../../components/input';
import { Icon, ResultRenderer } from '../../../components/ui';
import { balModalController } from '../../../controller/controllers';
import searchIcon from '../../../assets/icons/Iconframe.svg';
import {
  CraftsmanFilterCriteria,
  CraftsmanListDto,
} from '../../../types/resource-models';
import { useEffect, useState } from 'react';
import { getCraftsmanList } from '../data/requests';
import { useToken } from '../../../hooks';
import { CraftsmansListResponse } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { RequestResult } from '../../../data';

interface NetworkPartnerSelectorProps {
  onSelectedSuccess: (craftsman: CraftsmanListDto | undefined) => void;
  title?: string;
  craftsmanFilter?: CraftsmanFilterCriteria;
  disableNoChoice?: boolean;
}

export const NetworkPartnerSelector = ({
  onSelectedSuccess,
  title,
  craftsmanFilter,
  disableNoChoice,
}: NetworkPartnerSelectorProps): JSX.Element => {
  const token = useToken();
  const { t } = useTranslation();

  let initialFilter: CraftsmanFilterCriteria = {
    responsibleUserId: undefined,
    page: 0,
    maxItemCount: 8,
    orderBy: 'asc',
    orderField: 'Company.Name',
    isDeleted: false,
  };
  if (craftsmanFilter) {
    initialFilter = produce(craftsmanFilter, (draftState) => {
      draftState.responsibleUserId = undefined;
      draftState.page = 0;
      draftState.maxItemCount = 8;
      draftState.orderBy = 'asc';
      draftState.orderField = 'Company.Name';
      draftState.isDeleted = false;
    });
  }
  const [filter, setFilter] = useState<CraftsmanFilterCriteria>(initialFilter);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [craftsmanName, setCraftsmanName] = useState<string | undefined>(
    undefined,
  );
  const [requestResult, setRequestResult] = useState<
    RequestResult<CraftsmansListResponse>
  >({
    status: 'initial',
  });
  const [selectedCraftsman, setSelectedCraftsman] = useState<
    CraftsmanListDto | undefined
  >(undefined);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (token) {
      setSelectedCraftsman(undefined);
      getCraftsmanList(token, filter).then((response) => {
        setRequestResult(response);
        if (response.status === 'success') {
          setTotalPages(response.value.totalPages);
        }
      });
    }
  }, [token, filter]);

  const getGridOptions = (): GridOptions => {
    return {
      defaultColDef: {
        sortable: false,
        filter: false,
        autoHeight: false,
      },

      onGridReady(event) {
        const pixelRange = event.api.getVerticalPixelRange();
        let gridHeight = pixelRange.bottom - pixelRange.top;
        // Setting max item count in filter to avoid vertical scroll.
        const rowHeigth = event.api.getSizesForCurrentTheme().rowHeight;
        const headerHeight =
          event.api.getSizesForCurrentTheme().headerHeight ?? 0;
        gridHeight = gridHeight - headerHeight;
        const maxRowsFitting = gridHeight / rowHeigth;
        if (Math.trunc(maxRowsFitting) !== filter.maxItemCount) {
          setFilter(
            produce(filter, (draftState) => {
              draftState.maxItemCount = Math.trunc(maxRowsFitting);
            }),
          );
        }
      },
      overlayNoRowsTemplate: '&nbsp;',
      rowHeight: 40,
      columnDefs: [
        {
          field: 'company.displayName',
          headerName: t('general.name'),
          width: 350,
        },
        {
          field: 'company.address.addressString',
          headerName: t('general.address'),
          width: 280,
        },
        {
          field: 'company.email',
          headerName: t('general.email'),
          width: 200,
        },
        {
          field: 'company.phoneNumber',
          headerName: t('general.phone'),
          width: 125,
        },
      ],
      onRowClicked(event: RowClickedEvent) {
        setSelectedCraftsman(event.data);
      },
    };
  };

  const onCraftsmanNameChanged = (event: CustomEvent) => {
    setCraftsmanName(event.detail);
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      onSearchDelay(event.detail);
    }, 500);

    setTimer(newTimer);
  };

  const onCraftsmanNameKeyEnter = (event: CustomEvent<KeyboardEvent>) => {
    if (event.detail.key == 'Enter') {
      clearTimeout(timer);
      onSearchButtonClicked();
    }
  };

  const setLoading = () => {
    setRequestResult(
      produce(requestResult, (draftState) => {
        draftState.status = 'loading';
      }),
    );
  };

  const onSearchDelay = (eventDetail: string) => {
    if (filter.companyName != eventDetail) {
      setLoading();
      setFilter(
        produce(filter, (draftState) => {
          draftState.companyName = eventDetail;
          draftState.page = 0;
        }),
      );
    }
  };

  const onSearchButtonClicked = () => {
    if (filter.companyName != craftsmanName) {
      setLoading();
      setFilter(
        produce(filter, (draftState) => {
          draftState.companyName = craftsmanName;
          draftState.page = 0;
        }),
      );
    }
  };

  const setPageFilter = (page: number) => {
    setLoading();
    setFilter(
      produce(filter, (draftState) => {
        draftState.page = page;
      }),
    );
  };

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">
          {title ?? t('networkPartners.selectorTitle')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div className="my-5">
          <div className="is-flex is-flex-direction-row mt-4">
            <BalButton
              color="info"
              size="small"
              square
              outlined
              className="mr-3"
              onClick={(event) => event.detail == 1 && onSearchButtonClicked()}
            >
              <Icon iconPath={searchIcon} />
            </BalButton>
            <BalTextField
              className="is-flex-grow-1"
              placeholder={t('networkPartners.selectByName')}
              value={craftsmanName}
              onInput={(event) => onCraftsmanNameChanged(event)}
              onKeyPress={(event) => onCraftsmanNameKeyEnter(event)}
            ></BalTextField>
          </div>
        </div>
        <div className="task-table-modal">
          <ResultRenderer
            result={requestResult}
            defaultValue={{ totalPages: 1, totalItems: 0, entities: [] }}
            loaded={(tasksResponse) => {
              return (
                <AgGridReact
                  suppressCellFocus={true}
                  rowSelection={'single'}
                  rowData={tasksResponse.entities}
                  gridOptions={getGridOptions()}
                />
              );
            }}
          />
        </div>
        <BalPagination
          className="mt-2"
          value={totalPages > 0 ? filter.page + 1 : 0}
          totalPages={totalPages}
          onBalChange={(e) => {
            setPageFilter(e.detail - 1);
          }}
        />

        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            outlined
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          {disableNoChoice ? (
            <></>
          ) : (
            <BalButton
              elementType="button"
              color="warning"
              outlined
              onClick={(event) => {
                if (event.detail == 1) {
                  onSelectedSuccess(undefined);
                  balModalController.dismiss();
                }
              }}
            >
              {t('general.buttons.notSelect')}
            </BalButton>
          )}

          <BalButton
            color="primary"
            disabled={selectedCraftsman === undefined}
            onClick={(event) => {
              if (selectedCraftsman && event.detail == 1) {
                onSelectedSuccess(selectedCraftsman);
                balModalController.dismiss();
              }
            }}
          >
            {t('general.buttons.select')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};
