import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useToken } from '../../../hooks';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import { ManagementCard } from './admin-management-card';
import { useImportStateFromApi } from '../data/hooks';
import { postImportFile } from '../../files/data/requests';
import { getUsersExportFile } from '../../user';
import { APIRoutes } from '../../../router/router';
import { RequestResult } from '../../../data';
import { ImportStateDto } from '../../../types/resource-models';

type UserManagementCardProps = {
  exportButtonLoadingState?: boolean;
  setExportButtonLoadingState: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserManagementCard = ({
  exportButtonLoadingState,
  setExportButtonLoadingState,
}: UserManagementCardProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const inputFile = useRef<HTMLInputElement>(null);

  const [importUsersStateFromApi, setImportUsersStateFromApi] =
    useImportStateFromApi('Users');
  const [importUsersButtonLoadingState, setImportUsersButtonLoadingState] =
    useState(false);

  const onExport = () => {
    getUsersExportFile(token).then((userResponse) => {
      setExportButtonLoadingState(true);
      if (userResponse.status === 'success') {
        const a = document.createElement('a');
        a.href = userResponse.localValue;
        const date = new Date();
        a.download = `ExportedUsers_${date.toISOString()}.json`;
        a.click();
      } else if (userResponse.status === 'error') {
        toast(ErrorToast(userResponse.errorValue));
      }
      setExportButtonLoadingState(false);
    });
  };

  const onChangedFiles = async (
    route: string,
    setImportLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
    setImportStateFromApi: React.Dispatch<
      React.SetStateAction<RequestResult<ImportStateDto[]>>
    >,
    inputFile: React.RefObject<HTMLInputElement>,
  ) => {
    if (
      inputFile.current &&
      inputFile.current.files &&
      inputFile.current.files.length > 0
    ) {
      setImportStateFromApi({ status: 'loading' });
      setImportLoadingState(true);
      const selectedFile = inputFile.current.files[0];

      postImportFile(route, token, selectedFile).then((result) => {
        if (result.status === 'success') {
          setImportStateFromApi({ status: 'initial' });
          toast(
            ToastNotification({
              message: t('usersManagement.usersImportSuccess'),
              color: 'success',
            }),
          );
        } else if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
        if (inputFile.current) {
          inputFile.current.value = '';
        }
        setImportLoadingState(false);
      });
    }
  };

  return (
    <ManagementCard
      title={t('usersManagement.title')}
      description={t('usersManagement.description')}
      importButtonName={t('usersManagement.import')}
      importButtonLoadingState={importUsersButtonLoadingState}
      inputFile={inputFile}
      exportProps={{
        onExport: onExport,
        exportTranslation: t('usersManagement.export'),
        exportButtonLoadingState: exportButtonLoadingState,
      }}
      onChangedFiles={async () => {
        onChangedFiles(
          APIRoutes.POST_USERS_IMPORT.create({}),
          setImportUsersButtonLoadingState,
          setImportUsersStateFromApi,
          inputFile,
        );
      }}
      importStateFromApi={importUsersStateFromApi}
    />
  );
};
