import {
  BalCard,
  BalHeading,
  BalIcon,
  BalList,
  BalListItem,
  BalListItemContent,
  BalListItemIcon,
  BalListItemTitle,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { AppModuleVisibility } from '../../types/types';
import { EBRoutes } from '../../router/router';
import { WithCapabilities } from '../authorization';
import { getEnabledModules } from '../../utils/utilities';
import { useNavigate } from 'react-router';
import { ShowTextModal } from '../input/show-text-modal';
import { NonExpertTasks } from '../../features/investigation-task/components/non-experts-task-view';

export type CardModulesProps = {
  appModuleVisability: AppModuleVisibility;
};

export const CardModules = ({
  appModuleVisability: appModuleVisability,
}: CardModulesProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <WithCapabilities
        className="column is-full"
        requiredCapabilities={['CreatePropertyInspectionTask']}
        capabilityEnabled={appModuleVisability.propertyInspection}
      >
        <BalCard className="p-5 has-text-white" color="info" inverted>
          <BalHeading className="p-0" space="none" level="h4" inverted>
            {t('branch.Standard')}
          </BalHeading>
          <p>{t('home.propertyTechnicalDescription')}</p>
          <BalList className="column py-0" background="dark">
            <BalListItem
              clickable
              onClick={() =>
                navigate(EBRoutes.PROPERTY_INSPECTION_TASK_WIZARD.template())
              }
            >
              <BalListItemIcon className="min-height-0px">
                <BalIcon name="plus" size="small" />
              </BalListItemIcon>
              <BalListItemContent>
                <BalListItemTitle>
                  {t('general.buttons.createTask')}
                </BalListItemTitle>
              </BalListItemContent>
            </BalListItem>
          </BalList>
        </BalCard>
      </WithCapabilities>
      <WithCapabilities
        className="column is-full"
        requiredCapabilities={['CreateFraudInspectionTask']}
        capabilityEnabled={appModuleVisability.investigationInspection}
      >
        <BalCard className="column is-full p-5 has-text-white" inverted>
          <BalHeading className="p-0" space="none" level="h4" inverted>
            {t('home.investigation')}
          </BalHeading>
          <p>{t('home.investigationDescription')}</p>
          <BalList className="column py-0" background="dark">
            <ShowTextModal
              buttonText={t('home.reportFraudSubmissionCriteria')}
              title={t('investigationTask.disclaimer.title')}
              subTitle={t('investigationTask.disclaimer.subTitle')}
              text={t('investigationTask.disclaimer.text')}
              requiredCapabilities={'CreatePropertyInspectionTask'}
              listItem
            />
            <WithCapabilities
              requiredCapabilities={['CreateFraudInspectionTask']}
            >
              <NonExpertTasks />
            </WithCapabilities>
            <BalListItem
              clickable
              onClick={() =>
                navigate(EBRoutes.INVESTIGATION_TASK_WIZARD.template())
              }
            >
              <BalListItemIcon className="min-height-0px">
                <BalIcon name="plus" size="small" />
              </BalListItemIcon>
              <BalListItemContent>
                <BalListItemTitle>{t('home.reportFraud')}</BalListItemTitle>
              </BalListItemContent>
            </BalListItem>
          </BalList>
        </BalCard>
      </WithCapabilities>
      <WithCapabilities
        className="column is-full"
        requiredCapabilities={['CreateVehicleTask']}
        capabilityEnabled={appModuleVisability.vehicleInspection}
      >
        <BalCard className="column  has-text-white is-full p-5" inverted>
          <BalHeading className="p-0" space="none" level="h4" inverted>
            {t('home.motorVehicle')}
          </BalHeading>
          <p>{t('home.motorVehicleDescription')}</p>

          <BalList className="column py-0" background="dark">
            {getEnabledModules().vehicleBonusCorrection && (
              <BalListItem
                clickable
                onClick={() =>
                  navigate(
                    EBRoutes.VEHICLE_BONUS_CORRECTION_TASK_WIZARD.template(),
                  )
                }
              >
                <BalListItemIcon className="min-height-0px">
                  <BalIcon name="plus" size="small" />
                </BalListItemIcon>
                <BalListItemContent>
                  <BalListItemTitle>
                    {t('home.createVehicleBonusCorrection')}
                  </BalListItemTitle>
                </BalListItemContent>
              </BalListItem>
            )}
            {getEnabledModules().riskAssessment && (
              <BalListItem
                clickable
                onClick={() =>
                  navigate(EBRoutes.VEHICLE_RISK_ASSESSMENT_WIZARD.template())
                }
              >
                <BalListItemIcon className="min-height-0px">
                  <BalIcon name="plus" size="small" />
                </BalListItemIcon>
                <BalListItemContent>
                  <BalListItemTitle>
                    {t('home.createVehicleRiskAssessment')}
                  </BalListItemTitle>
                </BalListItemContent>
              </BalListItem>
            )}
            <BalListItem
              clickable
              onClick={() =>
                navigate(EBRoutes.VEHICLE_INSPECTION_TASK_WIZARD.template())
              }
            >
              <BalListItemIcon className="min-height-0px">
                <BalIcon name="plus" size="small" />
              </BalListItemIcon>
              <BalListItemContent>
                <BalListItemTitle>
                  {t('home.createMotorVehicleInspection')}
                </BalListItemTitle>
              </BalListItemContent>
            </BalListItem>
          </BalList>
        </BalCard>
      </WithCapabilities>
      <WithCapabilities
        className="column is-full"
        requiredCapabilities={['CreateAccidentInspectionTask']}
        capabilityEnabled={appModuleVisability.accidentInspection}
      >
        <BalCard
          className="column is-full p-5  has-text-white"
          color="primary"
          inverted
        >
          <BalHeading className="p-0" space="none" level="h4" inverted>
            {t('home.accident')}
          </BalHeading>
          <p>{t('home.accidentDescription')}</p>
          <BalList className="column py-0" background="dark">
            <BalListItem
              clickable
              onClick={() =>
                navigate(EBRoutes.ACCIDENT_INSPECTION_TASK_WIZARD.template())
              }
            >
              <BalListItemIcon>
                <BalIcon name="plus" size="small" />
              </BalListItemIcon>
              <BalListItemContent>
                <BalListItemTitle>
                  {t('home.createAccidentInspection')}
                </BalListItemTitle>
              </BalListItemContent>
            </BalListItem>
          </BalList>
        </BalCard>
      </WithCapabilities>
    </>
  );
};
