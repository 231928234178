import { RequestResult } from './result';
import { Conversion } from './conversions';
import { concat } from '../../utils';
import { getToken } from '../../hooks/useToken';
import { VersionService } from '../../features/version/data/versionService';
import { Buffer } from 'buffer';

//------------------------------------------------------------------------------
// Requests
//------------------------------------------------------------------------------

/**
 * Request function that tries to handle all possible errors
 * that can happen when we make an HTTP request.
 * See `RequestError` for information about what can go wrong.
 *
 * @param request A standard `RequestInfo` as accepted by the browsers `fetch` function.
 * @param fromApi Conversion function from the JSON that the API returns to whatever internal representation we need.
 */
export async function jsonReq<T>(
  request: Request,
  fromApi: <T>(requestJSON: unknown) => Conversion<T>,
  processResults?: (value: T) => void,
): Promise<RequestResult<T>> {
  try {
    const response = await fetch(request);

    const versionService = VersionService.getInstance();
    versionService.setVersion(
      response.headers.get('api-current-version') ?? '',
    );

    if (
      (response.status === 200 && request.method === 'GET') ||
      (response.status === 201 && request.method === 'POST') ||
      (response.status === 200 && request.method === 'PATCH') ||
      (response.status === 200 && request.method === 'PUT')
    ) {
      try {
        const value = await response.json();
        const convertedValue = fromApi<T>(value);
        if (convertedValue.errorType === 'none') {
          if (processResults) {
            processResults(convertedValue.value);
          }
          return {
            status: 'success',
            value: convertedValue.value,
            localValue: convertedValue.value,
          };
        } else {
          return {
            status: 'error',
            errorValue: convertedValue,
          };
        }
      } catch {
        // if we got here there was an error while parsing the resulting json
        try {
          const bodyText = await response.text();
          return {
            status: 'error',
            errorValue: {
              errorType: 'json-parsing-error',
              jsonWithError: bodyText,
            },
          };
        } catch {
          // if we got here then the server never returned a body
          return {
            status: 'error',
            errorValue: {
              errorType: 'missing-body-error',
            },
          };
        }
      }
    } else if (response.status === 200 && request.method === 'DELETE') {
      return {
        status: 'success-no-value',
      };
    } else {
      // the response status wasn't `200`
      const requestInfo = requestInfoToUrlAndMethod(request);
      try {
        const errorTypeFunction = () => {
          switch (response.status) {
            case 401:
              return 'unauthorised-error';
            case 400:
              return 'bad-request-error';
            case 404: {
              return 'not-found-error';
            }
            case 501: {
              return 'not-implemented-error';
            }
            case 502: {
              return 'bad-gateway-error';
            }
            case 503: {
              return 'service-unavailable-error';
            }
            case 408: {
              return 'request-time-out-error';
            }
            default: {
              return 'internal-server-error';
            }
          }
        };
        const errorType = errorTypeFunction();
        const errorValue = await response.json();
        return {
          status: 'error',
          errorValue: {
            errorType: errorType,
            status: response.status,
            statusText: response.statusText,
            url: requestInfo.url,
            method: requestInfo.method,
            debugMessage: errorValue.debugMessage,
            errorKey: errorValue.errorType,
            details: errorValue.details,
            b3: errorValue.b3,
          },
        };
      } catch {
        // an error happened, but we couldn't parse the JSON
        return {
          status: 'error',
          errorValue: {
            errorType: 'error-parsing-error',
          },
        };
      }
    }
  } catch {
    // if we got here we have a connection error
    const requestInfo = requestInfoToUrlAndMethod(request);
    return {
      status: 'error',
      errorValue: {
        errorType: 'connection-error',
        url: requestInfo.url,
        method: requestInfo.method,
      },
    };
  }
}

/**
 * GET version of `jsonReq`.
 * @param url URL for the GET request.
 * @param fromApi Conversion function from the JSON that the API returns to whatever internal representation we need.
 */
export async function jsonGet<T>(
  url: string,
  bearerToken: string,
  processResults?: (value: T) => void,
): Promise<RequestResult<T>> {
  const request = new Request(url, {
    method: 'GET',
    headers: await makeAuthHeaders(bearerToken),
  });
  return jsonReq<T>(request, typeFromApi, processResults);
}

/**
 * GET version of `jsonReq`.
 * @param url URL for the GET request.
 * @param fromApi Conversion function from the JSON that the API returns to whatever internal representation we need.
 */
export async function jsonGetForceClearCache<T>(
  url: string,
  bearerToken: string,
  processResults?: (value: T) => void,
): Promise<RequestResult<T>> {
  const request = new Request(url, {
    method: 'GET',
    headers: await makeAuthHeaders(bearerToken),
  });
  request.headers.append('Clear-Site-Data', '"*"');
  return jsonReq<T>(request, typeFromApi, processResults);
}

/**
 * Extract information from the `RequestInfo` for a better error message.
 */
function requestInfoToUrlAndMethod(request: RequestInfo): {
  url: string;
  method: string;
} {
  return typeof request === 'string'
    ? { method: 'GET', url: request }
    : { method: request.method, url: request.url };
}

export async function makeAuthHeaders(
  bearerToken: string,
  withContentType = true,
): Promise<Record<string, string>> {
  let token = bearerToken;
  if (isTokenExpired(token)) {
    token = await getToken();
  }
  const headers: Record<string, string> = {
    Authorization: concat(['Bearer', token]),
    Accept: 'application/json',
  };
  if (withContentType) {
    headers['Content-Type'] = 'application/json';
  }
  return headers;
}

// Check if token is expired.
const isTokenExpired = (token: string): boolean => {
  if (!token || token.length === 0) {
    return true;
  }
  const tokenPayloadString = Buffer.from(token.split('.')[1], 'base64');
  if (!tokenPayloadString || tokenPayloadString.length < 1) {
    return true;
  }
  const tokenPayload = JSON.parse(tokenPayloadString.toString());
  return new Date(tokenPayload.exp * 1000) < new Date();
};

export async function makeAuthFileHeaders(
  bearerToken: string,
): Promise<Record<string, string>> {
  let token = bearerToken;
  if (isTokenExpired(token)) {
    token = await getToken();
  }
  return {
    Authorization: concat(['Bearer', token]),
    'Access-Control-Expose-Headers': 'Content-Disposition',
  };
}

export async function fileReq(
  request: Request,
  useCache?: boolean,
): Promise<RequestResult<string>> {
  let cachedResponse: Response | undefined;
  if ('caches' in window && useCache) {
    const cacheName = 'bat';
    const cache = await caches.open(cacheName);
    cachedResponse = await cache.match(request.url, {});
    const dateTimeToLive = new Date();
    // cache only for maximum 7 days
    dateTimeToLive.setDate(dateTimeToLive.getDate() - 7);
    if (
      !cachedResponse ||
      Date.parse(cachedResponse.headers.get('date') ?? Date.now().toString()) <
        Date.parse(dateTimeToLive.toString())
    ) {
      cachedResponse = await fetch(request);
      // use clone here to reset body read state
      await cache.put(request.url, cachedResponse.clone());
    }
  }
  const response = cachedResponse ? cachedResponse : await fetch(request);
  if (response.status === 200) {
    const data = await response.blob().then((blob) => {
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    });
    return {
      status: 'success',
      value: data,
      localValue: data,
    };
  } else {
    const requestInfo = requestInfoToUrlAndMethod(request);
    const errorValue = await response.json();

    return {
      status: 'error',
      errorValue: {
        errorType: 'internal-server-error',
        status: response.status,
        statusText: response.statusText,
        url: requestInfo.url,
        method: requestInfo.method,
        debugMessage: errorValue.debugMessage,
        errorKey: errorValue.errorType,
        details: errorValue.details,
        b3: errorValue.b3,
      },
    };
  }
}

export function typeFromApi<T>(t: unknown): Conversion<T> {
  return {
    errorType: 'none',
    value: t as T,
  };
}
