import { Props } from '@baloise/design-system-components/dist/types/props';
import { BalToast } from '@baloise/design-system-components-react';

type ToastNotificationProps = {
  message: string;
  color: Props.BalTostColor;
  onClose?: () => void;
};

export const ToastNotification = ({
  message,
  color,
  onClose,
}: ToastNotificationProps): JSX.Element => {
  return (
    <div className="toast">
      <BalToast
        color={color}
        duration={color === 'success' ? 3000 : 30000}
        onBalClose={onClose}
      >
        {message}
      </BalToast>
    </div>
  );
};
