import {
  BalButton,
  BalIcon,
  BalListItem,
  BalListItemContent,
  BalListItemSubtitle,
} from '@baloise/design-system-components-react';
import { Filter } from './view-menu';
import { DisabableLink } from '../../components/authorization';
import { RequestResult } from '../../data';
import {
  UserDto,
  UserMenuItemDto,
  UserMenuItemTypes,
} from '../../types/resource-models';
import { deleteUserMenuItem, patchUserMenuItem } from '../user/data/requests';
import produce from 'immer';
import { getDefaultVehicleMenuItem } from '../vehicle-task/components/vehicle-task-view-menu-items';
import { useTranslation } from 'react-i18next';
import { CombinedState as BaseTaskCombinedState } from '../base-task';
import { CombinedState as NetworkPartnerCombinedState } from '../network-partners';
import { balModalController } from '../../controller/controllers';
import { concat } from '../../utils';
import { balIconMenuDots } from '@baloise/design-system-icons';
import DeleteConfirmationDialog from '../dialogs/delete-confirmation-dialog';

export type MenuItem = {
  isCustomFilter: boolean;
  type: UserMenuItemTypes;
  label: string;
  filter: Filter;
  iconName?: string;
};

export const ViewMenuItem = ({
  user,
  setUser,
  label,
  filter,
  setLabelAndFilter,
  route,
  combinedState,
  token,
  editFilter,
  setEditFilter,
  userMenuItemType,
}: {
  user: RequestResult<UserDto>;
  setUser: (value: RequestResult<UserDto>) => void;
  label: string;
  filter: Filter;
  setLabelAndFilter: (label: string, filter: Filter) => void;
  route: string;
  isOpen: boolean;
  combinedState: BaseTaskCombinedState | NetworkPartnerCombinedState;
  token: string;
  editFilter: string | undefined;
  setEditFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  userMenuItemType: UserMenuItemTypes;
}): JSX.Element => {
  const { t } = useTranslation();
  const getCurrentMenuItem = (): UserMenuItemDto | undefined => {
    if (user.status === 'success') {
      const customMenuItem = user.value.userMenuItems.find(
        (x) =>
          x.isCustomFilter && x.label === label && x.type === userMenuItemType,
      );
      return customMenuItem;
    }
  };
  const currentItem = getCurrentMenuItem();
  const editCurrentMenuItem = async () => {
    if (user.status === 'success') {
      if (currentItem) {
        const newMenuItem = {
          ...currentItem,
          filter: JSON.stringify(combinedState?.filter),
        };
        const menuItemResult = await patchUserMenuItem(
          currentItem,
          newMenuItem,
          token,
        );
        if (menuItemResult.status === 'success') {
          setUser(
            produce(user, (draftState) => {
              draftState.value.userMenuItems =
                draftState.value.userMenuItems.map((x) => {
                  if (x.id === menuItemResult.value.id) {
                    return menuItemResult.value;
                  } else {
                    return x;
                  }
                });
              draftState.localValue.userMenuItems =
                draftState.value.userMenuItems.map((x) => {
                  if (x.id === menuItemResult.value.id) {
                    return menuItemResult.value;
                  } else {
                    return x;
                  }
                });
            }),
          );
          setLabelAndFilter(
            menuItemResult.value.label,
            JSON.parse(menuItemResult.value.filter),
          );
        }
        if (setEditFilter) setEditFilter(undefined);
      }
    }
  };
  const setInitialMenuItem = () => {
    if (setLabelAndFilter) {
      const menuItem = getDefaultVehicleMenuItem(
        t,
        user.status === 'success' ? user.value : undefined,
      );
      setLabelAndFilter(menuItem.label, menuItem.filter);
    }
  };
  const removeCurrentMenuItem = async () => {
    if (user.status === 'success') {
      const menuItemToDelete = user.value.userMenuItems.find(
        (x) => x.isCustomFilter && (x.id === editFilter || x.label === label),
      );
      if (menuItemToDelete) {
        await deleteUserMenuItem(menuItemToDelete.id, token);
        setUser(
          produce(user, (draftState) => {
            draftState.value.userMenuItems =
              draftState.value.userMenuItems.filter((x) => {
                return !x.isCustomFilter || x.id !== menuItemToDelete.id;
              });
            draftState.localValue.userMenuItems =
              draftState.value.userMenuItems.filter(
                (x) => !x.isCustomFilter || x.label !== combinedState?.label,
              );
          }),
        );
        setEditFilter(undefined);
        setInitialMenuItem();
      }
    }
  };
  const currentMenuItemIsCustom = (): boolean => {
    if (user.status === 'success') {
      const customMenuItem = user.value.userMenuItems.find(
        (x) => x.isCustomFilter && x.label === label,
      );
      return customMenuItem !== undefined;
    } else {
      return false;
    }
  };

  const openModal = async () => {
    const modal = await balModalController.create({
      component: DeleteConfirmationDialog,
      componentProps: {
        title: concat(
          [t('createCustomFilter.deleteFilter', label)],
          'space-hyphen-space',
        ),
        text: t('createCustomFilter.deleteFilterConfirmation'),
        onDelete: async () => {
          await removeCurrentMenuItem();
        },
      },
      cssClass: 'center-modal',
    });
    return modal.present();
  };
  return (
    <>
      <BalListItem
        className="item"
        onClick={() => {
          if (editFilter !== undefined) setEditFilter(undefined);
          if (currentItem?.id !== editFilter || !!!currentItem)
            setLabelAndFilter(label, filter);
        }}
        clickable
      >
        <BalListItemContent>
          <DisabableLink to={route}>
            <BalListItemSubtitle className="list-menu-item">
              {label}
              {currentMenuItemIsCustom() && (
                <div
                  className={`dropdown is-right is-hoverable ${
                    editFilter === currentItem?.id ? 'is-active' : ''
                  }`}
                >
                  <BalIcon svg={balIconMenuDots} size="small" color="info" />
                  <div className="dropdown-menu pl-3 p-0">
                    <div className="dropdown-content p-0">
                      <div className="dropdown-item is-flex is-flex-direction-column p-0">
                        <BalButton
                          className="is-full-width"
                          size="small"
                          color="info-light"
                          icon={
                            editFilter === currentItem?.id ? 'check' : 'edit'
                          }
                          onClick={() => {
                            if (editFilter) {
                              editCurrentMenuItem();
                            } else {
                              setEditFilter(currentItem?.id);
                              setLabelAndFilter(label, filter);
                            }
                          }}
                        >
                          {editFilter === currentItem?.id
                            ? t('general.buttons.saveCustomFilter')
                            : t('general.buttons.editCustomFilter')}
                        </BalButton>
                        <BalButton
                          className="is-full-width"
                          size="small"
                          color="danger"
                          onClick={() => {
                            setEditFilter(currentItem?.id);
                            setTimeout(() => {
                              openModal();
                            }, 100);
                          }}
                          icon="trash"
                        >
                          {t('createCustomFilter.deleteFilter')}
                        </BalButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </BalListItemSubtitle>
          </DisabableLink>
        </BalListItemContent>
      </BalListItem>
    </>
  );
};
