import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useToken } from '../../../hooks';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import { ManagementCard } from './admin-management-card';
import { useImportStateFromApi } from '../data/hooks';
import { postImportFile } from '../../files/data/requests';
import { APIRoutes } from '../../../router/router';
import { ImportStateDto } from '../../../types/resource-models';
import { RequestResult } from '../../../data/fetch/result';

export const GarageCraftsmanManagementCard = (): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const inputFileGarages = useRef<HTMLInputElement>(null);
  const [importGaragesButtonLoadingState, setImportGaragesButtonLoadingState] =
    useState(false);
  const [importGaragesStateFromApi, setImportGragesStateFromApi] =
    useImportStateFromApi('VehicleCraftsmen');

  const onChangedFiles = async (
    route: string,
    setImportLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
    setImportStateFromApi: React.Dispatch<
      React.SetStateAction<RequestResult<ImportStateDto[]>>
    >,
    inputFile: React.RefObject<HTMLInputElement>,
  ) => {
    if (
      inputFile.current &&
      inputFile.current.files &&
      inputFile.current.files.length > 0
    ) {
      setImportStateFromApi({ status: 'loading' });
      setImportLoadingState(true);
      const selectedFile = inputFile.current.files[0];
      postImportFile(route, token, selectedFile).then((result) => {
        if (result.status === 'success') {
          setImportStateFromApi({ status: 'initial' });
          toast(
            ToastNotification({
              message:
                route === APIRoutes.POST_VEHICLE_CRAFTSMEN_IMPORT.create({})
                  ? t('importExport.garagesImportSuccess')
                  : t('importExport.propertiesImportSuccess'),
              color: 'success',
            }),
          );
        } else if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
        if (inputFile.current) {
          inputFile.current.value = '';
        }
        setImportLoadingState(false);
      });
    }
  };

  return (
    <ManagementCard
      title={t('networkPartnersManagement.garagesTitel')}
      description={t('networkPartnersManagement.garagesDescription')}
      importButtonName={t('usersManagement.import')}
      importButtonLoadingState={importGaragesButtonLoadingState}
      inputFile={inputFileGarages}
      onChangedFiles={async () => {
        onChangedFiles(
          APIRoutes.POST_VEHICLE_CRAFTSMEN_IMPORT.create({}),
          setImportGaragesButtonLoadingState,
          setImportGragesStateFromApi,
          inputFileGarages,
        );
      }}
      importStateFromApi={importGaragesStateFromApi}
    />
  );
};
