import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';
import { useToken } from '../../../hooks';
import { getInvestigationTaskJournalHistory } from '../data/requests';
import { ErrorToast } from '../../../components/toast-notification';
import { toast } from 'react-toastify';
import { EntityProtocol } from '../../protocol';
import { JournalChangeDto } from '../../../../../bat-shared/resource-models';

function JournalHistory({ journalId }: { journalId: string }) {
  const token = useToken();
  const { t } = useTranslation();

  const JournalHistoryModalContent = (): JSX.Element => {
    const [journalHistory, setJournalHistory] = useState<JournalChangeDto[]>();
    const [journalCreation, setJournalCreation] = useState<JournalChangeDto>();
    useEffect(() => {
      if (journalHistory === undefined) {
        getInvestigationTaskJournalHistory(token, journalId as string).then(
          async (result) => {
            if (result.status === 'success') {
              setJournalHistory(result.value);
              setJournalCreation(result.value.pop());
            } else if (result.status === 'error') {
              toast(ErrorToast(result.errorValue));
            }
          },
        );
      }
    });
    const date = journalCreation
      ? new Date(journalCreation.dateTime)
      : new Date();
    const formattedDate = new Date(
      date.getTime() + -date.getTimezoneOffset() * 60000,
    ).toLocaleString();
    const { t } = useTranslation();
    return (
      <div className="modal-history">
        <BalModalHeader>
          <BalHeading space="none" level="h3">
            {t('journal.history.title')}
          </BalHeading>
          <BalText className="mb-0" color="primary-light" size="block">
            {journalCreation?.userName}
          </BalText>
          <BalText className="pb-5" color="primary-light" size="small">
            {formattedDate}
          </BalText>
        </BalModalHeader>
        <BalModalBody className="modal-history-body">
          {journalHistory && (
            <EntityProtocol history={journalHistory} maxMessageLength={50} />
          )}
        </BalModalBody>
        <BalButtonGroup position="right">
          <BalButton
            color="info"
            onClick={() => {
              balModalController.dismiss();
            }}
          >
            {t('general.buttons.close')}
          </BalButton>
        </BalButtonGroup>
      </div>
    );
  };
  return (
    <BalButton
      size="small"
      color="info"
      onClick={async () => {
        const modal = await balModalController.create({
          component: JournalHistoryModalContent,
          componentProps: {},
          cssClass: 'center-modal',
          backdropDismiss: false,
        });
        return modal.present();
      }}
    >
      {t('journal.history.title')}
    </BalButton>
  );
}

export default JournalHistory;
