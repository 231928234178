import { DateTime } from 'luxon';
import {
  BaseTaskListDto,
  BaseTaskStatus,
  CompanyDto,
  InsuranceSurveyResponse,
  PartnerAddressDto,
  PersonDto,
  UserDto,
} from '../types/resource-models';
import {
  AppModuleVisibility,
  BaseTask,
  Partner,
  PropertyCraftsman,
  TaskType,
  VehicleCraftsman,
} from '../types/types';
import { v4 as guid } from 'uuid';
import { getEnv } from '../components/ui/env-display';
import {
  concat,
  getBaseTaskStatusColor,
  getEditCapabilityFromType,
  getInsuranceSurveyResponseColor,
} from '../utils';
import { t } from 'i18next';
import { ClaimAddressDto } from '../../../bat-shared/resource-models';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { ILoadingCellRendererParams } from 'ag-grid-community';
import { BalTag, BalText } from '@baloise/design-system-components-react';
import { Avatar } from '../components/ui';
import { formatDateTime } from './date';
import { Dispatch, SetStateAction } from 'react';
import { RequestResult } from '../data';
import BaseTaskPrioritySelectorButton from '../features/base-task/components/base-task-worflow-buttons/base-task-priority-selector-button';
export { guid };

export class Utilities {
  static readonly maskClaimNumber = '00/000000/00.0';
  static readonly maskAccidentClaimNumber = '2|80/000000/00.0';
}

export const getDefaultPersonDto = (): PersonDto => ({
  id: guid(),
  firstName: '',
  lastName: '',
  gender: 'Undisclosed',
  phoneNumber: '',
  preferedContact: 'Phone',
  language: 'De',
  displayName: '',
});

export const getDefaultPerson = (): Partner => ({
  type: 'person',
  person: getDefaultPersonDto(),
});

export const getDefaultCompanyDto = (): CompanyDto => ({
  id: '00000000-0000-0000-0000-000000000000',
  name: '',
  address: {
    partnerId: '00000000-0000-0000-0000-000000000000',
    street: '',
    houseNumber: '',
    zipCode: 0,
    city: '',
    country: 'CH',
  },
  phoneNumber: '',
  email: '',
  preferedContact: 'Phone',
  language: 'De',
  displayName: '',
});

const getDefaultCraftsmanCompanyDto = (): CompanyDto => {
  const id = guid();
  return {
    id: id,
    name: '',
    address: getDefaultAddressWithPartnerId(id),
    phoneNumber: '',
    email: '',
    preferedContact: 'Phone',
    language: 'De',
    displayName: '',
  };
};

export const getDefaultCompany = (): Partner => ({
  type: 'company',
  company: getDefaultCompanyDto(),
});

export const getDefaultAddressWithPartnerId = (
  partnerId: string,
): PartnerAddressDto => ({
  partnerId: partnerId,
  street: '',
  city: '',
  zipCode: undefined,
  country: 'CH',
});

export const getDefaultAddressWithClaimId = (
  claimId: string,
): ClaimAddressDto => ({
  claimId: claimId,
  street: '',
  city: '',
  zipCode: undefined,
  country: 'CH',
});

export const getDefaultPropertyCraftsman = (
  responsibleId: string,
): PropertyCraftsman => {
  const defaultPersonDto = getDefaultPersonDto();
  return {
    type: 'propertyCraftsman',
    propertyCraftsman: {
      id: guid(),
      company: getDefaultCraftsmanCompanyDto(),
      primaryContactId: defaultPersonDto.id,
      contacts: [defaultPersonDto],
      responsibleId: responsibleId,
      partnerNumber: '',
      latitude: 0,
      longitude: 0,
      geoCoordinateState: 'Manual',
      createdAt: DateTime.utc().toString(),
      workCategories: [],
      isDeleted: false,
      isExternalPartner: false,
      isIndependentPropertyCraftsman: false,
    },
  };
};

export const getDefaultVehicleCraftsman = (
  responsibleId: string,
): VehicleCraftsman => {
  const defaultPersonDto = getDefaultPersonDto();
  return {
    type: 'vehicleCraftsman',
    vehicleCraftsman: {
      id: guid(),
      company: getDefaultCraftsmanCompanyDto(),
      primaryContactId: defaultPersonDto.id,
      contacts: [defaultPersonDto],
      responsibleId: responsibleId,
      partnerNumber: '',
      latitude: 0,
      longitude: 0,
      geoCoordinateState: 'Manual',
      vehicleSpecialties: [],
      vehicleBrands: [],
      headquarters: true,
      glassOnly: false,
      createdAt: DateTime.utc().toString(),
      isDeleted: false,
      isExternalPartner: true,
      isIndependentVehicleCraftsman: false,
    },
  };
};

export const getEnabledModules = (): AppModuleVisibility => {
  const stage = getEnv();
  const enabledDuringDevelopment = stage == 'DEV' || stage == 'NONE';
  const enabledDuringIntegration = stage == 'INT';

  return {
    accidentInspection: true,
    propertyInspection: true,
    propertyInspectionTaskJournal: true,
    networkPartner: true,
    investigationInspection: true,
    vehicleInspection: true,
    riskAssessment: true,
    vehicleBonusCorrection: true,
    userManagement:
      false || enabledDuringDevelopment || enabledDuringIntegration,
    resetFilter: false || enabledDuringDevelopment,
    investigationInspectionSentToAdos: true,
    investigationInspectionJournal: true,
    investigationInspectionExpenses: true,
    insuranceSurveys: true,
  };
};

export const getDisplayNameFromUser = (user?: UserDto): string => {
  if (user) {
    return concat([
      user?.displayName,
      concat(['(', user?.personalNumber, ')'], 'none'),
    ]);
  } else {
    return t('taskOverview.unassigned');
  }
};

export const IsNullOrUndefined = (booleanValue?: boolean): boolean => {
  return booleanValue === undefined || booleanValue === null;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (
    phoneNumber.charAt(0) == '0' &&
    (phoneNumber.length == 10 || phoneNumber.length == 13)
  ) {
    return formatPhoneNumberIntl(`+41${phoneNumber.substring(1)}`);
  } else {
    if (isValidPhoneNumber(phoneNumber)) {
      return formatPhoneNumberIntl(phoneNumber);
    } else {
      return phoneNumber;
    }
  }
};

export const renderInsuranceSurveyResponseTag = (
  params: ILoadingCellRendererParams,
): JSX.Element => {
  const response: InsuranceSurveyResponse | undefined = params.data.response as
    | InsuranceSurveyResponse
    | undefined;
  return (
    <div className="is-flex is-align-items-center">
      <BalTag color={getInsuranceSurveyResponseColor(response)} size="small">
        {t(`investigationTask.insuranceSurveyResponses.${response}`) as string}
      </BalTag>
    </div>
  );
};

export const renderBaseTaskStatusTag = (
  params: ILoadingCellRendererParams,
): JSX.Element => {
  const status: BaseTaskStatus | undefined = params.data.status as
    | BaseTaskStatus
    | undefined;
  return (
    <div className="is-flex is-align-items-center">
      <BalTag color={getBaseTaskStatusColor(status)} size="small">
        {t(`baseTaskStatus.${status}`) as string}
      </BalTag>
    </div>
  );
};

export const renderBaseTaskDate = (options: {
  value: string | undefined;
  valueFormatted: string | undefined;
}): JSX.Element => {
  if (options.valueFormatted) {
    return (
      <span className="bal-table-cell-text">
        <BalText space="none">{options.valueFormatted}</BalText>
      </span>
    );
  }
  return (
    <span className="bal-table-cell-text">
      <BalText space="none">{formatDateTime(options.value)}</BalText>
    </span>
  );
};

export const renderBaseTaskAssigneeAvatar = (
  params: ILoadingCellRendererParams,
): JSX.Element => {
  if (params.data.assignee) {
    const name = getDisplayNameFromUser(params.data.assignee);
    return (
      <div className="is-flex is-align-items-center">
        <Avatar
          user={params.data.assignee}
          size="32"
          className="is-flex-shrink-0"
        />
        <span className="bal-table-cell-text ml-2">
          <BalText space="none">{name}</BalText>
        </span>
      </div>
    );
  } else {
    return (
      <span className="bal-table-cell-text">
        <BalText space="none">
          <>{t('taskOverview.unassigned')}</>
        </BalText>
      </span>
    );
  }
};

export const earlybyteTableTextRenderer = (options: {
  value: string | undefined;
}): JSX.Element => {
  return (
    <span className="bal-table-cell-text">
      <BalText space="none">{options.value}</BalText>
    </span>
  );
};

export const RenderPriority = (
  params: ILoadingCellRendererParams,
  taskType: TaskType,
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
) => {
  const task = params.data as BaseTask;
  return (
    <>
      <BaseTaskPrioritySelectorButton
        className="pt-3"
        showEditIcon
        task={task}
        taskType={taskType}
        outlined
        requiredCapabilities={[getEditCapabilityFromType(taskType)]}
        onChangeSuccess={(task: RequestResult<BaseTask>) => {
          setTask && setTask(task as unknown as RequestResult<BaseTaskListDto>);
        }}
      />
      {task.priority && (
        <BalText className="mb-0 pt-1 pl-1 text-overflow-elipsis">
          {t(`baseTaskPriorities.${task.priority}`) as string}
        </BalText>
      )}
    </>
  );
};

export const getValueOrWhitespace = (value?: string | undefined): string => {
  // Workaround for DomException https://github.com/baloise-incubator/design-system/issues/1036
  const whitespace = ' ';
  if (typeof value === 'string') {
    return String(value).length > 0 ? value : whitespace;
  }
  return value === undefined ? whitespace : value;
};

// Removes the special characters '.', '-', '/' and the control digit.
export const cleanPolicyNumber = (value: string): string => {
  if (value.includes('-')) {
    const valueWithoutSpecialCharacters = value.replace(/[-./]/g, '');
    return valueWithoutSpecialCharacters.slice(
      0,
      valueWithoutSpecialCharacters.length - 1,
    );
  }
  return value;
};

// function used to leave the order done by the backend.
export const noComparingComparator = (): number => {
  return 0;
};
