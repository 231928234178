import { RequestResult } from '../../../../data';
import { BaseTaskStatus } from '../../../../types/resource-models';
import {
  BaseTask,
  PropertyInspectionTask,
  TaskTypes,
} from '../../../../types/types';
import { WithCapabilities } from '../../../../components/authorization';
import { useContext } from 'react';
import { UserContext } from '../../../../context';
import { concat } from '../../../../utils';
import { PropertyInspectionTaskRejectModal } from './property-inspection-task-reject-modal';
import { PropertyInspectionTaskReassignModal } from './property-inspection-task-reassign-modal';
import { PropertyInspectionTaskCompletion } from './property-inspection-task-completion';
import { PropertyInspectionTaskInProgressButton } from './property-inspection-task-in-progress-button';
import { getPropertyReport } from '../../../files/data/requests';
import { BaseTaskMenu } from '../../../base-task';
import { BaseTaskDeleteButton } from '../../../base-task/components';
import { BalButton } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EBRoutes } from '../../../../router/router';
import PropertyInspectionTaskInProgressWithIndependentButton from './property-inspection-task-in-progress-with-independent-button';

type PropertyTaskWorkflowButtonsProps = {
  task: PropertyInspectionTask;
  className?: string;
  onChangeSuccess?: (task: RequestResult<PropertyInspectionTask>) => void;
};

const showCloseButtonStates: BaseTaskStatus[] = [
  'InProgressLocal',
  'InProgressVideo',
  'InProgressOffice',
  'InProgressWithPartner',
];
const showAcceptButtonStates: BaseTaskStatus[] = [
  'Assigned',
  'SentToArchive',
  'InProgressLocal',
  'InProgressVideo',
  'InProgressOffice',
  'InProgressWithPartner',
];
const showRejectButtonStates: BaseTaskStatus[] = ['Assigned'];
const showDraftButtonStates: BaseTaskStatus[] = ['New'];
const showReassignButtonStates: BaseTaskStatus[] = [
  'SentToArchive',
  'Assigned',
  'InProgressLocal',
  'InProgressVideo',
  'InProgressOffice',
  'InProgressWithPartner',
  'Rejected',
];
const showMoreButtonStates: BaseTaskStatus[] = [
  'SentToArchive',
  'Assigned',
  'InProgressLocal',
  'InProgressVideo',
  'InProgressOffice',
  'InProgressWithPartner',
  'Rejected',
  'Closed',
];

export const PropertyTaskWorkflowButtons = ({
  task,
  className,
  onChangeSuccess,
}: PropertyTaskWorkflowButtonsProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const isAssignee =
    (user.status === 'success' || user.status === 'loading-with-value') &&
    user.value.id === task.assignee?.id;
  const reportName = `${task.claim.claimNumber}_report.pdf`;

  return (
    <div className={concat(['is-justify-content-space-between', className])}>
      <div>
        {isAssignee && showAcceptButtonStates.includes(task.status) && (
          <WithCapabilities
            requiredCapabilities={['EditPropertyInspectionTask']}
            passWithCapabilitiesPropsToChildren
          >
            <PropertyInspectionTaskInProgressButton
              task={task}
              onChangeSuccess={onChangeSuccess}
              text={
                showCloseButtonStates.includes(task.status)
                  ? t(`baseTaskStatus.${task.status}`)
                  : t('general.buttons.accept')
              }
            />
          </WithCapabilities>
        )}
        {isAssignee && showRejectButtonStates.includes(task.status) && (
          <PropertyInspectionTaskRejectModal
            task={task}
            onRejectSuccess={onChangeSuccess}
          />
        )}
        {showAcceptButtonStates.includes(task.status) && (
          <WithCapabilities
            requiredCapabilities={['EditPropertyInspectionTask']}
            passWithCapabilitiesPropsToChildren
          >
            <PropertyInspectionTaskInProgressWithIndependentButton
              task={task}
              onChangeSuccess={onChangeSuccess}
            />
          </WithCapabilities>
        )}
        {showReassignButtonStates.includes(task.status) && (
          <PropertyInspectionTaskReassignModal
            task={task}
            onReassignSuccess={onChangeSuccess}
          />
        )}
        {isAssignee &&
          showCloseButtonStates.includes(task.status) &&
          onChangeSuccess && (
            <PropertyInspectionTaskCompletion
              task={task}
              onChangeSuccess={onChangeSuccess}
            />
          )}
        {showDraftButtonStates.includes(task.status) &&
          onChangeSuccess &&
          task.deletedAt === null && (
            <>
              <BaseTaskDeleteButton
                className="ml-3"
                task={task}
                taskType={TaskTypes.PropertyInspectionTask}
                onChangeSuccess={onChangeSuccess}
                requiredCapabilities={['EditPropertyInspectionTask']}
              />
              <BalButton
                color="info"
                className="ml-3"
                size="small"
                onClick={(event) => {
                  if (event.detail == 1) {
                    navigate(
                      EBRoutes.PROPERTY_INSPECTION_TASK_WIZARD.create({
                        query: {
                          claimNumber: task.claim.claimNumber
                            .replaceAll('/', '')
                            .replace('.', ''),
                        },
                      }),
                    );
                    navigate(0);
                  }
                }}
              >
                {t('inspectionTask.inspectionTaskWorkflow.toWizard')}
              </BalButton>
            </>
          )}
      </div>
      {showMoreButtonStates.includes(task.status) && (
        <BaseTaskMenu
          task={task}
          reportName={reportName}
          getReport={getPropertyReport}
          taskType={TaskTypes.PropertyInspectionTask}
          onChangeSuccess={(taskResult: RequestResult<BaseTask>) =>
            onChangeSuccess &&
            onChangeSuccess(taskResult as RequestResult<PropertyInspectionTask>)
          }
          showQRCode={
            user.status === 'success' && task.assignee?.id === user.value.id
          }
        />
      )}
    </div>
  );
};
