import {
  BalButtonGroup,
  BalButton,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BalTextareaFormField } from '../../../../components/input';
import { ErrorToast } from '../../../../components/toast-notification';
import { RequestResult } from '../../../../data';
import { useToken } from '../../../../hooks';
import { BaseTask, TaskTypes } from '../../../../types/types';
import { updateBaseTask } from '../../data/requests';
import { CancelButton } from '../../../../components/ui';

export interface BaseTaskMemoFieldsFormProps<BaseTaskType extends BaseTask> {
  task: RequestResult<BaseTaskType>;
  setTask: Dispatch<RequestResult<BaseTaskType>>;
  taskType: TaskTypes;
  onSave?: (task: RequestResult<BaseTaskType>) => void;
  showSaveAndSendButton?: boolean;
  includeWhatField?: boolean;
}
export const BaseTaskMemoFieldsForm = <BaseTaskType extends BaseTask>({
  task,
  setTask,
  taskType,
  onSave,
  showSaveAndSendButton = true,
  includeWhatField = true,
}: BaseTaskMemoFieldsFormProps<BaseTaskType>): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const methods = useForm({
    mode: 'onChange',
  });
  const { handleSubmit, formState } = methods;
  const [saving, setSaving] = useState<boolean>(false);
  const [localTask, setLocalTask] = useState<RequestResult<BaseTaskType>>(task);

  const [internalNote, setInternalNote] = useState<string | undefined>(
    task.status === 'success' ? task.localValue.internalNote : undefined,
  );

  useEffect(() => {
    if (
      localTask.status === 'success' &&
      localTask.localValue.internalNote !== internalNote
    ) {
      setLocalTask(
        produce(localTask, (draft) => {
          draft.localValue.internalNote = internalNote;
        }),
      );
    }
  }, [localTask, setLocalTask, internalNote]);

  const onSubmit = async (saveAndSend: boolean) => {
    if (localTask.status === 'success') {
      if (!saving) {
        setSaving(true);
        const result = await updateBaseTask(
          localTask.value,
          localTask.localValue,
          taskType,
          token,
          saveAndSend,
        );
        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        } else {
          setTask(result);
          if (onSave) {
            onSave(task);
          }
        }
        setSaving(false);
      }
    }
  };

  return localTask.status === 'success' ? (
    <form
      onSubmit={handleSubmit(async () => {
        await onSubmit(false);
      })}
    >
      <div className="rows is-multiline is-gapless is-full-width mt-1">
        {includeWhatField && (
          <BalTextareaFormField
            controllerName="what"
            methods={methods}
            className="row"
            placeholder={t('general.claim.whatHappened')}
            title={
              taskType === TaskTypes.AccidentInspectionTask
                ? t('accidentInspectionTask.claimInformation.cardTitle')
                : taskType === TaskTypes.InvestigationTask
                ? t('investigationTask.suspicion')
                : taskType === TaskTypes.VehicleTask
                ? t('vehicleTask.overlay.accidentCourse')
                : t('claimDetail.claimDescription')
            }
            value={localTask.localValue.what}
            onBalChange={(e: CustomEvent<string | undefined | null>) => {
              setLocalTask(
                produce(localTask, (draftState) => {
                  draftState.localValue.what = e.detail ?? '';
                }),
              );
            }}
            required={
              taskType === TaskTypes.VehicleTask
                ? false
                : t('error.mandatoryField')
            }
          />
        )}
        <BalTextareaFormField
          controllerName="remarks"
          methods={methods}
          className="row"
          placeholder={t('general.claim.remarks')}
          title={t('general.claim.remarks')}
          value={localTask.localValue.remarks}
          onBalChange={(e: CustomEvent<string | undefined | null>) => {
            setLocalTask(
              produce(localTask, (draftState) => {
                draftState.localValue.remarks = e.detail ?? '';
              }),
            );
          }}
          required={false}
        />
        <BalTextareaFormField
          controllerName="internalNote"
          methods={methods}
          required={false}
          className="row"
          richText
          richTextHeight="175px"
          placeholder={t('baseTaskDtoColumns.internalNote')}
          title={t('baseTaskDtoColumns.internalNote')}
          value={internalNote}
          onChange={(text: string) => {
            setInternalNote(text);
          }}
        />
      </div>
      <div>
        <BalButtonGroup position="right">
          <CancelButton />
          <BalButton
            elementType="submit"
            color="info"
            disabled={
              !formState.isValid && Object.keys(formState.errors).length !== 0
            }
          >
            {t('general.buttons.save')}
          </BalButton>
          {showSaveAndSendButton && (
            <BalButton
              color="info"
              disabled={
                !formState.isValid && Object.keys(formState.errors).length !== 0
              }
              onClick={async () => {
                await onSubmit(true);
              }}
            >
              {t('general.buttons.saveAndSend')}
            </BalButton>
          )}
        </BalButtonGroup>
      </div>
    </form>
  ) : (
    <></>
  );
};
