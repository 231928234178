import {
  BalButton,
  BalModalHeader,
  BalModalBody,
  BalHeading,
  BalText,
  BalButtonGroup,
} from '@baloise/design-system-components-react';
import { Partner, AffectedPartner } from '../../../types/types';
import { useTranslation } from 'react-i18next';

import { balModalController } from '../../../controller/controllers';
import { ContactDetailInformation } from './contact-detail-information';
// import _ from 'lodash';

const DeleteContactModal = ({
  onDelete,
  affectedPartner,
  setContactDeleted,
}: {
  onDelete: (partner: Partner) => void;
  affectedPartner: AffectedPartner;
  setContactDeleted: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <BalModalHeader>
        <BalHeading level="h3">
          {t('general.contact.deleteContactTitle')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>{t('general.contact.deleteContact')}</BalText>
        <ContactDetailInformation
          contactPartner={affectedPartner}
        ></ContactDetailInformation>
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          {onDelete && (
            <BalButton
              color="primary"
              onClick={(event) => {
                if (event.detail == 1) {
                  onDelete(affectedPartner.partner);
                  balModalController.dismiss();
                  setContactDeleted(true);
                }
              }}
            >
              {t('general.buttons.delete')}
            </BalButton>
          )}
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export default DeleteContactModal;
