import { BalButton, BalIcon } from '@baloise/design-system-components-react';
import { balIconSearch, balIconEdit } from '@baloise/design-system-icons';
import { t } from 'i18next';
import { useCallback, useContext } from 'react';
import lang from 'suneditor-react/dist/types/lang';
import { WithCapabilities } from '../../../../../../components/authorization';
import { UserContext } from '../../../../../../context';
import { balModalController } from '../../../../../../controller/controllers';
import { useToken } from '../../../../../../hooks';
import { InsuranceSurveyListDto } from '../../../../../../types/resource-models';
import { getInsuranceSurvey } from '../../data/requests';
import { InsuranceSurveyEditFormModal } from '../insurance-survey-edit-form-modal';

export interface InsuranceSurveyEditButtonProps {
  currentInsuranceSurvey: InsuranceSurveyListDto | undefined;
  onEditInsuranceSurvey: (insuranceSurveyId?: string) => void;
  readonly?: boolean;
  onDeleteInsuranceSurvey: (
    insuranceSurvey: InsuranceSurveyListDto,
  ) => Promise<void>;
}

const InsuranceSurveyEditButton = ({
  currentInsuranceSurvey,
  onEditInsuranceSurvey,
  readonly,
  onDeleteInsuranceSurvey,
}: InsuranceSurveyEditButtonProps): JSX.Element => {
  const token = useToken();
  const { user } = useContext(UserContext);
  const getLanguage = useCallback((): lang => {
    let result: lang = 'de';
    if (user.status === 'success') {
      result = user.localValue.language.toLowerCase() as lang;
    }
    return result;
  }, [user]);

  const openEditModal = async () => {
    if (currentInsuranceSurvey) {
      const insuranceSurveyToEdit = await getInsuranceSurvey(
        token,
        currentInsuranceSurvey.id,
      );
      if (insuranceSurveyToEdit.status === 'success') {
        const modal = await balModalController.create({
          component: InsuranceSurveyEditFormModal,
          modalWidth: 1300,
          componentProps: {
            title: `${t('general.buttons.edit')} ${t(
              'investigationTask.insuranceSurvey.entityName',
            )}`,
            initialValue: insuranceSurveyToEdit,
            initialAddinState: false,
            language: getLanguage(),
            onSave: onEditInsuranceSurvey,
            onDeleteInsuranceSurvey: onDeleteInsuranceSurvey,
            readonly,
          },
          cssClass: 'center-modal',
          backdropDismiss: false,
          isClosable: false,
        });
        return modal.present();
      }
    }
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        className="ml-2"
        outlined
        square
        size="small"
        color="info"
        onClick={(event) => event.detail == 1 && openEditModal()}
      >
        <BalIcon svg={readonly ? balIconSearch : balIconEdit} size="small" />
      </BalButton>
    </WithCapabilities>
  );
};

export default InsuranceSurveyEditButton;
