import { BalTabItem, BalTabs } from '@baloise/design-system-components-react';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultCompanyDto, Utilities } from '../utils/utilities';
import { APIRoutes, EBRoutes } from '../router/router';
import { useToken, useCallbackPrompt } from '../hooks';
import { toast } from 'react-toastify';
import {
  ErrorToast,
  ToastNotification,
} from '../components/toast-notification';
import { WithCapabilities } from '../components/authorization';
import { BlockNavigation } from '../components/navigation';
import { TaskWizardButtons } from '../components/ui';
import {
  getVehicleTask,
  patchVehicleTask,
  useVehicleTask,
  VehicleTaskClaimDamageForm,
  VehicleTaskOverlay,
} from '../features/vehicle-task';
import {
  ClaimNumberInputCard,
  TaskInformationCards,
} from '../features/base-task';
import produce from 'immer';
import { FetchSuccess, RequestResult } from '../data/fetch/result';
import { TabType, TaskTypes, VehicleTask } from '../types/types';
import { getTask, postVehicleTask } from '../features/base-task/data/requests';
import { apiUrl } from '../data/fetch/base';
import { BaseTaskListDto, CompanyDto } from '../types/resource-models';
import VehicleTaskCompanyContactsCard from '../features/vehicle-task/components/vehicle-task-company-contact';
import { validateAffectedPartnerVehicle } from '../features/services';
import { VehicleTaskCompleteTaskCards } from '../features/vehicle-task/components/vehicle-task-complete-task-cards';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { VehicleTaskWizardContactsCards } from '../features/vehicle-task/components/vehicle-task-wizard-contacts-cards';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { balModalController } from '../controller/controllers';
import { BaseTaskManualCreationModalForm } from '../features/base-task/components/base-task-manual-creation-modal-form';
import { RequestError } from '../data';
import { BadRequestError } from '../data/fetch/errors';

const VehicleInspectionTaskWizardView = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const taskIdQueryParam = searchParams.get('taskId');
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabVehicleClaimNumber = useRef<HTMLBalTabItemElement>(null);
  const refTabVehicleCompanyContact = useRef<HTMLBalTabItemElement>(null);
  const refTabContactInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabVehicleDamage = useRef<HTMLBalTabItemElement>(null);
  const refTabVehicleClaimInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabCompleteVehicleClaim = useRef<HTMLBalTabItemElement>(null);
  const [vehicleTask, setVehicleTask] = useVehicleTask();
  const [tab, setTab] = useState<TabType>('vehicleClaimNumber');
  const [claimNumber, setClaimNumber] = useState<string>('');
  const [selectedPartner, setSelectedPartner] = useState<CompanyDto>(
    getDefaultCompanyDto(),
  );
  const navigate = useNavigate();
  const [selectedCraftsmanId, setSelectedCraftsmanId] = useState<
    string | undefined
  >(undefined);

  const [existingGarageToggle, setExistingGarageToggle] =
    useState<boolean>(false);
  const token = useToken();
  const patchTask = (
    tab: TabType,
    refNextTab: RefObject<HTMLBalTabItemElement>,
  ) => {
    setVehicleTask({ status: 'loading' });
    if (vehicleTask.status === 'success') {
      patchVehicleTask(vehicleTask.value, vehicleTask.localValue, token).then(
        (inspectionTaskResponse) => {
          setVehicleTask(inspectionTaskResponse);
          if (inspectionTaskResponse.status === 'success') {
            refNextTab.current
              ?.getOptions()
              .then((options) => refTabs.current?.select(options));
            setTab(tab);
          } else if (inspectionTaskResponse.status === 'error') {
            toast(ErrorToast(inspectionTaskResponse.errorValue));
          }
        },
      );
    }
  };

  const openManualCreationModal = async (claimNumber: string) => {
    const modal = await balModalController.create({
      component: BaseTaskManualCreationModalForm,
      modalWidth: 750,
      componentProps: {
        claimNumber,
        taskType: TaskTypes.VehicleTask,
        garage: selectedPartner,
        kind: 'VehicleInspectionTask',
        onCreateTaskSuccess,
        onCancel: onCreateTaskCancel,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  const onCreateTaskSuccess = (response: FetchSuccess<VehicleTask>) => {
    setVehicleTask(response);
    if (refTabContactInformation) {
      refTabContactInformation.current
        ?.getOptions()
        .then((options) => refTabs.current?.select(options));
    }
    setTab('contactInformation');
  };

  const onCreateTaskCancel = () => {
    setSelectedPartner(getDefaultCompanyDto());
    setExistingGarageToggle(false);
    setVehicleTask({
      status: 'initial',
    });
    setTab('vehicleClaimNumber');
  };

  const createOrFetchTask = (claimNumber: string) => {
    setVehicleTask({ status: 'loading' });
    postVehicleTask(
      {
        claimNumber: claimNumber,
        kind: 'VehicleInspectionTask',
        garage: selectedPartner,
        assigneeGarageCraftsmanId: selectedCraftsmanId,
      },
      token,
      `${apiUrl}${APIRoutes.POST_VEHICLE_TASK.create({})}`,
    ).then((taskResponse) => {
      if (taskResponse.status === 'success') {
        onCreateTaskSuccess(taskResponse);
      } else if (taskResponse.status === 'error') {
        if (
          (taskResponse.errorValue as RequestError).errorType ===
            'bad-request-error' &&
          (taskResponse.errorValue as BadRequestError).errorKey ===
            'CloneClaimClosedError'
        ) {
          toast(ErrorToast(taskResponse.errorValue));
          onCreateTaskCancel();
        } else {
          openManualCreationModal(claimNumber);
        }
      }
    });
  };

  const onEditTask = async (baseTaskid: string) => {
    setVehicleTask({ status: 'loading' });
    const taskResponse = await getTask<VehicleTask>(
      token,
      baseTaskid,
      TaskTypes.VehicleTask,
    );
    setVehicleTask(taskResponse);
  };

  const createOrFetchTaskCallback = useCallback(
    (taskId: string) => {
      if (token) {
        setVehicleTask({ status: 'loading' });
        getTask<VehicleTask>(token, taskId, TaskTypes.VehicleTask).then(
          (taskResponse) => {
            if (taskResponse.status === 'success') {
              setVehicleTask(taskResponse);
              refTabContactInformation.current
                ?.getOptions()
                .then((options) => refTabs.current?.select(options));
              setTab('contactInformation');
            } else if (taskResponse.status === 'error') {
              toast(ErrorToast(taskResponse.errorValue));
              setVehicleTask({ status: 'initial' });
            }
          },
        );
      }
    },
    [setVehicleTask, token],
  );

  useEffect(() => {
    if (taskIdQueryParam) {
      createOrFetchTaskCallback(taskIdQueryParam);
    }
  }, [taskIdQueryParam, createOrFetchTaskCallback, setVehicleTask]);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    tab !== 'vehicleClaimNumber' &&
      tab !== 'none' &&
      vehicleTask.status === 'success' &&
      vehicleTask.value.status === 'New',
  );
  useEffect(() => {
    if (vehicleTask.status === 'success') {
      if (vehicleTask.value.status !== 'New') {
        toast(
          ToastNotification({
            message: t('general.taskNotInNewState'),
            color: 'warning',
          }),
        );
        navigate(
          EBRoutes.VEHICLE_INSPECTION_TASK_DETAIL.create({
            taskId: vehicleTask.value.id,
          }),
        );
      }
    }
    // when adding navigate the naviation block is not working anymore.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTask, t]);
  return (
    <WithCapabilities
      requiredCapabilities={['CreateVehicleTask']}
      showErrorToast
    >
      {vehicleTask.status === 'success' ? (
        <>
          <VehicleTaskOverlay
            task={vehicleTask.value as unknown as BaseTaskListDto}
            tab={tab}
            getFullTask={getVehicleTask}
            hideLinkButtons
          />
          <BlockNavigation
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            taskType={TaskTypes.VehicleTask}
            taskId={vehicleTask.value.id}
          />
        </>
      ) : (
        <BlockNavigation
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
      )}
      <div className="columns wizard is-gapless is-full-height is-full-width scroll-bar-minimal-width-adjust">
        <div className="column is-11 is-flex wizard-max-width-1280px mr-3">
          <BalTabs
            interface="o-steps"
            ref={refTabs}
            value={tab}
            clickable={false}
          >
            <BalTabItem
              value="vehicleClaimNumber"
              label={t('vehicleTask.vehicleClaimNumber.tabTitle')}
              ref={refTabVehicleClaimNumber}
            >
              <ClaimNumberInputCard
                setClaimNumber={setClaimNumber}
                task={vehicleTask}
                setTask={setVehicleTask}
                refTabs={refTabs}
                refNextTab={refTabContactInformation}
                setTab={(tabType?: TabType) =>
                  setTab(tabType ?? 'vehicleCompanyContactInformation')
                }
                title={t('vehicleTask.vehicleClaimNumber.cardTitle')}
                text={t('vehicleTask.vehicleClaimNumber.cardText')}
                pattern={/^[1345679]\d\d{8}[[0-9X]{1}$/}
                taskButtons={
                  <TaskWizardButtons refTabs={refTabs} hasBack={false} />
                }
                isVehicleTask
                placeholder={Utilities.maskClaimNumber}
                onSearchAction={() => {
                  refTabContactInformation.current
                    ?.getOptions()
                    .then((options) => refTabs.current?.select(options));
                  setTab('vehicleCompanyContactInformation');
                }}
                onEditTask={onEditTask}
                onCreateNew={(task?: RequestResult<VehicleTask>) => {
                  if (task) {
                    if (task.status === 'success') {
                      patchVehicleTask(
                        task.value,
                        produce(task.value, (draftState) => {
                          if (task.value.status === 'New') {
                            draftState.deletedAt = new Date().toISOString();
                          } else {
                            draftState.status = 'Closed';
                          }
                        }),
                        token,
                      ).then((result) => {
                        if (result.status === 'success') {
                          setTab('vehicleCompanyContactInformation');
                        } else if (result.status === 'error') {
                          toast(ErrorToast(result.errorValue));
                        }
                      });
                    }
                  } else {
                    setTab('vehicleCompanyContactInformation');
                  }
                }}
                taskType={TaskTypes.VehicleTask}
              />
            </BalTabItem>
            <BalTabItem
              value="vehicleCompanyContactInformation"
              label={t('vehicleTask.vehicleCompany.company')}
              ref={refTabVehicleCompanyContact}
            >
              <VehicleTaskCompanyContactsCard
                claimNumber={claimNumber}
                task={vehicleTask}
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
                setSelectedCraftsmanId={setSelectedCraftsmanId}
                existingPartnerToggle={existingGarageToggle}
                setExistingPartnerToggle={setExistingGarageToggle}
                refTabcontactInformation={refTabContactInformation}
                createNewTask={createOrFetchTask}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    hasBack={true}
                    refPrevTab={refTabVehicleClaimNumber}
                    setPrevTab={() => {
                      setSelectedPartner(getDefaultCompanyDto());
                      setExistingGarageToggle(false);
                      setTab('vehicleClaimNumber');
                    }}
                    disabled={
                      !existingGarageToggle &&
                      (selectedPartner.name === '' ||
                        selectedPartner.address?.street === '' ||
                        selectedPartner.address?.houseNumber === '' ||
                        selectedPartner.address?.zipCode === 0 ||
                        selectedPartner.address?.city === '' ||
                        !isValidPhoneNumber(
                          selectedPartner.phoneNumber.replaceAll(' ', ''),
                        ))
                    }
                  />
                }
              />
            </BalTabItem>
            <BalTabItem
              value="contactInformation"
              label={t('vehicleTask.vehicleContact.contact')}
              ref={refTabContactInformation}
              className="pl-4"
            >
              <VehicleTaskWizardContactsCards
                task={vehicleTask}
                setTask={setVehicleTask}
                patchTask={patchVehicleTask}
              />
              <TaskWizardButtons
                refTabs={refTabs}
                hasBack={false}
                onClickNext={() =>
                  patchTask('vehicleTaskClaimDamageForm', refTabVehicleDamage)
                }
                disabled={
                  vehicleTask.status === 'success' &&
                  (vehicleTask.localValue.claim.affectedPartners.some(
                    (affectedPartner) => {
                      return !validateAffectedPartnerVehicle(affectedPartner);
                    },
                  ) ||
                    vehicleTask.localValue.claim.affectedPartners.filter(
                      (x) => x.role == 'AffectedThirdParty',
                    ).length > 1)
                }
              />
            </BalTabItem>
            <BalTabItem
              value="vehicleTaskClaimDamageForm"
              label={t('vehicleTask.vehicleClaimDamage.title')}
              ref={refTabVehicleDamage}
            >
              <VehicleTaskClaimDamageForm
                vehicleTask={vehicleTask}
                setVehicleTask={setVehicleTask}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabContactInformation}
                    setPrevTab={() => setTab('contactInformation')}
                  />
                }
                patchTask={() =>
                  patchTask(
                    'vehicleClaimInformation',
                    refTabVehicleClaimInformation,
                  )
                }
              />
            </BalTabItem>
            <BalTabItem
              value="vehicleClaimInformation"
              label={t('vehicleTask.vehicleClaimInformation.tabTitle')}
              ref={refTabVehicleClaimInformation}
            >
              <TaskInformationCards
                taskProps={{
                  task: vehicleTask,
                  setTask: setVehicleTask,
                  type: TaskTypes.VehicleTask,
                }}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabVehicleDamage}
                    setPrevTab={() => setTab('vehicleTaskClaimDamageForm')}
                  />
                }
                patchTask={() =>
                  patchTask('completeVehicleClaim', refTabCompleteVehicleClaim)
                }
                title={t('vehicleTask.vehicleClaimInformation.cardTitle')}
              />
            </BalTabItem>
            <BalTabItem
              value="completeVehicleClaim"
              label={t('vehicleTask.completeVehicleClaim.tabTitle')}
              ref={refTabCompleteVehicleClaim}
            >
              <VehicleTaskCompleteTaskCards
                task={vehicleTask}
                setTask={setVehicleTask}
                patchTask={patchVehicleTask}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabVehicleClaimInformation}
                    nextText={t(
                      'vehicleTask.completeVehicleClaim.submitInspectionButton',
                    )}
                    onClickBack={() => {
                      if (
                        vehicleTask.status === 'success' &&
                        !vehicleTask.localValue
                          .assigneeGarageCraftsmanContactMethod
                      ) {
                        setVehicleTask(
                          produce(vehicleTask, (draftState) => {
                            draftState.localValue.assigneeGarageCraftsmanRemarks =
                              undefined;
                          }),
                        );
                      }

                      setTab('vehicleClaimInformation');
                    }}
                  />
                }
                inspectorAppCapabilitiy={[
                  'EditVehicleTask',
                  'ReadOwnedVehicleTask',
                ]}
                setTab={setTab as Dispatch<SetStateAction<string>>}
              />
            </BalTabItem>
          </BalTabs>
        </div>
      </div>
    </WithCapabilities>
  );
};

export default VehicleInspectionTaskWizardView;
