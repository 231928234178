import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import { Globals, guid } from '../../../../utils';
import PropertyInspectionTaskRecipientEditFormModal from './property-inspection-task-recipient-edit-form-modal';

export interface PropertyInspectionTaskRecipientsAddButtonsProps {
  taskId: string;
  onAddRecipient: () => void;
}

const PropertyInspectionTaskRecipientAddButton = ({
  taskId,
  onAddRecipient,
}: PropertyInspectionTaskRecipientsAddButtonsProps): JSX.Element => {
  const openAddModal = async () => {
    const recipientToAdd = {
      ...Globals.propertyInspectionTaskRecipientDefault,
      id: guid(),
      propertyInspectionTaskId: taskId,
    };

    const modal = await balModalController.create({
      component: PropertyInspectionTaskRecipientEditFormModal,
      modalWidth: 800,
      componentProps: {
        title: `${t('general.buttons.create')} ${t(
          'inspectionTask.recipient.entityName',
        )}`,
        initialValue: recipientToAdd,
        initialAddingState: true,
        onSave: onAddRecipient,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditPropertyInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        className="ml-2"
        square
        outlined
        size="small"
        color="info"
        icon="plus"
        onClick={(event) => event.detail == 1 && openAddModal()}
      />
    </WithCapabilities>
  );
};

export default PropertyInspectionTaskRecipientAddButton;
