import '../../styles/general.scss';
import {
  Controller,
  FieldValues,
  UseFormReturn,
  ValidationRule,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BalSelectField from './bal-select-field';
import { TranslatedSelection } from '../../types/types';

type BalSelectFormFieldProps = {
  className?: string;
  titleClassName?: string;
  controllerName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<FieldValues, object>;
  title?: string;
  placeholder?: string;
  value?: string;
  values: TranslatedSelection[];
  onChange?: (e: CustomEvent | undefined) => void;
  onInput?: (e: CustomEvent<string | undefined>) => void;
  onFocus?: () => void;
  onKeyPress?: (e: CustomEvent<KeyboardEvent>) => void;
  required?: string | ValidationRule<boolean>;
  disabled?: boolean;
  alphabeticallySorted?: boolean;
};

const BalSelectFormField = (props: BalSelectFormFieldProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Controller
      name={props.controllerName}
      control={props.methods.control}
      defaultValue={props.value}
      rules={{
        required:
          props.required === undefined
            ? t('error.mandatoryField')
            : props.required,
      }}
      render={({ field, fieldState }) => (
        <BalSelectField
          {...field}
          {...fieldState}
          className={props.className}
          title={props.title}
          placeholder={
            props.required === undefined || props.required !== false
              ? `${props.placeholder} *`
              : props.placeholder
          }
          value={props.value}
          values={props.values}
          onInput={props.onInput}
          onFocus={props.onFocus}
          onKeyPress={props.onKeyPress}
          disabled={props.disabled}
          alphabeticallySorted={props.alphabeticallySorted}
          message={fieldState.error?.message}
          onChange={(e) => {
            if (e.detail) {
              props.onChange && props.onChange(e);
              field.onChange(e.detail);
            }
          }}
        />
      )}
    />
  );
};

export default BalSelectFormField;
