import {
  ColDef,
  GridOptions,
  ILoadingCellRendererParams,
} from 'ag-grid-community';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import '../../../styles/general.scss';
import {
  BaseTaskListDto,
  PreferedContact,
  UserTableSettingDto,
  UserTableTypes,
  VehicleTaskListDto,
} from '../../../types/resource-models';
import { TFunction, useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import {
  getNameFromPartner,
  TaskTypes,
  VehicleTask,
} from '../../../types/types';
import { BalIcon, BalText } from '@baloise/design-system-components-react';
import {
  WithCapabilities,
  DisabableLink,
} from '../../../components/authorization';
import { EBRoutes } from '../../../router/router';
import { formatDateTime } from '../../../utils/date';
import { Avatar } from '../../../components/ui';
import { balModalController } from '../../../controller/controllers';
import { TableSettingsForm } from '../../user/components/table-settings-form';
import { buildGridOptions } from '../../base-task/components/base-task-search-form/base-task-search-form-table-grid-options';
import { balIconSettings } from '@baloise/design-system-icons';
import {
  earlybyteTableTextRenderer,
  getDisplayNameFromUser,
  noComparingComparator,
  renderBaseTaskAssigneeAvatar,
  renderBaseTaskDate,
  renderBaseTaskStatusTag,
  RenderPriority,
} from '../../../utils/utilities';
import { RequestResult } from '../../../data';
import { EditQuickNotesModal } from './edit-quick-notes-modal';

export const getVehicleDefaultColDefs = (
  t: TFunction,
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): ColDef[] => {
  return [
    {
      field: 'tableSettingsButton',
      cellRenderer: 'overviewButton',
      width: 50,
      headerComponent: 'tableSettingsButton',
      maxWidth: 50,
      minWidth: 50,
      resizable: false,
      sortable: false,
    },
    {
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).createdAt;
      },
      valueFormatter: (params) => {
        const date = params.value;
        return formatDateTime(date);
      },
      comparator: noComparingComparator,
      field: 'createdAt',
      headerName: t('taskOverview.createdAt'),
      cellRenderer: 'dateRenderer',
    },
    {
      valueGetter: (params) => {
        const insuranceTpe = (params.data as BaseTaskListDto).claim
          .insuranceType;
        return t(`insuranceType.${insuranceTpe}`);
      },
      headerName: t('taskOverview.insuranceType'),
      comparator: noComparingComparator,
      field: 'claim.insuranceType',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as VehicleTask).affectedVehicle?.plate,
      headerName: t('taskOverview.licensePlate'),
      comparator: noComparingComparator,
      field: 'affectedVehicle.plate',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => (params.data as VehicleTask).appointmentEarliest,
      valueFormatter: (params) => {
        const date = params.value;
        return formatDateTime(date);
      },
      headerName: t('baseTaskListDtoColums.appointmentEarliest'),
      comparator: noComparingComparator,
      field: 'appointmentEarliest',
      cellRenderer: 'dateRenderer',
    },
    {
      valueGetter: (params) => {
        const date = (params.data as BaseTaskListDto).claim.claimDate;
        return date;
      },
      valueFormatter: (params) => {
        const date = params.value;
        return formatDateTime(date);
      },
      field: 'claim.claimDate',
      headerName: t('taskOverview.accidentDate'),
      comparator: noComparingComparator,
      cellRenderer: 'dateRenderer',
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimNumber,
      headerName: t('general.claim.claimNumber'),
      comparator: noComparingComparator,
      field: 'claim.claimNumber',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.policyNumber,
      headerName: t('general.claim.policyNumber'),
      comparator: noComparingComparator,
      field: 'claim.policyNumber',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        t(
          `insuranceType.${
            (params.data as BaseTaskListDto).claim.insuranceType
          }`,
        ),
      headerName: t('claimDetail.insuranceType'),
      comparator: noComparingComparator,
      field: 'claim.insuranceType',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        return t(
          `vehicleTask.VehicleTaskKinds.${
            (params.data as VehicleTaskListDto).kind
          }`,
        );
      },
      headerName: t('vehicleTask.kind'),
      comparator: noComparingComparator,
      field: 'Kind',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const policyHolder = (params.data as VehicleTask).claim.policyHolder;

        return policyHolder
          ? policyHolder.type === 'person'
            ? policyHolder.person.displayName
            : policyHolder.company.displayName
          : '';
      },
      headerName: t('taskOverview.policyHolder'),
      comparator: noComparingComparator,
      field: 'claim.policyHolder.displayName',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimAddress?.addressString ??
        '',
      headerName: t('general.address'),
      comparator: noComparingComparator,
      hide: true,
      field:
        'claim.claimAddress.street,claim.claimAddress.houseNumber,claim.claimAddress.street,claim.claimAddress.city',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimAddress
          ?.addressStreetString ?? '',
      headerName: t('general.streetAndNumber'),
      comparator: noComparingComparator,
      field: 'claim.claimAddress.street,claim.claimAddress.houseNumber',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimAddress?.zipCode ?? '',
      headerName: t('general.zipCode'),
      comparator: noComparingComparator,
      field: 'claim.claimAddress.zipCode',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimAddress?.city ?? '',
      headerName: t('general.city'),
      comparator: noComparingComparator,
      field: 'claim.claimAddress.city',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      cellRenderer: 'createdByRenderer',
      headerName: t('taskOverview.createdBy'),
      comparator: noComparingComparator,
      field: 'creator.displayName',
    },
    {
      cellRenderer: 'avatarRenderer',
      headerName: t('assignee.assigneeVehicle'),
      comparator: noComparingComparator,
      field: 'assignee.displayName',
    },
    {
      field: 'assignee.phoneNumber',
      headerName: t('baseTaskListDtoColums.assigneePhoneNumber'),
      comparator: noComparingComparator,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'assignee.insuranceCenter.name',
      headerName: t('baseTaskListDtoColums.assigneeInsuranceCenter'),
      comparator: noComparingComparator,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'assigneeGarage.name',
      headerName: t('vehicleTask.assigneeGarageName'),
      comparator: noComparingComparator,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'assigneeExternal.name',
      headerName: t('vehicleTask.independentPartner'),
      comparator: noComparingComparator,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'assignee.personalNumber',
      headerName: t('baseTaskListDtoColums.assigneePersonalNumber'),
      comparator: noComparingComparator,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'assignedAt',
      headerName: t('baseTaskListDtoColums.assignedAt'),
      comparator: noComparingComparator,
      hide: true,
      valueGetter: (params) => (params.data as BaseTaskListDto).assignedAt,
      valueFormatter: (params) => {
        const date = params.value;
        if (date != null) {
          return formatDateTime(date);
        } else {
          return t('taskOverview.unassigned');
        }
      },
      cellRenderer: 'dateRenderer',
    },
    {
      field: 'contact.role',
      headerName: t('baseTaskListDtoColums.contactRole'),
      comparator: noComparingComparator,
      hide: true,
      valueGetter: (params) => {
        const role = (params.data as BaseTaskListDto).contact?.role;
        return role ? t(`partnerRole.${role}`) : '';
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'contact.partner.partner_type',
      headerName: t('baseTaskListDtoColums.contactType'),
      comparator: noComparingComparator,
      hide: true,
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).contact?.partner.type ===
          'company'
          ? t('general.company')
          : t('general.person.label');
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      headerName: t('baseTaskListDtoColums.contactPhone'),
      comparator: noComparingComparator,
      field: 'contact.partner.phoneNumber',
      hide: true,
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).contact?.partner.company
          ? (params.data as BaseTaskListDto).contact?.partner.company
              ?.phoneNumber
          : (params.data as BaseTaskListDto).contact?.partner.person
              ?.phoneNumber;
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'contact.partner.email',
      headerName: t('baseTaskListDtoColums.contactEmail'),
      comparator: noComparingComparator,
      hide: true,
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).contact?.partner.company
          ? (params.data as BaseTaskListDto).contact?.partner.company?.email
          : (params.data as BaseTaskListDto).contact?.partner.person?.email;
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      headerName: t('baseTaskListDtoColums.contactPreferredContactMehod'),
      comparator: noComparingComparator,
      field: 'contact.partner.preferedContact',
      hide: true,
      valueGetter: (params) => {
        let methodName: PreferedContact | undefined = undefined;
        if ((params.data as BaseTaskListDto).contact?.partner.company) {
          methodName =
            (params.data as BaseTaskListDto).contact?.partner.company
              ?.preferedContact ?? undefined;
        }
        if ((params.data as BaseTaskListDto).contact?.partner.person) {
          methodName =
            (params.data as BaseTaskListDto).contact?.partner.person
              ?.preferedContact ?? undefined;
        }
        return methodName
          ? t(`baseTaskListDtoColums.contactMehod${methodName}`)
          : '';
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        return getNameFromPartner(
          (params.data as BaseTaskListDto).contact?.partner,
        );
      },
      headerName: t('general.contact.label'),
      comparator: noComparingComparator,
      field: 'contact.partner.displayName',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'status',
      comparator: noComparingComparator,
      headerName: t('general.claim.status'),
      cellRenderer: 'tagRenderer',
    },
    {
      field: 'claim.damagedParties',
      headerName: t('general.claim.damagedParties'),
      comparator: noComparingComparator,
      sortable: false,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'priority',
      hide: true,
      headerName: t('baseTaskListDtoColums.priority'),
      comparator: noComparingComparator,
      sortable: true,
      valueGetter: (params) => {
        const priority = (params.data as BaseTaskListDto).priority;
        return priority ? t(`baseTaskPriorities.${priority}`) : '';
      },
      cellRenderer: (params: ILoadingCellRendererParams) =>
        RenderPriority(params, TaskTypes.VehicleTask, setTask),
    },
    {
      field: 'closedAt',
      headerName: t('baseTaskListDtoColums.closedAt'),
      comparator: noComparingComparator,
      valueGetter: (params) => {
        const date = (params.data as BaseTaskListDto).closedAt;
        return date;
      },
      valueFormatter: (params) => {
        const date = params.value;
        if (date != null) {
          return formatDateTime(date);
        } else {
          return '';
        }
      },
      hide: true,
      cellRenderer: 'dateRenderer',
    },
    {
      field: 'claim.createdAt',
      headerName: t('general.claim.dateCreatedAt'),
      comparator: noComparingComparator,
      valueGetter: (params) => {
        const date = (params.data as BaseTaskListDto).claim.createdAt;
        return date;
      },
      valueFormatter: (params) => {
        const date = params.value;
        if (date != null) {
          return formatDateTime(date);
        } else {
          return '';
        }
      },
      cellRenderer: 'dateRenderer',
    },
    {
      field: 'quickNotes',
      hide: true,
      headerName: t('vehicleTask.quicknotes'),
      comparator: noComparingComparator,
      sortable: true,
      valueGetter: (params) => {
        const quickNotes = (params.data as VehicleTaskListDto).quickNotes;
        return quickNotes ?? '';
      },
      cellRenderer: 'quickNotesRenderer',
    },
  ];
};

const getDefaultColDefsClone = (t: TFunction): ColDef[] => {
  return JSON.parse(JSON.stringify(getVehicleDefaultColDefs(t)));
};

export const getAllBaseGridOptions = (
  t: TFunction,
  onSelectedColumns: (userTableSettingDto: UserTableSettingDto) => void,
  userTableType: UserTableTypes,
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): GridOptions => {
  const RenderQuickNotes = (params: ILoadingCellRendererParams) => {
    return (
      <WithCapabilities
        requiredCapabilities={['CreateVehicleTask', 'EditVehicleTask']}
        passWithCapabilitiesPropsToChildren
      >
        <EditQuickNotesModal
          buttonClassName="pt-2"
          label={t('vehicleTask.quicknotes')}
          placeholder={t('vehicleTask.quicknotes')}
          value={params.data.quickNotes}
          requiredCapabilities="EditVehicleTask"
          isRequired={false}
          vehicleTask={params.data as VehicleTask}
          setTask={setTask}
        />
        {params.data.quickNotes !== null && (
          <BalText className="mb-0 pt-1 pl-1 text-overflow-elipsis">
            {params.data.quickNotes}
          </BalText>
        )}
      </WithCapabilities>
    );
  };
  return {
    defaultColDef: {
      sortable: true,
      filter: false,
      width: 165,
      minWidth: 64,
      resizable: true,
    },
    //ag grid inserts default message if is either undefined or empty string, and gives error if is just a space
    // '&nbsp;' also just equals empty space, but works in this case
    overlayNoRowsTemplate: '&nbsp;',
    columnDefs: getVehicleDefaultColDefs(t, setTask),
    components: {
      createdByRenderer: function renderCreatedByAvatar(
        params: ILoadingCellRendererParams,
      ) {
        if (params.data) {
          const name = getDisplayNameFromUser(
            (params.data as BaseTaskListDto).creator,
          );
          return (
            <span className="bal-table-cell-text">
              {params.data.creator && (
                <Avatar
                  user={params.data.creator}
                  size="32"
                  className="is-flex-shrink-0"
                />
              )}
              <BalText space="none">
                <p className="ml-2">{name}</p>
              </BalText>
            </span>
          );
        } else {
          return t('taskOverview.unassigned');
        }
      },
      avatarRenderer: renderBaseTaskAssigneeAvatar,
      tagRenderer: renderBaseTaskStatusTag,
      dateRenderer: renderBaseTaskDate,
      quickNotesRenderer: RenderQuickNotes,
      overviewButton: function renderOverviewButton(
        params: ILoadingCellRendererParams,
      ) {
        if (params.data.status !== 'New') {
          return (
            <WithCapabilities
              className="is-flex is-align-items-center"
              requiredCapabilities={['CreateVehicleTask', 'EditVehicleTask']}
              passWithCapabilitiesPropsToChildren
            >
              <DisabableLink
                to={EBRoutes.VEHICLE_INSPECTION_TASK_DETAIL.create({
                  taskId: params.data.id,
                })}
              >
                <BalIcon name="nav-go-right" size="small" />
              </DisabableLink>
            </WithCapabilities>
          );
        } else return <></>;
      },
      tableSettingsButton: function useRenderTableSettings(
        params: ILoadingCellRendererParams,
      ) {
        const [modalOpened, setModalOpened] = useState(false);
        const { user } = useContext(UserContext);
        const openSettingsModal = async () => {
          const modal = await balModalController.create({
            component: TableSettingsForm,
            componentProps: {
              gridApi: params.api,
              user: user,
              userTableType: userTableType,
              onSelectedColumns: onSelectedColumns,
              defaultColDefs: getDefaultColDefsClone(t),
            },
            cssClass: 'center-modal',
            backdropDismiss: false,
          });
          modal.onDidDismiss().then(() => {
            setModalOpened(false);
          });
          return modal.present();
        };
        return (
          <div
            onClick={() => {
              if (!modalOpened) {
                setModalOpened(true);
                openSettingsModal();
              }
            }}
          >
            <BalIcon svg={balIconSettings} size="small" />
          </div>
        );
      },
    },
  };
};

export const useGridOptions = (
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): [GridOptions, React.Dispatch<GridOptions>] => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const userTableType = 'VehicleTask';

  const [gridOptions, setGridOptions] = useState<GridOptions>(
    buildGridOptions(
      user,
      setUser,
      t,
      userTableType,
      getAllBaseGridOptions,
      setTask,
    ),
  );

  useEffect(() => {
    setGridOptions(
      buildGridOptions(
        user,
        setUser,
        t,
        userTableType,
        getAllBaseGridOptions,
        setTask,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setUser, t, userTableType]);

  return [gridOptions, setGridOptions];
};
