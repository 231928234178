import { RefObject, useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[],
  handler: (event: AnyEvent) => void,
): void => {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      let isClickOutside = true;
      refs.forEach((ref) => {
        const el = ref?.current;

        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.target as Node)) {
          isClickOutside = false;
        }
      });

      // Also ignore clicks in BalModal
      const modals = document.getElementsByTagName('bal-modal');
      for (const el of modals) {
        if (!el || el.contains(event.target as Node)) {
          isClickOutside = false;
        }
      }

      // Also ignore clicks in Toastify
      const toasts = document.getElementsByClassName('Toastify');
      for (const el of toasts) {
        if (!el || el.contains(event.target as Node)) {
          isClickOutside = false;
        }
      }

      if (isClickOutside) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };

    // Reload only if refs or handler changes
  }, [refs, handler]);
};
