import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'i18next';
import { InsuranceSurveyListDto } from '../../../../../types/resource-models';
import { dateComparator, formatDateTime } from '../../../../../utils/date';
import {
  earlybyteTableTextRenderer,
  renderBaseTaskDate,
  renderInsuranceSurveyResponseTag,
} from '../../../../../utils/utilities';
import InsuranceSurveyEditButton, {
  InsuranceSurveyEditButtonProps,
} from './toolbar/insurance-survey-edit-button';
import InsuranceSurveyDeleteButton, {
  InsuranceSurveyDeleteButtonProps,
} from './toolbar/insurance-survey-delete-button';

export const getDefaultColDefs = (
  t: TFunction,
  insuranceSurveyEditButtonProps: InsuranceSurveyEditButtonProps,
  insuranceSurveyDeleteButtonProps: InsuranceSurveyDeleteButtonProps,
): ColDef[] => {
  return [
    {
      valueGetter: (params) => {
        return (params.data as InsuranceSurveyListDto).startDate;
      },
      valueFormatter: (params) => {
        const date = params.value;
        return formatDateTime(date);
      },
      comparator: dateComparator,
      field: 'startDate',
      headerName: t('investigationTask.insuranceSurvey.startDate'),
      minWidth: 150,
      cellRenderer: renderBaseTaskDate,
    },
    {
      valueGetter: (params) =>
        t(
          `investigationTask.insuranceSurveyTypes.${
            (params.data as InsuranceSurveyListDto).insuranceSurveyType
          }`,
        ),
      headerName: t('investigationTask.insuranceSurvey.type'),
      field: 'insuranceSurveyType',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      headerName: t('investigationTask.insuranceSurvey.insurance'),
      field: 'insurance',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const insuranceSurvey = params.data as InsuranceSurveyListDto;
        return t(
          `investigationTask.insuranceSurveyResponses.${insuranceSurvey.response}`,
        );
      },
      field: 'response',
      headerName: t('investigationTask.insuranceSurvey.response'),
      cellRenderer: 'tagRenderer',
    },
    {
      valueGetter: (params) => {
        const insuranceSurvey = params.data as InsuranceSurveyListDto;
        return t(`tristate.${insuranceSurvey.hasAttachments}`);
      },
      field: 'hasAttachments',
      headerName: t('investigationTask.insuranceSurvey.hasAttachments'),
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      cellRenderer: function renderEdit(params: ICellRendererParams) {
        return (
          <div className="mt-3">
            <InsuranceSurveyEditButton
              currentInsuranceSurvey={params.data}
              onEditInsuranceSurvey={
                insuranceSurveyEditButtonProps.onEditInsuranceSurvey
              }
              readonly={insuranceSurveyEditButtonProps.readonly}
              onDeleteInsuranceSurvey={
                insuranceSurveyDeleteButtonProps.onDeleteInsuranceSurvey
              }
            />
            <InsuranceSurveyDeleteButton
              currentInsuranceSurvey={params.data}
              onDeleteInsuranceSurvey={
                insuranceSurveyDeleteButtonProps.onDeleteInsuranceSurvey
              }
              disabled={insuranceSurveyDeleteButtonProps.disabled}
            />
          </div>
        );
      },
      sortable: false,
      width: 95,
      minWidth: 95,
      resizable: false,
    },
  ];
};

export const getAllGridOptions = (
  t: TFunction,
  insuranceSurveyEditButtonProps: InsuranceSurveyEditButtonProps,
  insuranceSurveyDeleteButtonProps: InsuranceSurveyDeleteButtonProps,
): GridOptions => {
  return {
    defaultColDef: {
      sortable: true,
      filter: false,
      width: 110,
      flex: 1,
      minWidth: 110,
      resizable: true,
    },
    //ag grid inserts default message if is either undefined or empty string, and gives error if is just a space
    // '&nbsp;' also just equals empty space, but works in this case
    overlayNoRowsTemplate: '&nbsp;',
    columnDefs: getDefaultColDefs(
      t,
      insuranceSurveyEditButtonProps,
      insuranceSurveyDeleteButtonProps,
    ),
    components: {
      tagRenderer: renderInsuranceSurveyResponseTag,
    },
  };
};
