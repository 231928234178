import {
  BalCard,
  BalHeading,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../data';
import { BaseTask, VehicleTask } from '../../../types/types';
import '../../../styles/general.scss';
import { RefObject, useEffect, useState } from 'react';
import { formatDateTime } from '../../../utils/date';
import { concat, concatAddress } from '../../../utils';
import { useToken } from '../../../hooks';
import { EBRoutes } from '../../../router/router';
import { BaseTaskAttachments, BaseTaskClaimNumber } from '../../base-task';
import { BaseTaskListDto } from '../../../types/resource-models';
import { getDisplayNameFromUser } from '../../../utils/utilities';
import { InfoDisplay } from '../../../components/ui';
import ImageMarker from 'react-image-marker';
import vehicleDamage from '../../../assets/img/bvm-damage-zone.png';
import { useDescriptionOfBaloiseCode } from '../../user/data/hooks';
import { VehicleTaskWorkflowButtons } from '.';
import BaseTaskInternalNotes from '../../base-task/components/base-task-internal-notes';

type VehicleTaskOverlayProps = {
  tab?: string;
  fillSpace?: boolean;
  slideIn?: boolean;
  hideLinkButtons?: boolean;
  refTaskOverlay?: RefObject<HTMLBalCardElement>;
  task: BaseTaskListDto;
  replaceTaskInList?: (task: RequestResult<BaseTask>) => void;
  getFullTask: (
    bearerToken: string,
    taskId: string,
  ) => Promise<RequestResult<VehicleTask>>;
};

export const VehicleTaskOverlay = ({
  tab,
  task,
  fillSpace,
  slideIn,
  hideLinkButtons,
  refTaskOverlay,
  replaceTaskInList,
  getFullTask,
}: VehicleTaskOverlayProps): JSX.Element => {
  const { t } = useTranslation();
  const [fullTask, setFullTask] = useState<VehicleTask | undefined>(undefined);
  const token = useToken();

  useEffect(() => {
    if (token !== '' && task && task.id !== '') {
      getFullTask(token, task.id).then((response) => {
        if (
          response.status == 'success' &&
          response.localValue.status == task.status
        ) {
          setFullTask(response.value);
        }
      });
    }
  }, [task, token, getFullTask]);
  const affectedPartners = fullTask?.claim.affectedPartners.filter(
    (x) => x.role == 'AffectedThirdParty',
  );
  const showButtons = fullTask && fullTask.status !== 'New';
  const kindDescription = useDescriptionOfBaloiseCode(fullTask?.claim.kind);

  return fullTask ? (
    <BalCard
      ref={refTaskOverlay}
      id="overlay"
      className={slideIn ? 'animated-overlay-in' : ''}
    >
      <>
        <BalHeading level="h4" className="overlay-title p-5">
          <div className="is-flex is-flex-direction-column is-full-width">
            <BaseTaskClaimNumber
              detailButtonRequiredCapabilities={[
                'CreateVehicleTask',
                'EditVehicleTask',
              ]}
              detailButtonRoute={
                fullTask.kind !== 'VehicleBonusCorrection' && !hideLinkButtons
                  ? EBRoutes.VEHICLE_INSPECTION_TASK_DETAIL.create({
                      taskId: fullTask.id,
                    })
                  : undefined
              }
              claimNumber={
                fullTask.claim.claimNumber != ''
                  ? fullTask.claim.claimNumber
                  : fullTask.claim.policyNumber
              }
              hideGoToAdos={fullTask.kind === 'VehicleRiskAssessment'}
            />
            {fullTask.status !== 'New' && (
              <>
                <BalText size="small" className="mt-1 has-text-black">
                  {concat([
                    formatDateTime(fullTask.createdAt),
                    t('general.from').toLowerCase(),
                    getDisplayNameFromUser(fullTask.creator),
                    fullTask.assignee?.insuranceCenter.name,
                  ])}
                </BalText>
                <div className="is-full-width mb-2 mt-2 bottom-blue-line"></div>
              </>
            )}
            <VehicleTaskWorkflowButtons
              task={fullTask}
              className="is-flex is-align-items-center"
              onChangeSuccess={
                replaceTaskInList
                  ? (task) => replaceTaskInList(task)
                  : undefined
              }
            />
          </div>
        </BalHeading>
        <div
          className={concat([
            'overlay-content mb-5 scroll-bar-minimal',
            showButtons ? 'show-buttons' : '',
          ])}
        >
          {fullTask.kind !== 'VehicleRiskAssessment' && fullTask.what && (
            <>
              <BalHeading level="h5" space="none" className="mt-5">
                {t('vehicleTask.expertiseOrderReport.whatHappened')}
              </BalHeading>
              <BalText>{fullTask.what}</BalText>
            </>
          )}
          <>
            <BalHeading level="h5" space="none" className="mt-5">
              {fullTask.kind === 'VehicleRiskAssessment' ||
              fullTask.kind === 'VehicleBonusCorrection'
                ? t('general.policy')
                : t('general.claim.claimInformation')}
            </BalHeading>
            <InfoDisplay
              title={t('claimDetail.policyHolder')}
              text={
                fullTask.claim.policyHolder
                  ? fullTask.claim.policyHolder.type === 'person'
                    ? fullTask.claim.policyHolder.person.displayName
                    : fullTask.claim.policyHolder.company.displayName
                  : ''
              }
            />
            {fullTask.kind === 'VehicleBonusCorrection' && (
              <>
                <InfoDisplay
                  title={t('general.address')}
                  text={
                    fullTask.claim.policyHolder
                      ? concatAddress(
                          fullTask.claim.policyHolder.type === 'person'
                            ? fullTask.claim.policyHolder.person.address
                            : fullTask.claim.policyHolder.company.address,
                        )
                      : ''
                  }
                />
                <InfoDisplay
                  title={t('overlay.policyNumber')}
                  text={fullTask.claim.policyNumber}
                />
                <InfoDisplay
                  title={t('taskOverview.createdAt')}
                  text={formatDateTime(fullTask.createdAt)}
                />
              </>
            )}
            {affectedPartners !== undefined && affectedPartners.length > 0 && (
              <InfoDisplay
                title={t('partnerRole.AffectedThirdParty')}
                text={
                  affectedPartners[0].partner.type === 'person'
                    ? affectedPartners[0].partner.person.displayName
                    : affectedPartners[0].partner.company.displayName
                }
              />
            )}
            {fullTask.kind === 'VehicleInspectionTask' && (
              <InfoDisplay
                title={t('claimDetail.insuranceType')}
                text={t(`insuranceType.${fullTask.claim.insuranceType}`)}
              />
            )}
          </>
          {fullTask.kind !== 'VehicleRiskAssessment' &&
            fullTask.kind !== 'VehicleBonusCorrection' && (
              <>
                <InfoDisplay
                  title={t('overlay.claimKind')}
                  text={kindDescription}
                />
                {fullTask.costs && (
                  <InfoDisplay
                    title={t('vehicleTask.vehicleClaimDamage.damageCost')}
                    text={fullTask.costs?.toString()}
                  />
                )}
              </>
            )}
          {fullTask.affectedVehicle && tab !== 'VehicleTaskClaimDamageForm' && (
            <>
              <BalHeading level="h5" space="none" className="mt-5">
                {t('vehicleTask.overlay.vehicleInformation')}
              </BalHeading>
              {fullTask.kind !== 'VehicleBonusCorrection' ? (
                <>
                  <InfoDisplay
                    title={t('vehicleTask.overlay.brand')}
                    text={fullTask.affectedVehicle.brand}
                  />
                  <InfoDisplay
                    title={t('vehicleTask.overlay.model')}
                    text={fullTask.affectedVehicle.model}
                  />
                  <InfoDisplay
                    title={t('vehicleTask.overlay.licensePlate')}
                    text={fullTask.affectedVehicle.plate}
                  />
                  <InfoDisplay
                    title={t('vehicleTask.vehicle.registration')}
                    text={
                      fullTask.affectedVehicle.registration &&
                      formatDateTime(fullTask.affectedVehicle.registration)
                    }
                  />
                  {fullTask.kind !== 'VehicleRiskAssessment' ? (
                    <div className="mt-2">
                      <BalText bold color="info">
                        {t('vehicleTask.vehicleClaimDamage.damageZone')}
                      </BalText>
                      <ImageMarker
                        src={vehicleDamage}
                        markers={fullTask.damageCoordinates}
                        markerComponent={() => <span>✕</span>}
                      />
                    </div>
                  ) : (
                    <InfoDisplay
                      title={t('vehicleTask.vehicle.desiredMaximumInsuredSum')}
                      text={fullTask.affectedVehicle.desiredMaximumInsuredSum?.toLocaleString(
                        'de-CH',
                      )}
                    />
                  )}
                </>
              ) : (
                <InfoDisplay
                  title={t('vehicleTask.overlay.licensePlate')}
                  text={fullTask.affectedVehicle.plate}
                />
              )}
            </>
          )}
          {fullTask.claim && fullTask.kind !== 'VehicleBonusCorrection' && (
            <>
              <BalHeading level="h5" space="none" className="mt-5">
                {t('vehicleTask.overlay.inspection')}
              </BalHeading>
              <InfoDisplay
                title={t('general.name')}
                text={concat([
                  fullTask.assigneeGarage.name,
                  fullTask.assigneeGarage.additionalName,
                ])}
              />
              {fullTask.visitationAtPolicyHolder && (
                <BalText size="small">
                  {t('vehicleTask.overlay.visitationAtPolicyHolder')}
                </BalText>
              )}
              <InfoDisplay
                title={t('general.address')}
                text={
                  fullTask.visitationAtPolicyHolder
                    ? fullTask.claim.policyHolder
                      ? concatAddress(
                          fullTask.claim.policyHolder.type === 'person'
                            ? fullTask.claim.policyHolder.person.address
                            : fullTask.claim.policyHolder.company.address,
                        )
                      : ''
                    : concatAddress(fullTask.assigneeGarage.address)
                }
              />
              <InfoDisplay
                title={t('general.phone')}
                text={fullTask.assigneeGarage.phoneNumber}
              />
              <InfoDisplay
                title={t('vehicleTask.expertiseOrderReport.reportedBy')}
                text={fullTask.reportedBy}
              />
              <InfoDisplay
                title={t('vehicleTask.overlay.meeting')}
                text={
                  fullTask.appointmentEarliest
                    ? formatDateTime(fullTask.appointmentEarliest)
                    : ''
                }
              />
            </>
          )}
          {fullTask.bonusCorrectionReason && (
            <>
              <BalHeading level="h5" space="none" className="mt-5">
                {t('vehicleTask.bonusCorrectionReason')}
              </BalHeading>
              <BalText>
                {t(
                  `vehicleTask.vehicleBonusCorrection.bonusCorrectionReason.${fullTask.bonusCorrectionReason}`,
                )}
              </BalText>
            </>
          )}
          {fullTask.quickNotes && (
            <>
              <BalHeading level="h5" space="none" className="mt-5">
                {t('vehicleTask.quicknotes')}
              </BalHeading>
              <BalText>{fullTask.quickNotes}</BalText>
            </>
          )}
          {fullTask.remarks && (
            <>
              <BalHeading level="h5" space="none" className="mt-5">
                {t('general.claim.remarks')}
              </BalHeading>
              <BalText>{fullTask.remarks}</BalText>
            </>
          )}
          {fullTask.internalNote && tab !== 'claimInformation' && (
            <BaseTaskInternalNotes internalNote={fullTask.internalNote} />
          )}
          {fullTask.attachments.length > 0 &&
            tab !== 'claimInformation' &&
            fullTask.kind !== 'VehicleRiskAssessment' && (
              <BaseTaskAttachments attachments={fullTask.attachments} />
            )}
        </div>
      </>
    </BalCard>
  ) : fillSpace ? (
    <div className="overlay slide-in is-pulled-right" />
  ) : (
    <div className="overlay-space-slide" />
  );
};
