import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'i18next';
import { dateComparator, formatDateTime } from '../../../utils/date';
import { ExpenseDto } from '../../../../../bat-shared/resource-models';
import CreateEditShowExpensesButton from './expenses-buttons/create-edit-show-expense';
import DeleteExpensesButton from './expenses-buttons/delete-expense';
import { Globals } from '../../../utils';
import {
  earlybyteTableTextRenderer,
  renderBaseTaskDate,
} from '../../../utils/utilities';
import { balModalController } from '../../../controller/controllers';

export const getExpenseColDefs = (
  t: TFunction,
  updateExpense: (
    expense: ExpenseDto,
    created: boolean,
    deleted: boolean,
    token: string,
  ) => void,
  taskId: string,
  readonly?: boolean,
): ColDef[] => {
  return [
    {
      valueGetter: (params) => (params.data as ExpenseDto).createdAt,
      valueFormatter: (params) => {
        const date = params.value;
        return formatDateTime(date);
      },
      comparator: dateComparator,
      field: 'expenseCreatedAt',
      headerName: t('expense.createdAt'),
      minWidth: 150,
      cellRenderer: renderBaseTaskDate,
    },
    {
      valueGetter: (params) => {
        const expense = params.data as ExpenseDto;
        const translatedExpenseType = t(
          `expense.expenseTypes.${expense.expenseType}`,
        );

        return translatedExpenseType;
      },
      headerName: t('expense.expenseType'),
      field: 'expenseType',
      minWidth: 200,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => (params.data as ExpenseDto)?.expenseName,
      headerName: t('expense.expenseName'),
      field: 'expenseName',
      minWidth: 200,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => (params.data as ExpenseDto).expenseSum,
      headerName: t('expense.expenseSum'),
      field: 'expenseSum',
      minWidth: 100,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const translatedValue = Globals.ExpenseCurrencies.find(
          (x) => x.value === (params.data as ExpenseDto).currency.toString(),
        );
        return translatedValue?.translatedValue;
      },
      headerName: t('expense.currency'),
      field: 'currency',
      width: 50,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      cellRenderer: function renderEdit(params: ICellRendererParams) {
        return (
          <div className="mt-3">
            <CreateEditShowExpensesButton
              expense={params.data as ExpenseDto}
              updateExpense={updateExpense}
              show={readonly}
              taskId={taskId}
            />
            <DeleteExpensesButton
              disabled={readonly}
              taskId={taskId}
              expense={params.data as ExpenseDto}
              updateExpense={updateExpense}
              onDelete={() => {
                balModalController.dismiss();
              }}
            />
          </div>
        );
      },
      sortable: false,
      width: 95,
      minWidth: 95,
      resizable: false,
    },
  ];
};

export const getExpenseGridOptions = (
  t: TFunction,
  taskId: string,
  updateExpense: (
    expense: ExpenseDto,
    created: boolean,
    delted: boolean,
    token: string,
  ) => void,
  readonly?: boolean,
): GridOptions => {
  return {
    defaultColDef: {
      sortable: true,
      filter: false,
      width: 110,
      flex: 1,
      minWidth: 110,
      resizable: false,
    },
    //ag grid inserts default message if is either undefined or empty string, and gives error if is just a space
    // '&nbsp;' also just equals empty space, but works in this case
    overlayNoRowsTemplate: '&nbsp;',
    columnDefs: getExpenseColDefs(t, updateExpense, taskId, readonly),
  };
};
