import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { WithCapabilities } from '../../../../../../components/authorization';
import { balModalController } from '../../../../../../controller/controllers';
import { InvestigationTask } from '../../../../../../types/types';
import { Globals, guid } from '../../../../../../utils';
import { InsuranceSurveyEditFormModal } from '../insurance-survey-edit-form-modal';

export interface InsuranceSurveyAddButtonsProps {
  task: InvestigationTask;
  onAddInsuranceSurvey: (insuranceSurveyId: string) => void;
  disabled?: boolean;
}

const InsuranceSurveyAddButton = ({
  task,
  onAddInsuranceSurvey,
  disabled,
}: InsuranceSurveyAddButtonsProps): JSX.Element => {
  const openAddModal = async () => {
    const insuranceSurveyToAdd = {
      ...Globals.insuranceSurveyDefault,
      id: guid(),
      investigationTaskId: task.id,
    };

    const modal = await balModalController.create({
      component: InsuranceSurveyEditFormModal,
      modalWidth: 1200,
      componentProps: {
        title: `${t('general.buttons.create')} ${t(
          'investigationTask.insuranceSurvey.entityName',
        )}`,
        initialValue: {
          status: 'success',
          value: insuranceSurveyToAdd,
          localValue: insuranceSurveyToAdd,
        },
        initialAddingState: true,
        onSave: onAddInsuranceSurvey,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        disabled={disabled}
        className="ml-2"
        square
        outlined
        size="small"
        color="info"
        icon="plus"
        onClick={(event) => event.detail == 1 && openAddModal()}
      />
    </WithCapabilities>
  );
};

export default InsuranceSurveyAddButton;
