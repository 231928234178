import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import DeleteConfirmationDialog from '../../../dialogs/delete-confirmation-dialog';
import { InspectionListDto } from '../../../../../../bat-shared/resource-models';
export interface PropertyInspectionTaskJournalDeleteButtonProps {
  currentInspection: InspectionListDto | undefined;
  disabled?: boolean;
  onDeleteInspection: (inspection: InspectionListDto) => Promise<void>;
  textButton?: boolean;
}

const InspectionDeleteButton = ({
  currentInspection,
  disabled,
  onDeleteInspection,
}: PropertyInspectionTaskJournalDeleteButtonProps): JSX.Element => {
  const openDeleteModal = async () => {
    if (currentInspection) {
      const modal = await balModalController.create({
        component: DeleteConfirmationDialog,
        componentProps: {
          title: `${t('general.buttons.delete')} ${t(
            'inspectionTask.inspection.entityName',
          )}`,
          text: t('inspectionTask.inspection.deleteConfirmationQuestion'),
          onDelete: async () => {
            await onDeleteInspection(currentInspection);
          },
        },
        cssClass: 'center-modal',
      });
      return modal.present();
    }
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditPropertyInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        disabled={disabled}
        className="ml-2"
        outlined
        square
        size="small"
        color="danger"
        icon="minus"
        onClick={async () => await openDeleteModal()}
      />
    </WithCapabilities>
  );
};

export default InspectionDeleteButton;
