import { Icon } from './icon';
import feedbackIcon from '../../assets/icons/icon-consultant.svg';

export const FeedbackDisplay = (): JSX.Element => {
  return (
    <>
      <a
        href={window.extended.BAT_APP_FEEDBACK_URL}
        target="_blank"
        rel="noreferrer"
        className="navbar-item p-2 navbar-add-button"
      >
        <Icon iconPath={feedbackIcon} />
      </a>
    </>
  );
};
