import { Props } from '@baloise/design-system-components/dist/types/props';
import '../../styles/general.scss';
import {
  Control,
  Controller,
  FieldValues,
  ValidationRule,
} from 'react-hook-form';
import { BalTextField } from './bal-text-field';
import { useTranslation } from 'react-i18next';

type BalTextFormFieldProps = {
  className?: string;
  titleClassName?: string;
  controllerName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FieldValues, object>;
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: CustomEvent<string | undefined>) => void;
  onInput?: (e: CustomEvent<string | undefined>) => void;
  onFocus?: () => void;
  onKeyPress?: (e: CustomEvent<KeyboardEvent>) => void;
  maxLength?: number;
  required?: string | ValidationRule<boolean>;
  type?: Props.BalInputInputType;
  disabled?: boolean;
  suffix?: string;
  mask?: 'contract-number' | 'claim-number' | 'offer-number';
  pattern?: ValidationRule<RegExp>;
  maxLengthRule?: ValidationRule<number> | undefined;
  validate?: (value: string) => string | true;
};

export const BalTextFormField = (props: BalTextFormFieldProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Controller
      name={props.controllerName}
      control={props.control}
      defaultValue={props.value}
      rules={{
        validate: props.validate,
        required:
          props.required === undefined
            ? t('error.mandatoryField')
            : props.required,
        pattern: props.pattern,
        maxLength: props.maxLengthRule,
      }}
      render={({ field, fieldState }) => (
        <BalTextField
          {...field}
          className={props.className}
          titleClassName={props.titleClassName}
          title={props.title}
          placeholder={
            props.required === undefined || props.required !== false
              ? `${props.placeholder} *`
              : props.placeholder
          }
          value={props.value}
          onChange={(e) => {
            props.onChange && props.onChange(e);
            field.onChange(e.detail);
          }}
          onInput={props.onInput}
          onFocus={props.onFocus}
          onKeyPress={props.onKeyPress}
          maxLength={props.maxLength}
          message={fieldState.error && fieldState.error.message}
          type={props.type}
          disabled={props.disabled}
          suffix={props.suffix}
          mask={props.mask}
        />
      )}
    />
  );
};

BalTextFormField.displayName = 'BalTextFormField';
