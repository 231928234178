import { BalButton, BalIcon } from '@baloise/design-system-components-react';
import { balIconSearch, balIconEdit } from '@baloise/design-system-icons';
import { t } from 'i18next';
import { useCallback, useContext } from 'react';
import lang from 'suneditor-react/dist/types/lang';
import { WithCapabilities } from '../../../../components/authorization';
import { UserContext } from '../../../../context';
import { balModalController } from '../../../../controller/controllers';
import { useToken } from '../../../../hooks';
import { PropertyInspectionTaskJournalListDto } from '../../../../types/resource-models';
import { getPropertyInspectionTaskJournal } from '../../data/requests';
import JournalEditFormModal from '../property-inspection-task-journal-edit-form-modal';

export interface PropertyInspectionTaskJournalEditButtonProps {
  currentJournal: PropertyInspectionTaskJournalListDto | undefined;
  onEditJournal: (journalId?: string) => void;
  readonly?: boolean;
  onDeleteJournal: (
    journal: PropertyInspectionTaskJournalListDto,
  ) => Promise<void>;
}

const PropertyInspectionTaskJournalEditButton = ({
  currentJournal,
  onEditJournal,
  readonly,
  onDeleteJournal,
}: PropertyInspectionTaskJournalEditButtonProps): JSX.Element => {
  const token = useToken();
  const { user } = useContext(UserContext);
  const getLanguage = useCallback((): lang => {
    let result: lang = 'de';
    if (user.status === 'success') {
      result = user.localValue.language.toLowerCase() as lang;
    }
    return result;
  }, [user]);

  const openEditModal = async () => {
    if (currentJournal) {
      const journalToEdit = await getPropertyInspectionTaskJournal(
        token,
        currentJournal.id,
      );
      if (journalToEdit.status === 'success') {
        const modal = await balModalController.create({
          component: JournalEditFormModal,
          modalWidth: 1300,
          componentProps: {
            title: `${t('general.buttons.edit')} ${t('journal.entityName')}`,
            initialValue: journalToEdit,
            initialAddinState: false,
            language: getLanguage(),
            onSave: onEditJournal,
            onDelete: async () => {
              await onDeleteJournal(currentJournal);
            },
            readonly,
          },
          cssClass: 'center-modal',
          backdropDismiss: false,
          isClosable: false,
        });
        return modal.present();
      }
    }
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditPropertyInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        className="ml-2"
        outlined
        square
        size="small"
        color="info"
        onClick={(event) => event.detail == 1 && openEditModal()}
      >
        <BalIcon svg={readonly ? balIconSearch : balIconEdit} size="small" />
      </BalButton>
    </WithCapabilities>
  );
};

export default PropertyInspectionTaskJournalEditButton;
