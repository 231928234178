import { BalButton } from '@baloise/design-system-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
type taskButtonsProps = {
  refTabs: React.RefObject<HTMLBalTabsElement>;
  refPrevTab?: React.RefObject<HTMLBalTabItemElement>;
  setPrevTab?: () => void;
  backText?: string;
  nextText?: string;
  hasBack?: boolean;
  hasNext?: boolean;
  onClickNext?: () => void;
  onClickBack?: () => void;
  disabled?: boolean;
};
export const TaskWizardButtons = ({
  refTabs,
  refPrevTab,
  setPrevTab,
  backText,
  nextText,
  hasBack = true,
  hasNext = true,
  onClickNext,
  onClickBack,
  disabled,
}: taskButtonsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="is-pulled-right mt-5">
      {hasBack && (
        <BalButton
          elementType="button"
          color="primary-light"
          onClick={
            onClickBack ??
            ((event) => {
              if (refPrevTab && event.detail == 1) {
                refPrevTab.current
                  ?.getOptions()
                  .then((options) => refTabs.current?.select(options));
                setPrevTab && setPrevTab();
              }
            })
          }
        >
          {backText ?? t('general.buttons.back')}
        </BalButton>
      )}
      {hasNext && (
        <BalButton
          className="ml-3"
          elementType="submit"
          color="primary"
          onClick={onClickNext}
          disabled={disabled}
        >
          {nextText ?? t('general.buttons.next')}
        </BalButton>
      )}
    </div>
  );
};
