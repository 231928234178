import { RequestResult } from '../../../../data';
import { BaseTask, TaskTypes, VehicleTask } from '../../../../types/types';
import { WithCapabilities } from '../../../../components/authorization';
import { UserContext } from '../../../../context';
import { concat } from '../../../../utils';
import { VehicleTaskReject } from './vehicle-task-reject';
import { VehicleTaskReassign } from './vehicle-task-reassign';
import { VehicleTaskInProgressButton } from './vehicle-task-in-progress-button';
import { VehicleTaskCompletion } from './vehicle-task-completion';
import {
  getVehicleTaskReport,
  getVehicleXml,
} from '../../../files/data/requests';
import VehicleTaskInProgressWithIndependentButton from './vehicle-task-in-progress-with-independent-button';
import { useToken } from '../../../../hooks';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../../components/toast-notification';
import { BaseTaskStatus } from '../../../../types/resource-models';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { BaseTaskMenu } from '../../../base-task';
import { duplicateVehicleRiskAsssessmentTask } from '../../data/requests';
import { BalButton } from '@baloise/design-system-components-react';
import { EBRoutes } from '../../../../router/router';
import { BaseTaskDeleteButton } from '../../../base-task/components';

type VehicleTaskWorkflowButtonsProps = {
  task: VehicleTask;
  className?: string;
  onChangeSuccess?: (task: RequestResult<VehicleTask>) => void;
};

const showCloseButtonStates: BaseTaskStatus[] = [
  'InProgressLocal',
  'InProgressVideo',
  'InProgressOffice',
  'InProgressWithPartner',
  'InProgressWithIndependent',
];
const showAcceptButtonStates: BaseTaskStatus[] = [
  'Assigned',
  'InProgressLocal',
  'InProgressVideo',
  'InProgressOffice',
  'InProgressWithPartner',
];
const showRejectButtonStates: BaseTaskStatus[] = ['Assigned'];
const showDeleteDraftButtonStates: BaseTaskStatus[] = ['New'];
const showReassignButtonStates: BaseTaskStatus[] = [
  'SentToArchive',
  'Assigned',
  'InProgressLocal',
  'InProgressVideo',
  'InProgressOffice',
  'InProgressWithPartner',
  'InProgressWithIndependent',
  'Rejected',
];
const showMoreButtonStates: BaseTaskStatus[] = [
  'SentToArchive',
  'Assigned',
  'InProgressLocal',
  'InProgressVideo',
  'InProgressOffice',
  'InProgressWithPartner',
  'InProgressWithIndependent',
  'Rejected',
  'Closed',
];

export const VehicleTaskWorkflowButtons = ({
  task,
  className,
  onChangeSuccess,
}: VehicleTaskWorkflowButtonsProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const isAssignee =
    (user.status === 'success' || user.status === 'loading-with-value') &&
    user.value.id === task.assignee?.id;
  const claimNumberSanitized = task.claim.claimNumber
    .replaceAll('/', '')
    .replace('.', '');

  const policyNumberSanitized = task.claim.policyNumber
    .replaceAll('/', '')
    .replace('.', '');

  const reportName = `${
    task.kind === 'VehicleInspectionTask'
      ? claimNumberSanitized
      : policyNumberSanitized
  }_report.pdf`;

  const xmlName =
    task.kind === 'VehicleRiskAssessment'
      ? `${policyNumberSanitized}_export.xml`
      : `${claimNumberSanitized}_export.xml`;

  return (
    <div className={concat(['is-justify-content-space-between', className])}>
      <div>
        {task.kind !== 'VehicleBonusCorrection' && (
          <>
            {isAssignee && showAcceptButtonStates.includes(task.status) && (
              <WithCapabilities
                requiredCapabilities={['EditVehicleTask']}
                passWithCapabilitiesPropsToChildren
              >
                <VehicleTaskInProgressButton
                  task={task}
                  onChangeSuccess={(e) => {
                    onChangeSuccess && onChangeSuccess(e);
                    getVehicleXml &&
                      getVehicleXml(token, task.id).then((file) => {
                        if (file.status === 'success') {
                          const a = document.createElement('a');
                          a.href = file.value;
                          a.download = xmlName;
                          a.click();
                        } else if (file.status === 'error') {
                          toast(ErrorToast(file.errorValue));
                        }
                      });
                    return;
                  }}
                  text={
                    showCloseButtonStates.includes(task.status)
                      ? t(`baseTaskStatus.${task.status}`)
                      : t('general.buttons.accept')
                  }
                />
              </WithCapabilities>
            )}
            {showRejectButtonStates.includes(task.status) && (
              <VehicleTaskReject
                task={task}
                onRejectSuccess={onChangeSuccess}
              />
            )}
            {task.kind === 'VehicleInspectionTask' &&
              showAcceptButtonStates.includes(task.status) && (
                <WithCapabilities
                  requiredCapabilities={['EditVehicleTask']}
                  passWithCapabilitiesPropsToChildren
                >
                  <VehicleTaskInProgressWithIndependentButton
                    task={task}
                    onChangeSuccess={onChangeSuccess}
                  />
                </WithCapabilities>
              )}
            {showReassignButtonStates.includes(task.status) && (
              <VehicleTaskReassign
                task={task}
                onReassignSuccess={onChangeSuccess}
              />
            )}
            {showCloseButtonStates.includes(task.status) && onChangeSuccess && (
              <VehicleTaskCompletion
                task={task}
                onChangeSuccess={onChangeSuccess}
                customCompletionTitle={
                  task.kind === 'VehicleRiskAssessment'
                    ? t('taskCompletion.riskAssessmentCompletionTitle')
                    : undefined
                }
                customCompletionText={
                  task.kind === 'VehicleRiskAssessment'
                    ? t('taskCompletion.riskAssessmentCompletion')
                    : undefined
                }
              />
            )}
          </>
        )}
        {showDeleteDraftButtonStates.includes(task.status) &&
          onChangeSuccess &&
          task.deletedAt === null && (
            <>
              <BaseTaskDeleteButton
                className="ml-3"
                task={task}
                taskType={TaskTypes.VehicleTask}
                onChangeSuccess={onChangeSuccess}
                requiredCapabilities={['EditVehicleTask']}
              />
              <BalButton
                color="info"
                className="ml-3"
                size="small"
                onClick={(event) => {
                  if (event.detail == 1) {
                    const route =
                      task.kind === 'VehicleInspectionTask'
                        ? EBRoutes.VEHICLE_INSPECTION_TASK_WIZARD.create({
                            query: {
                              taskId: task.id,
                            },
                          })
                        : EBRoutes.VEHICLE_RISK_ASSESSMENT_WIZARD.create({
                            query: {
                              taskId: task.id,
                            },
                          });
                    navigate(route);
                    navigate(0);
                  }
                }}
              >
                {t('inspectionTask.inspectionTaskWorkflow.toWizard')}
              </BalButton>
            </>
          )}
      </div>

      {showMoreButtonStates.includes(task.status) && (
        <>
          <BaseTaskMenu
            task={task}
            reportName={reportName}
            getReport={getVehicleTaskReport}
            xmlName={xmlName}
            getXml={getVehicleXml}
            duplicateVehicleRiskAsssessmentTask={
              duplicateVehicleRiskAsssessmentTask
            }
            taskType={TaskTypes.VehicleTask}
            vehicleTaskKind={task.kind}
            onChangeSuccess={(taskResult: RequestResult<BaseTask>) =>
              onChangeSuccess &&
              onChangeSuccess(taskResult as RequestResult<VehicleTask>)
            }
          />
        </>
      )}
    </div>
  );
};
