import { EditMeasureButton } from './edit-measure-button';
import { useTranslation } from 'react-i18next';
import { InvestigationTask } from '../../../../types/types';
import DisplayMeasure from './display-measure';
import { patchInvestigationTask } from '../../data/requests';
import { Dispatch, useEffect, useState } from 'react';
import { useToken } from '../../../../hooks';
import { RequestResult } from '../../../../data';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../../components/toast-notification';
import { InvestigationTaskMeasureDto } from '../../../../types/resource-models';
import { balModalController } from '../../../../controller/controllers';

interface DisplayMeasuresProps {
  investigationTask: InvestigationTask;
  setInvestigationTask?: Dispatch<RequestResult<InvestigationTask>>;
}

export const anyNameToShow = (measure: InvestigationTaskMeasureDto) =>
  measure.unknownPartner ||
  measure.policyHolder ||
  measure.additionalPartnersId.length > 0;

export const fullAnyNameToShow = (investigationTask: InvestigationTask) => {
  return (
    anyNameToShow(investigationTask.hisEntryMeasure) ||
    anyNameToShow(investigationTask.criminalChargesMeasure) ||
    anyNameToShow(investigationTask.civilClaimMeasure) ||
    anyNameToShow(investigationTask.regressMeasure) ||
    anyNameToShow(investigationTask.dejaVuEntryMeasure) ||
    anyNameToShow(investigationTask.artLossRegisterEntryMeasure) ||
    anyNameToShow(investigationTask.privatOrCicilConstitutionMeasure) ||
    anyNameToShow(investigationTask.policyHolderServicingMeasure)
  );
};

const DisplayMeasures = ({
  investigationTask,
  setInvestigationTask,
}: DisplayMeasuresProps) => {
  const { t } = useTranslation();
  const token = useToken();
  const requiredCapabilities = 'EditFraudInspectionTask';
  const [oldInvestigationTask, setOldInvestigationTask] =
    useState(investigationTask);

  useEffect(() => {
    setOldInvestigationTask(investigationTask);
  }, [investigationTask]);

  const save = async (newInvestigationTask: InvestigationTask) => {
    const result = await patchInvestigationTask(
      oldInvestigationTask,
      newInvestigationTask,
      token,
    );
    if (result.status === 'success') {
      setInvestigationTask && setInvestigationTask(result);
      balModalController.dismiss();
    } else if (result.status === 'error') {
      toast(ErrorToast(result.errorValue));
    }
  };

  return fullAnyNameToShow(investigationTask) || setInvestigationTask ? (
    <>
      <DisplayMeasure
        title={t('investigationMeasure.hisEntryMeasure')}
        measure={investigationTask.hisEntryMeasure}
        investigationTask={investigationTask}
        editComponent={
          setInvestigationTask && (
            <EditMeasureButton
              title={t('investigationMeasure.hisEntryMeasure')}
              investigationTask={investigationTask}
              measure={investigationTask.hisEntryMeasure}
              onSave={async (measure: InvestigationTaskMeasureDto) => {
                await save({
                  ...oldInvestigationTask,
                  hisEntryMeasure: measure,
                });
              }}
              requiredCapabilities={requiredCapabilities}
            />
          )
        }
      ></DisplayMeasure>
      <DisplayMeasure
        title={t('investigationMeasure.criminalChargesMeasure')}
        measure={investigationTask.criminalChargesMeasure}
        investigationTask={investigationTask}
        editComponent={
          setInvestigationTask && (
            <EditMeasureButton
              title={t('investigationMeasure.criminalChargesMeasure')}
              measure={investigationTask.criminalChargesMeasure}
              investigationTask={investigationTask}
              onSave={async (measure: InvestigationTaskMeasureDto) => {
                await save({
                  ...oldInvestigationTask,
                  criminalChargesMeasure: measure,
                });
              }}
              requiredCapabilities={requiredCapabilities}
            />
          )
        }
      ></DisplayMeasure>
      <DisplayMeasure
        title={t('investigationMeasure.civilClaimMeasure')}
        measure={investigationTask.civilClaimMeasure}
        investigationTask={investigationTask}
        editComponent={
          setInvestigationTask && (
            <EditMeasureButton
              title={t('investigationMeasure.civilClaimMeasure')}
              measure={investigationTask.civilClaimMeasure}
              investigationTask={investigationTask}
              onSave={async (measure: InvestigationTaskMeasureDto) => {
                await save({
                  ...oldInvestigationTask,
                  civilClaimMeasure: measure,
                });
              }}
              requiredCapabilities={requiredCapabilities}
            />
          )
        }
      ></DisplayMeasure>
      <DisplayMeasure
        title={t('investigationMeasure.regressMeasure')}
        measure={investigationTask.regressMeasure}
        investigationTask={investigationTask}
        editComponent={
          setInvestigationTask && (
            <EditMeasureButton
              title={t('investigationMeasure.regressMeasure')}
              measure={investigationTask.regressMeasure}
              investigationTask={investigationTask}
              onSave={async (measure: InvestigationTaskMeasureDto) => {
                await save({
                  ...oldInvestigationTask,
                  regressMeasure: measure,
                });
              }}
              requiredCapabilities={requiredCapabilities}
            />
          )
        }
      ></DisplayMeasure>
      <DisplayMeasure
        title={t('investigationMeasure.dejaVuEntryMeasure')}
        measure={investigationTask.dejaVuEntryMeasure}
        investigationTask={investigationTask}
        editComponent={
          setInvestigationTask && (
            <EditMeasureButton
              title={t('investigationMeasure.dejaVuEntryMeasure')}
              measure={investigationTask.dejaVuEntryMeasure}
              investigationTask={investigationTask}
              onSave={async (measure: InvestigationTaskMeasureDto) => {
                await save({
                  ...oldInvestigationTask,
                  dejaVuEntryMeasure: measure,
                });
              }}
              requiredCapabilities={requiredCapabilities}
            />
          )
        }
      ></DisplayMeasure>
      <DisplayMeasure
        title={t('investigationMeasure.artLossRegisterEntryMeasure')}
        measure={investigationTask.artLossRegisterEntryMeasure}
        investigationTask={investigationTask}
        editComponent={
          setInvestigationTask && (
            <EditMeasureButton
              title={t('investigationMeasure.artLossRegisterEntryMeasure')}
              measure={investigationTask.artLossRegisterEntryMeasure}
              investigationTask={investigationTask}
              onSave={async (measure: InvestigationTaskMeasureDto) => {
                await save({
                  ...oldInvestigationTask,
                  artLossRegisterEntryMeasure: measure,
                });
              }}
              requiredCapabilities={requiredCapabilities}
            />
          )
        }
      ></DisplayMeasure>
      <DisplayMeasure
        title={t('investigationMeasure.privatOrCicilConstitutionMeasure')}
        measure={investigationTask.privatOrCicilConstitutionMeasure}
        investigationTask={investigationTask}
        editComponent={
          setInvestigationTask && (
            <EditMeasureButton
              title={t('investigationMeasure.privatOrCicilConstitutionMeasure')}
              measure={investigationTask.privatOrCicilConstitutionMeasure}
              investigationTask={investigationTask}
              onSave={async (measure: InvestigationTaskMeasureDto) => {
                await save({
                  ...oldInvestigationTask,
                  privatOrCicilConstitutionMeasure: measure,
                });
              }}
              requiredCapabilities={requiredCapabilities}
            />
          )
        }
      ></DisplayMeasure>
      <DisplayMeasure
        title={t('investigationMeasure.policyHolderServicingMeasure')}
        measure={investigationTask.policyHolderServicingMeasure}
        investigationTask={investigationTask}
        editComponent={
          setInvestigationTask && (
            <EditMeasureButton
              title={t('investigationMeasure.policyHolderServicingMeasure')}
              measure={investigationTask.policyHolderServicingMeasure}
              investigationTask={investigationTask}
              onSave={async (measure: InvestigationTaskMeasureDto) => {
                await save({
                  ...oldInvestigationTask,
                  policyHolderServicingMeasure: measure,
                });
              }}
              requiredCapabilities={requiredCapabilities}
            />
          )
        }
      ></DisplayMeasure>
    </>
  ) : (
    <div></div>
  );
};

export default DisplayMeasures;
