import { BalText } from '@baloise/design-system-components-react';
import { concat } from '../../utils';
import { DisabableLink } from '../authorization';

type BreadcrumbProp = {
  label: string;
  path?: string;
};

type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbProp[];
};

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps): JSX.Element => {
  return (
    <div className="is-flex is-align-items-center">
      {breadcrumbs.map((breadcrumb, index) => {
        const hasPath = breadcrumb.path && breadcrumb.path.length > 0;
        const isLastBreadcrumb = index === breadcrumbs.length - 1;
        const label = (
          <BalText
            size="small"
            className="ml-1"
            color={isLastBreadcrumb ? 'primary' : 'primary-light'}
          >
            {concat([breadcrumb.label, isLastBreadcrumb ? '' : '>'])}
          </BalText>
        );
        return (
          <DisabableLink
            key={index}
            to={`${breadcrumb.path}`}
            withCapabilitiesError={!hasPath}
          >
            {label}
          </DisabableLink>
        );
      })}
    </div>
  );
};
