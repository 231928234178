import React from 'react';
import {
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalFieldMessage,
  BalInput,
} from '@baloise/design-system-components-react';
import { Props } from '@baloise/design-system-components/dist/types/props';
import '../../styles/general.scss';
import { concat } from '../../utils';

type BalTextFieldProps = {
  className?: string;
  titleClassName?: string;
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: CustomEvent<string | undefined>) => void;
  onInput?: (e: CustomEvent<string | undefined>) => void;
  onFocus?: () => void;
  onKeyPress?: (e: CustomEvent<KeyboardEvent>) => void;
  maxLength?: number;
  message?: string;
  type?: Props.BalInputInputType;
  disabled?: boolean;
  suffix?: string;
  mask?: 'contract-number' | 'claim-number' | 'offer-number';
};

export const BalTextField = React.forwardRef(
  (
    {
      className,
      titleClassName,
      title,
      placeholder,
      value,
      onChange,
      onInput,
      onFocus,
      onKeyPress,
      maxLength,
      message,
      type,
      disabled,
      suffix,
      mask,
    }: BalTextFieldProps,
    ref: React.Ref<HTMLBalInputElement>,
  ): JSX.Element => {
    return (
      <BalField
        className={concat(['is-gapless', className])}
        disabled={disabled}
      >
        {title && (
          <BalFieldLabel className={concat(['m-0', titleClassName])}>
            {title}
          </BalFieldLabel>
        )}
        <BalFieldControl className="m-0 p-0">
          <BalInput
            mask={mask}
            ref={ref}
            placeholder={placeholder}
            defaultValue={value}
            value={value ?? ''}
            onBalChange={onChange}
            onBalInput={onInput}
            onBalFocus={onFocus}
            onBalKeyPress={onKeyPress}
            maxLength={maxLength}
            type={type}
            suffix={suffix}
            // Workaround, since autocomplete="off" is not respected by edge.
            autocomplete="new-password"
          />
        </BalFieldControl>
        <div className="m-0">
          {message && (
            <BalFieldMessage color="danger">{message}</BalFieldMessage>
          )}
        </div>
      </BalField>
    );
  },
);
BalTextField.displayName = 'BalTextField';
