import {
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalText,
  BalButtonGroup,
  BalButton,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';

type RemoveFileConfirmationProps = {
  fileName: string;
  removeFile: (fileName: string) => Promise<void>;
};
export const RemoveFileConfirmationModal = ({
  fileName,
  removeFile,
}: RemoveFileConfirmationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">
          {t('general.deleteAttachmentModal.title')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>
          {t('general.deleteAttachmentModal.body', {
            fileName: fileName,
          })}
        </BalText>
        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>

          <BalButton
            color="danger"
            onClick={async (event) => {
              if (removeFile && event.detail == 1) {
                await removeFile(fileName);
                balModalController.dismiss();
              }
            }}
          >
            {t('general.buttons.delete')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};
