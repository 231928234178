import produce from 'immer';
import { RequestResult } from '../../../data';
import {
  ExpenseDto,
  InvestigationTaskJournalFilterCriteria,
  ResponseOfInvestigationTaskJournalListDto,
} from '../../../types/resource-models';
import { Globals } from '../../../utils';

export type InvestigationTaskJournalCombinedState = {
  journals: RequestResult<ResponseOfInvestigationTaskJournalListDto>;
  filter: InvestigationTaskJournalFilterCriteria;
  defaultFilter: InvestigationTaskJournalFilterCriteria;
  label: string;
};

export interface ResponseOfExpenseDto {
  entities: ExpenseDto[];
  totalPages: number;
}

export const initialInvestigationTaskJournalCombinedState: InvestigationTaskJournalCombinedState =
  {
    journals: { status: 'initial' },
    defaultFilter: { ...Globals.filterDefault, maxItemCount: 10 },
    filter: { ...Globals.filterDefault, maxItemCount: 10 },
    label: '-',
  };

export const getInitialInvestigationTaskJournalCombinedState = (
  investigationTaskId: string,
): InvestigationTaskJournalCombinedState => {
  const filter: InvestigationTaskJournalFilterCriteria = {
    ...Globals.filterDefault,
    maxItemCount: 10,
    ...({
      investigationTaskId: investigationTaskId,
    } as Partial<InvestigationTaskJournalFilterCriteria>),
  };

  return produce(initialInvestigationTaskJournalCombinedState, (draftState) => {
    draftState.defaultFilter = {
      ...Globals.filterDefault,
      maxItemCount: 10,
      ...(filter as Partial<InvestigationTaskJournalFilterCriteria>),
    };
    draftState.filter = draftState.defaultFilter;
    draftState.label = '-';
  });
};

/*export const getInitialInvestigationTaskExpenseCombinedState = (
  investigationTaskId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  investigationTask: InvestigationTask,
): InvestigationTaskExpenseCombinedState => {
  const filter: ExpenseFilterCriteria = {
    ...Globals.filterDefault,
    maxItemCount: 10,
    ...({
      investigationTaskId: investigationTaskId,
    } as Partial<ExpenseFilterCriteria>),
  };

  return produce(initialInvestigationTaskExpenseCombinedState, (draftState) => {
    draftState.defaultFilter = {
      ...Globals.filterDefault,
      maxItemCount: 10,
      ...(filter as Partial<ExpenseFilterCriteria>),
    };
    draftState.filter = draftState.defaultFilter;
    draftState.label = '-';
    draftState.expenses.status = 'success';
    if (draftState.expenses.status === 'success') {
      draftState.expenses = investigationTask.expenses;
    }
  });
};*/
