import {
  BalClose,
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalFieldMessage,
  BalSelect,
  BalSelectOption,
} from '@baloise/design-system-components-react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/general.scss';
import { FormValidation } from '../../types/types';

const BalMultipleTypeAheadField = <T,>({
  className,
  id,
  label,
  placeholder,
  value,
  result,
  valueFormatter,
  keyFormatter,
  onChange,
  disabled,
  filter,
  formValidation,
  blocker,
}: {
  className?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  value?: Array<T>;
  result: Array<T> | undefined;
  valueFormatter: (value: T) => string;
  keyFormatter: (value: T) => string;
  onChange: (choice: T[] | undefined) => void;
  disabled?: boolean;
  filter?: (value: T, index: number, array: T[]) => unknown;
  formValidation?: FormValidation;
  blocker?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const getAllKeysFormatted = (value: Array<T>): string[] => {
    const result: string[] = [];
    value.forEach((item: T) => {
      result.push(keyFormatter(item));
    });
    return result;
  };

  const selectInput = useRef<HTMLBalSelectElement>(null);

  // Hide close button in each value when disabled manually, because BalSelect doesn't do it.
  if (selectInput?.current) {
    const list = selectInput.current.getElementsByTagName('bal-close');
    const closeArray = Array.from(list);
    closeArray.forEach((element: HTMLBalCloseElement) => {
      element.style.display = disabled ? 'none' : 'flex';
    });
    const inputs = selectInput.current.getElementsByTagName('input');
    const inputsArray = Array.from(inputs);
    inputsArray.forEach((element: HTMLInputElement) => {
      element.style.maxWidth = disabled ? '0rem' : '99rem';
    });
  }

  const balSelectRef = useRef<HTMLBalSelectElement>(null);
  useEffect(() => {
    const resetValueF = async () => {
      if (balSelectRef.current && value?.length === 0) {
        await balSelectRef.current.clear();
        // After clearing, the object can became null.
        if (balSelectRef.current) {
          balSelectRef.current.setAttribute('value', '');
        }
      }
    };
    resetValueF();
  }, [value]);
  return (
    <>
      <BalField className={className} disabled={disabled}>
        {label && <BalFieldLabel>{label}</BalFieldLabel>}
        <div className="is-flex is-align-items-center">
          <BalFieldControl className="m-0 is-full-width p-0">
            <BalSelect
              {...(formValidation ? formValidation.register : {})}
              ref={selectInput}
              disabled={disabled}
              id={id}
              typeahead
              multiple
              placeholder={placeholder ?? t('general.pleaseChoose')}
              value={value ? getAllKeysFormatted(value) : []}
              onBalChange={(e) => {
                if (result && e.detail !== null && !blocker) {
                  const selectedValues = e.detail ?? undefined;
                  const selectedItems: T[] = [];
                  result.forEach((resultItem: T) => {
                    if (selectedValues?.includes(keyFormatter(resultItem))) {
                      selectedItems.push(resultItem);
                    }
                  });
                  onChange(selectedItems);

                  if (formValidation) {
                    formValidation.register.onChange(e);
                  }
                }
              }}
            >
              {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
              <div className="bal-select__inner">
                {result &&
                  result.filter(filter ?? (() => true)).map((element) => {
                    if (element) {
                      const text = valueFormatter(element);
                      const key = keyFormatter(element);
                      return (
                        <BalSelectOption
                          key={key}
                          value={key}
                          label={text}
                          disabled={disabled}
                        >
                          {text}
                        </BalSelectOption>
                      );
                    }
                  })}
              </div>
            </BalSelect>
          </BalFieldControl>
          {value && !disabled && !blocker && (
            <BalClose
              className="ml-2"
              onClick={() => {
                onChange([]);
              }}
            />
          )}
        </div>
        {formValidation && formValidation.formState.errors ? (
          <BalFieldMessage color="danger">
            {formValidation &&
              formValidation.formState.errors[formValidation.register.name] &&
              t('error.mandatoryField')}
          </BalFieldMessage>
        ) : (
          <></>
        )}
      </BalField>
    </>
  );
};

export default BalMultipleTypeAheadField;
