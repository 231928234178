import { GridOptions } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import {
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { UserContext } from '../../../context';
import {
  buildGridOptions,
  getAllBaseGridOptions,
} from '../../base-task/components/base-task-search-form/base-task-search-form-table-grid-options';
import { RequestResult } from '../../../data';
import { BaseTaskListDto } from '../../../types/resource-models';

export const useGridOptions = (
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): [GridOptions, React.Dispatch<GridOptions>] => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const userTableType = 'InvestigationTask';

  const [gridOptions, setGridOptions] = useState<GridOptions>(
    buildGridOptions(
      user,
      setUser,
      t,
      userTableType,
      getAllBaseGridOptions,
      setTask,
    ),
  );

  useEffect(() => {
    setGridOptions(
      buildGridOptions(
        user,
        setUser,
        t,
        userTableType,
        getAllBaseGridOptions,
        setTask,
      ),
    );
  }, [user, setUser, t, userTableType, setTask]);

  return [gridOptions, setGridOptions];
};
