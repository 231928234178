import { GridOptions } from 'ag-grid-community';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import { RequestResult } from '../../../data';
import {
  BaseTaskListDto,
  PropertyInspectionTaskListDto,
  UserTableSettingDto,
  UserTableTypes,
} from '../../../types/resource-models';
import { convertUtcToLocal } from '../../../utils/date';
import {
  earlybyteTableTextRenderer,
  noComparingComparator,
} from '../../../utils/utilities';
import {
  buildGridOptions,
  getAllBaseGridOptions,
} from '../../base-task/components/base-task-search-form/base-task-search-form-table-grid-options';

export const useGridOptions = (
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): [GridOptions, React.Dispatch<GridOptions>] => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const userTableType = 'PropertyInspectionTask';

  const [gridOptions, setGridOptions] = useState<GridOptions>(
    buildGridOptions(
      user,
      setUser,
      t,
      userTableType,
      getPropertyInspectionTasksGridOptions,
      setTask,
    ),
  );

  useEffect(() => {
    setGridOptions(
      buildGridOptions(
        user,
        setUser,
        t,
        userTableType,
        getPropertyInspectionTasksGridOptions,
        setTask,
      ),
    );
  }, [user, setUser, t, userTableType, setTask]);

  return [gridOptions, setGridOptions];
};

const getPropertyInspectionTasksGridOptions = (
  t: TFunction,
  onSelectedColumns: (userTableSettingDto: UserTableSettingDto) => void,
  userTableType: UserTableTypes,
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): GridOptions => {
  const gridOptions = getAllBaseGridOptions(
    t,
    onSelectedColumns,
    userTableType,
    setTask,
  );
  if (gridOptions.columnDefs) {
    gridOptions.columnDefs.push({
      valueGetter: (params) => {
        const cause = (params.data as PropertyInspectionTaskListDto).claim
          .propertyInspectionTaskCause;
        if (cause) {
          return t(`inspectionTask.causes.${cause}`);
        } else {
          return '';
        }
      },
      headerName: t('inspectionTask.cause'),
      field: 'Claim.Cause.Value',
      cellRenderer: earlybyteTableTextRenderer,
      comparator: noComparingComparator,
    });

    gridOptions.columnDefs.push({
      valueGetter: (params) => {
        const scheduledInspectionDate = convertUtcToLocal({
          date: (params.data as PropertyInspectionTaskListDto)
            .scheduledInspectionDate,
          formatted: true,
          notIncludeSeconds: true,
        });
        if (scheduledInspectionDate) {
          return scheduledInspectionDate;
        } else {
          return '';
        }
      },
      headerName: t('inspectionTask.scheduledInspectionDate'),
      field: 'scheduledInspectionDate',
      cellRenderer: earlybyteTableTextRenderer,
      comparator: noComparingComparator,
    });
  }
  return gridOptions;
};
