import {
  BalCard,
  BalHeading,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { EBRoutes } from '../../../router/router';
import { DisabableLinkButton } from '../../../components/authorization';

export const VehicleTaskGuidelinesView = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="p5 mt-2 mx-7 columns is-multiline">
      <BalCard className="p5 mx-7 columns column is-8 is-multiline">
        <BalHeading className="column is-12 " space="none" level="h2">
          {t('vehicleTask.guidelines.cardTitle')}
        </BalHeading>
        <BalText className="column is-8">
          {t('vehicleTask.guidelines.text1')}
        </BalText>
        <div className="column is-12">
          <DisabableLinkButton
            outlined
            to={EBRoutes.VEHICLE_INSPECTION_TASK_WIZARD}
            linkParams={{}}
            color="primary"
            icon="plus"
          >
            {t('vehicleTask.guidelines.button')}
          </DisabableLinkButton>
        </div>
        <div className="mt-2 columns is-multiline pb-5 px-5 top-blue-line">
          <div className="column is-half py-0 pr-8">
            <BalHeading subtitle level="h4" space="top" className="mb-2">
              {t('vehicleTask.guidelines.subtitle1')}
            </BalHeading>
            <BalText>{t('vehicleTask.guidelines.text2')}</BalText>
          </div>
          <div className="column is-half py-0 pr-8">
            <BalHeading subtitle level="h4" space="top" className="mb-2">
              {t('vehicleTask.guidelines.subtitle2')}
            </BalHeading>
            <BalText>{t('vehicleTask.guidelines.text3')}</BalText>
          </div>
          <div className="column is-half py-0 pr-8">
            <BalHeading subtitle level="h4" space="top" className="mb-2">
              {t('vehicleTask.guidelines.subtitle3')}
            </BalHeading>
            <BalText className="has-text-justified">
              {t('vehicleTask.guidelines.text4')}
            </BalText>
          </div>
        </div>
      </BalCard>
    </div>
  );
};
