import { RequestResult } from '../../../../data';
import { BaseTaskStatus } from '../../../../types/resource-models';
import {
  AccidentInspectionTask,
  BaseTask,
  TaskTypes,
} from '../../../../types/types';
import { WithCapabilities } from '../../../../components/authorization';
import { useContext } from 'react';
import { UserContext } from '../../../../context';
import { concat } from '../../../../utils';
import AccidentInspectionTaskRejectModal from './accident-inspection-task-reject';
import AccidentInspectionTaskReassign from './accident-inspection-task-reassign';
import AccidentInspectionTaskCompletion from './accident-inspection-task-completion';
import AccidentInspectionTaskInProgressButton from './accident-inspection-task-in-progress';
import { getAccidentInspectionTaskReport } from '../../../files/data/requests';
import { BaseTaskMenu } from '../../../base-task';
import { BalButton } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EBRoutes } from '../../../../router/router';
import { BaseTaskDeleteButton } from '../../../base-task/components';

type AccidentTaskWorkflowButtonsProps = {
  task: AccidentInspectionTask;
  className?: string;
  onChangeSuccess?: (task: RequestResult<AccidentInspectionTask>) => void;
};

const showCloseButtonStates: BaseTaskStatus[] = ['InProgress'];
const showAcceptButtonStates: BaseTaskStatus[] = ['Assigned'];
const showRejectButtonStates: BaseTaskStatus[] = ['Assigned'];
const showDraftButtonStates: BaseTaskStatus[] = ['New'];
const showReassignButtonStates: BaseTaskStatus[] = [
  'Assigned',
  'InProgress',
  'Rejected',
];
const showMoreButtonStates: BaseTaskStatus[] = [
  'Assigned',
  'InProgress',
  'Rejected',
  'Closed',
];

export const AccidentTaskWorkflowButtons = ({
  task,
  className,
  onChangeSuccess,
}: AccidentTaskWorkflowButtonsProps): JSX.Element => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAssignee =
    (user.status === 'success' || user.status === 'loading-with-value') &&
    user.value.id === task.assignee?.id;
  const reportName = `${task.claim.claimNumber}_report.pdf`;

  return (
    <div className={concat(['is-justify-content-space-between', className])}>
      <div className="is-flex is-align-items-center">
        {isAssignee && showAcceptButtonStates.includes(task.status) && (
          <WithCapabilities
            requiredCapabilities={['EditAccidentInspectionTask']}
            passWithCapabilitiesPropsToChildren
          >
            <AccidentInspectionTaskInProgressButton
              task={task}
              onChangeSuccess={onChangeSuccess}
            />
          </WithCapabilities>
        )}
        {isAssignee && showRejectButtonStates.includes(task.status) && (
          <AccidentInspectionTaskRejectModal
            task={task}
            onRejectSuccess={onChangeSuccess}
          />
        )}
        {showReassignButtonStates.includes(task.status) && (
          <AccidentInspectionTaskReassign
            task={task}
            onReassignSuccess={onChangeSuccess}
          />
        )}
        {isAssignee &&
          showCloseButtonStates.includes(task.status) &&
          onChangeSuccess && (
            <AccidentInspectionTaskCompletion
              task={task}
              onChangeSuccess={onChangeSuccess}
            />
          )}
        {showDraftButtonStates.includes(task.status) &&
          onChangeSuccess &&
          task.deletedAt === null && (
            <>
              <BaseTaskDeleteButton
                className="ml-3"
                task={task}
                taskType={TaskTypes.AccidentInspectionTask}
                onChangeSuccess={onChangeSuccess}
                requiredCapabilities={['EditAccidentInspectionTask']}
              />
              <BalButton
                color="info"
                className="ml-3"
                size="small"
                onClick={(event) => {
                  if (event.detail == 1) {
                    navigate(
                      EBRoutes.ACCIDENT_INSPECTION_TASK_WIZARD.create({
                        query: {
                          claimNumber: task.claim.claimNumber
                            .replaceAll('/', '')
                            .replace('.', ''),
                        },
                      }),
                    );
                    navigate(0);
                  }
                }}
              >
                {t('inspectionTask.inspectionTaskWorkflow.toWizard')}
              </BalButton>
            </>
          )}
      </div>
      {showMoreButtonStates.includes(task.status) && (
        <BaseTaskMenu
          task={task}
          reportName={reportName}
          getReport={getAccidentInspectionTaskReport}
          taskType={TaskTypes.AccidentInspectionTask}
          onChangeSuccess={(taskResult: RequestResult<BaseTask>) =>
            onChangeSuccess &&
            onChangeSuccess(taskResult as RequestResult<AccidentInspectionTask>)
          }
        />
      )}
    </div>
  );
};
