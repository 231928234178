import {
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalButtonGroup,
  BalField,
  BalRadioGroup,
  BalRadio,
  BalFieldMessage,
  BalTextarea,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { VehicleTask } from '../../../types/types';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import { balModalController } from '../../../controller/controllers';
import { PolicyHolderContactMethod } from '../../../types/resource-models';
import { Globals } from '../../../utils';
import { toast } from 'react-toastify';
import { ToastNotification } from '../../../components/toast-notification';

type VehicleTaskSendProps = {
  task: VehicleTask;
  onSendSuccess?: (task: RequestResult<VehicleTask>) => void;
  patchVehicleTask: (
    vehicleTask: VehicleTask,
    vehicleTaskNew: VehicleTask,
    bearerToken: string,
    sendToAdos?: boolean,
    sendToAssigneeGarageCraftsman?: boolean,
  ) => Promise<RequestResult<VehicleTask>>;
};

export const VehicleTaskSendModal = ({
  task,
  onSendSuccess,
  patchVehicleTask,
}: VehicleTaskSendProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const [
    assigneeGarageCraftsmanContactMethod,
    setAssigneeGarageCraftsmanContactMethod,
  ] = useState<PolicyHolderContactMethod | undefined>(
    task.assigneeGarageCraftsmanContactMethod,
  );
  const [assigneeGarageCraftsmanRemarks, setAssigneeGarageCraftsmanRemarks] =
    useState<string | undefined>(task.assigneeGarageCraftsmanRemarks);
  const methods = useForm({ mode: 'onChange' });
  const [submitting, setSubmitting] = useState(false);

  return (
    <form
      onSubmit={methods.handleSubmit(() => {
        if (!submitting) {
          setSubmitting(true);
          onSendSuccess && onSendSuccess({ status: 'loading' });
          patchVehicleTask(
            task,
            produce(task, (draftState) => {
              draftState.assigneeGarageCraftsmanContactMethod =
                assigneeGarageCraftsmanContactMethod;
              draftState.assigneeGarageCraftsmanRemarks =
                assigneeGarageCraftsmanRemarks;
            }),
            token,
            false,
            true,
          ).then((task: RequestResult<VehicleTask>) => {
            if (task.status === 'success' && onSendSuccess) {
              toast(
                ToastNotification({
                  message: t('vehicleTask.sendingToExternalPartner'),
                  color: 'success',
                }),
              );
              onSendSuccess(task);
            }
            balModalController.dismiss();
            setSubmitting(false);
          });
        }
      })}
    >
      <BalModalHeader>
        <BalHeading level="h3">
          {t('vehicleTask.sendToExternalModal.title')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div className="columns">
          <div className="column pr-0"></div>
          <div className="column is-narrow pl-0 pt-0"></div>
        </div>
        <div className="columns is-3 is-multiline px-4">
          {t('vehicleTask.sendToExternalModal.hint')}
        </div>
        <div className="mb-2 mt-5">
          <BalField className="is-gapless column is-full-width">
            <BalHeading space="none" level="h5" subtitle>
              {t('vehicleTask.policyHolderContactMethod')}
            </BalHeading>
            <Controller
              name="policyHolderContactMethod"
              rules={{ required: t('error.mandatoryField') }}
              control={methods.control}
              defaultValue={assigneeGarageCraftsmanContactMethod}
              render={({ field, fieldState }) => (
                <>
                  <BalRadioGroup
                    {...field}
                    interface="select-button"
                    value={assigneeGarageCraftsmanContactMethod}
                    onBalChange={(e) => {
                      setAssigneeGarageCraftsmanContactMethod(
                        e.detail as PolicyHolderContactMethod,
                      );
                      field.onChange(e.detail);
                    }}
                  >
                    {Globals.policyHolderContactMethod.map((contactMethod) => {
                      return (
                        <BalRadio key={contactMethod} value={contactMethod}>
                          <span className="pl-2">
                            {t(
                              `policyHolderContactMethodType.${contactMethod}`,
                            )}
                          </span>
                        </BalRadio>
                      );
                    })}
                  </BalRadioGroup>
                  <div className="m-0">
                    {fieldState && (
                      <BalFieldMessage color="danger">
                        {fieldState.error && fieldState.error.message}
                      </BalFieldMessage>
                    )}
                  </div>
                </>
              )}
            />
          </BalField>
          <BalField className="is-gapless column is-full-width">
            <BalHeading space="none" level="h5" subtitle>
              {t('vehicleTask.assigneeGarageCraftsmanRemarks')}
            </BalHeading>
            <BalTextarea
              id="assigneeGarageCraftsmanRemarks'"
              placeholder={t('general.claim.remarks')}
              rows={4}
              value={assigneeGarageCraftsmanRemarks}
              onBalInput={(e: CustomEvent<string | undefined>) => {
                if (e.detail || e.detail === '') {
                  setAssigneeGarageCraftsmanRemarks(e.detail);
                }
              }}
            />
          </BalField>
        </div>
        <BalButtonGroup position="right" className="mt-8">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton color="primary" elementType="submit" disabled={submitting}>
            {t('general.buttons.saveAndSend')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </form>
  );
};
