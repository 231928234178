import {
  BalButtonGroup,
  BalButton,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BalTextareaFormField } from '../../../../components/input';
import { ErrorToast } from '../../../../components/toast-notification';
import { RequestResult } from '../../../../data';
import { useToken } from '../../../../hooks';
import { InvestigationTask, TaskTypes } from '../../../../types/types';
import { updateBaseTask } from '../../../base-task/data/requests';

export interface InvestigationTaskSummaryFieldsFormProps {
  task: RequestResult<InvestigationTask>;
  setTask: Dispatch<RequestResult<InvestigationTask>>;
  onSave?: (task: RequestResult<InvestigationTask>) => void;
  onClose: () => void;
}
export const InvestigationTaskSummaryFieldsForm = ({
  task,
  setTask,
  onSave,
  onClose,
}: InvestigationTaskSummaryFieldsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const methods = useForm({
    mode: 'onChange',
  });
  const { handleSubmit, formState } = methods;
  const [saving, setSaving] = useState<boolean>(false);
  const [localTask, setLocalTask] =
    useState<RequestResult<InvestigationTask>>(task);

  const [summary, setSummary] = useState<string | undefined>(
    task.status === 'success' ? task.localValue.summary : undefined,
  );

  useEffect(() => {
    if (
      localTask.status === 'success' &&
      localTask.localValue.summary !== summary
    ) {
      setLocalTask(
        produce(localTask, (draft) => {
          draft.localValue.summary = summary;
        }),
      );
    }
  }, [localTask, setLocalTask, summary]);

  const onSubmit = async (saveAndSend: boolean) => {
    if (localTask.status === 'success') {
      if (!saving) {
        setSaving(true);
        const result = await updateBaseTask(
          localTask.value,
          localTask.localValue,
          TaskTypes.InvestigationTask,
          token,
          saveAndSend,
        );
        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        } else {
          setTask(result);
          if (onSave) {
            onSave(task);
          }
        }
        setSaving(false);
      }
    }
  };

  return localTask.status === 'success' ? (
    <form
      onSubmit={handleSubmit(async () => {
        await onSubmit(false);
      })}
    >
      <div className="rows is-multiline is-gapless is-full-width mt-1">
        <BalTextareaFormField
          controllerName="summary"
          methods={methods}
          required={false}
          className="row"
          richText
          richTextHeight="175px"
          placeholder={t('investigationTask.summary')}
          title={t('investigationTask.summary')}
          value={summary}
          onChange={(text: string) => {
            setSummary(text);
          }}
        />
        <BalTextareaFormField
          controllerName="recommendation"
          methods={methods}
          required={false}
          className="row"
          richText
          richTextHeight="175px"
          placeholder={t('investigationTask.recommendation')}
          title={t('investigationTask.recommendation')}
          value={localTask.localValue.recommendation}
          onChange={(text: string) => {
            setLocalTask(
              produce(localTask, (draft) => {
                draft.localValue.recommendation = text;
              }),
            );
          }}
        />
      </div>
      <div>
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) => event.detail == 1 && onClose()}
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            elementType="submit"
            color="info"
            disabled={
              !formState.isValid && Object.keys(formState.errors).length !== 0
            }
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </div>
    </form>
  ) : (
    <></>
  );
};
