import { balModalController as coreModalController } from '@baloise/design-system-components';
import { ReactDelegate } from './framework-delegate';

const oldModalCreate = coreModalController.create.bind(coreModalController);
coreModalController.create = (options) => {
  return oldModalCreate({
    ...options,
    delegate: {
      ...ReactDelegate(),
    },
  });
};

export const balModalController = coreModalController;
