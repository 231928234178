import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../../context';
import { balModalController } from '../../../controller/controllers';
import { postCraftsMan } from '..';
import { Craftsman } from '../../../types/types';
import {
  getDefaultPropertyCraftsman,
  getDefaultVehicleCraftsman,
} from '../../../utils/utilities';
import { useToken } from '../../../hooks';
import { NetworkPartner } from './create-edit-network-partner-modules';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import {
  DisabableButton,
  WithCapabilities,
} from '../../../components/authorization';
import { useNavigate } from 'react-router';
import { EBRoutes } from '../../../router/router';
import { BrowserRouter } from 'react-router-dom';
import { Branch } from '../../../types/resource-models';
import { CancelButton } from '../../../components/ui';

type CreateEditPartnerProps =
  | { className?: string; action: 'create'; filterBranch?: Branch }
  | {
      className?: string;
      action: 'edit';
      partnerId: string;
      onChangeSuccess?: (partnerId: string) => void;
    };

type PartnerProps = {
  partnerId: string;
  onChangeSuccess?: (partnerId: string) => void;
};

type CreatePartnerProps = {
  userId: string;
  filterBranch: Branch | undefined;
} & PartnerProps;

export const CreateEditNetworkPartner = (
  props: CreateEditPartnerProps,
): JSX.Element => {
  // TODO: Open Ticket for max height for modal. modal-container Class doesn't work in whatever container I put it in this issue.
  return (
    <div className={props.className}>
      {props.action === 'create' ? (
        <CreatePartner
          userId=""
          partnerId=""
          filterBranch={props.filterBranch}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

const CreatePartnerModalWithRouter = (props: CreatePartnerProps) => {
  return (
    <BrowserRouter>
      <CreatePartnerModal {...props} />
    </BrowserRouter>
  );
};

const CreatePartnerModal = ({
  // Need to pass userId from outside, since we can't use UserContext in the modal
  userId,
  filterBranch,
}: CreatePartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const methods = useForm({ mode: 'onChange' });
  const [partner, setPartner] = useState<Craftsman>(
    filterBranch == 'Property'
      ? getDefaultPropertyCraftsman(userId)
      : getDefaultVehicleCraftsman(userId),
  );
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  return (
    <div>
      <BalModalHeader>
        <BalHeading className="mb-5" space="none" level="h3">
          {t('networkPartners.addPartner')}
        </BalHeading>
        <div className="columns is-multiline">
          <div className="columns column is-gapless is-full">
            <BalButton
              className="column is-narrow mr-3"
              size="small"
              color="info"
              outlined={partner.type !== 'propertyCraftsman'}
              onClick={(event) =>
                event.detail == 1 &&
                setPartner(getDefaultPropertyCraftsman(userId))
              }
            >
              {t('networkPartners.PropertyCraftsman')}
            </BalButton>
            <BalButton
              className="column is-narrow"
              size="small"
              color="info"
              outlined={partner.type !== 'vehicleCraftsman'}
              onClick={(event) =>
                event.detail == 1 &&
                setPartner(getDefaultVehicleCraftsman(userId))
              }
            >
              {t('networkPartners.VehicleCraftsman')}
            </BalButton>
          </div>
        </div>
      </BalModalHeader>
      <BalModalBody>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(() => {
              if (!submitting) {
                setSubmitting(true);
                postCraftsMan(token, partner).then((result) => {
                  if (result.status === 'success') {
                    toast(
                      ToastNotification({
                        message: t('networkPartners.create.successMessage'),
                        color: 'success',
                      }),
                    );
                    navigate(
                      EBRoutes.NETWORK_PARTNER_DETAIL.create({
                        networkPartnerId:
                          partner.type === 'propertyCraftsman'
                            ? partner.propertyCraftsman.id
                            : partner.vehicleCraftsman.id,
                      }),
                    );
                    navigate(0);
                    balModalController.dismiss();
                  } else if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                  setSubmitting(false);
                });
              }
            })}
          >
            <NetworkPartner partner={partner} setPartner={setPartner} />
            <BalButtonGroup position="right" className="mt-5">
              <CancelButton />
              <BalButton
                elementType="submit"
                color="primary"
                disabled={submitting}
              >
                {t('general.buttons.save')}
              </BalButton>
            </BalButtonGroup>
          </form>
        </FormProvider>
      </BalModalBody>
    </div>
  );
};

const CreatePartner = ({ filterBranch }: CreatePartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const openModal = async () => {
    const modal = await balModalController.create({
      component: CreatePartnerModalWithRouter,
      cssClass: 'center-modal',
      componentProps: {
        userId:
          user.status === 'success' || user.status === 'loading-with-value'
            ? user.value.id
            : '',
        filterBranch: filterBranch,
      },
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['CreateNetworkPartner']}
      passWithCapabilitiesPropsToChildren
    >
      <DisabableButton
        onClick={(event) => event.detail == 1 && openModal()}
        className="column is-narrow"
        color="primary"
        icon="plus"
      >
        {t('networkPartners.createPartner')}
      </DisabableButton>
    </WithCapabilities>
  );
};
