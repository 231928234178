import { useMap } from 'react-leaflet';

export const MapCenter = ({
  center,
  zoom,
}: {
  center: L.LatLngExpression;
  zoom: number;
}): JSX.Element => {
  const map = useMap();
  map.setView(center, zoom);

  return <></>;
};
