import {
  Branch,
  Language,
  Recommendation,
  PreferedContact,
  Region,
  VehicleSpecialtyNames,
  Gender,
  VehicleRegress,
  AddressDto,
  BaseFilterCriteria,
  VehicleBrandNames,
  PartnerRole,
  PolicyHolderContactMethod,
  TaskAssessment,
  JournalInsuranceSurveyType,
  JournalResearchType,
  JournalType,
  InvestigationTaskJournalDto,
  InvestigationTaskStatus,
  UserDto,
  VehicleTaskKind,
  ExpenseTypeDto,
  InvestigationCheatingParty,
  BaseTaskPriority,
  AppCapabilityNames,
  BaseTaskStatus,
  InvestigationTaskJournalListDto,
  InsuranceSurveyDto,
  InsuranceSurveyType,
  InsuranceSurveyResponse,
  PropertyInspectionTaskCause,
  PropertyInspectionTaskJournalDto,
  PropertyInspectionTaskJournalType,
  RecipientType,
  PropertyInspectionTaskRecipientDto,
} from './types/resource-models';
import {
  AffectedPartner,
  BaseTask,
  BaseTaskStatusGroupedFilter,
  Claim,
  Partner,
  TaskTypes,
  TranslatedSelection,
  WithNone,
} from './types/types';
import { v4 as guid } from 'uuid';
import {
  InvestigationTaskType,
  VehicleType,
} from '../../bat-shared/resource-models';
import {
  EstimatedSavings,
  InsuranceType,
  VehicleBonusCorrectionReason,
} from './types/resource-models';
import {
  InvestigationTaskCompletionCode,
  JournalAssessmentType,
} from '../../bat-shared/resource-models';
import {
  AccidentInspectionTaskCategory,
  Compensation,
} from './types/resource-models';
import { t } from 'i18next';
import { InvestigationTask } from './types/types';
import { Props } from '@baloise/design-system-components/dist/types/props';
import { DateTime } from 'luxon';
import { RequestResult } from './data';

export type Delimiter =
  | 'none'
  | 'space'
  | 'comma'
  | 'comma-space'
  | 'space-hyphen-space'
  | 'space-slash-space';

export const delimiterMap: Record<Delimiter, string> = {
  none: '',
  space: ' ',
  comma: ',',
  'comma-space': ', ',
  'space-hyphen-space': ' - ',
  'space-slash-space': ' / ',
};

export const concat = (
  list: (string | undefined)[],
  delimiter: Delimiter = 'space',
): string =>
  list.filter((s) => s && s.length > 0).join(delimiterMap[delimiter]);

export const concatCommaSpace = (list: (string | undefined)[]): string =>
  concat(list, 'comma-space');

export const concatAddress = (address: AddressDto | undefined): string =>
  address
    ? concat(
        [
          concat([address.street, address.houseNumber]),
          address.addition,
          concat([
            address.zipCode !== 0 ? address.zipCode?.toString() : undefined,
            address.city,
          ]),
        ],
        'comma-space',
      )
    : '';

export class Globals {
  public static readonly noneValue = 'None';

  public static readonly baseTaskPriorities: BaseTaskPriority[] = [
    'Low',
    'Medium',
    'High',
  ];

  public static readonly filterDefault: BaseFilterCriteria = {
    maxItemCount: 50,
    orderBy: 'asc',
    page: 0,
  };

  public static readonly craftsmanFilterDefault: BaseFilterCriteria = {
    maxItemCount: 9999,
    orderBy: 'asc',
    page: 0,
  };

  public static readonly journalDefault: InvestigationTaskJournalDto = {
    id: '00000000-0000-0000-0000-000000000000',
    journalType: 'Phone',
    journalSubtype: '',
    createdAt: '',
    creator: undefined,
    creatorId: undefined,
    status: 'New',
    confidential: true,
    schemaId: undefined,
    investigationTaskId: '',
    note: '',
    attachments: [],
  };

  public static readonly propertyInspectionTaskJournalDefault: PropertyInspectionTaskJournalDto =
    {
      id: '00000000-0000-0000-0000-000000000000',
      orderNumber: 100,
      journalType: 'PhoneEmail',
      createdAt: '',
      creator: undefined,
      creatorId: '00000000-0000-0000-0000-000000000000',
      confidential: false,
      propertyInspectionTaskId: '',
      note: '',
      attachments: [],
      updateToAdos: false,
    };

  public static readonly propertyInspectionTaskRecipientDefault: PropertyInspectionTaskRecipientDto =
    {
      id: '00000000-0000-0000-0000-000000000000',
      propertyInspectionTaskId: '00000000-0000-0000-0000-000000000000',
      recipientType: 'Additional',
    };

  public static readonly insuranceSurveyDefault: InsuranceSurveyDto = {
    id: '00000000-0000-0000-0000-000000000000',
    insuranceSurveyType: 'PropertyAndVehicle',
    startDate: DateTime.utc().toString(),
    insurance: '',
    response: 'Open',
    hasAttachments: false,
    investigationTaskId: '',
    attachments: [],
  };

  public static readonly allStates: BaseTaskStatusGroupedFilter[] = [
    'New',
    'Assigned',
    'InProgress',
    'SentToArchive',
    'Rejected',
    'Closed',
  ];

  public static readonly allVehicleTaskStates: BaseTaskStatusGroupedFilter[] = [
    'New',
    'Assigned',
    'InProgress',
    'Rejected',
    'Closed',
  ];

  public static readonly allBranches: Branch[] = [
    'None',
    'GeneralLiability',
    'MotorVehicle',
    'MotorVehicleLiability',
    'Property',
    'Technical',
    'Accident',
    'Health',
    'Transport',
    'IndividualLife',
    'GroupLife',
  ];

  public static readonly allInsuranceTypes: InsuranceType[] = [
    'None',
    'AccidentPetty',
    'AccidentSingle',
    'AccidentGroup',
    'AccidentAgricultural',
    'AccidentMotorVehiclePassenger',
    'AccidentLiechtenstein',
    'AccidentStudents',
    'AccidentChildren',
    'AccidentSupplement',
    'AccidentDamage',
    'HealthHospital',
    'HealthDailySicknessAllowance',
    'HealthAmbulatory',
    'HealthCure',
    'HealthMediplan',
    'HealthBaloiseCare',
    'GeneralLiabilityAnnullationTravel',
    'GeneralLiabilityPersonalLiability',
    'GeneralLiabilityHuntingLiability',
    'GeneralLiabilityPublicLiability',
    'GeneralLiabilityBuildingLiability',
    'GeneralLiabilityAgriculturalLiability',
    'GeneralLiabilityBuilderLiability',
    'GeneralLiabilityBikeMotorcycleLiability',
    'GeneralLiabilityDepositConfidentialDamage',
    'GeneralLiabilityFinancialLoss',
    'GeneralLiabilityCompanyClosure',
    'GeneralLiabilityPublicLiabilityDamage',
    'MotorVehicleLiability',
    'MotorVehicleCollisionCoverage',
    'MotorVehiclePartialCoverage',
    'MotorVehicleFullCoverage',
    'MotorVehicleLegalProtection',
    'PropertyElementalVehicleBuilding',
    'PropertyElementalNormalVehicle',
    'PropertyElementalNormalBuilding',
    'PropertyEarthquakeBuilding',
    'PropertySpecialVehicle',
    'PropertySpecialBuilding',
    'PropertyFireVehicleBuilding',
    'PropertyFireVehicle',
    'PropertyFireBuilding',
    'PropertyExtendedCoverageBuilding',
    'PropertyBusinessInterruption',
    'PropertyBusinessInterruptionSysA',
    'PropertyBusinessInterruptionSysB',
    'PropertyBusinessInterruptionSysC',
    'PropertyBusinessInterruptionSysU',
    'PropertyBusinessInterruptionSysL',
    'PropertyBusinessInterruptionAdditionalCosts',
    'PropertyBusinessInterruptionTradeCommerceSME',
    'PropertyBusinessInterruptionTenantIncome',
    'PropertyTheftValuablesTheftRobbery',
    'PropertyTheftValuablesSimpleTheft',
    'PropertyTheftValuablesValuables',
    'PropertyWaterVehicleBuilding',
    'PropertyWaterVehicle',
    'PropertyWaterBuilding',
    'PropertyBusinessInterruptionWater',
    'PropertyGlass',
    'TechnicalMachineBreak',
    'TechnicalMachineBusinessInterruption',
    'TechnicalMachineCoverage',
    'TechnicalAssembly',
    'TechnicalMachineWarranty',
    'TechnicalConstructionBuilderLiability',
    'TechnicalATA',
    'TechnicalEDVAAdditionalCosts',
    'TechnicalEDVAProperty',
    'TechnicalEDVAMedia',
    'TechnicalEDVASoftware',
    'TechnicalITProducts',
    'TechnicalInstallations',
    'PropertyAssistancePocketKnife',
    'PropertyAssistanceCardProtectionService',
    'PropertyAssistanceLegalAdviceByPhone',
    'PropertyAssistanceVisitor',
    'PropertyLegalProtection',
    'PropertyAssistanceBuilding',
    'TransportCargo',
    'TransportInlandMarine',
    'TransportCargoTransit',
    'TransportFullTransit',
    'TransportVehilce',
  ];

  public static insuranceTypeTranslatedSelections =
    (): TranslatedSelection[] => {
      return Globals.allInsuranceTypes
        .map((insuranceType) => ({
          value: insuranceType,
          translatedValue: t(`insuranceType.${insuranceType}`),
        }))
        .sort((a, b) => a.translatedValue.localeCompare(b.translatedValue));
    };

  public static readonly standardBranches: Branch[] = [
    'GeneralLiability',
    'MotorVehicleLiability',
    'Property',
    'Technical',
  ];

  public static readonly languages: Language[] = ['De', 'Fr', 'It'];

  public static readonly regions: Region[] = ['Local', 'Regional', 'National'];

  public static readonly preferredContactMethod: PreferedContact[] = [
    'Email',
    'Phone',
  ];

  public static readonly genders: Gender[] = [
    'Female',
    'Male',
    'Diverse',
    'Undisclosed',
  ];

  public static readonly compensation: Compensation[] = [
    'FairValue',
    'FairValueSupplement',
    'NewValue',
    'Unknown',
  ];

  public static readonly estimatedSavings: EstimatedSavings[] = [
    'Low',
    'Medium',
    'High',
    'Highest',
  ];

  public static readonly AccidentInspectionTaskCategories: AccidentInspectionTaskCategory[] =
    ['Single', 'Integral'];

  public static readonly CheatingParties: InvestigationCheatingParty[] = [
    'PolicyHolder',
    'DamagedParty',
    'Both',
  ];

  public static readonly CompletionCodes: InvestigationTaskCompletionCode[] = [
    'NotifyOblication',
    'AbusiveReverseInsurance',
    'DeliberateCausing',
    'IncreaseRiskOfPolicyholder',
    'ContradictoryFactsOrNoProof',
    'FraudulentJustificationOfClaim',
    'AbusiveMultipleInsurance',
    'ModifyingDamageWithFraudulentIntent',
    'NoEvidenceOfEventInLaw',
    'DeliberateDeceptionContract',
    'RejectionLackOfCustomerResponse',
    'RejectionClaimCancellationByPolicyholder',
    'RejectionPartial',
    'RejectionExclusionOfCoverage',
    'RejectionExternalInvestigations',
    'RejectionOther',
    'NoRejectionNormalClaim',
    'NoRejectionRiskAssessment',
    'LostSavings',
  ];

  /*public static readonly CompletionMainCodes: InvestigationTaskCompletionMainCode[] =
    [
      'NotifyOblication',
      'AbusiveReverseInsurance',
      'DeliberateCausing',
      'IncreaseRiskOfPolicyholder',
      'ContradictoryFactsOrNoProof',
      'FraudulentJustificationOfClaim',
      'AbusiveMultipleInsurance',
      'ModifyingDamageWithFraudulentIntent',
      'NoEvidenceOfEventInLaw',
      'DeliberateDeceptionContract',
      'Rejection',
      'NoRejection',
    ];*/

  public static readonly CompletionSubCodes: InvestigationTaskCompletionCode[] =
    [
      'FraudulentJustificationOfClaim',
      'RejectionLackOfCustomerResponse',
      'RejectionClaimCancellationByPolicyholder',
      'RejectionPartial',
      'RejectionExclusionOfCoverage',
      'RejectionExternalInvestigations',
      'RejectionOther',
      'NoRejectionNormalClaim',
      'NoRejectionRiskAssessment',
    ];

  public static readonly vehicleRecommendation: Recommendation[] = [
    'Repair',
    'PayOut',
    'TotalLoss',
    'Unknown',
    'Other',
  ];

  public static readonly vehicleSpecialties: VehicleSpecialtyNames[] = [
    'Camper',
    'Tesla',
    'UtilityVehicleLight',
    'UtilityVehicleHeavy',
  ];

  public static readonly tristate: WithNone<boolean>[] = ['None', false, true];

  public static readonly vehicleRegress: VehicleRegress[] = [
    'NoRegress',
    'RegressDomestic',
    'RegressAbroad',
  ];

  public static readonly vehicleTypes: VehicleType[] = [
    'Car',
    'DeliveryVan',
    'Truck',
    'Trailer',
    'Autobus',
    'RV',
    'Motorcycle',
    'Diverse',
  ];

  public static readonly vehicleBrands: VehicleBrandNames[] = [
    'Abarth',
    'AlfaRomeo',
    'Audi',
    'BMW',
    'BMWi',
    'Bucher',
    'Cadillac',
    'Chevrolet',
    'Citroën',
    'Corvette',
    'Cupra',
    'Dacia',
    'Daihatsu',
    'Dangel',
    'DSAutomobiles',
    'Fiat',
    'Ford',
    'Honda',
    'Humbaur',
    'Hyundai',
    'Infiniti',
    'Isuzu',
    'Jaguar',
    'Jeep',
    'LandRover',
    'Lexus',
    'Mazda',
    'MercedesBenz',
    'Mini',
    'Mitsubishi',
    'Mustang',
    'Nissan',
    'Opel',
    'Peugeot',
    'Renault',
    'RenaultPro',
    'Rentier',
    'Seat',
    'Skoda',
    'Smart',
    'Subaru',
    'Suzuki',
    'Toyota',
    'Volvo',
    'VW',
    'VWNF',
    'Zaugg',
  ];

  public static readonly roles: PartnerRole[] = [
    'None',
    'PolicyHolder',
    'PolicyHolderDomicileAddress',
    'AffectedThirdParty',
    'AffectedPolicyHolder',
    'InsuredPerson',
    'OtherInvolved',
    'PaymentRecipient',
  ];

  public static readonly VehicleTaskPartnerRoles: PartnerRole[] = [
    'AffectedThirdParty',
    'OtherInvolved',
    'PaymentRecipient',
  ];

  public static readonly InvestigationTaskPartnerRoles: PartnerRole[] = [
    'AffectedThirdParty',
    'OtherInvolved',
    'PaymentRecipient',
    'InsuredPerson',
  ];

  public static readonly policyHolderContactMethod: PolicyHolderContactMethod[] =
    ['ByPolicyHolder', 'ByVehicleCraftsman'];

  public static readonly VehicleBonusCorrectionReason: VehicleBonusCorrectionReason[] =
    ['UndoStepIncrease', 'StepIncrease'];

  public static readonly InvestigationTaskTypes: InvestigationTaskType[] = [
    'Investigation',
    'Service',
  ];
  public static readonly taskAssessment: TaskAssessment[] = [
    'Easy',
    'Medium',
    'Complex',
  ];
  public static readonly JournalAssessmentTypes: JournalAssessmentType[] = [
    'Accident',
    'ForensicAccountingCheck',
    'Key',
    'Medical',
    'Other',
  ];
  public static readonly JournalInsuranceSurveyTypes: JournalInsuranceSurveyType[] =
    ['Life', 'PropertyAndVehicle'];

  public static readonly JournalResearchTypes: JournalResearchType[] = [
    'ArtLossQuery',
    'Astra',
    'AuthoritiesClarifications',
    'CarClaimInfoQuery',
    'CreditCheck',
    'HisQuery',
    'MetaData',
    'MohawkQuery',
    'Osint',
    'Other',
  ];

  public static readonly InsuranceSurveyTypes: InsuranceSurveyType[] = [
    'Life',
    'PropertyAndVehicle',
  ];

  public static readonly InsuranceSurveyResponses: InsuranceSurveyResponse[] = [
    'Open',
    'Positive',
    'Negative',
  ];

  public static readonly InvestigationTaskStatuses: InvestigationTaskStatus[] =
    [
      'New',
      'InvestigationNew',
      'InProgress',
      'InvestigationCompleted',
      'Rejected',
      'Closed',
    ];

  public static readonly JournalTypes: JournalType[] = [
    'Assessment',
    'Email',
    'InsuranceSurvey',
    'Investigation',
    'Meeting',
    'OutdoorInvestigation',
    'Phone',
    'Research',
  ];

  public static readonly PropertyInspectionTaskJournalTypes: PropertyInspectionTaskJournalType[] =
    [
      'ESINotes',
      'PhoneEmail',
      'InspectionData',
      'Assessment',
      'OfferConfirmation',
      'Rejection',
      'Regress',
      'InvestigationInclusion',
    ];

  public static readonly VehicleTaskKinds: VehicleTaskKind[] = [
    'VehicleInspectionTask',
    'VehicleRiskAssessment',
    'VehicleBonusCorrection',
  ];

  public static readonly UnassignedUserDto: UserDto = {
    id: '00000000-0000-0000-0000-000000000000',
    upn: '',
    firstName: 'None',
    lastName: '',
    phoneNumber: '',
    insuranceCenter: { id: '', name: '' },
    language: 'De',
    personalNumber: '',
    appCapabilities: [],
    lifeCycleStatus: 'Deactivated',
    userMenuItems: [],
    userTableSettings: [],
  };

  public static readonly ExpensesTypes: ExpenseTypeDto[] = [
    'ExternalExperts',
    'Medical',
    'Observations',
    'ExternalInvestigation',
  ];

  public static readonly ExpenseCurrencies: TranslatedSelection[] = [
    { value: 'CHF', translatedValue: 'CHF' },
    { value: 'Euro', translatedValue: '€' },
  ];

  public static readonly propertyInspectionTaskCauses: PropertyInspectionTaskCause[] =
    [
      'Flood',
      'Storm',
      'Rockslide',
      'Landslide',
      'Avalanche',
      'Hail',
      'StoneImpact',
      'SnowPressure',
      'BackWater',
      'GroundWater',
    ];

  public static readonly RecipientTypes: RecipientType[] = [
    'Additional',
    'Attendee',
    'Baloise',
    'Counterpart',
    'Craftsman',
    'Inspector',
    'PolicyHolder',
    'SalesRepresentative',
  ];
}

/* @loetsphi: 08.08.2022: UnionToTuple creates a lot of errors and is not stable. Downside of not using it is to manually check the values each time they are updated.
// https://github.com/microsoft/TypeScript/issues/13298#issuecomment-885980381

type UnionToIntersection<U> = (
  U extends never ? never : (arg: U) => never
) extends (arg: infer I) => void
  ? I
  : never;

type UnionToTuple<T> = UnionToIntersection<
  T extends never ? never : (t: T) => T
> extends (_: never) => infer W
  ? [...UnionToTuple<Exclude<T, W>>, W]
  : [];*/

export { guid };
export const capitalize = (str: string): string =>
  str.length > 0 ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : str;

export const getAffectedPartnerFunctionOrRole = (
  affectedPartner: AffectedPartner,
  translatedRole: string,
): string | undefined => {
  if (affectedPartner.partner.type === 'person') {
    if (
      affectedPartner.role !== Globals.noneValue ||
      affectedPartner.partner.person.function
    ) {
      if (affectedPartner.role === Globals.noneValue) {
        return affectedPartner.partner.person.function;
      }

      return concat(
        [translatedRole, affectedPartner.partner.person.function],
        'space-slash-space',
      );
    }
  } else {
    if (
      affectedPartner.role !== Globals.noneValue ||
      affectedPartner.partner.company.function
    ) {
      return concat(
        [
          affectedPartner.role !== Globals.noneValue
            ? translatedRole
            : t('partnerRole.None'),
          affectedPartner.partner.company.function,
        ],
        affectedPartner.role !== Globals.noneValue
          ? 'space-slash-space'
          : 'space',
      );
    }
  }
};

export function StringCap(string: string, size: number): string {
  if (string.length > size)
    return concat([string.substring(0, size).trim(), ' ...']);
  return string;
}

export const convertBytesToMB = (value: number): number => {
  return Math.round((value / Math.pow(1024, 2)) * 100) / 100;
};

export const stringAsBooleanUndefined = (
  value: string,
): boolean | undefined => {
  if (value.toLowerCase() === 'true') return true;
  if (value.toLowerCase() === 'false') return false;
  return undefined;
};

export const booleanUndefinedToStringTranslated = (
  value: boolean | undefined | null,
): string => {
  if (value === undefined || value === null) return '';
  return value ? t('tristate.true') : t('tristate.false');
};

export const booleanUndefinedToString = (
  value: boolean | undefined | null,
): string => {
  if (value === undefined || value === null) return '';
  return value ? 'true' : 'false';
};

export const checkInvestigationJournalType = (
  journal: InvestigationTaskJournalDto,
): boolean => {
  switch (journal.journalType) {
    case 'Assessment':
      return journal.journalAssessmentType !== undefined;
    case 'InsuranceSurvey':
      return journal.journalInsuranceSurveyType !== undefined;
    case 'Research':
      return journal.journalResearchType !== undefined;
    default:
      return true;
  }
};

export const getTaskTypeFromEntityName = (entityName?: string): TaskTypes =>
  entityName === 'PropertyInspectionTask'
    ? TaskTypes.PropertyInspectionTask
    : entityName === 'AccidentInspectionTask'
    ? TaskTypes.AccidentInspectionTask
    : entityName === 'InvestigationTask'
    ? TaskTypes.InvestigationTask
    : entityName === 'VehicleTask'
    ? TaskTypes.VehicleTask
    : TaskTypes.PropertyInspectionTask;

export const getAssigneePerTypeTranslated = (taskType: TaskTypes): string => {
  switch (taskType) {
    case TaskTypes.PropertyInspectionTask:
      return t('assignee.assigneeStandard');
    case TaskTypes.AccidentInspectionTask:
      return t('assignee.assigneeAccident');
    case TaskTypes.InvestigationTask:
      return t('assignee.assigneeInvestigation');
    case TaskTypes.VehicleTask:
      return t('assignee.assigneeVehicle');
    default:
      return t('assignee.assigneeStandard');
  }
};

export const completionCodesWithoutSavingsRequired: InvestigationTaskCompletionCode[] =
  ['NoRejectionNormalClaim', 'NoRejectionRiskAssessment'];

export const checkInvestigationCompletionDisabled = (
  investigationTask: InvestigationTask,
): boolean => {
  return (
    (investigationTask.investigationTaskType === 'Investigation' &&
      (investigationTask.taskAssessment === null ||
        investigationTask.investigationTaskCompletionCode === null)) ||
    (investigationTask.investigationTaskType === 'Investigation' &&
      !investigationTask.investigationTaskCompletionCode) ||
    (investigationTask.investigationTaskType === 'Investigation' &&
      completionCodeRequiresSavings(investigationTask) &&
      !(
        typeof investigationTask.investigationSavings === 'number' &&
        investigationTask.investigationSavings >= 0
      )) ||
    (investigationTask.investigationTaskType === 'Service' &&
      !(
        investigationTask.artLossServiceCompletionCode ||
        investigationTask.carClaimInfoQueryServiceCompletionCode ||
        investigationTask.creditCheckServiceCompletionCode ||
        investigationTask.hisQueryServiceCompletionCode ||
        investigationTask.insuranceSurveyServiceCompletionCode ||
        investigationTask.consultingServiceCompletionCode ||
        investigationTask.contactWithAuthoritiesServiceCompletionCode ||
        investigationTask.hisRegistrationServiceCompletionCode ||
        investigationTask.mohawkQueryServiceCompletionCode ||
        investigationTask.osintServiceCompletionCode
      ))
  );
};

export const isInvestigationCompletionAllowed = (
  investigationTask: InvestigationTask,
): boolean => {
  return (
    (investigationTask.investigationTaskType === 'Investigation' &&
      investigationTask.taskAssessment &&
      investigationTask.investigationTaskCompletionCode &&
      completionCodeRequiresSavings(investigationTask) &&
      !(
        typeof investigationTask.investigationSavings === 'number' &&
        investigationTask.investigationSavings >= 0
      )) ??
    false
  );
};

export const completionCodeRequiresSavings = (
  investigationTask: InvestigationTask,
): boolean => {
  return (
    ((investigationTask.investigationTaskType === 'Investigation' &&
      investigationTask.investigationTaskCompletionCode &&
      !completionCodesWithoutSavingsRequired.includes(
        investigationTask.investigationTaskCompletionCode,
      )) ||
      investigationTask.investigationTaskType === 'Service') ??
    false
  );
};

export const isJournalAndExpansesDisabled = (
  investigationTaskStatus: InvestigationTaskStatus,
): boolean => {
  switch (investigationTaskStatus) {
    case 'New':
    case 'InvestigationNew':
      return true;
    default:
      return false;
  }
};

export const getEditCapabilityFromType = (
  taskType: TaskTypes,
): AppCapabilityNames => {
  let result: AppCapabilityNames = 'EditPropertyInspectionTask';
  switch (taskType) {
    case TaskTypes.AccidentInspectionTask:
      result = 'EditAccidentInspectionTask';
      break;
    case TaskTypes.InvestigationTask:
      result = 'EditFraudInspectionTask';
      break;
    case TaskTypes.VehicleTask:
      result = 'EditVehicleTask';
      break;

    default:
      break;
  }
  return result;
};

export const getBaseTaskStatusColor = (
  status?: BaseTaskStatus,
): Props.BalTagColor | undefined => {
  switch (status) {
    case 'Assigned':
    case 'InProgressWithIndependent':
      return 'info';
    case 'InProgress':
    case 'InProgressVideo':
    case 'InProgressLocal':
    case 'InProgressOffice':
    case 'InProgressWithPartner':
    case 'SentToArchive':
      return 'primary';
    case 'Closed':
      return 'success';
    case 'Rejected':
      return 'danger';
    case 'InvestigationCompleted':
      return 'warning';
    case 'InvestigationNew':
      return 'grey';
    case 'New':
    default:
      return undefined;
  }
};

export const getInsuranceSurveyResponseColor = (
  status?: InsuranceSurveyResponse,
): Props.BalTagColor | undefined => {
  switch (status) {
    case 'Open':
      return 'info';
    case 'Negative':
      return 'success';
    case 'Positive':
      return 'danger';
    default:
      return undefined;
  }
};

export const getJournalType = (
  journal: InvestigationTaskJournalListDto,
): string => {
  const translatedJournalType = t(
    `journal.journalTypes.${journal.journalType}`,
  );

  if (journal.journalType === 'Assessment') {
    return `${translatedJournalType} -> ${t(
      `journal.journalAssessmentTypes.${journal.journalSubtype}`,
    )}`;
  }

  if (journal.journalType === 'InsuranceSurvey') {
    return `${translatedJournalType} -> ${t(
      `journal.journalInsuranceSurveyTypes.${journal.journalSubtype}`,
    )}`;
  }

  if (journal.journalType === 'Research') {
    return `${translatedJournalType} -> ${t(
      `journal.journalResearchTypes.${journal.journalSubtype}`,
    )}`;
  }

  return translatedJournalType;
};

export const cachedKey = (object: object): string => {
  return JSON.stringify({ ...object, isCached: true });
};

const isCachedKey = (key: string) => {
  try {
    const cachedKey = JSON.parse(key);
    return cachedKey['isCached'] !== null;
  } catch (error) {
    return false;
  }
};

export const removeCachedDataFromLocalStorage = () => {
  const itemsToRemove: string[] = [];

  // Detect cached data.
  for (let i = 0; i < localStorage.length; i++) {
    const itemKey: string = localStorage.key(i) ?? '';
    if (isCachedKey(itemKey)) {
      itemsToRemove.push(itemKey);
    }
  }

  // Remove cached data.
  itemsToRemove.forEach((element: string) => {
    localStorage.removeItem(element);
  });
};

export const toFormattedClaimNumber = (input: string) => {
  if (input.length == 11) {
    // e.g. 44127733110 to 44/127733/11.0
    const inputPadded = input.padEnd(11, ' ');
    const firstSegment = inputPadded.substring(0, 2);
    const secondSegment = inputPadded.substring(2, 8);
    const thirdSegment = inputPadded.substring(8, 10);
    const fourthSegment = inputPadded.substring(10, 11);
    return `${firstSegment}/${secondSegment}/${thirdSegment}.${fourthSegment}`;
  } else {
    return input;
  }
};

export const getLabelValueFromBkpNumber = (bkpNumber: string): string => {
  switch (bkpNumber) {
    case '101-199':
      return t('workCategory.bkp101_199Label');
    case '201-211':
      return t('workCategory.bkp201_211Label');
    case '212-219':
      return t('workCategory.bkp212_219Label');
    case '221':
      return t('workCategory.bkp221Label');
    case '222-223':
      return t('workCategory.bkp222_223Label');
    case '224':
      return t('workCategory.bkp224Label');
    case '225-226':
      return t('workCategory.bkp225_226Label');
    case '227-228':
      return t('workCategory.bkp227_228Label');
    case '231-239':
      return t('workCategory.bkp231_239Label');
    case '241-249':
      return t('workCategory.bkp241_249Label');
    case '251-259':
      return t('workCategory.bkp251_259Label');
    case '261-269':
      return t('workCategory.bkp261_269Label');
    case '271':
      return t('workCategory.bkp271Label');
    case '272':
      return t('workCategory.bkp272Label');
    case '273-275':
      return t('workCategory.bkp273_275Label');
    case '276-279':
      return t('workCategory.bkp276_279Label');
    case '281':
      return t('workCategory.bkp281Label');
    case '282-283':
      return t('workCategory.bkp282_283Label');
    case '284':
      return t('workCategory.bkp284Label');
    case '285':
      return t('workCategory.bkp285Label');
    case '286':
      return t('workCategory.bkp286Label');
    case '287':
      return t('workCategory.bkp287Label');
    case '291-299':
      return t('workCategory.bkp291_299Label');
    case '301-399':
      return t('workCategory.bkp301_399Label');
    case '401-499':
      return t('workCategory.bkp401_499Label');
    default:
      return `Please add translation for bkp number: ${bkpNumber}`;
  }
};

const warningsShown = (task: RequestResult<BaseTask>): number => {
  let warnings = 0;
  if (task.status === 'success' && task.value) {
    if (task.value.deletedAt) {
      warnings++;
    }
    if (task.value.status === 'New') {
      warnings++;
    }
    if (
      task.value.claim?.createdManually &&
      task.value.type !== TaskTypes.InvestigationTask
    ) {
      warnings++;
    }
  }

  return warnings;
};

export const getFullHeightWarningCssClass = (task: RequestResult<BaseTask>) => {
  const warningsNumber = warningsShown(task);
  switch (warningsNumber) {
    case 1:
      return 'is-full-height-with-one-warning';
    case 2:
      return 'is-full-height-with-two-warning';
    case 3:
      return 'is-full-height-with-three-warning';
    default:
      return 'is-full-height';
  }
};

export const getPolicyHolder = (claim: Claim): AffectedPartner | undefined => {
  const policyHolderId =
    claim.policyHolder.type === 'person'
      ? claim.policyHolder.person.id
      : claim.policyHolder.company.id;

  return claim.affectedPartners.find((p) => {
    return p.partner.type === 'person'
      ? p.partner.person.id === policyHolderId
      : p.partner.company.id === policyHolderId;
  });
};

export const getPartnerName = (partner: Partner): string => {
  return (
    (partner.type === 'person'
      ? `${partner.person?.displayName}`
      : partner.company?.displayName) ?? ''
  );
};
/*export const completionCodeHasSubCode = (
  investigationTaskCompletionMainCode: InvestigationTaskCompletionMainCode,
): boolean => {
  return (
    ((investigationTaskCompletionMainCode &&
      Globals.CompletionSubCodes.find(
        (subCode) =>
          subCode.includes(
            investigationTaskCompletionMainCode.toString() ?? '',
          ) && subCode !== investigationTaskCompletionMainCode.toString(),
      )?.length) ??
      0) > 0 ?? false
  );
};*/
