import { BalIcon } from '@baloise/design-system-components-react';
import { Craftsman } from '../../types/types';
import { RequestResult } from '../../data';
import { concat } from '../../utils';
import { balIconLocate } from '@baloise/design-system-icons';
type PartnerProps = {
  partner: RequestResult<Craftsman>;
};

const LocateButton = (
  { partner }: PartnerProps,
  className?: string,
): JSX.Element => {
  if (partner.status === 'success') {
    const position: L.LatLngExpression =
      partner.localValue.type === 'propertyCraftsman'
        ? [
            partner.localValue.propertyCraftsman.latitude,
            partner.localValue.propertyCraftsman.longitude,
          ]
        : [
            partner.localValue.vehicleCraftsman.latitude,
            partner.localValue.vehicleCraftsman.longitude,
          ];
    if (position[0] != null && position[1] != null) {
      return (
        <a
          className={concat([' mb-3', className])}
          href={`https://www.google.com/maps/dir/?api=1&destination=${position[0]}%2C${position[1]}`}
          target="_blank"
          rel="noreferrer"
        >
          <BalIcon size="medium" svg={balIconLocate}></BalIcon>
        </a>
      );
    } else return <></>;
  } else return <></>;
};
export default LocateButton;
