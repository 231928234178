import { RequestResult } from '../../../../data';
import { InvestigationTaskStatus } from '../../../../types/resource-models';
import {
  BaseTask,
  InvestigationTask,
  TaskTypes,
} from '../../../../types/types';
import { WithCapabilities } from '../../../../components/authorization';
import { useContext } from 'react';
import { UserContext } from '../../../../context';
import { concat } from '../../../../utils';
import InvestigationTaskReject from './investigation-task-reject';
import InvestigationTaskReassign from './investigation-task-reassign';
import InvestigationTaskCompletion from './investigation-task-completion';
import InvestigationTaskInProgressButton from './investigation-task-in-progress-button';
import { getInvestigationReport } from '../../../files/data/requests';
import { BaseTaskMenu } from '../../../base-task';
import { BaseTaskDeleteButton } from '../../../base-task/components';
import { BalButton } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EBRoutes } from '../../../../router/router';
import InvestigationTaskOpenButton from './investigation-task-open-button';

type InvestigationTaskWorkflowButtonsProps = {
  task: InvestigationTask;
  className?: string;
  onChangeSuccess?: (task: RequestResult<InvestigationTask>) => void;
};

const showCloseButtonStates: InvestigationTaskStatus[] = [
  'InProgress',
  'InvestigationCompleted',
];
const showAcceptButtonStates: InvestigationTaskStatus[] = ['InvestigationNew'];
const showRejectButtonStates: InvestigationTaskStatus[] = ['InvestigationNew'];
const showDraftButtonStates: InvestigationTaskStatus[] = ['New'];
const showReassignButtonStates: InvestigationTaskStatus[] = [
  'InvestigationNew',
  'InProgress',
  'InvestigationCompleted',
];
const showMoreButtonStates: InvestigationTaskStatus[] = [
  'InvestigationNew',
  'InProgress',
  'Closed',
  'InvestigationCompleted',
  'Rejected',
];
const showNewButtonStates: InvestigationTaskStatus[] = [
  'InvestigationCompleted',
  'Closed',
  'Rejected',
];

export const InvestigationTaskWorkflowButtons = ({
  task,
  className,
  onChangeSuccess,
}: InvestigationTaskWorkflowButtonsProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const isAssignee =
    (user.status === 'success' || user.status === 'loading-with-value') &&
    user.value.id === task.assignee?.id;
  const reportName = `${task.claim.claimNumber}_report.pdf`;
  const fullReportName = `${task.claim.claimNumber}_full_report.pdf`;

  return (
    <div className={concat(['is-justify-content-space-between', className])}>
      <div className="is-flex is-align-items-center">
        {isAssignee && showAcceptButtonStates.includes(task.status) && (
          <WithCapabilities
            requiredCapabilities={['EditFraudInspectionTask']}
            passWithCapabilitiesPropsToChildren
          >
            <InvestigationTaskInProgressButton
              task={task}
              onChangeSuccess={onChangeSuccess}
            />
          </WithCapabilities>
        )}
        {isAssignee && showRejectButtonStates.includes(task.status) && (
          <InvestigationTaskReject
            task={task}
            onRejectSuccess={onChangeSuccess}
          />
        )}
        {showReassignButtonStates.includes(task.status) && (
          <InvestigationTaskReassign
            task={task}
            onReassignSuccess={onChangeSuccess}
          />
        )}
        {showCloseButtonStates.includes(task.status) && onChangeSuccess && (
          <InvestigationTaskCompletion
            investigationTask={task}
            onChangeSuccess={onChangeSuccess}
          />
        )}
        {showDraftButtonStates.includes(task.status) &&
          onChangeSuccess &&
          task.deletedAt === null && (
            <>
              <BaseTaskDeleteButton
                className="ml-3"
                task={task}
                taskType={TaskTypes.InvestigationTask}
                onChangeSuccess={onChangeSuccess}
                requiredCapabilities={[
                  'CreateFraudInspectionTask',
                  'EditFraudInspectionTask',
                ]}
              />
              <BalButton
                color="info"
                className="ml-3"
                size="small"
                onClick={(event) => {
                  if (event.detail == 1) {
                    navigate(
                      EBRoutes.INVESTIGATION_TASK_WIZARD.create({
                        query: {
                          taskId: task.id,
                        },
                      }),
                    );
                    navigate(0);
                  }
                }}
              >
                {t('inspectionTask.inspectionTaskWorkflow.toWizard')}
              </BalButton>
            </>
          )}
        {showNewButtonStates.includes(task.status) &&
          onChangeSuccess &&
          task.deletedAt === null && (
            <>
              <InvestigationTaskOpenButton
                task={task}
                requiredCapabilities={['EditFraudInspectionTask']}
                onChangeSuccess={onChangeSuccess}
              />
            </>
          )}
      </div>
      {showMoreButtonStates.includes(task.status) && (
        <BaseTaskMenu
          task={task}
          reportName={reportName}
          fullReportName={fullReportName}
          getReport={getInvestigationReport}
          taskType={TaskTypes.InvestigationTask}
          onChangeSuccess={(taskResult: RequestResult<BaseTask>) =>
            onChangeSuccess &&
            onChangeSuccess(taskResult as RequestResult<InvestigationTask>)
          }
        />
      )}
    </div>
  );
};
