import { useContext, useRef, useState } from 'react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import NetworkHeader from './network-header';
import NetworkTable from './network-table';
import {
  WithCapabilities,
  WithCapabilitiesErrorProps,
} from '../../components/authorization';
import { RequestResult } from '../../data';
import { Routes, Route } from 'react-router';
import NetworkPartnerDetail from '../network-partner-detail/network-partner-detail';
import { Craftsman } from '../../types/types';
import {
  NetworkPartnerOverlay,
  NetworkOverviewMenu,
  useCraftsmansFromApi,
} from '../../features/network-partners';
import {
  CombinedStateProps,
  replaceCraftsmanInStateList,
} from '../../features/network-partners/data/hooks';
import {
  CraftsmanFilterCriteria,
  SortOrder,
} from '../../types/resource-models';
import { Filter } from '../../features/view-menu/view-menu';
import { getDefaultMenuItem } from '../../features/network-partners/components/network-partner-view-menu-items';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { Globals } from '../../utils';
import { UserContext } from '../../context';

const NetworkPartners = (): JSX.Element => {
  return (
    <WithCapabilities
      requiredCapabilities={['ReadNetworkPartner']}
      passWithCapabilitiesPropsToChildren
      showErrorToast
    >
      <NetworkPartnersContent />
    </WithCapabilities>
  );
};

export default NetworkPartners;

const NetworkPartnersContent = ({
  withCapabilitiesError,
}: WithCapabilitiesErrorProps): JSX.Element => {
  const [combinedState, setCombinedState] = useCraftsmansFromApi();

  const { user } = useContext(UserContext);
  const setLabelAndFilter = (label: string, filter: Filter) => {
    // get users profile sorting configuration to apply it to current filter.
    let customOrderField: string | undefined = undefined;
    let customOrderBy: SortOrder | undefined = undefined;
    if (user.status === 'success') {
      const userTableSetting = user.localValue.userTableSettings.find(
        (x) => x.type === 'Craftsman',
      );
      if (userTableSetting?.orderField) {
        customOrderField = userTableSetting?.orderField;
        customOrderBy = userTableSetting?.orderBy;
      }
    }

    setCombinedState(
      produce(combinedState, (draftState) => {
        if (
          combinedState.craftsmans.status === 'success' ||
          combinedState.craftsmans.status === 'error'
        ) {
          draftState.craftsmans.status = 'loading-with-value';
        }
        draftState.defaultFilter = {
          ...Globals.craftsmanFilterDefault,
          ...(filter as Partial<CraftsmanFilterCriteria>),
        };
        draftState.filter = customOrderField
          ? {
              ...draftState.defaultFilter,
              orderField: customOrderField,
              orderBy: customOrderBy ?? 'asc',
            }
          : draftState.defaultFilter;
        draftState.label = label;
      }),
    );
  };
  const [editFilter, setEditFilter] = useState<string | undefined>();

  return (
    <>
      <NetworkOverviewMenu
        setLabelAndFilter={setLabelAndFilter}
        editFilter={editFilter}
        setEditFilter={setEditFilter}
        combinedState={combinedState}
      />
      {!withCapabilitiesError && (
        <Routes>
          <Route
            index
            element={
              <NetworkPartnersTable
                setLabelAndFilter={setLabelAndFilter}
                combinedState={combinedState}
                setCombinedState={setCombinedState}
                editFilter={editFilter}
              />
            }
          />
          <Route
            path={':networkPartnerId'}
            element={
              <NetworkPartnerDetail
                combinedState={combinedState}
                setCombinedState={setCombinedState}
              />
            }
          />
        </Routes>
      )}
    </>
  );
};

type NetworkPartnerTableProps = {
  setLabelAndFilter: (label: string, filter: Filter) => void;
  editFilter: string | undefined;
} & WithCapabilitiesErrorProps &
  CombinedStateProps;

const NetworkPartnersTable = ({
  setLabelAndFilter,
  withCapabilitiesError,
  combinedState,
  setCombinedState,
  editFilter,
}: NetworkPartnerTableProps): JSX.Element => {
  const [showMap, setShowMap] = useState<boolean>(false);
  const [partner, setPartner] = useState<RequestResult<Craftsman>>({
    status: 'initial',
  });
  const [, setGridApi] = useState<GridApi>();
  const refNetworkOverlay = useRef<HTMLBalCardElement>(null);
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };
  const { t } = useTranslation();

  const setInitialMenuItem = () => {
    if (setLabelAndFilter) {
      const menuItem = getDefaultMenuItem(t);
      setLabelAndFilter(menuItem.label, menuItem.filter);
    }
  };

  return (
    <>
      {partner.status === 'success' && (
        <NetworkPartnerOverlay
          refNetworkOverlay={refNetworkOverlay}
          slideIn
          partner={partner}
          setPartner={setPartner}
          replaceCraftsmanInList={replaceCraftsmanInStateList}
        />
      )}
      {!withCapabilitiesError && (
        <div className="is-full-height has-background-grey-1 is-flex is-flex-direction-column pl-5 pr-7 pt-5">
          <NetworkHeader
            showMap={showMap}
            setShowMap={setShowMap}
            combinedState={combinedState}
            setCombinedState={setCombinedState}
            setInitialMenuItem={setInitialMenuItem}
            editFilter={!!editFilter}
          />
          <div className="is-flex-grow-1 mt-5 mb-5">
            <NetworkTable
              combinedState={combinedState}
              setCombinedState={setCombinedState}
              onGridReady={onGridReady}
              setPartner={setPartner}
              refNetworkOverlay={refNetworkOverlay}
              showMap={showMap}
            />
          </div>
        </div>
      )}
    </>
  );
};
