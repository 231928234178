import { BalButton } from '@baloise/design-system-components-react';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { WithCapabilities } from '../../../components/authorization';
import {
  EditTextModalContent,
  EditTextModalProps,
} from '../../../components/input/edit-text-modal';
import { ErrorToast } from '../../../components/toast-notification';
import { balModalController } from '../../../controller/controllers';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import { BaseTaskListDto } from '../../../types/resource-models';
import { TaskTypes, VehicleTask } from '../../../types/types';
import { updateBaseTaskField } from '../../base-task/data/requests';

const EditQuickNotesModalContent = ({
  label,
  placeholder,
  value,
  requiredCapabilities,
  isRequired,
  vehicleTask,
  setTask,
}: EditQuickNotesModalProps): JSX.Element => {
  const token = useToken();
  const onSave = async (value: string | undefined) => {
    const newTask = vehicleTask;
    const result = await updateBaseTaskField<VehicleTask>(
      token,
      newTask.id,
      TaskTypes.VehicleTask,
      'quickNotes',
      value,
    );
    if (result.status === 'success') {
      if (setTask) {
        setTask(result as unknown as RequestResult<BaseTaskListDto>);
      }
      balModalController.dismiss();
    } else if (result.status === 'error') {
      toast(ErrorToast(result.errorValue));
    }
  };

  return (
    <EditTextModalContent
      label={label}
      placeholder={placeholder}
      value={value}
      isRequired={isRequired}
      requiredCapabilities={requiredCapabilities}
      onSave={onSave}
    />
  );
};

type EditQuickNotesModalProps = Omit<EditTextModalProps, 'onSave'> & {
  vehicleTask: VehicleTask;
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>;
};
export const EditQuickNotesModal = (
  props: EditQuickNotesModalProps,
): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditQuickNotesModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      modalWidth: 750,
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <>
      {props.requiredCapabilities ? (
        <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
          <BalButton
            className={props.buttonClassName}
            square
            outlined
            size="small"
            color="primary-light"
            icon="edit"
            onClick={(event) => event.detail == 1 && openModal()}
          />
        </WithCapabilities>
      ) : (
        <BalButton
          className={props.className}
          square
          outlined
          size="small"
          color="primary-light"
          icon="edit"
          onClick={(event) => event.detail == 1 && openModal()}
        />
      )}
    </>
  );
};
