import {
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalButtonGroup,
  BalText,
  BalPagination,
  BalSpinner,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../data/hooks';
import { AppCapabilityNames, UserDto } from '../../../types/resource-models';
import { concat, Globals } from '../../../utils';
import { balModalController } from '../../../controller/controllers';
import { BalTextField } from '../../../components/input';
import searchIcon from '../../../assets/icons/Iconframe.svg';
import produce from 'immer';
import { Icon, Avatar } from '../../../components/ui';
import { getDisplayNameFromUser } from '../../../utils/utilities';
import { t } from 'i18next';

const UserInfo = ({
  inspector,
  assignee,
  setAssignee,
  highlightExternals,
}: {
  inspector: UserDto;
  assignee?: UserDto;
  setAssignee: React.Dispatch<React.SetStateAction<UserDto | undefined>>;
  highlightExternals?: boolean;
}): JSX.Element => {
  const userIsExternalPartner = (user: UserDto): boolean => {
    if (
      !highlightExternals ||
      !user.appCapabilities ||
      user.appCapabilities.length === 0
    ) {
      return false;
    }
    return (
      user.appCapabilities.find((x) => x === 'ReadOwnedVehicleTask') !==
      undefined
    );
  };

  return (
    <div
      className={concat([
        'column is-4 p-1 assignee-button',
        assignee?.id === inspector.id ? 'assignee-selected' : '',
      ])}
      onClick={(event) => event.detail == 1 && setAssignee(inspector)}
    >
      <div
        className={`is-flex is-align-items-center ${
          userIsExternalPartner(inspector)
            ? 'primary-line-rounded-border'
            : 'blue-line-rounded-border'
        } is-full-height p-2`}
      >
        <div>
          <Avatar user={inspector} size={'40'} className="" />
        </div>
        <div className="is-flex is-flex-direction-column ml-2">
          <BalText bold size="small" color="primary-light">
            {getDisplayNameFromUser(inspector)}
          </BalText>
          {userIsExternalPartner(inspector) && (
            <BalText bold size="small" color="primary">
              {`${t('vehicleTask.external')}`}
            </BalText>
          )}
        </div>
      </div>
    </div>
  );
};
export type SelectUserModalProps = {
  initialSelected: UserDto | undefined;
  appCapabilityNames: AppCapabilityNames[] | undefined;
  onSelectedSuccess: (user: UserDto) => void;
  title?: string;
  highlightExternals?: boolean;
};

export const SelectUserModal = (props: SelectUserModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [userSearchName, setUserSearchName] = useState<string | undefined>(
    undefined,
  );
  const [combinedState, setCombinedState] = useUsers({
    ...Globals.filterDefault,
    maxItemCount: 12,
    orderField: 'lastName',
    appCapabilities: props.appCapabilityNames,
  });
  const [selectedUser, setSelectedUser] = useState<UserDto | undefined>(
    props.initialSelected,
  );

  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const onUserSearchNameChanged = (event: CustomEvent) => {
    setUserSearchName(event.detail);
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      onSearchDelay(event.detail);
    }, 500);

    setTimer(newTimer);
  };

  const onSearchDelay = (eventDetail: string) => {
    if (combinedState.filter.name != eventDetail) {
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.users.status = 'loading-with-value';
          draftState.filter.name = eventDetail;
          setSelectedUser(undefined);
        }),
      );
    }
  };

  return (
    <>
      <BalModalHeader>
        <BalHeading className="pt-0 mt-0" space="none" level="h3">
          {props.title ? props.title : t('general.pleaseChoose')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div className="is-flex is-full-width pr-1 mb-3 mt-5">
          <BalTextField
            className="is-full-width pr-2"
            placeholder={t('reassign.searchPlaceholder')}
            value={userSearchName}
            onInput={(event) => onUserSearchNameChanged(event)}
          />
          <BalButton color="primary-light" outlined square>
            <Icon iconPath={searchIcon} />
          </BalButton>
        </div>
        {combinedState.users.status === 'success' ? (
          <>
            <div className="columns is-3 is-multiline px-4">
              {combinedState.users.value.entities.map((inspector: UserDto) => {
                return (
                  <UserInfo
                    key={inspector.id}
                    inspector={inspector}
                    assignee={selectedUser}
                    setAssignee={setSelectedUser}
                    highlightExternals={props.highlightExternals}
                  />
                );
              })}
            </div>
            <BalPagination
              className="mt-3"
              value={combinedState.filter.page + 1}
              onBalChange={(e) =>
                setCombinedState(
                  produce(combinedState, (draftState) => {
                    if (combinedState.users.status === 'success') {
                      draftState.users.status = 'loading-with-value';
                    }
                    draftState.filter.page = e.detail - 1;
                  }),
                )
              }
              totalPages={combinedState.users.value.totalPages}
            />
          </>
        ) : (
          <BalSpinner></BalSpinner>
        )}

        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            color="primary"
            disabled={selectedUser == undefined}
            onClick={(event) => {
              if (selectedUser && event.detail == 1) {
                props.onSelectedSuccess(selectedUser);
                balModalController.dismiss();
              }
            }}
          >
            {t('general.buttons.accept')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};
