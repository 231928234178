import {
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import produce from 'immer';

import { InvestigationTask, ISortingState } from '../../../../../types/types';

import '../../../../../styles/general.scss';

import { ResultRenderer } from '../../../../../components/ui';
import { InsuranceSurveyListDto } from '../../../../../types/resource-models';
import { useToken } from '../../../../../hooks';
import InsuranceSurveyAddButton from './toolbar/insurance-survey-add-button';
import {
  getInitialInsuranceSurveyCombinedState,
  InsuranceSurveyCombinedState,
} from '../data/hooks';
import { deleteInsuranceSurvey, getInsuranceSurveys } from '../data/requests';
import { getAllGridOptions } from './insurance-survey-search-form-table-grid-options';
import { isJournalAndExpansesDisabled } from '../../../../../utils';
import { InsuranceSurveyAddAllButton } from './toolbar/insurance-survey-add-all-button';

export const InsuranceSurveySearchFormTable = ({
  task,
  onInsuranceSurveySelected,
}: {
  task: InvestigationTask;
  onInsuranceSurveySelected?: (insuranceSurveyId?: string) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();

  const [insuranceSurveyCombinedState, setInsuranceSurveyCombinedState] =
    useState<InsuranceSurveyCombinedState>(
      getInitialInsuranceSurveyCombinedState(task.id ?? ''),
    );

  useEffect(() => {
    if (
      task.id &&
      token !== '' &&
      insuranceSurveyCombinedState.items.status === 'loading'
    ) {
      getInsuranceSurveys(token, insuranceSurveyCombinedState.filter).then(
        (result) => {
          setInsuranceSurveyCombinedState(
            produce(insuranceSurveyCombinedState, (draftState) => {
              draftState.items = result;
            }),
          );
        },
      );
    }
  }, [task, token, insuranceSurveyCombinedState]);

  const [selectedInsuranceSurveyId, setSelectedInsuranceSurveyId] = useState<
    string | undefined
  >(undefined);

  const [sorting, setSorting] = useState<ISortingState | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const onGridReadyLocal = (params: GridReadyEvent) => {
    setGridApi(params.api);
    if (sorting) {
      const gridApi = params.api;
      const columnsApi = params.columnApi;
      if (insuranceSurveyCombinedState.filter.orderField) {
        const colDef = gridApi
          .getColumnDefs()
          ?.find(
            (x) =>
              (x as ColDef).field ==
              insuranceSurveyCombinedState.filter.orderField,
          );
        if (colDef) {
          columnsApi.applyColumnState({
            state: [
              {
                colId: (colDef as ColDef).colId ?? colDef.headerName ?? '',
                sort: insuranceSurveyCombinedState.filter.orderBy,
              },
            ],
          });
        }
      }
    }
    // Select row.
    if (selectedInsuranceSurveyId) {
      const selectedNode = params.api
        .getRenderedNodes()
        .find(
          (n) =>
            (n.data as InsuranceSurveyListDto).id === selectedInsuranceSurveyId,
        );
      selectedNode?.setSelected(true);
    }
  };

  const getSorting = (columnApi: ColumnApi): ISortingState | undefined => {
    const columns = columnApi.getAllDisplayedColumns();
    const sortingColumn = columns.find((c) => c.getSort());
    if (sortingColumn) {
      const colDef = sortingColumn.getColDef();
      return {
        field: colDef.field ?? '',
        direction: sortingColumn.getSort() ?? 'asc',
      };
    } else {
      return undefined;
    }
  };

  const [selectedInsuranceSurvey, setSelectedInsuranceSurvey] = useState<
    InsuranceSurveyListDto | undefined
  >(undefined);
  const [rowChanged, setRowChanged] = useState<boolean>(false);

  const refreshInsuranceSurvey = (insuranceSurveyId?: string) => {
    onInsuranceSurveySelected && onInsuranceSurveySelected(insuranceSurveyId);
    setSelectedInsuranceSurveyId(insuranceSurveyId);
    setRowChanged(true);
  };

  useEffect(() => {
    if (rowChanged) {
      setRowChanged(false);
      setInsuranceSurveyCombinedState(
        produce(insuranceSurveyCombinedState, (draft) => {
          if (insuranceSurveyCombinedState.items.status === 'success') {
            draft.items.status = 'loading';
          }
        }),
      );
    }
  }, [
    rowChanged,
    insuranceSurveyCombinedState,
    setInsuranceSurveyCombinedState,
    setRowChanged,
  ]);

  const onDeleteInsuranceSurvey = async (
    insuranceSurvey: InsuranceSurveyListDto,
  ) => {
    await deleteInsuranceSurvey(insuranceSurvey.id, token);
    refreshInsuranceSurvey();
  };

  const [gridOptions, setGridOptions] = useState(
    getAllGridOptions(
      t,
      {
        currentInsuranceSurvey: selectedInsuranceSurvey,
        onEditInsuranceSurvey: refreshInsuranceSurvey,
        readonly: isJournalAndExpansesDisabled(task.status),
        onDeleteInsuranceSurvey: onDeleteInsuranceSurvey,
      },
      {
        currentInsuranceSurvey: selectedInsuranceSurvey,
        onDeleteInsuranceSurvey: onDeleteInsuranceSurvey,
        disabled: isJournalAndExpansesDisabled(task.status),
      },
    ),
  );

  useEffect(() => {
    if (gridOptions) {
      gridOptions.onRowClicked = (params) => {
        setSelectedInsuranceSurvey(params.data);
      };
    }
  }, [gridOptions]);

  useEffect(() => {
    if (task) {
      const gridOptionsNew = getAllGridOptions(
        t,
        {
          currentInsuranceSurvey: selectedInsuranceSurvey,
          onEditInsuranceSurvey: refreshInsuranceSurvey,
          readonly: isJournalAndExpansesDisabled(task.status),
          onDeleteInsuranceSurvey: onDeleteInsuranceSurvey,
        },
        {
          currentInsuranceSurvey: selectedInsuranceSurvey,
          onDeleteInsuranceSurvey: onDeleteInsuranceSurvey,
          disabled: isJournalAndExpansesDisabled(task.status),
        },
      );
      if (gridOptionsNew.columnDefs && gridApi) {
        gridApi.setColumnDefs(gridOptionsNew.columnDefs);
      }
      setGridOptions(gridOptionsNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <>
      <ResultRenderer
        result={insuranceSurveyCombinedState.items}
        defaultValue={[]}
        loaded={(tasksResponse) => {
          return (
            <>
              {task.id && (
                <div className="is-flex is-flex-direction-column">
                  <div className="flex-float-right pr-3">
                    <InsuranceSurveyAddAllButton
                      task={task}
                      onAddAllInsuranceSurveys={refreshInsuranceSurvey}
                      disabled={isJournalAndExpansesDisabled(task.status)}
                    />
                    <InsuranceSurveyAddButton
                      task={task}
                      onAddInsuranceSurvey={refreshInsuranceSurvey}
                      disabled={isJournalAndExpansesDisabled(task.status)}
                    />
                  </div>
                </div>
              )}
              <div className="task-table-detail">
                <AgGridReact
                  suppressColumnMoveAnimation={true}
                  suppressCellFocus={true}
                  rowSelection={'single'}
                  onGridReady={onGridReadyLocal}
                  rowData={tasksResponse}
                  rowHeight={40}
                  gridOptions={gridOptions}
                  onSelectionChanged={async (event: SelectionChangedEvent) => {
                    const api = event.api;
                    const selectedNodes = api.getSelectedNodes();
                    let selectedInsuranceSurveyId = undefined;
                    if (selectedNodes.length > 0) {
                      const rowNode: RowNode = selectedNodes[0];
                      selectedInsuranceSurveyId = (
                        rowNode.data as InsuranceSurveyListDto
                      ).id;
                      setSelectedInsuranceSurveyId(selectedInsuranceSurveyId);
                      onInsuranceSurveySelected &&
                        (await onInsuranceSurveySelected(
                          selectedInsuranceSurveyId,
                        ));
                    }
                  }}
                  onSortChanged={(event: SortChangedEvent) => {
                    if (sorting) {
                      setSorting(undefined);
                      return;
                    }
                    // get order column and direction.
                    if (event.columnApi) {
                      const currentSorting = getSorting(event.columnApi);
                      setSorting(currentSorting);
                      // Avoid unfocus header problems in aggrid when changing filter.
                      const focused = document.activeElement;
                      if (focused) {
                        (focused as HTMLInputElement).blur();
                      }
                    }
                  }}
                />
              </div>
            </>
          );
        }}
      />

      {insuranceSurveyCombinedState.items.status == 'error' && (
        <span>{t('error.requestError.connectionError')}</span>
      )}
    </>
  );
};
