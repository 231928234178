import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalNotification,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';
import { BaseTask, TaskTypes } from '../../../types/types';
import BaseTaskDetailInformation from './base-task-detail-information';
import { useNavigate } from 'react-router';
import { RequestResult } from '../../../data';
import { BaseTaskListDto } from '../../../../../bat-shared/resource-models';
import { useBaseTask } from '../data/hooks';
import { VehicleTaskKind } from '../../../types/resource-models';

export const BaseTaskExistingClaimModal = ({
  className,
  draftStatus,
  taskResponse,
  taskDetailRoute,
  refTabs,
  refNextTab,
  setTab,
  onCreateNew,
  onEditTask,
  taskType,
}: {
  className?: string;
  draftStatus: boolean;
  taskResponse: BaseTaskListDto;
  taskDetailRoute: string;
  refTabs: React.RefObject<HTMLBalTabsElement>;
  refNextTab: React.RefObject<HTMLBalTabItemElement>;
  setTab: () => void;
  onCreateNew(task: RequestResult<BaseTask>): void;
  onEditTask(baseTaskId: string): Promise<void>;
  taskType: TaskTypes;
  vehicleTaskKind?: VehicleTaskKind;
}): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [baseTask] = useBaseTask(taskResponse.id, taskType);

  return taskResponse && baseTask.status === 'success' ? (
    <div className={className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {t('inspectionTask.claimInformation.existingBaseTaks')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>
          {t('inspectionTask.claimInformation.warningMessage', {
            claimNumber: taskResponse.claim.claimNumber,
            status: t(`baseTaskStatus.${taskResponse.status}`),
          })}
        </BalText>
        <BaseTaskDetailInformation
          className="mt-4"
          taskInformation={taskResponse}
          taskType={taskType}
        />
        <div className="mt-6 is-flex is-full-width is-justify-content-space-between">
          {taskResponse.status == 'New' ? (
            <BalNotification color="warning">
              <BalText>
                {t('inspectionTask.claimInformation.warningMessageDraftState')}
              </BalText>
            </BalNotification>
          ) : (
            <BalNotification color="danger">
              <BalText bold>
                {t('inspectionTask.claimInformation.warningMessageOpendState')}
              </BalText>
            </BalNotification>
          )}
        </div>
        {draftStatus === true ? (
          <BalButtonGroup position="right">
            <BalButton
              elementType="button"
              color="primary-light"
              onClick={(event) => {
                event.detail == 1 && balModalController.dismiss();
              }}
            >
              {t('general.buttons.cancel')}
            </BalButton>
            <BalButton
              color="danger"
              outlined
              onClick={(event) => {
                if (event.detail == 1) {
                  onCreateNew(baseTask);
                  balModalController.dismiss();
                }
              }}
            >
              {t('inspectionTask.inspectionTaskWorkflow.deleteAndCreateNew')}
            </BalButton>
            <BalButton
              color="info"
              onClick={async (event) => {
                if (event.detail === 1) {
                  await onEditTask(taskResponse.id);
                  refNextTab.current
                    ?.getOptions()
                    .then((options) => refTabs.current?.select(options));
                  setTab();
                  balModalController.dismiss();
                }
              }}
            >
              {t('inspectionTask.inspectionTaskWorkflow.edit')}
            </BalButton>
          </BalButtonGroup>
        ) : (
          <BalButtonGroup position="right">
            <BalButton
              elementType="button"
              color="primary-light"
              onClick={(event) =>
                event.detail == 1 && balModalController.dismiss()
              }
            >
              {t('general.buttons.cancel')}
            </BalButton>
            <BalButton
              color="danger"
              outlined
              onClick={(event) => {
                if (event.detail == 1) {
                  onCreateNew(baseTask);
                  balModalController.dismiss();
                }
              }}
            >
              {t('inspectionTask.inspectionTaskWorkflow.closeAndCreateNew')}
            </BalButton>
            <BalButton
              color="info"
              onClick={(event) => {
                if (event.detail == 1) {
                  navigate(taskDetailRoute, { replace: true });
                  navigate(0);
                  balModalController.dismiss();
                }
              }}
            >
              {t('inspectionTask.inspectionTaskWorkflow.toDetailView')}
            </BalButton>
          </BalButtonGroup>
        )}
      </BalModalBody>
    </div>
  ) : (
    <></>
  );
};
