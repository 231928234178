import { BalButton } from '@baloise/design-system-components-react';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import { AppCapabilityNames } from '../../../../types/resource-models';
import EditMeasureModal, { EditMeasureModalProps } from './edit-measure-modal';

type EditMeasureButtonProps = EditMeasureModalProps & {
  requiredCapabilities: AppCapabilityNames;
  disabled?: boolean;
};

export function EditMeasureButton(props: EditMeasureButtonProps): JSX.Element {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditMeasureModal,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: true,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        disabled={props.disabled}
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
}
