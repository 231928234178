import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  BalCard,
  BalCheckbox,
  BalCheckboxGroup,
  BalField,
  BalFieldControl,
  BalFieldMessage,
  BalHeading,
  BalIcon,
  BalList,
  BalListItem,
  BalListItemContent,
  BalListItemIcon,
  BalListItemSubtitle,
  BalListItemTitle,
  BalSpinner,
  BalText,
} from '@baloise/design-system-components-react';
import { RequestResult } from '../../../data';
import {
  AccidentInspectionTask,
  BaseTask,
  InvestigationTask,
  TaskTypes,
  VehicleTask,
} from '../../../types/types';
import { useDropdownUsers } from '../../../features/user/data/hooks';
import { EBRoutes } from '../../../router/router';
import { useToken } from '../../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import produce from 'immer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import _ from 'lodash';
import { BalTypeAheadField } from '../../../components/input';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import {
  AppCapabilityNames,
  BaseTaskStatus,
  CraftsmanFilterCriteria,
  CraftsmanListDto,
} from '../../../types/resource-models';
import { getDisplayNameFromUser } from '../../../utils/utilities';
import { PropertyInspectionTask } from '../../../types/types';
import { getAssigneePerTypeTranslated } from '../../../utils';
import { getCraftsmanList } from '../../network-partners/data/requests';

export const CompleteTaskCards = <T extends BaseTask>({
  task,
  setTask,
  taskType,
  taskButtons,
  patchTask,
  submitStatus,
  title,
  text,
  hasADOS,
  canChangeCreator,
  canChangeAgent,
  inspectorAppCapabilitiy,
  clerksAppCapabilitiy,
  nextLocation,
  setTab,
  assigneeRequired,
  quickLinkRegionAssignment,
  warning,
}: {
  task: RequestResult<T>;
  setTask: React.Dispatch<RequestResult<T>>;
  taskType: TaskTypes;
  taskButtons?: JSX.Element;
  patchTask(
    task: T,
    taskNew: T,
    bearerToken: string,
  ):
    | Promise<RequestResult<PropertyInspectionTask>>
    | Promise<RequestResult<VehicleTask>>
    | Promise<RequestResult<InvestigationTask>>
    | Promise<RequestResult<AccidentInspectionTask>>;
  submitStatus?: BaseTaskStatus;
  title?: string;
  text?: string;
  hasADOS?: boolean;
  canChangeCreator?: boolean;
  canChangeAgent?: boolean;
  inspectorAppCapabilitiy?: AppCapabilityNames[];
  clerksAppCapabilitiy?: AppCapabilityNames[];
  setTab: Dispatch<SetStateAction<string>>;
  nextLocation:
    | 'VEHICLE_INSPECTION_TASK'
    | 'PROPERTY_INSPECTION_TASK'
    | 'ACCIDENT_INSPECTION_TASK'
    | 'INVESTIGATION_TASK'
    | 'HOME';
  assigneeRequired?: boolean;
  quickLinkRegionAssignment?: boolean;
  warning?: JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });
  const token = useToken();
  const inspectors = useDropdownUsers({
    appCapabilityNames: inspectorAppCapabilitiy,
  });
  const clerks = useDropdownUsers({
    appCapabilityNames: clerksAppCapabilitiy,
  });

  const navigate = useNavigate();
  const [onlyADOS, setOnlyADOS] = useState<boolean | undefined>(undefined);
  const handleAdosCheckBoxOnChange = async (event: CustomEvent) => {
    setOnlyADOS(event.detail);
    if (event.detail) {
      methods.unregister('creator');
      methods.unregister('assignee');
      methods.unregister('agent');
      methods.unregister('assigneeIndependent');
    }
  };

  useEffect(() => {
    if (task.status === 'success' && hasADOS && onlyADOS === undefined) {
      const localOnlyADOS =
        task.localValue.claim.branch == 'Property' ||
        task.localValue.claim.branch == 'Technical';
      setOnlyADOS(localOnlyADOS);
      if (localOnlyADOS) {
        methods.unregister('creator');
        methods.unregister('assignee');
        methods.unregister('assigneeIndependent');
        methods.unregister('agent');
      }
    }
  }, [task, onlyADOS, hasADOS, methods]);

  // Making coherent initial value of agent.
  useEffect(() => {
    if (
      task.status === 'success' &&
      task.localValue.claim.agent?.id !== methods.getValues().agent
    ) {
      methods.setValue('agent', task.localValue.claim.agent);
      methods.clearErrors();
    }
  }, [task, methods]);

  const getValidateAssignee = (taskType: TaskTypes, baseTask: BaseTask) => {
    if (taskType === TaskTypes.PropertyInspectionTask) {
      const propertyInspectionTask = baseTask as PropertyInspectionTask;
      return !propertyInspectionTask.assignee &&
        !propertyInspectionTask.assigneeExternalId
        ? t('inspectionTask.assigneeOrAssigneeExternalRequired')
        : propertyInspectionTask.assignee != null &&
          propertyInspectionTask.assigneeExternalId != null
        ? t('inspectionTask.onlyAssigneeOrAssigneeExternalAllowed')
        : true;
    } else {
      return true;
    }
  };

  const getRequiredAssignee = (taskType: TaskTypes) => {
    if (taskType === TaskTypes.PropertyInspectionTask) {
      return false;
    } else {
      return assigneeRequired ?? false ? t('error.mandatoryField') : false;
    }
  };

  const [propertyCraftsmen, setPropertyCraftsmen] = useState<
    CraftsmanListDto[] | undefined
  >(undefined);

  useEffect(() => {
    if (!propertyCraftsmen) {
      const craftsmanFilter: CraftsmanFilterCriteria = {
        responsibleUserId: undefined,
        page: 0,
        maxItemCount: 1000,
        orderBy: 'asc',
        orderField: 'Company.Name',
        isDeleted: false,
        isIndependentPropertyCraftsman: true,
        branch: 'Property',
      };
      getCraftsmanList(token, craftsmanFilter).then((response) => {
        if (response.status === 'success') {
          setPropertyCraftsmen(response.value.entities);
        }
      });
    }
  }, [token, task, taskType, propertyCraftsmen]);

  const getNewTask = (task: T): T => {
    const newTask: T = {
      ...task,
      status: onlyADOS ? 'SentToArchive' : submitStatus ?? 'Assigned',
      assigneeId:
        onlyADOS || task.assignee === undefined ? undefined : task.assignee?.id,
      creatorId: task.creator.id,
    };
    if (
      taskType === TaskTypes.PropertyInspectionTask &&
      (newTask as PropertyInspectionTask).assigneeExternalId
    ) {
      (newTask as PropertyInspectionTask).status = 'InProgressWithIndependent';
    }
    return newTask;
  };

  return (
    <div>
      {(task.status === 'initial' || task.status === 'loading') && (
        <BalSpinner />
      )}
      {task.status === 'success' && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(() => {
              setTab('none');
              if (onlyADOS || task.localValue.assignee !== undefined) {
                setTask({ status: 'loading' });
                patchTask(task.value, getNewTask(task.localValue), token).then(
                  (inspectionTaskResponse) => {
                    if (inspectionTaskResponse.status === 'success') {
                      toast(
                        ToastNotification({
                          message: t(
                            'inspectionTask.completeClaim.successMessage',
                          ),
                          color: 'success',
                        }),
                      );
                      if (nextLocation)
                        navigate(EBRoutes[nextLocation].create({}));
                    } else if (inspectionTaskResponse.status === 'error') {
                      toast(ErrorToast(inspectionTaskResponse.errorValue));
                    }
                  },
                );
              }
            })}
          >
            <BalCard className="mt-5 p-5 columns is-multiline">
              <BalHeading
                space="none"
                level="h4"
                className="pb-3 column is-full"
              >
                {title}
              </BalHeading>
              <div className="column is-full columns is-multiline">
                <BalText className="column is-full">{text}</BalText>
                {hasADOS && (
                  <div className="pl-4 pb-6 pt-3 is-gapless is-full-width">
                    <BalField>
                      <BalFieldControl>
                        <BalCheckboxGroup>
                          <BalCheckbox
                            interface="switch"
                            checked={onlyADOS}
                            onBalChange={handleAdosCheckBoxOnChange}
                          >
                            {t(
                              'inspectionTask.completeClaim.adosCheckboxLabel',
                            )}
                          </BalCheckbox>
                        </BalCheckboxGroup>
                      </BalFieldControl>
                      <BalFieldMessage>
                        {t('inspectionTask.completeClaim.adosCheckboxMessage')}
                      </BalFieldMessage>
                    </BalField>
                  </div>
                )}
                {!onlyADOS && inspectors && inspectors.status === 'success' && (
                  <div
                    className={'is-flex is-full-width is-flex-direction-column'}
                  >
                    {canChangeCreator &&
                      clerks &&
                      clerks.status === 'success' && (
                        <div className="column columns is-multiline is-gapless is-full-width is-vcentered">
                          <BalTypeAheadField
                            id="creator"
                            requiredPlaceholder={true}
                            className="column is-8 pb-0"
                            label={t('general.claim.creator')}
                            result={clerks.value}
                            valueFormatter={(value) =>
                              getDisplayNameFromUser(value)
                            }
                            hasBkey
                            bKeyFormatter={(value) => value?.personalNumber}
                            keyFormatter={(value) => value?.id ?? ''}
                            value={task.localValue.creator}
                            formValidation={{
                              register: methods.register('creator', {
                                required: t('error.mandatoryField'),
                                value: task.localValue.creator,
                              }),
                              formState: methods.formState,
                            }}
                            onChange={(choice) => {
                              if (
                                !_.isEqual(task.localValue.creator, choice) &&
                                choice !== undefined
                              ) {
                                methods.setValue('creator', choice);
                                methods.clearErrors();
                              }
                              setTask(
                                produce(task, (draftState) => {
                                  draftState.localValue.creator = choice;
                                }),
                              );
                            }}
                          />
                        </div>
                      )}
                    <div
                      className={
                        'is-flex is-full-width is-flex-direction-column'
                      }
                    >
                      {canChangeAgent &&
                        clerks &&
                        clerks.status === 'success' && (
                          <div className="column columns is-multiline is-gapless is-full-width is-vcentered">
                            <BalTypeAheadField
                              className="column is-8 pb-0"
                              id="agent"
                              label={t('claimDetail.taskAgent')}
                              result={clerks.value}
                              valueFormatter={(value) =>
                                getDisplayNameFromUser(value)
                              }
                              hasBkey
                              bKeyFormatter={(value) => value?.personalNumber}
                              keyFormatter={(value) => value?.id ?? ''}
                              value={task.localValue.claim.agent}
                              formValidation={{
                                register: methods.register('agent', {
                                  required: t('error.mandatoryField'),
                                  value: task.localValue.claim.agent,
                                }),
                                formState: methods.formState,
                              }}
                              onChange={(choice) => {
                                if (
                                  !_.isEqual(
                                    task.localValue.claim.agent,
                                    choice,
                                  ) &&
                                  choice !== undefined
                                ) {
                                  methods.setValue('agent', choice);
                                  methods.clearErrors();
                                }
                                setTask(
                                  produce(task, (draftState) => {
                                    draftState.localValue.claim.agent = choice;
                                  }),
                                );
                              }}
                            />
                          </div>
                        )}
                    </div>
                    <div className="column columns is-multiline is-gapless is-full-width is-vcentered">
                      <>
                        <BalTypeAheadField
                          className="column is-8 pb-0"
                          id="assignee"
                          requiredPlaceholder={assigneeRequired}
                          label={getAssigneePerTypeTranslated(taskType)}
                          result={inspectors.value}
                          valueFormatter={(value) =>
                            getDisplayNameFromUser(value)
                          }
                          hasBkey={true}
                          bKeyFormatter={(value) => value?.personalNumber}
                          keyFormatter={(value) => value?.id ?? ''}
                          value={task.localValue.assignee}
                          formValidation={{
                            register: methods.register('assignee', {
                              required: getRequiredAssignee(taskType),
                              validate: () =>
                                getValidateAssignee(taskType, task.localValue),
                              value: task.localValue.assignee,
                            }),
                            formState: methods.formState,
                          }}
                          onChange={(choice) => {
                            if (
                              !_.isEqual(task.localValue.assignee, choice) &&
                              choice !== undefined
                            ) {
                              methods.setValue('assignee', choice);
                              methods.clearErrors();
                            }
                            setTask(
                              produce(task, (draftState) => {
                                draftState.localValue.assignee = choice;
                              }),
                            );
                          }}
                        />
                        {taskType === TaskTypes.PropertyInspectionTask &&
                          propertyCraftsmen && (
                            // Independent partner assignee selection.
                            <BalTypeAheadField
                              className="column is-8 pb-0"
                              id="assigneeIndependent"
                              requiredPlaceholder={assigneeRequired}
                              label={t('inspectionTask.externalExperts')}
                              result={propertyCraftsmen}
                              valueFormatter={(value) =>
                                value.type === 'person'
                                  ? value.person?.displayName ?? ''
                                  : value.company?.displayName ?? ''
                              }
                              hasBkey={false}
                              keyFormatter={(value) => value?.company?.id ?? ''}
                              value={
                                (task.localValue as PropertyInspectionTask)
                                  .assigneeExternal
                              }
                              formValidation={{
                                register: methods.register(
                                  'assigneeIndependent',
                                  {
                                    required: getRequiredAssignee(taskType),
                                    validate: () =>
                                      getValidateAssignee(
                                        taskType,
                                        task.localValue,
                                      ),
                                    value: (
                                      task.localValue as PropertyInspectionTask
                                    ).assigneeExternal,
                                  },
                                ),
                                formState: methods.formState,
                              }}
                              onChange={(choice) => {
                                const localValue =
                                  task.localValue as PropertyInspectionTask;
                                if (
                                  !_.isEqual(
                                    localValue.assigneeExternal,
                                    choice,
                                  ) &&
                                  choice !== undefined
                                ) {
                                  methods.setValue(
                                    'assigneeIndependent',
                                    choice,
                                  );
                                  methods.clearErrors();
                                }
                                setTask(
                                  produce(task, (draftState) => {
                                    (
                                      draftState.localValue as PropertyInspectionTask
                                    ).assigneeExternalId = choice?.company?.id;
                                    (
                                      draftState.localValue as PropertyInspectionTask
                                    ).assigneeExternal = choice;
                                  }),
                                );
                              }}
                            />
                          )}
                      </>
                    </div>
                    {warning && warning}
                    {quickLinkRegionAssignment && (
                      <BalList className="py-0 column is-full">
                        <BalListItem
                          clickable
                          target="_blank"
                          href={window.extended.BAT_APP_REGION_ASSIGNMENT}
                        >
                          <BalListItemContent>
                            <BalListItemTitle>
                              {t('home.quickLinks.regionAssignment')}
                            </BalListItemTitle>
                            <BalListItemSubtitle>
                              {t('home.quickLinks.regionAssignmentHint')}
                            </BalListItemSubtitle>
                          </BalListItemContent>
                          <BalListItemIcon right>
                            <BalIcon name="nav-go-right" size="xsmall" />
                          </BalListItemIcon>
                        </BalListItem>
                      </BalList>
                    )}
                  </div>
                )}
              </div>
            </BalCard>
            {taskButtons && taskButtons}
          </form>
        </FormProvider>
      )}
    </div>
  );
};
