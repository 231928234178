import L from 'leaflet';
import { MapContainerProps } from 'react-leaflet';
import pin from '../../../assets/icons/pin.svg';

export const MarkerIcon = L.icon({
  className: 'marker-icon-custom',
  iconUrl: pin,
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [-8, -32],
});

L.Marker.prototype.options.icon = MarkerIcon;

export const mapProps: Required<
  Pick<
    MapContainerProps,
    | 'center'
    | 'zoom'
    | 'minZoom'
    | 'maxZoom'
    | 'attributionControl'
    | 'maxBounds'
  >
> = {
  center: [46.786, 8.265],
  zoom: 8.25,
  minZoom: 8.25,
  maxZoom: 20,
  attributionControl: false,
  maxBounds: [
    [44.677, 4.923],
    [48.931, 11.543],
  ],
};

export const mapUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
