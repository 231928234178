import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { WithCapabilities } from '../../../../../../components/authorization';
import { balModalController } from '../../../../../../controller/controllers';
import { InsuranceSurveyListDto } from '../../../../../../types/resource-models';
import DeleteConfirmationDialog from '../../../../../dialogs/delete-confirmation-dialog';
import { capitalize } from 'lodash';
export interface InsuranceSurveyDeleteButtonProps {
  currentInsuranceSurvey: InsuranceSurveyListDto | undefined;
  disabled?: boolean;
  onDeleteInsuranceSurvey: (
    insuranceSurvey: InsuranceSurveyListDto,
  ) => Promise<void>;
  textButton?: boolean;
}

const InsuranceSurveyDeleteButton = ({
  currentInsuranceSurvey,
  disabled,
  onDeleteInsuranceSurvey,
  textButton,
}: InsuranceSurveyDeleteButtonProps): JSX.Element => {
  const openDeleteModal = async () => {
    if (currentInsuranceSurvey) {
      const modal = await balModalController.create({
        component: DeleteConfirmationDialog,
        componentProps: {
          title: `${t('general.buttons.delete')} ${t(
            'investigationTask.insuranceSurvey.entityName',
          )}`,
          text: t(
            'investigationTask.insuranceSurvey.deleteConfirmationQuestion',
          ),
          onDelete: async () => {
            await onDeleteInsuranceSurvey(currentInsuranceSurvey);
            setTimeout(() => {
              balModalController.dismiss();
            }, 1000);
          },
        },
        cssClass: 'center-modal',
      });
      return modal.present();
    }
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      {textButton ? (
        <BalButton
          className="ml-4"
          color="danger"
          onClick={async () => await openDeleteModal()}
        >
          {capitalize(t('general.buttons.delete'))}
        </BalButton>
      ) : (
        <BalButton
          disabled={disabled}
          className="ml-2"
          outlined
          square
          size="small"
          color="danger"
          icon="minus"
          onClick={async () => await openDeleteModal()}
        />
      )}
    </WithCapabilities>
  );
};

export default InsuranceSurveyDeleteButton;
