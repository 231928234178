import {
  BalButton,
  BalButtonGroup,
  BalDatepicker,
  BalFieldLabel,
  BalHeading,
  BalIcon,
  BalModalBody,
  BalModalHeader,
  BalSelect,
  BalSelectOption,
} from '@baloise/design-system-components-react';
import {
  balIconSearch,
  balIconEdit,
  balIconPlus,
} from '@baloise/design-system-icons';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WithCapabilities } from '../../../../components/authorization';
import { BalNumberFormField, BalTextField } from '../../../../components/input';
import BalSelectFormField from '../../../../components/input/bal-select-form-field';
import { balModalController } from '../../../../controller/controllers';
import { useToken } from '../../../../hooks';
import {
  ExpenseCurrencyDto,
  ExpenseDto,
  ExpenseTypeDto,
} from '../../../../types/resource-models';
import { Globals } from '../../../../utils';
import DeleteExpensesButton from './delete-expense';
import { CancelButton } from '../../../../components/ui';

const CreateEditShowExpensesFormModal = ({
  oldExpense,
  updateExpense,
  create,
  show,
  taskId,
}: {
  oldExpense: ExpenseDto;
  updateExpense: (
    expense: ExpenseDto,
    created: boolean,
    deleted: boolean,
    token: string,
  ) => void;
  create: boolean;
  show?: boolean;
  taskId?: string;
}): JSX.Element => {
  const [expense, setExpense] = useState<ExpenseDto>(
    oldExpense ?? {
      expenseType: 'Observations',
      currency: 'CHF',
      createdAt: new Date().toISOString(),
    },
  );
  const methods = useForm({ mode: 'onChange' });
  const { t } = useTranslation();
  const token = useToken();
  return (
    <FormProvider {...methods}>
      <form>
        <BalModalHeader>
          <BalHeading space="none" level="h3" subtitle>
            {create ? t('expense.createExpense') : t('expense.editExpense')}
          </BalHeading>
        </BalModalHeader>
        <BalModalBody>
          <BalFieldLabel>{t('expense.createdAt')}</BalFieldLabel>
          <BalDatepicker
            className="mb-4"
            disabled={show}
            placeholder={t('expense.createdAt')}
            value={expense?.createdAt.slice(0, 10)}
            onBalChange={(e: CustomEvent<null | string | undefined>) => {
              if (e.detail || e.detail === '') {
                setExpense({ ...expense, createdAt: e.detail });
              }
            }}
          />
          <BalFieldLabel>{t('expense.expenseType')}</BalFieldLabel>
          <BalSelect
            disabled={show}
            value={expense?.expenseType}
            placeholder={t('general.pleaseChoose')}
            onBalChange={(e) => {
              if (e.detail) {
                setExpense({
                  ...expense,
                  expenseType: e.detail.toString() as ExpenseTypeDto,
                });
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {Globals.ExpensesTypes.map((value) => (
                <BalSelectOption
                  key={value}
                  value={value}
                  label={t(`expense.expenseTypes.${value}`) as string}
                >
                  {t(`expense.expenseTypes.${value}`) as string}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
          <BalTextField
            className="mt-4"
            disabled={show}
            title={t('expense.expenseName')}
            placeholder={t('expense.expenseName')}
            value={expense?.expenseName ?? ''}
            onChange={(e: CustomEvent<string | undefined>) => {
              setExpense({ ...expense, expenseName: e.detail });
            }}
          />
          <BalNumberFormField
            controllerName="expenseSum"
            className="mb-4"
            disabled={show}
            methods={methods}
            title={t('expense.expenseSum')}
            placeholder={t('expense.expenseSum')}
            value={expense?.expenseSum?.toString() ?? ''}
            onChange={(e: CustomEvent<string | undefined>) => {
              setExpense({
                ...expense,
                expenseSum: e.detail as unknown as number,
              });
            }}
          />
          <BalSelectFormField
            controllerName="expenseType"
            methods={methods}
            disabled={show}
            value={expense?.currency}
            values={Globals.ExpenseCurrencies}
            onChange={(e) => {
              if (e) {
                setExpense({
                  ...expense,
                  currency: e.detail.toString() as ExpenseCurrencyDto,
                });
              }
            }}
          />
          <BalButtonGroup position="right">
            {!create && taskId && (
              <DeleteExpensesButton
                expense={expense}
                updateExpense={updateExpense}
                textButton={!create}
                taskId={taskId}
                onDelete={() => {
                  setTimeout(() => {
                    balModalController.dismiss();
                  }, 1000);
                }}
              />
            )}
            <CancelButton />
            <BalButton
              color="info"
              onClick={() => {
                updateExpense(
                  create
                    ? { ...expense, createdAt: new Date().toDateString() }
                    : expense,
                  create,
                  false,
                  token,
                );

                balModalController.dismiss();
              }}
              disabled={
                show ||
                (!methods.formState.isValid &&
                  Object.keys(methods.formState.errors).length !== 0)
              }
            >
              {t('general.buttons.save')}
            </BalButton>
          </BalButtonGroup>
        </BalModalBody>
      </form>
    </FormProvider>
  );
};

const CreateEditShowExpensesButton = ({
  expense,
  updateExpense,
  create,
  show,
  disabled,
  taskId,
}: {
  expense?: ExpenseDto;
  updateExpense: (
    expense: ExpenseDto,
    create: boolean,
    deleted: boolean,
    token: string,
  ) => void;
  create?: boolean;
  show?: boolean;
  disabled?: boolean;
  taskId?: string;
}): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: CreateEditShowExpensesFormModal,
      componentProps: {
        oldExpense: create ? undefined : expense,
        updateExpense: updateExpense,
        create: create,
        show: show,
        taskId: taskId,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };
  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        className="ml-2"
        disabled={disabled}
        outlined
        square
        size="small"
        color="info"
        onClick={async () => openModal()}
      >
        <BalIcon
          svg={show ? balIconSearch : create ? balIconPlus : balIconEdit}
          size="small"
        />
      </BalButton>
    </WithCapabilities>
  );
};

export default CreateEditShowExpensesButton;
