import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToken } from '../../../hooks';
import { RequestResult } from '../../../data';
import {
  Craftsman,
  CraftsmanDetail,
  CraftsmansResponse,
  PropertyCraftsman,
  VehicleCraftsman,
  VehicleCraftsmanFilter,
  WorkCategories,
} from '../../../types/types';
import {
  getCraftsman,
  getCraftsmanDetail,
  getCraftsmans,
  getWorkCategories,
} from './requests';
import { Globals } from '../../../utils';
import {
  CraftsmanFilterCriteria,
  SortOrder,
} from '../../../types/resource-models';
import { UserContext } from '../../../context';

export type CombinedState = {
  craftsmans: RequestResult<CraftsmansResponse>;
  filter: CraftsmanFilterCriteria;
  defaultFilter: CraftsmanFilterCriteria;
  label?: string;
};

export type CombinedStateProps = {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
};

export const filterVehicleCraftsmans: Partial<VehicleCraftsmanFilter> = {
  branch: 'MotorVehicle',
  isDeleted: false,
  orderField: 'partnerNumber',
  orderBy: 'asc',
};

export let networkPartnerState: CombinedState | undefined;
export let networkPartnerSetState:
  | React.Dispatch<React.SetStateAction<CombinedState>>
  | undefined;

export function useCraftsmansFromApi(): [
  CombinedState,
  React.Dispatch<React.SetStateAction<CombinedState>>,
] {
  const { t } = useTranslation();
  const token = useToken();
  const { user } = useContext(UserContext);
  const [combinedState, setCombinedState] = useState<CombinedState>({
    craftsmans: { status: 'initial' },
    filter: {
      maxItemCount: 9999,
      orderBy: 'asc',
      page: 0,
      ...filterVehicleCraftsmans,
    },
    defaultFilter: {
      ...Globals.craftsmanFilterDefault,
      ...filterVehicleCraftsmans,
    },
    label: t('networkPartners.VehicleCraftsmans'),
  });

  useEffect(() => {
    if (user.status === 'success') {
      // check if exists an user's filter information and apply it
      let customOrderField: string | undefined = 'partnerNumber';
      let customOrderBy: SortOrder | undefined = 'asc';
      const userTableSetting = user.localValue.userTableSettings.find(
        (x) => x.type === 'Craftsman',
      );
      if (userTableSetting?.orderField) {
        customOrderField = userTableSetting?.orderField;
        customOrderBy = userTableSetting?.orderBy;
      }
      if (
        customOrderField &&
        customOrderBy &&
        combinedState.defaultFilter.orderField ===
          combinedState.filter.orderField &&
        combinedState.defaultFilter.orderBy === combinedState.filter.orderBy
      ) {
        setCombinedState(
          produce(combinedState, (draft) => {
            draft.filter.orderField = customOrderField;
            draft.filter.orderBy = customOrderBy ?? 'asc';
          }),
        );
      }
    }
  }, [user, combinedState]);

  useEffect(() => {
    if (
      token !== '' &&
      (combinedState.craftsmans.status === 'initial' ||
        combinedState.craftsmans.status === 'loading-with-value')
    ) {
      getCraftsmans(token, combinedState.filter).then((result) => {
        setCombinedState(
          produce(combinedState, (draftState) => {
            draftState.craftsmans = result;
          }),
        );
      });
    }
  }, [token, combinedState]);

  networkPartnerState = combinedState;
  networkPartnerSetState = setCombinedState;

  return [combinedState, setCombinedState];
}

export const replaceCraftsmanInStateList = (
  newCraftsman: RequestResult<Craftsman>,
): void => {
  if (
    networkPartnerState &&
    networkPartnerSetState &&
    newCraftsman.status === 'success' &&
    networkPartnerState.craftsmans.status === 'success'
  ) {
    const index = networkPartnerState.craftsmans.value.entities.findIndex(
      (craftsman: Craftsman) =>
        (newCraftsman.value.type === craftsman.type &&
          newCraftsman.value.type === 'propertyCraftsman' &&
          newCraftsman.value.propertyCraftsman.id ==
            (craftsman as PropertyCraftsman).propertyCraftsman.id) ||
        (newCraftsman.value.type === 'vehicleCraftsman' &&
          newCraftsman.value.vehicleCraftsman.id ==
            (craftsman as VehicleCraftsman).vehicleCraftsman.id),
    );
    networkPartnerSetState(
      produce(networkPartnerState, (draftState) => {
        if (draftState.craftsmans.status === 'success') {
          draftState.craftsmans.value.entities.splice(
            index,
            1,
            newCraftsman.value,
          );
        }
      }),
    );
  }
};

export const refreshCraftsmanList = (): void => {
  if (networkPartnerState && networkPartnerSetState) {
    networkPartnerSetState(
      produce(networkPartnerState, (draftState) => {
        draftState.craftsmans.status = 'initial';
      }),
    );
  }
};

export function useCraftsmanFromApi(
  networkPartnerID: string,
): [
  RequestResult<Craftsman>,
  React.Dispatch<React.SetStateAction<RequestResult<Craftsman>>>,
] {
  const token = useToken();
  const [craftsmanResponse, setCraftsmanResponse] = useState<
    RequestResult<Craftsman>
  >({
    status: 'loading',
  });

  useEffect(() => {
    if (token !== '' && networkPartnerID !== '') {
      getCraftsman(token, networkPartnerID).then(setCraftsmanResponse);
    }
  }, [token, networkPartnerID]);
  return [craftsmanResponse, setCraftsmanResponse];
}

export function useCraftsmanDetailFromApi(
  networkPartnerID: string,
): [
  RequestResult<CraftsmanDetail>,
  React.Dispatch<React.SetStateAction<RequestResult<CraftsmanDetail>>>,
] {
  const token = useToken();
  const [craftsmanResponse, setCraftsmanResponse] = useState<
    RequestResult<CraftsmanDetail>
  >({
    status: 'loading',
  });

  useEffect(() => {
    if (
      token !== '' &&
      networkPartnerID !== '' &&
      craftsmanResponse.status === 'loading'
    ) {
      getCraftsmanDetail(token, networkPartnerID).then(setCraftsmanResponse);
    }
  }, [token, networkPartnerID, craftsmanResponse]);
  return [craftsmanResponse, setCraftsmanResponse];
}

export function useWorkCategoriesFromApi(): RequestResult<WorkCategories> {
  const token = useToken();
  const [workCategories, setWorkCategories] = useState<
    RequestResult<WorkCategories>
  >({ status: 'loading' });

  useEffect(() => {
    if (token !== '' && workCategories.status === 'loading') {
      getWorkCategories(token).then(setWorkCategories);
    }
  }, [token, workCategories.status]);
  return workCategories;
}
