import {
  BalButton,
  BalButtonGroup,
  BalCheckbox,
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AppCapabilityNames } from '../../types/resource-models';
import { WithCapabilities } from '../authorization/with-capabilities';

type EditCheckboxModalProps = {
  label: string;
  text: string;
  value: boolean;
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  onSave(value: boolean): void;
};

const EditCheckboxModalContent = (
  props: EditCheckboxModalProps,
): JSX.Element => {
  const { t } = useTranslation();
  const [value, setValue] = useState<boolean>(props.value);

  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {props.label}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalCheckbox checked={value} onBalChange={(e) => setValue(e.detail)}>
          {props.text}
        </BalCheckbox>
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            color="info"
            onClick={(event) => {
              event.detail == 1 && props.onSave(value);
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export const EditCheckboxModal = (
  props: EditCheckboxModalProps,
): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditCheckboxModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        className={props.className}
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
};
