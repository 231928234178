import { BalSpinner } from '@baloise/design-system-components-react';
import { RequestError, RequestResult } from '../../data';
import { toast } from 'react-toastify';
import { ErrorToast } from '../toast-notification';

export type RequestErrorProps = { requestError: RequestError };

type ResultViewProps<T> = {
  /**
   * The result as returned from the API.
   */
  result: RequestResult<T>;
  /**
   * The view to display when an error ocurred.
   * If no view specified, ErrorToast is used.
   */
  ErrorView?: React.ComponentType<RequestErrorProps>;
  /**
   * An optional loading view. If you don't pass any, the default spinners are used.
   */
  LoadingView?: JSX.Element;
  /**
   * What to display once loading finished.
   */
  loaded: (value: T) => JSX.Element;
  /**
   * What to pass to loaded if an error occured.
   */
  defaultValue: T;
};

/**
 * Handle `RequestsResult` without thinking about the loading and the error case.
 */
export const ResultRenderer = <T,>(props: ResultViewProps<T>): JSX.Element => {
  const { result, ErrorView, LoadingView, loaded, defaultValue } = props;
  const Loader = LoadingView ? LoadingView : <BalSpinner />;

  switch (result.status) {
    case 'initial': {
      return Loader;
    }
    case 'loading': {
      return Loader;
    }
    case 'error': {
      const { errorValue } = result;
      return (
        <>
          {ErrorView ? (
            <ErrorView requestError={errorValue} />
          ) : (
            toast(ErrorToast(errorValue))
          )}
          {loaded(defaultValue)}
        </>
      );
    }
    case 'loading-with-value':
    case 'success': {
      const { value } = result;
      return loaded(value);
    }
    case 'success-no-value': {
      return Loader;
    }
  }
};
