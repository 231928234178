import { BalText } from '@baloise/design-system-components-react';
import {
  BaseTaskChangeDto,
  CraftsmanChangeDto,
  InsuranceSurveyChangeDto,
  JournalChangeDto,
  PropertyInspectionTaskJournalChangeDto,
} from '../../../types/resource-models';
import { EntityChange } from '../../../types/types';
import { concat, StringCap } from '../../../utils';
import { getCraftsmanMessage } from './entity-protocol-craftsman-messages';
import { getBaseTaskMessage } from './entity-protocol-task-messages';
import { getJournalMessage } from './entity-protocol-journal-messages';
import { getInsuranceSurveyMessage } from './entity-protocol-insurance-survey-messages';
import { getPropertyInspectionTaskJournalMessage } from './entity-protocol-property-inspection-task-journal-messages';

type EntityProtocolProps = {
  history: EntityChange[];
  maxMessageLength?: number;
};

export type ProtocolMessage = {
  message: string;
  change?: EntityChange | undefined;
  icon: JSX.Element;
};

const getGroupedChanges = (history: EntityChange[]) => {
  const getMessage = (change: EntityChange, groupedChanges: EntityChange[]) => {
    if (change.entityRelatedToChange === 'BaseTask') {
      return getBaseTaskMessage(
        change as BaseTaskChangeDto,
        groupedChanges as BaseTaskChangeDto[],
      );
    }

    if (change.entityRelatedToChange === 'Craftsman') {
      return getCraftsmanMessage(
        change as CraftsmanChangeDto,
        groupedChanges as CraftsmanChangeDto[],
      );
    }

    if (change.entityRelatedToChange === 'InvestigationTaskJournal') {
      return getJournalMessage(
        change as JournalChangeDto,
        groupedChanges as JournalChangeDto[],
      );
    }

    if (change.entityRelatedToChange === 'InsuranceSurvey') {
      return getInsuranceSurveyMessage(
        change as InsuranceSurveyChangeDto,
        groupedChanges as InsuranceSurveyChangeDto[],
      );
    }

    if (change.entityRelatedToChange === 'PropertyInspectionTaskJournal') {
      return getPropertyInspectionTaskJournalMessage(
        change as PropertyInspectionTaskJournalChangeDto,
        groupedChanges as PropertyInspectionTaskJournalChangeDto[],
      );
    }
  };

  const groupedChanges: {
    messages: string[];
    changes: EntityChange[];
    icon: JSX.Element;
    datetime: string;
    userName: string | undefined;
    dateTime: string;
    id: string;
  }[] = [];
  history.map((change) => {
    const otherElement = groupedChanges?.find(
      (c) => c?.datetime === change.dateTime,
    );
    if (otherElement) {
      const previous = groupedChanges.pop();
      if (previous) {
        const newValue = getMessage(change, previous.changes);
        groupedChanges.push({
          messages: previous.messages.includes(newValue?.message ?? '')
            ? previous.messages
            : [...previous.messages, newValue?.message ?? ''],
          changes:
            newValue?.change &&
            !previous.changes.some(
              (change) => change.id === newValue?.change?.id ?? '',
            )
              ? [...previous.changes, newValue.change]
              : previous.changes,
          icon: newValue?.icon ?? previous.icon,
          datetime: change.dateTime,
          userName: change.userName,
          dateTime: change.dateTime,
          id: change.id,
        });
      }
    } else {
      const values = getMessage(change, []);
      if (values) {
        groupedChanges.push({
          messages: [values.message],
          changes: values.change ? [values.change] : [],
          icon: values.icon,
          datetime: change.dateTime,
          userName: change.userName,
          dateTime: change.dateTime,
          id: change.id,
        });
      }
    }
  });
  return groupedChanges;
};

const EntityProtocol = ({
  history,
  maxMessageLength,
}: EntityProtocolProps): JSX.Element => {
  const groupedChanges = getGroupedChanges(history);
  const messageLength = maxMessageLength ?? 40;
  return (
    <>
      <div className="timeline-line" />
      {groupedChanges.map((change) => {
        const date = new Date(change.dateTime);
        const formattedDate = new Date(
          date.getTime() + -date.getTimezoneOffset() * 60000,
        ).toLocaleString();
        return (
          <div className="mb-2 is-flex" key={change.id}>
            {change.icon}
            <div className="is-flex is-flex-direction-column">
              {change.messages.map((message) => {
                return (
                  <div
                    data-tooltip={message}
                    data-tooltip-location="bottom"
                    key={message + change.id}
                    className={
                      message.length > messageLength ? 'tooltip ml-4' : 'ml-4'
                    }
                  >
                    <BalText className="mb-0" color="info" size="small">
                      {StringCap(message, messageLength)}
                    </BalText>
                  </div>
                );
              })}
              <BalText className="ml-4" size="small" color="primary-light">
                {concat([change.userName, formattedDate], 'comma-space')}
              </BalText>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EntityProtocol;
