import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useState, useEffect } from 'react';
import {} from '../../../types/types';
import '../../../styles/general.scss';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../data';
import {
  InspectionListDto,
  ResponseOfInspectionListDto,
} from '../../../types/resource-models';
import {
  deletePropertyInspectionTaskInspection,
  getPropertyInspectionTaskInspections,
} from '../data/requests';
import { useToken } from '../../../hooks';
import { earlybyteTableTextRenderer } from '../../../utils/utilities';
import { convertUtcToLocalWithoutTime } from '../../../utils/date';
import { ResultRenderer } from '../../../components/ui';
import { BalButton } from '@baloise/design-system-components-react';
import { EBRoutes } from '../../../router/router';
import { useNavigate } from 'react-router';
import InspectionDeleteButton from './inspection-toolbar/inspection-delete-button';
import { toast } from 'react-toastify';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';

export interface InspectionSelectorFormProps {
  inspectionTaskId: string;
  initialSelectedInspectionId?: string;
  initialInspections?: InspectionListDto[];
  //onDeleteInspection: (inspectionId: string) => Promise<void>;
}
export const InspectionSearchForm = ({
  inspectionTaskId,
  initialInspections,
}: //onDeleteInspection,
InspectionSelectorFormProps): JSX.Element => {
  const [inspections, setInspections] = useState<
    RequestResult<ResponseOfInspectionListDto>
  >(
    initialInspections
      ? {
          status: 'success',
          value: {
            entities: initialInspections,
            totalItems: initialInspections.length,
            totalPages: 1,
          },
          localValue: {
            entities: initialInspections,
            totalItems: initialInspections.length,
            totalPages: 1,
          },
        }
      : { status: 'initial' },
  );

  const token = useToken();

  useEffect(() => {
    if (inspections.status === 'initial') {
      getPropertyInspectionTaskInspections(token, inspectionTaskId).then(
        (result) => {
          setInspections(result);
        },
      );
    }
  }, [token, inspections, inspectionTaskId]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const getGridOptions = (): GridOptions => {
    return {
      onGridSizeChanged(event) {
        if (event.clientWidth > 0) {
          event.api.sizeColumnsToFit();
        }
      },
      defaultColDef: {
        sortable: true,
        filter: false,
        autoHeight: false,
        resizable: true,
      },

      overlayNoRowsTemplate: '&nbsp;',
      rowHeight: 40,
      columnDefs: [
        {
          headerName: t('inspectionTask.inspection.inspectionDate'),
          width: 80,
          cellRenderer: earlybyteTableTextRenderer,
          valueGetter: (params) => {
            const inspection = params.data as InspectionListDto;
            if (inspection.inspectionClaim?.inspectionDate) {
              return convertUtcToLocalWithoutTime(
                inspection.inspectionClaim?.inspectionDate,
              );
            } else {
              return '';
            }
          },
          comparator: (valueA: string, valueB: string) => {
            if (valueA.toLowerCase() == valueB.toLowerCase()) return 0;
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
          },
        },
        {
          field: 'status',
          headerName: t('inspectionTask.inspection.status'),
          width: 80,
          valueGetter: (params) => {
            const inspection = params.data as InspectionListDto;
            if (inspection.status) {
              return t(
                `inspectionTask.inspection.inspectionStates.${inspection.status}`,
              );
            } else {
              return '';
            }
          },

          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          field: 'totalCosts',
          headerName: t('inspectionTask.inspection.totalCosts'),
          width: 80,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          field: 'id',
          headerName: t('inspectionTask.inspection.identifier'),
          width: 64,
          valueGetter: (params) => {
            const inspection = params.data as InspectionListDto;
            return inspection.id.substring(0, 8);
          },
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          cellRenderer: function renderEdit(params: ICellRendererParams) {
            return (
              <div className="mt-3">
                <BalButton
                  square
                  outlined
                  size="small"
                  color="primary-light"
                  icon="edit"
                  disabled={
                    (params.data as InspectionListDto).status === 'Accepted'
                  }
                  onClick={() => {
                    const inspection = params.data as InspectionListDto;
                    navigate(
                      EBRoutes.PROPERTY_INSPECTION_TASK_SENDING_WIZARD.create({
                        taskId: inspectionTaskId,
                        inspectionId: inspection.id,
                      }),
                    );
                  }}
                ></BalButton>
                <InspectionDeleteButton
                  currentInspection={params.data as InspectionListDto}
                  onDeleteInspection={async (inspection) => {
                    await deletePropertyInspectionTaskInspection(
                      inspectionTaskId,
                      inspection.id,
                      token,
                    ).then((response) => {
                      if (response.status === 'error') {
                        toast(ErrorToast(response.errorValue));
                      } else {
                        toast(
                          ToastNotification({
                            message: t(
                              'inspectionTask.inspection.deleteSuccessMessage',
                            ),
                            color: 'success',
                          }),
                        );
                        setInspections((prev) => ({
                          ...prev,
                          status: 'initial',
                        }));
                      }
                    });
                  }}
                ></InspectionDeleteButton>
              </div>
            );
          },
          sortable: false,
          width: 70,
          minWidth: 70,
          resizable: false,
        },
      ],
    };
  };

  return (
    <>
      <div className="task-table-detail mt-3">
        <ResultRenderer
          result={inspections}
          defaultValue={{ totalPages: 1, totalItems: 0, entities: [] }}
          loaded={(response) => {
            return (
              <AgGridReact
                suppressCellFocus={true}
                rowSelection={'single'}
                rowData={response.entities}
                gridOptions={getGridOptions()}
              />
            );
          }}
        />
      </div>
    </>
  );
};
