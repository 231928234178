import {
  BalCard,
  BalHeading,
  BalText,
  BalSpinner,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { getBaseTasksList } from '../data/requests';
import { BaseTask } from '../../../types/types';
import { Controller, useForm } from 'react-hook-form';
import { ErrorToast } from '../../../components/toast-notification/error-toast';
import { useToken } from '../../../hooks';
import investigationImage from '../../../assets/img/BVM-Logo-sw_1000.png';
import { BalTextField } from '../../../components/input';
import { balModalController } from '../../../controller/controllers';
import { BaseTaskExistingClaimModal } from './base-task-existing-claim-modal';
import { BrowserRouter } from 'react-router-dom';
import { EBRoutes } from '../../../router/router';
import { TaskTypes } from '../../../types/types';
import {
  BaseTaskFilterCriteria,
  BaseTaskListDto,
} from '../../../../../bat-shared/resource-models';
import { toast } from 'react-toastify';

import React, { RefObject } from 'react';
import VehicleTaskExistingClaimModal from '../../vehicle-task/components/vehicle-task-existing-claim-modal';
import { RequestResult } from '../../../data/fetch/result';

export const ClaimNumberInputCard = <T extends BaseTask>({
  setClaimNumber,
  task,
  refTabs,
  refTabContactInformation,
  refNextTab,
  setTab,
  taskButtons,
  title,
  text,
  pattern,
  isAccidentTask,
  isVehicleTask,
  isRiskAssessment,
  isBonusCorrection,
  isInvestigation,
  placeholder,
  onEditTask,
  onSearchAction,
  onCreateNew,
  taskType,
  patternErrorMessage,
}: {
  setClaimNumber?: React.Dispatch<React.SetStateAction<string>>;
  task: RequestResult<T>;
  setTask: React.Dispatch<RequestResult<T>>;
  refTabs: React.RefObject<HTMLBalTabsElement>;
  refTabContactInformation?: RefObject<HTMLBalTabItemElement>;
  refTabVehicleContact?: RefObject<HTMLBalTabItemElement>;
  refNextTab: React.RefObject<HTMLBalTabItemElement>;
  setTab: () => void;
  taskButtons?: JSX.Element;
  title: string;
  text: string;
  pattern: RegExp;
  isAccidentTask?: boolean;
  isVehicleTask?: boolean;
  isRiskAssessment?: boolean;
  isBonusCorrection?: boolean;
  isInvestigation?: boolean;
  placeholder: string;
  onEditTask: (taskId: string) => Promise<void>;
  onSearchAction: (
    claimNumber: string,
    refNextTab?: RefObject<HTMLBalTabItemElement> | null,
  ) => void;
  onCreateNew(task?: RequestResult<BaseTask>, claimNumber?: string): void;
  taskType: TaskTypes;
  patternErrorMessage?: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const { handleSubmit, control } = useForm();

  const onTaskCreation = (claimNumber: string) => {
    const filters: BaseTaskFilterCriteria = {
      claimNumber: claimNumber,
      page: 0,
      maxItemCount: isVehicleTask ? 9999 : 1,
      notStatus: ['Closed'],
      orderBy: 'desc',
      orderField: 'createdAt',
      vehicleTaskKinds: isVehicleTask
        ? isBonusCorrection
          ? ['VehicleBonusCorrection']
          : ['VehicleInspectionTask']
        : undefined,
    };

    if (isRiskAssessment || (isInvestigation && claimNumber.length < 11)) {
      onSearchAction(claimNumber, refTabContactInformation);
    } else {
      getBaseTasksList(token, filters, taskType).then((result) => {
        if (result.status === 'success') {
          if (result.value.entities.length > 0) {
            if (isVehicleTask) {
              openModal(result.value.entities, false);
            } else if (
              result.value.entities[0].status !== 'New' &&
              result.value.entities[0].status !== 'Closed'
            ) {
              openModal(result.value.entities, false);
            } else if (result.value.entities[0].status === 'New') {
              openModal(result.value.entities, true);
            } else {
              onSearchAction(claimNumber, refTabContactInformation);
            }
          } else {
            onSearchAction(claimNumber, refTabContactInformation);
          }
        } else if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      });
    }
  };

  const modalWithRouter = ({
    tasksResponse,
    draftStatus,
    isVehicleTask,
  }: {
    tasksResponse: BaseTaskListDto[];
    draftStatus: boolean;
    isVehicleTask?: boolean;
  }) => {
    return (
      tasksResponse && (
        <BrowserRouter>
          {isVehicleTask ? (
            <>
              <VehicleTaskExistingClaimModal
                tasksResponse={tasksResponse}
                onCreateNew={onCreateNew}
                onEditTask={onEditTask}
                setTab={setTab}
              />
            </>
          ) : (
            <BaseTaskExistingClaimModal
              refTabs={refTabs}
              refNextTab={refNextTab}
              setTab={setTab}
              taskResponse={tasksResponse[0]}
              draftStatus={draftStatus}
              taskDetailRoute={
                isAccidentTask
                  ? EBRoutes.ACCIDENT_INSPECTION_TASK_DETAIL.create({
                      taskId: tasksResponse[0].id,
                    })
                  : isInvestigation
                  ? EBRoutes.INVESTIGATION_TASK_DETAIL.create({
                      taskId: tasksResponse[0].id,
                    })
                  : EBRoutes.PROPERTY_INSPECTION_TASK_DETAIL.create({
                      taskId: tasksResponse[0].id,
                    })
              }
              onCreateNew={onCreateNew}
              onEditTask={onEditTask}
              taskType={taskType}
            />
          )}
        </BrowserRouter>
      )
    );
  };

  const openModal = async (
    tasksResponse: BaseTaskListDto[],
    draftStatus: boolean,
    claimNumber?: string,
  ) => {
    const modal = await balModalController.create({
      component: modalWithRouter,
      modalWidth: 750,
      componentProps: {
        tasksResponse: tasksResponse,
        draftStatus: draftStatus,
        isVehicleTask: isVehicleTask,
        claimNumber: claimNumber,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <div>
      {task.status === 'loading' && <BalSpinner />}
      {task.status === 'error' && <></>}
      {task.status !== 'loading' && (
        <form
          onSubmit={handleSubmit((data) => {
            if (data.claimNumber || isInvestigation) {
              onTaskCreation(data.claimNumber);
              setClaimNumber && setClaimNumber(data.claimNumber);
            }
          })}
        >
          <BalCard className="mt-5 p-5">
            {isInvestigation && (
              <p>
                <img
                  className="mb-4"
                  src={investigationImage}
                  width={142}
                ></img>
              </p>
            )}
            <BalHeading space="none" level="h4" className="mt-2 mb-4">
              {title}
            </BalHeading>

            <div className="columns is-gapless is-multiline">
              <BalText className="column is-full">{text}</BalText>
              {isVehicleTask && false && (
                // Guidelines feature not ready - remove false from the condition and this comment ;-)
                <a className="mb-4" href="/vehicle-guidelines">
                  {t('vehicleTask.guidelines.guidelines')}
                </a>
              )}
              <Controller
                name="claimNumber"
                control={control}
                defaultValue=""
                rules={{
                  pattern: {
                    value: pattern,
                    message: patternErrorMessage
                      ? patternErrorMessage
                      : t('validators.invalidClaimNumber'),
                  },
                }}
                render={({ field, fieldState }) => (
                  <BalTextField
                    {...field}
                    className="column is-full py-0 mt-3"
                    title={
                      isInvestigation || isRiskAssessment
                        ? ''
                        : t('general.claim.claimNumber')
                    }
                    placeholder={placeholder}
                    maxLength={isRiskAssessment ? 10 : 14}
                    onInput={(e: CustomEvent<string | undefined>) => {
                      field.onChange(e.detail);
                    }}
                    mask={
                      isInvestigation
                        ? undefined
                        : isRiskAssessment
                        ? undefined
                        : 'claim-number'
                    }
                    message={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </div>
          </BalCard>
          {taskButtons}
        </form>
      )}
    </div>
  );
};
