import { diff, jsonPatchPathConverter } from 'just-diff';
import {
  InsuranceSurveyChangeDto,
  InsuranceSurveyCreateAllDto,
  InsuranceSurveyDto,
  InsuranceSurveyFilterCriteriaDto,
  InsuranceSurveyListDto,
} from '../../../../../../../bat-shared/resource-models';
import { RequestResult, jsonGet, jsonReq } from '../../../../../data';
import { apiUrl } from '../../../../../data/fetch/base';
import {
  makeAuthHeaders,
  typeFromApi,
} from '../../../../../data/fetch/requests';
import { APIRoutes } from '../../../../../router/router';

export const getInsuranceSurveys = async (
  bearerToken: string,
  filter: InsuranceSurveyFilterCriteriaDto,
): Promise<RequestResult<InsuranceSurveyListDto[]>> => {
  const { investigationTaskId, ...rest } = filter;
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_INSURANCE_SURVEYS.create({
      query: {
        ...rest,
        InvestigationTaskId: investigationTaskId,
      },
    })}`,
    bearerToken,
  );
};

export const getInsuranceSurvey = async (
  bearerToken: string,
  insuranceSurveyId: string,
): Promise<RequestResult<InsuranceSurveyDto>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_INSURANCE_SURVEY.create({
      insuranceSurveyId: insuranceSurveyId,
    })}`,
    bearerToken,
  );

export const getInsuranceSurveyHistory = async (
  bearerToken: string,
  insuranceSurveyId: string,
): Promise<RequestResult<InsuranceSurveyChangeDto[]>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_INSURANCE_SURVEY_HISTORY.create({
      insuranceSurveyId,
    })}`,
    bearerToken,
  );

export async function postInsuranceSurvey(
  insuranceSurvey: InsuranceSurveyDto,
  bearerToken: string,
): Promise<RequestResult<InsuranceSurveyDto>> {
  const route = `${apiUrl}${APIRoutes.POST_INSURANCE_SURVEY.create({})}`;
  const request = new Request(route, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(insuranceSurvey),
  });
  return jsonReq<InsuranceSurveyDto>(request, typeFromApi);
}

export async function postAllInsuranceSurveys(
  insuranceSurveyCreateAllDto: InsuranceSurveyCreateAllDto,
  bearerToken: string,
): Promise<RequestResult<InsuranceSurveyDto>> {
  const route = `${apiUrl}${APIRoutes.POST_ALL_INSURANCE_SURVEYS.create({})}`;
  const request = new Request(route, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(insuranceSurveyCreateAllDto),
  });
  return jsonReq<InsuranceSurveyDto>(request, typeFromApi);
}

export const patchInsuranceSurvey = async (
  insuranceSurveyOld: InsuranceSurveyDto,
  insuranceSurveyNew: InsuranceSurveyDto,
  bearerToken: string,
): Promise<RequestResult<InsuranceSurveyDto>> => {
  const patch = JSON.stringify(
    diff(insuranceSurveyOld, insuranceSurveyNew, jsonPatchPathConverter),
  );
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_INSURANCE_SURVEY.create({
      insuranceSurveyId: insuranceSurveyOld.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<InsuranceSurveyDto>(request, typeFromApi);
};

export const deleteInsuranceSurvey = async (
  insuranceSurveyId: string,
  bearerToken: string,
): Promise<void> => {
  const route = `${apiUrl}${APIRoutes.DELETE_INSURANCE_SURVEY.create({
    insuranceSurveyId: insuranceSurveyId,
  })}`;

  const request = new Request(route, {
    method: 'DELETE',
    headers: await makeAuthHeaders(bearerToken),
  });
  await jsonReq(request, typeFromApi);
};

export const sendInsuranceSurveyToAdos = async (
  bearerToken: string,
  insuranceSurveyId: string,
): Promise<RequestResult<void>> => {
  const route = `${apiUrl}${APIRoutes.PUT_INSURANCE_SURVEY_SENDTOADOS.create({
    insuranceSurveyId: insuranceSurveyId,
  })}`;

  const request = new Request(route, {
    method: 'PUT',
    headers: await makeAuthHeaders(bearerToken),
  });
  return await jsonReq(request, typeFromApi);
};
