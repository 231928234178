import { diff, jsonPatchPathConverter } from 'just-diff';
import { JournalChangeDto } from '../../../../../bat-shared/resource-models';
import { jsonGet, RequestResult } from '../../../data';
import { apiUrl } from '../../../data/fetch/base';
import {
  jsonReq,
  makeAuthHeaders,
  typeFromApi,
} from '../../../data/fetch/requests';
import { APIRoutes } from '../../../router/router';
import {
  InvestigationTaskJournalDto,
  InvestigationTaskJournalFilterCriteria,
  ResponseOfInvestigationTaskJournalListDto,
} from '../../../types/resource-models';

export const getInvestigationTaskJournals = async (
  bearerToken: string,
  filter: InvestigationTaskJournalFilterCriteria,
): Promise<RequestResult<ResponseOfInvestigationTaskJournalListDto>> => {
  const { investigationTaskId, ...rest } = filter;
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_INVESTIGATION_TASK_JOURNALS.create({
      query: {
        ...rest,
        InvestigationTaskId: investigationTaskId,
      },
    })}`,
    bearerToken,
  );
};

export const getInvestigationTaskJournal = async (
  bearerToken: string,
  investigationTaskJournalId: string,
): Promise<RequestResult<InvestigationTaskJournalDto>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_INVESTIGATION_TASK_JOURNAL.create({
      journalId: investigationTaskJournalId,
    })}`,
    bearerToken,
  );

export async function postInvestigationTaskJournal(
  journal: InvestigationTaskJournalDto,
  bearerToken: string,
): Promise<RequestResult<InvestigationTaskJournalDto>> {
  const route = `${apiUrl}${APIRoutes.POST_INVESTIGATION_TASK_JOURNAL.create(
    {},
  )}`;
  const request = new Request(route, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(journal),
  });
  return jsonReq<InvestigationTaskJournalDto>(request, typeFromApi);
}

export const patchInvestigationTaskJournal = async (
  journalOld: InvestigationTaskJournalDto,
  journalNew: InvestigationTaskJournalDto,
  bearerToken: string,
): Promise<RequestResult<InvestigationTaskJournalDto>> => {
  const patch = JSON.stringify(
    diff(journalOld, journalNew, jsonPatchPathConverter),
  );
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_INVESTIGATION_TASK_JOURNAL.create({
      journalId: journalOld.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<InvestigationTaskJournalDto>(request, typeFromApi);
};

export const deleteInvestigationTaskJournal = async (
  journalId: string,
  bearerToken: string,
): Promise<RequestResult<InvestigationTaskJournalDto>> => {
  const patchOperations = [
    { op: 'replace', path: 'deletedAt', value: new Date().toISOString() },
  ];
  const patch = JSON.stringify(patchOperations);

  const route = `${apiUrl}${APIRoutes.PATCH_INVESTIGATION_TASK_JOURNAL.create({
    journalId: journalId,
  })}`;

  const request = new Request(route, {
    method: 'PATCH',
    headers: await makeAuthHeaders(bearerToken),
    body: patch,
  });
  return await jsonReq<InvestigationTaskJournalDto>(request, typeFromApi);
};

export const getInvestigationTaskJournalHistory = async (
  bearerToken: string,
  investigationTaskJournalId: string,
): Promise<RequestResult<JournalChangeDto[]>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_INVESTIGATION_TASK_JOURNAL_HISTORY.create({
      journalId: investigationTaskJournalId,
    })}`,
    bearerToken,
  );

export const sendJournalToAdos = async (
  bearerToken: string,
  investigationTaskJournalId: string,
): Promise<RequestResult<void>> => {
  const route = `${apiUrl}${APIRoutes.PUT_INVESTIGATION_TASK_JOURNAL_SENDTOADOS.create(
    {
      journalId: investigationTaskJournalId,
    },
  )}`;

  const request = new Request(route, {
    method: 'PUT',
    headers: await makeAuthHeaders(bearerToken),
  });
  return await jsonReq(request, typeFromApi);
};
