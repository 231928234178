import {
  BalIcon,
  BalText,
  BalButton,
  BalSpinner,
} from '@baloise/design-system-components-react';
import { balIconDownload } from '@baloise/design-system-icons';
import { concat } from 'lodash';
import { useEffect, useState } from 'react';
import { FileViewModal } from '.';
import { getAttachment } from '../../features/files';
import { useToken } from '../../hooks';
import { AttachmentDto } from '../../types/resource-models';
import { convertBytesToMB } from '../../utils';
import { formatDateTime } from '../../utils/date';

export const FileView = ({ file }: { file: AttachmentDto }) => {
  const token = useToken();
  const [attachment, setAttachment] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (token && !attachment) {
      getAttachment(token, file.id).then((response) => {
        if (response.status === 'success') {
          setAttachment(response.value);
        }
      });
    }
  }, [token, file, attachment]);

  const downloadAttachment = () => {
    if (file) {
      const a = document.createElement('a');
      a.href = attachment ?? '';
      a.download = file.fileName;
      a.target = '_blank';
      a.click();
    }
  };

  return (
    <>
      {file?.contentType.includes('image') &&
        (attachment ? <img src={attachment} /> : <BalSpinner></BalSpinner>)}
      <div
        key={file.fileName}
        className="mt-1 is-flex is-align-items-center p-3 "
      >
        <BalIcon className="mt-1 mr-4" name="document" size="small" />
        <div className="bottom-blue-line is-flex is-justify-content-space-between is-full-width">
          <div className="mb-2">
            <BalText bold size="small" color="info">
              {file.fileName}
            </BalText>
            <BalText size="small" color="primary-light">
              {concat([
                file.contentType
                  .substring(file.contentType.lastIndexOf('/') + 1)
                  .toUpperCase(),
                `(${convertBytesToMB(file.size)} MB)`,
                concat(['(', formatDateTime(file.savedOn), ')'], 'none'),
              ])}
            </BalText>
          </div>
          <div>
            <>
              <FileViewModal
                contentType={file.contentType}
                fileId={file.id}
                label={file.fileName}
                fileUrlParam={attachment}
              />
              <BalButton
                className="is-align-self-flex-end mb-3 ml-3"
                color="primary"
                size="small"
                square
                onClick={() => {
                  downloadAttachment();
                }}
              >
                <BalIcon svg={balIconDownload} size="small" />
              </BalButton>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
