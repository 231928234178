import { APIRoutes } from '../../../router/router';
import { apiUrl } from '../../../data/fetch/base';
import { jsonReq, RequestResult } from '../../../data';
import { makeAuthHeaders, typeFromApi } from '../../../data/fetch/requests';
import {
  AffectedPartner,
  Partner,
  TaskType,
  TaskTypes,
} from '../../../types/types';
import { diff, jsonPatchPathConverter } from 'just-diff';

export const deletePartner = async (
  bearerToken: string,
  partnerId: string,
): Promise<RequestResult<AffectedPartner>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.DELETE_PARTNER.create({ partnerId: partnerId })}`,
    {
      method: 'DELETE',
      headers: await makeAuthHeaders(bearerToken),
    },
  );
  return jsonReq(request, typeFromApi);
};

export const postPartner = async (
  bearerToken: string,
  taskId: string,
  affectedPartner: AffectedPartner,
  taskType: TaskType,
): Promise<RequestResult<AffectedPartner>> => {
  const apiRoute =
    taskType === TaskTypes.PropertyInspectionTask
      ? APIRoutes.POST_PROPERTY_INSPECTION_PARTNER.create({
          taskId: taskId,
        })
      : taskType === TaskTypes.AccidentInspectionTask
      ? APIRoutes.POST_ACCIDENT_INSPECTION_TASK_PARTNER.create({
          taskId: taskId,
        })
      : taskType === TaskTypes.VehicleTask
      ? APIRoutes.POST_VEHICLE_TASK_PARTNER.create({
          taskId: taskId,
        })
      : APIRoutes.POST_INVESTIGATION_TASK_PARTNER.create({
          taskId: taskId,
        });
  const request = new Request(`${apiUrl}${apiRoute}`, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(affectedPartner),
  });
  return jsonReq(request, typeFromApi);
};

export const patchPartner = async (
  bearerToken: string,
  oldPartner: AffectedPartner,
  newPartner: AffectedPartner,
): Promise<RequestResult<Partner>> => {
  const patchOperations = diff(
    oldPartner.partner,
    newPartner.partner,
    jsonPatchPathConverter,
  );
  const patch = JSON.stringify(patchOperations);
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_PARTNER.create({
      partnerId:
        oldPartner.partner.type === 'person'
          ? oldPartner.partner.person.id
          : oldPartner.partner.company.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return jsonReq(request, typeFromApi);
};
