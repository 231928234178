import {
  BalButton,
  BalCard,
  BalHeading,
  BalText,
} from '@baloise/design-system-components-react';
import { RefObject } from 'react';
import { RequestResult } from '../../../data';
import { ImportStateDto } from '../../../types/resource-models';
import { ExportProperties } from '../../../types/types';
import { AdminImportStateTable } from './admin-import-state-table';

type ManagementCardProps = {
  title: string;
  description: string;
  importButtonName: string;
  exportProps?: ExportProperties;
  importButtonLoadingState?: boolean;
  inputFile: RefObject<HTMLInputElement>;
  onChangedFiles: () => Promise<void>;
  importStateFromApi: RequestResult<ImportStateDto[]>;
};

export const ManagementCard = ({
  title,
  description,
  importButtonName,
  exportProps,
  importButtonLoadingState,
  inputFile,
  onChangedFiles,
  importStateFromApi,
}: ManagementCardProps): JSX.Element => {
  return (
    <div className="column">
      <BalCard className="p-5 columns is-gapless is-multiline">
        <BalHeading className="column is-12" space="none" level="h4">
          {title}
        </BalHeading>
        <BalText bold color="info" className="mt-5 column is-12">
          {description}
        </BalText>
        <div className="mt-4 is-flex is-full-width is-justify-content-space-between">
          {exportProps ? (
            <div className="mt-1">
              <BalButton
                loading={exportProps.exportButtonLoadingState}
                onClick={(event) => {
                  event.detail == 1 && exportProps.onExport();
                }}
              >
                {exportProps.exportTranslation}
              </BalButton>
            </div>
          ) : (
            <></>
          )}
          <div className="mt-1">
            <div>
              <input
                type="file"
                id="file"
                ref={inputFile}
                hidden
                onChange={() => onChangedFiles()}
              />
            </div>
            <BalButton
              className={exportProps ? 'is-pulled-right' : ''}
              loading={importButtonLoadingState}
              onClick={async (event) => {
                if (inputFile.current != null && event.detail == 1) {
                  inputFile.current.click();
                }
              }}
            >
              {importButtonName}
            </BalButton>
          </div>
        </div>
        <div className="column is-full">
          <AdminImportStateTable importStateFromApi={importStateFromApi} />
        </div>
      </BalCard>
    </div>
  );
};
