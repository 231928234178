import {
  BalButton,
  BalCard,
  BalHeading,
  BalSelect,
  BalSelectOption,
  BalText,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { RequestResult } from '../../../data';
import { Language, UserDto } from '../../../types/resource-models';
import { useToken } from '../../../hooks';
import i18n from '../../../i18n/config';
import { Globals } from '../../../utils';
import { ToastNotification } from '../../../components/toast-notification';
import { patchUser, postDefaultColumnsProperty } from '../data/requests';
import '../../../styles/general.scss';
import { useState } from 'react';
import { getEnabledModules } from '../../../utils/utilities';
import { BalTextField } from '../../../components/input';

type userLanguageChangeProps = {
  user: RequestResult<UserDto>;
  setUser: React.Dispatch<RequestResult<UserDto>>;
};

export const UserLanguageChangeCard = ({
  user,
  setUser,
}: userLanguageChangeProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();

  const [disabledProperty, setDisabledProperty] = useState(false);
  const [disabledVehicle, setDisabledVehicle] = useState(false);
  const [disabledCraftsman, setDisabledCraftsman] = useState(false);
  const [disabledInvestigation, setDisabledInvestigation] = useState(false);
  const [disabledAccident, setDisabledAccident] = useState(false);

  if (user.status === 'success' || user.status === 'loading-with-value') {
    return (
      <div className="column">
        <BalCard className="p-5 columns is-gapless is-multiline is-full-width">
          <BalHeading className="p-0 column is-full" space="none" level="h4">
            {t('settings')}
          </BalHeading>
          <div className="column is-full mt-5 columns is-gapless is-vcentered">
            <BalText bold color="info" className="column is-4">
              {t('language.label')}
            </BalText>
            <BalSelect
              noBorder
              className="column"
              value={user.localValue.language}
              onBalChange={(e) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setUser(
                    produce(user, (draftState) => {
                      draftState.localValue.language = value as Language;
                    }),
                  );
                }
              }}
            >
              {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
              <div className="bal-select__inner">
                {Globals.languages.map((language) => (
                  <BalSelectOption
                    key={language}
                    value={language}
                    label={t(`language.languages.${language}`)}
                  >
                    {t(`language.languages.${language}`)}
                  </BalSelectOption>
                ))}
              </div>
            </BalSelect>
          </div>
          <div className="column is-full columns is-gapless is-vcentered">
            <BalText bold color="info" className="column is-4">
              {t('user.jobTitle')}
            </BalText>
            <BalTextField
              className="column is-8"
              maxLength={128}
              placeholder={t('user.jobTitle')}
              value={user.localValue.jobTitle}
              onChange={(event: CustomEvent<string | undefined>) => {
                setUser(
                  produce(user, (draftState) => {
                    draftState.localValue.jobTitle = event.detail;
                  }),
                );
              }}
            />
          </div>
          <div className="column is-full pulled-right-fixed-height">
            <div className="row">
              {getEnabledModules().resetFilter && (
                <BalText bold color="info">
                  {t('general.resetFilter')}
                </BalText>
              )}
              <div className="p-0">
                {getEnabledModules().resetFilter && (
                  <>
                    <BalButton
                      class="ml-2 user-language-card-reset-button"
                      size="small"
                      color="info"
                      outlined={true}
                      disabled={disabledProperty}
                      onClick={async () => {
                        setDisabledProperty(true);
                        if (user.status === 'success') {
                          const userTableSetting =
                            user.localValue.userTableSettings.find(
                              (x) => x.type === 'PropertyInspectionTask',
                            );
                          const propertyTaskUserTableSettingsDto =
                            await postDefaultColumnsProperty(
                              token,
                              user.value.id,
                              'PropertyInspectionTask',
                              userTableSetting,
                            );

                          const newUser = produce(user, (draftUser) => {
                            draftUser.localValue.userTableSettings =
                              draftUser.localValue.userTableSettings.filter(
                                (ts) => ts.type !== 'PropertyInspectionTask',
                              );
                            draftUser.localValue.userTableSettings.push(
                              propertyTaskUserTableSettingsDto,
                            );
                            draftUser.value.userTableSettings =
                              draftUser.localValue.userTableSettings;
                          });
                          setUser(newUser);
                        }
                      }}
                    >
                      {t('branch.Property')}
                    </BalButton>

                    <BalButton
                      class="ml-2 user-language-card-reset-button"
                      size="small"
                      color="info"
                      outlined={true}
                      disabled={disabledAccident}
                      onClick={async () => {
                        setDisabledAccident(true);
                        if (user.status === 'success') {
                          const userTableSetting =
                            user.localValue.userTableSettings.find(
                              (x) => x.type === 'AccidentInspectionTask',
                            );
                          const accidentInspectionTaskUserTableSettingsDto =
                            await postDefaultColumnsProperty(
                              token,
                              user.value.id,
                              'AccidentInspectionTask',
                              userTableSetting,
                            );

                          const newUser = produce(user, (draftUser) => {
                            draftUser.localValue.userTableSettings =
                              draftUser.localValue.userTableSettings.filter(
                                (ts) => ts.type !== 'AccidentInspectionTask',
                              );
                            draftUser.localValue.userTableSettings.push(
                              accidentInspectionTaskUserTableSettingsDto,
                            );
                            draftUser.value.userTableSettings =
                              draftUser.localValue.userTableSettings;
                          });
                          setUser(newUser);
                        }
                      }}
                    >
                      {t('home.accident')}
                    </BalButton>
                    <BalButton
                      class="ml-2 user-language-card-reset-button"
                      size="small"
                      color="info"
                      outlined={true}
                      disabled={disabledInvestigation}
                      onClick={async () => {
                        setDisabledInvestigation(true);
                        if (user.status === 'success') {
                          const userTableSetting =
                            user.localValue.userTableSettings.find(
                              (x) => x.type === 'InvestigationTask',
                            );
                          const investigationTaskUserTableSettingsDto =
                            await postDefaultColumnsProperty(
                              token,
                              user.value.id,
                              'InvestigationTask',
                              userTableSetting,
                            );

                          const newUser = produce(user, (draftUser) => {
                            draftUser.localValue.userTableSettings =
                              draftUser.localValue.userTableSettings.filter(
                                (ts) => ts.type !== 'InvestigationTask',
                              );
                            draftUser.localValue.userTableSettings.push(
                              investigationTaskUserTableSettingsDto,
                            );
                            draftUser.value.userTableSettings =
                              draftUser.localValue.userTableSettings;
                          });
                          setUser(newUser);
                        }
                      }}
                    >
                      {t('home.investigation')}
                    </BalButton>
                  </>
                )}
                <BalButton
                  className="is-pulled-right user-language-card-reset-button"
                  onClick={(event) => {
                    if (event.detail == 1) {
                      setUser(
                        produce(user, (draftState) => {
                          draftState.status = 'loading-with-value';
                        }),
                      );
                      patchUser(user.value, user.localValue, token).then(
                        (userResponse) => {
                          setUser(userResponse);
                          if (userResponse.status === 'success') {
                            if (
                              i18n.language !==
                              userResponse.value.language.toLowerCase()
                            ) {
                              i18n.changeLanguage(
                                userResponse.value.language.toLowerCase(),
                              );
                            }
                            toast(
                              ToastNotification({
                                message: t(
                                  'language.languageChange.successMessage',
                                ),
                                color: 'success',
                              }),
                            );
                          }
                          if (userResponse.status === 'error') {
                            toast(
                              ToastNotification({
                                message: t(
                                  'language.languageChange.errorMessage',
                                ),
                                color: 'danger',
                              }),
                            );
                          }
                        },
                      );
                    }
                  }}
                >
                  {t('general.buttons.save')}
                </BalButton>
              </div>
              {getEnabledModules().resetFilter && (
                <div className="column p-0 mt-1">
                  <BalButton
                    class="ml-2 user-language-card-reset-button"
                    size="small"
                    color="info"
                    outlined={true}
                    disabled={disabledVehicle}
                    onClick={async () => {
                      setDisabledVehicle(true);
                      if (user.status === 'success') {
                        const userTableSetting =
                          user.localValue.userTableSettings.find(
                            (x) => x.type === 'VehicleTask',
                          );

                        const vehicleTaskUserTableSettingsDto =
                          await postDefaultColumnsProperty(
                            token,
                            user.value.id,
                            'VehicleTask',
                            userTableSetting,
                          );

                        const newUser = produce(user, (draftUser) => {
                          draftUser.localValue.userTableSettings =
                            draftUser.localValue.userTableSettings.filter(
                              (ts) => ts.type !== 'VehicleTask',
                            );
                          draftUser.localValue.userTableSettings.push(
                            vehicleTaskUserTableSettingsDto,
                          );
                          draftUser.value.userTableSettings =
                            draftUser.localValue.userTableSettings;
                        });
                        setUser(newUser);
                      }
                    }}
                  >
                    {t('home.motorVehicle')}
                  </BalButton>
                  <BalButton
                    class="ml-2 user-language-card-reset-button"
                    size="small"
                    color="info"
                    outlined={true}
                    disabled={disabledCraftsman}
                    onClick={async () => {
                      setDisabledCraftsman(true);
                      if (user.status === 'success') {
                        const userTableSetting =
                          user.localValue.userTableSettings.find(
                            (x) => x.type === 'Craftsman',
                          );
                        const craftsmanUserTableSettingsDto =
                          await postDefaultColumnsProperty(
                            token,
                            user.value.id,
                            'Craftsman',
                            userTableSetting,
                          );

                        const newUser = produce(user, (draftUser) => {
                          draftUser.localValue.userTableSettings =
                            draftUser.localValue.userTableSettings.filter(
                              (ts) => ts.type !== 'Craftsman',
                            );
                          draftUser.localValue.userTableSettings.push(
                            craftsmanUserTableSettingsDto,
                          );
                          draftUser.value.userTableSettings =
                            draftUser.localValue.userTableSettings;
                        });
                        setUser(newUser);
                      }
                    }}
                  >
                    {t('home.networkPartner')}
                  </BalButton>
                </div>
              )}
            </div>
          </div>
        </BalCard>
      </div>
    );
  } else {
    return <></>;
  }
};
