import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { Icon } from './icon';
import { ToastNotification } from '../toast-notification';
import copyIcon from '../../assets/icons/icon-clipboard.svg';

export const CopyButton = ({
  copyValue,
  className,
}: {
  copyValue: string;
  className?: string;
}): JSX.Element => {
  return (
    <BalButton
      className={className}
      size="small"
      color="primary-light"
      disabled={copyValue === ''}
      square
      onClick={(event) => {
        if (event.detail == 1) {
          navigator.clipboard.writeText(copyValue);
          toast(
            ToastNotification({
              message: t('general.copySuccessMessage', {
                copyValue: copyValue,
              }),
              color: 'success',
            }),
          );
        }
      }}
    >
      <Icon iconPath={copyIcon} />
    </BalButton>
  );
};
