import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalInput,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import { balModalController } from '../../../controller/controllers';
import { useToken } from '../../../hooks';
import {
  UserMenuItemDto,
  UserMenuItemTypes,
} from '../../../types/resource-models';
import { guid } from '../../../utils';
import { Filter } from '../../view-menu/view-menu';
import { postUserMenuItem } from '../data/requests';

type CreateCustomUserMenuItemProps = {
  filter: Filter;
  type: UserMenuItemTypes;
  className?: string;
  onCreated?: (userMenuItemDto: UserMenuItemDto) => void;
};

const CreateCustomUserMenuItem = ({
  filter,
  type,
  className,
  onCreated,
}: CreateCustomUserMenuItemProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const [textValue, setTextValue] = useState<string | undefined>('');
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const getMenuItem = (label: string): UserMenuItemDto => {
    return {
      id: guid(),
      isCustomFilter: true,
      type: type,
      label: label,
      filter: JSON.stringify(filter),
      iconName: '',
    };
  };

  return (
    <div className={className}>
      <BalModalHeader>
        <BalHeading space="none" level="h3">
          {t('createCustomFilter.title')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>{t('createCustomFilter.body')}</BalText>
        <BalInput
          className="pt-4"
          placeholder={t('createCustomFilter.filterNamePlaceholder')}
          value={textValue}
          onBalChange={(event: CustomEvent<string | undefined>) => {
            const value = event.detail;
            if (value && value.length > 0) {
              setRequiredError(false);
            } else {
              setRequiredError(true);
            }

            setTextValue(value);
          }}
        />
        {requiredError && (
          <BalText className="mt-2" color="danger" size="small" bold>
            {t('error.mandatoryField')}
          </BalText>
        )}
        <BalButtonGroup className="pt-4" position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={() => balModalController.dismiss()}
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            color="info"
            disabled={saveDisabled}
            onClick={async () => {
              setSaveDisabled(true);
              if (!textValue || textValue === '') {
                setRequiredError(true);
                return;
              }
              const userMenuItemDto = getMenuItem(textValue);
              const menuItemResult = await postUserMenuItem(
                userMenuItemDto,
                token,
              );
              if (menuItemResult.status === 'error') {
                toast(ErrorToast(menuItemResult.errorValue));
                setSaveDisabled(false);
                return;
              }
              if (menuItemResult.status === 'success') {
                if (onCreated) {
                  onCreated(menuItemResult.value);
                }
                toast(
                  ToastNotification({
                    message: t('createCustomFilter.creationSuccess'),
                    color: 'success',
                  }),
                );
                balModalController.dismiss();
              } else {
                console.error(`Error saving menuitem: ${menuItemResult}`);
              }
              setSaveDisabled(false);
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export default CreateCustomUserMenuItem;
