import { BalHeading, BalText } from '@baloise/design-system-components-react';
import { AffectedPartner, VehicleTask } from '../../../types/types';
import { Translation } from 'react-i18next';
import { concat } from '../../../utils';
import ImageMarker from 'react-image-marker';
import vehicleDamage from '../../../assets/img/bvm-damage-zone.png';
import { InfoDisplay } from '../../../components/ui';
import { Dispatch } from 'react';
import { RequestResult } from '../../../data';
import {
  EditDateModal,
  EditNumberModal,
  EditTextModal,
} from '../../../components/input';
import { useToken } from '../../../hooks';
import produce from 'immer';
import { patchVehicleTask } from '../data/requests';
import { balModalController } from '../../../controller/controllers';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../components/toast-notification';
import { formatDateTime } from '../../../utils/date';

export const VehicleInfo = ({
  task,
  setTask,
}: {
  task: VehicleTask;
  setTask?: Dispatch<RequestResult<VehicleTask>>;
}): JSX.Element => {
  const token = useToken();
  return (
    <Translation>
      {(t) => {
        return (
          <div>
            <InfoDisplay
              title={t('vehicleTask.overlay.brand')}
              text={task.affectedVehicle.brand}
              editComponent={
                setTask && (
                  <EditTextModal
                    isRequired={true}
                    label={t('vehicleTask.overlay.brand')}
                    placeholder={t('vehicleTask.overlay.brand')}
                    value={task.affectedVehicle.brand}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.affectedVehicle.brand = value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            <InfoDisplay
              title={t('vehicleTask.overlay.model')}
              text={task.affectedVehicle.model}
              editComponent={
                setTask && (
                  <EditTextModal
                    isRequired={true}
                    label={t('vehicleTask.overlay.model')}
                    placeholder={t('vehicleTask.overlay.model')}
                    value={task.affectedVehicle.model}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.affectedVehicle.model = value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            <InfoDisplay
              title={t('vehicleTask.overlay.licensePlate')}
              text={task.affectedVehicle.plate}
              editComponent={
                setTask && (
                  <EditTextModal
                    isRequired={true}
                    label={t('vehicleTask.overlay.licensePlate')}
                    placeholder={t('vehicleTask.overlay.licensePlate')}
                    value={task.affectedVehicle.plate}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.affectedVehicle.plate = value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            {/* //todo change to use code dto here */}
            <InfoDisplay
              title={t('vehicleTask.overlay.bodyType')}
              text={task.affectedVehicle.bodyType}
            />
            <InfoDisplay
              title={t('vehicleTask.vehicle.vehicleIdentificationNumber')}
              text={task.affectedVehicle.vehicleIdentificationNumber}
              editComponent={
                setTask && (
                  <EditTextModal
                    isRequired={true}
                    label={t('vehicleTask.vehicle.vehicleIdentificationNumber')}
                    placeholder={t(
                      'vehicleTask.vehicle.vehicleIdentificationNumber',
                    )}
                    value={task.affectedVehicle.vehicleIdentificationNumber}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.affectedVehicle.vehicleIdentificationNumber =
                              value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            <InfoDisplay
              title={t('vehicleTask.vehicle.vehicleMasterNumber')}
              text={task.affectedVehicle.vehicleMasterNumber}
              editComponent={
                setTask && (
                  <EditTextModal
                    isRequired={true}
                    label={t('vehicleTask.vehicle.vehicleMasterNumber')}
                    placeholder={t('vehicleTask.vehicle.vehicleMasterNumber')}
                    value={task.affectedVehicle.vehicleMasterNumber}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.affectedVehicle.vehicleMasterNumber =
                              value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            <InfoDisplay
              title={t('vehicleTask.vehicle.registration')}
              text={
                task.affectedVehicle.registration &&
                formatDateTime(task.affectedVehicle.registration)
              }
              editComponent={
                setTask && (
                  <EditDateModal
                    isRequired={true}
                    label={t('vehicleTask.vehicle.registration')}
                    placeholder={t('vehicleTask.vehicle.registration')}
                    value={task.affectedVehicle.registration?.substring(0, 10)}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string, updateToAdos: boolean) => {
                      patchVehicleTask(
                        task,
                        produce(task, (draftState) => {
                          draftState.affectedVehicle.registration = value ?? ''; // internally we always use empty strings instead of null ones.
                        }),
                        token,
                        updateToAdos,
                      ).then((result) => {
                        if (result.status === 'success') {
                          setTask(result);
                          balModalController.dismiss();
                        } else if (result.status === 'error') {
                          toast(ErrorToast(result.errorValue));
                        }
                      });
                    }}
                  />
                )
              }
            />
            <InfoDisplay
              title={t('vehicleTask.vehicle.mileage')}
              text={task.affectedVehicle.mileage?.toString()}
              editComponent={
                setTask && (
                  <EditNumberModal
                    isRequired={true}
                    label={t('vehicleTask.vehicle.mileage')}
                    placeholder={t('vehicleTask.vehicle.mileage')}
                    value={task.affectedVehicle.mileage?.toString()}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.affectedVehicle.mileage =
                              value as unknown as number;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            <InfoDisplay
              title={t('vehicleTask.vehicle.typeCertificate')}
              text={task.affectedVehicle.typeCertificate}
              editComponent={
                setTask && (
                  <EditTextModal
                    isRequired={true}
                    label={t('vehicleTask.vehicle.typeCertificate')}
                    placeholder={t('vehicleTask.vehicle.typeCertificate')}
                    value={task.affectedVehicle.typeCertificate}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.affectedVehicle.typeCertificate = value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
            {task.kind !== 'VehicleRiskAssessment' && (
              <>
                <InfoDisplay
                  title={t(
                    'vehicleTask.expertiseOrderReport.insuranceSumInCHF',
                  )}
                  text={task.affectedVehicle.newValue?.toString()}
                  editComponent={
                    setTask && (
                      <EditNumberModal
                        isRequired={true}
                        label={t(
                          'vehicleTask.expertiseOrderReport.insuranceSumInCHF',
                        )}
                        placeholder={t(
                          'vehicleTask.expertiseOrderReport.insuranceSumInCHF',
                        )}
                        value={task.affectedVehicle.newValue?.toString()}
                        requiredCapabilities="EditVehicleTask"
                        onSave={(value: string | undefined) => {
                          if (value) {
                            patchVehicleTask(
                              task,
                              produce(task, (draftState) => {
                                draftState.affectedVehicle.newValue =
                                  value as unknown as number;
                              }),
                              token,
                            ).then((result) => {
                              if (result.status === 'success') {
                                setTask(result);
                                balModalController.dismiss();
                              } else if (result.status === 'error') {
                                toast(ErrorToast(result.errorValue));
                              }
                            });
                          }
                        }}
                      />
                    )
                  }
                />
                <InfoDisplay
                  title={t('vehicleTask.expertiseOrderReport.accesoriesCHF')}
                  text={task.affectedVehicle.equipmentValue?.toString()}
                  editComponent={
                    setTask && (
                      <EditNumberModal
                        isRequired={true}
                        label={t(
                          'vehicleTask.expertiseOrderReport.accesoriesCHF',
                        )}
                        placeholder={t(
                          'vehicleTask.expertiseOrderReport.accesoriesCHF',
                        )}
                        value={task.affectedVehicle.equipmentValue?.toString()}
                        requiredCapabilities="EditVehicleTask"
                        onSave={(value: string | undefined) => {
                          if (value) {
                            patchVehicleTask(
                              task,
                              produce(task, (draftState) => {
                                draftState.affectedVehicle.equipmentValue =
                                  value as unknown as number;
                              }),
                              token,
                            ).then((result) => {
                              if (result.status === 'success') {
                                setTask(result);
                                balModalController.dismiss();
                              } else if (result.status === 'error') {
                                toast(ErrorToast(result.errorValue));
                              }
                            });
                          }
                        }}
                      />
                    )
                  }
                />
              </>
            )}
            {task.kind === 'VehicleRiskAssessment' && (
              <>
                <InfoDisplay
                  title={t('vehicleTask.vehicle.desiredMaximumInsuredSum')}
                  text={task.affectedVehicle.desiredMaximumInsuredSum?.toLocaleString(
                    'de-CH',
                  )}
                  editComponent={
                    setTask && (
                      <EditNumberModal
                        isRequired={true}
                        label={t(
                          'vehicleTask.vehicle.desiredMaximumInsuredSum',
                        )}
                        placeholder={t(
                          'vehicleTask.vehicle.desiredMaximumInsuredSum',
                        )}
                        value={task.affectedVehicle.desiredMaximumInsuredSum?.toString()}
                        requiredCapabilities="EditVehicleTask"
                        onSave={(value: string | undefined) => {
                          if (value) {
                            patchVehicleTask(
                              task,
                              produce(task, (draftState) => {
                                draftState.affectedVehicle.desiredMaximumInsuredSum =
                                  value as unknown as number;
                              }),
                              token,
                            ).then((result) => {
                              if (result.status === 'success') {
                                setTask(result);
                                balModalController.dismiss();
                              } else if (result.status === 'error') {
                                toast(ErrorToast(result.errorValue));
                              }
                            });
                          }
                        }}
                      />
                    )
                  }
                />
              </>
            )}
          </div>
        );
      }}
    </Translation>
  );
};

export const AffectedPersonInfo = ({
  affected,
}: {
  affected: AffectedPartner;
}): JSX.Element => {
  return (
    <Translation>
      {(t) => {
        return (
          <>
            <BalHeading level="h5" space="none" className="mt-5">
              {t('vehicleTask.overlay.harmedPartyInformation')}
            </BalHeading>
            {affected.partner.type === 'company' ? (
              <>
                <InfoDisplay
                  title={t('general.name')}
                  text={concat([
                    affected.partner.company.name,
                    affected.partner.company.additionalName,
                  ])}
                />
                <InfoDisplay
                  title={t('general.address')}
                  text={
                    affected.partner.company.address
                      ? concat(
                          [
                            affected.partner.company.address.street,
                            concat([
                              affected.partner.company.address.zipCode?.toString(),
                              affected.partner.company.address.city,
                            ]),
                          ],
                          'comma-space',
                        )
                      : ''
                  }
                />
                <InfoDisplay
                  title={t('general.email')}
                  text={affected.partner.company.email ?? ''}
                />

                <InfoDisplay
                  title={t('general.phone')}
                  text={affected.partner.company.phoneNumber}
                />
              </>
            ) : (
              <>
                <InfoDisplay
                  title={t('general.name')}
                  text={affected.partner.person.displayName}
                />
                <InfoDisplay
                  title={t('general.address')}
                  text={
                    affected.partner.person.address
                      ? concat(
                          [
                            affected.partner.person.address.street,
                            concat([
                              affected.partner.person.address.zipCode?.toString(),
                              affected.partner.person.address.city,
                            ]),
                          ],
                          'comma-space',
                        )
                      : ''
                  }
                />
                <InfoDisplay
                  title={t('general.email')}
                  text={affected.partner.person.email ?? ''}
                />

                <InfoDisplay
                  title={t('general.phone')}
                  text={affected.partner.person.phoneNumber}
                />
              </>
            )}
          </>
        );
      }}
    </Translation>
  );
};

export const AccidentInfo = ({ task }: { task: VehicleTask }): JSX.Element => {
  return (
    <Translation>
      {(t) => {
        return (
          <>
            <BalHeading level="h5" space="none" className="mt-5">
              {t('vehicleTask.overlay.accidentInformation')}
            </BalHeading>
            <div className="columns">
              <BalText bold color="info" className="column is-4">
                {t('vehicleTask.vehicleClaimDamage.damageZone')}
              </BalText>
              <ImageMarker
                src={vehicleDamage}
                markers={task.damageCoordinates}
                markerComponent={() => <span>✕</span>}
              />
            </div>
            {task.what && (
              <InfoDisplay
                title={t('vehicleTask.overlay.accidentCourse')}
                text={task.what}
              />
            )}
          </>
        );
      }}
    </Translation>
  );
};

export const VehicleAdditionalInfo = ({
  task,
}: {
  task: VehicleTask;
}): JSX.Element => {
  return (
    <Translation>
      {(t) => {
        return (
          <>
            <BalHeading level="h5" space="none" className="mt-5">
              {t('overlay.additionalInformation')}
            </BalHeading>
            <InfoDisplay
              title={t('vehicleTask.overlay.cover')}
              text={task.coverage}
            />
            <InfoDisplay
              title={t('vehicleTask.overlay.regress')}
              text={t(`vehicleRegress.${task.regress}`)}
            />
            <InfoDisplay
              title={t('vehicleTask.vehicleClaimInformation.liability')}
              text={task.liability}
            />
          </>
        );
      }}
    </Translation>
  );
};
