import { BalButton, BalIcon } from '@baloise/design-system-components-react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  ErrorToast,
  ToastNotification,
} from '../../../../components/toast-notification';
import { RequestResult } from '../../../../data';
import { useOnClickOutside, useToken } from '../../../../hooks';
import {
  BaseTask,
  InvestigationTask,
  TaskTypes,
  VehicleTask,
} from '../../../../types/types';
import { getEnabledModules } from '../../../../utils/utilities';
import { VehicleTaskKind } from '../../../../../../bat-shared/resource-models';
import { useNavigate } from 'react-router-dom';
import { EBRoutes } from '../../../../router/router';
import { balIconMenuDots } from '@baloise/design-system-icons';
import { updateBaseTaskToAdos } from '../../data/requests';
import BaseTaskPrioritySelectorButton from './base-task-priority-selector-button';
import { getEditCapabilityFromType } from '../../../../utils';
import { BaseTaskDeleteButton } from '..';
import { FileViewModal } from '../../../../components/ui';
import { VehicleTaskSendCometButton } from '../../../vehicle-task/components/vehicle-task-send-comet/vehicle-task-send-comet-button';
import { openQRCodeModal } from '../../../../components/ui/qrcode-modal';

export const BaseTaskMenu = ({
  task,
  reportName,
  fullReportName,
  getReport,
  xmlName,
  getXml,
  duplicateVehicleRiskAsssessmentTask,
  taskType,
  vehicleTaskKind,
  onChangeSuccess,
  showQRCode,
}: {
  task: BaseTask;
  reportName: string;
  fullReportName?: string;
  getReport: (
    bearerToken: string,
    taskId: string,
    fullReport?: boolean,
  ) => Promise<RequestResult<string>>;
  xmlName?: string;
  getXml?: (
    bearerToken: string,
    taskId: string,
  ) => Promise<RequestResult<string>>;
  duplicateVehicleRiskAsssessmentTask?: (
    sourceId: string,
    bearerToken: string,
  ) => Promise<RequestResult<VehicleTask>>;
  taskType: TaskTypes;
  vehicleTaskKind?: VehicleTaskKind;
  onChangeSuccess?: (task: RequestResult<BaseTask>) => void;
  showQRCode?: boolean;
}): JSX.Element => {
  const token = useToken();
  const { t } = useTranslation();
  const groupButtonRef = useRef(null);
  const groupDivRef = useRef(null);
  useOnClickOutside([groupButtonRef, groupDivRef], () => {
    const element = document.getElementById('display-buttons');
    !element?.classList.contains('display-buttons-hidden') &&
      element?.classList.add('display-buttons-hidden');
  });
  const navigate = useNavigate();
  const [duplicating, setDuplicating] = useState(false);
  const downloadReport = (fullReport?: boolean) => {
    getReport(token, task.id, fullReport).then((fileResponse) => {
      if (fileResponse.status === 'success') {
        const a = document.createElement('a');
        a.href = fileResponse.value;
        a.download = fullReport && fullReportName ? fullReportName : reportName;
        a.target = '_blank';
        a.click();
      } else if (fileResponse.status === 'error') {
        toast(ErrorToast(fileResponse.errorValue));
      }
    });
  };

  return (
    <div className="is-flex is-flex-direction-column is-align-items-flex-end">
      <BalButton
        color="primary-light"
        flat
        outlined
        ref={groupButtonRef}
        onClick={(event) => {
          if (event.detail == 1) {
            const element = document.getElementById('display-buttons');
            element?.classList.contains('display-buttons-hidden')
              ? element.classList.remove('display-buttons-hidden')
              : element?.classList.add('display-buttons-hidden');
          }
        }}
      >
        <div className="dropdown px-1 m-1">
          <BalIcon svg={balIconMenuDots} size="small" color="info" />
        </div>
      </BalButton>
      <div
        id="display-buttons"
        className="dropdown-menu-position is-flex is-flex-direction-column display-buttons-hidden"
        ref={groupDivRef}
      >
        {
          // Remove when functionality is implemented
          (taskType == TaskTypes.InvestigationTask &&
            !getEnabledModules().investigationInspectionJournal) ||
          (taskType == TaskTypes.PropertyInspectionTask &&
            !getEnabledModules().propertyInspectionTaskJournal) ? (
            <></>
          ) : (
            <>
              <BalButton
                className="is-full-width"
                size="small"
                color="info"
                outlined
                download={reportName}
                onClick={() => {
                  downloadReport(false);
                }}
              >
                {t('overlay.downloadReport')}
              </BalButton>
              <FileViewModal
                label={reportName}
                fileId={task.id}
                contentType="application/pdf"
                getFile={() => getReport(token, task.id, false)}
                report
              />

              {/* Investigation report download */}
              {(taskType == TaskTypes.InvestigationTask ||
                taskType == TaskTypes.PropertyInspectionTask) && (
                <>
                  <BalButton
                    className="is-full-width"
                    size="small"
                    color="info"
                    outlined
                    download={fullReportName ? fullReportName : reportName}
                    onClick={() => {
                      downloadReport(true);
                    }}
                  >
                    {t(
                      `${
                        taskType == TaskTypes.InvestigationTask
                          ? 'overlay.downloadInvestigationReport'
                          : 'overlay.downloadPropertyInspectionTaskReport'
                      }`,
                    )}
                  </BalButton>
                  <FileViewModal
                    label={reportName}
                    fileId={task.id}
                    contentType="application/pdf"
                    getFile={() => getReport(token, task.id, true)}
                    report
                    buttonText={t(
                      `${
                        taskType == TaskTypes.InvestigationTask
                          ? 'overlay.viewInvestigationReport'
                          : 'overlay.viewPropertyInspectionTaskReport'
                      }`,
                    )}
                  />
                </>
              )}
            </>
          )
        }
        {getXml &&
          xmlName &&
          (vehicleTaskKind === 'VehicleInspectionTask' ||
            vehicleTaskKind === 'VehicleRiskAssessment') && (
            <BalButton
              size="small"
              color="info"
              outlined
              download={xmlName}
              onClick={() => {
                getXml &&
                  getXml(token, task.id).then((file) => {
                    if (file.status === 'success') {
                      const a = document.createElement('a');
                      a.href = file.value;
                      a.download = xmlName;
                      a.click();
                    } else if (file.status === 'error') {
                      toast(ErrorToast(file.errorValue));
                    }
                  });
              }}
            >
              {t('overlay.downloadXml')}
            </BalButton>
          )}
        {
          // Remove when functionality is implemented
          vehicleTaskKind === 'VehicleRiskAssessment' ||
          (taskType == TaskTypes.InvestigationTask &&
            !getEnabledModules().investigationInspectionSentToAdos) ? (
            <></>
          ) : (
            <BalButton
              size="small"
              color="info"
              outlined
              onClick={(event) => {
                if (event.detail == 1) {
                  updateBaseTaskToAdos(token, task.id, taskType).then(
                    (result) => {
                      if (result.status === 'success') {
                        toast(
                          ToastNotification({
                            message: t('overlay.sendToAdosSuccessfully'),
                            color: 'success',
                          }),
                        );
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    },
                  );
                }
              }}
            >
              {taskType == TaskTypes.InvestigationTask
                ? t('overlay.sendToAdosInvestigation')
                : taskType == TaskTypes.PropertyInspectionTask
                ? t('overlay.sendToAdosPropertyInspectionTask')
                : t('overlay.sendToAdos')}
            </BalButton>
          )
        }
        <BaseTaskPrioritySelectorButton
          task={task}
          taskType={taskType}
          outlined
          requiredCapabilities={[getEditCapabilityFromType(taskType)]}
          onChangeSuccess={onChangeSuccess}
        />
        {taskType == TaskTypes.InvestigationTask && onChangeSuccess && (
          <BaseTaskDeleteButton
            task={task as InvestigationTask}
            taskType={taskType}
            onChangeSuccess={onChangeSuccess}
            requiredCapabilities={['EditFraudInspectionTask']}
          />
        )}
        {taskType === TaskTypes.VehicleTask &&
          vehicleTaskKind &&
          vehicleTaskKind === 'VehicleInspectionTask' && (
            <VehicleTaskSendCometButton
              task={task as VehicleTask}
            ></VehicleTaskSendCometButton>
          )}
        {taskType === TaskTypes.VehicleTask &&
          vehicleTaskKind &&
          vehicleTaskKind === 'VehicleRiskAssessment' && (
            <BalButton
              loading={duplicating}
              size="small"
              color="info"
              outlined
              onClick={async (event) => {
                if (duplicateVehicleRiskAsssessmentTask && event.detail == 1) {
                  setDuplicating(true);
                  const result = await duplicateVehicleRiskAsssessmentTask(
                    task.id,
                    token,
                  );
                  setDuplicating(false);
                  if (result.status === 'success') {
                    toast(
                      ToastNotification({
                        message: t('general.taskDuplicatedSuccessfully'),
                        color: 'success',
                      }),
                    );
                    navigate(
                      EBRoutes.VEHICLE_INSPECTION_TASK_DETAIL.create({
                        taskId: result.localValue.id,
                      }),
                    );
                    navigate(0);
                  } else if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                }
              }}
            >
              {t('general.buttons.duplicate')}
            </BalButton>
          )}
        {taskType === TaskTypes.PropertyInspectionTask && showQRCode && (
          <BalButton
            size="small"
            color="info"
            outlined
            onClick={() => {
              openQRCodeModal(
                t('inspectionTask.inspectionTaskWorkflow.taskQRCode'),
                task.id,
              );
            }}
          >
            {t('general.buttons.showQRCode')}
          </BalButton>
        )}
      </div>
    </div>
  );
};
