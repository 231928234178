import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import {
  InvestigationTask,
  TranslatedSelection,
} from '../../../../types/types';
import { balModalController } from '../../../../controller/controllers';
import {
  DisabableButton,
  WithCapabilities,
} from '../../../../components/authorization';
import { patchInvestigationTask } from '../../data/requests';
import { useToken } from '../../../../hooks';
import {
  BalButton,
  BalButtonGroup,
  BalCheckbox,
  BalHeading,
  BalInput,
  BalModalBody,
  BalModalHeader,
  BalSelect,
  BalSelectOption,
  BalText,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import {
  checkInvestigationCompletionDisabled,
  completionCodeRequiresSavings,
  Globals,
  isInvestigationCompletionAllowed,
} from '../../../../utils';
import {
  InvestigationCheatingParty,
  InvestigationTaskCompletionCode,
  InvestigationTaskType,
  TaskAssessment,
} from '../../../../types/resource-models';
import { useEffect, useState } from 'react';
import BalSelectFormField from '../../../../components/input/bal-select-form-field';
import { useForm } from 'react-hook-form';
import { CancelButton } from '../../../../components/ui';

type InvestigationTaskCompletionProps = {
  investigationTask: InvestigationTask;
  onChangeSuccess: (task: RequestResult<InvestigationTask>) => void;
};

const InvestigationTaskCompletion = (
  props: InvestigationTaskCompletionProps,
): JSX.Element => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: InvestigationTaskCompletionModal,
      modalWidth: 1200,
      componentProps: { ...props },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <DisabableButton
        onClick={(event) => event.detail == 1 && openModal()}
        className="ml-3"
        size="small"
        color="success"
      >
        {t('general.buttons.close')}
      </DisabableButton>
    </WithCapabilities>
  );
};

const InvestigationTaskCompletionModal = ({
  investigationTask,
  onChangeSuccess,
}: InvestigationTaskCompletionProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();

  const methods = useForm({ mode: 'onChange' });

  const [completionCodes, setCompletionCodes] = useState<TranslatedSelection[]>(
    [],
  );
  /*const [completionMainCodes, setCompletionMainCodes] = useState<
    TranslatedSelection[]
  >([]);*/

  /*const [selectedCompletionCode, setSelectedCompletionCode] = useState<
    InvestigationTaskCompletionCode | undefined
  >(investigationTask.investigationTaskCompletionCode);
  const [selectedCompletionMainCode, setSelectedCompletionMainCode] = useState<
    InvestigationTaskCompletionMainCode | undefined
  >(investigationTask.investigationTaskCompletionMainCode);*/

  const [investigationTaskState, setInvestigationTask] =
    useState<InvestigationTask>(investigationTask);

  const investigationTaskTypes: TranslatedSelection[] =
    Globals.InvestigationTaskTypes.map((investigationTaskType) => ({
      value: investigationTaskType,
      translatedValue: t(
        `investigationTask.investigationTaskType.${investigationTaskType}`,
      ),
    }));

  const cheatingParties: TranslatedSelection[] = Globals.CheatingParties.map(
    (cheatingParty) => ({
      value: cheatingParty,
      translatedValue: t(
        `investigationTask.cheatingPartyType.${cheatingParty}`,
      ),
    }),
  );

  const taskAssessmentTranslatedSelections: TranslatedSelection[] =
    Globals.taskAssessment.map((taskAssessment) => ({
      value: taskAssessment,
      translatedValue: t(`taskAssessment.${taskAssessment}`),
    }));

  useEffect(() => {
    setCompletionCodes(
      Globals.CompletionCodes.map((completionCode) => ({
        value: completionCode,
        translatedValue: t(`investigationTaskCompletionCode.${completionCode}`),
      })),
    );
  }, [t]);

  /*useEffect(() => {
    setCompletionMainCodes(
      Globals.CompletionMainCodes.map((completionMainCode) => ({
        value: completionMainCode,
        translatedValue: t(
          `investigationTaskCompletionPartialCode.${completionMainCode}`,
        ),
      })),
    );
  }, [t]);
  useEffect(() => {
    setCompletionCodes(
      Globals.CompletionSubCodes.map((completionSubCode) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        main: completionMainCodes.find((subCode) =>
          completionSubCode.startsWith(subCode.value.toString()),
        )!,
        sub: {
          value: completionSubCode,
          translatedValue: t(
            `investigationTaskCompletionPartialCode.${completionSubCode}`,
          ),
        },
      })),
    );
  }, [completionMainCodes, t]);*/

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">
          {t('taskCompletion.cardTitleInvestigation', {
            taskIdentifier:
              investigationTask.claim.claimNumber ??
              investigationTask.claim.policyNumber,
          })}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText color="info" className="mt-5">
          {t('taskCompletion.investigationCompletion')}
        </BalText>
        <BalSelect
          className="is-8 mt-3 mb-5"
          value={investigationTaskState.investigationTaskType}
          placeholder={t('investigationTask.type')}
          onBalChange={(e) => {
            if (e.detail) {
              setInvestigationTask(
                produce(investigationTaskState, (draftState) => {
                  draftState.investigationTaskType =
                    e.detail?.toString() as InvestigationTaskType;
                }),
              );
            }
          }}
        >
          {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
          <div className="bal-select__inner">
            {investigationTaskTypes.map((selection) => (
              <BalSelectOption
                key={selection.value}
                value={selection.value}
                label={selection.translatedValue}
              >
                {selection.translatedValue}
              </BalSelectOption>
            ))}
          </div>
        </BalSelect>
        {investigationTaskState.investigationTaskType === 'Investigation' && (
          <>
            <BalText className="mt-3" color="info">
              {t('taskCompletion.investigationCompletionInvestigation')}
            </BalText>
            <BalSelect
              className="mb-4"
              value={investigationTaskState.taskAssessment ?? ''}
              placeholder={t('investigationTask.taskAssessment')}
              onBalChange={(e) => {
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.taskAssessment =
                      e.detail?.toString() as TaskAssessment;
                  }),
                );
              }}
            >
              {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
              <div className="bal-select__inner">
                {taskAssessmentTranslatedSelections.map((selection) => (
                  <BalSelectOption
                    key={selection.value}
                    value={selection.value}
                    label={selection.translatedValue}
                  >
                    {selection.translatedValue}
                  </BalSelectOption>
                ))}
              </div>
            </BalSelect>

            <BalSelectFormField
              methods={methods}
              placeholder={t(
                'investigationTask.investigationTaskCompletionCode',
              )}
              title={t('investigationTask.investigationTaskCompletionCode')}
              controllerName="cheatingParties"
              values={completionCodes}
              value={investigationTaskState.investigationTaskCompletionCode}
              required={t('error.mandatoryField')}
              onChange={(choice) => {
                if (choice?.detail) {
                  const value = choice.detail;
                  setInvestigationTask(
                    produce(investigationTaskState, (draftState) => {
                      draftState.investigationTaskCompletionCode =
                        value as InvestigationTaskCompletionCode;
                    }),
                  );
                }
              }}
            />
            {/*
              // Because the Baloise Design System crashes when BalSelect Values are exchanged we just create multiple BalSelects as Workaround.
              completionCodes && selectedCompletionMainCode === 'Rejection' && (
                <BalSelect
                  className="mt-3 mb-3 pb-3"
                  value={selectedCompletionCode}
                  placeholder={t(
                    'investigationTask.investigationTaskCompletionCode',
                  )}
                  onBalChange={(e) => {
                    if (e.detail) {
                      const completionCode =
                        e.detail?.toString() as InvestigationTaskCompletionCode;
                      setSelectedCompletionCode(completionCode);
                    }
                  }}
                >
                  {completionCodes.map(
                    (selection) =>
                      selection?.main?.value === 'Rejection' && (
                        <BalSelectOption
                          key={selection.sub.value}
                          value={selection.sub.value}
                          label={selection.sub.translatedValue}
                        >
                          {selection.sub.translatedValue}
                        </BalSelectOption>
                      ),
                  )}
                </BalSelect>
              )
            */}
            {/*completionCodes && selectedCompletionMainCode === 'NoRejection' && (
              <BalSelect
                className="mt-3 mb-3 pb-3"
                value={selectedCompletionCode}
                placeholder={t(
                  'investigationTask.investigationTaskCompletionCode',
                )}
                onBalChange={(e) => {
                  if (e.detail) {
                    const completionCode =
                      e.detail?.toString() as InvestigationTaskCompletionCode;
                    setSelectedCompletionCode(completionCode);
                  }
                }}
              >
                {completionCodes.map(
                  (selection) =>
                    selection?.main?.value === 'NoRejection' && (
                      <BalSelectOption
                        key={selection.sub.value}
                        value={selection.sub.value}
                        label={selection.sub.translatedValue}
                      >
                        {selection.sub.translatedValue}
                      </BalSelectOption>
                    ),
                )}
              </BalSelect>
            )*/}
            {/*completionCodes &&
              selectedCompletionMainCode === 'DenialOfDamage' && (
                <BalSelect
                  className="mt-3 mb-3 pb-3"
                  value={selectedCompletionCode}
                  placeholder={t(
                    'investigationTask.investigationTaskCompletionCode',
                  )}
                  onBalChange={(e) => {
                    if (e.detail) {
                      const completionCode =
                        e.detail?.toString() as InvestigationTaskCompletionCode;
                      setSelectedCompletionCode(completionCode);
                    }
                  }}
                >
                  {completionCodes.map(
                    (selection) =>
                      selection?.main?.value === 'DenialOfDamage' && (
                        <BalSelectOption
                          key={selection.sub.value}
                          value={selection.sub.value}
                          label={selection.sub.translatedValue}
                        >
                          {selection.sub.translatedValue}
                        </BalSelectOption>
                      ),
                  )}
                </BalSelect>
              )*/}
            {/*completionCodes &&
              selectedCompletionMainCode ===
                'FraudulentJustificationOfClaim' && (
                <BalSelect
                  className="mt-3 mb-3 pb-3"
                  value={selectedCompletionCode}
                  placeholder={t(
                    'investigationTask.investigationTaskCompletionCode',
                  )}
                  onBalChange={(e) => {
                    if (e.detail) {
                      const completionCode =
                        e.detail?.toString() as InvestigationTaskCompletionCode;
                      setSelectedCompletionCode(completionCode);
                    }
                  }}
                >
                  {completionCodes.map(
                    (selection) =>
                      selection?.main?.value ===
                        'FraudulentJustificationOfClaim' && (
                        <BalSelectOption
                          key={selection.sub.value}
                          value={selection.sub.value}
                          label={selection.sub.translatedValue}
                        >
                          {selection.sub.translatedValue}
                        </BalSelectOption>
                      ),
                  )}
                </BalSelect>
              )*/}
            {investigationTaskState.investigationTaskCompletionCode ===
              'FraudulentJustificationOfClaim' && (
              <BalSelectFormField
                methods={methods}
                placeholder={t('investigationTask.cheatingParty')}
                title={t('investigationTask.cheatingParty')}
                controllerName="cheatingParties"
                values={cheatingParties}
                value={investigationTaskState.cheatingParty}
                required={t('error.mandatoryField')}
                onChange={(choice) => {
                  if (choice?.detail) {
                    const value = choice.detail;
                    setInvestigationTask(
                      produce(investigationTaskState, (draftState) => {
                        draftState.cheatingParty =
                          value as InvestigationCheatingParty;
                      }),
                    );
                  }
                }}
              />
            )}
          </>
        )}
        {((investigationTaskState.investigationTaskType === 'Investigation' &&
          completionCodeRequiresSavings(investigationTaskState)) ||
          investigationTaskState.investigationTaskType === 'Service') && (
          <>
            <BalText className="mt-3 mb-0" color="info" size="small" bold>
              {t('investigationTask.investigationSavings')}
            </BalText>
            <BalInput
              placeholder={t('investigationTask.investigationSavings')}
              value={investigationTaskState.investigationSavings?.toString()}
              title={t('investigationTask.investigationSavings')}
              min={undefined}
              max={undefined}
              onBalChange={(e) => {
                let value: number | undefined = parseFloat(e.detail ?? '');
                value = Number.isNaN(value) ? undefined : value;
                const newInvestigationTask = produce(
                  investigationTaskState,
                  (draft) => {
                    draft.investigationSavings = value;
                  },
                );
                setInvestigationTask(newInvestigationTask);
              }}
            />
          </>
        )}
        {investigationTaskState.investigationTaskType === 'Service' && (
          <>
            <BalText color="info">
              {t('taskCompletion.investigationCompletionService')}
            </BalText>
            {/*Service Area Completion Codes*/}
            <BalHeading
              className="mb-1"
              space="none"
              level="h4"
              color="info"
              subtitle
            >
              {t('investigationTask.serviceCompletionCodes')}
            </BalHeading>
            <BalCheckbox
              className="mr-5"
              checked={investigationTaskState.creditCheckServiceCompletionCode}
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.creditCheckServiceCompletionCode = e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.creditCheckServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={
                investigationTaskState.insuranceSurveyServiceCompletionCode
              }
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.insuranceSurveyServiceCompletionCode = e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.insuranceSurveyServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={
                investigationTaskState.carClaimInfoQueryServiceCompletionCode
              }
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.carClaimInfoQueryServiceCompletionCode =
                      e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.carClaimInfoQueryServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={investigationTaskState.hisQueryServiceCompletionCode}
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.hisQueryServiceCompletionCode = e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.hisQueryServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={investigationTaskState.mohawkQueryServiceCompletionCode}
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.mohawkQueryServiceCompletionCode = e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.mohawkQueryServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={investigationTaskState.osintServiceCompletionCode}
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.osintServiceCompletionCode = e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.osintServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={investigationTaskState.consultingServiceCompletionCode}
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.consultingServiceCompletionCode = e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.consultingServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={
                investigationTaskState.contactWithAuthoritiesServiceCompletionCode
              }
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.contactWithAuthoritiesServiceCompletionCode =
                      e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.contactWithAuthoritiesServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={
                investigationTaskState.hisRegistrationServiceCompletionCode
              }
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.hisRegistrationServiceCompletionCode = e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.hisRegistrationServiceCompletionCode',
              )}
            </BalCheckbox>
            <BalCheckbox
              className="mr-5"
              checked={investigationTaskState.artLossServiceCompletionCode}
              onBalChange={(e) =>
                setInvestigationTask(
                  produce(investigationTaskState, (draftState) => {
                    draftState.artLossServiceCompletionCode = e.detail;
                  }),
                )
              }
            >
              {t(
                'investigationTaskServiceCompletionCode.artLossServiceCompletionCode',
              )}
            </BalCheckbox>
          </>
        )}
        <BalButtonGroup position="right" className="mt-5">
          <div className="bal-button-group__inner">
            <CancelButton />
            {isInvestigationCompletionAllowed(investigationTaskState) && (
              <BalButton
                className="ml-3"
                color="primary"
                onClick={async (event) => {
                  event.detail == 1 &&
                    (await patchInvestigationTask(
                      investigationTask,
                      produce(investigationTaskState, (draftState) => {
                        draftState.hisEntryMeasure =
                          investigationTaskState.hisEntryMeasure ?? false;
                        draftState.criminalChargesMeasure =
                          investigationTaskState.criminalChargesMeasure ??
                          false;
                        draftState.civilClaimMeasure =
                          investigationTaskState.civilClaimMeasure ?? false;
                        draftState.regressMeasure =
                          investigationTaskState.regressMeasure ?? false;
                        draftState.dejaVuEntryMeasure =
                          investigationTaskState.dejaVuEntryMeasure ?? false;
                        draftState.artLossRegisterEntryMeasure =
                          investigationTaskState.artLossRegisterEntryMeasure ??
                          false;
                        draftState.privatOrCicilConstitutionMeasure =
                          investigationTaskState.privatOrCicilConstitutionMeasure ??
                          false;
                        draftState.policyHolderServicingMeasure =
                          investigationTaskState.policyHolderServicingMeasure ??
                          false;
                        draftState.status = 'InvestigationCompleted';
                      }),
                      token,
                    ).then((task: RequestResult<InvestigationTask>) => {
                      if (task.status === 'success') {
                        onChangeSuccess(task);
                      }
                      balModalController.dismiss();
                    }));
                }}
              >
                {t('baseTaskStatus.InvestigationCompleted')}
              </BalButton>
            )}
            <BalButton
              color="primary"
              className="ml-3"
              disabled={checkInvestigationCompletionDisabled(
                investigationTaskState,
              )}
              onClick={async (event) => {
                event.detail == 1 &&
                  (await patchInvestigationTask(
                    investigationTask,
                    produce(investigationTaskState, (draftState) => {
                      draftState.hisEntryMeasure =
                        investigationTaskState.hisEntryMeasure ?? false;
                      draftState.criminalChargesMeasure =
                        investigationTaskState.criminalChargesMeasure ?? false;
                      draftState.civilClaimMeasure =
                        investigationTaskState.civilClaimMeasure ?? false;
                      draftState.regressMeasure =
                        investigationTaskState.regressMeasure ?? false;
                      draftState.dejaVuEntryMeasure =
                        investigationTaskState.dejaVuEntryMeasure ?? false;
                      draftState.artLossRegisterEntryMeasure =
                        investigationTaskState.artLossRegisterEntryMeasure ??
                        false;
                      draftState.privatOrCicilConstitutionMeasure =
                        investigationTaskState.privatOrCicilConstitutionMeasure ??
                        false;
                      draftState.policyHolderServicingMeasure =
                        investigationTaskState.policyHolderServicingMeasure ??
                        false;

                      draftState.status = 'Closed';
                    }),
                    token,
                  ).then((task: RequestResult<InvestigationTask>) => {
                    if (task.status === 'success') {
                      onChangeSuccess(task);
                    }
                    balModalController.dismiss();
                  }));
              }}
            >
              {t('general.buttons.close')}
            </BalButton>
          </div>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};

export default InvestigationTaskCompletion;
