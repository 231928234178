import { Route, Routes } from 'react-router';
import {
  BalApp,
  BalNavbar,
  BalNavbarBrand,
  BalNavbarMenu,
  BalNavbarMenuStart,
  BalNavbarMenuEnd,
  useBaloiseDesignSystem,
} from '@baloise/design-system-components-react';
import { ToastContainer } from 'react-toastify';
import { EBRoutes } from '../router/router';
import '../styles/general.scss';
import '../styles/overrides.scss';
import '../styles/tooltip.scss';
import '../styles/favorite-menu.scss';
import HomeView from '../views/home-view';
import { ReactElement } from 'react';
import ProfileView from '../views/profile-view';
import { LoginView } from '../views/login-view';
import { LogoutView } from '../views/logout-view';
import NetworkPartnersView from '../views/network-partners/network-partners';
import { UserContext } from '../context';
import { useUser } from '../features/user';
import { VehicleTaskGuidelinesView } from '../features/vehicle-task';
import InvestigationTaskView from '../views/investigation-task-view';
import InvestigationTaskWizardView from '../views/investigation-task-wizard-view';
import { getEnabledModules } from '../utils/utilities';
import PropertyInspectionTaskView from '../views/property-inspection-task-view';
import AccidentInspectionTaskView from '../views/accident-inspection-task-view';
import AccidentInspectionTaskWizardView from '../views/accident-inspection-task-wizard-view';
import VehicleTaskView from '../views/vehicle-task-view';
import { FeedbackDisplay, EnvDisplay } from '../components/ui';
import { NavbarUser, NavbarAddTaskIcon } from '../components/navigation';
import { NavigationModules } from '../components/navigation/navigation-modules';
import PropertyInspectionTaskWizardView from '../views/property-inspection-task-wizard-view';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { VersionWarning } from '../features/version';
import VehicleRiskAssessmentWizardView from '../views/vehicle-risk-assessment-wizard-view';
import VehicleBonusCorrectionTaskWizardView from '../views/vehicle-bonus-correction-task-wizard-view';
import VehicleInspectionTaskWizardView from '../views/vehicle-inspection-task-wizard-view';
import i18next from 'i18next';
import PropertyInspectionTaskSendingWizardView from '../features/property-inspection-task/components/property-inspection-task-sending-wizard/property-inspection-task-sending-wizard-view';

function App(): ReactElement {
  const [user, setUser] = useUser();
  useBaloiseDesignSystem({
    defaults: {
      region: 'CH',
      language:
        i18next.language === 'de'
          ? 'de'
          : i18next.language === 'fr'
          ? 'fr'
          : 'it',
    },
  });
  const enabledModules = getEnabledModules();

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <UserContext.Provider value={{ user: user, setUser: setUser }}>
        <BalApp className="is-full-height outer-div">
          <header>
            <VersionWarning></VersionWarning>
            <BalNavbar container="fluid" light>
              <BalNavbarBrand href={EBRoutes.HOME.template()} />
              <BalNavbarMenu className="unset-max-width">
                <BalNavbarMenuStart>
                  <NavigationModules appModuleVisability={enabledModules} />
                </BalNavbarMenuStart>
                <EnvDisplay />
                <BalNavbarMenuEnd>
                  <div className="navbar-item">
                    <FeedbackDisplay />
                    <NavbarAddTaskIcon />
                  </div>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <NavbarUser />
                  </div>
                </BalNavbarMenuEnd>
              </BalNavbarMenu>
            </BalNavbar>
          </header>
          <main className="is-full-height is-fullwidth main-div scroll">
            <Routes>
              <Route path={EBRoutes.LOGIN.template()} element={<LoginView />} />
              <Route
                path={EBRoutes.LOGOUT.template()}
                element={<LogoutView />}
              />
              <Route
                index
                element={<HomeView appModuleVisability={enabledModules} />}
              />
              <Route
                path={EBRoutes.PROFILE.template()}
                element={<ProfileView appModuleVisability={enabledModules} />}
              />
              <Route
                path={EBRoutes.INVESTIGATION_TASK.template()}
                element={<InvestigationTaskView />}
              />
              <Route
                path={EBRoutes.NETWORK_PARTNERS.template()}
                element={<NetworkPartnersView />}
              />
              <Route
                path={EBRoutes.ACCIDENT_INSPECTION_TASK.template()}
                element={<AccidentInspectionTaskView />}
              />
              <Route
                path={EBRoutes.ACCIDENT_INSPECTION_TASK_WIZARD.template()}
                element={<AccidentInspectionTaskWizardView />}
              />
              <Route
                path={EBRoutes.PROPERTY_INSPECTION_TASK.template()}
                element={<PropertyInspectionTaskView />}
              />
              <Route
                path={EBRoutes.VEHICLE_INSPECTION_TASK.template()}
                element={<VehicleTaskView />}
              />
              <Route
                path={EBRoutes.PROPERTY_INSPECTION_TASK_WIZARD.template()}
                element={<PropertyInspectionTaskWizardView />}
              />
              <Route
                path={EBRoutes.PROPERTY_INSPECTION_TASK_SENDING_WIZARD.template()}
                element={<PropertyInspectionTaskSendingWizardView />}
              />
              <Route
                path={EBRoutes.INVESTIGATION_TASK_WIZARD.template()}
                element={<InvestigationTaskWizardView />}
              />
              <Route
                path={EBRoutes.VEHICLE_RISK_ASSESSMENT_WIZARD.template()}
                element={<VehicleRiskAssessmentWizardView />}
              />
              <Route
                path={EBRoutes.VEHICLE_BONUS_CORRECTION_TASK_WIZARD.template()}
                element={<VehicleBonusCorrectionTaskWizardView />}
              />
              <Route
                path={EBRoutes.VEHICLE_INSPECTION_TASK_GUIDELINES.template()}
                element={<VehicleTaskGuidelinesView />}
              />
              <Route
                path={EBRoutes.VEHICLE_INSPECTION_TASK_WIZARD.template()}
                element={<VehicleInspectionTaskWizardView />}
              />
            </Routes>
          </main>
          <ToastContainer
            position="bottom-left"
            className="toast-container"
            closeButton={false}
          />
        </BalApp>
      </UserContext.Provider>
    </MsalAuthenticationTemplate>
  );
}

export default App;
