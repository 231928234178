import { BalText } from '@baloise/design-system-components-react';
import { concat } from '../../utils';
import { getValueOrWhitespace } from '../../utils/utilities';

type InfoDisplayProps = {
  title: string;
  text?: string;
  className?: string;
  editComponent?: JSX.Element;
};

export const InfoDisplay = ({
  title,
  text,
  className,
  editComponent,
}: InfoDisplayProps): JSX.Element => {
  if (text || editComponent) {
    return (
      <div
        className={concat([
          'mt-1 mb-1 columns is-gapless bottom-blue-line is-flex is-justify-content-space-between',
          className,
          editComponent ? 'mt-0 mb-0' : 'mt-0 mb-1',
        ])}
      >
        <BalText
          bold
          size="small"
          className="column is-4 is-flex is-flex-direction-column is-justify-content-center"
          color="info"
        >
          {title}
        </BalText>
        <BalText
          className={concat([
            'column is-flex is-flex-direction-column is-justify-content-center',
          ])}
        >
          {getValueOrWhitespace(text)}
        </BalText>
        {editComponent && <div className="mt-1 mb-1">{editComponent}</div>}
      </div>
    );
  } else return <div></div>;
};
