import { BalTag, BalText } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../../components/ui';
import { UserDto, BaseTaskStatus } from '../../../types/resource-models';
import { concat, getBaseTaskStatusColor } from '../../../utils';

type TaskDetailInfoDisplayProps = {
  title: string;
  text?: string;
  user?: UserDto;
  taskStatus?: BaseTaskStatus;
  className?: string;
  editComponent?: JSX.Element;
};

export const TaskDetailInfoDisplay = ({
  title,
  text,
  user,
  taskStatus,
  className,
  editComponent,
}: TaskDetailInfoDisplayProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className={concat([
        'my-3 pb-1 columns is-gapless is-vcentered bottom-blue-line',
        className,
      ])}
    >
      <BalText className="column is-4" color="primary-light" size="small">
        {title}
      </BalText>
      {user && (
        <div className="column mr-1">
          <Avatar user={user} size="22" />
        </div>
      )}
      {taskStatus ? (
        <div className="column is-8">
          <BalTag size="small" color={getBaseTaskStatusColor(taskStatus)}>
            {t(`baseTaskStatus.${taskStatus}`)}
          </BalTag>
        </div>
      ) : (
        <div
          className={concat([
            'column  is-flex is-justify-content-space-between',
            user ? 'is-7' : 'is-8',
          ])}
        >
          <BalText
            color="primary"
            size="small"
            bold
            className="mb-0 is-flex is-align-items-center"
          >
            {text ?? ' '}
          </BalText>
          {editComponent}
        </div>
      )}
    </div>
  );
};
