import '../../../../styles/dropdown-button.scss';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { VehicleTask } from '../../../../types/types';
import {
  BaseTaskStatus,
  CraftsmanListDto,
  VehicleTaskStatusDto,
} from '../../../../types/resource-models';
import { useToken } from '../../../../hooks';
import produce from 'immer';
import { patchVehicleTask } from '../../data/requests';
import { useCallback, useEffect, useState } from 'react';
import { balModalController } from '../../../../controller/controllers';
import { NetworkPartnerSelector } from '../../../network-partners/components';
import { DisabableButton } from '../../../../components/authorization';
import ConfirmationDialog from '../../../dialogs/confirmation-dialog';

type VehicleTaskInProgressWithIndependentButtonProps = {
  task: VehicleTask;
  onChangeSuccess?: (task: RequestResult<VehicleTask>) => void;
};

const VehicleTaskInProgressWithIndependentButton = ({
  task,
  onChangeSuccess,
}: VehicleTaskInProgressWithIndependentButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const craftsmanSelectorTitle = t('vehicleTask.independentPartner');

  const [settingState, setSettingState] = useState<BaseTaskStatus | undefined>(
    undefined,
  );

  const onChangeCallback = useCallback(
    (task: RequestResult<VehicleTask>) => {
      if (onChangeSuccess) {
        onChangeSuccess(task);
      }
    },
    [onChangeSuccess],
  );

  useEffect(() => {
    if (token && settingState === 'InProgressWithIndependent') {
      const openCraftsmanSelectorModal = async () => {
        const modal = await balModalController.create({
          component: NetworkPartnerSelector,
          modalWidth: 1024,
          componentProps: {
            disableNoChoice: true,
            onSelectedSuccess: onCraftsmanSelected,
            title: craftsmanSelectorTitle,
            craftsmanFilter: {
              isIndependentVehicleCraftsman: true,
              branch: 'MotorVehicle',
            },
          },
          cssClass: 'center-modal',
          backdropDismiss: false,
        });
        modal.onDidDismiss().then(() => {
          setSettingState(undefined);
        });
        modal.present();
      };

      const changeState = async (
        state: BaseTaskStatus,
        assigneeExternalId: string | undefined,
      ) => {
        const result = await patchVehicleTask(
          task,
          produce(task, (draftState) => {
            draftState.status = state as VehicleTaskStatusDto;
            draftState.assigneeExternalId = assigneeExternalId;
          }),
          token,
        );

        if (result.status === 'success' && onChangeCallback) {
          onChangeCallback(result);
        }
      };

      const onCraftsmanSelected = async (craftsman: CraftsmanListDto) => {
        await openConfirmationDialog(craftsman);
      };

      const openConfirmationDialog = async (craftsman: CraftsmanListDto) => {
        const modal = await balModalController.create({
          component: ConfirmationDialog,
          componentProps: {
            title: t('vehicleTask.confirmInProgressWithIndependent.title'),
            text: t('vehicleTask.confirmInProgressWithIndependent.message'),
            onAccept: async () => {
              balModalController.dismiss();
              await changeState(settingState, craftsman?.company?.id);
              setSettingState(undefined);
            },
          },
          cssClass: 'center-modal',
          backdropDismiss: false,
        });
        modal.onDidDismiss().then(() => {
          setSettingState(undefined);
        });
        modal.present();
      };

      openCraftsmanSelectorModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingState, onChangeCallback, task, token, craftsmanSelectorTitle]);

  const onChangeState = async (state: BaseTaskStatus) => {
    if (state === 'InProgressWithIndependent') {
      setSettingState(state); // To store the state selected to be applied when selected craftsman -> https://dev.to/shareef/react-usestate-hook-is-asynchronous-1hia
    } else {
      const result = await patchVehicleTask(
        task,
        produce(task, (draftState) => {
          draftState.status = state as VehicleTaskStatusDto;
        }),
        token,
      );

      if (result.status === 'success' && onChangeSuccess) {
        onChangeSuccess(result);
      }
    }
  };

  return (
    <DisabableButton
      className="mr-1"
      size="small"
      color="primary"
      onClick={(event) =>
        event.detail == 1 && onChangeState('InProgressWithIndependent')
      }
    >
      {t('general.buttons.reassignInProgressWithIndependent')}
    </DisabableButton>
  );
};

export default VehicleTaskInProgressWithIndependentButton;
