import { APIRoutes, PropertiesToString } from '../../../router/router';
import { apiUrl } from '../../../data/fetch/base';
import { jsonGet, jsonReq, RequestResult } from '../../../data';

import { diff, jsonPatchPathConverter } from 'just-diff';
import {
  AccidentInspectionTaskFilterCriteriaDto,
  BaseFilterCriteria,
  BaseTaskChangeDto,
  BaseTaskFilterCriteria,
} from '../../../types/resource-models';
import {
  AccidentInspectionTask,
  AccidentInspectionTasksResponse,
  TaskTypes,
} from '../../../types/types';
import { concat } from '../../../utils';
import { makeAuthHeaders, typeFromApi } from '../../../data/fetch/requests';

export const patchAccidentInspectionTask = async (
  accidentInspectionTask: AccidentInspectionTask,
  accidentInspectionTaskNew: AccidentInspectionTask,
  bearerToken: string,
  updateToAdos?: boolean,
): Promise<RequestResult<AccidentInspectionTask>> => {
  const affectedPartnersToDelete =
    accidentInspectionTask.claim.affectedPartners.filter(
      (origAffectedPartner) =>
        !accidentInspectionTaskNew.claim.affectedPartners.some(
          (newAffectedPartner) =>
            origAffectedPartner.id === newAffectedPartner.id,
        ),
    );

  const affectedPartnersToKeep =
    accidentInspectionTask.claim.affectedPartners.filter(
      (partner) => !affectedPartnersToDelete.includes(partner),
    );

  const accidentInspectionTaskWithoutDeletedPartners = {
    ...accidentInspectionTask,
    claim: {
      ...accidentInspectionTask.claim,
      affectedPartners: affectedPartnersToKeep,
    },
  };

  const partnerToDeletePaths = affectedPartnersToDelete
    .map((affectedPartner) => {
      return concat(
        [
          '/claim/affectedPartners/',
          accidentInspectionTask.claim.affectedPartners
            .indexOf(affectedPartner)
            .toString()
            .trim(),
        ],
        'none',
      );
    })
    .sort()
    .reverse(); // json patch operations are order sensitive, start with biggest index

  const diffOperationsGenerated = diff(
    accidentInspectionTaskWithoutDeletedPartners,
    accidentInspectionTaskNew,
    jsonPatchPathConverter,
  );
  const partnerToAddOperations = diffOperationsGenerated.filter(
    (operation) =>
      operation.op == 'add' &&
      operation.path.includes('claim/affectedPartners/'),
  );

  /*
    diff() doesnt know ids, affectedPartner Array needs to be updated manually
    1. Select generated operations, without claim/affectedPartner add-operations
    2. Insert manual add-Opertions, adjust index
    3. Remove deleted partners
  */

  const patchOperations = diffOperationsGenerated.filter(
    (operation) => !partnerToAddOperations.includes(operation),
  );

  let numberOfAffectedPartners =
    accidentInspectionTask.claim.affectedPartners.length;
  partnerToAddOperations.forEach((operation) =>
    patchOperations.push({
      op: operation.op,
      path: concat(
        ['/claim/affectedPartners/', (numberOfAffectedPartners++).toString()],
        'none',
      ),
      value: operation.value,
    }),
  );

  partnerToDeletePaths.forEach((partnerToDeletePath) =>
    patchOperations.push({
      op: 'remove',
      path: partnerToDeletePath,
      value: undefined,
    }),
  );

  if (updateToAdos) {
    patchOperations.push({
      op: 'add',
      path: 'updateToAdos',
      value: undefined,
    });
  }

  const patch = JSON.stringify(patchOperations);

  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_ACCIDENT_INSPECTION_TASK.create({
      accidentInspectionTaskId: accidentInspectionTask.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<AccidentInspectionTask>(request, typeFromApi);
};

export const getAccidentInspectionTasks = async (
  bearerToken: string,
  filter: BaseTaskFilterCriteria,
): Promise<RequestResult<AccidentInspectionTasksResponse>> => {
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_ACCIDENT_INSPECTION_TASKS.create({
      query: filter as Partial<
        PropertiesToString<AccidentInspectionTaskFilterCriteriaDto> &
          PropertiesToString<BaseFilterCriteria>
      >,
    })}`,
    bearerToken,
  );
};

export const getAccidentInspectionTask = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<AccidentInspectionTask>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_ACCIDENT_INSPECTION_TASK.create({
      taskId: taskId,
    })}`,
    bearerToken,
    (result) => (result.type = TaskTypes.AccidentInspectionTask),
  );

export const getAccidentInspectionTaskAllHistory = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<BaseTaskChangeDto[]>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_ACCIDENT_INSPECTION_TASK_ALL_HISTORY.create({
      taskId: taskId,
    })}`,
    bearerToken,
  );
