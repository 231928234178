import { BalButton, BalIcon } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { WithCapabilities } from '../../../../../../components/authorization';
import { balModalController } from '../../../../../../controller/controllers';
import { InvestigationTask } from '../../../../../../types/types';
import { balIconSettings } from '@baloise/design-system-icons';
import { InsuranceSurveyCreateAllFormModal } from '../insurance-survey-create-all-form-modal';

export interface InsuranceSurveyAddAllButtonsProps {
  task: InvestigationTask;
  onAddAllInsuranceSurveys: (insuranceSurveyId: string) => void;
  disabled?: boolean;
}

export const InsuranceSurveyAddAllButton = ({
  task,
  onAddAllInsuranceSurveys,
  disabled,
}: InsuranceSurveyAddAllButtonsProps): JSX.Element => {
  const openAddModal = async () => {
    const modal = await balModalController.create({
      component: InsuranceSurveyCreateAllFormModal,
      modalWidth: 1200,
      componentProps: {
        title: `${t('general.buttons.create')} ${t(
          'investigationTask.insuranceSurvey.entityName',
        )}`,
        investigationTaskId: task.id,
        onSave: onAddAllInsuranceSurveys,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        disabled={disabled}
        className="ml-2"
        square
        outlined
        size="small"
        color="info"
        title={t('investigationTask.insuranceSurvey.createAll')}
        onClick={(event) => event.detail == 1 && openAddModal()}
      >
        <BalIcon svg={balIconSettings} size="small" />
      </BalButton>
    </WithCapabilities>
  );
};
