import '../../styles/general.scss';
import {
  Controller,
  FieldValues,
  UseFormReturn,
  ValidationRule,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BalDatepicker,
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalFieldMessage,
} from '@baloise/design-system-components-react';
import { concat } from '../../utils';
import { DateTime } from 'luxon';

type BalDatepickerFormFieldProps = {
  className?: string;
  titleClassName?: string;
  controllerName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<FieldValues, object>;
  title?: string;
  placeholder?: string;
  value?: string;
  onBalChange?: (e: CustomEvent<string | undefined | null>) => void;
  required?: string | ValidationRule<boolean>;
  disabled?: boolean;
  maxDate?: DateTime;
};

export const BalDatepickerFormField = (
  props: BalDatepickerFormFieldProps,
): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Controller
      name={props.controllerName}
      control={props.methods.control}
      defaultValue={props.value}
      rules={{
        required:
          props.required === undefined
            ? t('error.mandatoryField')
            : props.required,
      }}
      render={({ field, fieldState }) => (
        <BalField
          className={concat(['is-gapless', props.className])}
          disabled={props.disabled}
        >
          {props.title && (
            <BalFieldLabel className={concat(['m-0', props.titleClassName])}>
              {props.title}
            </BalFieldLabel>
          )}
          <BalFieldControl className="m-0 p-0">
            <BalDatepicker
              placeholder={
                props.required === undefined || props.required !== false
                  ? `${props.placeholder} *`
                  : props.placeholder
              }
              value={props.value}
              onBalChange={(e: CustomEvent<string | undefined | null>) => {
                props.onBalChange && props.onBalChange(e);
                field.onChange(e.detail);
              }}
              max={props.maxDate && props.maxDate.toFormat('yyyy-MM-dd')}
            />
          </BalFieldControl>
          <div className="m-0">
            {fieldState.error && (
              <BalFieldMessage color="danger">
                {fieldState.error.message}
              </BalFieldMessage>
            )}
          </div>
        </BalField>
      )}
    />
  );
};

BalDatepickerFormField.displayName = 'BalDatepickerFormField';
