import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import DeleteConfirmationDialog from '../../../dialogs/delete-confirmation-dialog';
import { capitalize } from 'lodash';
import { PropertyInspectionTaskRecipientDto } from '../../../../../../bat-shared/resource-models';
import { deletePropertyInspectionTaskRecipient } from '../../data/requests';
import { useToken } from '../../../../hooks';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../../components/toast-notification';
export interface PropertyInspectionTaskRecipientsDeleteButtonProps {
  currentRecipient: PropertyInspectionTaskRecipientDto;
  onDeleteRecipient: () => void;
  textButton?: boolean;
}

const PropertyInspectionTaskRecipientDeleteButton = ({
  currentRecipient,
  onDeleteRecipient,
  textButton,
}: PropertyInspectionTaskRecipientsDeleteButtonProps): JSX.Element => {
  const token = useToken();
  const onDelete = async () => {
    const result = await deletePropertyInspectionTaskRecipient(
      currentRecipient.id,
      token,
    );

    if (result.status === 'success-no-value') {
      onDeleteRecipient();
    } else if (result.status === 'error') {
      toast(ErrorToast(result.errorValue));
    }
  };
  const openDeleteModal = async () => {
    if (currentRecipient) {
      const modal = await balModalController.create({
        component: DeleteConfirmationDialog,
        componentProps: {
          title: `${t('general.buttons.delete')} ${t(
            'inspectionTask.recipient.entityName',
          )}`,
          text: t('inspectionTask.recipient.deleteConfirmationQuestion'),
          onDelete: onDelete,
          cssClass: 'center-modal',
        },
      });
      return modal.present();
    }
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditPropertyInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      {textButton ? (
        <BalButton
          className="ml-4"
          color="danger"
          onClick={async () => await openDeleteModal()}
        >
          {capitalize(t('general.buttons.delete'))}
        </BalButton>
      ) : (
        <BalButton
          className="ml-2"
          outlined
          square
          size="small"
          color="danger"
          icon="minus"
          onClick={async () => await openDeleteModal()}
        />
      )}
    </WithCapabilities>
  );
};

export default PropertyInspectionTaskRecipientDeleteButton;
