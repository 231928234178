import {
  BalHeading,
  BalTabs,
  BalTabItem,
  BalText,
  BalNotification,
  BalCheckbox,
  BalButton,
  BalAccordion,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import '../../../styles/general.scss';
import '../../../styles/detail-view.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import { useInvestigationTaskFromApi } from '../data/hooks';
import { convertUtcToLocal, formatDateTime } from '../../../utils/date';
import {
  completionCodeRequiresSavings,
  concat,
  concatAddress,
  getAssigneePerTypeTranslated,
  getFullHeightWarningCssClass,
  Globals,
} from '../../../utils';
import { RouteKey, EBRoutes } from '../../../router/router';
import { useEffect, useRef, useState } from 'react';
import {
  AddressDto,
  AttachmentDto,
  BaseTaskChangeDto,
  Branch,
  InvestigationCheatingParty,
  InsuranceType,
  InvestigationTaskType,
  TaskAssessment,
  UserDto,
  Language,
  InvestigationTaskJournalDto,
  InsuranceSurveyDto,
} from '../../../types/resource-models';
import { useToken } from '../../../hooks';
import produce from 'immer';
import { toast } from 'react-toastify';
import { balModalController } from '../../../controller/controllers';
import {
  EditCheckboxModal,
  EditDateModal,
  AddressEditButton,
  EditTextModal,
} from '../../../components/input';
import {
  TaskDetailInfoDisplay,
  BaseTaskAttachments,
  CombinedState,
} from '../../base-task';
import { FileUpload, deleteFile } from '../../files';
import { ErrorToast } from '../../../components/toast-notification';
import { WithCapabilitiesErrorProps } from '../../../components/authorization';
import {
  CopyButton,
  InfoDisplay,
  Breadcrumbs,
  ResultRenderer,
} from '../../../components/ui';
import {
  InvestigationTaskSummaryFieldsButton,
  patchInvestigationTask,
} from '..';
import { InvestigationTaskWorkflowButtons } from './investigation-task-workflow-buttons/investigation-task-workflow-buttons';
import {
  cleanPolicyNumber,
  getDefaultAddressWithClaimId,
  getDisplayNameFromUser,
  getEnabledModules,
} from '../../../utils/utilities';
import { EntityProtocol } from '../../protocol';
import { RequestResult } from '../../../data';
import { getInvestigationTaskAllHistory } from '../data/requests';
import {
  getInvestigationTaskJournal,
  getInvestigationTaskJournals,
} from '../../journal/data/requests';
import JournalSearchFormTable from '../../journal/components/journal-search-form-table';
import {
  getInitialInvestigationTaskJournalCombinedState,
  InvestigationTaskJournalCombinedState,
} from '../../journal/data/hooks';
import InvestigationTaskContactsCards from './investigation-task-contacts-cards';
import { InvestigationTaskCompletionCode } from '../../../../../bat-shared/resource-models';
import { EditSelectionModal } from '../../../components/input/edit-selection-modal';
import { TranslatedSelection, TaskTypes } from '../../../types/types';
import { EstimatedSavings } from '../../../types/resource-models';
import TaskJumpAdos from '../../base-task/components/task-detail-jump-ados';
import { SelectUserModal, SelectUserModalProps } from '../../user';
import { JournalShowView } from '../../journal/components/journal-show-view';
import { BaseTaskMemoFieldsButton } from '../../base-task/components';
import ExpenseSearchFormTable from './expense-search-form-table';
import { InsuranceSurveySearchFormTable } from './insurance-survey/components/insurance-survey-search-form-table';
import { InsuranceSurveyShowView } from './insurance-survey/components/insurance-survey-show-view';
import { getInsuranceSurvey } from './insurance-survey/data/requests';
import DisplayMeasures from './measures/display-measures';

type InvestigationTaskDetailId = {
  taskId: string;
};

export const InvestigationTaskDetail = ({
  withCapabilitiesError,
  setCombinedState,
  combinedState,
}: {
  withCapabilitiesError?: WithCapabilitiesErrorProps;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  combinedState: CombinedState;
}): JSX.Element => {
  return (
    <>
      {!withCapabilitiesError && (
        <InvestigationTaskDetailContent
          combinedState={combinedState}
          setCombinedState={setCombinedState}
        />
      )}
    </>
  );
};

const InvestigationTaskDetailContent = ({
  setCombinedState,
  combinedState,
}: {
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  combinedState: CombinedState;
}): JSX.Element => {
  const reloadTable = () => {
    if (combinedState.tasks.status === 'success') {
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.tasks = { status: 'loading' };
        }),
      );
    }
  };
  const token = useToken();
  const { t } = useTranslation();
  const { taskId } = useParams<InvestigationTaskDetailId>();
  const [investigationTask, setInvestigationTask] = useInvestigationTaskFromApi(
    taskId ?? '',
  );

  const refContentTabs = useRef<HTMLBalTabsElement>(null);
  const refContentTabDefault = useRef<HTMLBalTabItemElement>(null);
  const [searchParams] = useSearchParams();
  const getDefaultTabItem = () => {
    const tabItem = searchParams.get('TabItem');
    if (
      tabItem &&
      (tabItem === 'content' || tabItem == 'journal' || tabItem == 'expenses')
    ) {
      return tabItem;
    } else {
      return refContentTabDefault.current?.value;
    }
  };

  const [currentContentDefaultTab, setCurrentContentDefaultTab] = useState<
    string | undefined
  >(getDefaultTabItem());

  // Journal control area.
  const [currentJournal, setCurrentJournal] = useState<
    RequestResult<InvestigationTaskJournalDto> | undefined
  >(undefined);
  const onJournalSelected = async (journalId?: string) => {
    if (!journalId) {
      setCurrentJournal(undefined);
      setCurrentDefaultTab('details');
    } else {
      setCurrentJournal({ status: 'loading' });
      const journalRequest = await getInvestigationTaskJournal(
        token,
        journalId as string,
      );
      if (journalRequest.status === 'success') {
        setCurrentDefaultTab('journalContent');
      }
      setCurrentJournal(journalRequest);
    }
  };
  const [journalCombinedState, setJournalCombinedState] =
    useState<InvestigationTaskJournalCombinedState>(
      getInitialInvestigationTaskJournalCombinedState(taskId ?? ''),
    );

  useEffect(() => {
    if (
      investigationTask.status === 'success' &&
      taskId &&
      token !== '' &&
      currentContentDefaultTab &&
      currentContentDefaultTab === 'journal' &&
      journalCombinedState.journals.status === 'loading'
    ) {
      getInvestigationTaskJournals(token, journalCombinedState.filter).then(
        (result) => {
          setJournalCombinedState(
            produce(journalCombinedState, (draftState) => {
              draftState.journals = result;
            }),
          );
        },
      );
    }
  }, [
    investigationTask,
    taskId,
    token,
    currentContentDefaultTab,
    journalCombinedState,
  ]);

  useEffect(() => {
    if (
      currentContentDefaultTab &&
      currentContentDefaultTab === 'journal' &&
      journalCombinedState.journals.status === 'initial'
    ) {
      setJournalCombinedState(
        produce(journalCombinedState, (draftState) => {
          draftState.journals.status = 'loading';
        }),
      );
      setCurrentJournal(undefined);
    }
  }, [currentContentDefaultTab, journalCombinedState]);

  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabDefault = useRef<HTMLBalTabItemElement>(null);
  const refTabAttachments = useRef<HTMLBalTabItemElement>(null);
  const [currentDefaultTab, setCurrentDefaultTab] = useState<
    string | undefined
  >(refTabDefault.current?.value);

  // Insurance survey.
  const [currentInsuranceSurvey, setCurrentInsuranceSurvey] = useState<
    RequestResult<InsuranceSurveyDto> | undefined
  >(undefined);
  const onInsuranceSurveySelected = async (insuranceSurveyId?: string) => {
    if (!insuranceSurveyId) {
      setCurrentInsuranceSurvey(undefined);
      setCurrentDefaultTab('details');
    } else {
      const insuranceSurveyRequest = await getInsuranceSurvey(
        token,
        insuranceSurveyId as string,
      );
      if (insuranceSurveyRequest.status === 'success') {
        setCurrentDefaultTab('insuranceSurveyContent');
      }
      setCurrentInsuranceSurvey(insuranceSurveyRequest);
    }
  };

  // History control area
  const [investigationTaskHistory, setInvestigationTaskHistory] = useState<
    RequestResult<BaseTaskChangeDto[]> | undefined
  >(undefined);

  useEffect(() => {
    if (investigationTask.status === 'success') {
      if (currentDefaultTab && currentDefaultTab === 'protocol') {
        if (taskId) {
          setInvestigationTaskHistory({
            status: 'loading',
          });
          getInvestigationTaskAllHistory(token, taskId).then((result) => {
            if (result.status === 'success') {
              setInvestigationTaskHistory(result);
            }
          });
        }
      }
    }
  }, [investigationTask, currentDefaultTab, token, taskId]);

  const completionCodes: TranslatedSelection[] = Globals.CompletionCodes.map(
    (completionCode) => ({
      value: completionCode,
      translatedValue: t(`investigationTaskCompletionCode.${completionCode}`),
    }),
  );

  const removeFile = async (fileName: string) => {
    if (
      investigationTask.status == 'success' &&
      investigationTask.localValue.attachments
    ) {
      const attachmentToDelete = investigationTask.localValue.attachments.find(
        (attachment: AttachmentDto) => attachment.fileName == fileName,
      );
      if (attachmentToDelete) {
        const result = await deleteFile(token, attachmentToDelete);

        if (
          result.status === 'success-no-value' ||
          result.status === 'success'
        ) {
          const newAttachments =
            investigationTask.localValue.attachments.filter(
              (attachment: AttachmentDto) => attachment.fileName !== fileName,
            );
          setInvestigationTask(
            produce(investigationTask, (draftState) => {
              draftState.localValue.attachments = newAttachments;
            }),
          );
        }

        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      }
    }
  };

  if (investigationTask.status === 'success') {
    const assignee = investigationTask.value.assignee;
    const creator = investigationTask.value.creator;
    const agent = investigationTask.value.claim.agent;
    const claim = investigationTask.value.claim;
    const assigneeName = getDisplayNameFromUser(assignee);
    const creatorName = getDisplayNameFromUser(creator);
    const agentName = getDisplayNameFromUser(agent);

    const lastLocationKey: RouteKey = 'INVESTIGATION_TASK';
    const policyHolderPartner =
      claim.policyHolder.type === 'person'
        ? claim.policyHolder.person
        : claim.policyHolder.company;

    const branchTranslatedSelections: TranslatedSelection[] =
      Globals.allBranches.map((branch) => ({
        value: branch,
        translatedValue: t(`branch.${branch}`),
      }));

    const taskAssessmentTranslatedSelections: TranslatedSelection[] =
      Globals.taskAssessment.map((taskAssessment) => ({
        value: taskAssessment,
        translatedValue: t(`taskAssessment.${taskAssessment}`),
      }));

    const investigationTaskTypes: TranslatedSelection[] =
      Globals.InvestigationTaskTypes.map((investigationTaskType) => ({
        value: investigationTaskType,
        translatedValue: t(
          `investigationTask.investigationTaskType.${investigationTaskType}`,
        ),
      }));

    const cheatingParties: TranslatedSelection[] = Globals.CheatingParties.map(
      (cheatingParty) => ({
        value: cheatingParty,
        translatedValue: t(
          `investigationTask.cheatingPartyType.${cheatingParty}`,
        ),
      }),
    );

    const estimatedSavings: TranslatedSelection[] =
      Globals.estimatedSavings.map((estimatedSaving) => ({
        value: estimatedSaving,
        translatedValue: t(`estimatedSavings.${estimatedSaving}`),
      }));
    const openSelectClaimAgentModal = async () => {
      const modalProps: SelectUserModalProps = {
        initialSelected: agent,
        onSelectedSuccess: (user: UserDto) => {
          patchInvestigationTask(
            investigationTask.value,
            produce(investigationTask.value, (draftState) => {
              draftState.claim.agent = user;
            }),
            token,
          ).then((result) => {
            if (result.status === 'success') {
              setInvestigationTask(result);
              balModalController.dismiss();
            } else if (result.status === 'error') {
              toast(ErrorToast(result.errorValue));
            }
          });
        },
        appCapabilityNames: undefined,
      };
      const modal = await balModalController.create({
        component: SelectUserModal,
        componentProps: modalProps,
        cssClass: 'center-modal',
        backdropDismiss: false,
      });
      return modal.present();
    };

    const languages: TranslatedSelection[] = Globals.languages.map(
      (language) => ({
        value: language,
        translatedValue: t(`language.languages.${language}`),
      }),
    );

    return (
      <div className="is-full-height has-background-grey-1 is-flex is-flex-direction-column">
        <div className="has-background-white mr-6 mt-6 ml-6 mb-4 pt-6 pl-6 overflow-hidden detail-border-shadow detail-content">
          <div className="pr-5">
            {investigationTask.value.deletedAt && (
              <BalNotification color="danger">
                <BalText>
                  {t('claimDetail.warningMessageSoftDeleted', {
                    dateTime: convertUtcToLocal({
                      date: investigationTask.value.deletedAt,
                    }),
                  })}
                </BalText>
              </BalNotification>
            )}
            {investigationTask.value.status === 'New' && (
              <BalNotification className="mt-1" color="info">
                <BalText>{t('claimDetail.warningMessageDraft')}</BalText>
              </BalNotification>
            )}
          </div>
          <div
            className={`mt-5 is-flex is-flex-direction-column is-full-width ${getFullHeightWarningCssClass(
              investigationTask,
            )} position-relative`}
          >
            <div className="detail-header is-flex is-flex-direction-column is-justify-content-center">
              <Breadcrumbs
                breadcrumbs={[
                  {
                    label: t(`EBRoutes.${lastLocationKey}`),
                    path: EBRoutes[lastLocationKey].create(),
                  },
                  {
                    label: claim.claimNumber, //review
                  },
                ]}
              />
              <BalHeading
                level="h3"
                subtitle
                space="none"
                className="mt-1 mb-1 is-flex"
              >
                {concat([
                  t('investigationTask.suspicion'),
                  (claim.claimNumber ?? '').length == 0
                    ? claim.policyNumber
                    : claim.claimNumber,
                ])}
                <CopyButton
                  className="mb-3 ml-3"
                  copyValue={claim.claimNumber
                    .replaceAll('/', '')
                    .replace('.', '')}
                />
                <TaskJumpAdos
                  className="ml-3 is-inline-block"
                  task={investigationTask}
                />
              </BalHeading>
              <BalText size="small" className="has-text-black mb-4 mt-2">
                {concat([
                  formatDateTime(investigationTask.value.createdAt),
                  t('general.from'),
                  getDisplayNameFromUser(investigationTask.value.creator),
                  '(',
                  investigationTask.value.creator?.insuranceCenter.name,
                  ')',
                ])}
              </BalText>
            </div>
            <div className="columns is-gapless detail-overview">
              <div className="column mr-1 scroll-bar-minimal">
                <BalTabs
                  ref={refContentTabs}
                  className="is-full-width is-full-height"
                  value={
                    currentContentDefaultTab
                      ? currentContentDefaultTab
                      : 'content'
                  }
                  onBalChange={() => {
                    // Journal tab management.
                    if (
                      refContentTabs.current?.value !== 'journal' &&
                      currentJournal
                    ) {
                      setCurrentJournal(undefined);
                      setCurrentDefaultTab('details');
                      setJournalCombinedState(
                        produce(journalCombinedState, (draftState) => {
                          draftState.journals.status = 'initial';
                        }),
                      );
                    }
                    if (refContentTabs.current?.value === 'journal') {
                      setCurrentJournal(undefined);
                    }
                    // Insurance survey tab management.
                    if (
                      refContentTabs.current?.value !== 'insuranceSurveys' &&
                      currentInsuranceSurvey
                    ) {
                      setCurrentDefaultTab('details');
                    }
                    if (
                      refContentTabs.current?.value === 'insuranceSurveys' &&
                      currentInsuranceSurvey?.status === 'success'
                    ) {
                      onInsuranceSurveySelected(
                        currentInsuranceSurvey?.localValue.id,
                      );
                    }

                    if (refContentTabs.current?.value) {
                      setCurrentContentDefaultTab(
                        refContentTabs.current?.value,
                      );
                    }
                  }}
                >
                  <BalTabItem
                    className="bal-tab-headings"
                    value="content"
                    label={t('general.details')}
                    ref={refContentTabDefault}
                  >
                    <div className="tab-item-content columns is-multiline is-gapless pt-4">
                      <div className="column is-12">
                        {/* Task description section */}
                        <div className="is-flex is-align-items-center mb-2 mt-1">
                          <BalHeading
                            space="none"
                            level="h4"
                            color="info"
                            subtitle
                          >
                            {t('general.taskDescription')}
                          </BalHeading>
                          <div className="pl-6">
                            <BaseTaskMemoFieldsButton
                              requiredCapabilities={['EditFraudInspectionTask']}
                              task={investigationTask}
                              setTask={setInvestigationTask}
                              taskType={TaskTypes.InvestigationTask}
                              showSaveAndSendButton={false}
                            />
                          </div>
                        </div>
                        <BalText space="none" color="info" bold size="small">
                          {t('investigationTask.suspicion')}
                        </BalText>
                        <BalText className="enable-line-break">
                          {investigationTask.value.what}
                        </BalText>
                        {investigationTask.value.remarks && (
                          <>
                            <BalText
                              space="none"
                              color="info"
                              bold
                              size="small"
                            >
                              {t('general.claim.remarks')}
                            </BalText>
                            <BalText className="enable-line-break">
                              {investigationTask.value.remarks}
                            </BalText>
                          </>
                        )}
                        {investigationTask.value.internalNote && (
                          <>
                            <BalText
                              space="none"
                              color="info"
                              bold
                              size="small"
                            >
                              {t('baseTaskDtoColumns.internalNote')}
                            </BalText>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  investigationTask.value.internalNote ?? '',
                              }}
                            ></div>
                          </>
                        )}
                      </div>
                      <div className="columns is-multiline is-gapless pr-6 mt-4">
                        <BalHeading
                          space="none"
                          level="h4"
                          color="info"
                          subtitle
                        >
                          {t('general.claim.claimInformation')}
                        </BalHeading>
                        <div className="column is-full">
                          <InfoDisplay
                            title={t('general.branch')}
                            text={t(`branch.${claim.branch}`)}
                            editComponent={
                              <EditSelectionModal
                                label={t('general.branch')}
                                placeholder={t('general.branch')}
                                value={{
                                  value: claim.branch,
                                  translatedValue: t(`branch.${claim.branch}`),
                                }}
                                values={branchTranslatedSelections}
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(branch: Branch) => {
                                  if (branch) {
                                    patchInvestigationTask(
                                      investigationTask.value,
                                      produce(
                                        investigationTask.value,
                                        (draftState) => {
                                          draftState.claim.branch = branch;
                                        },
                                      ),
                                      token,
                                    ).then((result) => {
                                      if (result.status === 'success') {
                                        setInvestigationTask(result);
                                        balModalController.dismiss();
                                      } else if (result.status === 'error') {
                                        toast(ErrorToast(result.errorValue));
                                      }
                                    });
                                  }
                                }}
                              />
                            }
                          />
                        </div>
                        <div
                          /* TODO: Fix Policy Number Pattern/Validation */ className="column is-full"
                        >
                          <InfoDisplay
                            title={t('claimDetail.policyNumber')}
                            text={claim.policyNumber}
                            editComponent={
                              <EditTextModal
                                isRequired={true}
                                label={t('general.claim.policyNumber')}
                                placeholder={t('general.claim.policyNumber')}
                                value={
                                  investigationTask.value.claim.policyNumber
                                }
                                formatBeforeEdit={cleanPolicyNumber}
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: string | undefined) => {
                                  if (value) {
                                    patchInvestigationTask(
                                      investigationTask.value,
                                      produce(
                                        investigationTask.value,
                                        (draftState) => {
                                          draftState.claim.policyNumber = value;
                                        },
                                      ),
                                      token,
                                    ).then((result) => {
                                      if (result.status === 'success') {
                                        setInvestigationTask(result);
                                        balModalController.dismiss();
                                      } else if (result.status === 'error') {
                                        toast(ErrorToast(result.errorValue));
                                      }
                                    });
                                  }
                                }}
                              />
                            }
                          />
                        </div>
                        <div className="column is-full">
                          <InfoDisplay
                            title={t('general.claim.claimNumber')}
                            text={claim.claimNumber}
                            editComponent={
                              <EditTextModal
                                isRequired={true}
                                label={t('general.claim.claimNumber')}
                                placeholder={t('general.claim.claimNumber')}
                                value={
                                  investigationTask.value.claim.claimNumber
                                }
                                formatBeforeEdit={() =>
                                  investigationTask.value.claim.claimNumber
                                    .replaceAll('/', '')
                                    .replace('.', '')
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: string | undefined) => {
                                  if (value) {
                                    patchInvestigationTask(
                                      investigationTask.value,
                                      produce(
                                        investigationTask.value,
                                        (draftState) => {
                                          draftState.claim.claimNumber = value;
                                        },
                                      ),
                                      token,
                                    ).then((result) => {
                                      if (result.status === 'success') {
                                        setInvestigationTask(result);
                                        balModalController.dismiss();
                                      } else if (result.status === 'error') {
                                        toast(ErrorToast(result.errorValue));
                                      }
                                    });
                                  }
                                }}
                              />
                            }
                          />
                        </div>
                        <div className="column is-full">
                          <InfoDisplay
                            title={t('claimDetail.date')}
                            text={formatDateTime(claim.claimDate)}
                            editComponent={
                              <EditDateModal
                                isRequired={true}
                                label={t('claimDetail.date')}
                                placeholder={t('claimDetail.date')}
                                value={investigationTask.value.claim.claimDate?.substring(
                                  0,
                                  10,
                                )}
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(
                                  value: string,
                                  updateToAdos: boolean,
                                ) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.claim.claimDate =
                                          value ?? ''; // internally we always use empty strings instead of null ones.
                                      },
                                    ),
                                    token,
                                    updateToAdos,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            }
                          />
                        </div>
                        <div className="column is-full">
                          <InfoDisplay
                            title={t('claimDetail.place')}
                            text={concatAddress(claim.claimAddress)}
                            editComponent={
                              <AddressEditButton
                                requiredCapabilities={[
                                  'EditFraudInspectionTask',
                                ]}
                                initialValue={
                                  investigationTask.value.claim.claimAddress ??
                                  getDefaultAddressWithClaimId(
                                    investigationTask.value.claim.id,
                                  )
                                }
                                onSave={async (address: AddressDto) => {
                                  if (address) {
                                    const result = await patchInvestigationTask(
                                      investigationTask.value,
                                      produce(
                                        investigationTask.value,
                                        (draftState) => {
                                          draftState.claim.claimAddress = {
                                            claimId: draftState.claim.id,
                                            ...address,
                                          };
                                        },
                                      ),
                                      token,
                                    );
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  }
                                }}
                              />
                            }
                          />
                        </div>
                        <div className="column is-full">
                          <InfoDisplay
                            title={t('investigationTask.type')}
                            text={
                              investigationTask.value.investigationTaskType
                                ? t(
                                    `investigationTask.investigationTaskType.${investigationTask.value.investigationTaskType}`,
                                  )
                                : ''
                            }
                            editComponent={
                              <EditSelectionModal
                                disabled={
                                  investigationTask.value.status === 'Closed'
                                }
                                label={t('investigationTask.type')}
                                placeholder={t('investigationTask.type')}
                                isNullable={true}
                                value={{
                                  value:
                                    investigationTask.value
                                      .investigationTaskType ?? '',
                                  translatedValue: investigationTask.value
                                    .investigationTaskType
                                    ? t(
                                        `investigationTask.investigationTaskType.${investigationTask.value.investigationTaskType}`,
                                      )
                                    : '',
                                }}
                                values={investigationTaskTypes}
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: string) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.investigationTaskType = value
                                          ? (value as unknown as InvestigationTaskType)
                                          : undefined;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            }
                          />
                          <InfoDisplay
                            title={t('investigationTask.taskAssessment')}
                            text={
                              investigationTask.value.taskAssessment
                                ? t(
                                    `taskAssessment.${investigationTask.value.taskAssessment}`,
                                  )
                                : ''
                            }
                            editComponent={
                              <EditSelectionModal
                                label={t('investigationTask.taskAssessment')}
                                placeholder={t(
                                  'investigationTask.taskAssessment',
                                )}
                                value={{
                                  value:
                                    investigationTask.value.taskAssessment ??
                                    '',
                                  translatedValue: investigationTask.value
                                    .taskAssessment
                                    ? t(
                                        `taskAssessment.${investigationTask.value.taskAssessment}`,
                                      )
                                    : '',
                                }}
                                values={taskAssessmentTranslatedSelections}
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: TaskAssessment) => {
                                  if (value) {
                                    patchInvestigationTask(
                                      investigationTask.value,
                                      produce(
                                        investigationTask.value,
                                        (draftState) => {
                                          draftState.taskAssessment = value;
                                        },
                                      ),
                                      token,
                                    ).then((result) => {
                                      if (result.status === 'success') {
                                        setInvestigationTask(result);
                                        balModalController.dismiss();
                                      } else if (result.status === 'error') {
                                        toast(ErrorToast(result.errorValue));
                                      }
                                    });
                                  }
                                }}
                              />
                            }
                          />
                        </div>
                        <div className="column is-full">
                          <InfoDisplay
                            title={t('claimDetail.insuranceType')}
                            text={t(`insuranceType.${claim.insuranceType}`)}
                            editComponent={
                              <EditSelectionModal
                                label={t('claimDetail.insuranceType')}
                                placeholder={t('claimDetail.insuranceType')}
                                value={{
                                  value: claim.insuranceType,
                                  translatedValue: t(
                                    `insuranceType.${claim.insuranceType}`,
                                  ),
                                }}
                                values={Globals.insuranceTypeTranslatedSelections()}
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: InsuranceType) => {
                                  if (value) {
                                    patchInvestigationTask(
                                      investigationTask.value,
                                      produce(
                                        investigationTask.value,
                                        (draftState) => {
                                          draftState.claim.insuranceType =
                                            value;
                                        },
                                      ),
                                      token,
                                    ).then((result) => {
                                      if (result.status === 'success') {
                                        setInvestigationTask(result);
                                        balModalController.dismiss();
                                      } else if (result.status === 'error') {
                                        toast(ErrorToast(result.errorValue));
                                      }
                                    });
                                  }
                                }}
                              />
                            }
                          />
                        </div>
                        <div className="column is-full">
                          <InfoDisplay
                            title={t('investigationTask.estimatedSavings')}
                            text={
                              investigationTask.value.estimatedSavings != null
                                ? t(
                                    `estimatedSavings.${investigationTask.value.estimatedSavings}`,
                                  )
                                : ''
                            }
                            editComponent={
                              <EditSelectionModal
                                label={t('investigationTask.estimatedSavings')}
                                placeholder={t(
                                  'investigationTask.estimatedSavings',
                                )}
                                value={{
                                  value:
                                    investigationTask.value.estimatedSavings ??
                                    '',
                                  translatedValue:
                                    investigationTask.value.estimatedSavings !=
                                    null
                                      ? t(
                                          `estimatedSavings.${investigationTask.value.estimatedSavings}`,
                                        )
                                      : '',
                                }}
                                values={estimatedSavings}
                                isNullable
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: string) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.estimatedSavings = value as
                                          | EstimatedSavings
                                          | undefined;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            }
                          />
                        </div>
                        {investigationTask.value.investigationTaskType ===
                          'Investigation' && (
                          <>
                            <InfoDisplay
                              className="column is-full"
                              title={t(
                                'investigationTask.investigationTaskCompletionCode',
                              )}
                              text={
                                investigationTask.value
                                  .investigationTaskCompletionCode != null
                                  ? t(
                                      `investigationTaskCompletionCode.${investigationTask.value.investigationTaskCompletionCode}`,
                                    )
                                  : ''
                              }
                              editComponent={
                                <EditSelectionModal
                                  label={t(
                                    'investigationTask.investigationTaskCompletionCode',
                                  )}
                                  placeholder={t(
                                    'investigationTask.investigationTaskCompletionCode',
                                  )}
                                  value={{
                                    value:
                                      investigationTask.value
                                        .investigationTaskCompletionCode ?? '',
                                    translatedValue:
                                      investigationTask.value
                                        .investigationTaskCompletionCode != null
                                        ? t(
                                            `investigationTaskCompletionCode.${investigationTask.value.investigationTaskCompletionCode}`,
                                          )
                                        : '',
                                  }}
                                  values={completionCodes}
                                  isNullable
                                  requiredCapabilities="EditFraudInspectionTask"
                                  onSave={(value: string) => {
                                    patchInvestigationTask(
                                      investigationTask.value,
                                      produce(
                                        investigationTask.value,
                                        (draftState) => {
                                          draftState.investigationTaskCompletionCode =
                                            value as
                                              | InvestigationTaskCompletionCode
                                              | undefined;
                                        },
                                      ),
                                      token,
                                    ).then((result) => {
                                      if (result.status === 'success') {
                                        setInvestigationTask(result);
                                        balModalController.dismiss();
                                      } else if (result.status === 'error') {
                                        toast(ErrorToast(result.errorValue));
                                      }
                                    });
                                  }}
                                />
                              }
                            />
                            {investigationTask.value
                              .investigationTaskCompletionCode ==
                              'FraudulentJustificationOfClaim' && (
                              <InfoDisplay
                                className="column is-full"
                                title={t('investigationTask.cheatingParty')}
                                text={
                                  investigationTask.value.cheatingParty
                                    ? t(
                                        `investigationTask.cheatingPartyType.${investigationTask.value.cheatingParty}`,
                                      )
                                    : ''
                                }
                                editComponent={
                                  <EditSelectionModal
                                    label={t('investigationTask.cheatingParty')}
                                    placeholder={t(
                                      'investigationTask.cheatingParty',
                                    )}
                                    isNullable={true}
                                    value={{
                                      value:
                                        investigationTask.value.cheatingParty ??
                                        '',
                                      translatedValue: investigationTask.value
                                        .cheatingParty
                                        ? t(
                                            `investigationTask.cheatingPartyType.${investigationTask.value.cheatingParty}`,
                                          )
                                        : '',
                                    }}
                                    values={cheatingParties}
                                    requiredCapabilities="EditFraudInspectionTask"
                                    onSave={(value: string) => {
                                      patchInvestigationTask(
                                        investigationTask.value,
                                        produce(
                                          investigationTask.value,
                                          (draftState) => {
                                            draftState.cheatingParty =
                                              value as InvestigationCheatingParty;
                                          },
                                        ),
                                        token,
                                      ).then((result) => {
                                        if (result.status === 'success') {
                                          setInvestigationTask(result);
                                          balModalController.dismiss();
                                        } else if (result.status === 'error') {
                                          toast(ErrorToast(result.errorValue));
                                        }
                                      });
                                    }}
                                  />
                                }
                              />
                            )}
                          </>
                        )}
                        {((investigationTask.value.investigationTaskType ===
                          'Investigation' &&
                          completionCodeRequiresSavings(
                            investigationTask.value,
                          )) ||
                          investigationTask.value.investigationTaskType ===
                            'Service') && (
                          <div className="column is-full">
                            <InfoDisplay
                              title={t(
                                'investigationTask.investigationSavings',
                              )}
                              text={
                                investigationTask.value.investigationSavings?.toString() ??
                                ''
                              }
                              editComponent={
                                <EditTextModal
                                  isRequired={true}
                                  label={t(
                                    'investigationTask.investigationSavings',
                                  )}
                                  placeholder={t(
                                    'investigationTask.investigationSavings',
                                  )}
                                  value={investigationTask.value.investigationSavings?.toString()}
                                  requiredCapabilities="EditFraudInspectionTask"
                                  onSave={(value: string | undefined) => {
                                    let numberValue: number | undefined =
                                      parseFloat(value ?? '');
                                    numberValue = Number.isNaN(numberValue)
                                      ? undefined
                                      : numberValue;
                                    const newInvestigationTask = produce(
                                      investigationTask.value,
                                      (draft) => {
                                        draft.investigationSavings =
                                          numberValue;
                                      },
                                    );
                                    if (value) {
                                      patchInvestigationTask(
                                        investigationTask.value,
                                        newInvestigationTask,
                                        token,
                                      ).then((result) => {
                                        if (result.status === 'success') {
                                          setInvestigationTask(result);
                                          balModalController.dismiss();
                                        } else if (result.status === 'error') {
                                          toast(ErrorToast(result.errorValue));
                                        }
                                      });
                                    }
                                  }}
                                />
                              }
                            />
                          </div>
                        )}
                        {/*Measures Area*/}
                        <div className="is-full-width mt-3">
                          <BalHeading
                            className="pb-1"
                            space="none"
                            level="h4"
                            color="info"
                            subtitle
                          >
                            {t('investigationTask.measures')}
                          </BalHeading>
                          <DisplayMeasures
                            investigationTask={investigationTask.value}
                            setInvestigationTask={setInvestigationTask}
                          />
                        </div>
                      </div>
                      <div className="column is-6 pr-2">
                        <InvestigationTaskContactsCards
                          task={investigationTask}
                          setTask={setInvestigationTask}
                          patchTask={patchInvestigationTask}
                          detailView
                        />
                        {/*Service Area*/}
                        {investigationTask.value.investigationTaskType ===
                          'Service' && (
                          <div className="is-full-width mt-3">
                            <BalHeading
                              className="mb-1"
                              space="none"
                              level="h4"
                              color="info"
                              subtitle
                            >
                              {t('investigationTask.serviceCompletionCodes')}
                            </BalHeading>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .creditCheckServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.creditCheckServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.creditCheckServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .creditCheckServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.creditCheckServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .insuranceSurveyServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.insuranceSurveyServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.insuranceSurveyServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .insuranceSurveyServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.insuranceSurveyServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .carClaimInfoQueryServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.carClaimInfoQueryServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.carClaimInfoQueryServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .carClaimInfoQueryServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.carClaimInfoQueryServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .hisQueryServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.hisQueryServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.hisQueryServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .hisQueryServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.hisQueryServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .mohawkQueryServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.mohawkQueryServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.mohawkQueryServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .mohawkQueryServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.mohawkQueryServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .osintServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.osintServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.osintServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .osintServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.osintServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .consultingServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.consultingServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.consultingServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .consultingServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.consultingServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .contactWithAuthoritiesServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.contactWithAuthoritiesServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.contactWithAuthoritiesServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .contactWithAuthoritiesServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.contactWithAuthoritiesServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .hisRegistrationServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.hisRegistrationServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.hisRegistrationServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .hisRegistrationServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.hisRegistrationServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="is-flex is-justify-content-space-between">
                              <BalCheckbox
                                className="unclickable"
                                checked={
                                  investigationTask.value
                                    .artLossServiceCompletionCode
                                }
                              >
                                {t(
                                  'investigationTaskServiceCompletionCode.artLossServiceCompletionCode',
                                )}
                              </BalCheckbox>
                              <EditCheckboxModal
                                className="is-align-self-center"
                                label={t(
                                  'investigationTask.serviceCompletionCodes',
                                )}
                                text={t(
                                  'investigationTaskServiceCompletionCode.artLossServiceCompletionCode',
                                )}
                                value={
                                  investigationTask.value
                                    .artLossServiceCompletionCode
                                }
                                requiredCapabilities="EditFraudInspectionTask"
                                onSave={(value: boolean) => {
                                  patchInvestigationTask(
                                    investigationTask.value,
                                    produce(
                                      investigationTask.value,
                                      (draftState) => {
                                        draftState.artLossServiceCompletionCode =
                                          value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setInvestigationTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    disabled={
                      getEnabledModules().investigationInspectionJournal ==
                      false
                    }
                    className="bal-tab-headings is-full-height"
                    value="journal"
                    label={t('journal.entityName')}
                  >
                    <>
                      {investigationTask.status === 'success' &&
                        journalCombinedState.journals.status === 'success' && (
                          <BalAccordion
                            className="my-4"
                            openLabel={t(
                              'investigationTask.openConclusionCard',
                            )}
                            closeIcon="close"
                            closeLabel={t(
                              'investigationTask.closeConclusionCard',
                            )}
                          >
                            <div className="is-flex is-align-items-center my-2">
                              <BalHeading
                                space="none"
                                level="h4"
                                color="info"
                                subtitle
                              >
                                {t('investigationTask.conclusion')}
                              </BalHeading>
                              <div className="pl-6">
                                <InvestigationTaskSummaryFieldsButton
                                  requiredCapabilities={[
                                    'EditFraudInspectionTask',
                                  ]}
                                  task={investigationTask}
                                  setTask={setInvestigationTask}
                                />
                              </div>
                            </div>
                            <BalText
                              space="none"
                              color="info"
                              bold
                              size="small"
                            >
                              {investigationTask.value.summary
                                ? t('investigationTask.summary')
                                : ''}
                            </BalText>
                            <div
                              className="scroll-bar-minimal pb-4"
                              dangerouslySetInnerHTML={{
                                __html: investigationTask.value.summary ?? '',
                              }}
                            ></div>
                            <BalText
                              space="none"
                              color="info"
                              bold
                              size="small"
                            >
                              {investigationTask.value.recommendation
                                ? t('investigationTask.recommendation')
                                : ''}
                            </BalText>
                            <div
                              className="scroll-bar-minimal mb-4"
                              dangerouslySetInnerHTML={{
                                __html:
                                  investigationTask.value.recommendation ?? '',
                              }}
                            ></div>
                          </BalAccordion>
                        )}
                      {journalCombinedState.journals != null && (
                        <JournalSearchFormTable
                          task={investigationTask.value}
                          combinedState={journalCombinedState}
                          setCombinedState={setJournalCombinedState}
                          onJournalSelected={onJournalSelected}
                        />
                      )}
                    </>
                  </BalTabItem>
                  <BalTabItem
                    disabled={
                      getEnabledModules().investigationInspectionExpenses ==
                      false
                    }
                    className="bal-tab-headings is-full-height"
                    value="expenses"
                    label={t('expense.entityName')}
                  >
                    <div className="tab-item-content is-full-height">
                      {investigationTask.value?.expenses != null && (
                        <ExpenseSearchFormTable
                          task={investigationTask.value}
                          setInvestigationTask={setInvestigationTask}
                        />
                      )}
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    disabled={getEnabledModules().insuranceSurveys == false}
                    className="bal-tab-headings is-full-height"
                    value="insuranceSurveys"
                    label={t('investigationTask.insuranceSurvey.entityName')}
                  >
                    <div className="tab-item-content is-full-height">
                      <InsuranceSurveySearchFormTable
                        task={investigationTask.value}
                        onInsuranceSurveySelected={onInsuranceSurveySelected}
                      />
                    </div>
                  </BalTabItem>
                </BalTabs>
              </div>
            </div>
            <div className="sub-menu-actions is-pulled-right">
              <InvestigationTaskWorkflowButtons
                task={investigationTask.value}
                className="detail-header"
                onChangeSuccess={(task) => {
                  setInvestigationTask(task);
                  reloadTable();
                }}
              />
              <div className="detail-tabs">
                <BalTabs
                  ref={refTabs}
                  className="has-background-grey-1 p-4 detail-information-content"
                  value={currentDefaultTab ? currentDefaultTab : 'details'}
                  onBalChange={() => {
                    if (refTabs.current?.value) {
                      setCurrentDefaultTab(refTabs.current?.value);
                    }
                  }}
                >
                  <BalTabItem
                    className="bal-tab-headings"
                    value="details"
                    label={t('general.details')}
                    ref={refTabDefault}
                  >
                    <TaskDetailInfoDisplay
                      title={t('baseTaskListDtoColums.priority')}
                      text={
                        investigationTask.localValue.priority
                          ? t(
                              `baseTaskPriorities.${investigationTask.localValue.priority}`,
                            )
                          : ''
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={getAssigneePerTypeTranslated(
                        TaskTypes.InvestigationTask,
                      )}
                      text={assigneeName}
                      user={assignee}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.taskCreator')}
                      text={creatorName}
                      user={creator}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.taskAgent')}
                      text={agentName}
                      user={agent}
                      editComponent={
                        <BalButton
                          onClick={(event) => {
                            event.detail == 1 && openSelectClaimAgentModal();
                          }}
                          square
                          outlined
                          className="ml-3"
                          size="small"
                          color="primary-light"
                          icon="edit"
                        ></BalButton>
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={t('language.reportLabel')}
                      text={t(
                        `language.languages.${investigationTask.value.language}`,
                      )}
                      editComponent={
                        <EditSelectionModal
                          label={t('language.reportLabel')}
                          placeholder={t('language.reportLabel')}
                          value={{
                            value: investigationTask.value.language,
                            translatedValue: t(
                              `language.languages.${investigationTask.value.language}`,
                            ),
                          }}
                          values={languages}
                          requiredCapabilities="EditFraudInspectionTask"
                          onSave={(value: string) => {
                            patchInvestigationTask(
                              investigationTask.value,
                              produce(investigationTask.value, (draftState) => {
                                draftState.language = value as Language;
                              }),
                              token,
                            ).then((result) => {
                              if (result.status === 'success') {
                                setInvestigationTask(result);
                                balModalController.dismiss();
                              } else if (result.status === 'error') {
                                toast(ErrorToast(result.errorValue));
                              }
                            });
                          }}
                        />
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={t('general.claim.status')}
                      taskStatus={investigationTask.value.status}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.policyNumber')}
                      text={claim.policyNumber}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.policyHolderShort')}
                      text={concat(
                        [
                          claim.policyHolder.type === 'person'
                            ? claim.policyHolder.person.displayName
                            : claim.policyHolder.company.displayName,
                          ,
                          policyHolderPartner.address?.addressString ?? '',
                        ],
                        'comma-space',
                      )}
                    />
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'attachments'
                        ? 'attachments-tab pr-0'
                        : ''
                    }`}
                    value="attachments"
                    label={t('general.claim.attachments')}
                    ref={refTabAttachments}
                  >
                    <div className="tab-item-content content-container is-flex is-flex-direction-column attachments-tab pr-0">
                      <BaseTaskAttachments
                        attachments={investigationTask.localValue.attachments}
                        removeFile={removeFile}
                        withScrollbar
                      />
                      <FileUpload
                        entityType={TaskTypes.InvestigationTask}
                        className="pb-0"
                        entity={investigationTask}
                        setEntity={setInvestigationTask}
                        hasFileList={false}
                      />
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    className="bal-tab-headings scroll-bar-minimal"
                    value="protocol"
                    label={t('claimDetail.protocol')}
                  >
                    <div className="tab-item-content ">
                      {investigationTaskHistory != null && (
                        <ResultRenderer
                          result={investigationTaskHistory}
                          defaultValue={[]}
                          loaded={(tasksResponse) => {
                            return <EntityProtocol history={tasksResponse} />;
                          }}
                        />
                      )}
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'journalContent'
                        ? 'protocol-tab'
                        : ''
                    }`}
                    value="journalContent"
                    label={t('journal.journalContent')}
                    hidden={currentJournal === undefined}
                  >
                    <div className="tab-item-content scroll-bar-minimal pt-2">
                      {currentJournal && (
                        <ResultRenderer
                          result={currentJournal}
                          defaultValue={undefined}
                          loaded={(response) => {
                            return response ? (
                              <JournalShowView journal={response} />
                            ) : (
                              <></>
                            );
                          }}
                        />
                      )}
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'insuranceSurveyContent'
                        ? 'protocol-tab'
                        : ''
                    }`}
                    value="insuranceSurveyContent"
                    label={t('investigationTask.insuranceSurvey.contentTab')}
                    hidden={
                      refContentTabs.current?.value !== 'insuranceSurveys' ||
                      currentInsuranceSurvey?.status !== 'success'
                    }
                  >
                    <div className="tab-item-content scroll-bar-minimal pt-2">
                      {refContentTabs.current?.value === 'insuranceSurveys' &&
                        currentInsuranceSurvey?.status === 'success' &&
                        currentInsuranceSurvey && (
                          <ResultRenderer
                            result={currentInsuranceSurvey}
                            defaultValue={undefined}
                            loaded={(response) => {
                              return response ? (
                                <InsuranceSurveyShowView
                                  insuranceSurvey={response}
                                />
                              ) : (
                                <></>
                              );
                            }}
                          />
                        )}
                    </div>
                  </BalTabItem>
                </BalTabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};
