import { BalSpinner } from '@baloise/design-system-components-react';
import { RequestResult } from '../../../data';
import {
  AccidentInspectionTask,
  BaseTask,
  InvestigationTask,
  PropertyInspectionTask,
  TaskTypes,
  VehicleTask,
} from '../../../types/types';
import { ContactSelector } from '../../contacts/components/contact-selector';

export const TaskContactsCards = <T extends BaseTask>({
  task,
  setTask,
  patchTask,
  taskType,
  addressDetailSwitch,
  addressOpen,
}: {
  task: RequestResult<T>;
  setTask: React.Dispatch<RequestResult<T>>;
  patchTask(
    task: T,
    taskNew: T,
    bearerToken: string,
  ):
    | Promise<RequestResult<AccidentInspectionTask>>
    | Promise<RequestResult<PropertyInspectionTask>>
    | Promise<RequestResult<VehicleTask>>
    | Promise<RequestResult<InvestigationTask>>;
  taskType: TaskTypes;
  addressDetailSwitch?: boolean;
  addressOpen?: boolean;
}): JSX.Element => {
  return (
    <div>
      {(task.status === 'initial' || task.status === 'loading') && (
        <BalSpinner />
      )}
      {task.status === 'error' && <></>}
      {task.status === 'success' && (
        <ContactSelector
          className="mt-5 p-5"
          task={task}
          setTask={setTask}
          patchTask={(task, taskNew, token) => patchTask(task, taskNew, token)}
          addressDetailSwitch={addressDetailSwitch}
          addressOpen={addressOpen}
          taskType={taskType}
        />
      )}
    </div>
  );
};
