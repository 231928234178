import {
  BalButton,
  BalModalBody,
  BalButtonGroup,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';
import { RequestResult } from '../../../data';
import {
  AccidentInspectionTask,
  BaseTask,
  InvestigationTask,
  PropertyInspectionTask,
  TaskType,
  TaskTypes,
  VehicleTask,
} from '../../../types/types';
import { useState } from 'react';
import { useToken } from '../../../hooks';
import { ContactSelector } from './contact-selector';
import { WithCapabilities } from '../../../components/authorization';
import { patchInvestigationTask } from '../../investigation-task';
import { patchVehicleTask } from '../../vehicle-task';
import { patchAccidentInspectionTask } from '../../accident-inspection-task';
import { patchPropertyInspectionTask } from '../../property-inspection-task/data/requests';
import { CancelButton } from '../../../components/ui';

const EditContactsModal = <T extends BaseTask>({
  task,
  setTask,
  taskType,
}: {
  task: RequestResult<T>;
  setTask: React.Dispatch<RequestResult<T>>;
  taskType: TaskType;
}): JSX.Element => {
  const { t } = useTranslation();
  const [localTask, setLocalTask] = useState<RequestResult<T>>(task);
  const token = useToken();
  return (
    <BalModalBody>
      <ContactSelector
        task={localTask}
        setTask={setLocalTask}
        card={false}
        patchTask={(task, taskNew, token) =>
          taskType === TaskTypes.PropertyInspectionTask
            ? patchPropertyInspectionTask(
                task as PropertyInspectionTask,
                taskNew as PropertyInspectionTask,
                token,
              )
            : taskType === TaskTypes.AccidentInspectionTask
            ? patchAccidentInspectionTask(
                task as AccidentInspectionTask,
                taskNew as AccidentInspectionTask,
                token,
              )
            : taskType === TaskTypes.VehicleTask
            ? patchVehicleTask(
                task as VehicleTask,
                taskNew as VehicleTask,
                token,
              )
            : patchInvestigationTask(
                task as InvestigationTask,
                taskNew as InvestigationTask,
                token,
              )
        }
        taskType={taskType}
      />
      <BalButtonGroup position="right">
        <CancelButton />
        <BalButton
          color="info"
          onClick={(event) => {
            if (localTask.status === 'success' && event.detail == 1) {
              balModalController.dismiss();
              taskType === TaskTypes.PropertyInspectionTask
                ? patchPropertyInspectionTask(
                    localTask.value as PropertyInspectionTask,
                    localTask.localValue as PropertyInspectionTask,
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result as RequestResult<T>);
                    }
                  })
                : taskType === TaskTypes.AccidentInspectionTask
                ? patchAccidentInspectionTask(
                    localTask.value as AccidentInspectionTask,
                    localTask.localValue as AccidentInspectionTask,
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result as RequestResult<T>);
                    }
                  })
                : taskType === TaskTypes.VehicleTask
                ? patchVehicleTask(
                    localTask.value as VehicleTask,
                    localTask.localValue as VehicleTask,
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result as RequestResult<T>);
                    }
                  })
                : patchInvestigationTask(
                    localTask.value as InvestigationTask,
                    localTask.localValue as InvestigationTask,
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result as RequestResult<T>);
                    }
                  });
            }
          }}
        >
          {t('general.buttons.save')}
        </BalButton>
      </BalButtonGroup>
    </BalModalBody>
  );
};

export const EditContacts = <T extends BaseTask>({
  task,
  setTask,
  taskType,
}: {
  task: RequestResult<T>;
  setTask: React.Dispatch<RequestResult<T>>;
  taskType: TaskType;
}): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditContactsModal,
      componentProps: { task: task, setTask: setTask, taskType: taskType },
      cssClass: 'center-modal',
      backdropDismiss: false,
      modalWidth: 1000,
      isClosable: false,
    });
    return modal.present();
  };
  return (
    <WithCapabilities
      requiredCapabilities={[
        taskType === TaskTypes.PropertyInspectionTask
          ? 'EditPropertyInspectionTask'
          : taskType === TaskTypes.VehicleTask
          ? 'EditVehicleTask'
          : taskType === TaskTypes.InvestigationTask
          ? 'EditFraudInspectionTask'
          : 'EditAccidentInspectionTask',
      ]}
    >
      <BalButton
        className="pl-6"
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      ></BalButton>
    </WithCapabilities>
  );
};
