import i18next from 'i18next';
import { DateTime } from 'luxon';

export function formatTime(date?: string, ommitSeconds?: boolean): string {
  if (ommitSeconds)
    return date !== undefined && date !== null
      ? getDateTime(date).toFormat('HH:mm')
      : '';
  return date !== undefined && date !== null
    ? getDateTime(date).toFormat('HH:mm:ss')
    : '';
}

export function formatDateTime(date?: string): string {
  const dateFormat = getDateFormatBasedOnLanguage();
  return date !== undefined && date !== null
    ? getDateTime(date).toFormat(dateFormat)
    : '';
}

export function convertUtcToLocalWithoutTime(date: string): string {
  const dateFormat = getDateFormatBasedOnLanguage();
  return DateTime.fromISO(date, { zone: 'utc', locale: i18next.language })
    .setZone('system')
    .toFormat(dateFormat);
}

export function convertUtcToLocal({
  date,
  formatted,
  notIncludeSeconds,
}: {
  date?: string;
  formatted?: boolean;
  notIncludeSeconds?: boolean;
}): string {
  if (!date) return '';
  const dateTime = DateTime.fromISO(date, {
    zone: 'utc',
    locale: i18next.language,
  }).setZone('system');
  if (formatted ?? true) {
    const dateFormat = getDateFormatBasedOnLanguage();
    return dateTime.toFormat(
      `${dateFormat}, ${notIncludeSeconds ? 'HH:mm' : 'HH:mm:ss'}`,
    );
  } else {
    return dateTime.toString();
  }
}

export function convertUtcToFormat({
  date,
  format,
}: {
  date?: string;
  format: string;
}): string {
  if (!date) return '';
  const dateTime = DateTime.fromISO(date, {
    zone: 'utc',
    locale: i18next.language,
  }).setZone('system');

  return dateTime.toFormat(format);
}

export const dateComparator = (date1: string, date2: string): number => {
  const date1Number = date1 && new Date(date1).getTime();
  const date2Number = date2 && new Date(date2).getTime();

  if (date1Number == '' && date2Number == '') {
    return 0;
  }

  if (date1Number == '') {
    return -1;
  } else if (date2Number == '') {
    return 1;
  }

  return date1Number - date2Number;
};

function getDateTime(date: string): DateTime {
  return DateTime.fromISO(date, { locale: i18next.language });
}

function getDateFormatBasedOnLanguage(): string {
  switch (i18next.language) {
    case 'fr':
    case 'it':
      return 'dd LLLL yyyy';
    case 'de':
    default:
      return 'dd. LLLL yyyy';
  }
}
