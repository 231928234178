import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { WithCapabilities } from '../../../../components/authorization';
import { UserContext } from '../../../../context';
import { balModalController } from '../../../../controller/controllers';
import { InvestigationTask } from '../../../../types/types';
import { Globals, guid } from '../../../../utils';
import JournalEditFormModal from '../journal-edit-form-modal';

export interface JournalAddButtonsProps {
  task: InvestigationTask;
  onAddJournal: (journalId: string) => void;
  disabled?: boolean;
}

const JournalAddButton = ({
  task,
  onAddJournal,
  disabled,
}: JournalAddButtonsProps): JSX.Element => {
  const { user } = useContext(UserContext);
  const openAddModal = async () => {
    const journalToAdd = {
      ...Globals.journalDefault,
      id: guid(),
      investigationTaskId: task.id,
      creatorId: user.status === 'success' ? user.value.id : '',
      status: task.status,
      createdAt: DateTime.utc().toString(),
    };

    const modal = await balModalController.create({
      component: JournalEditFormModal,
      modalWidth: 1200,
      componentProps: {
        title: `${t('general.buttons.create')} ${t('journal.entityName')}`,
        initialValue: {
          status: 'success',
          value: journalToAdd,
          localValue: journalToAdd,
        },
        initialAddingState: true,
        onSave: onAddJournal,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        disabled={disabled}
        className="ml-2"
        square
        outlined
        size="small"
        color="info"
        icon="plus"
        onClick={(event) => event.detail == 1 && openAddModal()}
      />
    </WithCapabilities>
  );
};

export default JournalAddButton;
