import { BalText } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { convertUtcToLocalWithoutTime } from '../../../utils/date';
import { InvestigationTaskJournalDto } from '../../../types/resource-models';
import { getJournalType } from '../../../utils';
import { TaskDetailInfoDisplay } from '../../base-task';
import JournalHistory from './journal-history';
import { FileView } from '../../../components/ui/file-view';

export const JournalShowView = ({
  journal,
}: {
  journal: InvestigationTaskJournalDto;
}) => {
  return (
    <>
      <JournalHistory journalId={journal.id} />
      <TaskDetailInfoDisplay title={t('journal.note')} />
      <div
        dangerouslySetInnerHTML={{
          __html: journal.note ?? '',
        }}
      />
      <TaskDetailInfoDisplay title={''} />
      <TaskDetailInfoDisplay
        title={t('journal.type')}
        text={getJournalType(journal)}
      />
      {(journal.journalType === 'Email' ||
        journal.journalType === 'Meeting' ||
        journal.journalType === 'Phone') &&
        journal.contactPersonName && (
          <TaskDetailInfoDisplay
            title={t('journal.contactPersonName')}
            text={journal.contactPersonName}
          />
        )}
      <TaskDetailInfoDisplay
        title={t('journal.date')}
        text={convertUtcToLocalWithoutTime(journal.createdAt)}
      />
      <TaskDetailInfoDisplay
        title={t('journal.confidential')}
        text={t(`tristate.${journal.confidential}`)}
      />
      <BalText>
        <strong>{t('general.claim.attachments') as string}</strong>
      </BalText>
      {journal.attachments?.map((file, index) => {
        return (
          <div className="has-background-grey-light mb-3" key={index}>
            {file !== undefined && (
              <>
                <FileView file={file} />
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
