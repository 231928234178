import {
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalText,
  BalButtonGroup,
  BalField,
  BalFieldLabel,
  BalTextarea,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { BaseTask } from '../../../../types/types';
import { useToken } from '../../../../hooks';
import { concat } from '../../../../utils';
import { balModalController } from '../../../../controller/controllers';
import produce from 'immer';
import { useState } from 'react';

type BaseTaskCompletionProps = {
  task: BaseTask;
  onChangeSuccess: (task: RequestResult<BaseTask>) => void;
  patchBaseTask: (
    baseTask: BaseTask,
    baseTaskNew: BaseTask,
    bearerToken: string,
  ) => Promise<RequestResult<BaseTask>>;
  customCompletionTitle?: string;
  customCompletionText?: string;
  showRemarks?: boolean;
};

export const BaseTaskCompletion = ({
  task,
  onChangeSuccess,
  patchBaseTask,
  customCompletionTitle,
  customCompletionText,
  showRemarks,
}: BaseTaskCompletionProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const [remarks, setRemarks] = useState<string>(task.remarks);

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">
          {customCompletionTitle ??
            concat([
              t('claimDetail.claim'),
              task.claim.claimNumber,
              t('general.buttons.close').toLowerCase(),
            ])}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>
          {customCompletionText ?? t('taskCompletion.cardText')}
        </BalText>
        {showRemarks && (
          <BalField className="is-gapless column is-full-width">
            <BalFieldLabel className="my-3">
              {t('general.claim.remarks')}
            </BalFieldLabel>
            <BalTextarea
              id="remarks'"
              placeholder={t('general.claim.remarks')}
              rows={4}
              value={remarks}
              onBalInput={(e: CustomEvent<string | undefined>) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setRemarks(value);
                }
              }}
            />
          </BalField>
        )}
        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>

          <BalButton
            color="primary"
            onClick={async (event) => {
              event.detail == 1 &&
                (await patchBaseTask(
                  task,
                  produce(task, (draftState) => {
                    draftState.status = 'Closed';
                    draftState.remarks = remarks;
                  }),
                  token,
                ).then((task: RequestResult<BaseTask>) => {
                  if (task.status === 'success') {
                    onChangeSuccess(task);
                  }
                  balModalController.dismiss();
                }));
            }}
          >
            {t('general.buttons.close')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};
