import React, { useEffect, useState } from 'react';
import { useToken } from '../../../hooks';
import { RequestResult } from '../../../data';
import { ImportStateDto } from '../../../types/resource-models';
import { getImportStates } from './request';

export function useImportStateFromApi(
  importType: string,
): [
  RequestResult<ImportStateDto[]>,
  React.Dispatch<React.SetStateAction<RequestResult<ImportStateDto[]>>>,
] {
  const token = useToken();
  const [importStateResponse, setImportStateResponse] = useState<
    RequestResult<ImportStateDto[]>
  >({ status: 'initial' });

  useEffect(() => {
    if (token !== '' && importStateResponse.status === 'initial') {
      getImportStates(token, importType).then(setImportStateResponse);
    }
  }, [token, importType, importStateResponse.status]);

  return [importStateResponse, setImportStateResponse];
}
