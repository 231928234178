import {
  ColDef,
  ColumnApi,
  ColumnResizedEvent,
  GridOptions,
  GridReadyEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import '../../../../styles/general.scss';
import { BalPagination } from '@baloise/design-system-components-react';
import { RequestResult } from '../../../../data';
import {
  BaseTaskListDto,
  UserTableTypes,
} from '../../../../types/resource-models';
import { CombinedState } from '../../data/hooks';
import produce from 'immer';
import { ResultRenderer } from '../../../../components/ui';
import { useOnClickOutside, useToken } from '../../../../hooks';
import { UserContext } from '../../../../context';
import { ISortingState } from '../../../../types/types';
import {
  postColumnsOrderUserTableSettings,
  postColumnsWidthUserTableSettings,
  postSortUserTableSettings,
} from '../../../user/data/requests';

export const BaseTaskSearchFormTable = ({
  combinedState,
  setCombinedState,
  task,
  setTask,
  refTaskOverlay,
  onGridReady,
  gridOptions,
  userTableType,
}: {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  task: RequestResult<BaseTaskListDto>;
  setTask: React.Dispatch<React.SetStateAction<RequestResult<BaseTaskListDto>>>;
  refTaskOverlay: RefObject<HTMLBalCardElement>;
  onGridReady: (params: GridReadyEvent) => void;
  gridOptions?: GridOptions;
  userTableType: UserTableTypes;
}): JSX.Element => {
  const { t } = useTranslation();
  const [totalPages, setTotalPages] = useState(1);
  const tasks = combinedState.tasks;
  const { user, setUser } = useContext(UserContext);
  const token = useToken();

  useEffect(() => {
    if (tasks.status === 'success') {
      setTotalPages(tasks.value.totalPages);
    }
  }, [tasks]);
  const [selectedTaskId, setSelectedTaskId] = useState<string>(
    task?.status === 'success' ? task.value.id : '',
  );
  const refTable = useRef(null);
  useEffect(() => {
    if (tasks.status === 'success') {
      if (selectedTaskId === '') setTask({ status: 'initial' });
      else {
        const task = tasks.value.entities.find((task: BaseTaskListDto) => {
          return task.id === selectedTaskId;
        }) as BaseTaskListDto;
        if (task) {
          setTask({
            status: 'success',
            value: task,
            localValue: task,
          });
        }
      }
    }
  }, [selectedTaskId, setTask, tasks]);

  useOnClickOutside([refTable, refTaskOverlay], () => {
    if (combinedState.tasks.status === 'success') {
      const element = document.getElementById('overlay');
      element?.classList.add('animated-overlay-out');
      setSelectedTaskId('');
    }
  });

  useEffect(() => {
    if (gridOptions) {
      gridOptions.onRowClicked = (params) => {
        setSelectedTaskId(params.data.id);
      };
    }
  }, [gridOptions]);

  const onGridReadyLocal = (params: GridReadyEvent) => {
    const gridApi = params.api;
    gridApi.forEachNode((node) => {
      if (node.data.id === selectedTaskId) node.setSelected(true);
    });

    const columnsApi = params.columnApi;
    // If the filter is different to the default, will show it in the table.
    if (
      combinedState.filter.orderField &&
      (combinedState.filter.orderField !==
        combinedState.defaultFilter.orderField ||
        combinedState.filter.orderBy !== combinedState.defaultFilter.orderBy)
    ) {
      const colDef = gridApi
        .getColumnDefs()
        ?.find((x) => (x as ColDef).field == combinedState.filter.orderField);
      if (colDef) {
        columnsApi.applyColumnState({
          state: [
            {
              colId: (colDef as ColDef).colId ?? colDef.headerName ?? '',
              sort: combinedState.filter.orderBy,
            },
          ],
        });
      }
    }
    if (onGridReady) {
      onGridReady(params);
    }
  };

  const getSorting = (columnApi: ColumnApi): ISortingState | undefined => {
    const columns = columnApi.getAllDisplayedColumns();
    const sortingColumn = columns.find((c) => c.getSort());
    if (sortingColumn) {
      const colDef = sortingColumn.getColDef();
      return {
        field: colDef.field ?? '',
        direction: sortingColumn.getSort() ?? 'asc',
      };
    } else {
      return undefined;
    }
  };

  const [currentRow, setCurrentRow] = useState<number>(0);
  useEffect(() => {
    setCurrentRow(0);
  }, [combinedState]);

  return (
    <div className="is-full-height is-flex is-flex-direction-column is-justify-content-space-between">
      <div className="is-flex-grow-1">
        {tasks.status == 'success' && user.status === 'success' && (
          <ResultRenderer
            result={tasks}
            defaultValue={{ totalPages: 1, totalItems: 0, entities: [] }}
            loaded={(tasksResponse) => {
              return (
                <div
                  id="agGridDiv"
                  ref={refTable}
                  className="task-table is-full-height"
                >
                  <AgGridReact
                    suppressCellFocus={true}
                    rowSelection={'single'}
                    onGridReady={onGridReadyLocal}
                    rowData={tasksResponse.entities}
                    rowHeight={40}
                    gridOptions={gridOptions}
                    onSelectionChanged={(event) => {
                      const selectedNodes = event.api.getSelectedNodes();
                      if (selectedNodes.length > 0) {
                        const node = selectedNodes[0];
                        if (
                          combinedState.filter.page !== undefined &&
                          combinedState.filter.maxItemCount
                        ) {
                          const newItemCount =
                            (node.rowIndex ?? 0) +
                            1 +
                            combinedState.filter.maxItemCount *
                              combinedState.filter.page;
                          setCurrentRow(newItemCount);
                        }
                      }
                    }}
                    suppressMultiSort
                    suppressDragLeaveHidesColumns={true}
                    onColumnMoved={async (event) => {
                      if (
                        event.source === 'uiColumnDragged' &&
                        event.type === 'columnMoved' &&
                        event.toIndex !== undefined
                      ) {
                        await postColumnsOrderUserTableSettings(
                          event.columnApi.getAllDisplayedColumns(),
                          userTableType,
                          user,
                          setUser,
                          token,
                        );
                      }
                    }}
                    onColumnResized={async (event: ColumnResizedEvent) => {
                      if (event.column && event.finished) {
                        const column = event.column;
                        const colDef = column.getColDef();
                        if (colDef.resizable) {
                          await postColumnsWidthUserTableSettings(
                            column.getColId() ?? '',
                            column.getActualWidth() ?? 0,
                            userTableType,
                            user,
                            setUser,
                            token,
                          );
                        }
                      }
                    }}
                    onSortChanged={async (event: SortChangedEvent) => {
                      // get order column and direction.
                      if (event.columnApi) {
                        const currentSorting = getSorting(event.columnApi);
                        const sortingChanged =
                          currentSorting?.field !==
                            combinedState.filter.orderField ||
                          currentSorting?.direction !==
                            combinedState.filter.orderBy;
                        if (sortingChanged || !currentSorting) {
                          // Send new filter to user's profile.
                          await postSortUserTableSettings(
                            currentSorting?.field,
                            currentSorting?.direction,
                            userTableType,
                            user,
                            setUser,
                            token,
                          );

                          // Avoid unfocus header problems in aggrid when changing filter.
                          const focused = document.activeElement;
                          if (focused) {
                            (focused as HTMLInputElement).blur();
                          }

                          setCombinedState(
                            produce(combinedState, (draft) => {
                              if (combinedState.tasks.status === 'success') {
                                draft.tasks.status = 'loading-with-value';
                              }
                              if (currentSorting) {
                                draft.filter.orderField = currentSorting?.field;
                                draft.filter.orderBy =
                                  currentSorting?.direction;
                              } else {
                                draft.filter.orderField =
                                  draft.defaultFilter.orderField;
                                draft.filter.orderBy =
                                  draft.defaultFilter.orderBy;
                              }
                              draft.filter.page = 0;
                            }),
                          );
                        }
                      }
                    }}
                  />
                </div>
              );
            }}
          />
        )}
        {tasks.status == 'error' && (
          <span>{t('error.requestError.connectionError')}</span>
        )}
      </div>
      <div>
        <span className="mt-5 paginator-results">
          {currentRow === 0
            ? tasks.status === 'success'
              ? `${tasks.localValue.totalItems} ${t('general.results')}`
              : ''
            : `${t('general.result')} ${currentRow} ${t('general.of')} ${
                tasks.status === 'success' ? tasks.localValue.totalItems : ''
              }`}
        </span>
        <BalPagination
          id="paginator"
          className="mt-3"
          value={(combinedState.filter.page ?? 0) + 1}
          onBalChange={(e) => {
            setCombinedState(
              produce(combinedState, (draftState) => {
                if (combinedState.tasks.status === 'success') {
                  draftState.tasks.status = 'loading-with-value';
                }
                draftState.filter.page = e.detail - 1;
              }),
            );
          }}
          totalPages={totalPages}
        ></BalPagination>
      </div>
    </div>
  );
};
