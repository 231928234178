import '../../../styles/general.scss';
import { ImportStateDto } from '../../../types/resource-models';
import { RequestResult } from '../../../data';
import { ImportStateTable } from './import-state-table';

type ImportStateTableProps = {
  importStateFromApi: RequestResult<ImportStateDto[]>;
};

export const AdminImportStateTable = ({
  importStateFromApi,
}: ImportStateTableProps): JSX.Element => {
  return (
    <div className="table-container">
      <ImportStateTable
        importStates={
          importStateFromApi.status === 'success'
            ? importStateFromApi.localValue
            : []
        }
      />
    </div>
  );
};
