import { APIRoutes, PropertiesToString } from '../../../router/router';
import { apiUrl } from '../../../data/fetch/base';
import { jsonGet, jsonReq, RequestResult } from '../../../data';
import {
  PropertyInspectionTask,
  PropertyInspectionTasksResponse,
  TaskTypes,
} from '../../../types/types';
import { diff, jsonPatchPathConverter } from 'just-diff';
import {
  makeAuthHeaders,
  typeFromApi,
  makeAuthFileHeaders,
  fileReq,
} from '../../../data/fetch/requests';
import { concat } from '../../../utils';
import {
  BaseFilterCriteria,
  BaseTaskChangeDto,
  BaseTaskFilterCriteria,
  InspectionListDto,
  PropertyInspectionTaskFilterCriteriaDto,
  PropertyInspectionTaskJournalChangeDto,
  PropertyInspectionTaskJournalDto,
  PropertyInspectionTaskJournalFilterCriteria,
  PropertyInspectionTaskPhotoDto,
  PropertyInspectionTaskRecipientDto,
  PropertyInspectionTaskSendToRecipientsDto,
  ResponseOfInspectionListDto,
  ResponseOfPropertyInspectionTaskJournalListDto,
  ResponseOfPropertyInspectionTaskPhotoDto,
} from '../../../types/resource-models';
import { ResponseOfPropertyInspectionTaskRecipientDto } from '../../../../../bat-shared/resource-models';

export const patchPropertyInspectionTask = async (
  inspectionTask: PropertyInspectionTask,
  inspectionTaskNew: PropertyInspectionTask,
  bearerToken: string,
  updateToAdos?: boolean,
): Promise<RequestResult<PropertyInspectionTask>> => {
  const affectedPartnersToDelete = inspectionTask.claim.affectedPartners.filter(
    (origAffectedPartner) =>
      !inspectionTaskNew.claim.affectedPartners.some(
        (newAffectedPartner) =>
          origAffectedPartner.id === newAffectedPartner.id,
      ),
  );

  const affectedPartnersToKeep = inspectionTask.claim.affectedPartners.filter(
    (partner) => !affectedPartnersToDelete.includes(partner),
  );

  const inspectionTaskWithoutDeletedPartners = {
    ...inspectionTask,
    claim: {
      ...inspectionTask.claim,
      affectedPartners: affectedPartnersToKeep,
    },
  };

  const partnerToDeletePaths = affectedPartnersToDelete
    .map((affectedPartner) => {
      return concat(
        [
          '/claim/affectedPartners/',
          inspectionTask.claim.affectedPartners
            .indexOf(affectedPartner)
            .toString()
            .trim(),
        ],
        'none',
      );
    })
    .sort()
    .reverse(); // json patch operations are order sensitive, start with biggest index

  const diffOperationsGenerated = diff(
    inspectionTaskWithoutDeletedPartners,
    inspectionTaskNew,
    jsonPatchPathConverter,
  );
  const partnerToAddOperations = diffOperationsGenerated.filter(
    (operation) =>
      operation.op == 'add' &&
      operation.path.includes('claim/affectedPartners/'),
  );

  /*
    diff() doesnt know ids, affectedPartner Array needs to be updated manually
    1. Select generated operations, without claim/affectedPartner add-operations
    2. Insert manual add-Opertions, adjust index
    3. Remove deleted partners
  */

  const patchOperations = diffOperationsGenerated.filter(
    (operation) => !partnerToAddOperations.includes(operation),
  );

  let numberOfAffectedPartners = inspectionTask.claim.affectedPartners.length;
  partnerToAddOperations.forEach((operation) =>
    patchOperations.push({
      op: operation.op,
      path: concat(
        ['/claim/affectedPartners/', (numberOfAffectedPartners++).toString()],
        'none',
      ),
      value: operation.value,
    }),
  );

  partnerToDeletePaths.forEach((partnerToDeletePath) =>
    patchOperations.push({
      op: 'remove',
      path: partnerToDeletePath,
      value: undefined,
    }),
  );

  if (updateToAdos) {
    patchOperations.push({
      op: 'add',
      path: 'updateToAdos',
      value: undefined,
    });
  }

  // Only execute patch if there are operations
  if (patchOperations.length === 0) {
    return {
      status: 'success',
      value: inspectionTask,
      localValue: inspectionTask,
    };
  }

  const patch = JSON.stringify(patchOperations);

  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_PROPERTY_INSPECTION_TASK.create({
      inspectionTaskId: inspectionTask.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<PropertyInspectionTask>(request, typeFromApi);
};

export const patchInspection = async (
  inspection: InspectionListDto,
  inspectionNew: InspectionListDto,
  bearerToken: string,
): Promise<RequestResult<InspectionListDto>> => {
  const diffOperationsGenerated = diff(
    inspection,
    inspectionNew,
    jsonPatchPathConverter,
  );

  // Only execute patch if there are operations
  if (diffOperationsGenerated.length === 0) {
    return {
      status: 'success',
      value: inspection,
      localValue: inspection,
    };
  }

  const patch = JSON.stringify(diffOperationsGenerated);

  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_INSPECTION.create({
      inspectionId: inspection.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<InspectionListDto>(request, typeFromApi);
};

export const getInspectionTasks = async (
  bearerToken: string,
  filter: BaseTaskFilterCriteria,
): Promise<RequestResult<PropertyInspectionTasksResponse>> => {
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASKS.create({
      query: filter as Partial<
        PropertiesToString<PropertyInspectionTaskFilterCriteriaDto> &
          PropertiesToString<BaseFilterCriteria>
      >,
    })}`,
    bearerToken,
  );
};

export const getPropertyInspectionTask = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<PropertyInspectionTask>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK.create({
      taskId: taskId,
    })}`,
    bearerToken,
    (result) => {
      result.type = TaskTypes.PropertyInspectionTask;
    },
  );

export const getAttachment = async (
  bearerToken: string,
  fileId: string,
): Promise<RequestResult<string>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.GET_ATTACHMENT.create({ fileId: fileId })}`,
    {
      method: 'GET',
      headers: await makeAuthFileHeaders(bearerToken),
    },
  );
  return fileReq(request);
};

export const getPropertyInspectionTaskAllHistory = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<BaseTaskChangeDto[]>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_ALL_HISTORY.create({
      taskId: taskId,
    })}`,
    bearerToken,
  );

export const getPropertyInspectionTaskJournals = async (
  bearerToken: string,
  filter: PropertyInspectionTaskJournalFilterCriteria,
): Promise<RequestResult<ResponseOfPropertyInspectionTaskJournalListDto>> => {
  const { propertyInspectionTaskId, ...rest } = filter;

  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_JOURNALS.create({
      query: {
        ...rest,
        PropertyInspectionTaskId: propertyInspectionTaskId,
      },
    })}`,
    bearerToken,
  );
};

export const getPropertyInspectionTaskJournal = async (
  bearerToken: string,
  propertyInspectionTaskJournalId: string,
): Promise<RequestResult<PropertyInspectionTaskJournalDto>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_JOURNAL.create({
      journalId: propertyInspectionTaskJournalId,
    })}`,
    bearerToken,
  );

export const getPropertyInspectionTaskJournalNextOrder = async (
  bearerToken: string,
  propertyInspectionTaskId: string,
): Promise<RequestResult<number>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_JOURNAL_NEXT_ORDER.create(
      {
        propertyInspectionTaskId,
      },
    )}`,
    bearerToken,
  );

export async function postPropertyInspectionTaskJournal(
  journal: PropertyInspectionTaskJournalDto,
  bearerToken: string,
  reportToAdos: boolean,
): Promise<RequestResult<PropertyInspectionTaskJournalDto>> {
  const route = `${apiUrl}${APIRoutes.POST_PROPERTY_INSPECTION_TASK_JOURNAL.create(
    {},
  )}`;
  if (reportToAdos) {
    journal = { ...journal, updateToAdos: true };
  }

  const request = new Request(route, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(journal),
  });
  return jsonReq<PropertyInspectionTaskJournalDto>(request, typeFromApi);
}

export const patchPropertyInspectionTaskJournal = async (
  journalOld: PropertyInspectionTaskJournalDto,
  journalNew: PropertyInspectionTaskJournalDto,
  bearerToken: string,
  reportToAdos: boolean,
): Promise<RequestResult<PropertyInspectionTaskJournalDto>> => {
  const patchDiff = diff(journalOld, journalNew, jsonPatchPathConverter);

  if (reportToAdos) {
    patchDiff.push({ op: 'add', path: 'updateToAdos', value: true });
  }

  const patch = JSON.stringify(patchDiff);

  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_PROPERTY_INSPECTION_TASK_JOURNAL.create({
      journalId: journalOld.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<PropertyInspectionTaskJournalDto>(request, typeFromApi);
};

export const deletePropertyInspectionTaskJournal = async (
  journalId: string,
  bearerToken: string,
): Promise<RequestResult<PropertyInspectionTaskJournalDto>> => {
  const patchOperations = [
    { op: 'replace', path: 'deletedAt', value: new Date().toISOString() },
  ];
  const patch = JSON.stringify(patchOperations);

  const route = `${apiUrl}${APIRoutes.PATCH_PROPERTY_INSPECTION_TASK_JOURNAL.create(
    {
      journalId: journalId,
    },
  )}`;

  const request = new Request(route, {
    method: 'PATCH',
    headers: await makeAuthHeaders(bearerToken),
    body: patch,
  });
  return await jsonReq<PropertyInspectionTaskJournalDto>(request, typeFromApi);
};

export const getPropertyInspectionTaskJournalHistory = async (
  bearerToken: string,
  investigationTaskJournalId: string,
): Promise<RequestResult<PropertyInspectionTaskJournalChangeDto[]>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_JOURNAL_HISTORY.create({
      journalId: investigationTaskJournalId,
    })}`,
    bearerToken,
  );

export const getPropertyInspectionTaskRecipients = async (
  bearerToken: string,
  propertyInspectionTaskId: string,
): Promise<RequestResult<ResponseOfPropertyInspectionTaskRecipientDto>> => {
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_RECIPIENTS.create({
      taskId: propertyInspectionTaskId,
    })}`,
    bearerToken,
  );
};

export const sendToRecipients = async (
  bearerToken: string,
  propertyInspectionTaskSendToRecipientsDto: PropertyInspectionTaskSendToRecipientsDto,
): Promise<RequestResult<void>> => {
  const patch = JSON.stringify(propertyInspectionTaskSendToRecipientsDto);
  const route = `${apiUrl}${APIRoutes.PUT_SEND_INSPECTION_TO_RECIPIENTS.create()}`;

  const request = new Request(route, {
    method: 'PUT',
    headers: await makeAuthHeaders(bearerToken),
    body: patch,
  });
  return await jsonReq(request, typeFromApi);
};

export const getPropertyInspectionTaskRecipient = async (
  bearerToken: string,
  propertyInspectionTaskRecipientId: string,
): Promise<RequestResult<PropertyInspectionTaskRecipientDto>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_RECIPIENT.create({
      recipientId: propertyInspectionTaskRecipientId,
    })}`,
    bearerToken,
  );

export async function postPropertyInspectionTaskRecipient(
  recipient: PropertyInspectionTaskRecipientDto,
  bearerToken: string,
): Promise<RequestResult<PropertyInspectionTaskRecipientDto>> {
  const route = `${apiUrl}${APIRoutes.POST_PROPERTY_INSPECTION_TASK_RECIPIENT.create(
    {},
  )}`;
  const request = new Request(route, {
    method: 'POST',
    headers: await makeAuthHeaders(bearerToken),
    body: JSON.stringify(recipient),
  });
  return jsonReq<PropertyInspectionTaskRecipientDto>(request, typeFromApi);
}

export const patchPropertyInspectionTaskRecipient = async (
  recipientOld: PropertyInspectionTaskRecipientDto,
  recipientNew: PropertyInspectionTaskRecipientDto,
  bearerToken: string,
): Promise<RequestResult<PropertyInspectionTaskRecipientDto>> => {
  const patch = JSON.stringify(
    diff(recipientOld, recipientNew, jsonPatchPathConverter),
  );
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_PROPERTY_INSPECTION_TASK_RECIPIENT.create({
      recipientId: recipientOld.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<PropertyInspectionTaskRecipientDto>(
    request,
    typeFromApi,
  );
};

export const deletePropertyInspectionTaskRecipient = async (
  recipientId: string,
  bearerToken: string,
): Promise<RequestResult<PropertyInspectionTaskRecipientDto>> => {
  const route = `${apiUrl}${APIRoutes.DELETE_PROPERTY_INSPECTION_TASK_RECIPIENT.create(
    {
      recipientId: recipientId,
    },
  )}`;

  const request = new Request(route, {
    method: 'DELETE',
    headers: await makeAuthHeaders(bearerToken),
  });
  return await jsonReq<PropertyInspectionTaskRecipientDto>(
    request,
    typeFromApi,
  );
};

export const deletePropertyInspectionTaskInspection = async (
  propertyInspectionTaskId: string,
  inspectionId: string,
  bearerToken: string,
): Promise<RequestResult<InspectionListDto>> => {
  const route = `${apiUrl}${APIRoutes.DELETE_PROPERTY_INSPECTION_TASK_INSPECTION.create(
    {
      propertyInspectionTaskId: propertyInspectionTaskId,
      inspectionId: inspectionId,
    },
  )}`;

  const request = new Request(route, {
    method: 'DELETE',
    headers: await makeAuthHeaders(bearerToken),
  });
  return await jsonReq<InspectionListDto>(request, typeFromApi);
};

export const getPropertyInspectionTaskInspections = async (
  bearerToken: string,
  propertyInspectionTaskId: string,
): Promise<RequestResult<ResponseOfInspectionListDto>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_INSPECTIONS.create({
      propertyInspectionTaskId,
    })}`,
    bearerToken,
  );

export const getInspectionPhotos = async (
  bearerToken: string,
  inspectionId: string,
): Promise<RequestResult<ResponseOfPropertyInspectionTaskPhotoDto>> => {
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_PHOTOS.create({
      inspectionId,
    })}`,
    bearerToken,
  );
};

export const putInspectionPhotoRemarks = async (
  bearerToken: string,
  inspectionId: string,
  inspectionPhotoId: string,
  photo: PropertyInspectionTaskPhotoDto,
): Promise<RequestResult<string>> => {
  const headers = await makeAuthFileHeaders(bearerToken);
  headers['Content-Type'] = 'application/json';

  const request = new Request(
    `${apiUrl}${APIRoutes.PUT_PROPERTY_INSPECTION_TASK_PHOTO_REMARKS.create({
      inspectionId: inspectionId,
      inspectionPhotoId: inspectionPhotoId,
    })}`,
    {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(photo),
    },
  );
  return jsonReq(request, typeFromApi);
};

export const getInspectionPhotoById = async (
  bearerToken: string,
  inspectionId: string,
  inspectionPhotoId: string,
): Promise<RequestResult<PropertyInspectionTask>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_PROPERTY_INSPECTION_TASK_PHOTO_BY_ID.create({
      inspectionId: inspectionId,
      inspectionPhotoId: inspectionPhotoId,
    })}`,
    bearerToken,
    (result) => {
      result.type = TaskTypes.PropertyInspectionTask;
    },
  );

export const deleteInspectionPhoto = async (
  bearerToken: string,
  inspectionId: string,
  inspectionPhotoId: string,
): Promise<RequestResult<PropertyInspectionTaskPhotoDto>> => {
  const route = `${apiUrl}${APIRoutes.DELETE_PROPERTY_INSPECTION_TASK_PHOTO.create(
    {
      inspectionId: inspectionId,
      inspectionPhotoId: inspectionPhotoId,
    },
  )}`;

  const request = new Request(route, {
    method: 'DELETE',
    headers: await makeAuthHeaders(bearerToken),
  });
  return await jsonReq<PropertyInspectionTaskPhotoDto>(request, typeFromApi);
};
