import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { AccidentInspectionTask } from '../../../../types/types';
import { balModalController } from '../../../../controller/controllers';
import {
  DisabableButton,
  WithCapabilities,
} from '../../../../components/authorization';
import { patchAccidentInspectionTask } from '../../data/requests';
import { BaseTaskRejectModal } from '../../../base-task';

type RejectProps = {
  task: AccidentInspectionTask;
  onRejectSuccess?: (task: RequestResult<AccidentInspectionTask>) => void;
};

const AccidentInspectionTaskRejectModal = (props: RejectProps): JSX.Element => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: BaseTaskRejectModal,
      componentProps: { ...props, patchBaseTask: patchAccidentInspectionTask },
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditAccidentInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <DisabableButton
        onClick={(event) => event.detail == 1 && openModal()}
        size="small"
        color="warning"
        className="mr-2"
      >
        {t('general.buttons.reject')}
      </DisabableButton>
    </WithCapabilities>
  );
};

export default AccidentInspectionTaskRejectModal;
