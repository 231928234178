import {
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import lang from 'suneditor-react/dist/types/lang';
import { PropertyInspectionTaskRecipientDto } from '../../../../../../bat-shared/resource-models';
import { balModalController } from '../../../../controller/controllers';
import PropertyInspectionTaskRecipientEditForm from './property-inspection-task-recipient-edit-form';

export interface PropertyInspectionTaskRecipientEditFormModalProps {
  title: string;
  initialValue: PropertyInspectionTaskRecipientDto;
  initialAddingState: boolean;
  language: lang;
  readonly?: boolean;
  onSave: () => void;
}
const PropertyInspectionTaskRecipientEditFormModal = ({
  title,
  initialValue,
  initialAddingState,
  language,
  readonly,
  onSave,
}: PropertyInspectionTaskRecipientEditFormModalProps): JSX.Element => {
  const [saved, setSaved] = useState<boolean>(false);
  useEffect(() => {
    if (saved) {
      balModalController.dismiss();
    }
  }, [saved]);

  return (
    <>
      <BalModalHeader className="is-full-width create-contacts-header">
        <BalHeading level="h3">{title}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <PropertyInspectionTaskRecipientEditForm
          initialValue={initialValue}
          initialAddingState={initialAddingState}
          language={language}
          readonly={readonly}
          onSave={() => {
            onSave();
            setSaved(true);
          }}
        ></PropertyInspectionTaskRecipientEditForm>
      </BalModalBody>
    </>
  );
};

export default PropertyInspectionTaskRecipientEditFormModal;
