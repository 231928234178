import { BalButton } from '@baloise/design-system-components-react';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import { AppCapabilityNames } from '../../../../types/resource-models';
import {
  PropertyInspectionTaskSummaryFieldsFormProp,
  PropertyInspectionTaskSummaryFieldsModal,
} from './property-inspection-task-summary-fields-modal';

export interface PropertyInspectionTaskSummaryFieldsButtonProps
  extends PropertyInspectionTaskSummaryFieldsFormProp {
  requiredCapabilities: AppCapabilityNames[];
}
export const PropertyInspectionTaskSummaryFieldsButton = ({
  requiredCapabilities,
  task,
  setTask,
  onSave,
}: PropertyInspectionTaskSummaryFieldsButtonProps): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: PropertyInspectionTaskSummaryFieldsModal,
      componentProps: {
        task,
        setTask,
        onSave,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      modalWidth: 775,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={requiredCapabilities}>
      <BalButton
        square
        outlined
        size="small"
        color="info"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
};
