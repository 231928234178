import { concat } from '../../utils';

export const baseUrl = window.location
  ? concat([window.location.protocol, '//', window.location.host], 'none')
  : 'http://localhost:5000';

export const apiUrl = concat([baseUrl, '/api/webapp/v1.0'], 'none');

/**
 * Content type headers for json.
 */
export const JSONHeaders = new Headers({ 'Content-Type': 'text/json' });
