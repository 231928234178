import {
  BalButton,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { capitalize } from '../../utils';

type DeleteConfirmationDialogProps = {
  title: string;
  text: string;
  onDelete: () => Promise<void>;
  onCancel?: () => void;
};

const DeleteConfirmationDialog = ({
  title,
  text,
  onDelete,
  onCancel,
}: DeleteConfirmationDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">{title}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText className="column is-full">{text}</BalText>
        <div className="column is-full is-flex is-justify-content-flex-end pt-2">
          <BalButton
            color="primary-light"
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              balModalController.dismiss();
            }}
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            className="ml-4"
            color="danger"
            disabled={submitting}
            onClick={async () => {
              setSubmitting(true);
              await onDelete();
              setSubmitting(false);
              balModalController.dismiss();
            }}
          >
            {capitalize(t('general.buttons.delete'))}
          </BalButton>
        </div>
      </BalModalBody>
    </>
  );
};

export default DeleteConfirmationDialog;
