import { BalText } from '@baloise/design-system-components-react';
import { convertUtcToLocalWithoutTime } from '../../../utils/date';
import { TaskDetailInfoDisplay } from '../../base-task';
import { FileView } from '../../../components/ui/file-view';
import { PropertyInspectionTaskJournalDto } from '../../../types/resource-models';
import PropertyInspectionTaskJournalHistory from './property-inspection-task-journal-history';
import { useTranslation } from 'react-i18next';
import { PropertyInspectionTask } from '../../../types/types';

export const PropertyInspectionTaskJournalShowView = ({
  journal,
}: {
  journal: PropertyInspectionTaskJournalDto;
  propertyInspectionTask: PropertyInspectionTask;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <PropertyInspectionTaskJournalHistory journalId={journal.id} />
      <TaskDetailInfoDisplay title={t('journal.note')} />
      <div
        dangerouslySetInnerHTML={{
          __html: journal.note ?? '',
        }}
      />
      <TaskDetailInfoDisplay title={''} />
      <TaskDetailInfoDisplay
        title={t('journal.type')}
        text={t(`inspectionTask.journal.journalTypes.${journal.journalType}`)}
      />
      {journal.journalType === 'PhoneEmail' && journal.contactPersonName && (
        <TaskDetailInfoDisplay
          title={t('journal.contactPersonName')}
          text={journal.contactPersonName}
        />
      )}
      <TaskDetailInfoDisplay
        title={t('journal.date')}
        text={convertUtcToLocalWithoutTime(journal.createdAt)}
      />
      <TaskDetailInfoDisplay
        title={t('journal.confidential')}
        text={t(`tristate.${journal.confidential}`)}
      />
      <BalText>
        <strong>{t('general.claim.attachments') as string}</strong>
      </BalText>
      {journal.attachments?.map((file, index) => {
        return (
          <div className="has-background-grey-light mb-3" key={index}>
            {file !== undefined && (
              <>
                <FileView file={file} />
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
