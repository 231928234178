import { Props } from '@baloise/design-system-components/dist/types/props';
import { BalButton } from '@baloise/design-system-components-react';
import { WithCapabilitiesErrorProps } from '.';

type DisabableButtonProps = WithCapabilitiesErrorProps & {
  children: React.ReactNode;
  className?: string | undefined;
  color?: Props.BalButtonColor;
  icon?: string;
  outlined?: boolean;
  onClick?: React.MouseEventHandler<HTMLBalButtonElement> | undefined;
  size?: 'small' | '';
};

export const DisabableButton = ({
  withCapabilitiesError,
  ...props
}: DisabableButtonProps): JSX.Element => (
  <BalButton
    className={props.className}
    color={props.color}
    icon={props.icon}
    outlined={props.outlined}
    onClick={props.onClick}
    // baloise design system cannot handle small and flat. Current workaround for design system 12
    flat={props.size === 'small'}
    disabled={withCapabilitiesError}
  >
    {props.size === 'small' ? (
      <div className="px-1">{props.children}</div>
    ) : (
      <>{props.children}</>
    )}
  </BalButton>
);
