import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { authRequest, authProvider } from '../data/auth';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const useToken = (): string => {
  const { instance, accounts } = useMsal();
  const account = instance.getActiveAccount();
  const [token, setToken] = useState('');

  useEffect(() => {
    if (account ?? accounts[0]) {
      const accessTokenRequest = {
        scopes: authRequest.scopes,
        account: account ?? accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (response) => {
          setToken(response.accessToken);
        })
        .catch((error) => {
          // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react#acquire-a-token-with-a-redirect
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }, [account, accounts, instance]);

  return token;
};

// To be used outside a react component.
export const getToken = async (): Promise<string> => {
  const account = authProvider.getAllAccounts()[0];
  if (account) {
    const accessTokenRequest = {
      scopes: authRequest.scopes,
      account: account,
    };
    try {
      const response = await authProvider.acquireTokenSilent(
        accessTokenRequest,
      );
      return response.accessToken;
    } catch (error) {
      // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react#acquire-a-token-with-a-redirect
      if (error instanceof InteractionRequiredAuthError) {
        authProvider.acquireTokenRedirect(accessTokenRequest);
      }
      return '';
    }
  } else {
    return '';
  }
};
