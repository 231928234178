import { APIRoutes } from '../../../router/router';
import { apiUrl } from '../../../data/fetch/base';
import { jsonGet, RequestResult, jsonReq } from '../../../data';
import {
  Craftsman,
  CraftsmanDetail,
  CraftsmansListResponse,
  CraftsmansResponse,
  WorkCategories,
} from '../../../types/types';
import { makeAuthHeaders, typeFromApi } from '../../../data/fetch/requests';
import {
  AddressDto,
  CraftsmanChangeDto,
  CraftsmanFilterCriteria,
  GeoCoordinateDto,
} from '../../../types/resource-models';
import { diff, jsonPatchPathConverter } from 'just-diff';

export const postCraftsMan = async (
  bearerToken: string,
  partnerPost: Craftsman,
): Promise<RequestResult<Craftsman>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.POST_CRAFTSMAN.create({})}`,
    {
      method: 'POST',
      headers: await makeAuthHeaders(bearerToken),
      body: JSON.stringify(partnerPost),
    },
  );
  return jsonReq<Craftsman>(request, typeFromApi);
};

export const getCraftsmans = async (
  bearerToken: string,
  filter: CraftsmanFilterCriteria,
): Promise<RequestResult<CraftsmansResponse>> => {
  const { responsibleUserId, ...rest } = filter;
  return await jsonGet<CraftsmansResponse>(
    `${apiUrl}${APIRoutes.GET_CRAFTSMANS.create({
      query: {
        ...rest,
        ResponsibleUserId: responsibleUserId,
      },
    })}`,
    bearerToken,
  );
};

export const getCraftsmanList = async (
  bearerToken: string,
  filter: CraftsmanFilterCriteria,
): Promise<RequestResult<CraftsmansListResponse>> => {
  const { responsibleUserId, ...rest } = filter;
  const path = 'craftsmans/list';
  return await jsonGet<CraftsmansListResponse>(
    `${apiUrl}${APIRoutes.GET_CRAFTSMANS_LIST.create({
      path: path,
      query: {
        ...rest,
        ResponsibleUserId: responsibleUserId,
      },
    })}`,
    bearerToken,
  );
};

export const getCraftsman = async (
  bearerToken: string,
  networkPartnerId: string,
): Promise<RequestResult<Craftsman>> =>
  jsonGet<Craftsman>(
    `${apiUrl}${APIRoutes.GET_CRAFTSMAN.create({
      networkPartnerId: networkPartnerId,
    })}`,
    bearerToken,
  );

export const getCraftsmanDetail = async (
  bearerToken: string,
  networkPartnerId: string,
): Promise<RequestResult<CraftsmanDetail>> =>
  jsonGet<CraftsmanDetail>(
    `${apiUrl}${APIRoutes.GET_CRAFTSMAN_DETAIL.create({
      networkPartnerId: networkPartnerId,
    })}`,
    bearerToken,
  );

export const patchCraftsman = async (
  bearerToken: string,
  partner: Craftsman,
  partnerNew: Craftsman,
): Promise<RequestResult<Craftsman>> => {
  const patch = JSON.stringify(
    diff(partner, partnerNew, jsonPatchPathConverter),
  );
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_CRAFTSMAN.create({
      networkPartnerId:
        partnerNew.type === 'propertyCraftsman'
          ? partnerNew.propertyCraftsman.id
          : partnerNew.vehicleCraftsman.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<Craftsman>(request, typeFromApi);
};

export const getGeoCoordinates = async (
  bearerToken: string,
  address: AddressDto,
): Promise<RequestResult<GeoCoordinateDto>> =>
  jsonGet<GeoCoordinateDto>(
    `${apiUrl}${APIRoutes.GET_GEOCOORDINATES.create({
      query: {
        HouseNumber: address.houseNumber,
        Street: address.street,
        City: address.city,
        Country: address.country,
        ZipCode: address.zipCode?.toString(),
      },
    })}`,
    bearerToken,
  );

export const archiveCraftsman = async (
  bearerToken: string,
  partnerId: string,
): Promise<RequestResult<boolean>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.DELETE_CRAFTSMAN.create({
      networkPartnerId: partnerId,
    })}`,
    {
      method: 'DELETE',
      headers: await makeAuthHeaders(bearerToken),
    },
  );
  return jsonReq<boolean>(request, typeFromApi);
};

export const getWorkCategories = async (
  bearerToken: string,
): Promise<RequestResult<WorkCategories>> =>
  jsonGet<WorkCategories>(
    `${apiUrl}${APIRoutes.GET_WORKCATEGORIES.create({})}`,
    bearerToken,
  );

export const getNetworkPartnersAllHistory = async (
  bearerToken: string,
  craftsmanId: string,
): Promise<RequestResult<CraftsmanChangeDto[]>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_CRAFTSMAN_ALL_HISTORY.create({
      craftsmanId: craftsmanId,
    })}`,
    bearerToken,
  );
