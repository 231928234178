import { BaseTask } from '../../../../types/types';
import { Translation } from 'react-i18next';
import { InfoDisplay } from '../../../../components/ui';

type AdditionalInfoProps = {
  task: BaseTask;
};

export const BaseTaskAditionalInfo = ({
  task,
}: AdditionalInfoProps): JSX.Element => {
  return (
    <Translation>
      {(t) => {
        return (
          <>
            <InfoDisplay
              title={t('general.claim.what')}
              text={task.what}
              className="pb-1"
            />
            <InfoDisplay
              title={t('general.claim.remarks')}
              text={task.remarks}
              className="pb-1"
            />
          </>
        );
      }}
    </Translation>
  );
};
