import {
  BalButton,
  BalButtonGroup,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import lang from 'suneditor-react/dist/types/lang';
import {
  BalDatepickerFormField,
  BalTextareaFormField,
  BalTextFormField,
} from '../../../../../components/input';
import BalSelectFormField from '../../../../../components/input/bal-select-form-field';
import {
  ErrorToast,
  ToastNotification,
} from '../../../../../components/toast-notification';
import { RequestResult } from '../../../../../data';
import { useToken } from '../../../../../hooks';
import {
  InsuranceSurveyDto,
  AttachmentDto,
  InsuranceSurveyType,
  InsuranceSurveyResponse,
  InsuranceSurveyListDto,
} from '../../../../../types/resource-models';
import {
  TranslatedSelection,
  AttachmenEntity,
} from '../../../../../types/types';
import { Globals } from '../../../../../utils';
import { BaseTaskAttachments } from '../../../../base-task';
import { deleteFile, FileUpload } from '../../../../files';
import {
  patchInsuranceSurvey,
  postInsuranceSurvey,
  sendInsuranceSurveyToAdos,
} from '../data/requests';
import InsuranceSurveyDeleteButton from './toolbar/insurance-survey-delete-button';
import { CancelButton, FileViewModal } from '../../../../../components/ui';
import { getInsuranceSurveyReport } from '../../../../files/data/requests';
export interface InsuranceSurveyEditProps {
  initialValue: RequestResult<InsuranceSurveyDto>;
  initialAddingState: boolean;
  language?: lang;
  readonly?: boolean;
  onSave?: (insuranceSurveyId?: string) => void;
  onDeleteInsuranceSurvey: (
    insuranceSurvey: InsuranceSurveyListDto,
  ) => Promise<void>;
}
export const InsuranceSurveyEditForm = ({
  initialValue,
  initialAddingState,
  language,
  readonly,
  onSave,
  onDeleteInsuranceSurvey,
}: InsuranceSurveyEditProps): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [sendingToAdos, setSendingToAdos] = useState<boolean>(false);
  const [insuranceSurvey, setInsuranceSurvey] =
    useState<RequestResult<InsuranceSurveyDto>>(initialValue);

  const [positiveResponse, setPositiveResponse] = useState<string | undefined>(
    initialValue?.status === 'success'
      ? initialValue.localValue.positiveResponse
      : undefined,
  );

  const [addingState, setAddingState] = useState<boolean>(initialAddingState);
  const insuranceSurveyTypes: TranslatedSelection[] =
    Globals.InsuranceSurveyTypes.map((insuranceSurveyType) => ({
      value: insuranceSurveyType,
      translatedValue: t(
        `investigationTask.insuranceSurveyTypes.${insuranceSurveyType}`,
      ),
    }));

  const insuranceSurveyResponses: TranslatedSelection[] =
    Globals.InsuranceSurveyResponses.map((insuranceSurveyResponse) => ({
      value: insuranceSurveyResponse,
      translatedValue: t(
        `investigationTask.insuranceSurveyResponses.${insuranceSurveyResponse}`,
      ),
    }));

  const token = useToken();
  const removeFile = async (fileName: string) => {
    if (
      insuranceSurvey.status == 'success' &&
      insuranceSurvey.localValue.attachments
    ) {
      const attachmentToDelete = insuranceSurvey.localValue.attachments.find(
        (attachment: AttachmentDto) => attachment.fileName == fileName,
      );
      if (attachmentToDelete) {
        const result = await deleteFile(token, attachmentToDelete);
        if (
          result.status === 'success-no-value' ||
          result.status === 'success'
        ) {
          const newAttachments = insuranceSurvey.localValue.attachments.filter(
            (attachment: AttachmentDto) => attachment.fileName !== fileName,
          );
          setInsuranceSurvey(
            produce(insuranceSurvey, (draftState) => {
              draftState.localValue.attachments = newAttachments;
            }),
          );
        }
        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      }
    }
  };

  const postLocalInsuranceSurvey = async () => {
    if (insuranceSurvey.status === 'success') {
      const response = await postInsuranceSurvey(
        insuranceSurvey.localValue,
        token,
      );
      if (response.status === 'error') {
        toast(ErrorToast(response.errorValue));
      } else {
        setInsuranceSurvey(response);
        setAddingState(false);
      }
    }
  };

  const patchLocalInsuranceSurvey = async () => {
    if (insuranceSurvey.status === 'success') {
      const response = await patchInsuranceSurvey(
        insuranceSurvey.value,
        insuranceSurvey.localValue,
        token,
      );
      if (response.status === 'error') {
        toast(ErrorToast(response.errorValue));
      }
    }
  };

  useEffect(() => {
    if (
      insuranceSurvey.status === 'success' &&
      insuranceSurvey.localValue.positiveResponse !== positiveResponse
    ) {
      setInsuranceSurvey(
        produce(insuranceSurvey, (draft) => {
          draft.localValue.positiveResponse = positiveResponse;
        }),
      );
    }
  }, [insuranceSurvey, positiveResponse]);

  const isInvalidToSave = () => {
    return readonly || !methods.formState.isValid || saving;
  };

  const save = async () => {
    if (addingState) {
      await postLocalInsuranceSurvey();
    } else {
      await patchLocalInsuranceSurvey();
    }
  };

  return (
    <>
      {insuranceSurvey.status === 'success' && (
        <form
          onSubmit={methods.handleSubmit(async () => {
            if (!saving) {
              setSaving(true);
              await save();
              if (onSave) {
                onSave(insuranceSurvey.localValue?.id);
              }
              setSaving(false);
            }
          })}
        >
          <div className="rows is-multiline is-gapless is-full-width mt-1">
            <div className="columns is-multiline mt-1">
              <div className="column is-6 is-full-width">
                <div
                  data-tooltip={t(
                    'investigationTask.insuranceSurvey.previewInsuranceSurveyEntryTooltip',
                  )}
                  data-tooltip-location="bottom"
                  key="previewJournalEntry"
                  className="tooltip"
                >
                  <FileViewModal
                    notOutlined={true}
                    disabled={isInvalidToSave()}
                    label={`${t(
                      'investigationTask.insuranceSurvey.entityName',
                    )}_${insuranceSurvey.localValue.startDate
                      .toLocaleUpperCase()
                      .slice(0, 10)}.pdf`}
                    buttonText={t(
                      'investigationTask.insuranceSurvey.showInsuranceSurveyEntry',
                    )}
                    fileId={insuranceSurvey.localValue.id}
                    contentType="application/pdf"
                    getFile={async () => {
                      await save();
                      return getInsuranceSurveyReport(
                        token,
                        insuranceSurvey.localValue.id,
                      );
                    }}
                    report
                  />
                </div>
              </div>
              <div className="column is-6">
                <div
                  data-tooltip={t(
                    'investigationTask.insuranceSurvey.sendInsuranceSurveyEntryToAdosTooltip',
                  )}
                  data-tooltip-location="bottom"
                  key="previewJournalEntry"
                  className="tooltip"
                >
                  <BalButton
                    className="is-full-width"
                    size="small"
                    color="info"
                    disabled={isInvalidToSave()}
                    loading={sendingToAdos}
                    onClick={async (event) => {
                      if (event.detail == 1) {
                        setSendingToAdos(true);
                        await save();
                        sendInsuranceSurveyToAdos(
                          token,
                          insuranceSurvey.localValue.id,
                        ).then((result) => {
                          if (result.status === 'success') {
                            toast(
                              ToastNotification({
                                message: t(
                                  'investigationTask.insuranceSurvey.sentInsuranceSurveyEntryToAdosSuccessfully',
                                ),
                                color: 'success',
                              }),
                            );
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                          setSendingToAdos(false);
                        });
                      }
                    }}
                  >
                    {t(
                      'investigationTask.insuranceSurvey.sendInsuranceSurveyEntryToAdos',
                    )}
                  </BalButton>
                </div>
              </div>
            </div>
            <BalDatepickerFormField
              disabled={readonly}
              required={t('error.mandatoryField')}
              title={t('investigationTask.insuranceSurvey.startDate')}
              className="mb-4"
              controllerName={'startDate'}
              methods={methods}
              placeholder={t('investigationTask.insuranceSurvey.startDate')}
              value={insuranceSurvey.localValue.startDate.slice(0, 10)}
              onBalChange={(e: CustomEvent<null | string | undefined>) => {
                setInsuranceSurvey(
                  produce(insuranceSurvey, (draftState) => {
                    draftState.localValue.startDate = e.detail ?? '';
                  }),
                );
              }}
            />
            <BalSelectFormField
              disabled={readonly}
              required={t('error.mandatoryField')}
              controllerName={'type'}
              methods={methods}
              className="row"
              value={insuranceSurvey.localValue.insuranceSurveyType}
              values={insuranceSurveyTypes}
              title={t('investigationTask.insuranceSurvey.insuranceSurveyType')}
              placeholder={t('general.buttons.select')}
              alphabeticallySorted
              onChange={(e) => {
                setInsuranceSurvey(
                  produce(insuranceSurvey, (draftState) => {
                    if (e && e.detail) {
                      draftState.localValue.insuranceSurveyType =
                        e.detail as InsuranceSurveyType;
                    }
                  }),
                );
              }}
            />
            <BalTextFormField
              disabled={readonly}
              required={t('error.mandatoryField')}
              controllerName={'insurance'}
              control={methods.control}
              title={t('investigationTask.insuranceSurvey.insurance')}
              placeholder={t('investigationTask.insuranceSurvey.insurance')}
              value={insuranceSurvey.localValue.insurance}
              onChange={(e: CustomEvent<string | undefined>) => {
                if (e.detail || e.detail === '') {
                  setInsuranceSurvey(
                    produce(insuranceSurvey, (draftState) => {
                      draftState.localValue.insurance = e.detail ?? '';
                    }),
                  );
                }
              }}
            />
            <BalSelectFormField
              disabled={readonly}
              required={t('error.mandatoryField')}
              controllerName={'response'}
              methods={methods}
              className="row"
              value={insuranceSurvey.localValue.response}
              values={insuranceSurveyResponses}
              title={t('investigationTask.insuranceSurvey.response')}
              placeholder={t('general.buttons.select')}
              alphabeticallySorted
              onChange={(e) => {
                setInsuranceSurvey(
                  produce(insuranceSurvey, (draftState) => {
                    if (e && e.detail) {
                      draftState.localValue.response =
                        e.detail as InsuranceSurveyResponse;
                    }
                  }),
                );
              }}
            />
            {insuranceSurvey.localValue.response === 'Positive' && (
              <>
                <BalTextareaFormField
                  disabled={readonly}
                  required={t('error.mandatoryField')}
                  controllerName={'note'}
                  methods={methods}
                  richText
                  richTextHeight="200px"
                  language={language}
                  placeholder={t(
                    'investigationTask.insuranceSurvey.positiveResponse',
                  )}
                  title={t(
                    'investigationTask.insuranceSurvey.positiveResponse',
                  )}
                  value={positiveResponse}
                  onChange={(text: string) => {
                    setPositiveResponse(text);
                  }}
                />
                <BalDatepickerFormField
                  required={t('error.mandatoryField')}
                  title={t(
                    'investigationTask.insuranceSurvey.positiveResponseAtDate',
                  )}
                  className="mb-4"
                  controllerName={'positiveResponseAtDate'}
                  methods={methods}
                  placeholder={t(
                    'investigationTask.insuranceSurvey.positiveResponseAtDate',
                  )}
                  value={insuranceSurvey.localValue.positiveResponseAtDate?.slice(
                    0,
                    10,
                  )}
                  onBalChange={(e: CustomEvent<null | string | undefined>) => {
                    setInsuranceSurvey(
                      produce(insuranceSurvey, (draftState) => {
                        draftState.localValue.positiveResponseAtDate =
                          e.detail ?? '';
                      }),
                    );
                  }}
                />
              </>
            )}
            <div className="column">
              <div className="scroll-bar-minimal">
                <BaseTaskAttachments
                  disabled={readonly}
                  maximumHeight="370px"
                  attachments={insuranceSurvey.localValue.attachments}
                  removeFile={removeFile}
                />
              </div>
              <div>
                <FileUpload
                  disabled={readonly || !methods.formState.isValid || saving}
                  entityType="insuranceSurvey"
                  className="pb-0"
                  entity={insuranceSurvey as RequestResult<AttachmenEntity>}
                  setEntity={
                    setInsuranceSurvey as Dispatch<
                      RequestResult<AttachmenEntity>
                    >
                  }
                  hasFileList={false}
                  copyFromClipboard={true}
                  beforePostFile={async () => {
                    if (addingState) {
                      await postLocalInsuranceSurvey();
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <BalButtonGroup position="right">
                {!initialAddingState && onDeleteInsuranceSurvey && (
                  <InsuranceSurveyDeleteButton
                    currentInsuranceSurvey={insuranceSurvey.value}
                    onDeleteInsuranceSurvey={onDeleteInsuranceSurvey}
                    textButton
                  />
                )}
                <CancelButton />
                <BalButton
                  elementType="submit"
                  color="info"
                  disabled={isInvalidToSave()}
                >
                  {t('general.buttons.save')}
                </BalButton>
              </BalButtonGroup>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
