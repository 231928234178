import {
  BalCard,
  BalHeading,
  BalText,
} from '@baloise/design-system-components-react';
import { RefObject } from 'react';
import '../../../styles/general.scss';
import '../../../styles/map.scss';
import { Craftsman } from '../../../types/types';
import { formatDateTime } from '../../../utils/date';
import { CreateEditNetworkPartner as CreateEditPartner } from './create-edit-network-partner';
import { RequestResult } from '../../../data';
import {
  NetworkPartnerNumber,
  PropertyPartnerOverlay,
  VehiclePartnerOverlay,
} from './network-partner-overlay-modules';
import { NetworkPartnerEditButtonGroup } from './network-partner-edit-button';
import { getCraftsman } from '../data/requests';
import { useToken } from '../../../hooks';
import LocateButton from '../../../components/ui/locate-button';

type NetworkOverlayProps = {
  partner: RequestResult<Craftsman>;
  setPartner: React.Dispatch<RequestResult<Craftsman>>;
  fillSpace?: boolean;
  slideIn?: boolean;
  refNetworkOverlay?: RefObject<HTMLBalCardElement>;
  replaceCraftsmanInList?: (newCraftsman: RequestResult<Craftsman>) => void;
};

export const NetworkPartnerOverlay = ({
  partner,
  setPartner,
  fillSpace,
  slideIn,
  refNetworkOverlay,
  replaceCraftsmanInList,
}: NetworkOverlayProps): JSX.Element => {
  const token = useToken();
  if (partner.status === 'success') {
    const craftsman =
      partner.localValue.type === 'propertyCraftsman'
        ? partner.localValue.propertyCraftsman
        : partner.localValue.vehicleCraftsman;
    return (
      <BalCard
        ref={refNetworkOverlay}
        id="overlay"
        className={slideIn ? 'animated-overlay-in' : ''}
      >
        <BalHeading space="none" level="h4" className="overlay-title p-5">
          <div className="is-flex is-flex-direction-column is-full-width columnsl">
            <div>
              <NetworkPartnerNumber partner={partner} />
              <LocateButton partner={partner} />
            </div>
            <BalText size="small" className="mt-1 has-text-black">
              {formatDateTime(craftsman.createdAt ?? '')}
            </BalText>
            <div className="is-full-width mb-3 mt-3 bottom-blue-line"></div>
          </div>
          <div className="is-full-width is-flex is-justify-content-space-between is-align-items-center">
            <CreateEditPartner
              key={craftsman.id}
              action="edit"
              partnerId={craftsman.id}
              onChangeSuccess={async (partnerId: string) => {
                const craftsmanResult = await getCraftsman(token, partnerId);
                if (craftsmanResult.status == 'success') {
                  setPartner(craftsmanResult);
                  if (replaceCraftsmanInList) {
                    replaceCraftsmanInList(craftsmanResult);
                  }
                }
              }}
            />
            <NetworkPartnerEditButtonGroup
              partner={partner}
              setPartner={setPartner}
            />
          </div>
        </BalHeading>
        <div className="overlay-content mb-5 scroll-bar-minimal">
          {partner.localValue.type === 'propertyCraftsman' ? (
            <PropertyPartnerOverlay partner={partner} />
          ) : (
            <VehiclePartnerOverlay partner={partner} />
          )}
        </div>
      </BalCard>
    );
  } else {
    return fillSpace ? (
      <div className="overlay slide-in is-pulled-right"> </div>
    ) : (
      <div className="overlay-space-slide"></div>
    );
  }
};
