import { BalButton } from '@baloise/design-system-components-react';
import { WithCapabilities } from '../../../../components/authorization';
import { balModalController } from '../../../../controller/controllers';
import {
  VehicleTaskSendCometFormProps,
  VehicleTaskSendCometModal,
} from './vehicle-task-send-comet-modal';
import { useTranslation } from 'react-i18next';

export const VehicleTaskSendCometButton = ({
  task,
}: VehicleTaskSendCometFormProps): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: VehicleTaskSendCometModal,
      componentProps: {
        task,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      modalWidth: 775,
    });
    return modal.present();
  };
  const { t } = useTranslation();

  return (
    <WithCapabilities requiredCapabilities={['EditVehicleTask']}>
      <BalButton
        onClick={(event) => event.detail == 1 && openModal()}
        size="small"
        color="info"
        outlined={true}
      >
        {t('overlay.sendTotalLossToComet')}
      </BalButton>
    </WithCapabilities>
  );
};
