import {
  BalButtonGroup,
  BalButton,
  BalText,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { InsuranceSurveyCreateAllDto } from '../../../../../../../bat-shared/resource-models';
import { BalDatepickerFormField } from '../../../../../components/input';
import BalSelectFormField from '../../../../../components/input/bal-select-form-field';
import { ErrorToast } from '../../../../../components/toast-notification';
import { useToken } from '../../../../../hooks';
import { InsuranceSurveyType } from '../../../../../types/resource-models';
import { TranslatedSelection } from '../../../../../types/types';
import { Globals } from '../../../../../utils';
import { postAllInsuranceSurveys } from '../data/requests';
import { CancelButton } from '../../../../../components/ui';

export interface InsuranceSurveyCreateAllProps {
  investigationTaskId: string;
  onSave?: () => void;
}
export const InsuranceSurveyCreateAllForm = ({
  investigationTaskId,
  onSave,
}: InsuranceSurveyCreateAllProps): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [insuranceSurveyCreateAllDto, setInsuranceSurveyCreateAllDto] =
    useState<InsuranceSurveyCreateAllDto>({
      investigationTaskId,
      insuranceSurveyType: 'PropertyAndVehicle',
      startDate: new Date().toISOString(),
    });

  const insuranceSurveyTypes: TranslatedSelection[] =
    Globals.InsuranceSurveyTypes.map((insuranceSurveyTypeItem) => ({
      value: insuranceSurveyTypeItem,
      translatedValue: t(
        `investigationTask.insuranceSurveyTypes.${insuranceSurveyTypeItem}`,
      ),
    }));

  const token = useToken();

  const createAllInsuranceSurveys = async () => {
    const response = await postAllInsuranceSurveys(
      insuranceSurveyCreateAllDto,
      token,
    );
    if (response.status === 'error') {
      toast(ErrorToast(response.errorValue));
    }
  };

  return (
    <>
      <form
        onSubmit={methods.handleSubmit(async () => {
          if (!saving) {
            setSaving(true);
            await createAllInsuranceSurveys();
            if (onSave) {
              onSave();
            }
            setSaving(false);
          }
        })}
      >
        <div className="rows is-multiline is-gapless is-full-width mt-1">
          <BalText className="row mt-4" color="primary" bold>
            {t('investigationTask.insuranceSurvey.createAllSubtitle')}
          </BalText>
          <BalDatepickerFormField
            required={t('error.mandatoryField')}
            title={t('investigationTask.insuranceSurvey.startDate')}
            className="row mb-4"
            controllerName={'startDate'}
            methods={methods}
            placeholder={t('investigationTask.insuranceSurvey.startDate')}
            value={insuranceSurveyCreateAllDto.startDate.slice(0, 10)}
            onBalChange={(e: CustomEvent<null | string | undefined>) => {
              setInsuranceSurveyCreateAllDto(
                produce(insuranceSurveyCreateAllDto, (draftState) => {
                  draftState.startDate = e.detail ?? '';
                }),
              );
            }}
          />
          <BalSelectFormField
            required={t('error.mandatoryField')}
            controllerName={'type'}
            methods={methods}
            className="row"
            value={insuranceSurveyCreateAllDto.insuranceSurveyType}
            values={insuranceSurveyTypes}
            title={t('investigationTask.insuranceSurvey.insuranceSurveyType')}
            placeholder={t('general.buttons.select')}
            alphabeticallySorted
            onChange={(e) => {
              setInsuranceSurveyCreateAllDto(
                produce(insuranceSurveyCreateAllDto, (draftState) => {
                  if (e && e.detail) {
                    draftState.insuranceSurveyType =
                      e.detail as InsuranceSurveyType;
                  }
                }),
              );
            }}
          />
          <BalText className="row mt-2" color="primary" bold>
            {t('investigationTask.insuranceSurvey.createAllExplanation')}
          </BalText>
        </div>
        <div>
          <BalButtonGroup position="right">
            <CancelButton />
            <BalButton
              elementType="submit"
              color="info"
              disabled={!methods.formState.isValid || saving}
            >
              {t('investigationTask.insuranceSurvey.createAllStart')}
            </BalButton>
          </BalButtonGroup>
        </div>
      </form>
    </>
  );
};
