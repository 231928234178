import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { WithCapabilities } from '../../../../components/authorization';
import { InfoDisplay } from '../../../../components/ui';
import { balModalController } from '../../../../controller/controllers';
import { useToken } from '../../../../hooks';
import { ExpenseDto } from '../../../../types/resource-models';
import { formatDateTime } from '../../../../utils/date';
import { capitalize } from 'lodash';

const DeleteExpensesModal = ({
  expense,
  updateExpense,
  onDelete,
}: {
  expense: ExpenseDto;
  taskId: string;
  updateExpense: (
    expense: ExpenseDto,
    created: boolean,
    deleted: boolean,
    token: string,
  ) => void;
  onDelete?: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  return (
    <div>
      <BalModalHeader>
        <BalHeading space="none" level="h3" subtitle>
          {t('expense.deleteExpense')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>{t('expense.deleteConfirmation')}</BalText>
        <InfoDisplay
          title={t('expense.createdAt')}
          text={expense.createdAt ? formatDateTime(expense.createdAt) : ''}
          className="mt-3"
        />
        <InfoDisplay
          title={t('expense.expenseName')}
          text={expense.expenseName ?? ''}
        />
        <InfoDisplay
          title={t('expense.expenseSum')}
          text={expense.expenseSum.toString() ?? ''}
        />
        <InfoDisplay
          title={t('expense.expenseType')}
          text={t(`expense.expenseTypes.${expense.expenseType}`) as string}
        />
        <BalButtonGroup position="right">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            color="danger"
            onClick={() => {
              updateExpense(expense, false, true, token);
              balModalController.dismiss();
              onDelete && setTimeout(() => onDelete(), 1000);
            }}
          >
            {t('general.buttons.delete')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

const DeleteExpensesButton = ({
  expense,
  taskId,
  updateExpense,
  disabled,
  textButton,
  onDelete,
}: {
  expense: ExpenseDto;
  taskId: string;
  updateExpense: (
    expense: ExpenseDto,
    created: boolean,
    deleted: boolean,
    token: string,
  ) => void;
  disabled?: boolean;
  textButton?: boolean;
  onDelete: () => void;
}): JSX.Element => {
  const t = useTranslation().t;
  const openModal = async () => {
    const modal = await balModalController.create({
      component: DeleteExpensesModal,
      componentProps: {
        expense: expense,
        taskId: taskId,
        updateExpense: updateExpense,
        onDelete: onDelete,
      },
      cssClass: 'center-modal',
    });
    return modal.present();
  };
  return (
    <WithCapabilities
      requiredCapabilities={['EditFraudInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      {textButton ? (
        <BalButton
          className="ml-4"
          color="danger"
          onClick={async () => openModal()}
        >
          {capitalize(t('general.buttons.delete'))}
        </BalButton>
      ) : (
        <BalButton
          className="ml-2"
          disabled={disabled}
          outlined
          square
          size="small"
          color="danger"
          icon="minus"
          onClick={async () => openModal()}
        />
      )}
    </WithCapabilities>
  );
};

export default DeleteExpensesButton;
