import { BalButton } from '@baloise/design-system-components-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Route, QueryParamDefault } from 'react-route-type';
import { WithCapabilitiesErrorProps } from '.';

type LinkButtonProps<
  Parts extends string,
  QueryParams extends QueryParamDefault,
> = WithCapabilitiesErrorProps & {
  /**
   * The route that should be visited on a click on the button.
   */
  to: Route<Parts, QueryParams>;
  /**
   * Parameters to the route in `to`. Pass an empty object (`{}`) if
   * the route doesn't take any parameters.
   */
  linkParams: QueryParams;
  /**
   * Color to display inside of the button.
   */
  color: 'primary' | 'info' | 'link' | 'info-light' | 'light' | 'primary-light';
  /**
   * Elements to display inside of the button.
   */
  children: React.ReactNode;
  /**
   * If the button is disabled, the user can't click on the button.
   */
  icon?: string;
  /**
   * Should the button be rendered in a outlined state?
   */
  outlined?: boolean;
  buttonClassName?: string;
};

/**
 * A button that changes to a URL when clicked.
 * Can be in a disabled state, where the button
 * can't be clicked.
 * @param props
 */
export const DisabableLinkButton = <
  Parts extends string,
  QueryParams extends QueryParamDefault,
>({
  withCapabilitiesError,
  ...props
}: LinkButtonProps<Parts, QueryParams>): JSX.Element => {
  const { to, linkParams, icon, color, outlined, children, buttonClassName } =
    props;
  const button = (
    <BalButton
      className={buttonClassName}
      color={color}
      outlined={!outlined}
      icon={icon}
      disabled={withCapabilitiesError}
    >
      {children}
    </BalButton>
  );

  return withCapabilitiesError ? (
    button
  ) : (
    <Link to={to.create(linkParams)}>{button}</Link>
  );
};
