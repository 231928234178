import '../../../../styles/dropdown-button.scss';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { VehicleTask } from '../../../../types/types';
import {
  BaseTaskStatus,
  CraftsmanListDto,
  VehicleTaskStatusDto,
} from '../../../../types/resource-models';
import { useToken } from '../../../../hooks';
import produce from 'immer';
import { BaseTaskInProgressButton } from '../../../base-task';
import { patchVehicleTask } from '../../data/requests';
import { useCallback, useEffect, useState } from 'react';
import { balModalController } from '../../../../controller/controllers';
import { NetworkPartnerSelector } from '../../../network-partners/components';

type State = {
  title: string;
  state: BaseTaskStatus;
};

type VehicleTaskInProgressButtonProps = {
  task: VehicleTask;
  onChangeSuccess?: (task: RequestResult<VehicleTask>) => void;
  text?: string;
};

export const VehicleTaskInProgressButton = ({
  task,
  onChangeSuccess,
  text,
}: VehicleTaskInProgressButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const states: State[] = [
    {
      state: 'InProgressLocal',
      title: t('baseTaskStatus.InProgressLocal'),
    },
    {
      state: 'InProgressVideo',
      title: t('baseTaskStatus.InProgressVideo'),
    },
    {
      state: 'InProgressOffice',
      title: t('baseTaskStatus.InProgressOffice'),
    },
  ];

  const craftsmanSelectorTitle = t('networkPartners.selectorTitle');

  const [settingState, setSettingState] = useState<BaseTaskStatus | undefined>(
    undefined,
  );

  const onChangeCallback = useCallback(
    (task: RequestResult<VehicleTask>) => {
      if (onChangeSuccess) {
        onChangeSuccess(task);
      }
    },
    [onChangeSuccess],
  );

  useEffect(() => {
    if (token && settingState === 'InProgressWithPartner') {
      const openCraftsmanSelectorModal = async () => {
        const modal = await balModalController.create({
          component: NetworkPartnerSelector,
          modalWidth: 1024,
          componentProps: {
            onSelectedSuccess: onCraftsmanSelected,
            title: craftsmanSelectorTitle,
            craftsmanFilter: {
              vehicleCraftsmanOnly: true,
              isExternalPartner: true,
            },
          },
          cssClass: 'center-modal',
          backdropDismiss: false,
        });
        modal.onDidDismiss().then(() => {
          setSettingState(undefined);
        });
        modal.present();
      };

      const changeState = async (
        state: BaseTaskStatus,
        assigneeExternalId: string | undefined,
      ) => {
        const result = await patchVehicleTask(
          task,
          produce(task, (draftState) => {
            draftState.status = state as VehicleTaskStatusDto;
            draftState.assigneeExternalId = assigneeExternalId;
          }),
          token,
        );

        if (result.status === 'success' && onChangeCallback) {
          onChangeCallback(result);
        }
      };

      const onCraftsmanSelected = async (craftsman: CraftsmanListDto) => {
        if (settingState) {
          await changeState(settingState, craftsman?.company?.id);
          setSettingState(undefined);
        }
      };

      openCraftsmanSelectorModal();
    }
  }, [settingState, onChangeCallback, task, token, craftsmanSelectorTitle]);

  const onChangeState = async (state: BaseTaskStatus) => {
    if (state === 'InProgressWithPartner') {
      setSettingState(state); // To store the state selected to be applied when selected craftsman -> https://dev.to/shareef/react-usestate-hook-is-asynchronous-1hia
    } else {
      const result = await patchVehicleTask(
        task,
        produce(task, (draftState) => {
          draftState.status = state as VehicleTaskStatusDto;
        }),
        token,
      );

      if (result.status === 'success' && onChangeSuccess) {
        onChangeSuccess(result);
      }
    }
  };

  return (
    <BaseTaskInProgressButton
      states={states}
      onChangeState={onChangeState}
      text={text}
    />
  );
};
