import {
  ColDef,
  ColumnApi,
  ColumnResizedEvent,
  GridReadyEvent,
  SortChangedEvent,
  ValueGetterFunc,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BalPagination } from '@baloise/design-system-components-react';
import '../../styles/general.scss';
import { useOnClickOutside, useToken } from '../../hooks';
import { RequestResult } from '../../data';
import {
  Craftsman,
  PropertyCraftsman,
  VehicleCraftsman,
} from '../../types/types';
import { concat } from '../../utils';
import NetworkMap from './network-map';
import { CombinedState } from '../../features/network-partners';
import { ResultRenderer } from '../../components/ui';
import { useGridOptions } from './network-table-grid-options';
import produce from 'immer';
import {
  postColumnsOrderUserTableSettings,
  postColumnsWidthUserTableSettings,
  postSortUserTableSettings,
} from '../../features/user/data/requests';
import { UserContext } from '../../context';
import { useTranslation } from 'react-i18next';

interface ISortingState {
  field: string;
  direction: 'asc' | 'desc';
  getter: ValueGetterFunc | undefined;
}

const NetworkTable = ({
  combinedState,
  setCombinedState,
  setPartner,
  onGridReady,
  refNetworkOverlay,
  showMap,
}: {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  setPartner: React.Dispatch<React.SetStateAction<RequestResult<Craftsman>>>;
  refNetworkOverlay: RefObject<HTMLBalCardElement>;
  onGridReady: (params: GridReadyEvent) => void;
  showMap: boolean;
}): JSX.Element => {
  const pageSize = 50;
  const { t } = useTranslation();
  const [selectedPartner, setSelectedPartner] = useState<string>('');
  const refTable = useRef(null);
  const partners = combinedState.craftsmans;
  const [page, setPage] = useState<number>(1);
  const { user, setUser } = useContext(UserContext);
  const token = useToken();

  const onGridReadyLocal = (params: GridReadyEvent) => {
    const gridApi = params.api;
    const columnsApi = params.columnApi;
    // If the filter is different to the default, will show it in the table.
    if (
      combinedState.filter.orderField &&
      (combinedState.filter.orderField !==
        combinedState.defaultFilter.orderField ||
        combinedState.filter.orderBy !== combinedState.defaultFilter.orderBy)
    ) {
      const colDef = gridApi
        .getColumnDefs()
        ?.find((x) => (x as ColDef).field == combinedState.filter.orderField);
      if (colDef) {
        columnsApi.applyColumnState({
          state: [
            {
              colId: (colDef as ColDef).colId ?? colDef.headerName ?? '',
              sort: combinedState.filter.orderBy,
            },
          ],
        });
      }
    }
    if (onGridReady) {
      onGridReady(params);
    }
  };

  const [currentRow, setCurrentRow] = useState<number>(0);
  useEffect(() => {
    setCurrentRow(0);
    setPage(1);
  }, [combinedState]);

  useEffect(() => {
    if (partners.status === 'success') {
      if (selectedPartner === '') setPartner({ status: 'initial' });
      else {
        const partner = partners.value.entities.find(
          (partner: Craftsman) =>
            selectedPartner ===
            (partner.type === 'propertyCraftsman'
              ? (partner as PropertyCraftsman).propertyCraftsman.id
              : (partner as VehicleCraftsman).vehicleCraftsman.id),
        ) as Craftsman;
        setPartner({
          status: 'success',
          value: partner,
          localValue: partner,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner, setSelectedPartner, setPartner]);

  useOnClickOutside([refTable, refNetworkOverlay], () => {
    if (!showMap) {
      const element = document.getElementById('overlay');
      element?.classList.add('animated-overlay-out');
      setSelectedPartner('');
    }
  });

  const [gridOptions] = useGridOptions();

  useEffect(() => {
    if (gridOptions) {
      gridOptions.onRowClicked = (params) => {
        const craftsman = params.data as Craftsman;
        setSelectedPartner(
          craftsman.type === 'propertyCraftsman'
            ? (craftsman as PropertyCraftsman).propertyCraftsman.id
            : (craftsman as VehicleCraftsman).vehicleCraftsman.id,
        );
      };
    }
  }, [gridOptions]);

  const getSorting = (columnApi: ColumnApi): ISortingState | undefined => {
    const columns = columnApi.getAllDisplayedColumns();
    const sortingColumn = columns.find((c) => c.getSort());
    if (sortingColumn) {
      const colDef = sortingColumn.getColDef();
      return {
        field: colDef.field ?? '',
        direction: sortingColumn.getSort() ?? 'asc',
        getter:
          typeof colDef.valueGetter === 'string'
            ? undefined
            : colDef.valueGetter,
      };
    } else {
      // Default ordering.
      return {
        field: 'partnerNumber',
        direction: 'asc',
        getter: undefined,
      };
    }
  };

  return (
    <div className="is-full-height is-flex is-flex-direction-column is-justify-content-space-between scroll-bar-minimal-x">
      <ResultRenderer
        result={combinedState.craftsmans}
        defaultValue={{ totalPages: 1, totalItems: 0, entities: [] }}
        loaded={(partnersResponse) => {
          return (
            <div className="is-full-height is-full-width">
              <div
                className={concat([
                  'position-relative is-full-height is-full-width',
                  showMap ? 'front' : 'back',
                ])}
              >
                <NetworkMap
                  partners={partnersResponse.entities}
                  setPartner={setPartner}
                />
              </div>
              <div
                ref={refTable}
                className={concat([
                  'position-relative network-table task-table is-full-height is-full-width',
                  showMap ? 'back' : 'front',
                ])}
              >
                <AgGridReact
                  suppressCellFocus={true}
                  rowSelection={'single'}
                  onGridReady={onGridReadyLocal}
                  rowData={partnersResponse.entities.slice(
                    (page - 1) * pageSize,
                    page * pageSize,
                  )}
                  gridOptions={gridOptions}
                  onSelectionChanged={(event) => {
                    const selectedNodes = event.api.getSelectedNodes();
                    if (selectedNodes.length > 0) {
                      const node = selectedNodes[0];
                      if (page !== undefined && pageSize !== undefined) {
                        const newItemCount =
                          (node.rowIndex ?? 0) + 1 + pageSize * (page - 1);
                        setCurrentRow(newItemCount);
                      }
                    }
                  }}
                  suppressMultiSort
                  suppressDragLeaveHidesColumns={true}
                  onColumnMoved={async (event) => {
                    if (
                      event.source === 'uiColumnDragged' &&
                      event.type === 'columnMoved' &&
                      event.toIndex !== undefined
                    ) {
                      await postColumnsOrderUserTableSettings(
                        event.columnApi.getAllDisplayedColumns(),
                        'Craftsman',
                        user,
                        setUser,
                        token,
                      );
                    }
                  }}
                  onColumnResized={async (event: ColumnResizedEvent) => {
                    if (event.column && event.finished) {
                      const column = event.column;
                      const colDef = column.getColDef();
                      if (colDef.resizable) {
                        await postColumnsWidthUserTableSettings(
                          column.getColId() ?? '',
                          column.getActualWidth() ?? 0,
                          'Craftsman',
                          user,
                          setUser,
                          token,
                        );
                      }
                    }
                  }}
                  onSortChanged={async (event: SortChangedEvent) => {
                    // get order column and direction.
                    if (event.columnApi) {
                      const currentSorting = getSorting(event.columnApi);
                      const sortingChanged =
                        currentSorting?.field !==
                          combinedState.filter.orderField ||
                        currentSorting?.direction !==
                          combinedState.filter.orderBy;
                      if (sortingChanged || !currentSorting) {
                        // Send new filter to user's profile.
                        await postSortUserTableSettings(
                          currentSorting?.field,
                          currentSorting?.direction,
                          'Craftsman',
                          user,
                          setUser,
                          token,
                        );

                        // Avoid unfocus header problems in aggrid when changing filter.
                        const focused = document.activeElement;
                        if (focused) {
                          (focused as HTMLInputElement).blur();
                        }

                        setCombinedState(
                          produce(combinedState, (draft) => {
                            if (combinedState.craftsmans.status === 'success') {
                              draft.craftsmans.status = 'loading-with-value';
                            }
                            if (currentSorting) {
                              draft.filter.orderField = currentSorting?.field;
                              draft.filter.orderBy = currentSorting?.direction;
                            } else {
                              draft.filter.orderField =
                                draft.defaultFilter.orderField;
                              draft.filter.orderBy =
                                draft.defaultFilter.orderBy;
                            }
                            draft.filter.page = 0;
                          }),
                        );
                      }
                    }
                  }}
                />
              </div>
            </div>
          );
        }}
      />
      <div>
        {!showMap && combinedState.craftsmans.status === 'success' && (
          <div>
            <span className="mt-5 paginator-results">
              {currentRow === 0
                ? combinedState.craftsmans.status === 'success'
                  ? `${combinedState.craftsmans.value.totalItems} ${t(
                      'general.results',
                    )}`
                  : ''
                : `${t('general.result')} ${currentRow} ${t('general.of')} ${
                    combinedState.craftsmans.status === 'success'
                      ? combinedState.craftsmans.value.totalItems
                      : ''
                  }`}
            </span>
            <BalPagination
              className="mt-3"
              value={page}
              onBalChange={(e) => {
                setPage(e.detail);
              }}
              totalPages={
                Math.trunc(
                  combinedState.craftsmans.value.entities.length / 50,
                ) + 1
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NetworkTable;
