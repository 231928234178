import {
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../../controller/controllers';
import { RequestResult } from '../../../../data';
import { PropertyInspectionTask } from '../../../../types/types';
import {
  PropertyInspectionTaskSummaryFieldsForm,
  PropertyInspectionTaskSummaryFieldsFormProps,
} from './property-inspection-task-summay-fields-form';

export type PropertyInspectionTaskSummaryFieldsFormProp = Omit<
  PropertyInspectionTaskSummaryFieldsFormProps,
  'onClose'
>;

export const PropertyInspectionTaskSummaryFieldsModal = ({
  task,
  setTask,
  onSave,
}: PropertyInspectionTaskSummaryFieldsFormProp): JSX.Element => {
  const [saved, setSaved] = useState<boolean>(false);
  useEffect(() => {
    if (saved) {
      balModalController.dismiss();
    }
  }, [saved]);
  const { t } = useTranslation();
  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3" className="pb-5">
          {t('investigationTask.completeInvestigation.cardTitle')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <PropertyInspectionTaskSummaryFieldsForm
          task={task}
          setTask={setTask}
          onSave={(task: RequestResult<PropertyInspectionTask>) => {
            if (onSave) {
              onSave(task);
            }
            setSaved(true);
          }}
          onClose={() => balModalController.dismiss()}
        ></PropertyInspectionTaskSummaryFieldsForm>
      </BalModalBody>
    </>
  );
};
