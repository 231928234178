import React, { ReactElement, useState } from 'react';
import {
  BalButton,
  BalText,
  BalInput,
  BalSelect,
  BalCheckbox,
  BalTextarea,
  BalSelectOption,
  BalField,
  BalCheckboxGroup,
  BalFieldControl,
  BalRadioGroup,
  BalRadio,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { Craftsman } from '../../../types/types';
import { useDropdownUsers } from '../../../features/user/data/hooks';
import { Globals, getLabelValueFromBkpNumber } from '../../../utils';
import {
  Gender,
  Language,
  PersonDto,
  PreferedContact,
  Region,
  VehicleBrandNames,
  VehicleSpecialtyNames,
  WorkCategoryDto,
} from '../../../types/resource-models';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Controller, useFormContext } from 'react-hook-form';
import produce from 'immer';
import { BalValidators } from '@baloise/web-app-validators';
import {
  BalTypeAheadField,
  PhoneInput,
  BalTextField,
  BalTextFormField,
  CountryList,
} from '../../../components/input';
import { useToken } from '../../../hooks';
import { getGeoCoordinates, useWorkCategoriesFromApi } from '..';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import {
  getDefaultPersonDto,
  getDisplayNameFromUser,
} from '../../../utils/utilities';
type PartnerProps = {
  partner: Craftsman;
  setPartner: React.Dispatch<React.SetStateAction<Craftsman>>;
};
import { v4 as guid } from 'uuid';
import { concat } from 'lodash';

const PartnerIdentification = ({
  partner,
  setPartner,
}: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const craftsman =
    partner.type === 'propertyCraftsman'
      ? partner.propertyCraftsman
      : partner.vehicleCraftsman;
  return (
    <div className="columns is-multiline is-gapless is-full-width">
      <BalText bold className="column is-full mt-2" color="info">
        {t('networkPartners.partnerNumber')}
      </BalText>
      <BalInput
        {...register('partnerNumber', {
          required: true,
          value: craftsman.partnerNumber,
        })}
        id="partnerNumber"
        className="column is-full"
        placeholder={concat([t('networkPartners.partnerNumber'), '*']).join(
          ' ',
        )}
        min={undefined}
        max={undefined}
        value={craftsman.partnerNumber}
        onBalChange={(e) => {
          if (e.detail || e.detail === '') {
            const value = e.detail;
            if (partner.type === 'propertyCraftsman') {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.propertyCraftsman.partnerNumber = value;
                }),
              );
            } else {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.partnerNumber = value;
                }),
              );
            }
          }
        }}
      />
      {errors.partnerNumber && (
        <BalText className="mt-2" color="danger" size="small" bold>
          {t('error.mandatoryField')}
        </BalText>
      )}
    </div>
  );
};

const CompanyFields = ({
  partner,
  setPartner,
}: { isVehicle?: boolean } & PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const { control } = methods;

  const validateEmail = (value: string) =>
    BalValidators.isEmail()(value) ? true : t('validators.invalidEmail');
  const [coordinatesWarning, setCoordinatesWarning] = useState<{
    show: boolean;
    displaying: boolean;
  }>({ show: false, displaying: false });

  useEffect(() => {
    if (coordinatesWarning.show && !coordinatesWarning.displaying) {
      setCoordinatesWarning({ ...coordinatesWarning, displaying: true });
      const id = guid();
      toast(
        ToastNotification({
          message: t('networkPartners.coordinatesUpdateWarning'),
          color: 'warning',
          onClose: () => {
            setCoordinatesWarning({ show: false, displaying: false });
          },
        }),
        {
          toastId: id,
        },
      );
    }
  }, [coordinatesWarning, t]);

  const craftsman =
    partner.type === 'propertyCraftsman'
      ? partner.propertyCraftsman
      : partner.vehicleCraftsman;
  const [country, setCountry] = useState<string | undefined>(
    craftsman.company.address?.country === 'CH' ||
      craftsman.company.address?.country === undefined
      ? 'CH'
      : undefined,
  );

  return (
    <div className="columns is-multiline is-gapless is-full-width mt-6">
      <BalText bold className="column is-full" color="info">
        {t('general.company')}
      </BalText>
      <BalTextFormField
        control={control}
        controllerName="companyName"
        required={t('error.mandatoryField')}
        type="text"
        className="column is-full"
        placeholder={t('general.company')}
        value={craftsman.company.name}
        onChange={(e) => {
          if (e.detail || e.detail === '') {
            const value = e.detail;
            if (partner.type === 'propertyCraftsman') {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.propertyCraftsman.company.name = value;
                }),
              );
            } else {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.company.name = value;
                }),
              );
            }
          }
        }}
      />
      <div className="columns is-multiline is-gapless column is-full mt-2">
        <Controller
          name="street"
          control={control}
          defaultValue={
            craftsman.company.address && craftsman.company.address.street
              ? craftsman.company.address?.street
              : undefined
          }
          rules={{
            required: t('error.mandatoryField'),
          }}
          render={({ field, fieldState }) => (
            <BalTextField
              {...field}
              className="column is-8 mr-4"
              placeholder={concat([t('locationDetails.street'), '*']).join(' ')}
              value={craftsman.company.address?.street}
              onChange={(e) => {
                if (e.detail || e.detail === '') {
                  if (craftsman.company.address?.street) {
                    setCoordinatesWarning({
                      ...coordinatesWarning,
                      show: true,
                    });
                  }
                  if (partner.type === 'propertyCraftsman') {
                    setPartner(
                      produce(partner, (draftState) => {
                        if (draftState.propertyCraftsman.company.address) {
                          if (e.detail || e.detail === '')
                            draftState.propertyCraftsman.company.address.street =
                              e.detail;
                        }
                      }),
                    );
                  } else {
                    setPartner(
                      produce(partner, (draftState) => {
                        if (draftState.vehicleCraftsman.company.address) {
                          if (e.detail || e.detail === '')
                            draftState.vehicleCraftsman.company.address.street =
                              e.detail;
                        }
                      }),
                    );
                  }
                  field.onChange(e.detail);
                }
              }}
              message={fieldState.error && fieldState.error.message}
            />
          )}
        />
        <Controller
          name="houseNumber"
          control={control}
          defaultValue={
            craftsman.company.address && craftsman.company.address.houseNumber
              ? craftsman.company.address?.houseNumber
              : undefined
          }
          rules={{
            required: t('error.mandatoryField'),
            pattern: {
              value: /^[a-zA-Z0-9_.-]*$/,
              message: t('validators.invalidHouseNumber'),
            },
          }}
          render={({ field, fieldState }) => (
            <BalTextField
              {...field}
              className="column"
              placeholder={concat([t('locationDetails.houseNumber'), '*']).join(
                ' ',
              )}
              value={craftsman.company.address?.houseNumber}
              onChange={(e) => {
                if (e.detail || e.detail === '') {
                  if (craftsman.company.address?.houseNumber)
                    setCoordinatesWarning({
                      ...coordinatesWarning,
                      show: true,
                    });
                  if (partner.type === 'propertyCraftsman') {
                    setPartner(
                      produce(partner, (draftState) => {
                        if (draftState.propertyCraftsman.company.address) {
                          draftState.propertyCraftsman.company.address.houseNumber =
                            e.detail;
                        }
                      }),
                    );
                  } else {
                    setPartner(
                      produce(partner, (draftState) => {
                        if (draftState.vehicleCraftsman.company.address) {
                          draftState.vehicleCraftsman.company.address.houseNumber =
                            e.detail;
                        }
                      }),
                    );
                  }
                  field.onChange(e.detail);
                }
              }}
              message={fieldState.error && fieldState.error.message}
            />
          )}
        />
      </div>
      <BalInput
        className="column is-full mt-1"
        placeholder={t('networkPartners.additionalAddress')}
        value={craftsman.company.address?.addition}
        onBalChange={(e) => {
          if (e.detail || e.detail === '') {
            if (craftsman.company.address?.addition)
              setCoordinatesWarning({ ...coordinatesWarning, show: true });
            if (partner.type === 'propertyCraftsman') {
              setPartner(
                produce(partner, (draftState) => {
                  if (draftState.propertyCraftsman.company.address) {
                    draftState.propertyCraftsman.company.address.addition =
                      e.detail;
                  }
                }),
              );
            } else {
              setPartner(
                produce(partner, (draftState) => {
                  if (draftState.vehicleCraftsman.company.address) {
                    draftState.vehicleCraftsman.company.address.addition =
                      e.detail;
                  }
                }),
              );
            }
          }
        }}
      />
      <div className="columns is-multiline is-gapless column is-full mt-2">
        <BalTextFormField
          controllerName={'zipCode'}
          control={control}
          className="column mr-4"
          placeholder={t('general.zipCode')}
          value={
            craftsman.company.address && craftsman.company.address.zipCode
              ? craftsman.company.address?.zipCode.toString()
              : undefined
          }
          required={t('error.mandatoryField')}
          pattern={
            country === 'CH' || country === undefined
              ? {
                  value: /^[1-9][0-9]+$/,
                  message: t('validators.invalidZipcode'),
                }
              : undefined
          }
          maxLengthRule={
            country === 'CH' || country === undefined
              ? {
                  value: 4,
                  message: t('validators.invalidZipcode'),
                }
              : undefined
          }
          onChange={(e) => {
            if (
              craftsman.company.address?.zipCode &&
              craftsman.company.address?.zipCode.toString() !== '0' &&
              craftsman.company.address?.zipCode.toString().length <= 4
            )
              setCoordinatesWarning({ ...coordinatesWarning, show: true });
            if (e.detail || e.detail === '') {
              const value = e.detail;
              if (partner.type === 'propertyCraftsman') {
                setPartner(
                  produce(partner, (draftState) => {
                    if (draftState.propertyCraftsman.company.address) {
                      draftState.propertyCraftsman.company.address.zipCode =
                        value as unknown as number;
                    }
                  }),
                );
              } else {
                setPartner(
                  produce(partner, (draftState) => {
                    if (draftState.vehicleCraftsman.company.address) {
                      draftState.vehicleCraftsman.company.address.zipCode =
                        value as unknown as number;
                    }
                  }),
                );
              }
            }
          }}
        />
        <Controller
          name="city"
          control={control}
          defaultValue={craftsman.company.address?.city}
          rules={{
            required: t('error.mandatoryField'),
            pattern: {
              value:
                /^([a-zA-Z\u0080-\u024F0-9]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F0-9]*$/,
              message: t('validators.invalidCity'),
            },
          }}
          render={({ field, fieldState }) => (
            <BalTextField
              {...field}
              className="column"
              placeholder={concat([t('networkPartners.place'), '*']).join(' ')}
              value={craftsman.company.address?.city}
              onChange={(e) => {
                if (e.detail || e.detail === '') {
                  if (craftsman.company.address?.city)
                    setCoordinatesWarning({
                      ...coordinatesWarning,
                      show: true,
                    });
                  const value = e.detail;
                  if (partner.type === 'propertyCraftsman') {
                    setPartner(
                      produce(partner, (draftState) => {
                        if (draftState.propertyCraftsman.company.address) {
                          draftState.propertyCraftsman.company.address.city =
                            value;
                        }
                      }),
                    );
                  } else {
                    setPartner(
                      produce(partner, (draftState) => {
                        if (draftState.vehicleCraftsman.company.address) {
                          draftState.vehicleCraftsman.company.address.city =
                            value;
                        }
                      }),
                    );
                  }
                  field.onChange(value);
                }
              }}
              message={fieldState.error && fieldState.error.message}
            />
          )}
        />
      </div>
      <CountryList
        controllerName={'country'}
        methods={methods}
        value={
          partner.type === 'propertyCraftsman'
            ? partner.propertyCraftsman.company.address?.country
            : partner.vehicleCraftsman.company.address?.country
        }
        onChange={(choice) => {
          setCountry(choice);
          if (partner.type === 'propertyCraftsman') {
            setPartner(
              produce(partner, (draftState) => {
                if (draftState.propertyCraftsman.company.address) {
                  draftState.propertyCraftsman.company.address.country =
                    choice ?? undefined;
                }
              }),
            );
          } else {
            setPartner(
              produce(partner, (draftState) => {
                if (draftState.vehicleCraftsman.company.address) {
                  draftState.vehicleCraftsman.company.address.country =
                    choice ?? undefined;
                }
              }),
            );
          }
        }}
      />
      <div className="columns is-multiline is-gapless column is-full mt-1">
        <Controller
          name="latitude"
          control={control}
          defaultValue={craftsman.latitude !== 0 ?? undefined}
          rules={{
            required: t('error.mandatoryField'),
            pattern: {
              value: /^[+-]?([0-9]+.?[0-9]*|.[0-9]+)$/,
              message: t('validators.decimalsOnly'),
            },
          }}
          render={({ field, fieldState }) => (
            <BalTextField
              {...field}
              className="column mr-4"
              placeholder={concat([t('networkPartners.latitude'), '*']).join(
                ' ',
              )}
              value={
                craftsman.latitude !== 0
                  ? craftsman.latitude.toString()
                  : undefined
              }
              onChange={(e) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  if (partner.type === 'propertyCraftsman') {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.propertyCraftsman.latitude =
                          value as unknown as number;
                      }),
                    );
                  } else {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.vehicleCraftsman.latitude =
                          value as unknown as number;
                      }),
                    );
                  }
                }
                field.onChange(e.detail);
              }}
              message={fieldState.error && fieldState.error.message}
            />
          )}
        />
        <Controller
          name="longitude"
          control={control}
          defaultValue={craftsman.longitude !== 0 ?? undefined}
          rules={{
            required: t('error.mandatoryField'),
            pattern: {
              value: /^[+-]?([0-9]+.?[0-9]*|.[0-9]+)$/,
              message: t('validators.decimalsOnly'),
            },
          }}
          render={({ field, fieldState }) => (
            <BalTextField
              {...field}
              className="column"
              placeholder={concat([t('networkPartners.longitude'), '*']).join(
                ' ',
              )}
              value={
                craftsman.longitude !== 0
                  ? craftsman.longitude.toString()
                  : undefined
              }
              onChange={(e) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  if (partner.type === 'propertyCraftsman') {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.propertyCraftsman.longitude =
                          value as unknown as number;
                      }),
                    );
                  } else {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.vehicleCraftsman.longitude =
                          value as unknown as number;
                      }),
                    );
                  }
                }
                field.onChange(e.detail);
              }}
              message={fieldState.error && fieldState.error.message}
            />
          )}
        />
      </div>
      <div className="columns is-multiline is-gapless is-full column mt-1">
        <PhoneInput
          name="craftsman-phoneNumber"
          control={control}
          value={craftsman.company.phoneNumber}
          setValue={(value: string) => {
            if (partner.type === 'propertyCraftsman') {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.propertyCraftsman.company.phoneNumber = value;
                }),
              );
            } else {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.company.phoneNumber = value;
                }),
              );
            }
          }}
        />
        <div className="columns is-multiline is-gapless is-full column mt-1">
          <Controller
            name="company-email"
            control={control}
            defaultValue={craftsman.company.email}
            rules={{
              validate: validateEmail,
            }}
            render={({ field, fieldState }) => (
              <BalTextField
                {...field}
                className="column mr-4"
                placeholder={t('general.email')}
                value={craftsman.company.email}
                onChange={(e) => {
                  if (e.detail || e.detail === '') {
                    const value = e.detail;
                    if (partner.type === 'propertyCraftsman') {
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.propertyCraftsman.company.email = value;
                        }),
                      );
                    } else {
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.vehicleCraftsman.company.email = value;
                        }),
                      );
                    }
                    field.onChange(value);
                  }
                }}
                message={fieldState.error && fieldState.error.message}
              />
            )}
          />
          <Controller
            name="website"
            control={control}
            defaultValue={craftsman.company.website}
            rules={{
              pattern: {
                value:
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$$/,
                message: t('validators.invalidWebsite'),
              },
            }}
            render={({ field, fieldState }) => (
              <BalTextField
                {...field}
                className="column"
                placeholder={t('networkPartners.website')}
                value={craftsman.company.website}
                onChange={(e) => {
                  if (e.detail || e.detail === '') {
                    if (partner.type === 'propertyCraftsman') {
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.propertyCraftsman.company.website =
                            e.detail;
                        }),
                      );
                    } else {
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.vehicleCraftsman.company.website =
                            e.detail;
                        }),
                      );
                    }
                    field.onChange(e.detail);
                  }
                }}
                message={fieldState.error && fieldState.error.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

const ContactsFields = ({ partner, setPartner }: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="columns is-multiline is-gapless is-full-width mt-6">
      <div className="column is-flex is-align-items-center is-justify-content-space-between is-full">
        <BalText bold color="info">
          {t('general.contact.label')}
        </BalText>
        <BalButton
          elementType="button"
          className="p-0"
          outlined
          size="small"
          square
          color="primary-light"
          icon="plus"
          onClick={(event) => {
            if (event.detail == 1) {
              if (partner.type === 'propertyCraftsman') {
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.propertyCraftsman.contacts.push(
                      getDefaultPersonDto(),
                    );
                  }),
                );
              } else {
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.vehicleCraftsman.contacts.push(
                      getDefaultPersonDto(),
                    );
                  }),
                );
              }
            }
          }}
        />
      </div>
      <BalRadioGroup
        value={
          partner.type === 'propertyCraftsman'
            ? partner.propertyCraftsman.primaryContactId
            : ''
        }
      >
        <div className="bal-radio-checkbox-group__inner">
          {partner.type === 'propertyCraftsman'
            ? partner.propertyCraftsman.contacts.map((contact, index) => (
                <ContactFields
                  key={contact.id}
                  setPrimaryContactId={(id) => {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.propertyCraftsman.primaryContactId = id;
                      }),
                    );
                  }}
                  contact={contact}
                  setContact={(contact) => {
                    setPartner(
                      produce(partner, (draftState) => {
                        const contactIndex =
                          draftState.propertyCraftsman.contacts.findIndex(
                            (partnerContact) =>
                              partnerContact.id === contact.id,
                          );
                        if (contactIndex >= 0) {
                          draftState.propertyCraftsman.contacts[contactIndex] =
                            contact;
                        }
                      }),
                    );
                  }}
                  contactIndex={index}
                  deleteAdditionalContact={(partnerToDeleteIndex) => {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.propertyCraftsman.contacts.splice(
                          partnerToDeleteIndex,
                          1,
                        );
                      }),
                    );
                  }}
                />
              ))
            : partner.vehicleCraftsman.contacts.map((contact, index) => (
                <ContactFields
                  key={contact.id}
                  setPrimaryContactId={(id) => {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.vehicleCraftsman.primaryContactId = id;
                      }),
                    );
                  }}
                  contact={contact}
                  setContact={(contact) => {
                    setPartner(
                      produce(partner, (draftState) => {
                        const contactIndex =
                          draftState.vehicleCraftsman.contacts.findIndex(
                            (partnerContact) =>
                              partnerContact.id === contact.id,
                          );
                        if (contactIndex >= 0) {
                          draftState.vehicleCraftsman.contacts[contactIndex] =
                            contact;
                        }
                      }),
                    );
                  }}
                  contactIndex={index}
                  deleteAdditionalContact={(partnerToDeleteIndex) => {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.vehicleCraftsman.contacts.splice(
                          partnerToDeleteIndex,
                          1,
                        );
                      }),
                    );
                  }}
                />
              ))}
        </div>
      </BalRadioGroup>
    </div>
  );
};

const ContactFields = ({
  setPrimaryContactId,
  contact,
  setContact,
  contactIndex,
  deleteAdditionalContact,
}: {
  setPrimaryContactId: (id: string) => void;
  contact: PersonDto;
  setContact: (contact: PersonDto) => void;
  contactIndex: number;
  deleteAdditionalContact: (partnerToDeleteIndex: number) => void;
}): ReactElement => {
  const { t } = useTranslation();
  const isAdditionalContact = contactIndex > 0;
  const { control } = useFormContext();
  const validateEmail = (value: string) =>
    BalValidators.isEmail()(value) ? true : t('validators.invalidEmail');
  return (
    <div className="column is-full">
      <div className={isAdditionalContact ? 'mt-5 mb-4' : ''}>
        <div className={'columns is-multiline is-gapless is-full-width'}>
          <div
            className={`column is-flex is-align-items-center is-justify-content-space-between is-full ${
              isAdditionalContact ? 'mt-4' : ''
            }`}
          >
            <div>
              <BalRadio
                key={contact.id}
                value={contact.id}
                onChange={() => {
                  setPrimaryContactId(contact.id);
                }}
              ></BalRadio>
              {/* <input
                type="radio"
                checked={primaryContactId === contact.id}
                className={`${isAdditionalContact ? '' : 'mt-2'}`}
                value={contact.id}
                onChange={() => {
                  setPrimaryContactId(contact.id);
                }}
              ></input> */}
              {t('networkPartners.primaryContact')}
            </div>
            <BalButton
              elementType="button"
              className="p-0"
              size="small"
              square
              outlined
              color="primary-light"
              icon="close"
              onClick={(event) =>
                event.detail == 1 && deleteAdditionalContact(contactIndex)
              }
            />
          </div>
          <BalSelect
            className="column mr-4"
            value={contact.gender}
            placeholder={t('genders.label')}
            onBalChange={(e) => {
              if (e.detail || e.detail === '') {
                const value = e.detail;
                setContact(
                  produce(contact, (draftState) => {
                    draftState.gender = value as Gender;
                  }),
                );
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {Globals.genders.map((gender) => (
                <BalSelectOption
                  key={gender}
                  value={gender}
                  label={t(`genders.gender.${gender}`)}
                >
                  {t(`genders.gender.${gender}`)}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
          <div className="columns is-multiline is-9 column is-gapless">
            <BalInput
              type="text"
              className="column mr-4"
              placeholder={t('general.person.firstName')}
              value={contact.firstName}
              onBalChange={(e) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setContact(
                    produce(contact, (draftState) => {
                      draftState.firstName = value;
                    }),
                  );
                }
              }}
            />
            <BalInput
              type="text"
              className="column is-6"
              placeholder={t('general.person.lastName')}
              value={contact.lastName}
              onBalChange={(e) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setContact(
                    produce(contact, (draftState) => {
                      draftState.lastName = value;
                    }),
                  );
                }
              }}
            />
          </div>
          <BalInput
            type="text"
            className="column is-full"
            placeholder={t('general.function')}
            value={contact.function}
            onBalChange={(e) => {
              if (e.detail || e.detail === '') {
                setContact(
                  produce(contact, (draftState) => {
                    draftState.function = e.detail;
                  }),
                );
              }
            }}
          />
          <div className="columns is-multiline is-full-width is-gapless mt-1">
            <PhoneInput
              name={`contact-phoneNumber-${contact.id}`}
              className="column mr-4"
              control={control}
              value={contact.phoneNumber}
              setValue={(value: string) => {
                setContact(
                  produce(contact, (draftState) => {
                    draftState.phoneNumber = value;
                  }),
                );
              }}
            />
            <PhoneInput
              name={`contact-mobileNumber-${contact.id}`}
              className="column is-6"
              placeholder={t('general.mobile')}
              control={control}
              value={contact.mobileNumber ?? ''}
              setValue={(value: string) => {
                setContact(
                  produce(contact, (draftState) => {
                    draftState.mobileNumber = value;
                  }),
                );
              }}
            />
          </div>
          <Controller
            name={`email-${contact.id}`}
            control={control}
            defaultValue={contact.email}
            rules={{
              validate: validateEmail,
            }}
            render={({ field, fieldState }) => (
              <BalTextField
                {...field}
                className="column is-full"
                placeholder={t('general.email')}
                value={contact.email}
                onChange={(e: CustomEvent<string | undefined>) => {
                  if (e.detail || e.detail === '') {
                    const value = e.detail;
                    setContact(
                      produce(contact, (draftState) => {
                        draftState.email = value;
                      }),
                    );
                    field.onChange(value);
                  }
                }}
                message={fieldState.error && fieldState.error.message}
              />
            )}
          />
          <BalSelect
            className="column is-full mt-1"
            placeholder={t('networkPartners.preferredContactMethod.label')}
            value={contact.preferedContact}
            onBalChange={(e) => {
              if (e.detail || e.detail === '') {
                const value = e.detail;
                setContact(
                  produce(contact, (draftState) => {
                    draftState.preferedContact = value as PreferedContact;
                  }),
                );
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {Globals.preferredContactMethod.map((preferredContact) => (
                <BalSelectOption
                  key={preferredContact}
                  value={preferredContact}
                  label={t(
                    `networkPartners.preferredContactMethod.preferredContact.${preferredContact}`,
                  )}
                >
                  {t(
                    `networkPartners.preferredContactMethod.preferredContact.${preferredContact}`,
                  )}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
          {/* TODO: Implement language in backend */}
          <BalSelect
            className="column is-full mt-1"
            placeholder={t('language.label')}
            value={contact.language}
            onBalChange={(e) => {
              if (e.detail || e.detail === '') {
                const value = e.detail;
                setContact(
                  produce(contact, (draftState) => {
                    draftState.language = value as Language;
                  }),
                );
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {Globals.languages.map((language) => (
                <BalSelectOption
                  key={language}
                  value={language}
                  label={t(`language.languages.${language}`)}
                >
                  {t(`language.languages.${language}`)}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
        </div>
      </div>
    </div>
  );
};

const ResponsibleFields = ({
  isVehicle,
  partner,
  setPartner,
}: {
  isVehicle?: boolean;
} & PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const inspectors = useDropdownUsers({
    appCapabilityNames: isVehicle
      ? ['EditVehicleTask', 'ReadOwnedVehicleTask']
      : ['EditPropertyInspectionTask'],
  });
  const { register, formState } = useFormContext();

  if (inspectors && inspectors.status === 'success') {
    const getNameFromInspectorId = (inspectorId: string) => {
      const inspector = inspectors.value.find((x) => x.id === inspectorId);
      if (inspector === null) {
        return '';
      } else {
        return getDisplayNameFromUser(inspector);
      }
    };

    const craftsman =
      partner.type === 'propertyCraftsman'
        ? partner.propertyCraftsman
        : partner.vehicleCraftsman;

    return (
      <div className="columns is-multiline is-gapless is-full-width mt-4">
        <BalText bold color="info">
          {t('networkPartners.responsibility')}
        </BalText>
        <BalTypeAheadField
          className="column is-full"
          id="assignee"
          placeholder={
            isVehicle
              ? t('networkPartners.MFresponsible')
              : t('networkPartners.SIresponsible')
          }
          result={inspectors.value.map((i) => i.id)}
          valueFormatter={(value) => getNameFromInspectorId(value)}
          keyFormatter={(value) => value}
          value={craftsman.responsibleId}
          onChange={(choice) => {
            if (partner.type === 'propertyCraftsman') {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.propertyCraftsman.responsibleId = choice;
                }),
              );
            } else {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.responsibleId = choice;
                }),
              );
            }
          }}
          formValidation={{
            register: register('responsible', {
              required: false,
              value: craftsman.responsibleId,
            }),
            formState: formState,
          }}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

const PropertyOperationalInformation = ({
  partner,
  setPartner,
}: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const workCategories = useWorkCategoriesFromApi();
  if (
    partner.type === 'propertyCraftsman' &&
    workCategories.status === 'success'
  ) {
    return (
      <div className="columns is-multiline is-gapless is-full-width mt-2">
        <BalSelect
          className="column is-full"
          placeholder={t('networkPartners.region')}
          value={partner.propertyCraftsman.region}
          onBalChange={(e) => {
            if (e.detail || e.detail === '') {
              const value = e.detail;
              setPartner(
                produce(partner, (draftState) => {
                  draftState.propertyCraftsman.region = value as Region;
                }),
              );
            }
          }}
        >
          {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
          <div className="bal-select__inner">
            {Globals.regions.map((region) => (
              <BalSelectOption
                key={region}
                value={region}
                label={t(`region.${region}`)}
              >
                {t(`region.${region}`)}
              </BalSelectOption>
            ))}
          </div>
        </BalSelect>
        <div className="columns column is-full is-gapless mt-2">
          <BalSelect
            multiple
            className="column py-0 is-full"
            placeholder={t('workCategory.label')}
            value={partner.propertyCraftsman.workCategories.map(
              (workCategory) => workCategory.bkpNumber,
            )}
            onBalChange={(e) => {
              if (e.detail || e.detail === '') {
                const value = e.detail;
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.propertyCraftsman.workCategories = (
                      value as string[]
                    ).map((workCategoryBkpNumber) => {
                      if (workCategories.status === 'success') {
                        return (
                          workCategories.value.find(
                            (workCategory: WorkCategoryDto) =>
                              workCategoryBkpNumber === workCategory.bkpNumber,
                          ) ?? { id: '', bkpNumber: '' }
                        );
                      }
                      return { id: '', bkpNumber: '' };
                    });
                  }),
                );
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {workCategories.value.map((workCategory: WorkCategoryDto) => (
                <BalSelectOption
                  key={workCategory.bkpNumber}
                  value={workCategory.bkpNumber}
                  label={getLabelValueFromBkpNumber(workCategory.bkpNumber)}
                >
                  {getLabelValueFromBkpNumber(workCategory.bkpNumber)}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
        </div>
        <div className="columns column is-full is-gapless mt-2">
          <BalField>
            <BalFieldControl>
              <BalCheckboxGroup>
                <BalCheckbox
                  interface="checkbox"
                  checked={
                    partner.propertyCraftsman.isIndependentPropertyCraftsman
                  }
                  onBalChange={(e) => {
                    if (e.detail !== undefined) {
                      const value = e.detail;
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.propertyCraftsman.isIndependentPropertyCraftsman =
                            value;
                        }),
                      );
                    }
                  }}
                >
                  {t('networkPartners.isIndependentPropertyCraftsman')}
                </BalCheckbox>
              </BalCheckboxGroup>
            </BalFieldControl>
          </BalField>
          <BalField className="ml-5">
            <BalFieldControl>
              <BalCheckboxGroup>
                <BalCheckbox
                  interface="checkbox"
                  checked={partner.propertyCraftsman.isExternalPartner}
                  onBalChange={(e) => {
                    if (e.detail !== undefined) {
                      const value = e.detail;
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.propertyCraftsman.isExternalPartner =
                            value;
                        }),
                      );
                    }
                  }}
                >
                  {t('networkPartners.isExternalPartner')}
                </BalCheckbox>
              </BalCheckboxGroup>
            </BalFieldControl>
          </BalField>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const VehicleOperationalInformation = ({
  partner,
  setPartner,
}: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  if (partner.type === 'vehicleCraftsman') {
    return (
      <div className="columns is-multiline is-gapless is-full-width mt-2">
        <BalCheckbox
          checked={partner.vehicleCraftsman.headquarters}
          onBalChange={(e) => {
            if (e.detail !== undefined) {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.headquarters = e.detail;
                }),
              );
            }
          }}
        >
          {t('networkPartners.headquarters')}
        </BalCheckbox>
        <BalCheckbox
          className="ml-5"
          checked={partner.vehicleCraftsman.glassOnly}
          onBalChange={(e) => {
            if (e.detail !== undefined) {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.glassOnly = e.detail;
                }),
              );
            }
          }}
        >
          {t('networkPartners.onlyGlass')}
        </BalCheckbox>
        <div className="columns column py-0 mt-2 is-full is-gapless balSelectDiv">
          <BalSelect
            multiple
            className="column py-0 is-full"
            placeholder={t('networkPartners.specialtyCompany')}
            value={partner.vehicleCraftsman.vehicleSpecialties}
            onBalChange={(e) => {
              if (e.detail || e.detail === '') {
                const value = e.detail;
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.vehicleCraftsman.vehicleSpecialties = (
                      value as string[]
                    ).map((specialty) => specialty as VehicleSpecialtyNames);
                  }),
                );
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {Globals.vehicleSpecialties.map((vehicleSpecialty) => (
                <BalSelectOption
                  key={vehicleSpecialty}
                  value={vehicleSpecialty}
                  label={t(`vehicleSpecialty.${vehicleSpecialty}`)}
                >
                  {t(`vehicleSpecialty.${vehicleSpecialty}`)}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
        </div>
        <div className="columns column py-0 is-full is-gapless mt-2 balSelectDiv">
          <BalSelect
            multiple
            className="column py-0 is-full"
            placeholder={t('networkPartners.carBrands')}
            value={partner.vehicleCraftsman.vehicleBrands}
            onBalChange={(e) => {
              if (e.detail || e.detail === '') {
                const value = e.detail;
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.vehicleCraftsman.vehicleBrands = (
                      value as string[]
                    ).map((brand) => brand as VehicleBrandNames);
                  }),
                );
              }
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {Globals.vehicleBrands.map((vehicleBrand) => (
                <BalSelectOption
                  key={vehicleBrand}
                  value={vehicleBrand}
                  label={vehicleBrand.toString()}
                >
                  {vehicleBrand.toString()}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
        </div>
        <BalCheckbox
          interface="checkbox"
          checked={partner.vehicleCraftsman.isIndependentVehicleCraftsman}
          onBalChange={(e) => {
            if (e.detail !== undefined) {
              const value = e.detail;
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.isIndependentVehicleCraftsman =
                    value;
                }),
              );
            }
          }}
        >
          {t('networkPartners.isIndependentVehicleCraftsman')}
        </BalCheckbox>
        <BalCheckbox
          className="ml-5"
          interface="checkbox"
          checked={partner.vehicleCraftsman.isExternalPartner}
          onBalChange={(e) => {
            if (e.detail !== undefined) {
              const value = e.detail;
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.isExternalPartner = value;
                }),
              );
            }
          }}
        >
          {t('networkPartners.isExternalPartner')}
        </BalCheckbox>
      </div>
    );
  } else {
    return <></>;
  }
};

const OperationalInformation = ({
  partner,
  setPartner,
}: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="columns is-multiline is-gapless is-full-width mt-6">
      <div className="column is-full">
        <BalText bold color="info">
          {t('networkPartners.operationalInformation')}
        </BalText>
      </div>
      {partner.type === 'propertyCraftsman' ? (
        <PropertyOperationalInformation
          partner={partner}
          setPartner={setPartner}
        />
      ) : (
        <VehicleOperationalInformation
          partner={partner}
          setPartner={setPartner}
        />
      )}
    </div>
  );
};

export const Coordinates = ({
  partner,
  setPartner,
}: PartnerProps): JSX.Element => {
  const token = useToken();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const craftsman =
    partner.type === 'propertyCraftsman'
      ? partner.propertyCraftsman
      : partner.vehicleCraftsman;

  useEffect(() => {
    if (
      token &&
      craftsman.company.address &&
      craftsman.company.address.houseNumber &&
      craftsman.company.address.street &&
      craftsman.company.address.city &&
      craftsman.company.address.zipCode
    ) {
      getGeoCoordinates(token, {
        ...craftsman.company.address,
        country: 'Switzerland',
      }).then((result) => {
        if (result.status === 'success') {
          setPartner((partnerOld) =>
            produce(partnerOld, (draftState) => {
              if (draftState.type === 'propertyCraftsman') {
                draftState.propertyCraftsman.latitude = result.value.latitude;
                draftState.propertyCraftsman.longitude = result.value.longitude;
              } else {
                draftState.vehicleCraftsman.latitude = result.value.latitude;
                draftState.vehicleCraftsman.longitude = result.value.longitude;
              }
            }),
          );
        } else if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      });
    }
  }, [craftsman.company.address, setPartner, token]);

  return craftsman.latitude && craftsman.longitude ? (
    <></>
  ) : (
    <div className="columns is-multiline is-gapless is-full-width mt-6">
      <div className="column is-full">
        <BalText bold color="info">
          {t('networkPartners.coordinates')}
        </BalText>
      </div>
      <div className="columns is-multiline is-full-width is-gapless is-vcentered">
        <BalInput
          {...register('latitude', {
            validate: (value: string) => value,
            value: craftsman.latitude ? craftsman.latitude : undefined,
          })}
          type="number"
          className="column is-4 mr-4"
          placeholder={t('networkPartners.latitude')}
          min={undefined}
          max={undefined}
          value={craftsman.latitude ? craftsman.latitude.toString() : undefined}
          onBalChange={(e) => {
            if (e.detail || e.detail === '') {
              const value = e.detail;
              if (partner.type === 'propertyCraftsman') {
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.propertyCraftsman.latitude =
                      value as unknown as number;
                  }),
                );
              } else {
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.vehicleCraftsman.latitude =
                      value as unknown as number;
                  }),
                );
              }
            }
          }}
        />
        <BalInput
          {...register('longitude', {
            validate: (value: string) => value,
            value: craftsman.longitude ? craftsman.longitude : undefined,
          })}
          type="number"
          className="column is-4 mr-4"
          placeholder={t('networkPartners.longitude')}
          min={undefined}
          max={undefined}
          value={
            craftsman.longitude ? craftsman.longitude.toString() : undefined
          }
          onBalChange={(e) => {
            if (e.detail || e.detail === '') {
              const value = e.detail;
              if (partner.type === 'propertyCraftsman') {
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.propertyCraftsman.longitude =
                      value as unknown as number;
                  }),
                );
              } else {
                setPartner(
                  produce(partner, (draftState) => {
                    draftState.vehicleCraftsman.longitude =
                      value as unknown as number;
                  }),
                );
              }
            }
          }}
        />
      </div>
      {(errors.latitude || errors.longitude) && (
        <BalText className="mt-2" color="danger" size="small" bold>
          {t('error.mandatoryField')}
        </BalText>
      )}
    </div>
  );
};

const Comments = ({ partner, setPartner }: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  const craftsman =
    partner.type === 'propertyCraftsman'
      ? partner.propertyCraftsman
      : partner.vehicleCraftsman;
  return (
    <div className="columns is-multiline is-gapless is-full-width mt-6">
      <BalText bold color="info">
        {t('general.claim.remarks')}
      </BalText>
      <BalTextarea
        className="column is-full mt-1"
        placeholder={t('general.claim.remarks')}
        value={craftsman.remarks}
        onBalChange={(e: CustomEvent<string | undefined>) => {
          if (e.detail || e.detail === '') {
            if (partner.type === 'propertyCraftsman') {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.propertyCraftsman.remarks = e.detail;
                }),
              );
            } else {
              setPartner(
                produce(partner, (draftState) => {
                  draftState.vehicleCraftsman.remarks = e.detail;
                }),
              );
            }
          }
        }}
      />
    </div>
  );
};

export const NetworkPartner = ({
  partner,
  setPartner,
}: PartnerProps): JSX.Element => {
  return (
    <>
      <PartnerIdentification partner={partner} setPartner={setPartner} />
      <CompanyFields partner={partner} setPartner={setPartner} />
      <ContactsFields partner={partner} setPartner={setPartner} />
      <OperationalInformation partner={partner} setPartner={setPartner} />
      <ResponsibleFields partner={partner} setPartner={setPartner} />
      <Comments partner={partner} setPartner={setPartner} />
    </>
  );
};
