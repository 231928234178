import {
  BalHeading,
  BalTabs,
  BalText,
  BalTabItem,
  BalNotification,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import '../../styles/general.scss';
import '../../styles/detail-view.scss';
import '../../styles/map.scss';
import { useParams } from 'react-router-dom';
import { RouteKey, EBRoutes } from '../../router/router';
import { convertUtcToLocal, formatDateTime } from '../../utils/date';
import { concat, concatAddress } from '../../utils';
import React, { useEffect, useRef, useState } from 'react';
import { RequestResult } from '../../data';
import { Craftsman } from '../../types/types';
import { PartnerDetail } from './network-partner-detail-modules';
import { WithCapabilitiesErrorProps } from '../../components/authorization';
import { Avatar, Breadcrumbs, ResultRenderer } from '../../components/ui';
import {
  NetworkPartnerEditButtonGroup,
  useCraftsmanDetailFromApi,
} from '../../features/network-partners';

import { useToken } from '../../hooks';
import { CombinedStateProps } from '../../features/network-partners/data/hooks';
import {
  CraftsmanChangeDto,
  PropertyCraftsmanDetailDto,
  VehicleCraftsmanDetailDto,
} from '../../types/resource-models';
import { EntityProtocol } from '../../features/protocol';
import { getNetworkPartnersAllHistory } from '../../features/network-partners/data/requests';
import { getDisplayNameFromUser } from '../../utils/utilities';

type NetworkPartnerDetailId = {
  networkPartnerId: string;
};

const NetworkPartnerDetail = ({
  withCapabilitiesError,
  combinedState,
  setCombinedState,
}: WithCapabilitiesErrorProps & CombinedStateProps): JSX.Element => {
  return (
    <>
      {!withCapabilitiesError && (
        <NetworkPartnerDetailContent
          combinedState={combinedState}
          setCombinedState={setCombinedState}
        />
      )}
    </>
  );
};

export default NetworkPartnerDetail;

const NetworkPartnerDetailContent = ({}: CombinedStateProps): JSX.Element => {
  const { t } = useTranslation();
  const { networkPartnerId } = useParams<NetworkPartnerDetailId>();
  const [networkPartner, setNetworkPartner] = useCraftsmanDetailFromApi(
    networkPartnerId ?? '',
  );
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabDefault = useRef<HTMLBalTabItemElement>(null);
  const [currentDefaultTab, setCurrentDefaultTab] = useState<
    string | undefined
  >(refTabDefault.current?.value);

  const [networkPartnerHistory, setNetworkPartnerHistory] = useState<
    RequestResult<CraftsmanChangeDto[]> | undefined
  >(undefined);
  const token = useToken();
  const [craftsman, setCraftsman] = useState<
    PropertyCraftsmanDetailDto | VehicleCraftsmanDetailDto | undefined
  >(undefined);
  useEffect(() => {
    if (networkPartner.status === 'success') {
      if (currentDefaultTab && currentDefaultTab === 'protocol') {
        if (networkPartnerId) {
          setNetworkPartnerHistory({
            status: 'loading',
          });
          getNetworkPartnersAllHistory(token, networkPartnerId).then(
            (result) => {
              if (result.status === 'success') {
                setNetworkPartnerHistory(result);
              }
            },
          );
        }
      }
      setCraftsman(
        networkPartner.localValue.type === 'propertyCraftsman'
          ? networkPartner.localValue.propertyCraftsman
          : networkPartner.localValue.vehicleCraftsman,
      );
    }
  }, [networkPartner, currentDefaultTab, token, networkPartnerId]);

  // TODO 1:Border are not yet implemented, non existent in backend
  //   const borderArea =
  // craftsman.type === 'propertyCraftsman'
  //   ? craftsman.propertyCraftsman.company.
  //   : craftsman.vehicleCraftsman.region;

  // TODO 2: Additional Contacts are not yet implemented as far as I can see

  // TODO 3: Genusinformation doesn't exist in backend
  // const genus =
  //   craftsman.type === 'propertyCraftsman'
  //     ? craftsman.propertyCraftsman.genus
  //     : craftsman.vehicleCraftsman.genus;

  // TODO 4: Create date not implemented
  //   const createdDate =
  // craftsman.type === 'propertyCraftsman'
  //   ? craftsman.propertyCraftsman.createdAt
  //   : craftsman.vehicleCraftsman.createdAt

  const lastLocationKey: RouteKey = 'NETWORK_PARTNERS';

  if (networkPartner.status === 'success' && craftsman) {
    return (
      <div className="is-full-height has-background-grey-1 is-flex is-flex-direction-column">
        {craftsman.deletedAt && (
          <BalNotification color="danger">
            <BalText>
              {t('claimDetail.warningMessageSoftDeleted', {
                dateTime: convertUtcToLocal({ date: craftsman.deletedAt }),
              })}
            </BalText>
          </BalNotification>
        )}
        <div className="has-background-white mr-6 mt-6 ml-6 pt-6 pb-6 pl-6 detail-border-shadow detail-content">
          <div className="is-flex is-flex-direction-column is-full-width is-full-height position-relative">
            <div className="is-full-width detail-header is-flex is-flex-direction-column is-justify-content-center">
              <Breadcrumbs
                breadcrumbs={[
                  {
                    label: t(`EBRoutes.${lastLocationKey}`),
                    path: EBRoutes[lastLocationKey].create(),
                  },
                  {
                    label: craftsman.company.name,
                  },
                ]}
              />
              <>
                <BalHeading
                  level="h3"
                  subtitle
                  space="none"
                  className="mt-1 mb-1"
                >
                  {craftsman.company.name}
                </BalHeading>
                <BalText size="small">
                  {concat([
                    t('general.claim.createdAt'),
                    formatDateTime(craftsman.createdAt),
                  ])}
                </BalText>
              </>
            </div>
            <div className="columns is-gapless detail-overview mb-6 scroll-bar-minimal">
              <PartnerDetail
                partner={networkPartner}
                setPartner={setNetworkPartner}
              />
            </div>
            {networkPartner.status === 'success' && (
              <div className="sub-menu-actions is-pulled-right">
                <BalHeading level="h3" space="none">
                  {concat([
                    craftsman.company.name,
                    craftsman.company.additionalName,
                  ])}
                </BalHeading>
                <BalText>{concatAddress(craftsman.company.address)}</BalText>
                <NetworkPartnerEditButtonGroup
                  className="mb-2 is-flex"
                  key={craftsman.id}
                  partner={networkPartner}
                  setPartner={
                    setNetworkPartner as React.Dispatch<
                      RequestResult<Craftsman>
                    >
                  }
                />
                <div className="detail-tabs">
                  <BalTabs
                    ref={refTabs}
                    className="has-background-grey-1 p-4 detail-information-content"
                    value={currentDefaultTab ? currentDefaultTab : 'details'}
                    onBalChange={() => {
                      if (refTabs.current?.value) {
                        setCurrentDefaultTab(refTabs.current?.value);
                      }
                    }}
                  >
                    <BalTabItem
                      className="bal-tab-headings"
                      value="details"
                      label={t('general.details')}
                      ref={refTabDefault}
                    >
                      <div className="mt-3 pb-1 columns is-gapless is-vcentered bottom-blue-line">
                        <BalText
                          className="column is-4"
                          color="primary-light"
                          size="small"
                        >
                          {t('networkPartners.responsibility')}
                        </BalText>
                        {craftsman.responsible && (
                          <>
                            <div className="column mr-1">
                              <Avatar user={craftsman.responsible} size="22" />
                            </div>
                            <BalText className="column is-7" bold>
                              {getDisplayNameFromUser(craftsman.responsible)}
                            </BalText>
                          </>
                        )}
                      </div>
                    </BalTabItem>
                    <BalTabItem
                      className="bal-tab-headings scroll-bar-minimal"
                      value="protocol"
                      label={t('networkPartners.protocol')}
                    >
                      <div className="tab-item-content pt-4">
                        {networkPartnerHistory != null && (
                          <ResultRenderer
                            result={networkPartnerHistory}
                            defaultValue={[]}
                            loaded={(tasksResponse) => {
                              return <EntityProtocol history={tasksResponse} />;
                            }}
                          />
                        )}
                      </div>
                    </BalTabItem>
                  </BalTabs>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
