import { cloneElement, isValidElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../context';
import { AppCapabilityNames } from '../../types/resource-models';
import { ToastNotification } from '../toast-notification/toast-notification';

export type WithCapabilitiesErrorProps = {
  withCapabilitiesError?: boolean;
};

type WithCapabilitiesProps = {
  /**
   * Use className to add custom classes.
   */

  className?: string;

  /**
   * Don't use this prop directly. All enclosed tags are children of this.
   */
  children: React.ReactNode;

  /**
   * If true, WithCapabilitiesErrorProps with withCapabilitiesError == true is passed down to the children components.
   */
  passWithCapabilitiesPropsToChildren?: boolean;

  /**
   * List of required app capabilities.
   */
  requiredCapabilities: AppCapabilityNames[];

  /**
   * Triggers error toast in case of missing app capabilities.
   */
  showErrorToast?: boolean;

  /**
   * Deactive or activate certain capabilities/features.
   */
  capabilityEnabled?: boolean;
};

export const WithCapabilities = ({
  className,
  children,
  passWithCapabilitiesPropsToChildren = false,
  requiredCapabilities = [],
  showErrorToast,
  capabilityEnabled: capabilityEnabled,
}: WithCapabilitiesProps): React.ReactElement => {
  const { user } = useContext(UserContext);
  const userFetchSuccess =
    user.status === 'success' || user.status === 'loading-with-value';
  const userCapabilities = userFetchSuccess ? user.value.appCapabilities : [];
  const hasCapability =
    requiredCapabilities.length === 0 ||
    requiredCapabilities.some((requiredCapability) =>
      userCapabilities.includes(requiredCapability),
    );

  const noCapabilityOrDisabled = !hasCapability || capabilityEnabled == false;
  if (noCapabilityOrDisabled && !passWithCapabilitiesPropsToChildren) {
    if (showErrorToast && userFetchSuccess) {
      _showErrorToast();
    }
    return <></>;
  }

  if (
    !hasCapability &&
    passWithCapabilitiesPropsToChildren &&
    isValidElement(children)
  ) {
    if (showErrorToast && userFetchSuccess) {
      _showErrorToast();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clonedElement = cloneElement(children as React.ReactElement<any>, {
      withCapabilitiesError: true,
    });
    return className ? (
      <div className={className}>{clonedElement}</div>
    ) : (
      clonedElement
    );
  }

  return className ? (
    <div className={className}>{children}</div>
  ) : (
    <>{children}</>
  );
};

const _showErrorToast = () => {
  const { t } = useTranslation();
  toast(
    ToastNotification({
      message: t('error.appCapabilityError'),
      color: 'danger',
    }),
  );
};
