import {
  BalCard,
  BalHeading,
  BalSpinner,
  BalText,
} from '@baloise/design-system-components-react';
import { RequestResult } from '../../../data';
import { VehicleTask } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BalTextField, CountryList } from '../../../components/input';
import produce from 'immer';

const RiskAssessmentVisitationCard = ({
  task,
  setVehicleTask,
  taskButtons,
  patchTask,
}: {
  task: RequestResult<VehicleTask>;
  setVehicleTask: React.Dispatch<RequestResult<VehicleTask>>;
  taskButtons: JSX.Element;
  patchTask: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });

  return (
    <div>
      {task.status === 'loading' && <BalSpinner />}
      {task.status === 'error' && <></>}
      {task.status === 'success' && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(patchTask)}>
            <BalCard className="mt-5 p-5 columns is-multiline is-gapless is-full-width">
              <BalHeading space="none" level="h4" className=" column is-full">
                {t('vehicleTask.vehicleCompany.visitationLocation')}
              </BalHeading>
              <div className="columns column is-full is-multiline is-gapless mb-4">
                <BalText bold className="column is-full mb-2" color="info">
                  {t('vehicleTask.vehicleCompany.companyOrPolicyHolder')}
                </BalText>
                <Controller
                  name="companyName"
                  control={methods.control}
                  rules={{
                    required: t('error.mandatoryField'),
                  }}
                  defaultValue={task.localValue.assigneeGarage.name}
                  render={({ field, fieldState }) => (
                    <>
                      <BalTextField
                        {...field}
                        className="column is-full mb-4"
                        title={t('general.name')}
                        placeholder={t('general.name')}
                        value={task.localValue.assigneeGarage.name}
                        onChange={(e) => {
                          if (e.detail || e.detail === '') {
                            const value = e.detail;
                            setVehicleTask(
                              produce(task, (draftState) => {
                                draftState.localValue.assigneeGarage.name =
                                  value;
                              }),
                            );
                          }
                          field.onChange(e.detail);
                        }}
                        message={fieldState.error && fieldState.error.message}
                      />
                    </>
                  )}
                />
                <Controller
                  name="street"
                  control={methods.control}
                  rules={{
                    required: t('error.mandatoryField'),
                  }}
                  defaultValue={task.localValue.assigneeGarage.address?.street}
                  render={({ field, fieldState }) => (
                    <BalTextField
                      {...field}
                      className="column is-8 mr-4"
                      title={t('locationDetails.street')}
                      placeholder={t('locationDetails.street')}
                      value={task.localValue.assigneeGarage.address?.street}
                      onChange={(e) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(task, (draftState) => {
                              if (
                                draftState.localValue.assigneeGarage.address
                              ) {
                                draftState.localValue.assigneeGarage.address.street =
                                  value;
                              }
                            }),
                          );
                        }
                        field.onChange(e.detail);
                      }}
                      message={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
                <Controller
                  name="houseNumber"
                  control={methods.control}
                  rules={{
                    required: t('error.mandatoryField'),
                    pattern: {
                      value: /^[a-zA-Z0-9_.-]*$/,
                      message: t('validators.invalidHouseNumber'),
                    },
                  }}
                  defaultValue={
                    task.localValue.assigneeGarage.address?.houseNumber
                  }
                  render={({ field, fieldState }) => (
                    <BalTextField
                      {...field}
                      className="column"
                      title={t('locationDetails.houseNumber')}
                      placeholder={t('locationDetails.houseNumber')}
                      value={
                        task.localValue.assigneeGarage.address?.houseNumber
                      }
                      onChange={(e) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(task, (draftState) => {
                              if (
                                draftState.localValue.assigneeGarage.address
                              ) {
                                draftState.localValue.assigneeGarage.address.houseNumber =
                                  value;
                              }
                            }),
                          );
                        }
                        field.onChange(e.detail);
                      }}
                      message={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
                <Controller
                  name="zipCode"
                  control={methods.control}
                  defaultValue={
                    task.localValue.assigneeGarage.address &&
                    task.localValue.assigneeGarage.address.zipCode
                      ? task.localValue.assigneeGarage.address?.zipCode?.toString()
                      : undefined
                  }
                  rules={{
                    required: t('error.mandatoryField'),
                    pattern:
                      task.localValue.assigneeGarage.address?.country ===
                        'CH' ||
                      task.localValue.assigneeGarage.address?.country ===
                        undefined ||
                      task.localValue.assigneeGarage.address?.country === null
                        ? {
                            value: /^[1-9][0-9]+$/,
                            message: t('validators.invalidZipcode'),
                          }
                        : undefined,
                  }}
                  render={({ field, fieldState }) => (
                    <BalTextField
                      {...field}
                      className="column is-4 mr-4  my-2"
                      title={t('general.zipCode')}
                      placeholder={t('general.zipCode')}
                      value={
                        task.localValue.assigneeGarage.address &&
                        task.localValue.assigneeGarage.address.zipCode
                          ? task.localValue.assigneeGarage.address?.zipCode?.toString()
                          : undefined
                      }
                      onChange={(e) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(task, (draftState) => {
                              if (
                                draftState.localValue.assigneeGarage.address
                              ) {
                                draftState.localValue.assigneeGarage.address.zipCode =
                                  value as unknown as number;
                              }
                            }),
                          );
                        }
                        field.onChange(e.detail);
                      }}
                      message={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
                <Controller
                  name="city"
                  control={methods.control}
                  defaultValue={task.localValue.assigneeGarage.address?.city}
                  rules={{
                    required: t('error.mandatoryField'),
                    pattern: {
                      value:
                        /^([a-zA-Z\u0080-\u024F0-9]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F0-9]*$/,
                      message: t('validators.invalidCity'),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <BalTextField
                      {...field}
                      className="column  my-2"
                      title={t('networkPartners.place')}
                      placeholder={t('networkPartners.place')}
                      value={task.localValue.assigneeGarage.address?.city}
                      onChange={(e) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(task, (draftState) => {
                              if (
                                draftState.localValue.assigneeGarage.address
                              ) {
                                draftState.localValue.assigneeGarage.address.city =
                                  value;
                              }
                            }),
                          );
                        }
                        field.onChange(e.detail);
                      }}
                      message={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
                <div className="column is-12 is-flex is-flex-direction-column">
                  <CountryList
                    controllerName={'country'}
                    methods={methods}
                    value={task.localValue.assigneeGarage.address?.country}
                    onChange={(choice) => {
                      setVehicleTask(
                        produce(task, (draftState) => {
                          if (draftState.localValue.assigneeGarage.address) {
                            draftState.localValue.assigneeGarage.address.country =
                              choice;
                          }
                        }),
                      );
                    }}
                  />
                </div>
              </div>
            </BalCard>
            {taskButtons}
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default RiskAssessmentVisitationCard;
