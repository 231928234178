import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { WithCapabilitiesErrorProps } from '.';
import { concat } from '../../utils';

export const DisabableLink = ({
  withCapabilitiesError,
  ...props
}: LinkProps & WithCapabilitiesErrorProps): React.ReactElement => {
  const classNames = concat([
    props.className ?? '',
    withCapabilitiesError ? 'link-disabled' : '',
  ]);
  return (
    <Link
      {...props}
      className={classNames.length > 0 ? classNames : undefined}
      onClick={withCapabilitiesError ? (e) => e.preventDefault() : undefined}
    >
      {props.children}
    </Link>
  );
};
