import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// hook adapted from https://usehooks.com/useDebounce/
export function useDebounce<T>(
  delay: number,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const [value, setValue] = useState<T>();
  const [debouncedValue, setDebouncedValue] = useState<T>();
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return [debouncedValue, setValue];
}
