import {
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import lang from 'suneditor-react/dist/types/lang';
import { balModalController } from '../../../controller/controllers';
import { RequestResult } from '../../../data';
import { InvestigationTaskJournalDto } from '../../../types/resource-models';
import JournalEditForm from './journal-edit-form';

export interface JournalEditFormModalProps {
  title: string;
  initialValue: RequestResult<InvestigationTaskJournalDto>;
  initialAddingState: boolean;
  language: lang;
  readonly?: boolean;
  onSave: (journalId?: string) => void;
  onDelete: () => Promise<void>;
}
const JournalEditFormModal = ({
  title,
  initialValue,
  initialAddingState,
  language,
  readonly,
  onSave,
  onDelete,
}: JournalEditFormModalProps): JSX.Element => {
  const [saved, setSaved] = useState<boolean>(false);
  useEffect(() => {
    if (saved) {
      balModalController.dismiss();
    }
  }, [saved]);

  return (
    <>
      <BalModalHeader className="is-full-width create-contacts-header">
        <BalHeading level="h3">{title}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <JournalEditForm
          initialValue={initialValue}
          initialAddingState={initialAddingState}
          language={language}
          readonly={readonly}
          onSave={(journalId?: string) => {
            onSave(journalId);
            setSaved(true);
          }}
          onDelete={async () => {
            await onDelete();
            setTimeout(() => {
              balModalController.dismiss();
            }, 1000);
          }}
        ></JournalEditForm>
      </BalModalBody>
    </>
  );
};

export default JournalEditFormModal;
