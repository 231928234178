import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AppCapabilityNames, CoordinateDto } from '../../types/resource-models';
import { WithCapabilities } from '../authorization/with-capabilities';
import img from '../../assets/img/bvm-damage-zone.png';
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';
import produce from 'immer';
import { CancelButton } from '../ui';

type EditDamageCoordinatesModalProps = {
  label: string;
  value: CoordinateDto[];
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  onSave(value: CoordinateDto[]): void;
};

const EditDamageCoordinatesModalContent = (
  props: EditDamageCoordinatesModalProps,
): JSX.Element => {
  const { t } = useTranslation();
  const [damageCoordinates, setDamageCoordinates] = useState<CoordinateDto[]>(
    props.value,
  );

  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading space="none" level="h3">
          {props.label}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <ImageMarker
          src={img}
          markers={damageCoordinates}
          onAddMarker={(marker: Marker) =>
            setDamageCoordinates(
              produce(damageCoordinates, (draftState) => {
                draftState.push({
                  top: marker.top as number,
                  left: marker.left as number,
                });
              }),
            )
          }
          markerComponent={(props: MarkerComponentProps) => (
            <span
              onClick={(event) =>
                event.detail === 1 &&
                setDamageCoordinates(
                  produce(damageCoordinates, (draftState) => {
                    draftState.splice(props.itemNumber.valueOf(), 1);
                  }),
                )
              }
              className="unselectable"
            >
              {'X'}
            </span>
          )}
        />
        <BalButtonGroup position="right">
          <CancelButton />
          <BalButton
            color="info"
            onClick={(event) => {
              event.detail === 1 && props.onSave(damageCoordinates);
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export const EditDamageCoordinatesModal = (
  props: EditDamageCoordinatesModalProps,
): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditDamageCoordinatesModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        className={props.className}
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail === 1 && openModal()}
      />
    </WithCapabilities>
  );
};
