import {
  BalHeading,
  BalIcon,
  BalList,
} from '@baloise/design-system-components-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/general.scss';
import { motion } from 'framer-motion';
import { ReactComponent as Task } from '../../assets/icons/task.svg';
import { MenuItem, ViewMenuItem } from './view-menu-item';
import { UserContext } from '../../context';
import {
  AccidentInspectionTaskFilterCriteriaDto,
  CraftsmanFilterCriteria,
  InvestigationTaskFilterCriteriaDto,
  PropertyInspectionTaskFilterCriteriaDto,
  VehicleTaskFilterCriteriaDto,
} from '../../types/resource-models';
import { CombinedState as BaseTaskCombinedState } from '../base-task';
import { CombinedState as NetworkPartnerCombinedState } from '../network-partners';
import { useToken } from '../../hooks';

export type Filter =
  | Partial<PropertyInspectionTaskFilterCriteriaDto>
  | Partial<AccidentInspectionTaskFilterCriteriaDto>
  | Partial<InvestigationTaskFilterCriteriaDto>
  | Partial<VehicleTaskFilterCriteriaDto>
  | Partial<CraftsmanFilterCriteria>;

export const ViewMenu = ({
  setLabelAndFilter,
  menuItems,
  route,
  combinedState,
  editFilter,
  setEditFilter,
}: {
  setLabelAndFilter: (label: string, filter: Filter) => void;
  menuItems: MenuItem[];
  route: string;
  combinedState: BaseTaskCombinedState | NetworkPartnerCombinedState;
  editFilter: string | undefined;
  setEditFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(window.innerWidth < 1200 ? false : true);
  const token = useToken();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1200) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const displayMenu = () => {
    return menuItems.map((menuItem: MenuItem) => {
      return (
        <ViewMenuItem
          user={user}
          setUser={setUser}
          key={menuItem.label}
          label={menuItem.label}
          filter={menuItem.filter}
          setLabelAndFilter={setLabelAndFilter}
          route={route}
          isOpen={isOpen}
          combinedState={combinedState}
          token={token}
          editFilter={editFilter}
          setEditFilter={setEditFilter}
          userMenuItemType={menuItem.type}
        />
      );
    });
  };

  return user.status === 'success' || user.status === 'loading-with-value' ? (
    <motion.div
      animate={
        isOpen
          ? { width: 250 }
          : {
              width: 48,
            }
      }
      className="menu is-pulled-left is-flex is-flex-direction-column is-justify-content-space-between is-full-height"
    >
      {isOpen ? (
        <div className="favorite-sub-menu scroll-bar-minimal">
          <BalHeading space="none" level="h5" className="ml-4 mt-5">
            {t('overviewMenu.favorites')}
          </BalHeading>
          <BalList>{menuItems && displayMenu()}</BalList>
        </div>
      ) : (
        <div
          className={`favorite-sub-menu icon-small icon-info ml-2 mt-3 mb-4 ${
            !isOpen ? 'sub-menu-padding' : ''
          }`}
        >
          <div className="hoover-content is-fixed">
            <Task />
            <div className="favorite-content">
              <BalList>{menuItems && displayMenu()}</BalList>
            </div>
          </div>
        </div>
      )}
      {isOpen ? (
        <BalIcon
          onClick={(event) => event.detail == 1 && setIsOpen(false)}
          name="nav-go-left"
          className="m-4 is-align-self-flex-end mr-4"
        />
      ) : (
        <BalIcon
          onClick={(event) => event.detail == 1 && setIsOpen(true)}
          name="nav-go-right"
          className="m-4 is-align-self-flex-end"
        />
      )}
    </motion.div>
  ) : (
    <></>
  );
};
