import { BalHint, BalHintText } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';

export function getCurrentVersion(): string | undefined {
  return process.env.REACT_APP_UI_VERSION;
}

export function getEnv(): string {
  switch (window.extended.BAT_APP_STAGE) {
    case 'BatCloudInt':
    case 'BatCloudIntCmoPlus': {
      return 'INT';
    }
    case 'BatCloudAcc':
    case 'BatCloudAccCmoPlus': {
      return 'ACC';
    }
    case 'BatCloudProd':
    case 'BatCloudProdCmoPlus': {
      return 'PROD';
    }
    case 'BatCloudDev': {
      return 'DEV';
    }
    case 'Development': {
      return 'DEV';
    }
    default: {
      return 'NONE';
    }
  }
}

export const EnvDisplay = (): JSX.Element => {
  const stage = getEnv();
  const { t } = useTranslation();
  if (stage === 'INT' || stage === 'ACC' || stage === 'NONE')
    return (
      <>
        <h3 className="mt-5 title is-6">{t(`envType.${stage}.title`)}</h3>
        <BalHint class="mt-5 ml-1">
          <BalHintText>{t(`envType.${stage}.tooltip`)}</BalHintText>
        </BalHint>
      </>
    );
  else return <></>;
};
