import * as React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { authRequest } from '../data/auth';
import { Navigate } from 'react-router';
import { EBRoutes } from '../router/router';
import { useTranslation } from 'react-i18next';

export const LoginView: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    authRequest,
  );
  React.useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, authRequest);
    }
  }, [error, login]);
  return (
    <>
      <AuthenticatedTemplate>
        <Navigate to={EBRoutes.HOME.create({})} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {t('authentication.loggingIn')}
      </UnauthenticatedTemplate>
    </>
  );
};
