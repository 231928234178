import { BalButton } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { WithCapabilities } from '../../../../components/authorization';
import { ErrorToast } from '../../../../components/toast-notification';
import { UserContext } from '../../../../context';
import { balModalController } from '../../../../controller/controllers';
import { useToken } from '../../../../hooks';
import { PropertyInspectionTask } from '../../../../types/types';
import { Globals, guid } from '../../../../utils';
import { getPropertyInspectionTaskJournalNextOrder } from '../../data/requests';
import PropertyInspectionTaskJournalEditFormModal from '../property-inspection-task-journal-edit-form-modal';

export interface PropertyInspectionTaskJournalAddButtonsProps {
  task: PropertyInspectionTask;
  onAddJournal: (journalId: string) => void;
  disabled?: boolean;
}

const PropertyInspectionTaskJournalAddButton = ({
  task,
  onAddJournal,
  disabled,
}: PropertyInspectionTaskJournalAddButtonsProps): JSX.Element => {
  const { user } = useContext(UserContext);
  const token = useToken();
  const openAddModal = async () => {
    const result = await getPropertyInspectionTaskJournalNextOrder(
      token,
      task.id,
    );
    if (result.status === 'error') {
      toast(ErrorToast(result.errorValue));
    } else {
      const journalToAdd = {
        ...Globals.propertyInspectionTaskJournalDefault,
        id: guid(),
        propertyInspectionTaskId: task.id,
        orderNumber: result.status === 'success' ? result.localValue : 100,
        creatorId: user.status === 'success' ? user.value.id : '',
        status: task.status,
        createdAt: DateTime.utc().toString(),
      };

      const modal = await balModalController.create({
        component: PropertyInspectionTaskJournalEditFormModal,
        modalWidth: 1200,
        componentProps: {
          title: `${t('general.buttons.create')} ${t('journal.entityName')}`,
          initialValue: {
            status: 'success',
            value: journalToAdd,
            localValue: journalToAdd,
          },
          initialAddingState: true,
          onSave: onAddJournal,
        },
        cssClass: 'center-modal',
        backdropDismiss: false,
        isClosable: false,
      });
      return modal.present();
    }
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditPropertyInspectionTask']}
      passWithCapabilitiesPropsToChildren
    >
      <BalButton
        disabled={disabled || user.status !== 'success'}
        className="ml-2"
        square
        outlined
        size="small"
        color="info"
        icon="plus"
        onClick={(event) => event.detail == 1 && openAddModal()}
      />
    </WithCapabilities>
  );
};

export default PropertyInspectionTaskJournalAddButton;
