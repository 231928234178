import {
  BalCard,
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalNumberInput,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BalTextField } from './bal-text-field';
import { CombinedState } from '../../features/network-partners/data/hooks';
import { useOnClickOutside } from '../../hooks';
import '../../styles/radius-search.scss';

export const RadiusSearch = ({
  className,
  combinedState,
  setCombinedState,
  timer,
  setTimer,
  disabled,
}: {
  className: string;
  disabled?: boolean;
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  timer: NodeJS.Timeout | undefined;
  setTimer: React.Dispatch<React.SetStateAction<NodeJS.Timeout | undefined>>;
}): JSX.Element => {
  const [show, setShow] = useState(false);
  const refInput = useRef(null);
  const refCard = useRef(null);
  useOnClickOutside([refInput, refCard], () => setShow(false));
  const { t } = useTranslation();
  const onLocationSearchDelay = (value: string) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.craftsmans.status === 'success') {
          draftState.craftsmans.status = 'loading-with-value';
        }
        draftState.filter.location = value;
        draftState.filter.radius =
          value > '' ? draftState.filter.radius : undefined;
        draftState.filter.page = 0;
      }),
    );
  };
  const onRadiusSearchDelay = (value: number) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.craftsmans.status === 'success') {
          draftState.craftsmans.status = 'loading-with-value';
        }
        draftState.filter.radius = value;
        draftState.filter.page = 0;
      }),
    );
  };

  return (
    <div>
      <BalTextField
        disabled={disabled ?? false}
        value={combinedState.filter.location}
        className={className}
        ref={refInput}
        onFocus={() => setShow(true)}
        title={t('general.zipCodeCity')}
        placeholder={t('general.zipCodeCity')}
        onInput={(e: CustomEvent<string | undefined>) => {
          if (e.detail || e.detail === '') {
            clearTimeout(timer);
            const newTimer = setTimeout(() => {
              onLocationSearchDelay(e.detail ?? '');
            }, 500);
            setTimer(newTimer);
          }
        }}
        suffix={
          combinedState.filter.radius
            ? `(${combinedState.filter.radius} km)`
            : undefined
        }
      />
      {show && (
        <BalCard className="p-4 radius-search-overlay" ref={refCard}>
          <BalField>
            <BalFieldLabel>{t('networkPartners.radius')}</BalFieldLabel>
            <BalFieldControl className="m-0">
              <BalNumberInput
                value={combinedState.filter.radius ?? 0}
                onBalInput={(e: CustomEvent<number | undefined>) => {
                  if (e.detail) {
                    clearTimeout(timer);
                    const newTimer = setTimeout(() => {
                      onRadiusSearchDelay(e.detail ?? 0);
                    }, 500);
                    setTimer(newTimer);
                  }
                }}
                suffix="km"
                disabled={!combinedState.filter.location}
              />
            </BalFieldControl>
          </BalField>
        </BalCard>
      )}
    </div>
  );
};
