import { BalText } from '@baloise/design-system-components-react';
import { t } from 'i18next';
import { FileView } from '../../../../../components/ui/file-view';
import { InsuranceSurveyDto } from '../../../../../types/resource-models';
import { formatDateTime } from '../../../../../utils/date';
import { TaskDetailInfoDisplay } from '../../../../base-task';
import { InsuranceSurveyHistory } from './insurance-survey-history';

export const InsuranceSurveyShowView = ({
  insuranceSurvey,
}: {
  insuranceSurvey: InsuranceSurveyDto;
}) => {
  return (
    <>
      <InsuranceSurveyHistory insuranceSurveyId={insuranceSurvey.id} />
      <TaskDetailInfoDisplay title={''} />
      <TaskDetailInfoDisplay
        title={t('investigationTask.insuranceSurvey.startDate')}
        text={formatDateTime(insuranceSurvey.startDate)}
      />
      <TaskDetailInfoDisplay
        title={t('investigationTask.insuranceSurvey.type')}
        text={t(
          `investigationTask.insuranceSurveyTypes.${insuranceSurvey.insuranceSurveyType}`,
        )}
      />
      <TaskDetailInfoDisplay
        title={t('investigationTask.insuranceSurvey.insurance')}
        text={insuranceSurvey.insurance}
      />
      <TaskDetailInfoDisplay
        title={t('investigationTask.insuranceSurvey.response')}
        text={t(
          `investigationTask.insuranceSurveyResponses.${insuranceSurvey.response}`,
        )}
      />
      {insuranceSurvey.response === 'Positive' && (
        <>
          <TaskDetailInfoDisplay
            title={t('investigationTask.insuranceSurvey.positiveResponse')}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: insuranceSurvey.positiveResponse ?? '',
            }}
          />
          <TaskDetailInfoDisplay
            title={t(
              'investigationTask.insuranceSurvey.positiveResponseAtDate',
            )}
            text={
              insuranceSurvey.positiveResponseAtDate
                ? formatDateTime(insuranceSurvey.positiveResponseAtDate)
                : ''
            }
          />
        </>
      )}{' '}
      <BalText>
        <strong>{t('general.claim.attachments') as string}</strong>
      </BalText>
      {insuranceSurvey.attachments?.map((file, index) => {
        return (
          <div className="has-background-grey-light mb-3" key={index}>
            {file !== undefined && (
              <>
                <FileView file={file} />
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
