import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { balModalController } from '../../controller/controllers';
import { useTranslation } from 'react-i18next';
const CancelModal = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">{t('general.buttons.cancel')}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>{t('general.onCancelMessage')}</BalText>
        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            icon="nav-go-left"
            onClick={(event) => {
              if (event.detail == 1) {
                balModalController.dismiss();
              }
            }}
          >
            {t('general.buttons.back')}
          </BalButton>
          <BalButton
            elementType="button"
            color="info-light"
            icon="check"
            onClick={() => {
              balModalController.dismiss();
              setTimeout(() => {
                balModalController.dismiss();
              }, 500);
            }}
          >
            {t('general.buttons.yes')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};
export const CancelButton = () => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: CancelModal,
      isClosable: false,
      cssClass: 'center-modal',
    });
    return modal.present();
  };
  return (
    <BalButton
      elementType="button"
      color="primary-light"
      onClick={() => openModal()}
    >
      {t('general.buttons.cancel')}
    </BalButton>
  );
};
