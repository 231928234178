import {
  BalHeading,
  BalTabs,
  BalTabItem,
  BalText,
  BalCheckbox,
  BalNotification,
  BalHint,
  BalHintText,
  BalButton,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import '../../../styles/general.scss';
import '../../../styles/detail-view.scss';
import { useParams } from 'react-router-dom';
import { convertUtcToLocal, formatDateTime } from '../../../utils/date';
import {
  concat,
  concatAddress,
  getFullHeightWarningCssClass,
  Globals,
} from '../../../utils';
import { RouteKey, EBRoutes } from '../../../router/router';
import { Dispatch, useEffect, useRef, useState } from 'react';
import {
  TaskTypes,
  TranslatedSelection,
  VehicleTask,
} from '../../../types/types';
import ImageMarker from 'react-image-marker';
import vehicleDamage from '../../../assets/img/bvm-damage-zone.png';
import produce from 'immer';
import { toast } from 'react-toastify';
import {
  AttachmentDto,
  BaseTaskChangeDto,
  Branch,
  CoordinateDto,
  InsuranceType,
  Language,
  UserDto,
} from '../../../types/resource-models';
import { deleteFile, FileUpload } from '../../files';
import { useToken } from '../../../hooks';
import { balModalController } from '../../../controller/controllers';
import {
  AddressEditButton,
  EditCheckboxModal,
  EditDamageCoordinatesModal,
  EditDateModal,
  EditNumberModal,
  EditTextModal,
} from '../../../components/input';
import { patchVehicleTask, useVehicleTaskFromApi } from '..';
import { RequestResult } from '../../../data';
import { ErrorToast } from '../../../components/toast-notification';
import {
  Breadcrumbs,
  InfoDisplay,
  CopyButton,
  ResultRenderer,
} from '../../../components/ui';
import { VehicleTaskSendModal, VehicleTaskWorkflowButtons } from '.';
import {
  TaskDetailInfoDisplay,
  BaseTaskAttachments,
  CombinedState,
} from '../../base-task';
import {
  WithCapabilities,
  WithCapabilitiesErrorProps,
} from '../../../components/authorization';
import { WritableDraft } from 'immer/dist/internal';
import { getVehicleTaskAllHistory } from '../data/requests';
import { EntityProtocol } from '../../protocol';

import { useDescriptionOfBaloiseCode } from '../../user/data/hooks';
import { VehicleInfo } from '.';
import { PolicyInfo } from './vehicle-task-policy-info';
import {
  cleanPolicyNumber,
  getDefaultAddressWithClaimId,
  getDefaultAddressWithPartnerId,
  getDisplayNameFromUser,
} from '../../../utils/utilities';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { EditSelectionModal } from '../../../components/input/edit-selection-modal';
import { VehicleRegress } from '../../../types/resource-models';
import { BalValidators } from '@baloise/web-app-validators';
import { AddressDto } from '../../../../../bat-shared/resource-models';
import VehicleTaskDetailContactsCards from './vehicle-task-detail-contacts-cards';
import TaskJumpAdos from '../../base-task/components/task-detail-jump-ados';
import { SelectUserModal, SelectUserModalProps } from '../../user';
import { BaseTaskMemoFieldsButton } from '../../base-task/components';

type VehicleTaskDetailId = {
  taskId: string;
};

export const VehicleTaskDetail = ({
  withCapabilitiesError,
  setCombinedState,
  combinedState,
}: {
  withCapabilitiesError?: WithCapabilitiesErrorProps;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  combinedState: CombinedState;
}): JSX.Element => {
  return (
    <>
      {!withCapabilitiesError && (
        <VehicleTaskDetailContent
          combinedState={combinedState}
          setCombinedState={setCombinedState}
        />
      )}
    </>
  );
};

const Visitation = ({
  task,
  setTask,
}: {
  task: VehicleTask;
  setTask: Dispatch<RequestResult<VehicleTask>>;
}) => {
  const { t } = useTranslation();
  const token = useToken();
  const isCraftsmanGarage: boolean =
    task.assigneeGarageCraftsmanId !== null &&
    task.assigneeGarageCraftsmanId !== undefined;
  const openSendModal = async () => {
    const modal = await balModalController.create({
      component: VehicleTaskSendModal,
      componentProps: {
        task: task,
        onSendSuccess: setTask,
        patchVehicleTask: patchVehicleTask,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <>
      {isCraftsmanGarage && (
        <BalNotification>
          <BalText>
            <BalHint className="mr-3">
              <BalHintText>{t('vehicleTask.craftsmanGarageHint')}</BalHintText>
            </BalHint>
            {t('vehicleTask.craftsmanGarageNotification')}
          </BalText>
          <BalButton
            size="small"
            color="info"
            outlined
            onClick={(event) => event.detail == 1 && openSendModal()}
          >
            {t('vehicleTask.sendToExternalPartner')}
          </BalButton>
        </BalNotification>
      )}
      {task.visitationAtPolicyHolder ? (
        <BalNotification className="mb-5">
          <BalText>
            {t('vehicleTask.vehicleCompany.visitationAtPolicyHolder')}
          </BalText>
        </BalNotification>
      ) : (
        <>
          <InfoDisplay
            title={
              task.kind === 'VehicleInspectionTask'
                ? t('general.company')
                : t('general.name')
            }
            text={concat([
              task.assigneeGarage.name,
              task.assigneeGarage.additionalName,
            ])}
            editComponent={
              !isCraftsmanGarage && setTask ? (
                <EditTextModal
                  isRequired={true}
                  label={
                    task.kind === 'VehicleInspectionTask'
                      ? t('general.company')
                      : t('general.name')
                  }
                  placeholder={
                    task.kind === 'VehicleInspectionTask'
                      ? t('general.company')
                      : t('general.name')
                  }
                  value={task.assigneeGarage.name}
                  requiredCapabilities="EditVehicleTask"
                  onSave={(value: string | undefined) => {
                    if (value) {
                      patchVehicleTask(
                        task,
                        produce(task, (draftState) => {
                          draftState.assigneeGarage.name = value;
                        }),
                        token,
                      ).then((result) => {
                        if (result.status === 'success') {
                          setTask(result);
                          balModalController.dismiss();
                        } else if (result.status === 'error') {
                          toast(ErrorToast(result.errorValue));
                        }
                      });
                    }
                  }}
                />
              ) : undefined
            }
          />
          <InfoDisplay
            title={t('general.address')}
            text={concatAddress(task.assigneeGarage.address)}
            editComponent={
              !isCraftsmanGarage ? (
                <AddressEditButton
                  requiredCapabilities={['EditVehicleTask']}
                  initialValue={
                    task.assigneeGarage.address ??
                    getDefaultAddressWithPartnerId(task.assigneeGarage.id)
                  }
                  onSave={async (address: AddressDto) => {
                    if (address) {
                      const result = await patchVehicleTask(
                        task,
                        produce(task, (draftState) => {
                          draftState.assigneeGarage.address = {
                            partnerId: draftState.assigneeGarage.id,
                            ...address,
                          };
                        }),
                        token,
                      );
                      if (result.status === 'success') {
                        setTask(result);
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    }
                  }}
                ></AddressEditButton>
              ) : undefined
            }
          />
          {task.kind === 'VehicleInspectionTask' && (
            <>
              <InfoDisplay
                title={t('general.phone')}
                text={task.assigneeGarage.phoneNumber}
                editComponent={
                  !isCraftsmanGarage && setTask ? (
                    <EditTextModal
                      isRequired={true}
                      label={t('general.phone')}
                      placeholder={t('general.phone')}
                      value={task.assigneeGarage.phoneNumber}
                      requiredCapabilities="EditVehicleTask"
                      onSave={(value: string | undefined) => {
                        if (value) {
                          patchVehicleTask(
                            task,
                            produce(task, (draftState) => {
                              draftState.assigneeGarage.phoneNumber =
                                formatPhoneNumberIntl(value);
                            }),
                            token,
                          ).then((result) => {
                            if (result.status === 'success') {
                              setTask(result);
                              balModalController.dismiss();
                            } else if (result.status === 'error') {
                              toast(ErrorToast(result.errorValue));
                            }
                          });
                        }
                      }}
                      onValidate={(value: string) => {
                        const rawNumber = value.replaceAll(' ', '');
                        return isValidPhoneNumber(rawNumber)
                          ? true
                          : t('validators.invalidPhoneNumber');
                      }}
                    />
                  ) : undefined
                }
              />
              <InfoDisplay
                title={t('general.email')}
                text={task.assigneeGarage.email}
                editComponent={
                  !isCraftsmanGarage && setTask ? (
                    <EditTextModal
                      isRequired={false}
                      label={t('general.email')}
                      placeholder={t('general.email')}
                      value={task.assigneeGarage.email}
                      requiredCapabilities="EditVehicleTask"
                      onSave={(value: string | undefined) => {
                        if (value) {
                          patchVehicleTask(
                            task,
                            produce(task, (draftState) => {
                              draftState.assigneeGarage.email = value;
                            }),
                            token,
                          ).then((result) => {
                            if (result.status === 'success') {
                              setTask(result);
                              balModalController.dismiss();
                            } else if (result.status === 'error') {
                              toast(ErrorToast(result.errorValue));
                            }
                          });
                        }
                      }}
                      onValidate={(value: string) =>
                        BalValidators.isEmail()(value)
                          ? true
                          : t('validators.invalidEmail')
                      }
                    />
                  ) : undefined
                }
              />
            </>
          )}
          {task.assigneeGarageCraftsmanContactMethod && (
            <InfoDisplay
              title={t('vehicleTask.policyHolderContactMethod')}
              text={t(
                `policyHolderContactMethodType.${task.assigneeGarageCraftsmanContactMethod}`,
              )}
            />
          )}
          {task.assigneeGarageCraftsmanRemarks && (
            <InfoDisplay
              title={t('vehicleTask.assigneeGarageCraftsmanRemarks')}
              text={task.assigneeGarageCraftsmanRemarks}
            />
          )}
          {task.kind !== 'VehicleRiskAssessment' && (
            <InfoDisplay
              title={t('vehicleTask.expertiseOrderReport.reportedBy')}
              text={task.reportedBy}
              editComponent={
                setTask && (
                  <EditTextModal
                    isRequired={true}
                    label={t('vehicleTask.expertiseOrderReport.reportedBy')}
                    placeholder={t(
                      'vehicleTask.expertiseOrderReport.reportedBy',
                    )}
                    value={task.reportedBy}
                    requiredCapabilities="EditVehicleTask"
                    onSave={(value: string | undefined) => {
                      if (value) {
                        patchVehicleTask(
                          task,
                          produce(task, (draftState) => {
                            draftState.reportedBy = value;
                          }),
                          token,
                        ).then((result) => {
                          if (result.status === 'success') {
                            setTask(result);
                            balModalController.dismiss();
                          } else if (result.status === 'error') {
                            toast(ErrorToast(result.errorValue));
                          }
                        });
                      }
                    }}
                  />
                )
              }
            />
          )}
        </>
      )}
      <InfoDisplay
        title={t('vehicleTask.overlay.meeting')}
        text={
          task.appointmentEarliest
            ? formatDateTime(task.appointmentEarliest)
            : ''
        }
        editComponent={
          <EditDateModal
            isRequired={true}
            label={t('vehicleTask.overlay.meeting')}
            placeholder={t('vehicleTask.overlay.meeting')}
            value={task.appointmentEarliest?.substring(0, 10)}
            requiredCapabilities="EditVehicleTask"
            onSave={(value: string, updateToAdos: boolean) => {
              patchVehicleTask(
                task,
                produce(task, (draftState) => {
                  draftState.appointmentEarliest = value ?? ''; // internally we always use empty strings instead of null ones.
                }),
                token,
                updateToAdos,
              ).then((result) => {
                if (result.status === 'success') {
                  setTask(result);
                  balModalController.dismiss();
                } else if (result.status === 'error') {
                  toast(ErrorToast(result.errorValue));
                }
              });
            }}
          />
        }
      />
    </>
  );
};

const AdditionalInformation = ({
  task,
  setTask,
}: {
  task: VehicleTask;
  setTask: Dispatch<RequestResult<VehicleTask>>;
}) => {
  const token = useToken();
  const { t } = useTranslation();
  const regress: TranslatedSelection[] = Globals.vehicleRegress.map(
    (regress) => ({
      value: regress,
      translatedValue: t(`vehicleRegress.${regress}`),
    }),
  );

  return (
    <>
      <InfoDisplay
        title={t('vehicleTask.overlay.cover')}
        text={task.coverage}
        editComponent={
          <EditTextModal
            isRequired={true}
            label={t('vehicleTask.overlay.cover')}
            placeholder={t('vehicleTask.overlay.cover')}
            value={task.coverage}
            requiredCapabilities="EditVehicleTask"
            onSave={(value: string | undefined) => {
              if (value) {
                patchVehicleTask(
                  task,
                  produce(task, (draftState) => {
                    draftState.coverage = value;
                  }),
                  token,
                ).then((result) => {
                  if (result.status === 'success') {
                    setTask(result);
                    balModalController.dismiss();
                  } else if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                });
              }
            }}
          />
        }
      />
      <InfoDisplay
        title={t('vehicleTask.overlay.regress')}
        text={t(`vehicleRegress.${task.regress}`)}
        editComponent={
          <EditSelectionModal
            label={t('vehicleTask.overlay.regress')}
            placeholder={t('vehicleTask.overlay.cover')}
            value={{
              value: task.regress,
              translatedValue: t(`vehicleRegress.${task.regress}`),
            }}
            values={regress}
            requiredCapabilities="EditVehicleTask"
            onSave={(value: VehicleRegress) => {
              if (value) {
                patchVehicleTask(
                  task,
                  produce(task, (draftState) => {
                    draftState.regress = value;
                  }),
                  token,
                ).then((result) => {
                  if (result.status === 'success') {
                    setTask(result);
                    balModalController.dismiss();
                  } else if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                });
              }
            }}
          />
        }
      />
      <InfoDisplay
        title={t('vehicleTask.overlay.printDossier')}
        text={
          task.dossierPrint
            ? t('vehicleTask.overlay.yesPrintDossier')
            : t('vehicleTask.overlay.noPrintDossier')
        }
        editComponent={
          <EditCheckboxModal
            label={t('vehicleTask.overlay.printDossier')}
            text={t('vehicleTask.overlay.printDossier')}
            value={task.dossierPrint}
            requiredCapabilities="EditVehicleTask"
            onSave={(value: boolean) => {
              patchVehicleTask(
                task,
                produce(task, (draftState) => {
                  draftState.dossierPrint = value;
                }),
                token,
              ).then((result) => {
                if (result.status === 'success') {
                  setTask(result);
                  balModalController.dismiss();
                } else if (result.status === 'error') {
                  toast(ErrorToast(result.errorValue));
                }
              });
            }}
          />
        }
      />
      <InfoDisplay
        title={t('vehicleTask.vehicleClaimInformation.liability')}
        text={task.liability}
        editComponent={
          <EditNumberModal
            isRequired={false}
            label={t('vehicleTask.vehicleClaimInformation.liability')}
            placeholder={t('vehicleTask.vehicleClaimInformation.liability')}
            value={task.liability?.toString()}
            requiredCapabilities="EditVehicleTask"
            onSave={(value: string | undefined) => {
              patchVehicleTask(
                task,
                produce(task, (draftState) => {
                  draftState.liability = value ?? '';
                }),
                token,
              ).then((result) => {
                if (result.status === 'success') {
                  setTask(result);
                  balModalController.dismiss();
                } else if (result.status === 'error') {
                  toast(ErrorToast(result.errorValue));
                }
              });
            }}
          />
        }
      />
    </>
  );
};

const ClaimInformation = ({
  task,
  setTask,
}: {
  task: VehicleTask;
  setTask: Dispatch<RequestResult<VehicleTask>>;
}) => {
  const { t } = useTranslation();
  const token = useToken();
  const causeDescription = useDescriptionOfBaloiseCode(task.claim.cause);
  const kindDescription = useDescriptionOfBaloiseCode(task.claim.kind);

  const branchTranslatedSelections: TranslatedSelection[] =
    Globals.allBranches.map((branch) => ({
      value: branch,
      translatedValue: t(`branch.${branch}`),
    }));

  return (
    <div>
      {task.kind !== 'VehicleRiskAssessment' && (
        <InfoDisplay
          title={t('general.branch')}
          text={t(`branch.${task.claim.branch}`)}
          className="pb-1"
          editComponent={
            <EditSelectionModal
              label={t('general.branch')}
              placeholder={t('general.branch')}
              value={{
                value: task.claim.branch,
                translatedValue: t(`branch.${task.claim.branch}`),
              }}
              values={branchTranslatedSelections}
              requiredCapabilities="EditVehicleTask"
              onSave={(branch: Branch) => {
                if (branch) {
                  patchVehicleTask(
                    task,
                    produce(task, (draftState) => {
                      draftState.claim.branch = branch;
                    }),
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result);
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }
              }}
            />
          }
        />
      )}
      {task.claim.majorEventNumber && (
        <InfoDisplay
          title={t('overlay.majorEvent')}
          text={task.claim.majorEventNumber.toString()}
          className="pb-1"
        />
      )}
      <InfoDisplay
        title={t('overlay.policyNumber')}
        text={task.claim.policyNumber}
        className="pb-1"
        editComponent={
          <EditTextModal
            isRequired={true}
            label={t('general.claim.policyNumber')}
            placeholder={t('general.claim.policyNumber')}
            value={task.claim.policyNumber}
            formatBeforeEdit={cleanPolicyNumber}
            requiredCapabilities="EditVehicleTask"
            onSave={(value: string | undefined) => {
              if (value) {
                patchVehicleTask(
                  task,
                  produce(task, (draftState) => {
                    draftState.claim.policyNumber = value;
                  }),
                  token,
                ).then((result) => {
                  if (result.status === 'success') {
                    setTask(result);
                    balModalController.dismiss();
                  } else if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                });
              }
            }}
          />
        }
      />
      {task.kind !== 'VehicleRiskAssessment' && (
        <>
          <InfoDisplay
            title={t('overlay.insuranceType')}
            text={t(`insuranceType.${task.claim.insuranceType}`)}
            className="pb-1"
            editComponent={
              <EditSelectionModal
                label={t('claimDetail.insuranceType')}
                placeholder={t('claimDetail.insuranceType')}
                value={{
                  value: task.claim.insuranceType,
                  translatedValue: t(
                    `insuranceType.${task.claim.insuranceType}`,
                  ),
                }}
                values={Globals.insuranceTypeTranslatedSelections()}
                requiredCapabilities="EditVehicleTask"
                onSave={(value: InsuranceType) => {
                  if (value) {
                    patchVehicleTask(
                      task,
                      produce(task, (draftState) => {
                        draftState.claim.insuranceType = value;
                      }),
                      token,
                    ).then((result) => {
                      if (result.status === 'success') {
                        setTask(result);
                        balModalController.dismiss();
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    });
                  }
                }}
              />
            }
          />
          <InfoDisplay
            title={t('overlay.accidentDate')}
            text={formatDateTime(task.claim.claimDate)}
            className="pb-1"
            editComponent={
              <EditDateModal
                isRequired={true}
                label={t('overlay.accidentDate')}
                placeholder={t('overlay.accidentDate')}
                value={task.claim.claimDate?.substring(0, 10)}
                requiredCapabilities="EditVehicleTask"
                onSave={(value: string, updateToAdos: boolean) => {
                  patchVehicleTask(
                    task,
                    produce(task, (draftState) => {
                      draftState.claim.claimDate = value ?? ''; // internally we always use empty strings instead of null ones.
                    }),
                    token,
                    updateToAdos,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result);
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }}
              />
            }
          />
          <InfoDisplay
            title={t('overlay.place')}
            text={concatAddress(task.claim.claimAddress)}
            className="pb-1"
            editComponent={
              <AddressEditButton
                requiredCapabilities={['EditVehicleTask']}
                initialValue={
                  task.claim.claimAddress ??
                  getDefaultAddressWithClaimId(task.claim.id)
                }
                onSave={async (address: AddressDto) => {
                  if (address) {
                    const result = await patchVehicleTask(
                      task,
                      produce(task, (draftState) => {
                        draftState.claim.claimAddress = {
                          claimId: draftState.claim.id,
                          ...address,
                        };
                      }),
                      token,
                    );
                    if (result.status === 'success') {
                      setTask(result);
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  }
                }}
              />
            }
          />

          {task.claim.cause && (
            <InfoDisplay
              title={t('overlay.claimCause')}
              text={causeDescription}
              className="pb-1"
            />
          )}
          {task.claim.kind && (
            <InfoDisplay
              title={t('overlay.claimKind')}
              text={kindDescription}
              className="pb-1"
            />
          )}
          <InfoDisplay
            title={t('vehicleTask.expertiseOrderReport.amountOfDamageCHF')}
            text={task.costs?.toString() ?? ' '}
            editComponent={
              <EditNumberModal
                isRequired={false}
                label={t('vehicleTask.expertiseOrderReport.amountOfDamageCHF')}
                placeholder={t(
                  'vehicleTask.expertiseOrderReport.amountOfDamageCHF',
                )}
                value={task.costs?.toString()}
                requiredCapabilities="EditVehicleTask"
                onSave={(value: string | undefined) => {
                  patchVehicleTask(
                    task,
                    produce(task, (draftState) => {
                      if (value) {
                        draftState.costs = value as unknown as number;
                      } else {
                        draftState.costs = undefined;
                      }
                    }),
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result);
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }}
              />
            }
          />
        </>
      )}
      {task.kind !== 'VehicleRiskAssessment' && (
        <>
          <div className="columns is-gapless mt-2">
            <BalText color="info" className="column is-4">
              <strong>{t('vehicleTask.vehicleClaimDamage.damageZone')}</strong>
              <EditDamageCoordinatesModal
                className="ml-2"
                label={t('vehicleTask.vehicleClaimDamage.damageZone')}
                value={task.damageCoordinates}
                requiredCapabilities="EditVehicleTask"
                onSave={(value: CoordinateDto[]) => {
                  patchVehicleTask(
                    task,
                    produce(task, (draftState) => {
                      draftState.damageCoordinates = value;
                    }),
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result);
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }}
              />
            </BalText>

            <ImageMarker
              src={vehicleDamage}
              markers={task.damageCoordinates}
              markerComponent={() => <span>✕</span>}
            />
          </div>
          <div
            className={
              'mt-3 is-flex is-flex-row is-flex-direction-column bottom-blue-line'
            }
          >
            <BalText className="m-0 p-0" color="info">
              <strong>{t('vehicleTask.overlay.whiplash')}</strong>
            </BalText>

            <div className="is-flex is-justify-content-space-between">
              <BalCheckbox
                className="unclickable"
                checked={task.whiplashClarificationRearEnd}
              >
                {t('vehicleTask.vehicleClaimDamage.ifRear')}
              </BalCheckbox>
              <EditCheckboxModal
                className="is-align-self-center"
                label={t('vehicleTask.overlay.whiplash')}
                text={t('vehicleTask.vehicleClaimDamage.ifRear')}
                value={task.whiplashClarificationRearEnd}
                requiredCapabilities="EditVehicleTask"
                onSave={(value: boolean) => {
                  patchVehicleTask(
                    task,
                    produce(task, (draftState) => {
                      draftState.whiplashClarificationRearEnd = value;
                    }),
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result);
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }}
              />
            </div>
            <div className="is-flex is-justify-content-space-between">
              <BalCheckbox
                className="unclickable"
                checked={task.whiplashClarificationFrontal}
              >
                {t('vehicleTask.vehicleClaimDamage.ifFront')}
              </BalCheckbox>
              <EditCheckboxModal
                className="is-align-self-center"
                label={t('vehicleTask.overlay.whiplash')}
                text={t('vehicleTask.vehicleClaimDamage.ifFront')}
                value={task.whiplashClarificationFrontal}
                requiredCapabilities="EditVehicleTask"
                onSave={(value: boolean) => {
                  patchVehicleTask(
                    task,
                    produce(task, (draftState) => {
                      draftState.whiplashClarificationFrontal = value;
                    }),
                    token,
                  ).then((result) => {
                    if (result.status === 'success') {
                      setTask(result);
                      balModalController.dismiss();
                    } else if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  });
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const VehicleTaskDetailContent = ({
  setCombinedState,
  combinedState,
}: {
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  combinedState: CombinedState;
}): JSX.Element => {
  const reloadTable = () => {
    if (combinedState.tasks.status === 'success') {
      setCombinedState(
        produce(combinedState, (draftState: WritableDraft<CombinedState>) => {
          draftState.tasks = { status: 'loading' };
        }),
      );
    }
  };
  const token = useToken();
  const { t } = useTranslation();
  const { taskId } = useParams<VehicleTaskDetailId>();
  const [vehicleTask, setVehicleTask] = useVehicleTaskFromApi(taskId ?? '');
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabDefault = useRef<HTMLBalTabItemElement>(null);
  const refTabAttachments = useRef<HTMLBalTabItemElement>(null);
  const [currentDefaultTab, setCurrentDefaultTab] = useState<
    string | undefined
  >(refTabDefault.current?.value);
  const [vehicleTaskHistory, setVehicleTaskHistory] = useState<
    RequestResult<BaseTaskChangeDto[]> | undefined
  >(undefined);

  useEffect(() => {
    if (vehicleTask.status === 'success') {
      if (currentDefaultTab && currentDefaultTab === 'protocol') {
        if (taskId) {
          setVehicleTaskHistory({
            status: 'loading',
          });
          getVehicleTaskAllHistory(token, taskId).then((result) => {
            if (result.status === 'success') {
              setVehicleTaskHistory(result);
            }
          });
        }
      }
    }
  }, [vehicleTask, currentDefaultTab, token, taskId]);

  if (vehicleTask.status === 'success') {
    const assignee = vehicleTask.value.assignee;
    const claim = vehicleTask.value.claim;
    const creator = vehicleTask.value.creator;
    const assigneeName = getDisplayNameFromUser(assignee);
    const creatorName = getDisplayNameFromUser(creator);
    const lastLocationKey: RouteKey = 'VEHICLE_INSPECTION_TASK';
    const policyHolderPartner =
      claim.policyHolder.type === 'person'
        ? claim.policyHolder.person
        : claim.policyHolder.company;

    const removeFile = async (fileName: string) => {
      if (
        vehicleTask.status == 'success' &&
        vehicleTask.localValue.attachments
      ) {
        const attachmentToDelete = vehicleTask.localValue.attachments.find(
          (attachment: AttachmentDto) => attachment.fileName == fileName,
        );
        if (attachmentToDelete) {
          const result = await deleteFile(token, attachmentToDelete);

          if (
            result.status === 'success-no-value' ||
            result.status === 'success'
          ) {
            const newAttachments = vehicleTask.localValue.attachments.filter(
              (attachment: AttachmentDto) => attachment.fileName !== fileName,
            );
            setVehicleTask(
              produce(vehicleTask, (draftState) => {
                draftState.localValue.attachments = newAttachments;
              }),
            );
          }

          if (result.status === 'error') {
            toast(ErrorToast(result.errorValue));
          }
        }
      }
    };

    const languages: TranslatedSelection[] = Globals.languages.map(
      (language) => ({
        value: language,
        translatedValue: t(`language.languages.${language}`),
      }),
    );

    const openSelectCreatorModal = async () => {
      const modalProps: SelectUserModalProps = {
        initialSelected: vehicleTask.value.creator,
        onSelectedSuccess: (user: UserDto) => {
          patchVehicleTask(
            vehicleTask.value,
            produce(vehicleTask.value, (draftState) => {
              draftState.creator = user;
            }),
            token,
          ).then((result) => {
            if (result.status === 'success') {
              setVehicleTask(result);
              balModalController.dismiss();
            } else if (result.status === 'error') {
              toast(ErrorToast(result.errorValue));
            }
          });
        },
        appCapabilityNames: undefined,
      };
      const modal = await balModalController.create({
        component: SelectUserModal,
        componentProps: modalProps,
        cssClass: 'center-modal',
        backdropDismiss: false,
      });
      return modal.present();
    };

    return (
      <div className="is-full-height has-background-grey-1 is-flex is-flex-direction-column">
        <div className="has-background-white mr-6 mt-6 ml-6 mb-4 pt-6 pl-6 overflow-hidden detail-border-shadow detail-content">
          <div className="pr-5">
            {vehicleTask.value.deletedAt && (
              <BalNotification color="danger">
                <BalText>
                  {t('claimDetail.warningMessageSoftDeleted', {
                    dateTime: convertUtcToLocal({
                      date: vehicleTask.value.deletedAt,
                    }),
                  })}
                </BalText>
              </BalNotification>
            )}
            {vehicleTask.value.status === 'New' && (
              <BalNotification className="mt-1" color="info">
                <BalText>{t('claimDetail.warningMessageDraft')}</BalText>
              </BalNotification>
            )}
            {vehicleTask.value.claim.createdManually && (
              <BalNotification className="mt-1" color="warning">
                <BalText>
                  {t('claimDetail.warningMessageCreatedManually')}
                </BalText>
              </BalNotification>
            )}
          </div>
          <div
            className={`mt-5 is-flex is-flex-direction-column is-full-width ${getFullHeightWarningCssClass(
              vehicleTask,
            )} position-relative`}
          >
            <div className="is-full-width detail-header is-flex is-flex-direction-column is-justify-content-center">
              <Breadcrumbs
                breadcrumbs={[
                  {
                    label: t(`EBRoutes.${lastLocationKey}`),
                    path: EBRoutes[lastLocationKey].create(),
                  },
                  {
                    label: claim.claimNumber,
                  },
                ]}
              />
              <BalHeading
                level="h3"
                subtitle
                space="none"
                className="mt-1 mb-1 is-flex"
              >
                {vehicleTask.localValue.kind === 'VehicleRiskAssessment'
                  ? concat([t('claimDetail.policyNumber'), claim.policyNumber])
                  : concat([t('claimDetail.claim'), claim.claimNumber])}
                {vehicleTask.localValue.kind !== 'VehicleRiskAssessment' &&
                  taskId && (
                    <>
                      <CopyButton
                        className={'ml-3'}
                        copyValue={claim.claimNumber
                          .replaceAll('/', '')
                          .replace('.', '')}
                      />
                      <TaskJumpAdos
                        className="ml-3 is-inline-block"
                        task={vehicleTask}
                      />
                    </>
                  )}
              </BalHeading>
              <BalText size="small" className="has-text-black mb-4 mt-2">
                {concat([
                  formatDateTime(vehicleTask.value.createdAt),
                  t('general.from'),
                  getDisplayNameFromUser(vehicleTask.value.creator),
                  concat(
                    ['(', vehicleTask.value.creator?.insuranceCenter.name, ')'],
                    'none',
                  ),
                ])}
              </BalText>
            </div>
            <div className="is-gapless detail-overview">
              <div className="is-full-height scroll-bar-minimal">
                <div className="columns is-multiline mr-1">
                  <div className="column is-12">
                    {/* Task description section */}
                    <div className="is-flex is-align-items-center mb-2 mt-1">
                      <BalHeading space="none" level="h4" color="info" subtitle>
                        {t('general.taskDescription')}
                      </BalHeading>
                      <div className="pl-6">
                        <BaseTaskMemoFieldsButton
                          requiredCapabilities={['EditVehicleTask']}
                          task={vehicleTask}
                          setTask={setVehicleTask}
                          taskType={TaskTypes.VehicleTask}
                          includeWhatField={
                            vehicleTask.value.kind !== 'VehicleRiskAssessment'
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    {vehicleTask.value.kind !== 'VehicleRiskAssessment' &&
                      vehicleTask.value.what && (
                        <>
                          <BalText space="none" color="info" bold size="small">
                            {t('vehicleTask.overlay.accidentCourse')}
                          </BalText>
                          <BalText className="enable-line-break">
                            {vehicleTask.value.what}
                          </BalText>
                        </>
                      )}
                    {vehicleTask.value.remarks && (
                      <>
                        <BalText space="none" color="info" bold size="small">
                          {t('general.claim.remarks')}
                        </BalText>
                        <BalText className="enable-line-break">
                          {vehicleTask.value.remarks}
                        </BalText>
                      </>
                    )}

                    <div className="columns is-gapless">
                      <div className="column is-5">
                        <BalText space="none" color="info" bold size="small">
                          {t('vehicleTask.quicknotes')}
                        </BalText>
                        <BalText className="enable-line-break">
                          {vehicleTask.value.quickNotes}
                        </BalText>
                      </div>
                      <WithCapabilities
                        className="is-fullwidth is-flex is-flex-direction-row is-justify-content-space-between	"
                        requiredCapabilities={[
                          'CreateVehicleTask',
                          'EditVehicleTask',
                        ]}
                        passWithCapabilitiesPropsToChildren
                      >
                        <EditTextModal
                          buttonClassName="pl-6"
                          label={t('vehicleTask.quicknotes')}
                          placeholder={t('vehicleTask.quicknotes')}
                          value={vehicleTask.value.quickNotes}
                          requiredCapabilities="EditVehicleTask"
                          onSave={(value: string) => {
                            patchVehicleTask(
                              vehicleTask.value,
                              produce(vehicleTask.value, (draftState) => {
                                draftState.quickNotes = value;
                              }),
                              token,
                            ).then((result) => {
                              if (result.status === 'success') {
                                setVehicleTask(result);
                                balModalController.dismiss();
                              } else if (result.status === 'error') {
                                toast(ErrorToast(result.errorValue));
                              }
                            });
                          }}
                          isRequired={false}
                        />
                      </WithCapabilities>
                    </div>
                    {vehicleTask.value.internalNote && (
                      <>
                        <BalText space="none" color="info" bold size="small">
                          {t('baseTaskDtoColumns.internalNote')}
                        </BalText>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: vehicleTask.value.internalNote ?? '',
                          }}
                        ></div>
                      </>
                    )}
                  </div>
                  <div className="column is-6">
                    <BalHeading
                      className="mb-3"
                      level="h4"
                      space="none"
                      color="info"
                      subtitle
                    >
                      {vehicleTask.localValue.kind === 'VehicleRiskAssessment'
                        ? t('vehicleTask.kinds.VehicleRiskAssessmentTask')
                        : t('general.claim.claimInformation')}
                    </BalHeading>
                    {vehicleTask.value.claim && (
                      <ClaimInformation
                        task={vehicleTask.value}
                        setTask={setVehicleTask}
                      />
                    )}
                    {vehicleTask.value.affectedVehicle && (
                      <>
                        <BalHeading
                          space="none"
                          level="h4"
                          color="info"
                          subtitle
                          className="mt-3"
                        >
                          {t('vehicleTask.overlay.vehicleInformation')}
                        </BalHeading>
                        <VehicleInfo
                          task={vehicleTask.value}
                          setTask={setVehicleTask}
                        />
                      </>
                    )}
                    {vehicleTask.value.assigneeExternal && (
                      <div className="is-flex is-align-items-center">
                        <BalHeading
                          level="h4"
                          color="info"
                          subtitle
                          space="none"
                          className="mt-3"
                        >
                          {t('vehicleTask.independentPartner')}
                        </BalHeading>
                      </div>
                    )}
                    <div>
                      {vehicleTask.value.assigneeExternal && (
                        <>
                          <InfoDisplay
                            title={t('general.name')}
                            text={
                              vehicleTask.value.assigneeExternal?.name ?? ''
                            }
                            className="pb-1"
                          />
                          <InfoDisplay
                            title={t('general.address')}
                            text={
                              concatAddress(
                                vehicleTask.value.assigneeExternal?.address,
                              ) ?? ''
                            }
                            className="pb-1"
                          />
                          <InfoDisplay
                            title={t('general.phone')}
                            text={
                              vehicleTask.value.assigneeExternal?.phoneNumber ??
                              ''
                            }
                            className="pb-1"
                          />
                          <InfoDisplay
                            title={t('general.email')}
                            text={vehicleTask.value.assigneeExternal.email}
                            className="pb-1"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="column is-6">
                    <div>
                      <VehicleTaskDetailContactsCards
                        task={vehicleTask}
                        setTask={setVehicleTask}
                        patchTask={patchVehicleTask}
                        detailView
                      />
                    </div>
                    {vehicleTask.localValue.kind !==
                      'VehicleRiskAssessment' && (
                      <div>
                        <BalHeading
                          space="none"
                          level="h4"
                          color="info"
                          subtitle
                          className="mt-3"
                        >
                          {t('claimDetail.policyInformation')}
                        </BalHeading>
                        <PolicyInfo
                          task={vehicleTask.value}
                          setTask={setVehicleTask}
                        ></PolicyInfo>
                      </div>
                    )}
                    <div>
                      <BalHeading
                        space="none"
                        level="h4"
                        color="info"
                        subtitle
                        className="mt-3"
                      >
                        {t('vehicleTask.overlay.inspection')}
                      </BalHeading>
                      <Visitation
                        task={vehicleTask.value}
                        setTask={setVehicleTask}
                      />
                    </div>
                    {vehicleTask.localValue.kind !==
                      'VehicleRiskAssessment' && (
                      <>
                        <BalHeading
                          space="none"
                          level="h4"
                          color="info"
                          subtitle
                          className="mt-3"
                        >
                          {t('overlay.additionalInformation')}
                        </BalHeading>
                        <AdditionalInformation
                          task={vehicleTask.value}
                          setTask={setVehicleTask}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-menu-actions-vehicle is-pulled-right is-full-height">
              <VehicleTaskWorkflowButtons
                task={vehicleTask.value}
                className="detail-header is-flex is-align-items-center ml-1"
                onChangeSuccess={(task: RequestResult<VehicleTask>) => {
                  setVehicleTask(task);
                  reloadTable();
                }}
              />
              <div className="detail-tabs">
                <BalTabs
                  ref={refTabs}
                  className="has-background-grey-1 p-4 detail-information-content"
                  value={currentDefaultTab ? currentDefaultTab : 'details'}
                  onBalChange={() => {
                    if (refTabs.current?.value) {
                      setCurrentDefaultTab(refTabs.current?.value);
                    }
                  }}
                >
                  <BalTabItem
                    className="bal-tab-headings"
                    value="details"
                    label={t('general.details')}
                    ref={refTabDefault}
                  >
                    <TaskDetailInfoDisplay
                      title={t('baseTaskListDtoColums.priority')}
                      text={
                        vehicleTask.localValue.priority
                          ? t(
                              `baseTaskPriorities.${vehicleTask.localValue.priority}`,
                            )
                          : ''
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={t('assignee.assigneeVehicle')}
                      text={assigneeName}
                      user={assignee}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.taskCreator')}
                      text={creatorName}
                      user={creator}
                      editComponent={
                        vehicleTask.localValue.kind ===
                        'VehicleRiskAssessment' ? (
                          <BalButton
                            onClick={(event) => {
                              event.detail == 1 && openSelectCreatorModal();
                            }}
                            square
                            outlined
                            className="ml-3"
                            size="small"
                            color="info"
                            icon="edit"
                          ></BalButton>
                        ) : undefined
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={t('language.reportLabel')}
                      text={t(
                        `language.languages.${vehicleTask.value.language}`,
                      )}
                      editComponent={
                        <EditSelectionModal
                          label={t('language.reportLabel')}
                          placeholder={t('language.reportLabel')}
                          value={{
                            value: vehicleTask.value.language,
                            translatedValue: t(
                              `language.languages.${vehicleTask.value.language}`,
                            ),
                          }}
                          values={languages}
                          requiredCapabilities="EditFraudInspectionTask"
                          onSave={(value: string) => {
                            patchVehicleTask(
                              vehicleTask.value,
                              produce(vehicleTask.value, (draftState) => {
                                draftState.language = value as Language;
                              }),
                              token,
                            ).then((result) => {
                              if (result.status === 'success') {
                                setVehicleTask(result);
                                balModalController.dismiss();
                              } else if (result.status === 'error') {
                                toast(ErrorToast(result.errorValue));
                              }
                            });
                          }}
                        />
                      }
                    />
                    {vehicleTask.value.kind !== 'VehicleRiskAssessment' && (
                      <TaskDetailInfoDisplay
                        title={t('vehicleTask.independentPartner')}
                        text={vehicleTask.value.assigneeExternal?.name}
                      />
                    )}
                    <TaskDetailInfoDisplay
                      title={t('general.claim.status')}
                      taskStatus={vehicleTask.value.status}
                    />
                    {vehicleTask.value.kind !== 'VehicleRiskAssessment' && (
                      <TaskDetailInfoDisplay
                        title={t('general.branch')}
                        text={t(`branch.${claim.branch}`)}
                      />
                    )}
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.policyNumber')}
                      text={claim.policyNumber}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.policyHolderShort')}
                      text={concat([
                        claim.policyHolder.type === 'person'
                          ? claim.policyHolder.person.displayName
                          : claim.policyHolder.company.displayName,
                        policyHolderPartner.address?.addressString ?? '',
                      ])}
                    />
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'attachments'
                        ? 'attachments-tab'
                        : ''
                    }`}
                    value="attachments"
                    label={t('general.claim.attachments')}
                    ref={refTabAttachments}
                  >
                    <div className="tab-item-content content-container is-flex is-flex-direction-column attachments-tab pr-0">
                      <BaseTaskAttachments
                        attachments={vehicleTask.localValue.attachments}
                        removeFile={removeFile}
                        withScrollbar
                      />
                      <FileUpload
                        entityType={TaskTypes.VehicleTask}
                        className="pb-0"
                        entity={vehicleTask}
                        setEntity={setVehicleTask}
                        hasFileList={false}
                      />
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings scroll-bar-minimal ${
                      currentDefaultTab === 'protocol' ? 'protocol-tab' : ''
                    }`}
                    value="protocol"
                    label={t('claimDetail.protocol')}
                  >
                    <div className="tab-item-content">
                      {vehicleTaskHistory != null && (
                        <div className="pt-2">
                          <ResultRenderer
                            result={vehicleTaskHistory}
                            defaultValue={[]}
                            loaded={(tasksResponse) => {
                              return (
                                <EntityProtocol
                                  history={tasksResponse}
                                  maxMessageLength={50}
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </BalTabItem>
                </BalTabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};
