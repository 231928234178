import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { balModalController } from '../../../../controller/controllers';
import {
  DisabableButton,
  WithCapabilities,
} from '../../../../components/authorization';
import { patchVehicleTask } from '../../data/requests';
import { VehicleTask } from '../../../../types/types';
import { BaseTaskCompletion } from '../../../base-task';

type VehicleTaskCompletionProps = {
  task: VehicleTask;
  onChangeSuccess: (task: RequestResult<VehicleTask>) => void;
  customCompletionTitle?: string;
  customCompletionText?: string;
};

export const VehicleTaskCompletion = (
  props: VehicleTaskCompletionProps,
): JSX.Element => {
  const { t } = useTranslation();
  const openModal = async () => {
    const modal = await balModalController.create({
      component: BaseTaskCompletion,
      componentProps: {
        ...props,
        patchBaseTask: patchVehicleTask,
        showRemarks: props.task.kind === 'VehicleRiskAssessment',
      },
      cssClass: 'center-modal',
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditVehicleTask']}
      passWithCapabilitiesPropsToChildren
    >
      <DisabableButton
        onClick={(event) => event.detail == 1 && openModal()}
        size="small"
        color="success"
      >
        {t('general.buttons.close')}
      </DisabableButton>
    </WithCapabilities>
  );
};
