import { useTranslation } from 'react-i18next';
import { InfoDisplay } from '../../../components/ui';
import { BaseTaskListDto } from '../../../types/resource-models';
import { TaskTypes } from '../../../types/types';
import { concat, getAssigneePerTypeTranslated } from '../../../utils';
import { formatDateTime } from '../../../utils/date';
import { getDisplayNameFromUser } from '../../../utils/utilities';

type BaseTaskDetailInformationProps = {
  taskInformation: BaseTaskListDto;
  className?: string;
  taskType: TaskTypes;
};

const BaseTaskDetailInformation = ({
  taskInformation,
  className,
  taskType,
}: BaseTaskDetailInformationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={concat(['columns is-multiline is-gapless', className])}>
      <div className="column is-full">
        <InfoDisplay
          title={t('general.claim.claimNumber')}
          text={taskInformation.claim.claimNumber}
          className="pb-1"
        />
      </div>
      <div className="column is-full">
        <InfoDisplay
          title={t('taskOverview.createdAt')}
          text={formatDateTime(taskInformation.claim.createdAt)}
          className="pb-1"
        />
      </div>
      {taskInformation.assignee && (
        <div className="column is-full">
          <InfoDisplay
            title={getAssigneePerTypeTranslated(taskType)}
            text={getDisplayNameFromUser(taskInformation.assignee)}
            className="pb-1"
          />
        </div>
      )}
      <div className="column is-full">
        <InfoDisplay
          title={t('general.claim.status')}
          text={t(`baseTaskStatus.${taskInformation.status}`)}
          className="pb-1"
        />
      </div>
    </div>
  );
};

export default BaseTaskDetailInformation;
