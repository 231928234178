import { route } from 'react-route-type';
import { InsuranceSurveyFilterCriteriaDto } from '../../../bat-shared/resource-models';

import {
  AccidentInspectionTaskFilterCriteriaDto,
  BaseFilterCriteria,
  CraftsmanFilterCriteria,
  InvestigationTaskFilterCriteriaDto,
  InvestigationTaskJournalFilterCriteria,
  PropertyInspectionTaskFilterCriteriaDto,
  PropertyInspectionTaskJournalFilterCriteria,
  UserFilterCriteria,
  VehicleTaskFilterCriteriaDto,
} from '../types/resource-models';

export const EBRoutes = {
  HOME: route(''),
  LOGIN: route('login'),
  LOGOUT: route('logout'),
  VEHICLE_INSPECTION_TASK_WIZARD: route('vehicle-inspection-task-wizard'),
  VEHICLE_RISK_ASSESSMENT_WIZARD: route('vehicle-risk-assessment-wizard'),
  VEHICLE_BONUS_CORRECTION_TASK_WIZARD: route(
    'vehicle-bonus-correction-task-wizard',
  ),
  VEHICLE_INSPECTION_TASK_GUIDELINES: route('vehicle-guidelines'),
  INVESTIGATION_SERVICE_WIZARD: route('investigation-service-wizard'),
  INVESTIGATION_TASK_WIZARD: route('investigation-task-wizard'),
  INVESTIGATION_TASK_DETAIL: route({
    // please maintain path also in appsettings.json file.
    path: ['investigation-task', ':taskId'],
  }),
  INVESTIGATION_TASK: route({
    path: 'investigation-task',
    hasNested: true,
  }),
  PROFILE: route('profile'),
  ACCIDENT_INSPECTION_TASK_WIZARD: route('accident-inspection-task-wizard'),
  ACCIDENT_INSPECTION_TASK_DETAIL: route({
    // please maintain path also in appsettings.json file.
    path: ['accident-inspection-task', ':taskId'],
  }),
  ACCIDENT_INSPECTION_TASK: route({
    path: 'accident-inspection-task',
    hasNested: true,
  }),
  PROPERTY_INSPECTION_TASK_WIZARD: route('property-inspection-task-wizard'),
  PROPERTY_INSPECTION_TASK_SENDING_WIZARD: route({
    path: [
      'property-inspection-task',
      ':taskId',
      'sending-wizard',
      ':inspectionId',
    ],
  }),
  PROPERTY_INSPECTION_TASK: route({
    path: 'property-inspection-task',
    hasNested: true,
  }),
  PROPERTY_INSPECTION_TASK_DETAIL: route({
    // please maintain path also in appsettings.json file.
    path: ['property-inspection-task', ':taskId'],
  }),
  VEHICLE_INSPECTION_TASK: route({
    path: 'vehicle-task',
    hasNested: true,
  }),
  VEHICLE_INSPECTION_TASK_DETAIL: route({
    // please maintain path also in appsettings.json file.
    path: ['vehicle-task', ':taskId'],
  }),
  NETWORK_PARTNERS: route({
    path: 'network-partners',
    hasNested: true,
  }),
  NETWORK_PARTNER_DETAIL: route({
    path: ['network-partners', ':networkPartnerId'],
  }),
  ACCIDENT_TASK: route('accident-task'),
};

export type RouteKey = keyof typeof EBRoutes;

export type PropertiesToString<T> = {
  [K in keyof T as Capitalize<string & K>]: string;
};
export const APIRoutes = {
  GET_USERS: route<
    'users',
    PropertiesToString<UserFilterCriteria> &
      PropertiesToString<BaseFilterCriteria>
  >({
    path: 'users',
  }),
  PATCH_PROPERTY_INSPECTION_TASK: route({
    path: ['propertyInspectionTasks', ':inspectionTaskId'],
  }),
  PATCH_PROPERTY_INSPECTION_TASK_JOURNAL: route({
    path: ['propertyInspectionTasks', 'journal', ':journalId'],
  }),
  PATCH_PROPERTY_INSPECTION_TASK_RECIPIENT: route({
    path: ['propertyInspectionTasks', 'recipient', ':recipientId'],
  }),

  POST_PROPERTY_INSPECTION_TASK: route('propertyInspectionTasks'),
  POST_PROPERTY_INSPECTION_TASK_ATTACHMENT: route({
    path: ['propertyInspectionTasks', ':inspectionTaskId', 'attachments'],
  }),
  POST_PROPERTY_INSPECTION_TASK_JOURNAL: route([
    'propertyInspectionTasks',
    'journal',
  ]),
  POST_PROPERTY_INSPECTION_TASK_JOURNAL_ATTACHMENT: route({
    path: ['propertyInspectionTasks', 'journal', ':journalId', 'attachments'],
  }),
  POST_PROPERTY_INSPECTION_TASK_RECIPIENT: route([
    'propertyInspectionTasks',
    'recipient',
  ]),
  PUT_SEND_INSPECTION_TO_RECIPIENTS: route({
    path: ['propertyInspectionTasks', 'sendToRecipients'],
  }),
  GET_PROPERTY_INSPECTION_TASKS: route<
    'propertyInspectionTasks',
    PropertiesToString<PropertyInspectionTaskFilterCriteriaDto> &
      PropertiesToString<BaseFilterCriteria>
  >({ path: 'propertyInspectionTasks' }),
  GET_PROPERTY_INSPECTION_TASK_INSPECTIONS: route({
    path: [
      'propertyInspectionTasks',
      ':propertyInspectionTaskId',
      'inspections',
    ],
  }),
  DELETE_PROPERTY_INSPECTION_TASK_INSPECTION: route({
    path: [
      'propertyInspectionTasks',
      ':propertyInspectionTaskId',
      'inspections',
      ':inspectionId',
    ],
  }),
  PATCH_INSPECTION: route({
    path: ['inspections', ':inspectionId'],
  }),
  GET_PROPERTY_INSPECTION_TASK_PHOTOS: route({
    path: ['inspectionPhotos', ':inspectionId'],
  }),
  GET_PROPERTY_INSPECTION_TASK_PHOTO_BY_ID: route({
    path: ['inspectionPhotos', ':inspectionId', ':inspectionPhotoId'],
  }),
  PUT_PROPERTY_INSPECTION_TASK_PHOTO_REMARKS: route({
    path: [
      'inspectionPhotos',
      ':inspectionId',
      ':inspectionPhotoId',
      'remarks',
    ],
  }),
  POST_PROPERTY_INSPECTION_TASK_FAST_REPORT_PHOTO_BULK: route({
    path: ['inspectionPhotos', ':inspectionId', 'bulk'],
  }),
  DELETE_PROPERTY_INSPECTION_TASK_PHOTO: route({
    path: ['inspectionPhotos', ':inspectionId', ':inspectionPhotoId'],
  }),
  GET_BASE_TASK_EXPORT: route<
    ':path',
    PropertiesToString<PropertyInspectionTaskFilterCriteriaDto> &
      PropertiesToString<BaseFilterCriteria>
  >({
    path: ':path',
  }),
  GET_BASE_TASK: route({
    path: ':path',
  }),
  DELETE_PROPERTY_INSPECTION_TASK_RECIPIENT: route({
    path: ['propertyInspectionTasks', 'recipient', ':recipientId'],
  }),
  GET_PROPERTY_INSPECTION_TASK: route({
    path: ['propertyInspectionTasks', ':taskId'],
  }),
  GET_PROPERTY_INSPECTION_TASK_ALL_HISTORY: route({
    path: ['propertyInspectionTasks', ':taskId', 'all-history'],
  }),
  GET_PROPERTY_INSPECTION_TASK_REPORT: route({
    path: ['propertyInspectionTasks', ':taskId', 'report'],
  }),
  GET_PROPERTY_INSPECTION_TASK_INSPECTION_REPORT: route({
    path: [
      'propertyInspectionTasks',
      ':taskId',
      'inspections',
      ':inspectionId',
      'report',
    ],
  }),
  GET_PROPERTY_INSPECTION_TASK_RECIPIENTS: route({
    path: ['propertyInspectionTasks', ':taskId', 'getRecipients'],
  }),
  GET_PROPERTY_INSPECTION_TASK_RECIPIENT: route({
    path: ['propertyInspectionTasks', 'recipient', ':recipientId'],
  }),
  GET_PROPERTY_INSPECTION_TASK_JOURNALS: route<
    string,
    PropertiesToString<PropertyInspectionTaskJournalFilterCriteria> &
      PropertiesToString<BaseFilterCriteria>
  >({
    path: ['propertyInspectionTasks', 'journal'],
  }),
  GET_PROPERTY_INSPECTION_TASK_JOURNAL: route({
    path: ['propertyInspectionTasks', 'journal', ':journalId'],
  }),
  GET_PROPERTY_INSPECTION_TASK_JOURNAL_NEXT_ORDER: route({
    path: [
      'propertyInspectionTasks',
      ':propertyInspectionTaskId',
      'journal',
      'nextOrderNumber',
    ],
  }),
  GET_PROPERTY_INSPECTION_TASK_JOURNAL_HISTORY: route({
    path: ['propertyInspectionTasks', 'journal', ':journalId', 'all-history'],
  }),
  PATCH_ACCIDENT_INSPECTION_TASK: route({
    path: ['accidentInspectionTasks', ':accidentInspectionTaskId'],
  }),
  POST_ACCIDENT_INSPECTION_TASK: route('accidentInspectionTasks'),
  POST_ACCIDENT_INSPECTION_TASK_ATTACHMENT: route({
    path: [
      'accidentInspectionTasks',
      ':accidentInspectionTaskId',
      'attachments',
    ],
  }),
  GET_ACCIDENT_INSPECTION_TASK: route({
    path: ['accidentInspectionTasks', ':taskId'],
  }),
  GET_ACCIDENT_INSPECTION_TASKS: route<
    'accidentInspectionTasks',
    PropertiesToString<AccidentInspectionTaskFilterCriteriaDto> &
      PropertiesToString<BaseFilterCriteria>
  >({ path: 'accidentInspectionTasks' }),
  GET_ACCIDENT_INSPECTION_TASK_ALL_HISTORY: route({
    path: ['accidentInspectionTasks', ':taskId', 'all-history'],
  }),
  GET_ACCIDENT_INSPECTION_TASK_REPORT: route({
    path: ['accidentInspectionTasks', ':taskId', 'report'],
  }),
  GET_INVESTIGATION_TASKS: route<
    'investigationTasks',
    PropertiesToString<InvestigationTaskFilterCriteriaDto> &
      PropertiesToString<BaseFilterCriteria>
  >({
    path: 'investigationTasks',
  }),
  GET_NON_EXPERTS_INVESTIGATION_TASKS: route({
    path: ['investigationTasks', 'non-experts'],
  }),
  GET_INVESTIGATION_TASK_ALL_HISTORY: route({
    path: ['investigationTasks', ':taskId', 'all-history'],
  }),
  GET_INVESTIGATION_TASK_REPORT: route({
    path: ['investigationTasks', ':taskId', 'report'],
  }),
  DELETE_INVESTIGATION_TASK_EXPENSE: route({
    path: ['investigationTasks', ':taskId', 'expense', ':expenseId'],
  }),
  UPDATE_INVESTIGATION_TASK_EXPENSE: route({
    path: ['investigationTasks', ':taskId', 'expense'],
  }),
  GET_INVESTIGATION_TASK_JOURNALS: route<
    'journals',
    PropertiesToString<InvestigationTaskJournalFilterCriteria> &
      PropertiesToString<BaseFilterCriteria>
  >({
    path: 'journals',
  }),
  GET_INVESTIGATION_TASK_JOURNAL: route({
    path: ['journals', ':journalId'],
  }),
  GET_INVESTIGATION_TASK_JOURNAL_REPORT: route({
    path: ['journals', ':journalId', 'report'],
  }),
  GET_INVESTIGATION_TASK_JOURNAL_HISTORY: route({
    path: ['journals', ':journalId', 'all-history'],
  }),
  POST_INVESTIGATION_TASK_JOURNAL_ATTACHMENT: route({
    path: ['journals', ':journalId', 'attachments'],
  }),
  POST_INVESTIGATION_TASK_JOURNAL: route('journals'),
  PATCH_INVESTIGATION_TASK_JOURNAL: route({
    path: ['journals', ':journalId'],
  }),
  PUT_INVESTIGATION_TASK_JOURNAL_SENDTOADOS: route({
    path: ['journals', ':journalId', 'sendToAdos'],
  }),
  GET_INSURANCE_SURVEYS: route<
    'insuranceSurveys',
    PropertiesToString<InsuranceSurveyFilterCriteriaDto>
  >({
    path: 'insuranceSurveys',
  }),
  GET_INSURANCE_SURVEY: route({
    path: ['insuranceSurveys', ':insuranceSurveyId'],
  }),
  GET_INSURANCE_SURVEY_REPORT: route({
    path: ['insuranceSurveys', ':insuranceSurveyId', 'report'],
  }),
  GET_INSURANCE_SURVEY_HISTORY: route({
    path: ['insuranceSurveys', ':insuranceSurveyId', 'all-history'],
  }),
  POST_INSURANCE_SURVEY_ATTACHMENT: route({
    path: ['insuranceSurveys', ':insuranceSurveyId', 'attachments'],
  }),
  POST_INSURANCE_SURVEY: route('insuranceSurveys'),
  POST_ALL_INSURANCE_SURVEYS: route({
    path: ['insuranceSurveys', 'all-insurances'],
  }),
  PATCH_INSURANCE_SURVEY: route({
    path: ['insuranceSurveys', ':insuranceSurveyId'],
  }),
  DELETE_INSURANCE_SURVEY: route({
    path: ['insuranceSurveys', ':insuranceSurveyId'],
  }),
  PUT_INSURANCE_SURVEY_SENDTOADOS: route({
    path: ['insuranceSurveys', ':insuranceSurveyId', 'sendToAdos'],
  }),
  GET_VEHICLE_TASKS: route<
    'vehicleTasks',
    PropertiesToString<VehicleTaskFilterCriteriaDto> &
      PropertiesToString<BaseFilterCriteria>
  >({
    path: 'vehicleTasks',
  }),
  GET_VEHICLE_TASK_REPORT: route({
    path: ['vehicleTasks', ':taskId', 'report'],
  }),
  GET_VEHICLE_INSPECTION_TASK_XML: route({
    path: ['vehicleTasks', ':taskId', 'xml'],
  }),
  GET_INVESTIGATION_TASK: route({
    path: ['investigationTasks', ':investigationTaskId'],
  }),
  PATCH_INVESTIGATION_TASK: route({
    path: ['investigationTasks', ':investigationTaskId'],
  }),
  POST_INVESTIGATION_TASK: route('investigationTasks'),
  POST_INVESTIGATION_TASK_ATTACHMENT: route({
    path: ['investigationTasks', ':investigationTaskId', 'attachments'],
  }),
  POST_INVESTIGATION_TASK_IMPORT: route({
    path: ['admin', 'import-investigation-tasks'],
  }),
  POST_INVESTIGATION_TASK_PARTNER: route({
    path: ['investigationTasks', ':taskId', 'affectedPartner'],
  }),
  DELETE_ATTACHMENT: route({ path: ['attachments', ':fileId'] }),
  GET_USER_EXPORT: route({ path: ['admin', 'export-users'] }),
  POST_USERS_IMPORT: route({
    path: ['admin', 'import-users'],
  }),
  POST_VEHICLE_CRAFTSMEN_IMPORT: route({
    path: ['admin', 'import-vehicle-craftsmen'],
  }),
  POST_PROPERTY_CRAFTSMEN_IMPORT: route({
    path: ['admin', 'import-property-craftsmen'],
  }),
  GET_USER_PROFILE: route({ path: ['users', 'me'] }),
  GET_USER_BY_ID: route({ path: ['users', ':userId'] }),
  GET_USER_BY_UPN: route({ path: 'users', query: { Upn: '' } }),
  GET_USER_PICTURE: route({ path: ['users', ':userId', 'photo'] }),
  PATCH_USER: route({ path: ['users', ':userId'] }),
  POST_USER_MENUITEM: route({
    path: ['users', 'menuItem'],
  }),
  PATCH_USER_MENUITEM: route({
    path: ['users', 'menuItem', ':menuItemPatchOperations'],
  }),
  DELETE_USER_MENUITEM: route({
    path: ['users', 'menuItem', ':userMenuItemId'],
  }),
  POST_USER_TABLE_SETTING: route({
    path: ['users', 'tableSetting'],
  }),
  PATCH_VEHICLE_TASK: route({
    path: ['vehicleTasks', ':vehicleTaskId'],
  }),
  POST_VEHICLE_TASK: route('vehicleTasks'),
  POST_VEHICLE_TASK_MANUAL: route({ path: ['vehicleTasks', 'manual'] }),
  POST_DUPLICATE_VEHICLE_RISK_ASSESSMENT_TASK: route({
    path: [
      'vehicleTasks',
      'duplicate-vehicle-risk-assessment-task',
      ':sourceId',
    ],
  }),
  POST_VEHICLE_TASK_ATTACHMENT: route({
    path: ['vehicleTasks', ':vehicleTaskId', 'attachments'],
  }),
  GET_VEHICLE_INSPECTION_TASK: route({
    path: ['vehicleTasks', ':taskId'],
  }),
  GET_VEHICLE_INSPECTION_TASK_ALL_HISTORY: route({
    path: ['vehicleTasks', ':taskId', 'all-history'],
  }),
  GET_ATTACHMENT: route({
    path: ['attachments', ':fileId'],
  }),
  GET_CRAFTSMANS: route<
    'craftsmans',
    PropertiesToString<CraftsmanFilterCriteria> &
      PropertiesToString<BaseFilterCriteria>
  >({
    path: 'craftsmans',
  }),
  GET_CRAFTSMANS_LIST: route<
    ':path',
    PropertiesToString<CraftsmanFilterCriteria> &
      PropertiesToString<BaseFilterCriteria>
  >({
    path: ':path',
  }),
  GET_CRAFTSMAN: route({
    path: ['craftsmans', ':networkPartnerId'],
  }),
  GET_CRAFTSMAN_DETAIL: route({
    path: ['craftsmans', 'detail', ':networkPartnerId'],
  }),
  GET_CRAFTSMAN_ALL_HISTORY: route({
    path: ['craftsmans', ':craftsmanId', 'all-history'],
  }),
  POST_CRAFTSMAN: route('craftsmans'),
  PATCH_CRAFTSMAN: route({ path: ['craftsmans', ':networkPartnerId'] }),
  DELETE_CRAFTSMAN: route({ path: ['craftsmans', ':networkPartnerId'] }),
  GET_GEOCOORDINATES: route({
    path: 'address',
    query: { HouseNumber: '', Street: '', City: '', Country: '', ZipCode: '' },
  }),
  GET_WORKCATEGORIES: route('workcategories'),
  DELETE_PARTNER: route({ path: ['partners', ':partnerId'] }),
  PATCH_PARTNER: route({
    path: ['partners', ':partnerId'],
  }),
  POST_PROPERTY_INSPECTION_PARTNER: route({
    path: ['propertyInspectionTasks', ':taskId', 'affectedPartner'],
  }),
  POST_ACCIDENT_INSPECTION_TASK_PARTNER: route({
    path: ['accidentInspectionTasks', ':taskId', 'affectedPartner'],
  }),
  POST_VEHICLE_TASK_PARTNER: route({
    path: ['vehicleTasks', ':taskId', 'affectedPartner'],
  }),
  GET_IMPORT_STATE: route({
    path: ['admin', 'state'],
    query: { importType: '' },
  }),
};
