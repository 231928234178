import { APIRoutes, PropertiesToString } from '../../../router/router';
import { apiUrl } from '../../../data/fetch/base';
import {
  BaseFilterCriteria,
  BaseTaskChangeDto,
  BaseTaskFilterCriteria,
  VehicleTaskFilterCriteriaDto,
  VehicleTaskPostDto,
} from '../../../types/resource-models';
import {
  TaskTypes,
  VehicleTask,
  VehicleTasksListResponse,
} from '../../../types/types';
import { Operation, diff, jsonPatchPathConverter } from 'just-diff';
import {
  jsonReq,
  jsonGet,
  makeAuthHeaders,
  typeFromApi,
} from '../../../data/fetch/requests';
import { RequestResult } from '../../../data/fetch/result';

export const postVehicleTask = async (
  vehicleTaskPost: VehicleTaskPostDto,
  bearerToken: string,
): Promise<RequestResult<VehicleTask>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.POST_VEHICLE_TASK.create({})}`,
    {
      method: 'POST',
      headers: await makeAuthHeaders(bearerToken),
      body: JSON.stringify(vehicleTaskPost),
    },
  );
  return jsonReq<VehicleTask>(request, typeFromApi);
};

export const duplicateVehicleRiskAsssessmentTask = async (
  sourceId: string,
  bearerToken: string,
): Promise<RequestResult<VehicleTask>> => {
  const request = new Request(
    `${apiUrl}${APIRoutes.POST_DUPLICATE_VEHICLE_RISK_ASSESSMENT_TASK.create({
      sourceId: sourceId,
    })}`,
    {
      method: 'POST',
      headers: await makeAuthHeaders(bearerToken),
    },
  );
  return jsonReq<VehicleTask>(request, typeFromApi);
};

export const patchVehicleTask = async (
  vehicleTask: VehicleTask,
  vehicleTaskNew: VehicleTask,
  bearerToken: string,
  updateToAdos?: boolean,
  sendToAssigneeGarageCraftsman?: boolean,
  filter?: (op: Operation, path: string) => boolean,
): Promise<RequestResult<VehicleTask>> => {
  const vehicleTaskNewAdjusted = {
    ...vehicleTaskNew,
    recommendation: vehicleTaskNew.recommendation ?? 'Unknown',
    claim: {
      ...vehicleTaskNew.claim,
      policy: {
        ...vehicleTaskNew.claim.policy,
        compensation: vehicleTaskNew.claim?.policy?.compensation ?? 'Unknown',
      },
    },
  };

  const patchOperations = diff(
    vehicleTask,
    vehicleTaskNewAdjusted,
    jsonPatchPathConverter,
  ).filter((op) => (filter ? filter(op.op, op.path) : true));

  if (updateToAdos) {
    patchOperations.push({
      op: 'add',
      path: 'updateToAdos',
      value: undefined,
    });
  }

  if (sendToAssigneeGarageCraftsman) {
    patchOperations.push({
      op: 'add',
      path: 'sendToAssigneeGarageCraftsman',
      value: undefined,
    });
  }
  const patch = JSON.stringify(patchOperations);
  const request = new Request(
    `${apiUrl}${APIRoutes.PATCH_VEHICLE_TASK.create({
      vehicleTaskId: vehicleTask.id,
    })}`,
    {
      method: 'PATCH',
      headers: await makeAuthHeaders(bearerToken),
      body: patch,
    },
  );
  return await jsonReq<VehicleTask>(request, typeFromApi);
};

export const getVehicleTasks = async (
  bearerToken: string,
  filter: BaseTaskFilterCriteria,
): Promise<RequestResult<VehicleTasksListResponse>> => {
  return await jsonGet(
    `${apiUrl}${APIRoutes.GET_VEHICLE_TASKS.create({
      query: filter as Partial<
        PropertiesToString<VehicleTaskFilterCriteriaDto> &
          PropertiesToString<BaseFilterCriteria>
      >,
    })}`,
    bearerToken,
  );
};

export const getVehicleTask = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<VehicleTask>> =>
  jsonGet<VehicleTask>(
    `${apiUrl}${APIRoutes.GET_VEHICLE_INSPECTION_TASK.create({
      taskId: taskId,
    })}`,
    bearerToken,
    (result) => (result.type = TaskTypes.VehicleTask),
  );
export const getVehicleTaskAllHistory = async (
  bearerToken: string,
  taskId: string,
): Promise<RequestResult<BaseTaskChangeDto[]>> =>
  jsonGet(
    `${apiUrl}${APIRoutes.GET_VEHICLE_INSPECTION_TASK_ALL_HISTORY.create({
      taskId: taskId,
    })}`,
    bearerToken,
  );
