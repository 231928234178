import {
  BalModalHeader,
  BalModalBody,
  BalHeading,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import CreateEditContactForm, {
  CreateEditContactModalFormProps,
} from './create-edit-contact-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateEditContactModalProps
  extends CreateEditContactModalFormProps {}

const CreateEditContactModal = ({
  onSave,
  edit,
  affectedPartnerToEdit,
  addressOpen,
  onDelete,
  requiredPhoneOrMail,
  customRoles,
  detailSwitch,
  hasPartnerNr = false,
  hasGender = false,
  hasLanguage = false,
  hasPreferedContact = false,
  hasAddress = true,
  hasMobile = false,
  hasCompanyNr = false,
  hasBirthday = false,
  hasRole = false,
  hasFunction = false,
  policyHolderId,
}: CreateEditContactModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <BalModalHeader className="is-full-width create-contacts-header">
        <BalHeading space="none" level="h3" className="mb-5">
          {edit
            ? t('general.contact.editContact')
            : t('general.contact.createContact')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <CreateEditContactForm
          onSave={onSave}
          edit={edit}
          affectedPartnerToEdit={affectedPartnerToEdit}
          addressOpen={addressOpen}
          onDelete={onDelete}
          requiredPhoneOrMail={requiredPhoneOrMail}
          customRoles={customRoles}
          detailSwitch={detailSwitch}
          hasPartnerNr={hasPartnerNr}
          hasGender={hasGender}
          hasLanguage={hasLanguage}
          hasPreferedContact={hasPreferedContact}
          hasAddress={hasAddress}
          hasMobile={hasMobile}
          hasCompanyNr={hasCompanyNr}
          hasBirthday={hasBirthday}
          hasRole={hasRole}
          hasFunction={hasFunction}
          policyHolderId={policyHolderId}
        />
      </BalModalBody>
    </>
  );
};

export default CreateEditContactModal;
