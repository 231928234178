import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useToken } from '../../../hooks';
import {
  ErrorToast,
  ToastNotification,
} from '../../../components/toast-notification';
import { ManagementCard } from './admin-management-card';
import { useImportStateFromApi } from '../data/hooks';
import { postImportFile } from '../../files/data/requests';
import { APIRoutes } from '../../../router/router';
import { RequestResult } from '../../../data';
import {
  ImportStateDto,
  ImportType,
  Language,
} from '../../../types/resource-models';

export interface InvestigationTaskManagementCardProps {
  language: Language;
}

export const InvestigationTaskManagementCard = ({
  language,
}: InvestigationTaskManagementCardProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const inputFile = useRef<HTMLInputElement>(null);

  const getImportTypeFromLanguage = (language: Language): ImportType => {
    switch (language) {
      case 'Fr':
        return 'FrenchInvestigationTasks';
      case 'It':
        return 'ItalianInvestigationTasks';
      default:
        return 'GermanInvestigationTasks';
    }
  };

  const [
    importInvestigationTasksStateFromApi,
    setImportInvestigationTasksStateFromApi,
  ] = useImportStateFromApi(getImportTypeFromLanguage(language));
  const [
    importInvestigationTasksButtonLoadingState,
    setImportInvestigationTasksButtonLoadingState,
  ] = useState(false);

  const onChangedFiles = async (
    route: string,
    setImportLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
    setImportStateFromApi: React.Dispatch<
      React.SetStateAction<RequestResult<ImportStateDto[]>>
    >,
    inputFile: React.RefObject<HTMLInputElement>,
  ) => {
    if (
      inputFile.current &&
      inputFile.current.files &&
      inputFile.current.files.length > 0 &&
      importInvestigationTasksStateFromApi.status === 'success'
    ) {
      setImportStateFromApi({ status: 'loading' });
      setImportLoadingState(true);
      const selectedFile = inputFile.current.files[0];

      postImportFile(`${route}/${language}`, token, selectedFile).then(
        (result) => {
          if (result.status === 'success') {
            setImportStateFromApi({ status: 'initial' });
            toast(
              ToastNotification({
                message: t('investigationTask.importQueued'),
                color: 'success',
              }),
            );
          } else if (result.status === 'error') {
            toast(ErrorToast(result.errorValue));
          }
          if (inputFile.current) {
            inputFile.current.value = '';
          }
          setImportLoadingState(false);
        },
      );
    }
  };

  return (
    <ManagementCard
      title={t(`investigationTask.${language}ImportManagement`)}
      description={t('investigationTask.importManagementDescription')}
      importButtonName={t('general.import')}
      importButtonLoadingState={importInvestigationTasksButtonLoadingState}
      inputFile={inputFile}
      onChangedFiles={async () => {
        onChangedFiles(
          APIRoutes.POST_INVESTIGATION_TASK_IMPORT.create({}),
          setImportInvestigationTasksButtonLoadingState,
          setImportInvestigationTasksStateFromApi,
          inputFile,
        );
      }}
      importStateFromApi={importInvestigationTasksStateFromApi}
    />
  );
};
