import { BalTabItem, BalTabs } from '@baloise/design-system-components-react';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useToken, useCallbackPrompt } from '../hooks';
import { toast } from 'react-toastify';
import {
  ErrorToast,
  ToastNotification,
} from '../components/toast-notification';
import { WithCapabilities } from '../components/authorization';
import { BlockNavigation } from '../components/navigation';
import { TaskWizardButtons } from '../components/ui';
import {
  ClaimNumberInputCard,
  TaskInformationCards,
} from '../features/base-task';
import { RequestResult } from '../data/fetch/result';
import { TabType, TaskTypes, VehicleTask } from '../types/types';
import { getTask } from '../features/base-task/data/requests';
import { BaseTaskListDto } from '../types/resource-models';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getVehicleTask,
  patchVehicleTask,
  postVehicleTask,
  useVehicleTask,
  VehicleTaskClaimDamageForm,
  VehicleTaskOverlay,
} from '../features/vehicle-task';
import { VehicleTaskCompleteTaskCards } from '../features/vehicle-task/components';
import RiskAssessmentVisitationCard from '../features/vehicle-task/components/risk-assessment-visitation';
import { useForm } from 'react-hook-form';
import VehicleRiskAssessmentWizardContactsCards from '../features/vehicle-task/components/vehicle-risk-assessment-wizard-contacts-cards';
import { EBRoutes } from '../router/router';

const VehicleRiskAssessmentWizardView = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const taskIdQueryParam = searchParams.get('taskId');
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabVehicleClaimNumber = useRef<HTMLBalTabItemElement>(null);
  const refTabVehicleCompanyContact = useRef<HTMLBalTabItemElement>(null);
  const refTabContactInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabVehicle = useRef<HTMLBalTabItemElement>(null);
  const refTabVehicleClaimInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabCompleteVehicleClaim = useRef<HTMLBalTabItemElement>(null);
  const navigate = useNavigate();
  const [vehicleTask, setVehicleTask] = useVehicleTask();
  const [tab, setTab] = useState<TabType>('vehicleClaimNumber');
  const [policyNumber, setPolicyNumber] = useState<string>('');
  const token = useToken();
  const patchTask = async (
    tab: TabType,
    refNextTab: RefObject<HTMLBalTabItemElement>,
  ) => {
    setVehicleTask({ status: 'loading' });

    if (vehicleTask.status === 'success') {
      vehicleTask.localValue;
      const taskResponse = await patchVehicleTask(
        vehicleTask.value,
        vehicleTask.localValue,
        token,
      );
      setVehicleTask(taskResponse);
      if (taskResponse.status === 'success') {
        refNextTab.current
          ?.getOptions()
          .then((options) => refTabs.current?.select(options));
        setTab(tab);
      } else if (taskResponse.status === 'error') {
        toast(ErrorToast(taskResponse.errorValue));
      }
    }
  };

  const onEditTask = async (baseTaskid: string) => {
    setVehicleTask({ status: 'loading' });
    const taskResponse = await getTask<VehicleTask>(
      token,
      baseTaskid,
      TaskTypes.VehicleTask,
    );

    setVehicleTask(taskResponse);
  };

  const onSearchAction = (
    policyNumber: string,
    refNextTab?: RefObject<HTMLBalTabItemElement> | null,
  ) => {
    createTask(
      policyNumber,
      setVehicleTask,
      setTab,
      refTabs,
      token,
      refNextTab,
    );
  };

  const createTask = (
    claimNumber: string,
    setVehicleTask: (value: RequestResult<VehicleTask>) => void,
    setTab: (value: SetStateAction<TabType>) => void,
    refTabs: RefObject<HTMLBalTabsElement>,
    token: string,
    refNextTab?: RefObject<HTMLBalTabItemElement> | null,
  ) => {
    setVehicleTask({ status: 'loading' });
    postVehicleTask(
      {
        claimNumber: claimNumber,
        kind: 'VehicleRiskAssessment',
        garage: undefined,
        assigneeGarageCraftsmanId: undefined,
      },
      token,
    ).then((taskResponse) => {
      if (taskResponse.status === 'success' && refNextTab) {
        setVehicleTask(taskResponse);
        refNextTab.current
          ?.getOptions()
          .then((options) => refTabs.current?.select(options));
        setTab('contactInformation');
      } else if (taskResponse.status === 'error' && refNextTab) {
        toast(ErrorToast(taskResponse.errorValue));
        setVehicleTask({ status: 'initial' });
      }
    });
  };

  const createOrFetchTaskCallback = useCallback(
    (taskId: string) => {
      if (token) {
        setVehicleTask({ status: 'loading' });
        getTask<VehicleTask>(token, taskId, TaskTypes.VehicleTask).then(
          (taskResponse) => {
            if (taskResponse.status === 'success') {
              setVehicleTask(taskResponse);
              refTabContactInformation.current
                ?.getOptions()
                .then((options) => refTabs.current?.select(options));
              setTab('contactInformation');
            } else if (taskResponse.status === 'error') {
              toast(ErrorToast(taskResponse.errorValue));
              setVehicleTask({ status: 'initial' });
            }
          },
        );
      }
    },
    [setVehicleTask, token],
  );
  //useEffect to redirect if tast status is not dratft or new
  useEffect(() => {
    if (vehicleTask.status === 'success') {
      if (vehicleTask.value.status !== 'New') {
        toast(
          ToastNotification({
            message: t('general.taskNotInNewState'),
            color: 'warning',
          }),
        );
        navigate(
          EBRoutes.VEHICLE_INSPECTION_TASK_DETAIL.create({
            taskId: vehicleTask.value.id,
          }),
        );
      }
    }
    // when adding navigate the naviation block is not working anymore.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTask, t]);
  useEffect(() => {
    if (taskIdQueryParam) {
      createOrFetchTaskCallback(taskIdQueryParam);
    }
  }, [taskIdQueryParam, createOrFetchTaskCallback, setVehicleTask]);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    tab !== 'vehicleClaimNumber' &&
      tab !== 'none' &&
      vehicleTask.status === 'success' &&
      vehicleTask.value.status === 'New',
  );
  const vehicleClaimInformationMethods = useForm({ mode: 'onChange' });

  return (
    <WithCapabilities
      requiredCapabilities={['CreateVehicleRiskAssessment']}
      showErrorToast
    >
      {vehicleTask.status === 'success' ? (
        <>
          <VehicleTaskOverlay
            task={vehicleTask.value as unknown as BaseTaskListDto}
            tab={tab}
            getFullTask={getVehicleTask}
            hideLinkButtons
          />
          <BlockNavigation
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            taskType={TaskTypes.VehicleTask}
            taskId={vehicleTask.value.id}
          />
        </>
      ) : (
        <BlockNavigation
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
      )}
      <div className="columns wizard is-gapless is-full-height is-full-width scroll-bar-minimal-width-adjust">
        <div className="column is-11 is-flex wizard-max-width-1280px mr-3">
          <BalTabs
            clickable={false}
            interface="o-steps"
            ref={refTabs}
            value={tab}
          >
            <BalTabItem
              value="vehicleClaimNumber"
              label={`${t('vehicleTask.vehicleRiskAssessment.tabTitle')}`}
              ref={refTabVehicleClaimNumber}
            >
              <ClaimNumberInputCard
                setClaimNumber={setPolicyNumber}
                task={vehicleTask}
                setTask={setVehicleTask}
                refTabs={refTabs}
                refNextTab={refTabContactInformation}
                refTabContactInformation={refTabContactInformation}
                setTab={() => setTab('vehicleCompanyContactInformation')}
                title={t('vehicleTask.vehicleRiskAssessment.cardTitle')}
                text={t('vehicleTask.vehicleRiskAssessment.cardText')}
                pattern={/^[0-9]{9}$|[0-9]{10}$/}
                taskButtons={
                  <TaskWizardButtons refTabs={refTabs} hasBack={false} />
                }
                isRiskAssessment
                placeholder={t('general.claim.policyNumber')}
                onSearchAction={onSearchAction}
                onEditTask={onEditTask}
                onCreateNew={(task: RequestResult<VehicleTask>) => {
                  if (task.status === 'success') {
                    onSearchAction(policyNumber, refTabContactInformation);
                  }
                }}
                taskType={TaskTypes.VehicleTask}
                patternErrorMessage={t('validators.invalidPolicyNumber')}
              />
            </BalTabItem>
            <BalTabItem
              value="contactInformation"
              label={t('vehicleTask.vehicleContact.contact')}
              ref={refTabContactInformation}
            >
              <VehicleRiskAssessmentWizardContactsCards
                task={vehicleTask}
                setTask={setVehicleTask}
                saveAndNavigate={async () => {
                  vehicleTask.status === 'success' &&
                  vehicleTask.localValue.visitationAtPolicyHolder
                    ? await patchTask('vehicleClaimInformation', refTabVehicle)
                    : await patchTask(
                        'vehicleCompanyContactInformation',
                        refTabVehicleCompanyContact,
                      );
                }}
              />
            </BalTabItem>
            <BalTabItem
              hidden={
                vehicleTask.status !== 'success' ||
                (vehicleTask.status === 'success' &&
                  vehicleTask.localValue.visitationAtPolicyHolder)
              }
              value="vehicleCompanyContactInformation"
              label={t('vehicleTask.vehicleCompany.visitationLocation')}
              ref={refTabVehicleCompanyContact}
            >
              <RiskAssessmentVisitationCard
                task={vehicleTask}
                setVehicleTask={setVehicleTask}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabContactInformation}
                    setPrevTab={() => {
                      setTab('contactInformation');
                    }}
                  />
                }
                patchTask={() =>
                  patchTask(
                    'vehicleClaimInformation',
                    refTabVehicleClaimInformation,
                  )
                }
              />
            </BalTabItem>
            <BalTabItem
              value="vehicleClaimInformation"
              label={t('vehicleTask.vehicleClaimInformation.tabTitle')}
              ref={refTabVehicleClaimInformation}
            >
              <VehicleTaskClaimDamageForm
                vehicleTask={vehicleTask}
                setVehicleTask={setVehicleTask}
                methodsProp={vehicleClaimInformationMethods}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                patchTask={async () => {}}
              />
              <TaskInformationCards
                methodsProp={vehicleClaimInformationMethods}
                taskProps={{
                  task: vehicleTask,
                  setTask: setVehicleTask,
                  type: TaskTypes.VehicleTask,
                }}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabVehicle}
                    setPrevTab={() => {
                      vehicleTask.status === 'success' &&
                      vehicleTask.localValue.visitationAtPolicyHolder
                        ? setTab('contactInformation')
                        : setTab('vehicleCompanyContactInformation');
                    }}
                  />
                }
                patchTask={async () => {
                  await patchTask(
                    'completeVehicleClaim',
                    refTabCompleteVehicleClaim,
                  );
                }}
                title={t('vehicleTask.vehicleClaimInformation.cardTitle')}
              />
            </BalTabItem>
            <BalTabItem
              value="completeVehicleClaim"
              label={t('vehicleTask.completeVehicleClaim.tabTitle')}
              ref={refTabCompleteVehicleClaim}
            >
              <VehicleTaskCompleteTaskCards
                task={vehicleTask}
                setTask={setVehicleTask}
                patchTask={patchVehicleTask}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabVehicleClaimInformation}
                    setPrevTab={() => setTab('vehicleClaimInformation')}
                    nextText={
                      vehicleTask.status == 'success' &&
                      vehicleTask.localValue.kind == 'VehicleBonusCorrection'
                        ? t('vehicleTask.vehicleBonusCorrection.complete')
                        : vehicleTask.status == 'success' &&
                          vehicleTask.localValue.kind == 'VehicleRiskAssessment'
                        ? t('vehicleTask.vehicleRiskAssessment.complete')
                        : t(
                            'vehicleTask.completeVehicleClaim.submitInspectionButton',
                          )
                    }
                  />
                }
                inspectorAppCapabilitiy={[
                  'EditVehicleRiskAssessment',
                  'ReadOwnedVehicleTask',
                ]}
                setTab={setTab as Dispatch<SetStateAction<string>>}
                quickLinkRegionAssignment
              />
            </BalTabItem>
          </BalTabs>
        </div>
      </div>
    </WithCapabilities>
  );
};

export default VehicleRiskAssessmentWizardView;
