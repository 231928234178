import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { InsuranceSurveyChangeDto } from '../../../../../../../bat-shared/resource-models';
import { ErrorToast } from '../../../../../components/toast-notification';
import { balModalController } from '../../../../../controller/controllers';
import { useToken } from '../../../../../hooks';
import { EntityProtocol } from '../../../../protocol';
import { getInsuranceSurveyHistory } from '../data/requests';

export function InsuranceSurveyHistory({
  insuranceSurveyId,
}: {
  insuranceSurveyId: string;
}) {
  const token = useToken();
  const { t } = useTranslation();

  const InsuranceSurveyHistoryModalContent = (): JSX.Element => {
    const [insuranceSurveyHistory, setInsuranceSurveyHistory] =
      useState<InsuranceSurveyChangeDto[]>();
    const [insuranceSurveyCreation, setInsuranceSurveyCreation] =
      useState<InsuranceSurveyChangeDto>();
    useEffect(() => {
      if (insuranceSurveyHistory === undefined) {
        getInsuranceSurveyHistory(token, insuranceSurveyId as string).then(
          async (result) => {
            if (result.status === 'success') {
              setInsuranceSurveyHistory(result.value);
              setInsuranceSurveyCreation(result.value.pop());
            } else if (result.status === 'error') {
              toast(ErrorToast(result.errorValue));
            }
          },
        );
      }
    });
    const date = insuranceSurveyCreation
      ? new Date(insuranceSurveyCreation.dateTime)
      : new Date();
    const formattedDate = new Date(
      date.getTime() + -date.getTimezoneOffset() * 60000,
    ).toLocaleString();
    const { t } = useTranslation();
    return (
      <div className="modal-history">
        <BalModalHeader>
          <BalHeading space="none" level="h3">
            {t('journal.history.title')}
          </BalHeading>
          <BalText className="mb-0" color="primary-light" size="block">
            {insuranceSurveyCreation?.userName}
          </BalText>
          <BalText className="pb-5" color="primary-light" size="small">
            {formattedDate}
          </BalText>
        </BalModalHeader>
        <BalModalBody className="modal-history-body">
          {insuranceSurveyHistory && (
            <EntityProtocol
              history={insuranceSurveyHistory}
              maxMessageLength={50}
            />
          )}
        </BalModalBody>
        <BalButtonGroup position="right">
          <BalButton
            color="info"
            onClick={() => {
              balModalController.dismiss();
            }}
          >
            {t('general.buttons.close')}
          </BalButton>
        </BalButtonGroup>
      </div>
    );
  };
  return (
    <BalButton
      size="small"
      color="info"
      onClick={async () => {
        const modal = await balModalController.create({
          component: InsuranceSurveyHistoryModalContent,
          componentProps: {},
          cssClass: 'center-modal',
          backdropDismiss: false,
        });
        return modal.present();
      }}
    >
      {t('general.history')}
    </BalButton>
  );
}
