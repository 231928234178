import {
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalText,
  BalButtonGroup,
  BalClose,
  BalSelect,
  BalSelectOption,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateBaseTaskField } from '../data/requests';
import { useRef, useState } from 'react';
import { BaseTaskPriority } from '../../../types/resource-models';
import { Globals } from '../../../utils';
import { ErrorToast } from '../../../components/toast-notification';
import { balModalController } from '../../../controller/controllers';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import { BaseTask, TaskTypes } from '../../../types/types';

export interface BaseTaskPrioritySelectorModalFormProps<BaseTask> {
  task: BaseTask;
  taskType: TaskTypes;
  onChangeSuccess?: (task: RequestResult<BaseTask>) => void;
  outlined?: boolean;
}

const BaseTaskPrioritySelectorModalForm = <BaseTaskType extends BaseTask>({
  task,
  taskType,
  onChangeSuccess,
}: BaseTaskPrioritySelectorModalFormProps<BaseTaskType>): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const [priority, setPriority] = useState<BaseTaskPriority | undefined>(
    task.priority,
  );
  const balSelectRef = useRef<HTMLBalSelectElement>(null);
  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">
          {t('baseTaskListDtoColums.priority')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>{t('general.selectPriorityAsk')}</BalText>
        <div className="is-flex">
          <BalSelect
            ref={balSelectRef}
            className="column is-10 pl-0"
            value={priority}
            placeholder={t('baseTaskListDtoColums.priority')}
            onBalChange={(e) => {
              setPriority(
                e.detail ? (e.detail as BaseTaskPriority) : undefined,
              );
            }}
          >
            {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
            <div className="bal-select__inner">
              {Globals.baseTaskPriorities.map((selection) => (
                <BalSelectOption
                  key={selection}
                  value={selection}
                  label={t(`baseTaskPriorities.${selection}`)}
                >
                  {t(`baseTaskPriorities.${selection}`)}
                </BalSelectOption>
              ))}
            </div>
          </BalSelect>
          <div className="is-flex is-flex-direction-column is-justify-content-center">
            <div>
              {priority && (
                <BalClose
                  onClick={() => {
                    setPriority(undefined);
                    balSelectRef.current?.clear();
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>
          <BalButton
            color="info"
            onClick={async (event) => {
              if (event.detail == 1) {
                const result = await updateBaseTaskField<BaseTaskType>(
                  token,
                  task.id,
                  taskType,
                  'priority',
                  priority?.toString(),
                );

                if (result.status === 'success') {
                  onChangeSuccess && onChangeSuccess(result);
                  balModalController.dismiss();
                } else {
                  if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                }
              }
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};

export default BaseTaskPrioritySelectorModalForm;
