import {
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalText,
  BalButtonGroup,
} from '@baloise/design-system-components-react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { balModalController } from '../../controller/controllers';
import { deleteBaseTask } from '../../features/base-task/data/requests';
import { useToken } from '../../hooks';
import { TaskTypes } from '../../types/types';
import { ErrorToast } from '../toast-notification';

const BlockNavigationModal = ({
  cancelNavigation,
  confirmNavigation,
  taskType,
  taskId,
}: {
  showDialog: boolean;
  taskType?: TaskTypes;
  taskId?: string;
  cancelNavigation: () => void;
  confirmNavigation: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">{t('general.pageLeave')}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>{t('general.onLeaveMessage')}</BalText>
        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) => {
              if (event.detail == 1) {
                cancelNavigation();
                balModalController.dismiss();
              }
            }}
          >
            {t('general.buttons.cancel')}
          </BalButton>
          {taskId != undefined && taskType != undefined ? (
            <BalButton
              elementType="button"
              color="warning"
              onClick={async (event) => {
                if (event.detail == 1) {
                  const result = await deleteBaseTask(token, taskId, taskType);
                  if (result.status === 'success') {
                    confirmNavigation();
                    balModalController.dismiss();
                  } else {
                    if (result.status === 'error') {
                      toast(ErrorToast(result.errorValue));
                    }
                  }
                }
              }}
            >
              {t('general.buttons.delete')}
            </BalButton>
          ) : (
            <></>
          )}
          <BalButton
            onClick={(event) => {
              if (event.detail == 1) {
                confirmNavigation();
                balModalController.dismiss();
              }
            }}
            color="primary"
          >
            {t('general.buttons.end')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};

export const BlockNavigation = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  taskType,
  taskId,
}: {
  showDialog: boolean;
  taskType?: TaskTypes;
  taskId?: string;
  cancelNavigation: () => void;
  confirmNavigation: () => void;
}): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: BlockNavigationModal,
      isClosable: false,
      componentProps: {
        cancelNavigation: cancelNavigation,
        confirmNavigation: confirmNavigation,
        taskType: taskType,
        taskId: taskId,
      },
      cssClass: 'center-modal',
    });
    return modal.present();
  };
  useEffect(() => {
    if (showDialog) openModal();
    //had to disable it since it was requiring the openModal as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  return <></>;
};
