import {
  BalButton,
  BalCard,
  BalHeading,
  BalSpinner,
  BalText,
} from '@baloise/design-system-components-react';
import { RequestResult } from '../../../data';
import { VehicleTask } from '../../../types/types';
import { NetworkPartnerSelector } from '../../network-partners/components';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BalTextField, CountryList } from '../../../components/input';
import { WithCapabilities } from '../../../components/authorization';
import { DisabableButton } from '../../../components/authorization/disabable-button';
import { balModalController } from '../../../controller/controllers';
import React, { RefObject } from 'react';
import { getDefaultCompanyDto } from '../../../utils/utilities';

import { CompanyDto, CraftsmanListDto } from '../../../types/resource-models';
import produce from 'immer';
import { BalValidators } from '@baloise/web-app-validators';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
const VehicleTaskCompanyContactsCard = ({
  claimNumber,
  task,
  selectedPartner,
  setSelectedPartner,
  setSelectedCraftsmanId,
  existingPartnerToggle,
  setExistingPartnerToggle,
  refTabcontactInformation,
  createNewTask,
  setVehicleTab,
  taskButtons,
}: {
  claimNumber: string;
  task: RequestResult<VehicleTask>;
  selectedPartner: CompanyDto;
  setSelectedPartner: React.Dispatch<React.SetStateAction<CompanyDto>>;
  setSelectedCraftsmanId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  existingPartnerToggle: boolean;
  setExistingPartnerToggle: React.Dispatch<React.SetStateAction<boolean>>;
  refTabcontactInformation: RefObject<HTMLBalTabItemElement>;
  taskButtons: JSX.Element;
  createNewTask: (
    claimNumber: string,
    refNextTab?: RefObject<HTMLBalTabItemElement> | null,
  ) => void;
  setVehicleTab?: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });
  const validateEmail = (value: string) =>
    BalValidators.isEmail()(value) ? true : t('validators.invalidEmail');

  const openCraftsmanSelectorModal = async () => {
    const modal = await balModalController.create({
      component: NetworkPartnerSelector,
      modalWidth: 1024,
      componentProps: {
        onSelectedSuccess: onCraftsmanSelected,
        title: t('vehicleTask.vehicleCompany.selectCompanyTitle'),
        craftsmanFilter: {
          vehicleCraftsmanOnly: true,
          isExternalPartner: true,
        },
        disableNoChoice: true,
      },
      backdropDismiss: false,
    });
    modal.present();
  };

  const onCraftsmanSelected = async (craftsman: CraftsmanListDto) => {
    setSelectedPartner(craftsman.company);
    setSelectedCraftsmanId(craftsman.id);
    setExistingPartnerToggle(true);
  };

  const onSubmit = () => {
    if (task.status === 'initial') {
      if (claimNumber !== undefined) {
        createNewTask(claimNumber, refTabcontactInformation);
      }
    }
    if (
      task.status === 'success' &&
      task.value.kind === 'VehicleRiskAssessment' &&
      setVehicleTab
    ) {
      setVehicleTab();
    }
  };

  return (
    <div>
      {task.status === 'loading' && <BalSpinner />}
      {task.status === 'error' && <></>}
      {task.status !== 'loading' && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <BalCard className="mt-5 p-5 columns is-multiline is-gapless is-full-width">
              <BalHeading space="none" level="h4" className=" column is-full">
                {t('vehicleTask.vehicleCompany.visitationLocation')}
              </BalHeading>
              <div className="column is-full mb-4 is-flex is-align-items-center">
                <BalText>
                  {t('vehicleTask.vehicleCompany.selectCompanyDescription')}
                </BalText>
                <WithCapabilities
                  className="ml-6"
                  requiredCapabilities={['ReadNetworkPartner']}
                  passWithCapabilitiesPropsToChildren
                >
                  <DisabableButton
                    color="info"
                    onClick={() => {
                      openCraftsmanSelectorModal();
                    }}
                  >
                    {t('vehicleTask.vehicleCompany.selectCompany')}
                  </DisabableButton>
                </WithCapabilities>
              </div>
              <div className="columns column is-full is-multiline is-gapless mb-4">
                <BalText bold className="column is-full mb-2" color="info">
                  {t('vehicleTask.vehicleCompany.company')}
                </BalText>
                <Controller
                  name="companyName"
                  control={methods.control}
                  rules={{
                    required: existingPartnerToggle
                      ? false
                      : t('error.mandatoryField'),
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      {existingPartnerToggle ? (
                        <BalTextField
                          className="column is-full mb-4"
                          disabled
                          value={selectedPartner.name}
                        />
                      ) : (
                        <BalTextField
                          {...field}
                          className="column is-full mb-4"
                          placeholder={`${t('general.company')} *`}
                          value={selectedPartner.name}
                          onChange={(e) => {
                            if (e.detail || e.detail === '') {
                              const value = e.detail;
                              setSelectedPartner(
                                produce(selectedPartner, (draftState) => {
                                  draftState.name = value;
                                }),
                              );
                            }
                            field.onChange(e.detail);
                          }}
                          message={fieldState.error && fieldState.error.message}
                        />
                      )}
                    </>
                  )}
                />
                <Controller
                  name="street"
                  control={methods.control}
                  rules={{
                    required: existingPartnerToggle
                      ? false
                      : t('error.mandatoryField'),
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      {existingPartnerToggle ? (
                        <BalTextField
                          className="column is-8 mr-4"
                          disabled
                          value={selectedPartner.address?.street}
                        />
                      ) : (
                        <BalTextField
                          {...field}
                          className="column is-8 mr-4"
                          placeholder={`${t('locationDetails.street')} *`}
                          value={selectedPartner.address?.street}
                          onChange={(e) => {
                            if (e.detail || e.detail === '') {
                              setSelectedPartner(
                                produce(selectedPartner, (draftState) => {
                                  if (draftState.address) {
                                    if (e.detail) {
                                      draftState.address.street = e.detail;
                                    }
                                  }
                                }),
                              );
                            }
                            field.onChange(e.detail);
                          }}
                          message={fieldState.error && fieldState.error.message}
                        />
                      )}
                    </>
                  )}
                />
                <Controller
                  name="houseNumber"
                  control={methods.control}
                  rules={{
                    required: existingPartnerToggle
                      ? false
                      : t('error.mandatoryField'),
                    pattern: {
                      value: /^[a-zA-Z0-9_.-]*$/,
                      message: t('validators.invalidHouseNumber'),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      {existingPartnerToggle ? (
                        <BalTextField
                          className="column"
                          disabled
                          value={selectedPartner.address?.houseNumber}
                        />
                      ) : (
                        <BalTextField
                          {...field}
                          className="column"
                          placeholder={`${t('locationDetails.houseNumber')} *`}
                          value={selectedPartner.address?.houseNumber}
                          onChange={(e) => {
                            if (e.detail || e.detail === '') {
                              setSelectedPartner(
                                produce(selectedPartner, (draftState) => {
                                  if (draftState.address) {
                                    if (e.detail) {
                                      draftState.address.houseNumber = e.detail;
                                    }
                                  }
                                }),
                              );
                            }
                            field.onChange(e.detail);
                          }}
                          message={fieldState.error && fieldState.error.message}
                        />
                      )}
                    </>
                  )}
                />
                <Controller
                  name="zipCode"
                  control={methods.control}
                  defaultValue={
                    selectedPartner.address && selectedPartner.address.zipCode
                      ? selectedPartner.address?.zipCode?.toString()
                      : undefined
                  }
                  rules={{
                    required: existingPartnerToggle
                      ? false
                      : t('error.mandatoryField'),
                    pattern:
                      selectedPartner.address?.country === 'CH' ||
                      selectedPartner.address?.country === undefined ||
                      selectedPartner.address?.country === null
                        ? {
                            value: /^[1-9][0-9]+$/,
                            message: t('validators.invalidZipcode'),
                          }
                        : undefined,
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      {existingPartnerToggle ? (
                        <BalTextField
                          className="column is-8 mr-4  my-2"
                          disabled
                          value={selectedPartner.address?.zipCode?.toString()}
                        />
                      ) : (
                        <BalTextField
                          {...field}
                          className="column is-4 mr-4  my-2"
                          placeholder={`${t('general.zipCode')} *`}
                          value={
                            selectedPartner.address &&
                            selectedPartner.address.zipCode
                              ? selectedPartner.address?.zipCode?.toString()
                              : undefined
                          }
                          onChange={(e) => {
                            if (e.detail || e.detail === '') {
                              setSelectedPartner(
                                produce(selectedPartner, (draftState) => {
                                  if (draftState.address) {
                                    if (e.detail) {
                                      draftState.address.zipCode =
                                        e.detail as unknown as number;
                                    }
                                  }
                                }),
                              );
                            }
                            field.onChange(e.detail);
                          }}
                          message={fieldState.error && fieldState.error.message}
                        />
                      )}
                    </>
                  )}
                />
                <Controller
                  name="city"
                  control={methods.control}
                  defaultValue={selectedPartner.address?.city}
                  rules={{
                    required: existingPartnerToggle
                      ? false
                      : t('error.mandatoryField'),
                    pattern: {
                      value:
                        /^([a-zA-Z\u0080-\u024F0-9]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F0-9]*$/,
                      message: t('validators.invalidCity'),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      {existingPartnerToggle ? (
                        <BalTextField
                          className="column  my-2"
                          disabled
                          value={selectedPartner.address?.city}
                        />
                      ) : (
                        <BalTextField
                          {...field}
                          className="column  my-2"
                          placeholder={`${t('networkPartners.place')} *`}
                          value={selectedPartner.address?.city}
                          onChange={(e) => {
                            if (e.detail || e.detail === '') {
                              setSelectedPartner(
                                produce(selectedPartner, (draftState) => {
                                  if (draftState.address) {
                                    if (e.detail) {
                                      draftState.address.city = e.detail;
                                    }
                                  }
                                }),
                              );
                            }
                            field.onChange(e.detail);
                          }}
                          message={fieldState.error && fieldState.error.message}
                        />
                      )}
                    </>
                  )}
                />
                <div className="column is-12 is-flex is-flex-direction-column">
                  <>
                    {existingPartnerToggle ? (
                      <BalTextField
                        disabled
                        value={selectedPartner.address?.country}
                      />
                    ) : (
                      <CountryList
                        controllerName={'country'}
                        methods={methods}
                        value={selectedPartner.address?.country}
                        requiredPlaceholder
                        onChange={(choice) => {
                          setSelectedPartner(
                            produce(selectedPartner, (draftState) => {
                              if (draftState.address) {
                                draftState.address.country = choice;
                              }
                            }),
                          );
                        }}
                      />
                    )}
                  </>
                </div>

                <div className="columns is-multiline is-gapless is-full-width mt-1">
                  <Controller
                    name="phoneNumber"
                    control={methods.control}
                    defaultValue={selectedPartner.phoneNumber}
                    rules={{
                      required: existingPartnerToggle
                        ? false
                        : t('error.mandatoryField'),
                      validate: (value: string) => {
                        const rawNumber = value.replaceAll(' ', '');

                        return isValidPhoneNumber(rawNumber) ||
                          existingPartnerToggle
                          ? true
                          : t('validators.invalidPhoneNumber');
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        {existingPartnerToggle ? (
                          <BalTextField
                            className="column mr-4"
                            disabled
                            value={selectedPartner.phoneNumber}
                          />
                        ) : (
                          <BalTextField
                            {...field}
                            className="column mr-4"
                            placeholder={`${t('general.phone')} *`}
                            value={selectedPartner.phoneNumber}
                            onChange={(e: CustomEvent<string | undefined>) => {
                              if (e.detail || e.detail === '') {
                                const phoneNumber = e.detail;
                                if (
                                  phoneNumber.charAt(0) == '0' &&
                                  (phoneNumber.length == 10 ||
                                    phoneNumber.length == 13)
                                ) {
                                  field.onChange(
                                    formatPhoneNumberIntl(
                                      `+41${phoneNumber.substring(1)}`,
                                    ),
                                  );
                                  setSelectedPartner(
                                    produce(selectedPartner, (draftState) => {
                                      draftState.phoneNumber =
                                        formatPhoneNumberIntl(
                                          `+41${phoneNumber.substring(1)}`,
                                        );
                                    }),
                                  );
                                } else if (isValidPhoneNumber(phoneNumber)) {
                                  field.onChange(
                                    formatPhoneNumberIntl(phoneNumber),
                                  );
                                  setSelectedPartner(
                                    produce(selectedPartner, (draftState) => {
                                      draftState.phoneNumber =
                                        formatPhoneNumberIntl(phoneNumber);
                                    }),
                                  );
                                } else {
                                  field.onChange(phoneNumber);
                                  setSelectedPartner(
                                    produce(selectedPartner, (draftState) => {
                                      draftState.phoneNumber = phoneNumber;
                                    }),
                                  );
                                }
                              }
                            }}
                            message={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      </>
                    )}
                  />
                  <Controller
                    name="email"
                    control={methods.control}
                    defaultValue={selectedPartner.email}
                    rules={{
                      validate: validateEmail,
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        {existingPartnerToggle ? (
                          <BalTextField
                            className="column"
                            disabled
                            value={selectedPartner.email}
                          />
                        ) : (
                          <BalTextField
                            {...field}
                            className="column"
                            placeholder={t('general.email')}
                            value={selectedPartner.email}
                            onChange={(e: CustomEvent<string | undefined>) => {
                              if (e.detail || e.detail === '') {
                                const value = e.detail;
                                setSelectedPartner(
                                  produce(selectedPartner, (draftState) => {
                                    draftState.email = value;
                                  }),
                                );
                                field.onChange(value);
                              }
                            }}
                            message={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      </>
                    )}
                  />
                </div>
                {existingPartnerToggle ? (
                  <div className="column is-full is-flex is-justify-content-flex-end">
                    <BalButton
                      color="primary"
                      onClick={() => {
                        setSelectedPartner(getDefaultCompanyDto());
                        setSelectedCraftsmanId(undefined);
                        setExistingPartnerToggle(false);
                      }}
                    >
                      {t('vehicleTask.vehicleCompany.resetCompany')}
                    </BalButton>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </BalCard>
            {taskButtons}
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default VehicleTaskCompanyContactsCard;
