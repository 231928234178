import {
  BalButton,
  BalButtonGroup,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BalTextFormField, CountryList } from '../../components/input';
import { AddressDto } from '../../types/resource-models';
import { CancelButton } from '../ui';

export interface AddressEditProps {
  initialValue: AddressDto;
  onSave: (address: AddressDto) => void;
}
const AddressEditForm = ({
  initialValue,
  onSave,
}: AddressEditProps): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
  });
  const { handleSubmit, setError, control, clearErrors, formState } = methods;
  const [saving, setSaving] = useState<boolean>(false);
  const [address, setAddress] = useState<AddressDto>(initialValue);
  return (
    <form
      onSubmit={handleSubmit(async () => {
        if (!saving) {
          setSaving(true);
          onSave(address);
          setSaving(false);
        }
      })}
    >
      <div className="columns is-multiline is-gapless is-full-width mt-1">
        <BalTextFormField
          controllerName={'street'}
          control={control}
          className="column is-8 mr-4"
          placeholder={t('locationDetails.street')}
          title={t('locationDetails.street')}
          value={address.street}
          onChange={(e: CustomEvent<string | undefined>) => {
            setAddress(
              produce(address, (draftState) => {
                draftState.street = e.detail ?? '';
              }),
            );
          }}
          required={t('error.mandatoryField')}
        />
        <BalTextFormField
          controllerName={'houseNumber'}
          control={control}
          className="column"
          placeholder={t('locationDetails.houseNumber')}
          title={t('locationDetails.houseNumber')}
          value={address.houseNumber}
          onChange={(e) => {
            setAddress(
              produce(address, (draftState) => {
                draftState.houseNumber = e.detail ?? '';
              }),
            );
          }}
          required={t('error.mandatoryField')}
          pattern={{
            value: /^[a-zA-Z0-9_.-]*$/,
            message: t('validators.invalidHouseNumber'),
          }}
        />
      </div>
      <div className="columns is-multiline is-gapless is-full-width mt-1">
        <BalTextFormField
          controllerName={'zipCode'}
          control={control}
          className="column mr-4"
          placeholder={t('general.zipCode')}
          title={t('general.zipCode')}
          value={address.zipCode?.toString() ?? undefined}
          onChange={(e) => {
            setAddress(
              produce(address, (draftState) => {
                draftState.zipCode = (e.detail ?? 0) as unknown as number;
              }),
            );
          }}
          required={t('error.mandatoryField')}
          pattern={{
            value: /^[1-9][0-9]+$/,
            message: t('validators.invalidZipcode'),
          }}
          maxLengthRule={
            address.country === 'CH' ||
            address.country === undefined ||
            address.country === null
              ? {
                  value: 4,
                  message: t('validators.invalidZipcode'),
                }
              : undefined
          }
        />
        <BalTextFormField
          controllerName={'place'}
          control={control}
          className="column is-9"
          placeholder={t('networkPartners.place')}
          title={t('networkPartners.place')}
          value={address.city}
          onChange={(e) => {
            setAddress(
              produce(address, (draftState) => {
                draftState.city = e.detail ?? '';
              }),
            );
          }}
          required={t('error.mandatoryField')}
          pattern={{
            value:
              /^([a-zA-Z\u0080-\u024F0-9]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F0-9]*$/,
            message: t('validators.invalidCity'),
          }}
        />
      </div>
      <CountryList
        controllerName={'country'}
        methods={methods}
        value={address.country}
        onChange={(choice) => {
          setAddress(
            produce(address, (draftState) => {
              draftState.country = choice;
            }),
          );
          if (
            (choice === 'CH' || choice === undefined) &&
            address.zipCode &&
            address.zipCode > 9999
          ) {
            setError('zipCode', {
              type: 'manual',
              message: t('validators.invalidZipcode'),
            });
          } else {
            clearErrors('zipCode');
            clearErrors('country');
          }
        }}
      />
      <div>
        <BalButtonGroup position="right">
          <CancelButton />
          <BalButton
            elementType="submit"
            color="info"
            disabled={
              !formState.isValid && Object.keys(formState.errors).length !== 0
            }
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </div>
    </form>
  );
};

export default AddressEditForm;
