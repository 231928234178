import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AppCapabilityNames } from '../../types/resource-models';
import { WithCapabilities } from '../authorization/with-capabilities';
import { BalNumberFormField } from './bal-number-form-field';

type EditNumberModalProps = {
  label: string;
  placeholder: string;
  value?: string;
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  isRequired: boolean;
  onSave(value: string | undefined, reportToAdos: boolean): void;
  showSaveAndSendButton?: boolean;
};

const EditNumberModalContent = (props: EditNumberModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [numberValue, setNumberValue] = useState<string | undefined>(
    props.value,
  );
  const methods = useForm({ mode: 'onChange' });

  const onSubmit = () => {
    if (numberValue == props.value) {
      balModalController.dismiss();
    } else {
      props.onSave(numberValue, false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={props.className}>
          <BalModalHeader>
            <BalHeading className="pb-5" space="none" level="h3">
              {props.label}
            </BalHeading>
          </BalModalHeader>
          <BalModalBody>
            <BalNumberFormField
              controllerName="modalController"
              methods={methods}
              required={props.isRequired ? undefined : false}
              placeholder={props.placeholder}
              value={numberValue}
              onChange={(event: CustomEvent<string | undefined>) => {
                const value = event.detail;
                setNumberValue(value);
              }}
            />
            <BalButtonGroup position="right">
              <BalButton
                elementType="button"
                color="primary-light"
                onClick={(event) =>
                  event.detail == 1 && balModalController.dismiss()
                }
              >
                {t('general.buttons.cancel')}
              </BalButton>
              <BalButton color="info" elementType="submit">
                {t('general.buttons.save')}
              </BalButton>
            </BalButtonGroup>
          </BalModalBody>
        </div>
      </form>
    </FormProvider>
  );
};

export const EditNumberModal = (props: EditNumberModalProps): JSX.Element => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditNumberModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
};
