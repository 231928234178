import {
  Configuration,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import { baseUrl } from './fetch/base';

const config: Configuration = {
  auth: {
    authority: window.extended.BAT_APP_OAUTH2_AUTHORITY,
    clientId: window.extended.BAT_APP_OAUTH2_CLIENT_ID,
    navigateToLoginRequestUrl: false,
    redirectUri: baseUrl,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  //https://docs.microsoft.com/en-us/azure/communication-services/concepts/credentials-best-practices?tabs=javascript
  system: {
    tokenRenewalOffsetSeconds: 900,
  },
};

export const authProvider = new PublicClientApplication(config);

export const authRequest: RedirectRequest = {
  scopes: [window.extended.BAT_APP_OAUTH2_SCOPES],
};
