import {
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalButtonGroup,
  BalButton,
  BalField,
  BalFieldLabel,
  BalFieldControl,
  BalTextarea,
} from '@baloise/design-system-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvestigationTaskMeasureDto } from '../../../../types/resource-models';
import produce from 'immer';
import { EditMeasureContact } from './edit-measure-contact';
import { InvestigationTask } from '../../../../types/types';
import { concat, getPolicyHolder } from '../../../../utils';
import { CancelButton } from '../../../../components/ui';

export type EditMeasureModalProps = {
  title: string;
  investigationTask: InvestigationTask;
  measure: InvestigationTaskMeasureDto;
  className?: string;
  onSave?: (measure: InvestigationTaskMeasureDto) => Promise<void>;
};

const EditMeasureModal = ({
  title,
  measure,
  investigationTask,
  className,
  onSave,
}: EditMeasureModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [localMeasure, setLocalMeasure] =
    useState<InvestigationTaskMeasureDto>(measure);
  const [touchedForm, setTouchedForm] = useState(false);
  const policyHolder = getPolicyHolder(investigationTask.claim);
  const contactsButPolicyHolder = policyHolder
    ? investigationTask.claim.affectedPartners.filter(
        (x) => x.id !== policyHolder.id,
      )
    : [];
  const disableRemarks =
    !localMeasure.unknownPartner &&
    !localMeasure.policyHolder &&
    localMeasure.additionalPartnersId.length === 0;

  useEffect(() => {
    if (touchedForm) {
      const noPartnerSelected =
        !localMeasure.unknownPartner &&
        !localMeasure.policyHolder &&
        localMeasure.additionalPartnersId.length === 0;
      if (
        noPartnerSelected &&
        localMeasure.remarks &&
        localMeasure.remarks.length > 0
      ) {
        setLocalMeasure(
          produce(localMeasure, (draftState) => {
            draftState.remarks = undefined;
          }),
        );
      }
    }
  }, [localMeasure, touchedForm]);

  return (
    <div className={className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {title}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        {localMeasure && (
          <div className="columns is-multiline is-gapless is-full-width">
            <EditMeasureContact
              value={localMeasure.unknownPartner}
              setValue={(value: boolean) => {
                setTouchedForm(true);
                setLocalMeasure(
                  produce(localMeasure, (draftState) => {
                    draftState.unknownPartner = value;
                  }),
                );
              }}
              displayText={t('investigationMeasure.unknownPartner')}
            />
            <EditMeasureContact
              value={localMeasure.policyHolder}
              setValue={(value: boolean) => {
                setTouchedForm(true);
                setLocalMeasure(
                  produce(localMeasure, (draftState) => {
                    draftState.policyHolder = value;
                  }),
                );
              }}
              displayText={t('claimDetail.policyHolder')}
              affectedPartner={policyHolder}
              bold={true}
            />
            {contactsButPolicyHolder.map((affectedPartner) => (
              <EditMeasureContact
                key={affectedPartner.id}
                value={localMeasure.additionalPartnersId.includes(
                  affectedPartner.id,
                )}
                setValue={(value: boolean) => {
                  setTouchedForm(true);
                  setLocalMeasure(
                    produce(localMeasure, (draftState) => {
                      if (value) {
                        draftState.additionalPartnersId.push(
                          affectedPartner.id,
                        );
                      } else {
                        draftState.additionalPartnersId =
                          draftState.additionalPartnersId.filter(
                            (x) => x !== affectedPartner.id,
                          );
                      }
                    }),
                  );
                }}
                affectedPartner={affectedPartner}
              />
            ))}
            <BalField className={concat(['column is-full'])}>
              <BalFieldLabel className={concat(['m-0 mt-4'])}>
                {t('general.claim.remarks')}
              </BalFieldLabel>
              <BalFieldControl className="pb-0">
                <BalTextarea
                  disabled={disableRemarks}
                  className="text-area-no-space"
                  value={localMeasure.remarks}
                  onBalChange={(e: CustomEvent<string | undefined | null>) => {
                    setTouchedForm(true);
                    setLocalMeasure(
                      produce(localMeasure, (draftState) => {
                        draftState.remarks = e.detail ?? undefined;
                      }),
                    );
                  }}
                  rows={3}
                />
              </BalFieldControl>
            </BalField>
          </div>
        )}
        <BalButtonGroup position="right">
          <CancelButton />
          <BalButton
            color="info"
            onClick={async (event) => {
              if (event.detail == 1 && onSave) {
                await onSave(localMeasure);
              }
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};

export default EditMeasureModal;
