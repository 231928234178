export const Icon = ({
  iconPath,
  isWhite,
}: {
  iconPath: string;
  isWhite?: boolean;
}): JSX.Element => {
  return (
    <div className="is-flex is-justify-content-center is-align-items-center">
      <img
        src={iconPath}
        style={isWhite ? { filter: 'brightness(0) invert(1)' } : undefined}
      />
    </div>
  );
};
