import { Translation } from 'react-i18next';
import { BalHeading, BalText } from '@baloise/design-system-components-react';
import {
  DisabableLink,
  WithCapabilities,
} from '../../../../components/authorization';
import { CopyButton } from '../../../../components/ui';
import { AppCapabilityNames } from '../../../../types/resource-models';
import {
  getEnabledModules,
  getValueOrWhitespace,
} from '../../../../utils/utilities';
import TaskJumpAdos from '../task-detail-jump-ados';

type ClaimNumberProps = {
  claimNumber: string;
  detailButtonRoute?: string;
  detailButtonRequiredCapabilities: AppCapabilityNames[];
  journalButtonRoute?: string;
  propertyInspectionTaskJournalButtonRoute?: string;
  hideGoToAdos?: boolean;
};

export const BaseTaskClaimNumber = ({
  claimNumber,
  detailButtonRoute,
  detailButtonRequiredCapabilities,
  journalButtonRoute,
  propertyInspectionTaskJournalButtonRoute,
  hideGoToAdos,
}: ClaimNumberProps): JSX.Element => {
  return (
    <Translation>
      {(t) => {
        return (
          <div>
            <div className="is-flex is-align-items-center is-justify-content-space-between mb-2">
              <BalText size="small" color="info">
                {t('overlay.claimNumber')}
              </BalText>
              <WithCapabilities
                requiredCapabilities={detailButtonRequiredCapabilities}
                passWithCapabilitiesPropsToChildren
              >
                <div>
                  {detailButtonRoute && (
                    <DisabableLink to={detailButtonRoute}>
                      <BalText color="primary" size="small">
                        {t('overlay.detailView')}
                      </BalText>
                    </DisabableLink>
                  )}
                  {getEnabledModules().investigationInspectionJournal &&
                    journalButtonRoute && (
                      <DisabableLink to={journalButtonRoute}>
                        <BalText color="primary" size="small">
                          {t('journal.entityName')}
                        </BalText>
                      </DisabableLink>
                    )}
                  {getEnabledModules().propertyInspectionTaskJournal &&
                    propertyInspectionTaskJournalButtonRoute && (
                      <DisabableLink
                        to={propertyInspectionTaskJournalButtonRoute}
                      >
                        <BalText color="primary" size="small">
                          {t('inspectionTask.journal.entityName')}
                        </BalText>
                      </DisabableLink>
                    )}
                </div>
              </WithCapabilities>
            </div>
            <div className="is-flex">
              <BalHeading space="none" level="h4">
                {getValueOrWhitespace(claimNumber)}
              </BalHeading>
              <CopyButton
                className={'mb-3 ml-3'}
                copyValue={claimNumber.replaceAll('/', '').replace('.', '')}
              ></CopyButton>
              {!hideGoToAdos && (
                <TaskJumpAdos className="ml-3 pt-1" claimNumber={claimNumber} />
              )}
            </div>
          </div>
        );
      }}
    </Translation>
  );
};
