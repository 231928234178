import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { UserDto } from '../../../../types/resource-models';
import { VehicleTask } from '../../../../types/types';
import { useToken } from '../../../../hooks';
import { balModalController } from '../../../../controller/controllers';
import produce from 'immer';
import {
  SelectUserModal,
  SelectUserModalProps,
} from '../../../user/components/select-user-modal';
import {
  DisabableButton,
  WithCapabilities,
} from '../../../../components/authorization';
import { patchVehicleTask } from '../../data/requests';

type ReassignProps = {
  task: VehicleTask;
  onReassignSuccess?: (task: RequestResult<VehicleTask>) => void;
};

export const VehicleTaskReassign = (props: ReassignProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();

  const onSelectedSuccess = (user: UserDto) => {
    patchVehicleTask(
      props.task,
      produce(props.task, (draftState) => {
        draftState.assignee = user;
        draftState.status = 'Assigned';
      }),
      token,
    ).then((task: RequestResult<VehicleTask>) => {
      if (task.status === 'success' && props.onReassignSuccess) {
        props.onReassignSuccess(task);
      }
    });
  };

  const openModal = async () => {
    const modalProps: SelectUserModalProps = {
      initialSelected: props.task.assignee ?? undefined,
      onSelectedSuccess: onSelectedSuccess,
      appCapabilityNames: ['EditVehicleTask', 'ReadOwnedVehicleTask'],
      title: t('taskOverview.claimReassignement'),
      highlightExternals: true,
    };
    const modal = await balModalController.create({
      component: SelectUserModal,
      componentProps: modalProps,
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={['EditVehicleTask']}
      passWithCapabilitiesPropsToChildren
    >
      <DisabableButton
        onClick={(event) => event.detail == 1 && openModal()}
        className="mr-1"
        size="small"
        color="info"
      >
        {t('general.buttons.reassign')}
      </DisabableButton>
    </WithCapabilities>
  );
};
