import produce from 'immer';
import {
  BalHeading,
  BalTabs,
  BalTabItem,
  BalText,
  BalNotification,
  BalAccordion,
} from '@baloise/design-system-components-react';

import { useTranslation } from 'react-i18next';
import '../../../styles/general.scss';
import '../../../styles/detail-view.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getInitialPropertyInspectionTaskJournalCombinedState,
  PropertyInspectionTaskJournalCombinedState,
  usePropertyInspectionTaskFromApi,
} from '../data/hooks';
import { convertUtcToLocal, formatDateTime } from '../../../utils/date';
import {
  concat,
  concatAddress,
  getAssigneePerTypeTranslated,
  getFullHeightWarningCssClass,
  Globals,
} from '../../../utils';
import { RouteKey, EBRoutes } from '../../../router/router';
import { useEffect, useRef, useState } from 'react';
import {
  AddressDto,
  AttachmentDto,
  BaseTaskChangeDto,
  Branch,
  InsuranceType,
  Language,
  PropertyInspectionTaskJournalDto,
  UserDto,
} from '../../../types/resource-models';
import { toast } from 'react-toastify';
import { useToken } from '../../../hooks';
import {
  TaskDetailInfoDisplay,
  BaseTaskAttachments,
  SelectUserModalButton,
} from '../../base-task';
import { RequestResult } from '../../../data';
import {
  PropertyInspectionTask,
  TaskTypes,
  TranslatedSelection,
} from '../../../types/types';
import { balModalController } from '../../../controller/controllers';
import {
  AddressEditButton,
  EditDateModal,
  EditDateTimeModal,
  EditNumberModal,
  EditSelectionModal,
  EditTextModal,
} from '../../../components/input';
import { CombinedState } from '../../base-task';
import {
  getPropertyInspectionTaskAllHistory,
  getPropertyInspectionTaskJournal,
  getPropertyInspectionTaskJournals,
  patchPropertyInspectionTask,
} from '../data/requests';
import { ErrorToast } from '../../../components/toast-notification';
import {
  InfoDisplay,
  Breadcrumbs,
  CopyButton,
  ResultRenderer,
} from '../../../components/ui';
import { EditContacts, ContactDetailInformation } from '../../contacts';
import { PropertyTaskWorkflowButtons } from './property-inspection-task-workflow-buttons/property-inspection-task-workflow-buttons';
import { FileUpload, deleteFile } from '../../files';
import { WithCapabilitiesErrorProps } from '../../../components/authorization';
import { EntityProtocol } from '../../protocol/index';
import {
  cleanPolicyNumber,
  getDefaultAddressWithClaimId,
  getDisplayNameFromUser,
  getEnabledModules,
} from '../../../utils/utilities';
import TaskJumpAdos from '../../base-task/components/task-detail-jump-ados';
import { BaseTaskMemoFieldsButton } from '../../base-task/components';
import PropertyInspectionTaskJournalSearchFormTable from './property-inspection-task-journal-search-form-table';
import { PropertyInspectionTaskJournalShowView } from './property-inspection-task-journal-show-view';
import { PropertyInspectionTaskSummaryFieldsButton } from './property-inspection-task-summary-fields/property-inspection-task-summary-fields-button';
import { InspectionSearchForm } from './inspections-search-form-table';

type PropertyTaskDetailId = {
  taskId: string;
};

export const PropertyInspectionTaskDetail = ({
  withCapabilitiesError,
  setCombinedState,
  combinedState,
}: {
  withCapabilitiesError?: WithCapabilitiesErrorProps;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  combinedState: CombinedState;
}): JSX.Element => {
  return (
    <>
      {!withCapabilitiesError && (
        <PropertyInspectionTaskDetailContent
          setCombinedState={setCombinedState}
          combinedState={combinedState}
        />
      )}
    </>
  );
};

const PropertyInspectionTaskDetailContent = ({
  combinedState,
  setCombinedState,
}: {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
}): JSX.Element => {
  const reloadTable = () => {
    if (combinedState.tasks.status === 'success') {
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.tasks = { status: 'loading' };
        }),
      );
    }
  };
  const token = useToken();
  const { t } = useTranslation();
  const { taskId } = useParams<PropertyTaskDetailId>();
  const [propertyInspectionTask, setPropertyInspectionTask] =
    usePropertyInspectionTaskFromApi(taskId ?? '');
  const [propertyInspectionTaskHistory, setPropertyInspectionTaskHistory] =
    useState<RequestResult<BaseTaskChangeDto[]> | undefined>(undefined);
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabDefault = useRef<HTMLBalTabItemElement>(null);
  const refTabAttachments = useRef<HTMLBalTabItemElement>(null);
  const refContentTabDefault = useRef<HTMLBalTabItemElement>(null);
  const refContentTabs = useRef<HTMLBalTabsElement>(null);
  const [currentDefaultTab, setCurrentDefaultTab] = useState<
    string | undefined
  >(refTabDefault.current?.value);
  const [searchParams] = useSearchParams();
  const getDefaultTabItem = () => {
    const tabItem = searchParams.get('TabItem');
    if (
      tabItem &&
      (tabItem === 'content' || tabItem == 'journal' || tabItem == 'expenses')
    ) {
      return tabItem;
    } else {
      return refContentTabDefault.current?.value;
    }
  };

  const [currentContentDefaultTab, setCurrentContentDefaultTab] = useState<
    string | undefined
  >(getDefaultTabItem());

  useEffect(() => {
    if (propertyInspectionTask.status === 'success') {
      if (currentDefaultTab && currentDefaultTab === 'protocol') {
        if (taskId) {
          setPropertyInspectionTaskHistory({
            status: 'loading',
          });
          getPropertyInspectionTaskAllHistory(token, taskId).then((result) => {
            if (result.status === 'success') {
              setPropertyInspectionTaskHistory(result);
            }
          });
        }
      }
    }
  }, [propertyInspectionTask, currentDefaultTab, token, taskId]);

  const removeFile = async (fileName: string) => {
    if (
      propertyInspectionTask.status == 'success' &&
      propertyInspectionTask.localValue.attachments
    ) {
      const attachmentToDelete =
        propertyInspectionTask.localValue.attachments.find(
          (attachment: AttachmentDto) => attachment.fileName == fileName,
        );
      if (attachmentToDelete) {
        const result = await deleteFile(token, attachmentToDelete);

        if (
          result.status === 'success-no-value' ||
          result.status === 'success'
        ) {
          const newAttachments =
            propertyInspectionTask.localValue.attachments.filter(
              (attachment: AttachmentDto) => attachment.fileName !== fileName,
            );
          setPropertyInspectionTask(
            produce(propertyInspectionTask, (draftState) => {
              draftState.localValue.attachments = newAttachments;
            }),
          );
        }

        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      }
    }
  };

  // Journal control area.
  const [currentJournal, setCurrentJournal] = useState<
    RequestResult<PropertyInspectionTaskJournalDto> | undefined
  >(undefined);

  const onJournalSelected = async (journalId?: string) => {
    if (!journalId) {
      setCurrentJournal(undefined);
      setCurrentDefaultTab('details');
    } else {
      setCurrentJournal({ status: 'loading' });
      const journalRequest = await getPropertyInspectionTaskJournal(
        token,
        journalId as string,
      );
      if (journalRequest.status === 'success') {
        setCurrentDefaultTab('journalContent');
      }
      setCurrentJournal(journalRequest);
    }
  };
  const [journalCombinedState, setJournalCombinedState] =
    useState<PropertyInspectionTaskJournalCombinedState>(
      getInitialPropertyInspectionTaskJournalCombinedState(taskId ?? ''),
    );

  useEffect(() => {
    if (
      propertyInspectionTask.status === 'success' &&
      taskId &&
      token !== '' &&
      currentContentDefaultTab &&
      currentContentDefaultTab === 'journal' &&
      journalCombinedState.journals.status === 'loading'
    ) {
      getPropertyInspectionTaskJournals(
        token,
        journalCombinedState.filter,
      ).then((result) => {
        setJournalCombinedState(
          produce(journalCombinedState, (draftState) => {
            draftState.journals = result;
          }),
        );
      });
    }
  }, [
    propertyInspectionTask,
    taskId,
    token,
    currentContentDefaultTab,
    journalCombinedState,
  ]);

  useEffect(() => {
    if (
      currentContentDefaultTab &&
      currentContentDefaultTab === 'journal' &&
      journalCombinedState.journals.status === 'initial'
    ) {
      setJournalCombinedState(
        produce(journalCombinedState, (draftState) => {
          draftState.journals.status = 'loading';
        }),
      );
      setCurrentJournal(undefined);
    }
  }, [currentContentDefaultTab, journalCombinedState]);

  if (propertyInspectionTask.status === 'success') {
    const assignee = propertyInspectionTask.value.assignee;
    const creator = propertyInspectionTask.value.creator;
    const claim = propertyInspectionTask.value.claim;
    const contact = propertyInspectionTask.value.claim.affectedPartners.find(
      (affectedPartner) =>
        affectedPartner.id === propertyInspectionTask.value.contactId,
    );
    const furtherContacts =
      propertyInspectionTask.localValue.claim.affectedPartners.filter(
        (affectedPartner) =>
          affectedPartner.id != propertyInspectionTask.value.contactId,
      );
    const assigneeName = getDisplayNameFromUser(assignee);
    const creatorName = getDisplayNameFromUser(creator);
    const lastLocationKey: RouteKey = 'PROPERTY_INSPECTION_TASK';
    const policyHolder =
      claim.policyHolder.type === 'person'
        ? claim.policyHolder.person
        : claim.policyHolder.company;

    const onEditOriginExpert = (user: UserDto) => {
      patchPropertyInspectionTask(
        propertyInspectionTask.value,
        produce(propertyInspectionTask.value, (draftState) => {
          draftState.claim.originExpertId = user.id;
        }),
        token,
      ).then(async (task: RequestResult<PropertyInspectionTask>) => {
        if (task.status === 'success') {
          setPropertyInspectionTask(
            produce(propertyInspectionTask, (draftState) => {
              draftState.value.claim.originExpert = user;
              draftState.value.claim.originExpertId = user.id;
              draftState.localValue.claim.originExpert = user;
              draftState.localValue.claim.originExpertId = user.id;
            }),
          );
        }
      });
    };

    const onAssignOriginExpert = () => {
      patchPropertyInspectionTask(
        propertyInspectionTask.value,
        produce(propertyInspectionTask.value, (draftState) => {
          draftState.assignee = claim.originExpert;
          draftState.status = 'Assigned';
        }),
        token,
      ).then(async (task: RequestResult<PropertyInspectionTask>) => {
        if (task.status === 'success') {
          setPropertyInspectionTask(task);
          reloadTable();
        }
      });
    };

    const languages: TranslatedSelection[] = Globals.languages.map(
      (language) => ({
        value: language,
        translatedValue: t(`language.languages.${language}`),
      }),
    );

    const branchTranslatedSelections: TranslatedSelection[] =
      Globals.allBranches.map((branch) => ({
        value: branch,
        translatedValue: t(`branch.${branch}`),
      }));

    return (
      <div className="is-full-height has-background-grey-1 is-flex is-flex-direction-column">
        <div className="has-background-white mr-6 mt-6 ml-6 mb-4 pt-6 pl-6 overflow-hidden detail-border-shadow detail-content">
          <div className="pr-5">
            {propertyInspectionTask.value.deletedAt && (
              <BalNotification color="danger">
                <BalText>
                  {t('claimDetail.warningMessageSoftDeleted', {
                    dateTime: convertUtcToLocal({
                      date: propertyInspectionTask.value.deletedAt,
                    }),
                  })}
                </BalText>
              </BalNotification>
            )}
            {propertyInspectionTask.value.status === 'New' && (
              <BalNotification className="mt-1" color="info">
                <BalText>{t('claimDetail.warningMessageDraft')}</BalText>
              </BalNotification>
            )}
            {propertyInspectionTask.value.claim.createdManually && (
              <BalNotification className="mt-1" color="warning">
                <BalText>
                  {t('claimDetail.warningMessageCreatedManually')}
                </BalText>
              </BalNotification>
            )}
          </div>
          <div
            className={`mt-5 is-flex is-flex-direction-column is-full-width ${getFullHeightWarningCssClass(
              propertyInspectionTask,
            )} position-relative`}
          >
            <div className="is-full-width detail-header is-flex is-flex-direction-column is-justify-content-center">
              <Breadcrumbs
                breadcrumbs={[
                  {
                    label: t(`EBRoutes.${lastLocationKey}`),
                    path: EBRoutes[lastLocationKey].create(),
                  },
                  {
                    label: claim.claimNumber,
                  },
                ]}
              />
              <BalHeading
                level="h3"
                subtitle
                space="none"
                className="mt-1 is-flex"
              >
                {concat([t('claimDetail.claim'), claim.claimNumber])}
                <CopyButton
                  className="ml-3"
                  copyValue={claim.claimNumber
                    .replaceAll('/', '')
                    .replace('.', '')}
                ></CopyButton>
                <TaskJumpAdos
                  className="ml-3 is-inline-block"
                  task={propertyInspectionTask}
                />
              </BalHeading>
              <BalText size="small" className="has-text-black mb-4 mt-2">
                {concat([
                  formatDateTime(propertyInspectionTask.value.createdAt),
                  t('general.from'),
                  getDisplayNameFromUser(propertyInspectionTask.value.creator),
                  '(',
                  propertyInspectionTask.value.creator?.insuranceCenter.name,
                  ')',
                ])}
              </BalText>
            </div>
            <div className="columns is-gapless detail-overview">
              <div className="column mr-1 scroll-bar-minimal">
                <BalTabs
                  ref={refContentTabs}
                  className="is-full-width is-full-height"
                  value={
                    currentContentDefaultTab
                      ? currentContentDefaultTab
                      : 'content'
                  }
                  onBalChange={() => {
                    // Journal tab management.
                    if (
                      refContentTabs.current?.value !== 'journal' &&
                      currentJournal
                    ) {
                      setCurrentJournal(undefined);
                      setCurrentDefaultTab('details');
                      setJournalCombinedState(
                        produce(journalCombinedState, (draftState) => {
                          draftState.journals.status = 'initial';
                        }),
                      );
                    }
                    if (refContentTabs.current?.value === 'journal') {
                      setCurrentJournal(undefined);
                    }

                    if (refContentTabs.current?.value) {
                      setCurrentContentDefaultTab(
                        refContentTabs.current?.value,
                      );
                    }
                  }}
                >
                  <BalTabItem
                    className="bal-tab-headings"
                    value="content"
                    label={t('general.details')}
                    ref={refContentTabDefault}
                  >
                    <div className="tab-item-content columns is-multiline is-gapless pt-4">
                      <div className="column is-12">
                        {/* Task description section */}
                        <div className="is-flex is-align-items-center mb-2 mt-1">
                          <BalHeading
                            space="none"
                            level="h4"
                            color="info"
                            subtitle
                          >
                            {t('general.taskDescription')}
                          </BalHeading>
                          <div className="pl-6">
                            <BaseTaskMemoFieldsButton
                              requiredCapabilities={[
                                'EditPropertyInspectionTask',
                              ]}
                              task={propertyInspectionTask}
                              setTask={setPropertyInspectionTask}
                              taskType={TaskTypes.PropertyInspectionTask}
                            />
                          </div>
                        </div>
                        <BalText space="none" color="info" bold size="small">
                          {t('claimDetail.claimDescription')}
                        </BalText>
                        <BalText className="enable-line-break">
                          {propertyInspectionTask.value.what}
                        </BalText>
                        {propertyInspectionTask.value.remarks && (
                          <>
                            <BalText
                              space="none"
                              color="info"
                              bold
                              size="small"
                            >
                              {t('general.claim.remarks')}
                            </BalText>
                            <BalText className="enable-line-break">
                              {propertyInspectionTask.value.remarks}
                            </BalText>
                          </>
                        )}
                        {propertyInspectionTask.value.internalNote && (
                          <>
                            <BalText
                              space="none"
                              color="info"
                              bold
                              size="small"
                            >
                              {t('baseTaskDtoColumns.internalNote')}
                            </BalText>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  propertyInspectionTask.value.internalNote ??
                                  '',
                              }}
                            ></div>
                          </>
                        )}
                      </div>
                      <div className="column is-12 is-6-fullhd mt-3">
                        <div className="columns is-multiline is-gapless pr-6">
                          <BalHeading
                            space="none"
                            level="h4"
                            color="info"
                            subtitle
                          >
                            {t('general.claim.claimInformation')}
                          </BalHeading>
                          <div className="column is-full">
                            <InfoDisplay
                              title={t('general.branch')}
                              text={t(`branch.${claim.branch}`)}
                              className="pb-1"
                              editComponent={
                                <EditSelectionModal
                                  label={t('general.branch')}
                                  placeholder={t('general.branch')}
                                  value={{
                                    value: claim.branch,
                                    translatedValue: t(
                                      `branch.${claim.branch}`,
                                    ),
                                  }}
                                  values={branchTranslatedSelections}
                                  requiredCapabilities="EditPropertyInspectionTask"
                                  onSave={(branch: Branch) => {
                                    if (branch) {
                                      patchPropertyInspectionTask(
                                        propertyInspectionTask.value,
                                        produce(
                                          propertyInspectionTask.value,
                                          (draftState) => {
                                            draftState.claim.branch = branch;
                                          },
                                        ),
                                        token,
                                      ).then((result) => {
                                        if (result.status === 'success') {
                                          setPropertyInspectionTask(result);
                                          balModalController.dismiss();
                                        } else if (result.status === 'error') {
                                          toast(ErrorToast(result.errorValue));
                                        }
                                      });
                                    }
                                  }}
                                />
                              }
                            />
                          </div>
                          <div className="column is-full">
                            {claim.majorEventNumber ? (
                              <InfoDisplay
                                className="pb-1"
                                title={t('claimDetail.majorEvent')}
                                text={claim.majorEventNumber?.toString()}
                                editComponent={
                                  <EditNumberModal
                                    isRequired={true}
                                    label={t('claimDetail.majorEvent')}
                                    placeholder={t('claimDetail.majorEvent')}
                                    value={claim.majorEventNumber?.toString()}
                                    requiredCapabilities="EditPropertyInspectionTask"
                                    onSave={(value: string | undefined) => {
                                      patchPropertyInspectionTask(
                                        propertyInspectionTask.value,
                                        produce(
                                          propertyInspectionTask.value,
                                          (draftState) => {
                                            if (value) {
                                              draftState.claim.majorEventNumber =
                                                value as unknown as number;
                                            }
                                          },
                                        ),
                                        token,
                                      ).then((result) => {
                                        if (result.status === 'success') {
                                          setPropertyInspectionTask(result);
                                          balModalController.dismiss();
                                        } else if (result.status === 'error') {
                                          toast(ErrorToast(result.errorValue));
                                        }
                                      });
                                    }}
                                  />
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="column is-full">
                            <InfoDisplay
                              title={t('claimDetail.policyNumber')}
                              text={claim.policyNumber}
                              className="pb-1"
                              editComponent={
                                <EditTextModal
                                  isRequired={true}
                                  label={t('general.claim.policyNumber')}
                                  placeholder={t('general.claim.policyNumber')}
                                  value={
                                    propertyInspectionTask.value.claim
                                      .policyNumber
                                  }
                                  formatBeforeEdit={cleanPolicyNumber}
                                  requiredCapabilities="EditPropertyInspectionTask"
                                  onSave={(value: string | undefined) => {
                                    if (value) {
                                      patchPropertyInspectionTask(
                                        propertyInspectionTask.value,
                                        produce(
                                          propertyInspectionTask.value,
                                          (draftState) => {
                                            draftState.claim.policyNumber =
                                              value;
                                          },
                                        ),
                                        token,
                                      ).then((result) => {
                                        if (result.status === 'success') {
                                          setPropertyInspectionTask(result);
                                          balModalController.dismiss();
                                        } else if (result.status === 'error') {
                                          toast(ErrorToast(result.errorValue));
                                        }
                                      });
                                    }
                                  }}
                                />
                              }
                            />
                          </div>
                          <div className="column is-full">
                            <InfoDisplay
                              title={t('claimDetail.date')}
                              text={formatDateTime(claim.claimDate)}
                              className="pb-1"
                              editComponent={
                                <EditDateModal
                                  isRequired={true}
                                  label={t('claimDetail.date')}
                                  placeholder={t('claimDetail.date')}
                                  value={propertyInspectionTask.value.claim.claimDate?.substring(
                                    0,
                                    10,
                                  )}
                                  requiredCapabilities="EditPropertyInspectionTask"
                                  onSave={(
                                    value: string,
                                    updateToAdos: boolean,
                                  ) => {
                                    patchPropertyInspectionTask(
                                      propertyInspectionTask.value,
                                      produce(
                                        propertyInspectionTask.value,
                                        (draftState) => {
                                          draftState.claim.claimDate =
                                            value ?? ''; // internally we always use empty strings instead of null ones.
                                        },
                                      ),
                                      token,
                                      updateToAdos,
                                    ).then((result) => {
                                      if (result.status === 'success') {
                                        setPropertyInspectionTask(result);
                                        balModalController.dismiss();
                                      } else if (result.status === 'error') {
                                        toast(ErrorToast(result.errorValue));
                                      }
                                    });
                                  }}
                                />
                              }
                            />
                          </div>
                          {propertyInspectionTask.status === 'success' &&
                            propertyInspectionTask.value.status ===
                              'InProgressLocal' && (
                              <div className="column is-full">
                                <InfoDisplay
                                  title={t(
                                    'inspectionTask.scheduledInspectionDate',
                                  )}
                                  text={convertUtcToLocal({
                                    date: propertyInspectionTask.value
                                      .scheduledInspectionDate,
                                    formatted: true,
                                    notIncludeSeconds: true,
                                  })}
                                  className="pb-1"
                                  editComponent={
                                    <EditDateTimeModal
                                      isRequired={false}
                                      isUtc={true}
                                      label={t(
                                        'inspectionTask.scheduledInspectionDate',
                                      )}
                                      value={convertUtcToLocal({
                                        date: propertyInspectionTask.value
                                          .scheduledInspectionDate,
                                        formatted: false,
                                      })}
                                      requiredCapabilities="EditPropertyInspectionTask"
                                      onSave={(
                                        value: string,
                                        updateToAdos: boolean,
                                      ) => {
                                        patchPropertyInspectionTask(
                                          propertyInspectionTask.value,
                                          produce(
                                            propertyInspectionTask.value,
                                            (draftState) => {
                                              draftState.scheduledInspectionDate =
                                                value ?? ''; // internally we always use empty strings instead of null ones.
                                            },
                                          ),
                                          token,
                                          updateToAdos,
                                        ).then((result) => {
                                          if (result.status === 'success') {
                                            setPropertyInspectionTask(result);
                                            balModalController.dismiss();
                                          } else if (
                                            result.status === 'error'
                                          ) {
                                            toast(
                                              ErrorToast(result.errorValue),
                                            );
                                          }
                                        });
                                      }}
                                    />
                                  }
                                />
                              </div>
                            )}
                          <div className="column is-full">
                            <InfoDisplay
                              title={t('claimDetail.place')}
                              text={concatAddress(claim.claimAddress)}
                              className="pb-1"
                              editComponent={
                                <AddressEditButton
                                  requiredCapabilities={[
                                    'EditPropertyInspectionTask',
                                  ]}
                                  initialValue={
                                    propertyInspectionTask.value.claim
                                      .claimAddress ??
                                    getDefaultAddressWithClaimId(
                                      propertyInspectionTask.value.claim.id,
                                    )
                                  }
                                  onSave={async (address: AddressDto) => {
                                    if (address) {
                                      const result =
                                        await patchPropertyInspectionTask(
                                          propertyInspectionTask.value,
                                          produce(
                                            propertyInspectionTask.value,
                                            (draftState) => {
                                              draftState.claim.claimAddress = {
                                                claimId: draftState.claim.id,
                                                ...address,
                                              };
                                            },
                                          ),
                                          token,
                                        );
                                      if (result.status === 'success') {
                                        setPropertyInspectionTask(result);
                                      } else if (result.status === 'error') {
                                        toast(ErrorToast(result.errorValue));
                                      }
                                    }
                                  }}
                                />
                              }
                            />
                          </div>
                          <div className="column is-full">
                            <InfoDisplay
                              title={t('claimDetail.insuranceType')}
                              text={t(`insuranceType.${claim.insuranceType}`)}
                              className="pb-1"
                              editComponent={
                                <EditSelectionModal
                                  label={t('claimDetail.insuranceType')}
                                  placeholder={t('claimDetail.insuranceType')}
                                  value={{
                                    value: claim.insuranceType,
                                    translatedValue: t(
                                      `insuranceType.${claim.insuranceType}`,
                                    ),
                                  }}
                                  values={Globals.insuranceTypeTranslatedSelections()}
                                  requiredCapabilities="EditPropertyInspectionTask"
                                  onSave={(value: InsuranceType) => {
                                    if (value) {
                                      patchPropertyInspectionTask(
                                        propertyInspectionTask.value,
                                        produce(
                                          propertyInspectionTask.value,
                                          (draftState) => {
                                            draftState.claim.insuranceType =
                                              value;
                                          },
                                        ),
                                        token,
                                      ).then((result) => {
                                        if (result.status === 'success') {
                                          setPropertyInspectionTask(result);
                                          balModalController.dismiss();
                                        } else if (result.status === 'error') {
                                          toast(ErrorToast(result.errorValue));
                                        }
                                      });
                                    }
                                  }}
                                />
                              }
                            />
                          </div>
                          <div className="column is-full">
                            <SelectUserModalButton
                              user={claim.originExpert}
                              title={t('claimDetail.originExpert')}
                              taskType={TaskTypes.PropertyInspectionTask}
                              onEditSuccess={onEditOriginExpert}
                              onAssignOriginExpert={onAssignOriginExpert}
                              taskStatus={propertyInspectionTask.value.status}
                            />
                          </div>
                        </div>
                      </div>
                      {propertyInspectionTask.value.assigneeExternal && (
                        <div className="column is-12 is-6-fullhd mt-3">
                          <div className="is-flex is-align-items-center mb-2 mt-4">
                            <BalHeading
                              space="none"
                              level="h4"
                              color="info"
                              subtitle
                            >
                              {t('inspectionTask.externalPartner')}
                            </BalHeading>
                            {/* <BalButton
                          className="ml-2"
                          square
                          outlined
                          size="small"
                          color="info"
                          icon="nav-go-right"
                          onClick={() => {
                            if (propertyInspectionTask.value.assigneeExternalId)
                              navigate(
                                EBRoutes.NETWORK_PARTNER_DETAIL.create({
                                  networkPartnerId:
                                    propertyInspectionTask.value
                                      .assigneeExternalId,
                                }),
                              );
                          }}
                        /> */}
                          </div>
                          <div className="columns is-multiline is-gapless pr-6">
                            <div className="column is-full">
                              <InfoDisplay
                                title={t('general.name')}
                                text={
                                  propertyInspectionTask.value.assigneeExternal
                                    ?.company?.name ?? ''
                                }
                                className="pb-1"
                              />
                              <InfoDisplay
                                title={t('general.address')}
                                text={
                                  concatAddress(
                                    propertyInspectionTask.value
                                      .assigneeExternal?.company?.address,
                                  ) ?? ''
                                }
                                className="pb-1"
                              />
                              <InfoDisplay
                                title={t('general.phone')}
                                text={
                                  propertyInspectionTask.value.assigneeExternal
                                    ?.company?.phoneNumber ?? ''
                                }
                                className="pb-1"
                              />
                              <InfoDisplay
                                title={t('general.email')}
                                text={
                                  propertyInspectionTask.value.assigneeExternal
                                    ?.company?.email ?? ''
                                }
                                className="pb-1"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="column is-12 is-6-fullhd">
                        <div className="is-flex is-align-items-center mb-2 mt-4">
                          <BalHeading
                            space="none"
                            level="h4"
                            color="info"
                            subtitle
                          >
                            {t('claimDetail.contactInformation')}
                          </BalHeading>
                          <EditContacts
                            taskType={TaskTypes.PropertyInspectionTask}
                            task={propertyInspectionTask}
                            setTask={setPropertyInspectionTask}
                          />
                        </div>
                        {contact && contact.partner && (
                          <div className="columns is-multiline is-gapless pr-6">
                            <div className="column is-full">
                              <ContactDetailInformation
                                contactPartner={contact}
                              />
                            </div>
                          </div>
                        )}
                        {furtherContacts.length > 0 && (
                          <>
                            <BalHeading
                              space="none"
                              level="h4"
                              color="info"
                              subtitle
                              className="mb-2"
                            >
                              {t('claimDetail.furtherContacts')}
                            </BalHeading>
                            {furtherContacts.map((contact) => {
                              return (
                                <ContactDetailInformation
                                  contactPartner={contact}
                                  className="pr-6"
                                  key={contact.id}
                                ></ContactDetailInformation>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    disabled={
                      getEnabledModules().propertyInspectionTaskJournal == false
                    }
                    className="bal-tab-headings is-full-height"
                    value="journal"
                    label={t('inspectionTask.journal.entityName')}
                  >
                    <>
                      {propertyInspectionTask.status === 'success' &&
                        journalCombinedState.journals.status === 'success' && (
                          <BalAccordion
                            className="my-4"
                            openLabel={t(
                              'investigationTask.openConclusionCard',
                            )}
                            closeIcon="close"
                            closeLabel={t(
                              'investigationTask.closeConclusionCard',
                            )}
                          >
                            <div className="is-flex is-align-items-center my-2">
                              <BalHeading
                                space="none"
                                level="h4"
                                color="info"
                                subtitle
                              >
                                {t('investigationTask.conclusion')}
                              </BalHeading>
                              <div className="pl-6">
                                <PropertyInspectionTaskSummaryFieldsButton
                                  requiredCapabilities={[
                                    'EditPropertyInspectionTask',
                                  ]}
                                  task={propertyInspectionTask}
                                  setTask={setPropertyInspectionTask}
                                />
                              </div>
                            </div>
                            <BalText
                              space="none"
                              color="info"
                              bold
                              size="small"
                            >
                              {propertyInspectionTask.value.summary
                                ? t('inspectionTask.summary')
                                : ''}
                            </BalText>
                            <div
                              className="scroll-bar-minimal pb-4"
                              dangerouslySetInnerHTML={{
                                __html:
                                  propertyInspectionTask.value.summary ?? '',
                              }}
                            ></div>
                            <BalText
                              space="none"
                              color="info"
                              bold
                              size="small"
                            >
                              {propertyInspectionTask.value.recommendation
                                ? t('inspectionTask.recommendation')
                                : ''}
                            </BalText>
                            <div
                              className="scroll-bar-minimal mb-4"
                              dangerouslySetInnerHTML={{
                                __html:
                                  propertyInspectionTask.value.recommendation ??
                                  '',
                              }}
                            ></div>
                          </BalAccordion>
                        )}
                      {journalCombinedState.journals != null && (
                        <PropertyInspectionTaskJournalSearchFormTable
                          task={propertyInspectionTask.value}
                          combinedState={journalCombinedState}
                          setCombinedState={setJournalCombinedState}
                          onJournalSelected={onJournalSelected}
                        />
                      )}
                    </>
                  </BalTabItem>
                  <BalTabItem
                    disabled={
                      getEnabledModules().propertyInspectionTaskJournal == false
                    }
                    className="bal-tab-headings is-full-height"
                    value="inspections"
                    label={t('eSI.titleInspections')}
                  >
                    <>
                      {propertyInspectionTask.value.inspections != null && (
                        <InspectionSearchForm
                          inspectionTaskId={propertyInspectionTask.value.id}
                          initialInspections={
                            propertyInspectionTask.value.inspections
                          }
                        />
                      )}
                    </>
                  </BalTabItem>
                </BalTabs>
              </div>
            </div>
            <div className="sub-menu-actions is-pulled-right">
              <PropertyTaskWorkflowButtons
                task={propertyInspectionTask.value}
                className="detail-header is-flex is-align-items-center ml-1"
                onChangeSuccess={async (task) => {
                  setPropertyInspectionTask(task);
                  reloadTable();
                }}
              />
              <div className="detail-tabs">
                <BalTabs
                  ref={refTabs}
                  className="has-background-grey-1 p-4 detail-information-content"
                  value={currentDefaultTab ? currentDefaultTab : 'details'}
                  onBalChange={() => {
                    if (refTabs.current?.value) {
                      setCurrentDefaultTab(refTabs.current?.value);
                    }
                  }}
                >
                  <BalTabItem
                    className="bal-tab-headings"
                    value="details"
                    label={t('general.details')}
                    ref={refTabDefault}
                  >
                    <TaskDetailInfoDisplay
                      title={t('baseTaskListDtoColums.priority')}
                      text={
                        propertyInspectionTask.localValue.priority
                          ? t(
                              `baseTaskPriorities.${propertyInspectionTask.localValue.priority}`,
                            )
                          : ''
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={getAssigneePerTypeTranslated(
                        TaskTypes.PropertyInspectionTask,
                      )}
                      text={assigneeName}
                      user={assignee}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.taskCreator')}
                      text={creatorName}
                      user={creator}
                    />
                    <TaskDetailInfoDisplay
                      title={t('inspectionTask.externalPartner')}
                      text={
                        propertyInspectionTask.value.assigneeExternal?.company
                          ?.name
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={t('language.reportLabel')}
                      text={t(
                        `language.languages.${propertyInspectionTask.value.language}`,
                      )}
                      editComponent={
                        <EditSelectionModal
                          label={t('language.reportLabel')}
                          placeholder={t('language.reportLabel')}
                          value={{
                            value: propertyInspectionTask.value.language,
                            translatedValue: t(
                              `language.languages.${propertyInspectionTask.value.language}`,
                            ),
                          }}
                          values={languages}
                          requiredCapabilities="EditPropertyInspectionTask"
                          onSave={(value: string) => {
                            patchPropertyInspectionTask(
                              propertyInspectionTask.value,
                              produce(
                                propertyInspectionTask.value,
                                (draftState) => {
                                  draftState.language = value as Language;
                                },
                              ),
                              token,
                            ).then((result) => {
                              if (result.status === 'success') {
                                setPropertyInspectionTask(result);
                                balModalController.dismiss();
                              } else if (result.status === 'error') {
                                toast(ErrorToast(result.errorValue));
                              }
                            });
                          }}
                        />
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={t('general.claim.status')}
                      taskStatus={propertyInspectionTask.value.status}
                    />
                    <TaskDetailInfoDisplay
                      title={t('general.branch')}
                      text={t(`branch.${claim.branch}`)}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.policyNumber')}
                      text={claim.policyNumber}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.policyHolderShort')}
                      text={concat([
                        claim.policyHolder.type === 'person'
                          ? claim.policyHolder.person.displayName
                          : claim.policyHolder.company.displayName,
                        policyHolder.address ? policyHolder.address.street : '',
                        policyHolder.address
                          ? policyHolder.address.houseNumber
                          : '',
                        policyHolder.address
                          ? concat([
                              policyHolder.address.zipCode?.toString(),
                              policyHolder.address.city,
                            ])
                          : '',
                      ])}
                    />
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'attachments'
                        ? 'attachments-tab'
                        : ''
                    }`}
                    value="attachments"
                    label={t('general.claim.attachments')}
                    ref={refTabAttachments}
                  >
                    <div className="tab-item-content content-container is-flex is-flex-direction-column attachments-tab pr-0">
                      <BaseTaskAttachments
                        attachments={
                          propertyInspectionTask.localValue.attachments
                        }
                        removeFile={removeFile}
                        withScrollbar
                      />
                      <FileUpload
                        entityType={TaskTypes.PropertyInspectionTask}
                        className="pb-0"
                        entity={propertyInspectionTask}
                        setEntity={setPropertyInspectionTask}
                        hasFileList={false}
                      />
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'protocol' ? 'protocol-tab' : ''
                    }`}
                    value="protocol"
                    label={t('claimDetail.protocol')}
                  >
                    <div className="tab-item-content scroll-bar-minimal pt-2">
                      {propertyInspectionTaskHistory != null && (
                        <ResultRenderer
                          result={propertyInspectionTaskHistory}
                          defaultValue={[]}
                          loaded={(tasksResponse) => {
                            return <EntityProtocol history={tasksResponse} />;
                          }}
                        />
                      )}
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'journalContent'
                        ? 'protocol-tab'
                        : ''
                    }`}
                    value="journalContent"
                    label={t('inspectionTask.journal.entityName')}
                    hidden={currentJournal === undefined}
                  >
                    <div className="tab-item-content scroll-bar-minimal pt-2">
                      {currentJournal?.status === 'success' &&
                        propertyInspectionTask.status === 'success' && (
                          <PropertyInspectionTaskJournalShowView
                            journal={currentJournal.localValue}
                            propertyInspectionTask={
                              propertyInspectionTask.value
                            }
                          />
                        )}
                    </div>
                  </BalTabItem>
                </BalTabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};
