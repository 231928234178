import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { balModalController } from '../../controller/controllers';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

type QRCodeModalProps = {
  header: string;
  value: string;
};

const QRCodeModalContent = (props: QRCodeModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <BalModalHeader>
        <BalHeading space="none" level="h3" className="mb-3">
          {props.header}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <QRCode
          className="px-6"
          value={props.value}
          renderAs={'svg'}
          width={'100%'}
          height={512}
        />
        <BalButtonGroup position="right">
          <BalButton
            onClick={() => {
              balModalController.dismiss();
            }}
            color="info"
          >
            {t('general.buttons.close')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
};
export const openQRCodeModal = async (header: string, value: string) => {
  const modal = await balModalController.create({
    component: QRCodeModalContent,
    componentProps: { header: header, value: value },
    cssClass: 'center-modal',
    backdropDismiss: true,
  });
  return modal.present();
};
