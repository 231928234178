import { WithCapabilities } from '../authorization/with-capabilities';
import { DisabableLink } from '../authorization/disabable-link';
import { Icon } from '../ui/icon';
import addIcon from '../../assets/icons/journal-add.svg';
import { useContext } from 'react';
import { UserContext } from '../../context';
import { AppCapabilityNames } from '../../types/resource-models';
import { EBRoutes } from '../../router/router';
import { useLocation, useNavigate } from 'react-router';
import VehicleTaskWizardSelect from '../../features/vehicle-task/components/vehicle-task-wizard-select';
import { balModalController } from '../../controller/controllers';

const NavbarAddTaskIconContent = ({
  appCapability,
  to,
}: {
  appCapability: AppCapabilityNames;
  to: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const navigateTo = (url: string): void => {
    navigate(url);
  };
  const openModal = async () => {
    const modal = await balModalController.create({
      component: VehicleTaskWizardSelect,
      componentProps: { navigateTo },
      cssClass: 'center-modal',
    });
    return modal.present();
  };

  return (
    <WithCapabilities
      requiredCapabilities={[appCapability]}
      passWithCapabilitiesPropsToChildren
    >
      {appCapability === 'CreateVehicleTask' ? (
        <label
          className="navbar-item p-2 navbar-add-button"
          onClick={() => openModal()}
        >
          <Icon iconPath={addIcon} />
        </label>
      ) : (
        <DisabableLink className="navbar-item p-2 navbar-add-button" to={to}>
          <Icon iconPath={addIcon} />
        </DisabableLink>
      )}
    </WithCapabilities>
  );
};

export const NavbarAddTaskIcon = (): JSX.Element => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  if (location.pathname.search('wizard') > 0) {
    return <></>;
  }

  return user.status === 'success' || user.status === 'loading-with-value' ? (
    user.value.appCapabilities.includes('CreatePropertyInspectionTask') &&
    location.pathname.search('property-inspection-task') > 0 ? (
      <NavbarAddTaskIconContent
        appCapability="CreatePropertyInspectionTask"
        to={EBRoutes.PROPERTY_INSPECTION_TASK_WIZARD.create({})}
      />
    ) : user.value.appCapabilities.includes('CreateVehicleTask') &&
      location.pathname.search('vehicle-task') > 0 ? (
      <NavbarAddTaskIconContent
        appCapability="CreateVehicleTask"
        to={EBRoutes.VEHICLE_INSPECTION_TASK_WIZARD.create({})}
      />
    ) : user.value.appCapabilities.includes('CreateFraudInspectionTask') &&
      location.pathname.search('investigation-task') > 0 ? (
      <NavbarAddTaskIconContent
        appCapability="CreateFraudInspectionTask"
        to={EBRoutes.INVESTIGATION_TASK_WIZARD.create({})}
      />
    ) : user.value.appCapabilities.includes('CreateAccidentInspectionTask') &&
      location.pathname.search('accident-inspection-task') > 0 ? (
      <NavbarAddTaskIconContent
        appCapability="CreateAccidentInspectionTask"
        to={EBRoutes.ACCIDENT_INSPECTION_TASK_WIZARD.create({})}
      />
    ) : (
      <></>
    )
  ) : (
    <DisabableLink
      className="navbar-item p-2 navbar-add-button"
      to={''}
      withCapabilitiesError
    >
      <Icon iconPath={addIcon} />
    </DisabableLink>
  );
};
