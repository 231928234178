import {
  BalButton,
  BalButtonGroup,
  BalNotification,
  BalText,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { removeCachedDataFromLocalStorage } from '../../../utils';

export const VersionWarning = (): JSX.Element => {
  const { t } = useTranslation();

  // Version state management.
  const [updatedVersionState, setUpdatedVersionState] =
    useState<boolean>(false);
  addEventListener('onVersionChangedEvent', () => {
    if (!updatedVersionState) {
      setUpdatedVersionState(true);
    }
  });

  return (
    <>
      {updatedVersionState && (
        <div className="m-3">
          <BalNotification color="primary">
            <BalText bold>
              {t('general.refreshRequired', {
                refreshButtonName: t('general.refresh'),
              })}
            </BalText>
            <BalButtonGroup>
              <BalButton
                elementType="button"
                className="mt-3"
                color="info"
                onClick={async (event) => {
                  if (event.detail == 1) {
                    removeCachedDataFromLocalStorage();
                    window.location.reload();
                  }
                }}
              >
                {t('general.refresh')}
              </BalButton>
            </BalButtonGroup>
          </BalNotification>
        </div>
      )}
    </>
  );
};
