import React, { useEffect, useState } from 'react';
import {
  BaseTaskFilterTaskScreen,
  BaseTasksListResponse,
} from '../../../types/types';
import { Globals } from '../../../utils';
import { useToken } from '../../../hooks/useToken';
import { BaseTask, TaskTypes } from '../../../types/types';
import { getBaseTask } from './requests';
import { RequestResult } from '../../../data';

export type CombinedState = {
  tasks: RequestResult<BaseTasksListResponse>;
  filter: BaseTaskFilterTaskScreen;
  defaultFilter: BaseTaskFilterTaskScreen;
  label: string;
};

export const initialCombinedState: CombinedState = {
  tasks: { status: 'initial' },
  defaultFilter: Globals.filterDefault,
  filter: Globals.filterDefault,
  label: '-',
};

export type CombinedStateProps = {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
};

export function useBaseTask(
  baseTaskId: string,
  taskType: TaskTypes,
): [RequestResult<BaseTask>, React.Dispatch<RequestResult<BaseTask>>] {
  const token = useToken();
  const [baseTaskResponse, setBaseTaskResponse] = useState<
    RequestResult<BaseTask>
  >({
    status: 'loading',
  });

  useEffect(() => {
    setBaseTaskResponse({ status: 'loading' });
    if (token !== '' && baseTaskId !== '') {
      getBaseTask(token, baseTaskId, taskType).then(setBaseTaskResponse);
    }
  }, [token, baseTaskId, taskType]);

  return [baseTaskResponse, setBaseTaskResponse];
}
