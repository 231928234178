import * as React from 'react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

export const LogoutView: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  React.useEffect(() => {
    instance.logout();
  });

  return (
    <div className="hero is-halfheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <p className="subtitle"> {t('authentication.loggingOut')}</p>
        </div>
      </div>
    </div>
  );
};
