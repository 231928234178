import '../styles/general.scss';
import { WithCapabilities } from '../components/authorization';
import '../styles/general.scss';
import produce from 'immer';
import { Route, Routes } from 'react-router-dom';
import {
  PropertyInspectionTaskDetail,
  useInspectionTasksFromApi,
  PropertyInspectionTaskSearchForm,
  PropertyInspectionTaskViewMenu,
} from '../features/property-inspection-task';
import { Globals } from '../utils';
import { Filter } from '../features/view-menu/view-menu';
import {
  BaseTaskFilterCriteria,
  SortOrder,
  BaseTaskListDto,
} from '../types/resource-models';
import { useContext } from 'react';
import { UserContext } from '../context';
import { useState } from 'react';
import { RequestResult } from '../data';

const PropertyInspectionTaskView = (): JSX.Element => {
  const { combinedState, setCombinedState } = useInspectionTasksFromApi();
  const { user } = useContext(UserContext);
  const setLabelAndFilter = (label: string, filter: Filter) => {
    // get users profile sorting configuration to apply it to current filter.
    let customOrderField: string | undefined = undefined;
    let customOrderBy: SortOrder | undefined = undefined;
    if (user.status === 'success') {
      const userTableSetting = user.localValue.userTableSettings.find(
        (x) => x.type === 'PropertyInspectionTask',
      );
      if (userTableSetting?.orderField) {
        customOrderField = userTableSetting?.orderField;
        customOrderBy = userTableSetting?.orderBy;
      }
    }

    setCombinedState(
      produce(combinedState, (draftState) => {
        if (
          combinedState.tasks.status === 'success' ||
          combinedState.tasks.status === 'error'
        ) {
          draftState.tasks.status = 'loading-with-value';
        }
        draftState.defaultFilter = {
          ...Globals.filterDefault,
          ...(filter as Partial<BaseTaskFilterCriteria>),
        };
        draftState.filter = customOrderField
          ? {
              ...draftState.defaultFilter,
              orderField: customOrderField,
              orderBy: customOrderBy ?? 'asc',
            }
          : draftState.defaultFilter;
        draftState.label = label;
      }),
    );
  };
  const [task, setTask] = useState<RequestResult<BaseTaskListDto>>({
    status: 'initial',
  });
  const [editFilter, setEditFilter] = useState<string | undefined>();
  return (
    <WithCapabilities
      requiredCapabilities={[
        'CreatePropertyInspectionTask',
        'EditPropertyInspectionTask',
      ]}
      passWithCapabilitiesPropsToChildren={false}
      showErrorToast
    >
      <PropertyInspectionTaskViewMenu
        setLabelAndFilter={setLabelAndFilter}
        combinedState={combinedState}
        editFilter={editFilter}
        setEditFilter={setEditFilter}
      />
      {
        <Routes>
          <Route
            index
            element={
              <PropertyInspectionTaskSearchForm
                combinedState={combinedState}
                setCombinedState={setCombinedState}
                editFilter={editFilter}
                task={task}
                setTask={setTask}
              />
            }
          />
          <Route
            path={':taskId'}
            element={
              <PropertyInspectionTaskDetail
                setCombinedState={setCombinedState}
                combinedState={combinedState}
              />
            }
          />
        </Routes>
      }
    </WithCapabilities>
  );
};

export default PropertyInspectionTaskView;
