import '../../styles/general.scss';
import {
  Controller,
  FieldValues,
  UseFormReturn,
  ValidationRule,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalFieldMessage,
  BalTextarea,
} from '@baloise/design-system-components-react';
import { concat } from '../../utils';
import TextEditor from './text-editor';
import lang from 'suneditor-react/dist/types/lang';

type BalTextareaFormFieldProps = {
  className?: string;
  titleClassName?: string;
  controllerName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<FieldValues, object>;
  title?: string;
  placeholder?: string;
  value?: string;
  onBalChange?: (e: CustomEvent<string | undefined | null>) => void;
  onChange?: (text: string) => void;
  required?: string | ValidationRule<boolean>;
  disabled?: boolean;
  rows?: number | undefined;
  richText?: boolean;
  richTextHeight?: string;
  language?: lang;
};

export const BalTextareaFormField = (
  props: BalTextareaFormFieldProps,
): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Controller
      name={props.controllerName}
      control={props.methods.control}
      defaultValue={props.value}
      rules={{
        required:
          props.required === undefined
            ? t('error.mandatoryField')
            : props.required,
      }}
      render={({ field, fieldState }) => (
        <BalField
          className={concat(['is-gapless', props.className])}
          disabled={props.disabled}
        >
          {props.title && (
            <BalFieldLabel className={concat(['m-0', props.titleClassName])}>
              {props.title}
            </BalFieldLabel>
          )}
          <BalFieldControl className={props.richText ? 'is-full-height' : ''}>
            {props.richText ? (
              <TextEditor
                disabled={props.disabled}
                height={props.richTextHeight}
                placeholder={
                  props.required === undefined || props.required !== false
                    ? `${props.placeholder} *`
                    : props.placeholder
                }
                value={props.value}
                language={props.language ?? 'de'}
                onChange={(text: string) => {
                  props.onChange && props.onChange(text);
                  field.onChange(text);
                }}
              />
            ) : (
              <BalTextarea
                disabled={props.disabled}
                className="text-area-no-space"
                placeholder={
                  props.required === undefined || props.required !== false
                    ? `${props.placeholder} *`
                    : props.placeholder
                }
                value={props.value}
                onBalChange={(e: CustomEvent<string | undefined | null>) => {
                  props.onBalChange && props.onBalChange(e);
                  field.onChange(e.detail);
                }}
                rows={props.rows}
              />
            )}
          </BalFieldControl>
          <div className="m-0">
            {fieldState.error && (
              <BalFieldMessage color="danger">
                {fieldState.error.message}
              </BalFieldMessage>
            )}
          </div>
        </BalField>
      )}
    />
  );
};

BalTextareaFormField.displayName = 'BalTextareaFormField';
