import {
  ColDef,
  GridOptions,
  ILoadingCellRendererParams,
} from 'ag-grid-community';
import { TFunction } from 'i18next';
import { formatDateTime } from '../../../../utils/date';
import {
  AppCapabilityNames,
  BaseTaskListDto,
  ColumnWidth,
  UserDto,
  UserTableSettingDto,
} from '../../../../types/resource-models';
import { getNameFromPartner, TaskTypes } from '../../../../types/types';
import { BalIcon, BalText } from '@baloise/design-system-components-react';
import { EBRoutes } from '../../../../router/router';
import {
  DisabableLink,
  WithCapabilities,
} from '../../../../components/authorization';
import { Avatar } from '../../../../components/ui';
import { balModalController } from '../../../../controller/controllers';
import {
  earlybyteTableTextRenderer,
  getDisplayNameFromUser,
  noComparingComparator,
  renderBaseTaskAssigneeAvatar,
  renderBaseTaskStatusTag,
  RenderPriority,
} from '../../../../utils/utilities';
import { UserTableTypes } from '../../../../../../bat-shared/resource-models';
import { TableSettingsForm } from '../../../user/components/table-settings-form';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { RequestResult } from '../../../../data';
import produce from 'immer';
import {
  getAssigneePerTypeTranslated,
  getTaskTypeFromEntityName,
} from '../../../../utils';
import { balIconSettings } from '@baloise/design-system-icons';
import { UserContext } from '../../../../context';
import { renderBaseTaskDate } from '../../../../utils/utilities';

export const getBaseTaskDefaultColDefs = (
  t: TFunction,
  taskType: TaskTypes,
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): ColDef[] => {
  return [
    {
      field: 'tableSettingsButton',
      cellRenderer: 'overviewButton',
      width: 50,
      headerComponent: 'tableSettingsButton',
      maxWidth: 50,
      minWidth: 50,
      resizable: false,
      sortable: false,
    },
    {
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).createdAt;
      },
      field: 'createdAt',
      headerName: t('taskOverview.createdAt'),
      comparator: noComparingComparator,
      cellRenderer: 'dateRenderer',
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimNumber,
      headerName: t('general.claim.claimNumber'),
      comparator: noComparingComparator,
      field: 'claim.claimNumber',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.policyNumber,
      headerName: t('general.claim.policyNumber'),
      comparator: noComparingComparator,
      field: 'claim.policyNumber',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        t(
          `insuranceType.${
            (params.data as BaseTaskListDto).claim.insuranceType
          }`,
        ),
      headerName: t('claimDetail.insuranceType'),
      comparator: noComparingComparator,
      field: 'claim.insuranceType',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimAddress?.addressString ??
        '',
      headerName: t('general.address'),
      comparator: noComparingComparator,
      field:
        'claim.claimAddress.street,claim.claimAddress.houseNumber,claim.claimAddress.city',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimAddress
          ?.addressStreetString ?? '',
      headerName: t('general.streetAndNumber'),
      comparator: noComparingComparator,
      field: 'claim.claimAddress.street,claim.claimAddress.houseNumber',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimAddress?.zipCode ?? '',
      headerName: t('general.zipCode'),
      comparator: noComparingComparator,
      field: 'claim.claimAddress.zipCode',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        (params.data as BaseTaskListDto).claim.claimAddress?.city ?? '',
      headerName: t('general.city'),
      comparator: noComparingComparator,
      field: 'claim.claimAddress.city',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        const policyHolder = (params.data as BaseTaskListDto).claim
          .policyHolder;

        return policyHolder.type === 'person'
          ? policyHolder.person?.displayName
          : policyHolder.company?.displayName;
      },
      headerName: t('taskOverview.policyHolder'),
      comparator: noComparingComparator,
      field: 'claim.policyHolder.displayName',
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        return getNameFromPartner(
          (params.data as BaseTaskListDto).contact?.partner,
        );
      },
      headerName: t('general.contact.label'),
      comparator: noComparingComparator,
      sortable: true,
      field: 'contact.partner.contactName',
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) =>
        t(`branch.${(params.data as BaseTaskListDto).claim.branch}`),
      field: 'claim.branch',
      headerName: t('general.branch'),
      comparator: noComparingComparator,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).claim.claimDate;
      },
      field: 'claim.claimDate',
      headerName: t('taskOverview.claimDate'),
      comparator: noComparingComparator,
      cellRenderer: 'dateRenderer',
    },
    {
      cellRenderer: 'createdByRenderer',
      headerName: t('taskOverview.createdBy'),
      comparator: noComparingComparator,
      field: 'creator.displayName',
    },
    {
      cellRenderer: 'avatarRenderer',
      headerName: getAssigneePerTypeTranslated(taskType),
      comparator: noComparingComparator,
      field: 'assignee.displayName',
    },
    {
      field: 'assignee.phoneNumber',
      headerName: t('baseTaskListDtoColums.assigneePhoneNumber'),
      comparator: noComparingComparator,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'assignee.insuranceCenter.name',
      headerName: t('baseTaskListDtoColums.assigneeInsuranceCenter'),
      comparator: noComparingComparator,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'assignee.personalNumber',
      headerName: t('baseTaskListDtoColums.assigneePersonalNumber'),
      comparator: noComparingComparator,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'assignedAt',
      headerName: t('baseTaskListDtoColums.assignedAt'),
      comparator: noComparingComparator,
      hide: true,
      valueGetter: (params) => {
        const date = (params.data as BaseTaskListDto).assignedAt;
        return date;
      },
      valueFormatter: (params) => {
        const date = params.value;
        if (date != null) {
          return formatDateTime(date);
        } else {
          return t('taskOverview.unassigned');
        }
      },
      cellRenderer: 'dateRenderer',
    },
    {
      field: 'contact.role',
      headerName: t('baseTaskListDtoColums.contactRole'),
      comparator: noComparingComparator,
      hide: true,
      sortable: true, // The order is not translated in the db so is incoherent local sort and db sort.
      valueGetter: (params) => {
        return t(
          `partnerRole.${(params.data as BaseTaskListDto).contact?.role}`,
        );
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'contact.partner.partner_type',
      headerName: t('baseTaskListDtoColums.contactType'),
      comparator: noComparingComparator,
      hide: true,
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).contact?.partner.type ===
          'company'
          ? t('general.company')
          : t('general.person.label');
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      headerName: t('baseTaskListDtoColums.contactPhone'),
      comparator: noComparingComparator,
      hide: true,
      field: 'contact.partner.phoneNumber',
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).contact?.partner.company
          ? (params.data as BaseTaskListDto).contact?.partner.company
              ?.phoneNumber
          : (params.data as BaseTaskListDto).contact?.partner.person
              ?.phoneNumber;
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      headerName: t('baseTaskListDtoColums.contactEmail'),
      comparator: noComparingComparator,
      hide: true,
      field: 'contact.partner.email',
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).contact?.partner.company
          ? (params.data as BaseTaskListDto).contact?.partner.company?.email
          : (params.data as BaseTaskListDto).contact?.partner.person?.email;
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      headerName: t('baseTaskListDtoColums.contactPreferredContactMehod'),
      comparator: noComparingComparator,
      hide: true,
      field: 'contact.partner.preferedContact',
      valueGetter: (params) => {
        return (params.data as BaseTaskListDto).contact?.partner.company
          ? t(
              `baseTaskListDtoColums.contactMehod${
                (params.data as BaseTaskListDto).contact?.partner.company
                  ?.preferedContact
              }`,
            )
          : t(
              `baseTaskListDtoColums.contactMehod${
                (params.data as BaseTaskListDto).contact?.partner.person
                  ?.preferedContact
              }`,
            );
      },
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'status',
      headerName: t('general.claim.status'),
      comparator: noComparingComparator,
      cellRenderer: 'tagRenderer',
    },
    {
      field: 'closedAt',
      headerName: t('baseTaskListDtoColums.closedAt'),
      comparator: noComparingComparator,
      valueGetter: (params) => {
        const date = (params.data as BaseTaskListDto).closedAt;
        return date;
      },
      valueFormatter: (params) => {
        const date = params.value;
        if (date != null) {
          return formatDateTime(date);
        } else {
          return '';
        }
      },
      hide: true,
      cellRenderer: 'dateRenderer',
    },
    {
      field: 'claim.createdAt',
      headerName: t('general.claim.dateCreatedAt'),
      comparator: noComparingComparator,
      valueGetter: (params) => {
        const date = (params.data as BaseTaskListDto).claim.createdAt;
        return date;
      },
      valueFormatter: (params) => {
        const date = params.value;
        if (date != null) {
          return formatDateTime(date);
        } else {
          return '';
        }
      },
      cellRenderer: 'dateRenderer',
    },
    {
      field: 'claim.damagedParties',
      headerName: t('general.claim.damagedParties'),
      comparator: noComparingComparator,
      sortable: false,
      hide: true,
      cellRenderer: earlybyteTableTextRenderer,
    },
    {
      field: 'priority',
      hide: true,
      headerName: t('baseTaskListDtoColums.priority'),
      comparator: noComparingComparator,
      sortable: true,
      valueGetter: (params) => {
        const priority = (params.data as BaseTaskListDto).priority;
        return priority ? t(`baseTaskPriorities.${priority}`) : '';
      },
      cellRenderer: (params: ILoadingCellRendererParams) =>
        RenderPriority(params, taskType, setTask),
    },
  ];
};

const getDefaultColDefsClone = (
  t: TFunction,
  taskType: TaskTypes,
): ColDef[] => {
  return JSON.parse(JSON.stringify(getBaseTaskDefaultColDefs(t, taskType)));
};

const getRequiredCapabilitiesFromUserTableType = (
  userTableType: UserTableTypes,
): AppCapabilityNames[] => {
  switch (userTableType) {
    case 'PropertyInspectionTask':
      return ['CreatePropertyInspectionTask', 'EditPropertyInspectionTask'];
    case 'AccidentInspectionTask':
      return ['CreateAccidentInspectionTask', 'EditAccidentInspectionTask'];
    case 'InvestigationTask':
      return ['CreateFraudInspectionTask', 'EditFraudInspectionTask'];
    default:
      return [];
  }
};

export const getAllBaseGridOptions = (
  t: TFunction,
  onSelectedColumns: (userTableSettingDto: UserTableSettingDto) => void,
  userTableType: UserTableTypes,
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): GridOptions => {
  const taskType = getTaskTypeFromEntityName(userTableType);

  return {
    defaultColDef: {
      sortable: true,
      filter: false,
      width: 165,
      minWidth: 64,
      resizable: true,
    },
    //ag grid inserts default message if is either undefined or empty string, and gives error if is just a space
    // '&nbsp;' also just equals empty space, but works in this case
    overlayNoRowsTemplate: '&nbsp;',
    columnDefs: getBaseTaskDefaultColDefs(t, taskType, setTask),
    components: {
      createdByRenderer: function renderCreatedByAvatar(
        params: ILoadingCellRendererParams,
      ) {
        if (params.data) {
          const name = getDisplayNameFromUser(
            (params.data as BaseTaskListDto).creator,
          );
          return (
            <span className="bal-table-cell-text">
              {params.data.creator && (
                <Avatar
                  user={params.data.creator}
                  size="32"
                  className="is-flex-shrink-0"
                />
              )}
              <BalText space="none">
                <p className="ml-2">{name}</p>
              </BalText>
            </span>
          );
        } else {
          return '';
        }
      },
      avatarRenderer: renderBaseTaskAssigneeAvatar,
      tagRenderer: renderBaseTaskStatusTag,
      dateRenderer: renderBaseTaskDate,
      overviewButton: function renderOverviewButton(
        params: ILoadingCellRendererParams,
      ) {
        if (
          params.data.status !== 'New' &&
          params.data.kind !== 'BonusCorrection'
        ) {
          return (
            <WithCapabilities
              className="is-flex is-align-items-center"
              requiredCapabilities={getRequiredCapabilitiesFromUserTableType(
                userTableType,
              )}
              passWithCapabilitiesPropsToChildren
            >
              <DisabableLink
                to={
                  userTableType === 'PropertyInspectionTask'
                    ? EBRoutes.PROPERTY_INSPECTION_TASK_DETAIL.create({
                        taskId: params.data.id,
                      })
                    : userTableType === 'AccidentInspectionTask'
                    ? EBRoutes.ACCIDENT_INSPECTION_TASK_DETAIL.create({
                        taskId: params.data.id,
                      })
                    : EBRoutes.INVESTIGATION_TASK_DETAIL.create({
                        taskId: params.data.id,
                      })
                }
              >
                <BalIcon name="nav-go-right" size="small" />
              </DisabableLink>
            </WithCapabilities>
          );
        } else return <></>;
      },
      tableSettingsButton: function useRenderTableSettings(
        params: ILoadingCellRendererParams,
      ) {
        const [modalOpened, setModalOpened] = useState(false);
        const { user } = useContext(UserContext);
        const openSettingsModal = async () => {
          const modal = await balModalController.create({
            component: TableSettingsForm,
            componentProps: {
              gridApi: params.api,
              user: user,
              userTableType: userTableType,
              onSelectedColumns: onSelectedColumns,
              defaultColDefs: getDefaultColDefsClone(t, taskType),
            },
            cssClass: 'center-modal',
            backdropDismiss: false,
            isClosable: false,
          });
          modal.onDidDismiss().then(() => {
            setModalOpened(false);
          });
          return modal.present();
        };
        return (
          <div
            onClick={() => {
              if (!modalOpened) {
                setModalOpened(true);
                openSettingsModal();
              }
            }}
          >
            <BalIcon svg={balIconSettings} size="small" />
          </div>
        );
      },
    },
  };
};

export const getGridOptionsWithUserTableSettings = (
  user: UserDto,
  allGridOptions: GridOptions,
  userTableType: UserTableTypes,
): GridOptions => {
  const tableSetting = user.userTableSettings?.find(
    (x) => x.type === userTableType,
  );
  if (tableSetting) {
    // Hide/show columns.
    const columnsToHide: string[] = JSON.parse(tableSetting.columnsToHide);
    columnsToHide.forEach((colHeaderName: string) => {
      const colDef: ColDef | undefined = allGridOptions.columnDefs?.find(
        (x) => x.headerName === colHeaderName,
      );
      if (colDef) {
        colDef.hide = true;
      }
    });

    const columnsToShow: string[] = JSON.parse(tableSetting.columnsToShow);
    columnsToShow.forEach((colHeaderName: string) => {
      const colDef: ColDef | undefined = allGridOptions.columnDefs?.find(
        (x) => x.headerName === colHeaderName,
      );
      if (colDef) {
        colDef.hide = false;
      }
    });

    // Apply column widths.
    const columnWidths = tableSetting.columnWidths;
    if (columnWidths.length > 0) {
      allGridOptions.columnDefs?.forEach((colDef: ColDef) => {
        colDef.flex = undefined;
        const columnWidth: ColumnWidth | undefined = columnWidths.find(
          (cw) => colDef.field === cw.name,
        );
        if (columnWidth) {
          if (colDef.maxWidth && colDef.maxWidth < columnWidth.width) {
            colDef.maxWidth = columnWidth.width;
          }
          colDef.width = columnWidth.width;
        }
      });
    }
  }

  // Apply columns order.
  if (tableSetting?.columnsOrder && allGridOptions.columnDefs) {
    const columnsOrder = tableSetting.columnsOrder.split('|');
    let originColDefs: ColDef[] = allGridOptions.columnDefs;
    allGridOptions.columnDefs = [];
    columnsOrder.forEach((field) => {
      const colDef = originColDefs.find((c) => c.field === field);
      if (colDef && allGridOptions.columnDefs) {
        allGridOptions.columnDefs.push(colDef);
        originColDefs = originColDefs.filter((ocd) => ocd.field !== field);
      }
    });
    originColDefs.forEach((ocd) => {
      if (allGridOptions.columnDefs) {
        allGridOptions.columnDefs.push(ocd);
      }
    });
  }

  return allGridOptions;
};

export const buildGridOptions = (
  user: RequestResult<UserDto>,
  setUser: React.Dispatch<RequestResult<UserDto>>,
  t: TFunction,
  userTableType: UserTableTypes,
  getAllBaseGridOptions: (
    t: TFunction,
    onSelectedColumns: (userTableSettingDto: UserTableSettingDto) => void,
    userTableType: UserTableTypes,
    setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
  ) => GridOptions,
  setTask?: Dispatch<SetStateAction<RequestResult<BaseTaskListDto>>>,
): GridOptions => {
  if (user.status === 'success') {
    const onSelectedColumns = (userTableSettingDto: UserTableSettingDto) => {
      if (user.status === 'success') {
        setUser(
          produce(user, (draftState) => {
            draftState.value.userTableSettings =
              draftState.value.userTableSettings.filter(
                (x) => x.type !== userTableSettingDto.type,
              );
            draftState.value.userTableSettings.push(userTableSettingDto);
            draftState.localValue.userTableSettings =
              draftState.value.userTableSettings;
          }),
        );
      }
    };

    const allGridOptions = getAllBaseGridOptions(
      t,
      onSelectedColumns,
      userTableType,
      setTask,
    );

    return getGridOptionsWithUserTableSettings(
      user.value,
      allGridOptions,
      userTableType,
    );
  } else return {};
};
