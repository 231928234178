import {
  BalButtonGroup,
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalText,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BalDatepickerFormField,
  BalTextFormField,
} from '../../../components/input';
import BalSelectFormField from '../../../components/input/bal-select-form-field';
import { balModalController } from '../../../controller/controllers';
import { FetchSuccess } from '../../../data';
import { useToken } from '../../../hooks';
import {
  CompanyDto,
  VehicleTaskKind,
  BaseTaskManualPostDto,
} from '../../../types/resource-models';
import { BaseTask, TaskTypes, TranslatedSelection } from '../../../types/types';
import { toFormattedClaimNumber } from '../../../utils';
import { postTaskManual } from '../data/requests';

export interface BaseTakManualCreationModalFormProps {
  claimNumber: string;
  policyNumber: string;
  taskType: TaskTypes;
  garage?: CompanyDto;
  kind?: VehicleTaskKind;
  onCreateTaskSuccess: (response: FetchSuccess<BaseTask>) => void;
  onCancel: () => void;
}

export const BaseTaskManualCreationModalForm = ({
  claimNumber,
  policyNumber,
  taskType,
  garage,
  kind,
  onCreateTaskSuccess,
  onCancel,
}: BaseTakManualCreationModalFormProps): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [baseTaskManualPostDto, setBaseTaskManualPostDto] =
    useState<BaseTaskManualPostDto>({
      claimNumber,
      policyNumber,
      garage,
      kind,
      claimDate: new Date().toISOString(),
      isPerson: false,
    });

  const token = useToken();

  const policyHolderTypes: TranslatedSelection[] = [
    {
      value: 'Company',
      translatedValue: t('baseTaskManualCreation.policyHolderTypes.Company'),
    },
    {
      value: 'Person',
      translatedValue: t('baseTaskManualCreation.policyHolderTypes.Person'),
    },
  ];

  return (
    <>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {t('baseTaskManualCreation.title')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <form
          onSubmit={methods.handleSubmit(async () => {
            if (!saving) {
              setSaving(true);
              const result = await postTaskManual(
                baseTaskManualPostDto,
                taskType,
                token,
              );
              setSaving(false);
              if (result.status === 'success') {
                onCreateTaskSuccess(result);
                balModalController.dismiss();
              }
            }
          })}
        >
          <div className="rows is-multiline is-gapless is-full-width mt-1">
            <BalText>{t('baseTaskManualCreation.subtitle')}</BalText>
            <BalTextFormField
              disabled
              className="row"
              controllerName={'claimNumber'}
              control={methods.control}
              title={t('general.claim.claimNumber')}
              value={toFormattedClaimNumber(baseTaskManualPostDto.claimNumber)}
            />
            <BalSelectFormField
              required={t('error.mandatoryField')}
              controllerName={'policyHolderType'}
              methods={methods}
              className="row"
              value={baseTaskManualPostDto.isPerson ? 'Person' : 'Company'}
              values={policyHolderTypes}
              title={t('baseTaskManualCreation.policyHolderType')}
              placeholder={t('general.buttons.select')}
              alphabeticallySorted
              onChange={(e) => {
                setBaseTaskManualPostDto(
                  produce(baseTaskManualPostDto, (draftState) => {
                    draftState.isPerson = e?.detail === 'Person';
                  }),
                );
              }}
            />
            <BalDatepickerFormField
              required={t('error.mandatoryField')}
              title={t('general.claim.dateCreatedAt')}
              className="row mb-4"
              controllerName={'claimdate'}
              methods={methods}
              placeholder={t('general.claim.dateCreatedAt')}
              value={baseTaskManualPostDto.claimDate.slice(0, 10)}
              onBalChange={(e: CustomEvent<null | string | undefined>) => {
                setBaseTaskManualPostDto(
                  produce(baseTaskManualPostDto, (draftState) => {
                    draftState.claimDate = e.detail ?? '';
                  }),
                );
              }}
            />

            <BalTextFormField
              className="row"
              controllerName={'policyNumber'}
              control={methods.control}
              required={t('validators.invalidPolicyNumber')}
              title={t('general.claim.policyNumber')}
              placeholder={t('general.claim.policyNumber')}
              pattern={/^[0-9]{9}$|[0-9]{10}$/}
              maxLength={10}
              value={baseTaskManualPostDto.policyNumber}
              onChange={(e: CustomEvent<null | string | undefined>) => {
                setBaseTaskManualPostDto(
                  produce(baseTaskManualPostDto, (draftState) => {
                    draftState.policyNumber = e.detail ?? '';
                  }),
                );
              }}
            />
            <BalText>{t('baseTaskManualCreation.disclaimer')}</BalText>
          </div>
          <div>
            <BalButtonGroup position="right">
              <BalButton
                elementType="button"
                color="primary-light"
                onClick={() => {
                  onCancel();
                  balModalController.dismiss();
                }}
              >
                {t('general.buttons.cancel')}
              </BalButton>
              <BalButton
                elementType="submit"
                color="info"
                disabled={!methods.formState.isValid || saving}
              >
                {t('baseTaskManualCreation.createManualButton')}
              </BalButton>
            </BalButtonGroup>
          </div>
        </form>
      </BalModalBody>
    </>
  );
};
