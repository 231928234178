import produce from 'immer';
import {
  BalHeading,
  BalTabs,
  BalTabItem,
  BalText,
  BalNotification,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import '../../../styles/general.scss';
import '../../../styles/detail-view.scss';
import { useParams } from 'react-router-dom';
import { convertUtcToLocal, formatDateTime } from '../../../utils/date';
import {
  concat,
  concatAddress,
  getFullHeightWarningCssClass,
  Globals,
} from '../../../utils';
import { RouteKey, EBRoutes } from '../../../router/router';
import { useEffect, useRef, useState } from 'react';
import { ContactDetailInformation } from '../../contacts/components/contact-detail-information';
import {
  AccidentInspectionTaskCategory,
  AddressDto,
  AttachmentDto,
  BaseTaskChangeDto,
  Branch,
  InsuranceType,
  Language,
  UserDto,
} from '../../../types/resource-models';
import { deleteFile } from '../../files/data/requests';
import { toast } from 'react-toastify';
import { useToken } from '../../../hooks';
import {
  SelectUserModalButton,
  TaskDetailInfoDisplay,
  BaseTaskAttachments,
  CombinedState,
} from '../../base-task';
import { CopyButton, ResultRenderer } from '../../../components/ui';
import { RequestResult } from '../../../data';
import {
  AccidentInspectionTask,
  TaskTypes,
  TranslatedSelection,
} from '../../../types/types';
import { balModalController } from '../../../controller/controllers';
import { useAccidentInspectionTaskFromApi } from '../data/hooks';
import {
  getAccidentInspectionTaskAllHistory,
  patchAccidentInspectionTask,
} from '../data/requests';
import { Breadcrumbs } from '../../../components/ui/breadcrumbs';
import { InfoDisplay } from '../../../components/ui/info-display';
import { EditContacts } from '../../contacts/components/edit-contacts-modal';
import { AccidentTaskWorkflowButtons as AccidentInspectionTaskWorkflowButtons } from './accident-inspection-task-workflow-buttons/accident-inspection-task-workflow-buttons';
import { FileUpload } from '../../files/components/file-upload';
import { ErrorToast } from '../../../components/toast-notification';
import { WithCapabilitiesErrorProps } from '../../../components/authorization';
import { EditSelectionModal } from '../../../components/input/edit-selection-modal';
import { EditCheckboxModal } from '../../../components/input/edit-checkbox-modal';
import { EntityProtocol } from '../../protocol';
import {
  cleanPolicyNumber,
  getDefaultAddressWithClaimId,
  getDisplayNameFromUser,
} from '../../../utils/utilities';
import TaskJumpAdos from '../../base-task/components/task-detail-jump-ados';
import { BaseTaskMemoFieldsButton } from '../../base-task/components';
import {
  AddressEditButton,
  EditDateModal,
  EditTextModal,
} from '../../../components/input';

type PropertyTaskDetailId = {
  taskId: string;
};

const AccidentInspectionTaskDetail = ({
  withCapabilitiesError,
  setCombinedState,
  combinedState,
}: {
  withCapabilitiesError?: WithCapabilitiesErrorProps;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  combinedState: CombinedState;
}): JSX.Element => {
  return (
    <>
      {!withCapabilitiesError && (
        <AccidentInspectionTaskDetailContent
          setCombinedState={setCombinedState}
          combinedState={combinedState}
        />
      )}
    </>
  );
};

export default AccidentInspectionTaskDetail;

const AccidentInspectionTaskDetailContent = ({
  combinedState,
  setCombinedState,
}: {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
}): JSX.Element => {
  const reloadTable = () => {
    if (combinedState.tasks.status === 'success') {
      setCombinedState(
        produce(combinedState, (draftState) => {
          draftState.tasks = { status: 'loading' };
        }),
      );
    }
  };
  const token = useToken();
  const { t } = useTranslation();
  const { taskId } = useParams<PropertyTaskDetailId>();
  const [accidentInspectionTask, setAccidentInspectionTask] =
    useAccidentInspectionTaskFromApi(taskId ?? '');
  const [accidentInspectionTaskHistory, setAccidentInspectionTaskHistory] =
    useState<RequestResult<BaseTaskChangeDto[]> | undefined>(undefined);
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabDefault = useRef<HTMLBalTabItemElement>(null);
  const refTabAttachments = useRef<HTMLBalTabItemElement>(null);
  const [currentDefaultTab, setCurrentDefaultTab] = useState<
    string | undefined
  >(refTabDefault.current?.value);

  useEffect(() => {
    if (accidentInspectionTask.status === 'success') {
      if (currentDefaultTab && currentDefaultTab === 'protocol') {
        if (taskId) {
          setAccidentInspectionTaskHistory({
            status: 'loading',
          });
          getAccidentInspectionTaskAllHistory(token, taskId).then((result) => {
            if (result.status === 'success') {
              setAccidentInspectionTaskHistory(result);
            }
          });
        }
      }
    }
  }, [accidentInspectionTask, currentDefaultTab, token, taskId]);

  const removeFile = async (fileName: string) => {
    if (
      accidentInspectionTask.status == 'success' &&
      accidentInspectionTask.localValue.attachments
    ) {
      const attachmentToDelete =
        accidentInspectionTask.localValue.attachments.find(
          (attachment: AttachmentDto) => attachment.fileName == fileName,
        );
      if (attachmentToDelete) {
        const result = await deleteFile(token, attachmentToDelete);

        if (
          result.status === 'success-no-value' ||
          result.status === 'success'
        ) {
          const newAttachments =
            accidentInspectionTask.localValue.attachments.filter(
              (attachment: AttachmentDto) => attachment.fileName !== fileName,
            );
          setAccidentInspectionTask(
            produce(accidentInspectionTask, (draftState) => {
              draftState.localValue.attachments = newAttachments;
            }),
          );
        }

        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      }
    }
  };

  if (accidentInspectionTask.status === 'success') {
    const assignee = accidentInspectionTask.value.assignee;
    const creator = accidentInspectionTask.value.creator;
    const claim = accidentInspectionTask.value.claim;
    const contact = accidentInspectionTask.value.claim.affectedPartners.find(
      (affectedPartner) =>
        affectedPartner.id === accidentInspectionTask.value.contactId,
    );
    const furtherContacts =
      accidentInspectionTask.localValue.claim.affectedPartners.filter(
        (affectedPartner) =>
          affectedPartner.id != accidentInspectionTask.value.contactId,
      );
    const assigneeName = getDisplayNameFromUser(assignee);
    const creatorName = getDisplayNameFromUser(creator);
    const lastLocationKey: RouteKey = 'ACCIDENT_INSPECTION_TASK';
    const policyHolder =
      claim.policyHolder.type === 'person'
        ? claim.policyHolder.person
        : claim.policyHolder.company;

    const onEditOriginExpert = (user: UserDto) => {
      patchAccidentInspectionTask(
        accidentInspectionTask.value,
        produce(accidentInspectionTask.value, (draftState) => {
          draftState.claim.originExpertId = user.id;
        }),
        token,
      ).then((task: RequestResult<AccidentInspectionTask>) => {
        if (task.status === 'success') {
          setAccidentInspectionTask(
            produce(accidentInspectionTask, (draftState) => {
              draftState.value.claim.originExpert = user;
              draftState.value.claim.originExpertId = user.id;
              draftState.localValue.claim.originExpert = user;
              draftState.localValue.claim.originExpertId = user.id;
            }),
          );
        }
      });
    };

    const onAssignOriginExpert = () => {
      patchAccidentInspectionTask(
        accidentInspectionTask.value,
        produce(accidentInspectionTask.value, (draftState) => {
          draftState.assignee = claim.originExpert;
          draftState.status = 'Assigned';
        }),
        token,
      ).then((task: RequestResult<AccidentInspectionTask>) => {
        if (task.status === 'success') {
          setAccidentInspectionTask(task);
          reloadTable();
        }
      });
    };

    const categories: TranslatedSelection[] =
      Globals.AccidentInspectionTaskCategories.map((category) => ({
        value: category,
        translatedValue: t(`accidentInspectionTask.categories.${category}`),
      }));

    const languages: TranslatedSelection[] = Globals.languages.map(
      (language) => ({
        value: language,
        translatedValue: t(`language.languages.${language}`),
      }),
    );

    const branchTranslatedSelections: TranslatedSelection[] =
      Globals.allBranches.map((branch) => ({
        value: branch,
        translatedValue: t(`branch.${branch}`),
      }));

    return (
      <div className="is-full-height has-background-grey-1 is-flex is-flex-direction-column">
        <div className="has-background-white mr-6 mt-6 ml-6 mb-4 pt-6 pl-6 overflow-hidden detail-border-shadow detail-content">
          <div className="pr-5">
            {accidentInspectionTask.value.deletedAt && (
              <BalNotification color="danger">
                <BalText>
                  {t('claimDetail.warningMessageSoftDeleted', {
                    dateTime: convertUtcToLocal({
                      date: accidentInspectionTask.value.deletedAt,
                    }),
                  })}
                </BalText>
              </BalNotification>
            )}
            {accidentInspectionTask.value.status === 'New' && (
              <BalNotification className="mt-1" color="info">
                <BalText>{t('claimDetail.warningMessageDraft')}</BalText>
              </BalNotification>
            )}
            {accidentInspectionTask.value.claim.createdManually && (
              <BalNotification className="mt-1" color="warning">
                <BalText>
                  {t('claimDetail.warningMessageCreatedManually')}
                </BalText>
              </BalNotification>
            )}
          </div>
          <div
            className={`mt-5 is-flex is-flex-direction-column is-full-width ${getFullHeightWarningCssClass(
              accidentInspectionTask,
            )} position-relative`}
          >
            <div className="is-full-width detail-header is-flex is-flex-direction-column is-justify-content-center">
              <Breadcrumbs
                breadcrumbs={[
                  {
                    label: t(`EBRoutes.${lastLocationKey}`),
                    path: EBRoutes[lastLocationKey].create(),
                  },
                  {
                    label: claim.claimNumber,
                  },
                ]}
              />
              <BalHeading
                level="h3"
                subtitle
                space="none"
                className="mt-1 mb-1 is-flex"
              >
                {concat([t('claimDetail.claim'), claim.claimNumber])}
                <CopyButton
                  className="mb-3 ml-3"
                  copyValue={claim.claimNumber
                    .replaceAll('/', '')
                    .replace('.', '')}
                ></CopyButton>
                <TaskJumpAdos
                  className="ml-3 is-inline-block"
                  task={accidentInspectionTask}
                />
              </BalHeading>
              <BalText size="small" className="has-text-black mb-4 mt-2">
                {concat([
                  formatDateTime(accidentInspectionTask.value.createdAt),
                  t('general.from'),
                  getDisplayNameFromUser(accidentInspectionTask.value.creator),
                  '(',
                  accidentInspectionTask.value.creator?.insuranceCenter.name,
                  ')',
                ])}
              </BalText>
            </div>
            <div className="columns is-gapless detail-overview">
              <div className="column mr-1 scroll-bar-minimal">
                <div className="columns is-multiline is-gapless">
                  <div className="column is-12 mt-3">
                    {/* Task description section */}
                    <div className="is-flex is-align-items-center mb-2 mt-1">
                      <BalHeading space="none" level="h4" color="info" subtitle>
                        {t('general.taskDescription')}
                      </BalHeading>
                      <div className="pl-6">
                        <BaseTaskMemoFieldsButton
                          requiredCapabilities={['EditAccidentInspectionTask']}
                          task={accidentInspectionTask}
                          setTask={setAccidentInspectionTask}
                          taskType={TaskTypes.AccidentInspectionTask}
                        />
                      </div>
                    </div>
                    <BalText space="none" color="info" bold size="small">
                      {t('accidentInspectionTask.claimInformation.cardTitle')}
                    </BalText>
                    <BalText className="enable-line-break">
                      {accidentInspectionTask.value.what}
                    </BalText>
                    {accidentInspectionTask.value.remarks && (
                      <>
                        <BalText space="none" color="info" bold size="small">
                          {t('general.claim.remarks')}
                        </BalText>
                        <BalText className="enable-line-break">
                          {accidentInspectionTask.value.remarks}
                        </BalText>
                      </>
                    )}
                    {accidentInspectionTask.value.internalNote && (
                      <>
                        <BalText space="none" color="info" bold size="small">
                          {t('baseTaskDtoColumns.internalNote')}
                        </BalText>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              accidentInspectionTask.value.internalNote ?? '',
                          }}
                        ></div>
                      </>
                    )}
                  </div>
                </div>
                <div className="columns is-multiline is-gapless">
                  <div className="column is-12 is-6-fullhd mt-3">
                    <div className="columns is-multiline is-gapless pr-6">
                      <BalHeading space="none" level="h4" color="info" subtitle>
                        {t('general.claim.claimInformation')}
                      </BalHeading>
                      <div className="column is-full">
                        <InfoDisplay
                          title={t('general.branch')}
                          text={t(`branch.${claim.branch}`)}
                          className="pb-1"
                          editComponent={
                            <EditSelectionModal
                              label={t('general.branch')}
                              placeholder={t('general.branch')}
                              value={{
                                value: claim.branch,
                                translatedValue: t(`branch.${claim.branch}`),
                              }}
                              values={branchTranslatedSelections}
                              requiredCapabilities="EditAccidentInspectionTask"
                              onSave={(branch: Branch) => {
                                if (branch) {
                                  patchAccidentInspectionTask(
                                    accidentInspectionTask.value,
                                    produce(
                                      accidentInspectionTask.value,
                                      (draftState) => {
                                        draftState.claim.branch = branch;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setAccidentInspectionTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }
                              }}
                            />
                          }
                        />
                        {claim.majorEventNumber ? (
                          <InfoDisplay
                            className="pb-1"
                            title={t('claimDetail.majorEvent')}
                            text={claim.majorEventNumber?.toString()}
                          />
                        ) : (
                          <></>
                        )}
                        <InfoDisplay
                          title={t('claimDetail.policyNumber')}
                          text={claim.policyNumber}
                          className="pb-1"
                          editComponent={
                            <EditTextModal
                              isRequired={true}
                              label={t('general.claim.policyNumber')}
                              placeholder={t('general.claim.policyNumber')}
                              value={
                                accidentInspectionTask.value.claim.policyNumber
                              }
                              formatBeforeEdit={cleanPolicyNumber}
                              requiredCapabilities="EditAccidentInspectionTask"
                              onSave={(value: string | undefined) => {
                                if (value) {
                                  patchAccidentInspectionTask(
                                    accidentInspectionTask.value,
                                    produce(
                                      accidentInspectionTask.value,
                                      (draftState) => {
                                        draftState.claim.policyNumber = value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setAccidentInspectionTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }
                              }}
                            />
                          }
                        />
                        <InfoDisplay
                          title={t('claimDetail.date')}
                          text={formatDateTime(claim.claimDate)}
                          className="pb-1"
                          editComponent={
                            <EditDateModal
                              isRequired={true}
                              label={t('claimDetail.date')}
                              placeholder={t('claimDetail.date')}
                              value={accidentInspectionTask.value.claim.claimDate?.substring(
                                0,
                                10,
                              )}
                              requiredCapabilities="EditAccidentInspectionTask"
                              onSave={(
                                value: string,
                                updateToAdos: boolean,
                              ) => {
                                patchAccidentInspectionTask(
                                  accidentInspectionTask.value,
                                  produce(
                                    accidentInspectionTask.value,
                                    (draftState) => {
                                      draftState.claim.claimDate = value ?? ''; // internally we always use empty strings instead of null ones.
                                    },
                                  ),
                                  token,
                                  updateToAdos,
                                ).then((result) => {
                                  if (result.status === 'success') {
                                    setAccidentInspectionTask(result);
                                    balModalController.dismiss();
                                  } else if (result.status === 'error') {
                                    toast(ErrorToast(result.errorValue));
                                  }
                                });
                              }}
                            />
                          }
                        />
                        <InfoDisplay
                          title={t('claimDetail.place')}
                          text={concatAddress(claim.claimAddress)}
                          className="pb-1"
                          editComponent={
                            <AddressEditButton
                              requiredCapabilities={[
                                'EditAccidentInspectionTask',
                              ]}
                              initialValue={
                                accidentInspectionTask.value.claim
                                  .claimAddress ??
                                getDefaultAddressWithClaimId(
                                  accidentInspectionTask.value.claim.id,
                                )
                              }
                              onSave={async (address: AddressDto) => {
                                if (address) {
                                  const result =
                                    await patchAccidentInspectionTask(
                                      accidentInspectionTask.value,
                                      produce(
                                        accidentInspectionTask.value,
                                        (draftState) => {
                                          draftState.claim.claimAddress = {
                                            claimId: draftState.claim.id,
                                            ...address,
                                          };
                                        },
                                      ),
                                      token,
                                    );
                                  if (result.status === 'success') {
                                    setAccidentInspectionTask(result);
                                  } else if (result.status === 'error') {
                                    toast(ErrorToast(result.errorValue));
                                  }
                                }
                              }}
                            />
                          }
                        />
                        <InfoDisplay
                          title={t('claimDetail.insuranceType')}
                          text={t(`insuranceType.${claim.insuranceType}`)}
                          className="pb-1"
                          editComponent={
                            <EditSelectionModal
                              label={t('claimDetail.insuranceType')}
                              placeholder={t('claimDetail.insuranceType')}
                              value={{
                                value: claim.insuranceType,
                                translatedValue: t(
                                  `insuranceType.${claim.insuranceType}`,
                                ),
                              }}
                              values={Globals.insuranceTypeTranslatedSelections()}
                              requiredCapabilities="EditAccidentInspectionTask"
                              onSave={(value: InsuranceType) => {
                                if (value) {
                                  patchAccidentInspectionTask(
                                    accidentInspectionTask.value,
                                    produce(
                                      accidentInspectionTask.value,
                                      (draftState) => {
                                        draftState.claim.insuranceType = value;
                                      },
                                    ),
                                    token,
                                  ).then((result) => {
                                    if (result.status === 'success') {
                                      setAccidentInspectionTask(result);
                                      balModalController.dismiss();
                                    } else if (result.status === 'error') {
                                      toast(ErrorToast(result.errorValue));
                                    }
                                  });
                                }
                              }}
                            />
                          }
                        />
                        <InfoDisplay
                          className="pb-1"
                          title={t('accidentInspectionTask.lawyerConsulted')}
                          text={
                            accidentInspectionTask.value.lawyerConsulted
                              ? t('general.true')
                              : t('general.false')
                          }
                          editComponent={
                            <EditCheckboxModal
                              label={t(
                                'accidentInspectionTask.lawyerConsulted',
                              )}
                              text={t('accidentInspectionTask.lawyerConsulted')}
                              value={
                                accidentInspectionTask.value.lawyerConsulted
                              }
                              requiredCapabilities="EditAccidentInspectionTask"
                              onSave={(value: boolean) => {
                                patchAccidentInspectionTask(
                                  accidentInspectionTask.value,
                                  produce(
                                    accidentInspectionTask.value,
                                    (draftState) => {
                                      draftState.lawyerConsulted = value;
                                    },
                                  ),
                                  token,
                                ).then((result) => {
                                  if (result.status === 'success') {
                                    setAccidentInspectionTask(result);
                                    balModalController.dismiss();
                                  } else if (result.status === 'error') {
                                    toast(ErrorToast(result.errorValue));
                                  }
                                });
                              }}
                            />
                          }
                        />
                        <InfoDisplay
                          title={t('accidentInspectionTask.category')}
                          text={t(
                            `accidentInspectionTask.categories.${accidentInspectionTask.value.category}`,
                          )}
                          editComponent={
                            <EditSelectionModal
                              label={t('accidentInspectionTask.category')}
                              value={{
                                value: accidentInspectionTask.value.category,
                                translatedValue: t(
                                  `accidentInspectionTask.categories.${accidentInspectionTask.value.category}`,
                                ),
                              }}
                              values={categories}
                              placeholder={t('accidentInspectionTask.category')}
                              requiredCapabilities="EditAccidentInspectionTask"
                              onSave={(
                                value: AccidentInspectionTaskCategory,
                              ) => {
                                patchAccidentInspectionTask(
                                  accidentInspectionTask.value,
                                  produce(
                                    accidentInspectionTask.value,
                                    (draftState) => {
                                      draftState.category = value;
                                    },
                                  ),
                                  token,
                                ).then((result) => {
                                  if (result.status === 'success') {
                                    setAccidentInspectionTask(result);
                                    balModalController.dismiss();
                                  } else if (result.status === 'error') {
                                    toast(ErrorToast(result.errorValue));
                                  }
                                });
                              }}
                            />
                          }
                        />
                        <SelectUserModalButton
                          user={claim.originExpert}
                          title={t('claimDetail.originExpert')}
                          taskType={TaskTypes.AccidentInspectionTask}
                          onEditSuccess={onEditOriginExpert}
                          onAssignOriginExpert={onAssignOriginExpert}
                          taskStatus={accidentInspectionTask.value.status}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-12 is-6-fullhd mt-3">
                    <div className="is-flex is-align-items-center mb-2 mt-4">
                      <BalHeading space="none" level="h4" color="info" subtitle>
                        {t('claimDetail.contactInformation')}
                      </BalHeading>
                      <EditContacts
                        taskType={TaskTypes.AccidentInspectionTask}
                        task={accidentInspectionTask}
                        setTask={setAccidentInspectionTask}
                      />
                    </div>
                    {contact && contact.partner && (
                      <>
                        <div className="columns is-multiline is-gapless pr-6">
                          <div className="column is-full">
                            <ContactDetailInformation
                              contactPartner={contact}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {furtherContacts.length > 0 && (
                      <>
                        <BalHeading
                          space="none"
                          level="h4"
                          color="info"
                          subtitle
                          className="mb-2"
                        >
                          {t('claimDetail.furtherContacts')}
                        </BalHeading>
                        {furtherContacts.map((contact) => {
                          return (
                            <ContactDetailInformation
                              contactPartner={contact}
                              className="pr-6"
                              key={contact.id}
                            ></ContactDetailInformation>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-menu-actions is-pulled-right">
              <AccidentInspectionTaskWorkflowButtons
                task={accidentInspectionTask.value}
                className="detail-header is-flex is-align-items-center mx-4"
                onChangeSuccess={(task) => {
                  setAccidentInspectionTask(task);
                  reloadTable();
                }}
              />
              <div className="detail-tabs">
                <BalTabs
                  ref={refTabs}
                  className="has-background-grey-1 p-4 detail-information-content"
                  value={currentDefaultTab ? currentDefaultTab : 'details'}
                  onBalChange={() => {
                    if (refTabs.current?.value) {
                      setCurrentDefaultTab(refTabs.current?.value);
                    }
                  }}
                >
                  <BalTabItem
                    className="bal-tab-headings"
                    value="details"
                    label={t('general.details')}
                    ref={refTabDefault}
                  >
                    <TaskDetailInfoDisplay
                      title={t('baseTaskListDtoColums.priority')}
                      text={
                        accidentInspectionTask.localValue.priority
                          ? t(
                              `baseTaskPriorities.${accidentInspectionTask.localValue.priority}`,
                            )
                          : ''
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={t('assignee.assigneeStandard')}
                      text={assigneeName}
                      user={assignee}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.taskCreator')}
                      text={creatorName}
                      user={creator}
                    />
                    <TaskDetailInfoDisplay
                      title={t('language.reportLabel')}
                      text={t(
                        `language.languages.${accidentInspectionTask.value.language}`,
                      )}
                      editComponent={
                        <EditSelectionModal
                          label={t('language.reportLabel')}
                          placeholder={t('language.reportLabel')}
                          value={{
                            value: accidentInspectionTask.value.language,
                            translatedValue: t(
                              `language.languages.${accidentInspectionTask.value.language}`,
                            ),
                          }}
                          values={languages}
                          requiredCapabilities="EditFraudInspectionTask"
                          onSave={(value: string) => {
                            patchAccidentInspectionTask(
                              accidentInspectionTask.value,
                              produce(
                                accidentInspectionTask.value,
                                (draftState) => {
                                  draftState.language = value as Language;
                                },
                              ),
                              token,
                            ).then((result) => {
                              if (result.status === 'success') {
                                setAccidentInspectionTask(result);
                                balModalController.dismiss();
                              } else if (result.status === 'error') {
                                toast(ErrorToast(result.errorValue));
                              }
                            });
                          }}
                        />
                      }
                    />
                    <TaskDetailInfoDisplay
                      title={t('general.claim.status')}
                      taskStatus={accidentInspectionTask.value.status}
                    />
                    <TaskDetailInfoDisplay
                      title={t('general.branch')}
                      text={t(`branch.${claim.branch}`)}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.policyNumber')}
                      text={claim.policyNumber}
                    />
                    <TaskDetailInfoDisplay
                      title={t('claimDetail.policyHolderShort')}
                      text={concat([
                        claim.policyHolder.type === 'person'
                          ? claim.policyHolder.person.displayName
                          : claim.policyHolder.company.displayName,
                        policyHolder.address ? policyHolder.address.street : '',
                        policyHolder.address
                          ? policyHolder.address.houseNumber
                          : '',
                        policyHolder.address
                          ? concat([
                              policyHolder.address.zipCode?.toString(),
                              policyHolder.address.city,
                            ])
                          : '',
                      ])}
                    />
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'attachments'
                        ? 'attachments-tab'
                        : ''
                    }`}
                    value="attachments"
                    label={t('general.claim.attachments')}
                    ref={refTabAttachments}
                  >
                    <div className="tab-item-content content-container is-flex is-flex-direction-column attachments-tab pr-0">
                      <BaseTaskAttachments
                        attachments={
                          accidentInspectionTask.localValue.attachments
                        }
                        removeFile={removeFile}
                        withScrollbar
                      />
                      <FileUpload
                        entityType={TaskTypes.AccidentInspectionTask}
                        className="pb-0"
                        entity={accidentInspectionTask}
                        setEntity={setAccidentInspectionTask}
                        hasFileList={false}
                      />
                    </div>
                  </BalTabItem>
                  <BalTabItem
                    className={`bal-tab-headings ${
                      currentDefaultTab === 'protocol' ? 'protocol-tab' : ''
                    }`}
                    value="protocol"
                    label={t('claimDetail.protocol')}
                  >
                    <div className="tab-item-content scroll-bar-minimal pt-2">
                      {accidentInspectionTaskHistory != null && (
                        <ResultRenderer
                          result={accidentInspectionTaskHistory}
                          defaultValue={[]}
                          loaded={(tasksResponse) => {
                            return <EntityProtocol history={tasksResponse} />;
                          }}
                        />
                      )}
                    </div>
                  </BalTabItem>
                </BalTabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};
