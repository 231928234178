import {
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalPagination,
  BalListItem,
  BalListItemIcon,
  BalIcon,
  BalListItemContent,
  BalListItemTitle,
} from '@baloise/design-system-components-react';
import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { BalTextField } from '../../../components/input';
import { Icon, ResultRenderer } from '../../../components/ui';
import { balModalController } from '../../../controller/controllers';
import searchIcon from '../../../assets/icons/Iconframe.svg';
import {
  InvestigationTaskForNonExpertsDto,
  InvestigationTaskForNonExpertsFilterCriteriaDto,
  ResponseOfInvestigationTaskForNonExpertsDto,
} from '../../../types/resource-models';
import { useEffect, useState } from 'react';
import { useDebounce, useToken } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { RequestResult } from '../../../data';
import { getNonExpertInvestigationTasks } from '../data/requests';
import { formatDateTime } from '../../../utils/date';
import { earlybyteTableTextRenderer } from '../../../utils/utilities';

interface InvestigationTaskForNonExpertCombinedState {
  tasks: RequestResult<ResponseOfInvestigationTaskForNonExpertsDto>;
  filter: InvestigationTaskForNonExpertsFilterCriteriaDto;
}

const NonExpertTasksModal = (): JSX.Element => {
  const token = useToken();
  const { t } = useTranslation();

  const [combinedState, setCombinedState] =
    useState<InvestigationTaskForNonExpertCombinedState>({
      tasks: { status: 'initial' },
      filter: {
        page: 0,
        maxItemCount: 1,
        orderBy: 'desc',
      },
    });

  const [claimNumber, setClaimNumber] = useDebounce<string>(500);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    if (
      token &&
      (combinedState.tasks.status === 'initial' ||
        combinedState.tasks.status === 'loading')
    ) {
      if (combinedState.tasks.status === 'initial') {
        setCombinedState(
          produce(combinedState, (draft) => {
            draft.tasks = {
              status: 'success',
              value: { totalPages: 1, totalItems: 0, entities: [] },
              localValue: { totalPages: 1, totalItems: 0, entities: [] },
            };
          }),
        );
      } else {
        getNonExpertInvestigationTasks(token, combinedState.filter).then(
          (response) => {
            setCombinedState(
              produce(combinedState, (draft) => {
                draft.tasks = response;
              }),
            );
            if (response.status === 'success') {
              setTotalPages(response.value.totalPages);
            }
          },
        );
      }
    }
  }, [token, combinedState]);

  useEffect(() => {
    if (combinedState.tasks.status === 'success') {
      setCombinedState(
        produce(combinedState, (draft) => {
          draft.filter.claimNumber =
            claimNumber?.replaceAll('/', '').replace('.', '') ?? '';
          draft.tasks.status = 'loading';
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimNumber]);

  const getGridOptions = (): GridOptions => {
    return {
      onGridReady(event) {
        const pixelRange = event.api.getVerticalPixelRange();
        let gridHeight = pixelRange.bottom - pixelRange.top;
        // Setting max item count in filter to avoid vertical scroll.
        const rowHeigth = event.api.getSizesForCurrentTheme().rowHeight;
        const headerHeight =
          event.api.getSizesForCurrentTheme().headerHeight ?? 0;
        gridHeight = gridHeight - headerHeight;
        const maxRowsFitting = gridHeight / rowHeigth;
        if (Math.trunc(maxRowsFitting) !== combinedState.filter.maxItemCount) {
          setCombinedState(
            produce(combinedState, (draft) => {
              draft.filter.maxItemCount = Math.trunc(maxRowsFitting);
              draft.tasks.status = 'loading';
            }),
          );
        }
      },
      defaultColDef: {
        sortable: false,
        filter: false,
        autoHeight: false,
      },
      overlayNoRowsTemplate: '&nbsp;',
      rowHeight: 40,
      columnDefs: [
        {
          valueGetter: (params) => {
            return formatDateTime(
              new Date(params.data.createdAt).toISOString(),
            );
          },
          headerName: t('taskOverview.createdAt'),
          width: 125,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          field: 'claimNumber',
          headerName: t('taskOverview.caseNumber'),
          width: 150,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          valueGetter: (params) => {
            return t(
              `baseTaskStatus.${
                (params.data as InvestigationTaskForNonExpertsDto).status
              }`,
            );
          },
          headerName: t('general.claim.status'),
          width: 125,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          field: 'assignee.displayName',
          headerName: t('assignee.assigneeInvestigation'),
          width: 150,
          cellRenderer: earlybyteTableTextRenderer,
        },
        {
          valueGetter: (params) => {
            return params.data.closedAt
              ? formatDateTime(new Date(params.data.closedAt).toISOString())
              : '';
          },
          headerName: t('baseTaskListDtoColums.closedAt'),
          width: 150,
          cellRenderer: earlybyteTableTextRenderer,
        },
      ],
    };
  };

  const setPageFilter = (page: number) => {
    setCombinedState(
      produce(combinedState, (draft) => {
        draft.filter.page = page;
        draft.tasks.status = 'loading';
      }),
    );
  };
  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">
          {t('investigationTask.nonExpertTasks')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <div className="is-flex is-flex-direction-row my-4">
          <Icon iconPath={searchIcon} />
          <BalTextField
            className="is-flex-grow-1 ml-3"
            placeholder={t('taskOverview.caseNumber')}
            value={combinedState.filter.claimNumber}
            onInput={(event) => setClaimNumber(event.detail)}
          />
        </div>
        <div className="task-table-modal">
          <ResultRenderer
            result={combinedState.tasks}
            defaultValue={{ totalPages: 1, totalItems: 0, entities: [] }}
            loaded={(tasksResponse) => {
              return (
                <AgGridReact
                  suppressCellFocus={true}
                  rowSelection={'single'}
                  rowData={tasksResponse.entities}
                  gridOptions={getGridOptions()}
                />
              );
            }}
          />
        </div>
        <BalPagination
          className="mt-2"
          value={totalPages > 0 ? combinedState.filter.page + 1 : 0}
          totalPages={totalPages}
          onBalChange={(e) => {
            setPageFilter(e.detail - 1);
          }}
        />
      </BalModalBody>
    </>
  );
};
export const NonExpertTasks = () => {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: NonExpertTasksModal,
      cssClass: 'center-modal',
      modalWidth: 800,
      backdropDismiss: false,
    });
    return modal.present();
  };
  const { t } = useTranslation();
  return (
    <BalListItem clickable onClick={() => openModal()}>
      <BalListItemIcon className="min-height-0px">
        <BalIcon name="document" size="small" />
      </BalListItemIcon>
      <BalListItemContent>
        <BalListItemTitle>
          {t('investigationTask.nonExpertTasks')}
        </BalListItemTitle>
      </BalListItemContent>
    </BalListItem>
  );
};
