import {
  BalButton,
  BalButtonGroup,
} from '@baloise/design-system-components-react';
import { BalValidators } from '@baloise/web-app-validators';
import produce from 'immer';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import lang from 'suneditor-react/dist/types/lang';
import {
  PropertyInspectionTaskRecipientDto,
  RecipientType,
} from '../../../../../../bat-shared/resource-models';
import { BalTextFormField } from '../../../../components/input';
import BalSelectFormField from '../../../../components/input/bal-select-form-field';
import { ErrorToast } from '../../../../components/toast-notification';
import { CancelButton } from '../../../../components/ui';
import { useToken } from '../../../../hooks';
import { TranslatedSelection } from '../../../../types/types';
import { Globals } from '../../../../utils';
import {
  patchPropertyInspectionTaskRecipient,
  postPropertyInspectionTaskRecipient,
} from '../../data/requests';

export interface PropertyInspectionTaskRecipientEditProps {
  initialValue: PropertyInspectionTaskRecipientDto;
  initialAddingState: boolean;
  language?: lang;
  readonly?: boolean;
  onSave?: (recipientId?: string) => void;
}
const PropertyInspectionTaskRecipientEditForm = ({
  initialValue,
  initialAddingState,
  readonly,
  onSave,
}: PropertyInspectionTaskRecipientEditProps): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { control } = methods;
  const [saving, setSaving] = useState<boolean>(false);
  const [recipient, setRecipient] =
    useState<PropertyInspectionTaskRecipientDto>(initialValue);

  const [addingState, setAddingState] = useState<boolean>(initialAddingState);
  const unorderedRecipientTypes: TranslatedSelection[] =
    Globals.RecipientTypes.map((recipientType) => ({
      value: recipientType,
      translatedValue: t(
        `inspectionTask.recipient.customRecipientTypes.${recipientType}`,
      ),
    }));

  const recipientTypes: TranslatedSelection[] = unorderedRecipientTypes.sort(
    (a, b) => (a.translatedValue > b.translatedValue ? 1 : 0),
  );

  const token = useToken();

  const postRecipient = async () => {
    const response = await postPropertyInspectionTaskRecipient(
      recipient,
      token,
    );
    if (response.status === 'error') {
      toast(ErrorToast(response.errorValue));
    } else {
      if (response.status === 'success') {
        setRecipient(response.localValue);
      }
      setAddingState(false);
    }
  };

  const patchRecipient = async () => {
    const response = await patchPropertyInspectionTaskRecipient(
      initialValue,
      recipient,
      token,
    );
    if (response.status === 'error') {
      toast(ErrorToast(response.errorValue));
    }
  };

  const validateEmail = (value: string) =>
    BalValidators.isEmail()(value) ? true : t('validators.invalidEmail');

  return (
    <form
      onSubmit={methods.handleSubmit(async () => {
        if (!saving) {
          setSaving(true);
          if (addingState) {
            await postRecipient();
          } else {
            await patchRecipient();
          }
          if (onSave) {
            onSave(recipient.id);
          }
          setSaving(false);
        }
      })}
    >
      <div className="rows is-multiline is-gapless is-full-width mt-5">
        <BalTextFormField
          controllerName={'firstName'}
          control={control}
          required={t('error.mandatoryField')}
          title={t('inspectionTask.recipient.name')}
          placeholder={t('inspectionTask.recipient.name')}
          value={recipient.name}
          onChange={(e) => {
            if (e) {
              setRecipient(
                produce(recipient, (draftState) => {
                  draftState.name = e.detail;
                }),
              );
            }
          }}
        ></BalTextFormField>
        <BalTextFormField
          controllerName={'email'}
          control={control}
          validate={validateEmail}
          required={t('error.mandatoryField')}
          title={t('inspectionTask.recipient.email')}
          placeholder={t('inspectionTask.recipient.email')}
          value={recipient.email}
          onChange={(e) => {
            if (e) {
              setRecipient(
                produce(recipient, (draftState) => {
                  draftState.email = e.detail;
                }),
              );
            }
          }}
        ></BalTextFormField>
        <BalSelectFormField
          controllerName={'recipientType'}
          methods={methods}
          className="row"
          value={recipient.recipientType}
          values={recipientTypes}
          title={t('inspectionTask.recipient.type')}
          placeholder={t('general.buttons.select')}
          required={t('error.mandatoryField')}
          alphabeticallySorted
          onChange={(e) => {
            setRecipient(
              produce(recipient, (draftState) => {
                if (e) {
                  draftState.recipientType = e.detail as RecipientType;
                }
              }),
            );
          }}
        />
      </div>
      <div className="pt-6">
        <BalButtonGroup position="right">
          <CancelButton />
          <BalButton
            elementType="submit"
            color="info"
            disabled={readonly || !methods.formState.isValid || saving}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </div>
    </form>
  );
};

export default PropertyInspectionTaskRecipientEditForm;
