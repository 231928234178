import {
  BalCard,
  BalHeading,
  BalCheckbox,
  BalTextarea,
  BalSpinner,
  BalFieldLabel,
  BalField,
  BalRadioGroup,
  BalRadio,
  BalFieldMessage,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../data';
import {
  AccidentInspectionTask,
  InvestigationTask,
  PropertyInspectionTask,
  TaskTypes,
  TranslatedSelection,
  VehicleTask,
} from '../../../types/types';
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import produce from 'immer';
import { Globals } from '../../../utils';
import {
  AccidentInspectionTaskCategory,
  EstimatedSavings,
  VehicleBonusCorrectionReason,
} from '../../../types/resource-models';
import { FileUpload } from '../../../features/files';
import {
  BalDatepickerFormField,
  BalNumberFormField,
  BalTextareaFormField,
  BalTextFormField,
  BalTypeAheadField,
} from '../../../components/input';
import {
  Compensation,
  //VehicleBonusCorrectionReason,
} from '../../../types/resource-models';
import BalSelectFormField from '../../../components/input/bal-select-form-field';
import { DateTime } from 'luxon';
type TaskProps =
  | {
      task: RequestResult<PropertyInspectionTask>;
      setTask: React.Dispatch<RequestResult<PropertyInspectionTask>>;
      type: TaskTypes.PropertyInspectionTask;
    }
  | {
      task: RequestResult<VehicleTask>;
      setTask: React.Dispatch<RequestResult<VehicleTask>>;
      type: TaskTypes.VehicleTask;
    }
  | {
      task: RequestResult<InvestigationTask>;
      setTask: React.Dispatch<RequestResult<InvestigationTask>>;
      type: TaskTypes.InvestigationTask;
    }
  | {
      task: RequestResult<AccidentInspectionTask>;
      setTask: React.Dispatch<RequestResult<AccidentInspectionTask>>;
      type: TaskTypes.AccidentInspectionTask;
    };

export const TaskInformationCards = ({
  taskProps,
  taskButtons,
  patchTask,
  title,
  methodsProp,
}: {
  taskProps: TaskProps;
  taskButtons: JSX.Element;
  patchTask: () => void;
  title: string;
  methodsProp?: UseFormReturn<FieldValues, object>;
}): JSX.Element => {
  const { t } = useTranslation();
  const localMethods = useForm({ mode: 'onChange' });
  const methods = methodsProp ?? localMethods;

  const accidentInspectionTaskCategories: TranslatedSelection[] =
    Globals.AccidentInspectionTaskCategories.map((category) => ({
      value: category,
      translatedValue: t(`accidentInspectionTask.categories.${category}`),
    }));

  return (
    <div>
      {(taskProps.task.status === 'initial' ||
        taskProps.task.status === 'loading') && <BalSpinner />}
      {taskProps.task.status === 'error' && <></>}
      {taskProps.task.status === 'success' && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(patchTask)}>
            <BalCard className="mt-5 p-5">
              <BalHeading space="none" level="h4" className="pb-2">
                {title}
              </BalHeading>
              {taskProps.type === TaskTypes.PropertyInspectionTask ? (
                <div className="columns is-multiline">
                  <div className="column is-full py-0 my-2">
                    <BalTextareaFormField
                      className="with-error"
                      controllerName="propertyWhatHappened"
                      methods={methods}
                      placeholder={t('general.claim.whatHappened')}
                      title={t('general.claim.whatHappened')}
                      rows={4}
                      value={taskProps.task.localValue.what}
                      onBalChange={(
                        e: CustomEvent<string | undefined | null>,
                      ) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          taskProps.setTask(
                            produce(taskProps.task, (draftState) => {
                              if (draftState.status === 'success')
                                draftState.localValue.what = value;
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="column is-full py-0 m-0">
                    <BalFieldLabel className="m-0">
                      {t('general.claim.remarks')}
                    </BalFieldLabel>
                    <BalTextarea
                      className="column is-full p-0 text-area-no-space"
                      id="remarks"
                      title={t('general.claim.remarks')}
                      placeholder={t('general.claim.remarks')}
                      rows={4}
                      value={taskProps.task.localValue.remarks}
                      onBalInput={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          taskProps.setTask(
                            produce(taskProps.task, (draftState) => {
                              if (draftState.status === 'success')
                                draftState.localValue.remarks = value;
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              ) : taskProps.type === TaskTypes.AccidentInspectionTask ? (
                <div className="columns is-multiline">
                  <div className="column is-full">
                    <BalTextareaFormField
                      controllerName="accidentIWhatHappened"
                      methods={methods}
                      title={t(
                        'accidentInspectionTask.claimInformation.whatToDo',
                      )}
                      placeholder={t(
                        'accidentInspectionTask.claimInformation.whatToDo',
                      )}
                      rows={4}
                      value={taskProps.task.localValue.what}
                      onBalChange={(
                        e: CustomEvent<string | undefined | null>,
                      ) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          taskProps.setTask(
                            produce(taskProps.task, (draftState) => {
                              if (draftState.status === 'success')
                                draftState.localValue.what = value;
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="column is-full pt-0">
                    <BalCheckbox
                      className="mr-5"
                      checked={taskProps.task.localValue.lawyerConsulted}
                      onBalChange={(e: CustomEvent<boolean>) =>
                        taskProps.setTask(
                          produce(taskProps.task, (draftState) => {
                            if (draftState.status === 'success') {
                              draftState.localValue.lawyerConsulted =
                                e.detail as boolean;
                            }
                          }),
                        )
                      }
                    >
                      {t(
                        'accidentInspectionTask.claimInformation.lawyerConsulted',
                      )}
                    </BalCheckbox>
                  </div>

                  <BalSelectFormField
                    className="column is-half"
                    methods={methods}
                    placeholder={t('accidentInspectionTask.category')}
                    title={t('accidentInspectionTask.category')}
                    controllerName="accidentInspectionTaskCategories"
                    values={accidentInspectionTaskCategories}
                    value={taskProps.task.localValue.category}
                    required={t('error.mandatoryField')}
                    onChange={(choice) => {
                      if (choice?.detail) {
                        const value = choice.detail;
                        taskProps.setTask(
                          produce(taskProps.task, (draftState) => {
                            if (draftState.status === 'success') {
                              draftState.localValue.category =
                                value as AccidentInspectionTaskCategory;
                            }
                          }),
                        );
                      }
                    }}
                  />
                </div>
              ) : taskProps.type === TaskTypes.VehicleTask ? (
                <VehicleInformation
                  vehicleTask={taskProps.task}
                  setVehicleTask={taskProps.setTask}
                  methods={methods}
                />
              ) : (
                <InvestigationInformation
                  investigationTask={taskProps.task}
                  setInvestigationTask={taskProps.setTask}
                  methods={methods}
                />
              )}
              {/* VehicleRecommendation not used in xml nor report: https://gitlab.com/earlybyte/baloise/bat/-/snippets/2441685 */}
            </BalCard>
            {taskProps.type !== TaskTypes.InvestigationTask &&
              (taskProps.type !== TaskTypes.VehicleTask ||
                (taskProps.type === TaskTypes.VehicleTask &&
                  taskProps.task.value.kind === 'VehicleInspectionTask')) && (
                <BalCard className="mt-3 p-5">
                  <BalHeading space="none" level="h4" className="pb-2">
                    {t('general.addAttachments')}
                  </BalHeading>
                  {taskProps.type === TaskTypes.PropertyInspectionTask ? (
                    <FileUpload
                      entityType={taskProps.type}
                      entity={taskProps.task}
                      setEntity={taskProps.setTask}
                      hasFileList
                      copyFromClipboard
                    />
                  ) : taskProps.type === TaskTypes.AccidentInspectionTask ? (
                    <FileUpload
                      entityType={taskProps.type}
                      entity={taskProps.task}
                      setEntity={taskProps.setTask}
                      hasFileList
                      copyFromClipboard
                    />
                  ) : (
                    taskProps.type === TaskTypes.VehicleTask &&
                    taskProps.task.value.kind === 'VehicleInspectionTask' && (
                      <FileUpload
                        entityType={taskProps.type}
                        entity={taskProps.task}
                        setEntity={taskProps.setTask}
                        hasFileList
                        copyFromClipboard
                      />
                    )
                  )}
                </BalCard>
              )}
            {taskButtons}
          </form>
        </FormProvider>
      )}
    </div>
  );
};

const VehicleInformation = ({
  vehicleTask,
  setVehicleTask,
  methods,
}: {
  vehicleTask: RequestResult<VehicleTask>;
  setVehicleTask: React.Dispatch<RequestResult<VehicleTask>>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<FieldValues, object>;
}) => {
  const { t } = useTranslation();
  if (vehicleTask.status === 'success') {
    const hideForMotorVehicleLiability =
      vehicleTask.localValue.claim.branch == 'MotorVehicleLiability';

    return (
      <div className="columns is-multiline">
        <BalFieldLabel className="column is-full mt-3 pb-0 mb-0">
          {t('vehicleTask.vehicleClaimInformation.comments')}
        </BalFieldLabel>
        <BalTextarea
          className="column is-full py-0 my-2 text-area-no-space"
          value={vehicleTask.localValue.remarks}
          placeholder={t('vehicleTask.vehicleClaimInformation.comments')}
          onBalInput={(e: CustomEvent<string | undefined>) => {
            if (e.detail || e.detail === '') {
              const value = e.detail;
              setVehicleTask(
                produce(vehicleTask, (draftState) => {
                  draftState.localValue.remarks = value;
                }),
              );
            }
          }}
        />
        {(vehicleTask.value.kind === 'VehicleInspectionTask' ||
          vehicleTask.value.kind === 'VehicleRiskAssessment') && (
          <BalDatepickerFormField
            controllerName="vehicleAppointmentEarliest"
            className="column is-half py-0 my-2"
            methods={methods}
            required={t('error.mandatoryField')}
            title={t('vehicleTask.vehicleContact.toBeVisitedFrom')}
            placeholder={t('vehicleTask.vehicle.registrationDate')}
            value={
              vehicleTask.localValue.appointmentEarliest
                ? String(vehicleTask.localValue.appointmentEarliest).slice(
                    0,
                    10,
                  )
                : undefined
            }
            onBalChange={(e: CustomEvent<string | undefined | null>) => {
              if (e.detail || e.detail === '') {
                const value = e.detail;
                setVehicleTask(
                  produce(vehicleTask, (draftState) => {
                    draftState.localValue.appointmentEarliest = value;
                  }),
                );
              }
            }}
          />
        )}
        {vehicleTask.value.kind == 'VehicleInspectionTask' && (
          <>
            <BalTextFormField
              controllerName="vehicleReportedBy"
              className="column is-half py-0 my-2"
              control={methods.control}
              title={t('vehicleTask.reportedBy')}
              placeholder={t('vehicleTask.reportedBy')}
              value={vehicleTask.localValue.reportedBy}
              required={t('error.mandatoryField')}
              onChange={(e: CustomEvent<string | undefined>) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setVehicleTask(
                    produce(vehicleTask, (draftState) => {
                      draftState.localValue.reportedBy = value;
                    }),
                  );
                }
              }}
            />
            <BalTextFormField
              controllerName="vehicleConditions"
              className="column is-6 py-0 my-2"
              control={methods.control}
              title={t('claimPolicy.conditions')}
              placeholder={t('claimPolicy.conditions')}
              value={vehicleTask.localValue.claim.policy.conditions}
              onChange={(e: CustomEvent<string | undefined>) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setVehicleTask(
                    produce(vehicleTask, (draftState) => {
                      draftState.localValue.claim.policy.conditions = value;
                    }),
                  );
                }
              }}
            />
            <BalNumberFormField
              controllerName="vehicleDeduction"
              className="column is-half py-0 my-2"
              methods={methods}
              required={
                vehicleTask.localValue.claim.branch == 'MotorVehicle'
                  ? t('error.mandatoryFieldForMotorVehicle')
                  : false
              }
              title={t('claimPolicy.deduction')}
              placeholder={t('claimPolicy.deduction')}
              value={vehicleTask.localValue.claim.policy.deduction?.toString()}
              onChange={(e) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setVehicleTask(
                    produce(vehicleTask, (draftState) => {
                      draftState.localValue.claim.policy.deduction =
                        value as unknown as number;
                    }),
                  );
                }
              }}
            />
            <BalField className="is-gapless column is-6 py-0 my-2">
              <BalFieldLabel className="m-0">
                {t('claimPolicy.compensation')}
              </BalFieldLabel>
              <BalTypeAheadField
                className="column is-full px-0 mt-0 pt-0"
                id="compensation"
                placeholder={t('compensationType.Unknown')}
                result={Globals.compensation}
                valueFormatter={(value) => t(`compensationType.${value}`)}
                keyFormatter={(value) => value}
                value={vehicleTask.localValue.claim.policy.compensation}
                onChange={(choice) =>
                  setVehicleTask(
                    produce(vehicleTask, (draftState) => {
                      draftState.localValue.claim.policy.compensation =
                        choice as Compensation;
                    }),
                  )
                }
              />
            </BalField>
            <BalNumberFormField
              controllerName="vehicleLiability"
              className="column is-half py-0 my-2"
              methods={methods}
              required={false}
              title={t('vehicleTask.vehicleClaimInformation.liability')}
              placeholder={t('vehicleTask.vehicleClaimInformation.liability')}
              value={vehicleTask.localValue.liability}
              onChange={(e: CustomEvent<string | undefined>) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setVehicleTask(
                    produce(vehicleTask, (draftState) => {
                      draftState.localValue.liability = value;
                    }),
                  );
                }
              }}
            />
            {!hideForMotorVehicleLiability && (
              <div className="ml-4">
                <BalCheckbox
                  className="mr-5"
                  checked={vehicleTask.localValue.claim.policy.parkingDamage}
                  onBalChange={(e) =>
                    setVehicleTask(
                      produce(vehicleTask, (draftState) => {
                        draftState.localValue.claim.policy.parkingDamage =
                          e.detail;
                      }),
                    )
                  }
                >
                  {t('claimPolicy.parkingDamage')}
                </BalCheckbox>

                <BalCheckbox
                  className="mr-5"
                  checked={vehicleTask.localValue.claim.policy.blanketSign}
                  onBalChange={(e) =>
                    setVehicleTask(
                      produce(vehicleTask, (draftState) => {
                        draftState.localValue.claim.policy.blanketSign =
                          e.detail;
                      }),
                    )
                  }
                >
                  {t('claimPolicy.blanketSign')}
                </BalCheckbox>
                <BalCheckbox
                  className="mr-5"
                  checked={vehicleTask.localValue.replacementVehicleInsured}
                  onBalChange={(e) =>
                    setVehicleTask(
                      produce(vehicleTask, (draftState) => {
                        draftState.localValue.replacementVehicleInsured =
                          e.detail;
                      }),
                    )
                  }
                >
                  {t('vehicleTask.vehicleClaimInformation.replacementCar')}
                </BalCheckbox>
                <BalCheckbox
                  className="mr-5"
                  checked={vehicleTask.localValue.claim.policy.leasing}
                  onBalChange={(e) =>
                    setVehicleTask(
                      produce(vehicleTask, (draftState) => {
                        draftState.localValue.claim.policy.leasing = e.detail;
                      }),
                    )
                  }
                >
                  {t('claimPolicy.leasing')}
                </BalCheckbox>
              </div>
            )}
            <BalCheckbox
              className="ml-4"
              checked={vehicleTask.localValue.policeReportAvailable}
              onBalChange={(e) =>
                setVehicleTask(
                  produce(vehicleTask, (draftState) => {
                    draftState.localValue.policeReportAvailable = e.detail;
                  }),
                )
              }
            >
              {t('vehicleTask.policeReportAvailable')}
            </BalCheckbox>
          </>
        )}
        {vehicleTask.localValue.kind == 'VehicleBonusCorrection' && (
          <BalField className="is-gapless column is-full-width">
            <BalFieldLabel className="my-3">
              {t('vehicleTask.bonusCorrectionReason')}
            </BalFieldLabel>
            <Controller
              name="bonusCorrectionReason"
              rules={{ required: t('error.mandatoryField') }}
              control={methods.control}
              render={({ field, fieldState }) => (
                <>
                  <BalRadioGroup
                    {...field}
                    className="is-flex is-flex-direction-column"
                    vertical
                    interface="select-button"
                    value={vehicleTask.localValue.bonusCorrectionReason}
                    onBalChange={(e) => {
                      setVehicleTask(
                        produce(vehicleTask, (draftState) => {
                          draftState.localValue.bonusCorrectionReason =
                            e.detail as VehicleBonusCorrectionReason;
                        }),
                      );
                      field.onChange(e.detail);
                    }}
                  >
                    {Globals.VehicleBonusCorrectionReason.map(
                      (bonusCorrectionReason) => {
                        return (
                          <div key={bonusCorrectionReason}>
                            <BalRadio value={bonusCorrectionReason}>
                              <span className="mr-2">
                                {t(
                                  `vehicleTask.vehicleBonusCorrection.bonusCorrectionReason.${bonusCorrectionReason}`,
                                )}
                              </span>
                            </BalRadio>
                          </div>
                        );
                      },
                    )}
                  </BalRadioGroup>
                  <div className="m-0">
                    {fieldState && (
                      <BalFieldMessage color="danger">
                        {fieldState.error && fieldState.error.message}
                      </BalFieldMessage>
                    )}
                  </div>
                </>
              )}
            />
          </BalField>
        )}
        {/* Fields didn't used in xml nor report: https://gitlab.com/earlybyte/baloise/bat/-/snippets/2441685 */}
      </div>
    );
  } else return <></>;
};

const InvestigationInformation = ({
  investigationTask,
  setInvestigationTask,
  methods,
}: {
  investigationTask: RequestResult<InvestigationTask>;
  setInvestigationTask: React.Dispatch<RequestResult<InvestigationTask>>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  methods: UseFormReturn<FieldValues, object>;
}) => {
  const { t } = useTranslation();
  const estimatedSavings: TranslatedSelection[] = Globals.estimatedSavings.map(
    (estimatedSaving) => ({
      value: estimatedSaving,
      translatedValue: t(`estimatedSavings.${estimatedSaving}`),
    }),
  );

  if (investigationTask.status === 'success') {
    return (
      <>
        <div className="columns is-multiline">
          <BalTextareaFormField
            className="column is-full mb-0 pb-0"
            controllerName="suspicion"
            title={t('investigationTask.suspicion')}
            placeholder={t('investigationTask.describeWhatIsSuspicious')}
            rows={4}
            required={t('error.mandatoryField')}
            methods={methods}
            value={investigationTask.localValue.what}
            onBalChange={(e: CustomEvent<string | null | undefined>) => {
              if (e.detail || e.detail === '') {
                const value = e.detail;
                setInvestigationTask(
                  produce(investigationTask, (draftState) => {
                    if (draftState.status === 'success')
                      draftState.localValue.what = value;
                  }),
                );
              }
            }}
          />
          <BalSelectFormField
            className="column is-half"
            methods={methods}
            placeholder={t('general.pleaseChoose')}
            title={t('investigationTask.estimatedSavingsWizard')}
            controllerName="estimatedSavings"
            values={estimatedSavings}
            value={investigationTask.localValue.estimatedSavings}
            required={t('error.mandatoryField')}
            onChange={(choice) => {
              setInvestigationTask(
                produce(investigationTask, (draftState) => {
                  draftState.localValue.estimatedSavings =
                    choice?.detail as unknown as EstimatedSavings;
                }),
              );
            }}
          />
          <BalDatepickerFormField
            className="column is-half pl-4"
            controllerName="claimDate"
            methods={methods}
            title={t('taskOverview.claimDate')}
            placeholder={t('taskOverview.claimDate')}
            required={t('error.mandatoryField')}
            value={
              investigationTask.localValue.claim.claimDate
                ? String(investigationTask.localValue.claim.claimDate).slice(
                    0,
                    10,
                  )
                : undefined
            }
            onBalChange={(e: CustomEvent<string | undefined | null>) => {
              if (e.detail || e.detail === '') {
                const value = e.detail !== '' ? e.detail : undefined;

                setInvestigationTask(
                  produce(investigationTask, (draftState) => {
                    draftState.localValue.claim.claimDate = value;
                  }),
                );
              }
            }}
            maxDate={DateTime.now()}
          />
        </div>
      </>
    );
  } else return <></>;
};
