import {
  BalClose,
  BalField,
  BalFieldControl,
  BalFieldLabel,
  BalFieldMessage,
  BalSelect,
  BalSelectOption,
} from '@baloise/design-system-components-react';
import {
  AnimationControls,
  TargetAndTransition,
  VariantLabels,
} from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/general.scss';
import { FormValidation } from '../../types/types';
import { CopyButton } from '../ui/copy-button';

export const BalTypeAheadField = <T,>({
  className,
  id,
  label,
  placeholder,
  requiredPlaceholder,
  value,
  defaultValue,
  result,
  valueFormatter,
  keyFormatter,
  hasBkey,
  bKeyFormatter,
  onChange,
  disabled,
  filter,
  formValidation,
  blocker,
  resetValue,
  onBlur,
  onFocus,
}: {
  className?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  requiredPlaceholder?: boolean;
  value?: T;
  defaultValue?: string;
  result: Array<T> | undefined;
  valueFormatter: (value: T) => string;
  keyFormatter: (value: T) => string;
  hasBkey?: boolean;
  bKeyFormatter?: (value: T) => string;
  onChange: (choice: T) => void;
  disabled?: boolean;
  filter?: (value: T, index: number, array: T[]) => unknown;
  formValidation?: FormValidation;
  blocker?: boolean;
  resetValue?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  animation?:
    | AnimationControls
    | TargetAndTransition
    | VariantLabels
    | undefined;
}): JSX.Element => {
  const { t } = useTranslation();
  const balSelectRef = useRef<HTMLBalSelectElement>(null);

  useEffect(() => {
    const resetValueF = async () => {
      if (balSelectRef.current && !value) {
        await balSelectRef.current.clear();
        // After clearing, the object can became null.
        if (balSelectRef.current) {
          balSelectRef.current.setAttribute('value', '');
        }
      }
    };
    resetValueF();
  }, [value]);
  return (
    <>
      <BalField className={className} disabled={disabled}>
        {label && <BalFieldLabel>{label}</BalFieldLabel>}
        <div className="is-flex is-align-items-center is-full-width">
          <BalFieldControl className="m-0 is-full-width">
            <BalSelect
              defaultValue={defaultValue}
              {...(formValidation ? formValidation.register : {})}
              typeahead
              id={id}
              placeholder={
                placeholder
                  ? placeholder
                  : requiredPlaceholder
                  ? `${t('general.pleaseChoose')} *`
                  : t('general.pleaseChoose')
              }
              value={value !== undefined ? keyFormatter(value) : ''}
              ref={balSelectRef}
              onBalChange={async (e) => {
                if (result && e.detail !== null && !blocker) {
                  const value = e.detail ?? undefined;
                  const res = result.find(
                    (element) =>
                      value !== undefined && value === keyFormatter(element),
                  );

                  if (formValidation) {
                    await formValidation.register.onChange(e);
                  }
                  onChange(res as T);
                }
              }}
              onBalFocus={onFocus}
              onBalBlur={onBlur}
            >
              {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
              <div className="bal-select__inner">
                {result &&
                  result.filter(filter ?? (() => true)).map((element) => {
                    if (element !== undefined) {
                      const text = valueFormatter(element);
                      const key = keyFormatter(element);
                      return (
                        <BalSelectOption key={key} value={key} label={text}>
                          {text}
                        </BalSelectOption>
                      );
                    }
                  })}
              </div>
            </BalSelect>
          </BalFieldControl>
          <BalFieldControl>
            {value !== undefined && value !== null && !disabled && !blocker && (
              <BalClose
                className="ml-2"
                onClick={async () => {
                  const none: unknown = resetValue ?? null;
                  onChange(none as T);
                  if (balSelectRef && balSelectRef.current && !resetValue) {
                    await balSelectRef.current.clear();
                  }
                }}
              />
            )}
          </BalFieldControl>
        </div>
        {formValidation &&
        formValidation.formState.errors &&
        formValidation.formState.errors[formValidation.register.name] ? (
          <BalFieldMessage color="danger">
            {`${
              formValidation.formState.errors[formValidation.register.name]
                ?.message ?? t('error.mandatoryField')
            }`}
          </BalFieldMessage>
        ) : (
          <></>
        )}
      </BalField>
      {hasBkey && value && bKeyFormatter && (
        <>
          <div className="column is-1" />
          <div className="column is-3 bottom-blue-line is-flex is-align-items-center is-justify-content-space-between">
            {bKeyFormatter(value)}
            <CopyButton copyValue={bKeyFormatter(value)} />
          </div>
        </>
      )}
    </>
  );
};
