import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppModuleVisibility } from '../../types/types';
import { EBRoutes } from '../../router/router';
import { WithCapabilities } from '../authorization';
import { useState } from 'react';

type NavigationModulesProps = {
  appModuleVisability: AppModuleVisibility;
};

enum Modules {
  Standard,
  Accident,
  Investigation,
  MotorVehicle,
  NetworkPartners,
}

export const NavigationModules = ({
  appModuleVisability: appModuleVisability,
}: NavigationModulesProps): JSX.Element => {
  const { t } = useTranslation();
  const [selectedModule, setSelectedModule] = useState<Modules | undefined>(
    location.pathname.includes('investigation-task')
      ? Modules.Investigation
      : location.pathname.includes('accident-inspection-task')
      ? Modules.Accident
      : location.pathname.includes('network-partners')
      ? Modules.NetworkPartners
      : location.pathname.includes('property-inspection-task')
      ? Modules.Standard
      : location.pathname.includes('vehicle')
      ? Modules.MotorVehicle
      : undefined,
  );
  const applySelectedClass = (requiredModule: Modules) => {
    return selectedModule === requiredModule ? 'selected-navbar-item' : '';
  };
  return (
    <>
      <WithCapabilities
        requiredCapabilities={[
          'CreatePropertyInspectionTask',
          'EditPropertyInspectionTask',
        ]}
        capabilityEnabled={appModuleVisability.propertyInspection}
      >
        <Link
          className={`navbar-item ${applySelectedClass(Modules.Standard)}`}
          to={EBRoutes.PROPERTY_INSPECTION_TASK.create({})}
          onClick={() => {
            setSelectedModule(Modules.Standard);
          }}
        >
          {t('branch.Standard')}
        </Link>
      </WithCapabilities>
      <WithCapabilities
        requiredCapabilities={[
          'CreateAccidentInspectionTask',
          'EditAccidentInspectionTask',
        ]}
        capabilityEnabled={appModuleVisability.accidentInspection}
      >
        <Link
          className={`navbar-item ${applySelectedClass(Modules.Accident)}`}
          to={EBRoutes.ACCIDENT_INSPECTION_TASK.create({})}
          onClick={() => {
            setSelectedModule(Modules.Accident);
          }}
        >
          {t('branch.AccidentHealth')}
        </Link>
      </WithCapabilities>
      <WithCapabilities
        requiredCapabilities={[
          'CreateFraudInspectionTask',
          'EditFraudInspectionTask',
        ]}
        capabilityEnabled={appModuleVisability.investigationInspection}
      >
        <Link
          className={`navbar-item ${applySelectedClass(Modules.Investigation)}`}
          to={EBRoutes.INVESTIGATION_TASK.create({})}
          onClick={() => {
            setSelectedModule(Modules.Investigation);
          }}
        >
          {t('home.investigation')}
        </Link>
      </WithCapabilities>
      <WithCapabilities
        requiredCapabilities={['CreateVehicleTask', 'EditVehicleTask']}
        capabilityEnabled={appModuleVisability.vehicleInspection}
      >
        <Link
          className={`navbar-item ${applySelectedClass(Modules.MotorVehicle)}`}
          to={EBRoutes.VEHICLE_INSPECTION_TASK.create({})}
          onClick={() => {
            setSelectedModule(Modules.MotorVehicle);
          }}
        >
          {t('branch.MotorVehicle')}
        </Link>
      </WithCapabilities>
      <WithCapabilities
        requiredCapabilities={['ReadNetworkPartner']}
        capabilityEnabled={appModuleVisability.networkPartner}
      >
        <Link
          className={`navbar-item ${applySelectedClass(
            Modules.NetworkPartners,
          )}`}
          to={EBRoutes.NETWORK_PARTNERS.create({})}
          onClick={() => {
            setSelectedModule(Modules.NetworkPartners);
          }}
        >
          {t('EBRoutes.NETWORK_PARTNERS')}
        </Link>
      </WithCapabilities>
    </>
  );
};
