import L from 'leaflet';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { BalHeading, BalText } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { Craftsman } from '../../../types/types';
import {
  concat,
  concatCommaSpace,
  getLabelValueFromBkpNumber,
} from '../../../utils';
import '../../../styles/map.scss';
import { MapCenter, mapProps, mapUrl, MarkerIcon } from '..';
import { RequestResult } from '../../../data';
import { Link } from 'react-router-dom';
import { EBRoutes } from '../../../router/router';
import { InfoDisplay } from '../../../components/ui';

type PartnerProps = {
  partner: RequestResult<Craftsman>;
};

const PartnerCompanyFields = ({ partner }: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  if (partner.status === 'success') {
    return (
      <>
        <BalHeading level="h5" space="none">
          {t('general.company')}
        </BalHeading>
        <InfoDisplay
          title={t('networkPartners.partnerNumber')}
          text={
            partner.localValue.type === 'propertyCraftsman'
              ? partner.localValue.propertyCraftsman.partnerNumber
              : partner.localValue.vehicleCraftsman.partnerNumber
          }
          className="pb-1"
        />
        <InfoDisplay
          title={t('general.name')}
          text={
            partner.localValue.type === 'propertyCraftsman'
              ? concat([
                  partner.localValue.propertyCraftsman.company.name,
                  partner.localValue.propertyCraftsman.company.additionalName ??
                    '',
                ])
              : concat([
                  partner.localValue.vehicleCraftsman.company.name,
                  partner.localValue.vehicleCraftsman.company.additionalName ??
                    '',
                ])
          }
          className="pb-1"
        />
        <InfoDisplay
          title={t('locationDetails.street')}
          text={
            partner.localValue.type === 'propertyCraftsman'
              ? concat([
                  partner.localValue.propertyCraftsman.company.address?.street,
                  partner.localValue.propertyCraftsman.company.address
                    ?.houseNumber,
                ])
              : concat([
                  partner.localValue.vehicleCraftsman.company.address?.street,
                  partner.localValue.vehicleCraftsman.company.address
                    ?.houseNumber,
                ])
          }
          className="pb-1"
        />
        <InfoDisplay
          title={t('general.zipCodeCity')}
          text={
            partner.localValue.type === 'propertyCraftsman'
              ? concat([
                  partner.localValue.propertyCraftsman.company.address?.zipCode?.toString(),
                  partner.localValue.propertyCraftsman.company.address?.city,
                ])
              : concat([
                  partner.localValue.vehicleCraftsman.company.address?.zipCode?.toString(),
                  partner.localValue.vehicleCraftsman.company.address?.city,
                ])
          }
          className="pb-1"
        />
        <InfoDisplay
          title={t('networkPartners.telephoneCompany')}
          text={
            partner.localValue.type === 'propertyCraftsman'
              ? partner.localValue.propertyCraftsman.company.phoneNumber
              : partner.localValue.vehicleCraftsman.company.phoneNumber
          }
          className="pb-1"
        />
        {partner.localValue.type === 'propertyCraftsman'
          ? partner.localValue.propertyCraftsman.company.website && (
              <InfoDisplay
                title={t('networkPartners.website')}
                text={partner.localValue.propertyCraftsman.company.website}
                className="pb-1"
              />
            )
          : partner.localValue.vehicleCraftsman.company.website && (
              <InfoDisplay
                title={t('networkPartners.website')}
                text={partner.localValue.vehicleCraftsman.company.website}
                className="pb-1"
              />
            )}
      </>
    );
  } else {
    return <></>;
  }
};

export const NetworkMapSmall = ({ partner }: PartnerProps): JSX.Element => {
  if (partner.status === 'success') {
    const position: L.LatLngExpression =
      partner.localValue.type === 'propertyCraftsman'
        ? [
            partner.localValue.propertyCraftsman.latitude,
            partner.localValue.propertyCraftsman.longitude,
          ]
        : [
            partner.localValue.vehicleCraftsman.latitude,
            partner.localValue.vehicleCraftsman.longitude,
          ];
    return (
      <>
        <MapContainer
          {...mapProps}
          minZoom={6.25}
          zoomControl={false}
          className="mt-2 network-partner-map "
        >
          <TileLayer url={mapUrl} />
          <Marker position={position} icon={MarkerIcon} />
          <MapCenter center={position} zoom={9} />
        </MapContainer>
      </>
    );
  } else {
    return <></>;
  }
};

const PartnerContactFields = ({ partner }: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  if (partner.status === 'success') {
    const craftsman = partner.localValue;
    const primaryContact =
      craftsman.type === 'propertyCraftsman'
        ? craftsman.propertyCraftsman.contacts.find(
            (contact) =>
              contact.id === craftsman.propertyCraftsman.primaryContactId,
          )
        : craftsman.vehicleCraftsman.contacts.find(
            (contact) =>
              contact.id === craftsman.vehicleCraftsman.primaryContactId,
          );
    return (
      <>
        <BalHeading level="h5" space="none" className="mt-7">
          {t('general.contact.label')}
        </BalHeading>
        {primaryContact && primaryContact.function && (
          <InfoDisplay
            title={t('general.function')}
            text={primaryContact.function}
          />
        )}
        <InfoDisplay
          title={t('general.name')}
          text={primaryContact ? primaryContact.displayName : ''}
        />
        <InfoDisplay
          title={t('general.phone')}
          text={primaryContact ? primaryContact.phoneNumber : ''}
        />
        {primaryContact && primaryContact.mobileNumber && (
          <InfoDisplay
            title={t('general.mobile')}
            text={primaryContact.mobileNumber}
          />
        )}
        <InfoDisplay
          title={t('general.email')}
          text={
            primaryContact && primaryContact.email ? primaryContact.email : ''
          }
        />
      </>
    );
  } else {
    return <></>;
  }
};

const OperationalInformation = ({ partner }: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  if (partner.status === 'success') {
    return (
      <>
        <BalHeading level="h5" space="none" className="mt-5">
          {t('networkPartners.operationalInformation')}
        </BalHeading>
        {partner.localValue.type === 'propertyCraftsman' ? (
          <>
            {partner.localValue.propertyCraftsman.region && (
              <InfoDisplay
                title={t('networkPartners.region')}
                text={t(
                  `region.${partner.localValue.propertyCraftsman.region}`,
                )}
              />
            )}
            <InfoDisplay
              title={t('workCategory.label')}
              text={concatCommaSpace(
                partner.localValue.propertyCraftsman.workCategories.map(
                  (workCategory) =>
                    getLabelValueFromBkpNumber(workCategory.bkpNumber),
                ),
              )}
            />
          </>
        ) : (
          <>
            {partner.localValue.vehicleCraftsman.vehicleBrands && (
              <InfoDisplay
                title={t('networkPartners.carBrands')}
                text={concatCommaSpace(
                  partner.localValue.vehicleCraftsman.vehicleBrands,
                )}
              />
            )}
            {partner.localValue.vehicleCraftsman.vehicleSpecialties && (
              <InfoDisplay
                title={t('networkPartners.specialtyCompany')}
                text={concatCommaSpace(
                  partner.localValue.vehicleCraftsman.vehicleSpecialties,
                )}
              />
            )}
          </>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export const NetworkPartnerNumber = ({
  partner,
}: PartnerProps): JSX.Element => {
  const { t } = useTranslation();
  if (partner.status === 'success') {
    return (
      <>
        <div className="is-flex is-align-items-center is-justify-content-space-between mb-2">
          <BalText size="small" color="primary-light">
            {t('networkPartners.partner')}
          </BalText>
          <Link
            to={EBRoutes.NETWORK_PARTNER_DETAIL.create({
              networkPartnerId:
                partner.localValue.type === 'propertyCraftsman'
                  ? partner.localValue.propertyCraftsman.id
                  : partner.localValue.vehicleCraftsman.id,
            })}
          >
            <BalText color="primary" size="small">
              {t('overlay.detailView')}
            </BalText>
          </Link>
        </div>
        <BalHeading space="none" level="h3" color="info">
          {partner.localValue.type === 'propertyCraftsman'
            ? concat([
                partner.localValue.propertyCraftsman.company.name,
                partner.localValue.propertyCraftsman.company.additionalName ??
                  '',
              ])
            : concat([
                partner.localValue.vehicleCraftsman.company.name,
                partner.localValue.vehicleCraftsman.company.additionalName ??
                  '',
              ])}
        </BalHeading>
      </>
    );
  } else {
    return <></>;
  }
};

export const PropertyPartnerOverlay = ({
  partner,
}: PartnerProps): JSX.Element => {
  return (
    <>
      <PartnerCompanyFields partner={partner} />
      <NetworkMapSmall partner={partner} />
      <PartnerContactFields partner={partner} />
      <OperationalInformation partner={partner} />
    </>
  );
};

export const VehiclePartnerOverlay = ({
  partner,
}: PartnerProps): JSX.Element => {
  return (
    <>
      <PartnerCompanyFields partner={partner} />
      <NetworkMapSmall partner={partner} />
      <PartnerContactFields partner={partner} />
      <OperationalInformation partner={partner} />
    </>
  );
};
