import React from 'react';
import { RequestResult } from '../data';
import { UserDto } from '../types/resource-models';

type UserContextProps = {
  user: RequestResult<UserDto>;
  setUser: React.Dispatch<RequestResult<UserDto>>;
};

export const UserContext = React.createContext<UserContextProps>({
  user: { status: 'initial' },
  setUser: () => {
    return;
  },
});
