import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';

export interface ImportFailedModalProps {
  failedIdentifiers: string[];
}
const ImportFailedModal = ({
  failedIdentifiers,
}: ImportFailedModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <BalModalHeader>
        <BalHeading space="none" level="h3" subtitle className="m-0">
          {t('importExport.failedIdentifiers')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <table className="failed-identifiers-table">
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {failedIdentifiers.map((failedIdentification, index) => (
              <tr key={index}>
                <td>{failedIdentification}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <BalButtonGroup position="right">
            <BalButton
              elementType="button"
              color="primary-light"
              onClick={(event) => {
                if (event.detail == 1) {
                  balModalController.dismiss();
                }
              }}
            >
              {t('general.buttons.cancel')}
            </BalButton>
          </BalButtonGroup>
        </div>
      </BalModalBody>
    </>
  );
};

export default ImportFailedModal;
