import {
  BalHeading,
  BalModalBody,
  BalModalHeader,
} from '@baloise/design-system-components-react';
import { useState, useEffect } from 'react';
import { balModalController } from '../../../../../controller/controllers';
import { InsuranceSurveyCreateAllForm } from './insurance-survey-create-all-form';

export interface InsuranceSurveyCreateAllModalProps {
  investigationTaskId: string;
  title: string;
  onSave: (insuranceSurveyId?: string) => void;
}

export const InsuranceSurveyCreateAllFormModal = ({
  investigationTaskId,
  title,
  onSave,
}: InsuranceSurveyCreateAllModalProps): JSX.Element => {
  const [saved, setSaved] = useState<boolean>(false);
  useEffect(() => {
    if (saved) {
      balModalController.dismiss();
    }
  }, [saved]);

  return (
    <>
      <BalModalHeader className="is-full-width create-contacts-header">
        <BalHeading level="h3">{title}</BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <InsuranceSurveyCreateAllForm
          investigationTaskId={investigationTaskId}
          onSave={() => {
            onSave();
            setSaved(true);
          }}
        ></InsuranceSurveyCreateAllForm>
      </BalModalBody>
    </>
  );
};
