import {
  BalCard,
  BalHeading,
  BalCheckbox,
  BalFieldLabel,
  BalSpinner,
  BalFieldMessage,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../data';
import { VehicleTask } from '../../../types/types';
import img from '../../../assets/img/bvm-damage-zone.png';
import React, { useEffect } from 'react';
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';
import produce from 'immer';
import {
  BalDatepickerFormField,
  BalNumberFormField,
  BalTextareaFormField,
  BalTextFormField,
  BalTypeAheadField,
} from '../../../components/input';
import { Globals } from '../../../utils';
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { VehicleType } from '../../../types/resource-models';

export const VehicleTaskClaimDamageForm = ({
  vehicleTask,
  setVehicleTask,
  taskButtons,
  patchTask,
  methodsProp,
}: {
  vehicleTask: RequestResult<VehicleTask>;
  setVehicleTask: React.Dispatch<RequestResult<VehicleTask>>;
  taskButtons?: JSX.Element;
  patchTask?: () => void;
  methodsProp?: UseFormReturn<FieldValues, object>;
}): JSX.Element => {
  const { t } = useTranslation();
  const localMethods = useForm({ mode: 'onChange' });
  const methods = methodsProp ?? localMethods;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const patch = patchTask ?? (() => {});

  // Risk assessment validations about vehicleMasterNumber and vehicleIdentificationNumber are bounded.
  useEffect(() => {
    if (
      vehicleTask.status === 'success' &&
      vehicleTask.localValue.kind === 'VehicleRiskAssessment' &&
      (methods.getFieldState('vehicleMasterNumber').isDirty ||
        methods.getFieldState('vehicleIdentificationNumber').isDirty)
    ) {
      methods.trigger('vehicleMasterNumber');
      methods.trigger('vehicleIdentificationNumber');
    }
  }, [vehicleTask, methods]);

  return (
    <div>
      {(vehicleTask.status === 'initial' ||
        vehicleTask.status === 'loading') && <BalSpinner />}
      {vehicleTask.status === 'error' && <></>}
      {vehicleTask.status === 'success' && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(patch)}>
            <BalCard className="mt-5 p-5">
              <BalHeading space="none" level="h4" className="mb-3">
                {t('vehicleTask.overlay.vehicleInformation')}
              </BalHeading>
              <div className="columns is-multiline">
                {(vehicleTask.value.kind === 'VehicleInspectionTask' ||
                  vehicleTask.value.kind === 'VehicleRiskAssessment') && (
                  <>
                    <div className="column is-full p-0 m-0">
                      <Controller
                        name="vehicleTypeController"
                        control={methods.control}
                        defaultValue={
                          vehicleTask.localValue.affectedVehicle.vehicleType
                        }
                        rules={{
                          required:
                            vehicleTask.localValue.kind ===
                            'VehicleRiskAssessment'
                              ? t('error.mandatoryField')
                              : false,
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <BalTypeAheadField
                              className="column is-full pb-0 mb-0"
                              id="vehicleType"
                              placeholder={
                                vehicleTask.localValue.kind ===
                                'VehicleRiskAssessment'
                                  ? `${t('vehicleTask.vehicle.vehicleType')} *`
                                  : t('vehicleTask.vehicle.vehicleType')
                              }
                              result={Globals.vehicleTypes}
                              valueFormatter={(value) =>
                                t(`vehicleTask.vehicleType.${value}`)
                              }
                              keyFormatter={(value) => value}
                              value={
                                vehicleTask.localValue.affectedVehicle
                                  .vehicleType
                              }
                              onChange={(choice) => {
                                setVehicleTask(
                                  produce(vehicleTask, (draftState) => {
                                    draftState.localValue.affectedVehicle.vehicleType =
                                      choice as VehicleType;
                                  }),
                                );
                                field.onChange(choice);
                              }}
                            />
                            <div className="ml-4">
                              {fieldState && (
                                <BalFieldMessage color="danger">
                                  {fieldState.error && fieldState.error.message}
                                </BalFieldMessage>
                              )}
                            </div>
                          </>
                        )}
                      />
                    </div>
                    <BalTextFormField
                      title={t('vehicleTask.vehicle.brand')}
                      className="column is-6 py-0 my-0"
                      controllerName="vehicleBrand"
                      control={methods.control}
                      required={t('error.mandatoryField')}
                      placeholder={t('vehicleTask.vehicle.brand')}
                      maxLength={17}
                      value={vehicleTask.localValue.affectedVehicle.brand}
                      onChange={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.brand =
                                value;
                            }),
                          );
                        }
                      }}
                    />
                    <BalTextFormField
                      title={t('vehicleTask.vehicle.model')}
                      className="column is-6 py-0 my-0"
                      controllerName="vehicleModel"
                      control={methods.control}
                      required={t('error.mandatoryField')}
                      placeholder={t('vehicleTask.vehicle.model')}
                      maxLength={17}
                      value={vehicleTask.localValue.affectedVehicle.model}
                      onChange={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;

                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.model =
                                value;
                            }),
                          );
                        }
                      }}
                    />
                    <BalTextFormField
                      title={t(
                        'vehicleTask.vehicle.vehicleIdentificationNumber',
                      )}
                      className="column is-6 py-0 my-0"
                      controllerName="vehicleIdentificationNumber"
                      control={methods.control}
                      required={
                        vehicleTask.localValue.kind ===
                          'VehicleInspectionTask' ||
                        vehicleTask.localValue.affectedVehicle
                          .vehicleMasterNumber
                          ? false
                          : t('error.mandatoryMasterOrIdentificationNumber')
                      }
                      placeholder={t(
                        'vehicleTask.vehicle.vehicleIdentificationNumber',
                      )}
                      maxLength={17}
                      value={
                        vehicleTask.localValue.affectedVehicle
                          .vehicleIdentificationNumber
                      }
                      onChange={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.vehicleIdentificationNumber =
                                value;
                            }),
                          );
                        }
                      }}
                    />
                    <BalTextFormField
                      title={t('vehicleTask.vehicle.vehicleMasterNumber')}
                      className="column is-6 py-0 my-0"
                      controllerName="vehicleMasterNumber"
                      control={methods.control}
                      required={
                        vehicleTask.localValue.kind ===
                          'VehicleInspectionTask' ||
                        vehicleTask.localValue.affectedVehicle
                          .vehicleIdentificationNumber
                          ? false
                          : t('error.mandatoryMasterOrIdentificationNumber')
                      }
                      placeholder={t('vehicleTask.vehicle.vehicleMasterNumber')}
                      maxLength={17}
                      value={
                        vehicleTask.localValue.affectedVehicle
                          .vehicleMasterNumber
                      }
                      onChange={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.vehicleMasterNumber =
                                value;
                            }),
                          );
                        }
                      }}
                    />
                    <BalTextFormField
                      title={t('vehicleTask.vehicle.typeCertificate')}
                      className="column is-6 py-0 my-0"
                      controllerName="vehicleTypeCertificate"
                      control={methods.control}
                      required={false}
                      placeholder={t('vehicleTask.vehicle.typeCertificate')}
                      value={
                        vehicleTask.localValue.affectedVehicle.typeCertificate
                      }
                      onChange={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.typeCertificate =
                                value;
                            }),
                          );
                        }
                      }}
                    />
                  </>
                )}
                <BalTextFormField
                  title={t('vehicleTask.vehicle.licensePlate')}
                  className="column is-6 py-0 my-2"
                  controllerName="vehicleLicensePlate"
                  control={methods.control}
                  placeholder={t('vehicleTask.vehicle.licensePlate')}
                  required={t('error.mandatoryField')}
                  value={vehicleTask.localValue.affectedVehicle.plate}
                  onChange={(e: CustomEvent<string | undefined>) => {
                    if (e.detail || e.detail === '') {
                      const value = e.detail;
                      setVehicleTask(
                        produce(vehicleTask, (draftState) => {
                          draftState.localValue.affectedVehicle.plate = value;
                        }),
                      );
                    }
                  }}
                />
                {(vehicleTask.value.kind === 'VehicleInspectionTask' ||
                  vehicleTask.value.kind === 'VehicleRiskAssessment') && (
                  <>
                    <BalDatepickerFormField
                      title={t('vehicleTask.vehicle.registration')}
                      className="column py-0 my-0"
                      controllerName="vehicleRegistrationDate"
                      methods={methods}
                      required={
                        vehicleTask.localValue.kind === 'VehicleRiskAssessment'
                          ? t('error.mandatoryField')
                          : false
                      }
                      placeholder={t('vehicleTask.vehicle.registrationDate')}
                      value={
                        vehicleTask.localValue.affectedVehicle.registration
                          ? String(
                              vehicleTask.localValue.affectedVehicle
                                .registration,
                            ).slice(0, 10)
                          : undefined
                      }
                      onBalChange={(
                        e: CustomEvent<string | undefined | null>,
                      ) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.registration =
                                value;
                            }),
                          );
                        }
                      }}
                    />
                    <BalNumberFormField
                      title={t('vehicleTask.vehicle.mileage')}
                      className="column is-6 py-0 my-0"
                      controllerName="mileage"
                      methods={methods}
                      required={false}
                      placeholder={t('vehicleTask.vehicle.mileage')}
                      value={vehicleTask.localValue.affectedVehicle.mileage?.toString()}
                      onChange={(e) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.mileage =
                                value as unknown as number;
                            }),
                          );
                        }
                      }}
                    />
                  </>
                )}
                {vehicleTask.value.kind === 'VehicleInspectionTask' && (
                  <>
                    <BalNumberFormField
                      title={t('vehicleTask.vehicle.newValue')}
                      className="column is-6 py-0 my-2"
                      controllerName="vehicleNewValue"
                      methods={methods}
                      required={false}
                      placeholder={t('vehicleTask.vehicle.newValue')}
                      value={vehicleTask.localValue.affectedVehicle.newValue?.toString()}
                      onChange={(e) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.newValue =
                                value as unknown as number;
                            }),
                          );
                        }
                      }}
                    />
                    <BalNumberFormField
                      title={t('vehicleTask.vehicle.equipment')}
                      className="column is-6 py-0 my-2"
                      controllerName="vehicleEquipment"
                      methods={methods}
                      required={false}
                      placeholder={t('vehicleTask.vehicle.equipment')}
                      value={vehicleTask.localValue.affectedVehicle.equipmentValue?.toString()}
                      onChange={(e) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.equipmentValue =
                                value as unknown as number;
                            }),
                          );
                        }
                      }}
                    />
                  </>
                )}
                {vehicleTask.value.kind === 'VehicleRiskAssessment' && (
                  <>
                    <BalNumberFormField
                      title={t('vehicleTask.vehicle.desiredMaximumInsuredSum')}
                      className="column is-6 py-0 my-2"
                      controllerName="desiredMaximumInsuredSum"
                      methods={methods}
                      required={t('error.mandatoryField')}
                      placeholder={t(
                        'vehicleTask.vehicle.desiredMaximumInsuredSum',
                      )}
                      value={vehicleTask.localValue.affectedVehicle.desiredMaximumInsuredSum?.toString()}
                      onChange={(e) => {
                        if (e.detail || e.detail === '') {
                          const value = e.detail;
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.affectedVehicle.desiredMaximumInsuredSum =
                                value as unknown as number;
                            }),
                          );
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </BalCard>
            {vehicleTask.value.kind === 'VehicleInspectionTask' && (
              <BalCard className="mt-5 p-5">
                <BalHeading space="none" level="h4" className="mb-3">
                  {t('vehicleTask.vehicleClaimDamage.title')}
                </BalHeading>
                <div className="columns is-multiline is-gapless">
                  <BalTextareaFormField
                    className="column is-full py-0"
                    controllerName="whatHappend"
                    methods={methods}
                    required={false}
                    title={t('general.claim.whatHappened')}
                    placeholder={t('general.claim.whatHappened')}
                    value={vehicleTask.localValue.what}
                    onBalChange={(
                      e: CustomEvent<string | undefined | null>,
                    ) => {
                      if (e.detail || e.detail === '') {
                        const value = e.detail;
                        setVehicleTask(
                          produce(vehicleTask, (draftState) => {
                            draftState.localValue.what = value;
                          }),
                        );
                      }
                    }}
                  />
                  <BalNumberFormField
                    title={t('vehicleTask.vehicleClaimDamage.damageCost')}
                    className="column is-full py-0 my-2"
                    controllerName="vehicleDamageCost"
                    methods={methods}
                    required={t('error.mandatoryField')}
                    placeholder={t('vehicleTask.vehicleClaimDamage.damageCost')}
                    value={
                      vehicleTask.localValue.costs
                        ? vehicleTask.localValue.costs.toString()
                        : ''
                    }
                    onChange={(e) => {
                      if (e.detail || e.detail === '') {
                        const value = e.detail;
                        setVehicleTask(
                          produce(vehicleTask, (draftState) => {
                            draftState.localValue.costs =
                              value as unknown as number;
                          }),
                        );
                      }
                    }}
                  />
                  <BalFieldLabel className="column is-full mt-3">
                    {t('vehicleTask.vehicleClaimDamage.damageZone')}
                  </BalFieldLabel>
                  <ImageMarker
                    src={img}
                    markers={vehicleTask.localValue.damageCoordinates}
                    onAddMarker={(marker: Marker) =>
                      setVehicleTask(
                        produce(vehicleTask, (draftState) => {
                          draftState.localValue.damageCoordinates.push({
                            top: marker.top as number,
                            left: marker.left as number,
                          });
                        }),
                      )
                    }
                    markerComponent={(props: MarkerComponentProps) => (
                      <span
                        onClick={(event) =>
                          event.detail == 1 &&
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.damageCoordinates.splice(
                                props.itemNumber.valueOf(),
                                1,
                              );
                            }),
                          )
                        }
                        className="unselectable"
                      >
                        ✕
                      </span>
                    )}
                  />
                  <BalFieldLabel className="column is-full mt-3">
                    {t('vehicleTask.vehicleClaimDamage.whiplashEvaluation')}
                  </BalFieldLabel>
                  <div className="column mt-3">
                    <div>
                      <BalCheckbox
                        className="mr-3"
                        checked={
                          vehicleTask.localValue.whiplashClarificationRearEnd
                        }
                        onBalChange={(e: CustomEvent<boolean>) =>
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.whiplashClarificationRearEnd =
                                e.detail as boolean;
                            }),
                          )
                        }
                      >
                        {t('vehicleTask.vehicleClaimDamage.ifRear')}
                      </BalCheckbox>
                      <BalCheckbox
                        className="mr-3"
                        checked={
                          vehicleTask.localValue.whiplashClarificationFrontal
                        }
                        onBalChange={(e: CustomEvent<boolean>) =>
                          setVehicleTask(
                            produce(vehicleTask, (draftState) => {
                              draftState.localValue.whiplashClarificationFrontal =
                                e.detail as boolean;
                            }),
                          )
                        }
                      >
                        {t('vehicleTask.vehicleClaimDamage.ifFront')}
                      </BalCheckbox>
                    </div>
                  </div>
                </div>
              </BalCard>
            )}
            {taskButtons && taskButtons}
          </form>
        </FormProvider>
      )}
    </div>
  );
};
