import { useEffect, useState } from 'react';
import {
  BalButton,
  BalText,
  BalCheckbox,
  BalSelect,
  BalSelectOption,
  BalFieldMessage,
  BalDatepicker,
} from '@baloise/design-system-components-react';
import { BalValidators } from '@baloise/web-app-validators';
import { Partner, AffectedPartner } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import {
  getDefaultAddressWithPartnerId,
  getDefaultCompany,
  getDefaultPerson,
} from '../../../utils/utilities';
import {
  PhoneInput,
  BalTextField,
  BalTypeAheadField,
  CountryList,
  BalTextFormField,
} from '../../../components/input';
import produce from 'immer';
import {
  Gender,
  Language,
  PartnerAddressDto,
  PartnerDto,
  PartnerRole,
  PreferedContact,
} from '../../../types/resource-models';
import { concat, Globals } from '../../../utils';
import _ from 'lodash';
import { motion } from 'framer-motion';
import DeleteContactModal from './delete-contact-modal';
import { balModalController } from '../../../controller/controllers';
import { CancelButton } from '../../../components/ui';

export interface CreateEditContactModalFormProps {
  onSave: (partner: Partner, role?: PartnerRole) => void;
  edit?: boolean;
  affectedPartnerToEdit?: AffectedPartner;
  addressOpen?: boolean;
  className?: string;
  textClassName?: string;
  onDelete?: (partner: Partner) => void;
  requiredPhoneOrMail?: boolean;
  customRoles?: PartnerRole[];
  detailSwitch?: boolean;
  hasPartnerNr?: boolean;
  hasGender?: boolean;
  hasLanguage?: boolean;
  hasPreferedContact?: boolean;
  hasAddress?: boolean;
  hasMobile?: boolean;
  hasCompanyNr?: boolean;
  hasBirthday?: boolean;
  hasRole?: boolean;
  hasFunction?: boolean;
  methodsProp?: UseFormReturn<FieldValues, object>;
  setPartnerProps?: React.Dispatch<Partner>;
  policyHolderId?: string;
}

const CreateEditContactForm = ({
  onSave,
  edit,
  affectedPartnerToEdit,
  addressOpen,
  onDelete,
  requiredPhoneOrMail: requiredPhoneOrMail,
  customRoles,
  detailSwitch,
  hasPartnerNr = false,
  hasGender = false,
  hasLanguage = false,
  hasPreferedContact = false,
  hasAddress = true,
  hasMobile = false,
  hasCompanyNr = false,
  hasBirthday = false,
  hasRole = false,
  hasFunction = false,
  methodsProp,
  setPartnerProps,
  policyHolderId,
}: CreateEditContactModalFormProps): JSX.Element => {
  const { t } = useTranslation();
  const partnerToEdit = affectedPartnerToEdit?.partner;
  const [partner, setPartner] = useState<Partner>(
    edit
      ? affectedPartnerToEdit?.partner ?? getDefaultPerson()
      : getDefaultPerson(),
  );
  const partnerId =
    partner.type === 'person' ? partner.person.id : partner.company.id;
  useEffect(() => {
    if (partnerToEdit) {
      setPartner(partnerToEdit);
    }
  }, [partnerToEdit]);

  const partnerAdress =
    partnerToEdit?.type === 'person'
      ? (partnerToEdit.person.address as PartnerAddressDto)
      : (partnerToEdit?.company.address as PartnerAddressDto);

  const [showAddress, setShowAdress] = useState<boolean>(
    ((edit && partnerAdress && partnerAdress.street != '') || addressOpen) &&
      hasAddress
      ? true
      : false,
  );

  const [address, setAddress] = useState<PartnerAddressDto>(
    edit && partnerAdress
      ? partnerAdress
      : getDefaultAddressWithPartnerId((partner as PartnerDto).id),
  );
  useEffect(() => {
    if (edit && partnerAdress) {
      setAddress(partnerAdress);
    }
  }, [partnerAdress, edit]);

  const [contactDeleted, setContactDeleted] = useState<boolean>(false);

  //use effect to close modal when contact deleted is true
  useEffect(() => {
    if (contactDeleted) {
      setContactDeleted(false);
    }
  }, [contactDeleted]);

  const localMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (setPartnerProps) {
      setPartnerProps(partner);
    }
  }, [partner, setPartnerProps]);

  const methods = methodsProp ?? localMethods;

  // Validations about eMail and Phone numbers are bounded.
  useEffect(() => {
    if (
      methods.getFieldState('phoneNumber').isDirty ||
      methods.getFieldState('email').isDirty
    ) {
      methods.trigger('phoneNumber');
      methods.trigger('email');
    }
  }, [methods, partner]);

  const { handleSubmit, control, formState, setError, clearErrors } = methods;

  const openDeleteModal = async () => {
    balModalController.dismiss();
    const modal = await balModalController.create({
      component: DeleteContactModal,
      componentProps: {
        onDelete: onDelete,
        affectedPartner: affectedPartnerToEdit,
        setContactDeleted: setContactDeleted,
      },
      cssClass: 'center-modal',
    });
    return modal.present();
  };
  const [role, setRole] = useState<PartnerRole | undefined>(
    affectedPartnerToEdit?.role,
  );

  const validateEmail = (value: string) =>
    BalValidators.isEmail()(value) ? true : t('validators.invalidEmail');
  useEffect(() => {
    if (
      !_.isEqual(
        address,
        getDefaultAddressWithPartnerId((partner as PartnerDto).id),
      )
    ) {
      if (partner.type === 'person') {
        setPartner(
          produce(partner, (draftState) => {
            draftState.person.address = address;
          }),
        );
      } else {
        setPartner(
          produce(partner, (draftState) => {
            draftState.company.address = address;
          }),
        );
      }
    }
  }, [address, partner]);

  return (
    <>
      <div className="mt-2 is-flex-direction-row is-flex is-justify-content-space-between is-full-width is-align-items-end">
        {!edit && (
          <div className="pb-4">
            <BalButton
              size="small"
              color="info"
              outlined={partner.type !== 'person'}
              onClick={(event) =>
                event.detail == 1 && setPartner(getDefaultPerson())
              }
            >
              {t('general.person.label')}
            </BalButton>
            <BalButton
              className="mx-3"
              size="small"
              color="info"
              outlined={partner.type !== 'company'}
              onClick={(event) =>
                event.detail == 1 && setPartner(getDefaultCompany())
              }
            >
              {t('general.company')}
            </BalButton>
          </div>
        )}
        {detailSwitch && (
          <BalCheckbox
            interface="switch"
            className="create-contact-address-switch"
            checked={showAddress}
            onBalChange={(e: CustomEvent<boolean>) => {
              setShowAdress(e.detail as boolean);
              if (!e.detail)
                setAddress(
                  getDefaultAddressWithPartnerId((partner as PartnerDto).id),
                );
            }}
          >
            {t('general.detailed')}
          </BalCheckbox>
        )}
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(async () => {
            await onSave(partner, role);
            balModalController.dismiss();
          })}
        >
          <div className="columns is-multiline">
            {hasFunction && (
              <div className="column is-full columns is-gapless m-0">
                {partner.type === 'person' ? (
                  <BalTextField
                    className="column is-full"
                    placeholder={t('general.function')}
                    value={partner.person.function}
                    onChange={(e: CustomEvent<string | undefined>) => {
                      if (e.detail || e.detail === '') {
                        setPartner(
                          produce(partner, (draftState) => {
                            draftState.person.function = e.detail;
                          }),
                        );
                      }
                    }}
                  />
                ) : (
                  <BalTextField
                    className="column"
                    placeholder={t('general.function')}
                    value={partner.company.function}
                    onChange={(e: CustomEvent<string | undefined>) => {
                      if (e.detail || e.detail === '') {
                        setPartner(
                          produce(partner, (draftState) => {
                            draftState.company.function = e.detail;
                          }),
                        );
                      }
                    }}
                  />
                )}
              </div>
            )}
            {hasRole && (
              <Controller
                name="role"
                control={control}
                defaultValue={role}
                rules={{
                  required: t('error.mandatoryField'),
                }}
                render={({ field, fieldState }) => (
                  <>
                    <BalTypeAheadField
                      className="column is-full column is-gapless mb-0 pb-0"
                      id="assignee"
                      label={t('general.role')}
                      result={customRoles ?? Globals.roles}
                      keyFormatter={(value) => value}
                      valueFormatter={(value) => t(`partnerRole.${value}`)}
                      value={role}
                      disabled={edit}
                      onChange={(choice) => {
                        setRole(choice);
                        field.onChange(choice);
                      }}
                    />
                    <div className="ml-4">
                      {fieldState && (
                        <BalFieldMessage color="danger">
                          {fieldState.error && fieldState.error.message}
                        </BalFieldMessage>
                      )}
                    </div>
                  </>
                )}
              />
            )}
            {partner.type === 'person'
              ? hasPartnerNr && (
                  <BalTextField
                    className="column is-full column is-gapless mb-0"
                    placeholder={t('general.partnerNumber')}
                    title={t('general.partnerNumber')}
                    value={partner.person.partnerNumber}
                    onChange={(e: CustomEvent<string | undefined>) => {
                      if (e.detail || e.detail === '') {
                        const value = e.detail;
                        setPartner(
                          produce(partner, (draftState) => {
                            draftState.person.partnerNumber = value;
                          }),
                        );
                      }
                    }}
                  />
                )
              : hasPartnerNr && (
                  <BalTextField
                    className="column is-full column is-gapless"
                    placeholder={t('general.partnerNumber')}
                    title={t('general.partnerNumber')}
                    value={partner.company.partnerNumber}
                    onChange={(e: CustomEvent<string | undefined>) => {
                      if (e.detail || e.detail === '') {
                        const value = e.detail;
                        setPartner(
                          produce(partner, (draftState) => {
                            draftState.company.partnerNumber = value;
                          }),
                        );
                      }
                    }}
                  />
                )}
            {hasGender && partner.type === 'person' && (
              <div className="column is-full columns is-gapless">
                <BalSelect
                  className="column mr-2"
                  value={partner.person.gender}
                  placeholder={t('genders.label')}
                  title={t('genders.label')}
                  onBalChange={(e) => {
                    if (e.detail || e.detail === '') {
                      const value = e.detail;
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.person.gender = value as Gender;
                        }),
                      );
                    }
                  }}
                >
                  {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
                  <div className="bal-select__inner">
                    {Globals.genders.map((gender) => (
                      <BalSelectOption
                        key={gender}
                        value={gender}
                        label={t(`genders.gender.${gender}`)}
                      >
                        {t(`genders.gender.${gender}`)}
                      </BalSelectOption>
                    ))}
                  </div>
                </BalSelect>
              </div>
            )}
            <div className="column is-full columns is-gapless m-0">
              {partner.type === 'person' ? (
                <BalTextFormField
                  className="column mr-4"
                  placeholder={t('general.person.firstName')}
                  title={t('general.person.firstName')}
                  controllerName="firstName"
                  control={control}
                  required={t('error.mandatoryField')}
                  value={partner.person.firstName}
                  onChange={(e: CustomEvent<string | undefined>) => {
                    if (e.detail || e.detail === '') {
                      const value = e.detail;
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.person.firstName = value;
                        }),
                      );
                    }
                  }}
                />
              ) : (
                <BalTextFormField
                  controllerName="name"
                  control={control}
                  required={t('error.mandatoryField')}
                  className="column"
                  placeholder={t('general.name')}
                  title={t('general.name')}
                  value={partner.company.name}
                  onChange={(e: CustomEvent<string | undefined>) => {
                    if (e.detail || e.detail === '') {
                      const value = e.detail;
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.company.name = value;
                        }),
                      );
                    }
                  }}
                />
              )}
              {partner.type === 'person' && (
                <BalTextFormField
                  controllerName="lastName"
                  control={control}
                  required={t('error.mandatoryField')}
                  className="column ml-4"
                  placeholder={t('general.person.lastName')}
                  title={t('general.person.lastName')}
                  value={partner.person.lastName}
                  onChange={(e: CustomEvent<string | undefined>) => {
                    if (e.detail || e.detail === '') {
                      const value = e.detail;
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.person.lastName = value;
                        }),
                      );
                    }
                  }}
                />
              )}
            </div>
            {partner.type === 'person' && hasBirthday && (
              <>
                <BalDatepicker
                  className="column is-full is-gapless"
                  placeholder={t('general.birthday')}
                  value={
                    partner.person.birthday
                      ? String(partner.person.birthday).slice(0, 10)
                      : undefined
                  }
                  onBalChange={(e: CustomEvent<string | undefined>) => {
                    if (e.detail || e.detail === '') {
                      const value = e.detail;
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.person.birthday = value;
                        }),
                      );
                    }
                  }}
                />
              </>
            )}
            <motion.div
              animate={
                showAddress
                  ? {
                      height: 'auto',
                      opacity: 1,
                    }
                  : {
                      height: 0,
                      opacity: 0,
                      paddingBottom: 0,
                      paddingTop: 0,
                    }
              }
              className="columns is-multiline mb-0"
            >
              {showAddress && (
                <>
                  <div className="columns column is-full m-0 pb-0">
                    <BalTextFormField
                      className="column is-10 mb-0"
                      controllerName="street"
                      control={control}
                      required={t('error.mandatoryField')}
                      placeholder={t('locationDetails.street')}
                      title={t('locationDetails.street')}
                      value={address.street}
                      onChange={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          setAddress(
                            produce(address, (draftState) => {
                              draftState.street = String(e.detail);
                            }),
                          );
                        }
                      }}
                    />
                    <BalTextFormField
                      className="column is-2"
                      controllerName="houseNumber"
                      control={control}
                      required={t('error.mandatoryField')}
                      placeholder={t('locationDetails.houseNumber')}
                      title={t('locationDetails.houseNumber')}
                      value={address.houseNumber}
                      onChange={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          setAddress(
                            produce(address, (draftState) => {
                              draftState.houseNumber = String(e.detail);
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="columns column is-full m-0 pb-0 pt-0">
                    <BalTextFormField
                      controllerName="zipCode"
                      control={control}
                      required={t('error.mandatoryField')}
                      className="column is-6"
                      placeholder={t('locationDetails.zipCode')}
                      title={t('locationDetails.zipCode')}
                      value={address.zipCode?.toString()}
                      onChange={(e: CustomEvent<string | undefined>) => {
                        setAddress(
                          produce(address, (draftState) => {
                            draftState.zipCode = (e.detail ??
                              0) as unknown as number;
                          }),
                        );
                      }}
                      pattern={
                        address.country === 'CH' ||
                        address.country === undefined ||
                        address.country === null ||
                        address.country === ''
                          ? {
                              value: /^[1-9][0-9]+$/,
                              message: t('validators.invalidZipcode'),
                            }
                          : undefined
                      }
                      maxLengthRule={
                        address.country === 'CH' ||
                        address.country === undefined ||
                        address.country === null ||
                        address.country === ''
                          ? {
                              value: 4,
                              message: t('validators.invalidZipcode'),
                            }
                          : undefined
                      }
                    />
                    <BalTextFormField
                      controllerName="place"
                      control={control}
                      required={t('error.mandatoryField')}
                      className="column is-6"
                      placeholder={t('locationDetails.place')}
                      title={t('locationDetails.place')}
                      value={address.city}
                      onChange={(e: CustomEvent<string | undefined>) => {
                        if (e.detail || e.detail === '') {
                          setAddress(
                            produce(address, (draftState) => {
                              draftState.city = String(e.detail);
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="columns column is-full pl-6 mb-0 pb-0">
                    <CountryList
                      controllerName="country"
                      methods={methods}
                      value={address.country}
                      requiredPlaceholder={true}
                      label={t('general.country')}
                      onChange={(choice) => {
                        setAddress(
                          produce(address, (draftState) => {
                            draftState.country = choice;
                          }),
                        );
                        if (
                          (choice === 'CH' ||
                            choice === undefined ||
                            choice === '') &&
                          address.zipCode &&
                          address.zipCode > 9999
                        ) {
                          setError('zipCode', {
                            type: 'manual',
                            message: t('validators.invalidZipcode'),
                          });
                        } else {
                          clearErrors('zipCode');
                          clearErrors('country');
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </motion.div>
            <PhoneInput
              name="phoneNumber"
              className="column is-full mb-0"
              control={control}
              value={
                partner.type === 'person'
                  ? partner.person.phoneNumber
                  : partner.company.phoneNumber
              }
              required={
                requiredPhoneOrMail
                  ? (partner.type === 'person' && partner.person.email) ||
                    (partner.type === 'company' && partner.company.email)
                    ? false
                    : t('error.mandatoryPhoneOrEmail')
                  : false
              }
              setValue={(value: string) => {
                if (partner.type === 'person') {
                  setPartner(
                    produce(partner, (draftState) => {
                      draftState.person.phoneNumber = value;
                    }),
                  );
                } else {
                  setPartner(
                    produce(partner, (draftState) => {
                      draftState.company.phoneNumber = value;
                    }),
                  );
                }
              }}
            />
            {hasMobile && partner.type === 'person' && (
              <PhoneInput
                name="mobilePhone"
                placeholder={t('general.mobile')}
                title={t('general.mobile')}
                className="column is-full"
                control={control}
                value={partner.person.mobileNumber ?? ''}
                setValue={(value: string) => {
                  if (partner.type === 'person') {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.person.mobileNumber = value;
                      }),
                    );
                  }
                }}
                required={false}
              />
            )}
            {hasCompanyNr && partner.type === 'person' && (
              <PhoneInput
                name="companyPhone"
                placeholder={t('general.phoneBusiness')}
                title={t('general.phoneBusiness')}
                className="column is-full"
                control={control}
                value={partner.person.companyNumber ?? ''}
                setValue={(value: string) => {
                  if (partner.type === 'person') {
                    setPartner(
                      produce(partner, (draftState) => {
                        draftState.person.companyNumber = value;
                      }),
                    );
                  }
                }}
                required={false}
              />
            )}
            <Controller
              name="email"
              control={control}
              defaultValue={
                partner.type === 'person'
                  ? partner.person.email
                  : partner.company.email
              }
              rules={{
                validate: validateEmail,
                required: requiredPhoneOrMail
                  ? (partner.type === 'person' && partner.person.phoneNumber) ||
                    (partner.type === 'company' && partner.company.phoneNumber)
                    ? false
                    : t('error.mandatoryPhoneOrEmail')
                  : false,
              }}
              render={({ field, fieldState }) => (
                <BalTextField
                  {...field}
                  className="column is-full"
                  placeholder={
                    requiredPhoneOrMail &&
                    ((partner.type === 'person' &&
                      !partner.person.phoneNumber) ||
                      (partner.type === 'company' &&
                        !partner.company.phoneNumber))
                      ? concat([t('general.email'), '*'])
                      : t('general.email')
                  }
                  title={t('general.email')}
                  value={
                    partner.type === 'person'
                      ? partner.person.email
                      : partner.company.email
                  }
                  onChange={(e: CustomEvent<string | undefined>) => {
                    if (e.detail || e.detail === '') {
                      const value = e.detail;
                      if (partner.type === 'person') {
                        setPartner(
                          produce(partner, (draftState) => {
                            draftState.person.email = value;
                          }),
                        );
                      } else {
                        setPartner(
                          produce(partner, (draftState) => {
                            draftState.company.email = value;
                          }),
                        );
                      }
                      field.onChange(value);
                    }
                  }}
                  message={fieldState.error && fieldState.error.message}
                />
              )}
            />
          </div>
          {hasLanguage && (
            <BalSelect
              className="column is-full p-0 mt-4"
              placeholder={t('language.label')}
              title={t('language.label')}
              value={
                partner.type === 'person'
                  ? partner.person.language
                  : partner.company.language
              }
              onBalChange={(e) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setPartner(
                    produce(partner, (draftState) => {
                      if (draftState.type === 'person')
                        draftState.person.language = value as Language;
                      else draftState.company.language = value as Language;
                    }),
                  );
                }
              }}
            >
              {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
              <div className="bal-select__inner">
                {Globals.languages.map((language) => (
                  <BalSelectOption
                    key={language}
                    value={language}
                    label={t(`language.languages.${language}`)}
                  >
                    {t(`language.languages.${language}`)}
                  </BalSelectOption>
                ))}
              </div>
            </BalSelect>
          )}
          {hasPreferedContact && (
            <BalSelect
              className="column is-full p-0 mt-4"
              placeholder={t('networkPartners.preferredContactMethod.label')}
              title={t('networkPartners.preferredContactMethod.label')}
              value={
                partner.type === 'person'
                  ? partner.person.preferedContact
                  : partner.company.preferedContact
              }
              onBalChange={(e) => {
                if (e.detail || e.detail === '') {
                  const value = e.detail;
                  setPartner(
                    produce(partner, (draftState) => {
                      if (draftState.type === 'person')
                        draftState.person.preferedContact =
                          value as PreferedContact;
                      else
                        draftState.company.preferedContact =
                          value as PreferedContact;
                    }),
                  );
                }
              }}
            >
              {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
              <div className="bal-select__inner">
                {Globals.preferredContactMethod.map((preferredContact) => (
                  <BalSelectOption
                    key={preferredContact}
                    value={preferredContact}
                    label={t(
                      `networkPartners.preferredContactMethod.preferredContact.${preferredContact}`,
                    )}
                  >
                    {t(
                      `networkPartners.preferredContactMethod.preferredContact.${preferredContact}`,
                    )}
                  </BalSelectOption>
                ))}
              </div>
            </BalSelect>
          )}
          {!methodsProp && (
            <div className="mt-3 is-flex is-justify-content-flex-end">
              {onDelete && (
                <div>
                  <BalButton
                    disabled={policyHolderId === partnerId}
                    elementType="button"
                    color={'danger'}
                    onClick={(event) => event.detail == 1 && openDeleteModal()}
                  >
                    {t('general.buttons.delete')}
                  </BalButton>
                  {policyHolderId === partnerId && affectedPartnerToEdit && (
                    <BalText size="small" color="danger">
                      {concat([
                        t(`partnerRole.${affectedPartnerToEdit?.role ?? ''}`),
                        t('createContact.cannotDeleteContact'),
                      ])}
                    </BalText>
                  )}
                </div>
              )}
              <CancelButton />
              <BalButton
                elementType="submit"
                color="primary"
                disabled={
                  !methodsProp &&
                  !formState.isValid &&
                  Object.keys(formState.errors).length !== 0
                }
                onClick={() => {
                  if (!showAddress) {
                    if (partner.type === 'person') {
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.person.address = undefined;
                        }),
                      );
                    } else {
                      setPartner(
                        produce(partner, (draftState) => {
                          draftState.company.address = undefined;
                        }),
                      );
                    }
                  }
                }}
              >
                {t('general.buttons.save')}
              </BalButton>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default CreateEditContactForm;
