import {
  BalButton,
  BalButtonGroup,
  BalCheckbox,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import BalSelectFormField from '../../../components/input/bal-select-form-field';
import { ErrorToast } from '../../../components/toast-notification';
import { RequestResult } from '../../../data';
import { useToken } from '../../../hooks';
import {
  AttachmentDto,
  PropertyInspectionTaskJournalDto,
  PropertyInspectionTaskJournalListDto,
  PropertyInspectionTaskJournalType,
} from '../../../types/resource-models';
import { AttachmenEntity, TranslatedSelection } from '../../../types/types';
import { BaseTaskAttachments } from '../../base-task';
import { deleteFile, FileUpload } from '../../files';
import {
  patchPropertyInspectionTaskJournal,
  postPropertyInspectionTaskJournal,
} from '../data/requests';
import { Globals } from '../../../utils';
import { JournalResearchType } from '../../../../../bat-shared/resource-models';
import lang from 'suneditor-react/dist/types/lang';
import {
  BalDatepickerFormField,
  BalTextField,
  BalTextareaFormField,
  BalNumberFormField,
} from '../../../components/input';
import { CancelButton } from '../../../components/ui';
import PropertyInspectionTaskJournalDeleteButton from './journal-toolbar/property-inspection-task-journal-delete-button';

export interface PropertyInspectionTaskJournalEditProps {
  initialValue: RequestResult<PropertyInspectionTaskJournalDto>;
  initialAddingState: boolean;
  language?: lang;
  readonly?: boolean;
  onSave?: (journalId?: string) => void;
  onDelete: (journal: PropertyInspectionTaskJournalListDto) => Promise<void>;
}
const PropertyInspectionTaskJournalEditForm = ({
  initialValue,
  initialAddingState,
  language,
  readonly,
  onSave,
  onDelete,
}: PropertyInspectionTaskJournalEditProps): JSX.Element => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [reportToAdos, setReportToAdos] = useState<boolean>(false);
  const [journal, setJournal] =
    useState<RequestResult<PropertyInspectionTaskJournalDto>>(initialValue);

  const [note, setNote] = useState<string | undefined>(
    initialValue?.status === 'success'
      ? initialValue.localValue.note
      : undefined,
  );

  const [addingState, setAddingState] = useState<boolean>(initialAddingState);
  const unorderedJournalTypes: TranslatedSelection[] =
    Globals.PropertyInspectionTaskJournalTypes.map((journalType) => ({
      value: journalType,
      translatedValue: t(`inspectionTask.journal.journalTypes.${journalType}`),
    }));

  const journalTypes: TranslatedSelection[] = unorderedJournalTypes.sort(
    (a, b) => (a.translatedValue > b.translatedValue ? 1 : 0),
  );

  const token = useToken();
  const removeFile = async (fileName: string) => {
    if (journal.status == 'success' && journal.localValue.attachments) {
      const attachmentToDelete = journal.localValue.attachments.find(
        (attachment: AttachmentDto) => attachment.fileName == fileName,
      );
      if (attachmentToDelete) {
        const result = await deleteFile(token, attachmentToDelete);
        if (
          result.status === 'success-no-value' ||
          result.status === 'success'
        ) {
          const newAttachments = journal.localValue.attachments.filter(
            (attachment: AttachmentDto) => attachment.fileName !== fileName,
          );
          setJournal(
            produce(journal, (draftState) => {
              draftState.localValue.attachments = newAttachments;
            }),
          );
        }
        if (result.status === 'error') {
          toast(ErrorToast(result.errorValue));
        }
      }
    }
  };

  const postJournal = async () => {
    if (journal.status === 'success') {
      const response = await postPropertyInspectionTaskJournal(
        journal.localValue,
        token,
        reportToAdos,
      );
      if (response.status === 'error') {
        toast(ErrorToast(response.errorValue));
      } else {
        setJournal(response);
        setAddingState(false);
      }
    }
  };

  const patchJournal = async () => {
    if (journal.status === 'success') {
      const response = await patchPropertyInspectionTaskJournal(
        journal.value,
        journal.localValue,
        token,
        reportToAdos,
      );
      if (response.status === 'error') {
        toast(ErrorToast(response.errorValue));
      }
    }
  };

  useEffect(() => {
    if (journal.status === 'success' && journal.localValue.note !== note) {
      setJournal(
        produce(journal, (draft) => {
          draft.localValue.note = note;
        }),
      );
    }
  }, [journal, note]);

  return (
    <>
      {journal.status === 'success' && (
        <form
          onSubmit={methods.handleSubmit(async () => {
            if (!saving) {
              setSaving(true);
              if (addingState) {
                await postJournal();
              } else {
                await patchJournal();
              }
              if (onSave) {
                onSave(journal.localValue?.id);
              }
              setSaving(false);
            }
          })}
        >
          <div className="rows is-multiline is-gapless is-full-width mt-1">
            <BalCheckbox
              disabled={readonly}
              className="pt-1 pb-4"
              interface="switch"
              checked={journal.localValue.confidential}
              onBalChange={(e) =>
                setJournal(
                  produce(journal, (draftState) => {
                    draftState.localValue.confidential = e.detail;
                  }),
                )
              }
            >
              {t('journal.confidential')}
            </BalCheckbox>
            <BalNumberFormField
              controllerName={'orderNumber'}
              methods={methods}
              required={t('error.mandatoryField')}
              title={t('inspectionTask.journal.orderNumber')}
              placeholder={t('inspectionTask.journal.orderNumber')}
              value={journal.localValue.orderNumber?.toString() ?? '0'}
              onChange={(e) => {
                if (e && e.detail) {
                  setJournal(
                    produce(journal, (draftState) => {
                      draftState.localValue.orderNumber =
                        e.detail as unknown as number;
                    }),
                  );
                }
              }}
            ></BalNumberFormField>
            <BalDatepickerFormField
              disabled={readonly}
              title={t('expense.createdAt')}
              className="mb-4"
              controllerName={'createdAt'}
              methods={methods}
              placeholder={t('expense.createdAt')}
              value={journal.localValue.createdAt.slice(0, 10)}
              onBalChange={(e: CustomEvent<null | string | undefined>) => {
                setJournal(
                  produce(journal, (draftState) => {
                    draftState.localValue.createdAt = e.detail ?? '';
                  }),
                );
              }}
            />
            <BalSelectFormField
              disabled={readonly}
              controllerName={'journalType'}
              methods={methods}
              className="row"
              value={journal.localValue.journalType}
              values={journalTypes}
              title={t('inspectionTask.journal.type')}
              placeholder={t('general.buttons.select')}
              alphabeticallySorted
              onChange={(e) => {
                setJournal(
                  produce(journal, (draftState) => {
                    if (e && e.detail) {
                      draftState.localValue.journalType =
                        e.detail as PropertyInspectionTaskJournalType;
                    }
                  }),
                );
              }}
            />
            {journal.localValue.journalType === 'PhoneEmail' && (
              <BalTextField
                title={t('inspectionTask.journal.contactPersonName')}
                value={journal.localValue.contactPersonName}
                onChange={(e) => {
                  if (e && e.detail) {
                    setJournal(
                      produce(journal, (draftState) => {
                        draftState.localValue.contactPersonName =
                          e.detail as JournalResearchType;
                      }),
                    );
                  }
                }}
              ></BalTextField>
            )}
            <div className="columns pb-4">
              <div className="column is-8">
                <BalTextareaFormField
                  disabled={readonly}
                  controllerName={'note'}
                  methods={methods}
                  richText
                  richTextHeight="500px"
                  language={language}
                  placeholder={t('inspectionTask.journal.note')}
                  title={t('inspectionTask.journal.note')}
                  value={note}
                  onChange={(text: string) => {
                    setNote(text);
                  }}
                />
              </div>
              <div className="column is-4">
                <div className="scroll-bar-minimal">
                  <BaseTaskAttachments
                    disabled={readonly}
                    maximumHeight="370px"
                    attachments={journal.localValue.attachments}
                    removeFile={removeFile}
                  />
                </div>
                <div>
                  <FileUpload
                    disabled={readonly || !methods.formState.isValid || saving}
                    entityType="propertyInspectionTaskJournal"
                    className="pb-0"
                    entity={journal as RequestResult<AttachmenEntity>}
                    setEntity={
                      setJournal as Dispatch<RequestResult<AttachmenEntity>>
                    }
                    hasFileList={false}
                    copyFromClipboard={true}
                    beforePostFile={async () => {
                      if (addingState) {
                        await postJournal();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <BalButtonGroup position="right">
              {!initialAddingState && (
                <PropertyInspectionTaskJournalDeleteButton
                  currentJournal={
                    initialValue.status === 'success'
                      ? initialValue.value
                      : undefined
                  }
                  onDeleteJournal={onDelete}
                  textButton
                />
              )}
              <CancelButton />
              <BalButton
                elementType="submit"
                color="info"
                disabled={readonly || !methods.formState.isValid || saving}
                onClick={async () => {
                  setReportToAdos(false);
                }}
              >
                {t('general.buttons.save')}
              </BalButton>
              <BalButton
                elementType="submit"
                color="info"
                disabled={readonly || !methods.formState.isValid || saving}
                onClick={async () => {
                  setReportToAdos(true);
                }}
              >
                Speichern und Adossieren
              </BalButton>
            </BalButtonGroup>
          </div>
        </form>
      )}
    </>
  );
};

export default PropertyInspectionTaskJournalEditForm;
