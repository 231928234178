import { Claim } from '../../../../types/types';
import { Translation } from 'react-i18next';
import { InfoDisplay } from '../../../../components/ui';
import { BalHeading } from '@baloise/design-system-components-react';
import { formatDateTime } from '../../../../utils/date';
import { concatAddress } from '../../../../utils';
import { useDescriptionOfBaloiseCode } from '../../../user/data/hooks';

type ClaimInfoProps = {
  claim: Claim;
};

export const BaseTaskClaimInfo = ({ claim }: ClaimInfoProps): JSX.Element => {
  const causeDescription = useDescriptionOfBaloiseCode(claim.cause);
  const kindDescription = useDescriptionOfBaloiseCode(claim.kind);
  return (
    <Translation>
      {(t) => {
        return (
          <>
            <BalHeading level="h5" space="none">
              {t('overlay.claimInformation')}
            </BalHeading>
            <InfoDisplay
              title={t('general.branch')}
              text={t(`branch.${claim.branch}`)}
              className="pb-1"
            />
            {claim.majorEventNumber ? (
              <InfoDisplay
                title={t('overlay.majorEvent')}
                text={claim.majorEventNumber.toString()}
                className="pb-1"
              />
            ) : (
              <></>
            )}
            <InfoDisplay
              title={t('overlay.policyNumber')}
              text={claim.policyNumber}
              className="pb-1"
            />
            <InfoDisplay
              title={t('overlay.date')}
              text={formatDateTime(claim.claimDate)}
              className="pb-1"
            />
            <InfoDisplay
              title={t('overlay.place')}
              text={concatAddress(claim.claimAddress)}
              className="pb-1"
            />
            <InfoDisplay
              title={t('overlay.insuranceType')}
              text={t(`insuranceType.${claim.insuranceType}`)}
              className="pb-1"
            />

            {claim.cause && (
              <InfoDisplay
                title={t('overlay.claimCause')}
                text={causeDescription}
                className="pb-1"
              />
            )}
            {claim.kind && (
              <InfoDisplay
                title={t('overlay.claimKind')}
                text={kindDescription}
                className="pb-1"
              />
            )}
          </>
        );
      }}
    </Translation>
  );
};
