import {
  BalButton,
  BalHeading,
  BalIcon,
  BalModalBody,
  BalModalHeader,
  BalText,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../../controller/controllers';
import { BaseTask, TaskTypes, TabType } from '../../../types/types';
import { useNavigate } from 'react-router';
import { RequestResult } from '../../../data';
import {
  BaseTaskListDto,
  VehicleTaskListDto,
} from '../../../../../bat-shared/resource-models';
import { AgGridReact } from 'ag-grid-react';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { formatDateTime } from '../../../utils/date';
import {
  renderBaseTaskAssigneeAvatar,
  renderBaseTaskStatusTag,
} from '../../../utils/utilities';
import { EBRoutes } from '../../../router/router';
import { balIconSearch } from '@baloise/design-system-icons/src';
import { getAssigneePerTypeTranslated } from '../../../utils';

const VehicleTaskExistingClaimModal = ({
  className,
  tasksResponse,
  onCreateNew,
  onEditTask,
  setTab,
}: {
  className?: string;
  tasksResponse: BaseTaskListDto[];
  onCreateNew(task?: RequestResult<BaseTask>, claimNumber?: string): void;
  onEditTask: (taskId: string) => Promise<void>;
  setTab: (tabType?: TabType) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getGridOptions = (): GridOptions => {
    return {
      defaultColDef: {
        sortable: false,
        filter: false,
        autoHeight: false,
      },
      overlayNoRowsTemplate: '&nbsp;',
      rowHeight: 40,
      columnDefs: [
        {
          valueGetter: (params) => {
            return (params.data as BaseTaskListDto).createdAt;
          },
          valueFormatter: (params) => {
            const date = params.value;
            return formatDateTime(date);
          },
          field: 'createdAt',
          headerName: t('taskOverview.createdAt'),
          width: 150,
        },
        {
          cellRenderer: 'avatarRenderer',
          field: 'claimDetail.assignee',
          headerName: getAssigneePerTypeTranslated(TaskTypes.VehicleTask),
          width: 300,
        },
        {
          cellRenderer: 'tagRenderer',
          field: 'status',
          headerName: t('general.claim.status'),
          width: 125,
        },
        {
          cellRenderer: 'actionButtonRenderer',
          sortable: false,
          width: 135,
          minWidth: 95,
          flex: 1,
          resizable: false,
        },
      ],
      components: {
        avatarRenderer: renderBaseTaskAssigneeAvatar,
        tagRenderer: renderBaseTaskStatusTag,
        actionButtonRenderer: function renderEdit(params: ICellRendererParams) {
          const currentTask: VehicleTaskListDto = params.data;
          return (
            <div className="mr-5 p-0">
              {currentTask.status === 'New' ? (
                <>
                  <BalButton
                    color="danger"
                    size="small"
                    square
                    icon="trash"
                    outlined
                    className="mt-2"
                    title={t(
                      'inspectionTask.inspectionTaskWorkflow.deleteAndCreateNew',
                    )}
                    onClick={(event) => {
                      if (event.detail == 1) {
                        const baseTask = currentTask as unknown as BaseTask;
                        onCreateNew(
                          {
                            status: 'success',
                            value: baseTask,
                            localValue: baseTask,
                          },
                          baseTask.claim.claimNumber,
                        );
                        balModalController.dismiss();
                      }
                    }}
                  ></BalButton>

                  <BalButton
                    color="info"
                    className="ml-2 mt-2"
                    size="small"
                    square
                    icon="edit"
                    outlined
                    title={t('inspectionTask.inspectionTaskWorkflow.edit')}
                    onClick={async (event) => {
                      if (event.detail == 1) {
                        await onEditTask(currentTask.id);
                        setTab('contactInformation');
                        balModalController.dismiss();
                      }
                    }}
                  ></BalButton>
                </>
              ) : (
                <>
                  {currentTask.kind == 'VehicleInspectionTask' && (
                    <>
                      <BalButton
                        color="danger"
                        size="small"
                        square
                        icon="close"
                        outlined
                        className="ml-1 mt-2"
                        title={t(
                          'inspectionTask.inspectionTaskWorkflow.closeAndCreateNew',
                        )}
                        onClick={(event) => {
                          if (event.detail == 1) {
                            const baseTask = currentTask as unknown as BaseTask;
                            onCreateNew(
                              {
                                status: 'success',
                                value: baseTask,
                                localValue: baseTask,
                              },
                              baseTask.claim.claimNumber,
                            );
                            balModalController.dismiss();
                          }
                        }}
                      ></BalButton>
                      <BalButton
                        color="info"
                        className="ml-2 mt-2 "
                        size="small"
                        square
                        outlined
                        title={t(
                          'inspectionTask.inspectionTaskWorkflow.toDetailView',
                        )}
                        onClick={(event) => {
                          if (event.detail == 1) {
                            const route =
                              EBRoutes.VEHICLE_INSPECTION_TASK_DETAIL.create({
                                taskId: currentTask.id,
                              });
                            navigate(route, { replace: true });
                            navigate(0);
                            balModalController.dismiss();
                          }
                        }}
                      >
                        <BalIcon svg={balIconSearch}></BalIcon>
                      </BalButton>
                    </>
                  )}
                </>
              )}
            </div>
          );
        },
      },
    };
  };

  return tasksResponse ? (
    <div className={className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {t('inspectionTask.claimInformation.existingBaseTaks')}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>
          {t('vehicleTask.duplicatedTaskMessage', {
            claimNumber: tasksResponse[0].claim.claimNumber,
          })}
        </BalText>
        <div className="task-table-modal pt-4">
          <AgGridReact
            suppressCellFocus={true}
            rowSelection={'single'}
            rowData={tasksResponse}
            gridOptions={getGridOptions()}
          />
        </div>
        <div className="mt-6 is-flex is-full-width is-justify-content-space-between">
          <>
            <BalButton
              elementType="button"
              color="primary-light"
              onClick={(event) => {
                event.detail == 1 && balModalController.dismiss();
              }}
            >
              {t('general.buttons.cancel')}
            </BalButton>
            <BalButton
              color="info"
              onClick={(event) => {
                if (event.detail == 1) {
                  onCreateNew(undefined, tasksResponse[0].claim.claimNumber);
                  balModalController.dismiss();
                }
              }}
            >
              {t('inspectionTask.inspectionTaskWorkflow.ignoreAndCreateNew')}
            </BalButton>
          </>
        </div>
      </BalModalBody>
    </div>
  ) : (
    <></>
  );
};

export default VehicleTaskExistingClaimModal;
