import {
  BalHeading,
  BalField,
  BalFieldLabel,
  BalFieldControl,
  BalButton,
  BalIcon,
} from '@baloise/design-system-components-react';
import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppCapabilityNames,
  BaseTaskFilterCriteria,
  BaseTaskStatus,
  Branch,
  InsuranceType,
  PropertyInspectionTaskCause,
  PropertyInspectionTaskFilterCriteriaDto,
  UserDto,
  UserMenuItemDto,
  UserTableTypes,
  VehicleTaskFilterCriteriaDto,
  VehicleTaskKind,
} from '../../../../types/resource-models';
import {
  BaseTaskFilterTaskScreen,
  BaseTaskStatusGroupedFilter,
  TaskTypes,
} from '../../../../types/types';
import {
  capitalize,
  getAssigneePerTypeTranslated,
  Globals,
} from '../../../../utils';
import filterIcon from '../../../../assets/icons/filter.svg';
import { QueryParamDefault, Route } from 'react-route-type';
import { useToken } from '../../../../hooks';
import { CombinedState } from '../..';
import { useDropdownUsers } from '../../../user';
import { RequestResult } from '../../../../data';
import {
  DisabableButton,
  WithCapabilities,
} from '../../../../components/authorization';
import {
  BalTypeAheadField,
  BalTextField,
  DateRangePicker,
} from '../../../../components/input';
import { Icon } from '../../../../components/ui';
import { head } from 'lodash';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../../components/toast-notification';
import { getBaseTasksExport } from '../../data/requests';
import CreateCustomUserMenuItem from '../../../user/components/create-custom-user-menu-item';
import { balModalController } from '../../../../controller/controllers';
import { UserContext } from '../../../../context';
import { getDisplayNameFromUser } from '../../../../utils/utilities';
import BalMultipleTypeAheadField from '../../../../components/input/bal-multiple-type-ahead-field';
import { InvestigationTaskStatus } from '../../../../../../bat-shared/resource-models';
import VehicleTaskWizardSelect from '../../../vehicle-task/components/vehicle-task-wizard-select';
import { balIconDownload } from '@baloise/design-system-icons';
import { useNavigate } from 'react-router';
import { postShowFiltersUserTableSettings } from '../../../user/data/requests';
import { convertUtcToFormat } from '../../../../utils/date';
import { diff } from 'just-diff';

type AllowedState = BaseTaskStatusGroupedFilter | InvestigationTaskStatus;
interface IProps {
  combinedState: CombinedState;
  setCombinedState: React.Dispatch<React.SetStateAction<CombinedState>>;
  inProgressStates: BaseTaskStatus[];
  editorAppCapabilityNames: AppCapabilityNames[] | undefined;
  creatorsAppCapabilityNames: AppCapabilityNames[] | undefined;
  claimNumberPlaceHolder: string | undefined;
  createWizardRoute: Route<string, QueryParamDefault>;
  allStates: AllowedState[];
  allBranches: Branch[];
  taskType: TaskTypes;
  editFilter: boolean;
}

interface TimedSearchFields {
  claimNumber?: string;
  policyNumber?: string;
  location?: string;
  streetAndNumber?: string;
  policyHolderName?: string;
}

export const BaseTaskSearchFormFields = ({
  combinedState,
  setCombinedState,
  inProgressStates,
  editorAppCapabilityNames,
  creatorsAppCapabilityNames,
  claimNumberPlaceHolder,
  createWizardRoute,
  allStates,
  allBranches,
  taskType,
  editFilter,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const [showFilters, setShowFilters] = useState(
    window.outerHeight < 1000 ? false : true,
  );

  // Timed filters management.
  const [defaultFilter, setDefaultFilter] = useState<BaseTaskFilterTaskScreen>(
    combinedState.defaultFilter,
  );
  const [timedSearchFields, setTimedSearchField] = useState<TimedSearchFields>({
    claimNumber: defaultFilter.claimNumber,
    policyNumber: defaultFilter.policyNumber,
    location: defaultFilter.location,
    streetAndNumber: defaultFilter.streetAndNumber,
    policyHolderName: defaultFilter.policyHolderName,
  });
  useEffect(() => {
    if (diff(defaultFilter, combinedState.defaultFilter).length > 0) {
      setDefaultFilter(combinedState.defaultFilter);
      const newTimedSearchField = {
        claimNumber: combinedState.defaultFilter.claimNumber,
        policyNumber: combinedState.defaultFilter.policyNumber,
        location: combinedState.defaultFilter.location,
        streetAndNumber: combinedState.defaultFilter.streetAndNumber,
        policyHolderName: combinedState.defaultFilter.policyHolderName,
      };
      setTimedSearchField(newTimedSearchField);
    }
  }, [combinedState, defaultFilter]);
  // Timed filters management End.

  useEffect(() => {
    if (user.status === 'success') {
      const userTableSetting = user.localValue.userTableSettings.find(
        (x) => x.type === TaskTypes[taskType].toString(),
      );
      if (userTableSetting) {
        setShowFilters(userTableSetting.showFilters);
      }
    }
  }, [user, taskType]);

  const inspectors = useDropdownUsers({
    appCapabilityNames: editorAppCapabilityNames,
  });

  const [searchInspectors, setSearchInspectors] = useState<UserDto[]>([]);
  useEffect(() => {
    if (inspectors?.status === 'success') {
      const newSearchInspectors: UserDto[] = [];
      newSearchInspectors.push(Globals.UnassignedUserDto);
      newSearchInspectors[0].firstName = t('general.none');
      inspectors.value.map((inspector) => newSearchInspectors.push(inspector));
      setSearchInspectors(newSearchInspectors);
    }
  }, [inspectors, setSearchInspectors, t]);

  const [unfinishedInspectionReport, setUnfinishedInspectionReport] = useState<
    boolean | undefined
  >(undefined);
  useEffect(() => {
    const currentInfinishedInspectionReport =
      (combinedState.defaultFilter as PropertyInspectionTaskFilterCriteriaDto)
        .unfinishedInspectionsOnly ??
      (combinedState.filter as PropertyInspectionTaskFilterCriteriaDto)
        .unfinishedInspectionsOnly;
    setUnfinishedInspectionReport(currentInfinishedInspectionReport);
  }, [combinedState]);

  const creators = useDropdownUsers({
    appCapabilityNames: creatorsAppCapabilityNames,
  });

  const convertToGroupedStatusFilterList = (
    filteredStatusList: BaseTaskStatus[] | undefined,
  ) => {
    const result =
      filteredStatusList === undefined
        ? allStates
        : allStates.filter((status: AllowedState) =>
            filteredStatusList.some((filteredStatus) =>
              filteredStatus.startsWith(status),
            ),
          );
    return result;
  };

  const convertFromGroupedStatusFilterList = (
    groupedStatusList: AllowedState[] | undefined,
  ) => {
    const result: BaseTaskStatus[] = [];
    groupedStatusList?.forEach((groupedStatus: AllowedState) => {
      if (groupedStatus.startsWith('InProgress')) {
        inProgressStates.forEach((inProgressState: BaseTaskStatus) => {
          result.push(inProgressState);
        });
      } else {
        result.push(groupedStatus as BaseTaskStatus);
      }
    });
    return result;
  };

  const getGroupedStatusFilterList = (
    filteredStatusList: BaseTaskStatus[] | undefined,
  ) => {
    const availableStatusList =
      convertToGroupedStatusFilterList(filteredStatusList);

    const returnValue: RequestResult<AllowedState[]> = {
      status: 'success',
      value: availableStatusList,
      localValue: availableStatusList,
    };

    return returnValue;
  };
  const groupedStatusFilterList = getGroupedStatusFilterList(
    combinedState.defaultFilter.status,
  );

  const token = useToken();

  const [downloadingExportTaskFile, setDownloadingExportTaskFile] =
    useState(false);

  const onCreatedMenuItem = (userMenuItemDto: UserMenuItemDto) => {
    if (user.status === 'success') {
      setUser(
        produce(user, (draftState) => {
          draftState.value.userMenuItems.push(userMenuItemDto);
          draftState.localValue.userMenuItems.push(userMenuItemDto);
        }),
      );
    }
  };

  const openFilterSaveDialog = async () => {
    const modal = await balModalController.create({
      component: CreateCustomUserMenuItem,
      componentProps: {
        filter: combinedState.filter.assigneeOrCreatorUserId
          ? {
              ...combinedState.filter,
              assigneeUserId: [combinedState.filter.assigneeOrCreatorUserId],
              creatorUserId: [combinedState.filter.assigneeOrCreatorUserId],
              assigneeOrCreatorUserId: undefined,
            }
          : { ...combinedState.filter, assigneeOrCreatorUserId: undefined },
        type: TaskTypes[taskType],
        onCreated: onCreatedMenuItem,
        backdropDismiss: false,
      },
      cssClass: 'center-modal',
    });
    return modal.present();
  };

  const currentMenuItemIsCustom = (): boolean => {
    if (user.status === 'success') {
      const customMenuItem = user.value.userMenuItems.find(
        (x) => x.isCustomFilter && x.label === combinedState.label,
      );
      return customMenuItem !== undefined;
    } else {
      return false;
    }
  };
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const onClaimNumberSearchDelay = (value: string) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.tasks.status === 'success') {
          draftState.tasks.status = 'loading-with-value';
        }
        draftState.filter.claimNumber = value;
        draftState.filter.page = 0;
      }),
    );
  };

  const onPolicyNumberSearchDelay = (value: string) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.tasks.status === 'success') {
          draftState.tasks.status = 'loading-with-value';
        }
        draftState.filter.policyNumber = value;
        draftState.filter.page = 0;
      }),
    );
  };

  const onLocationSearchDelay = (value: string) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.tasks.status === 'success') {
          draftState.tasks.status = 'loading-with-value';
        }
        draftState.filter.location = value;
        draftState.filter.page = 0;
      }),
    );
  };
  const onStreetAndNumberSearchDelay = (value: string) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.tasks.status === 'success') {
          draftState.tasks.status = 'loading-with-value';
        }
        draftState.filter.streetAndNumber = value;
        draftState.filter.page = 0;
      }),
    );
  };

  const onPolicyHolderNameSearchDelay = (value: string) => {
    setCombinedState(
      produce(combinedState, (draftState) => {
        if (combinedState.tasks.status === 'success') {
          draftState.tasks.status = 'loading-with-value';
        }
        draftState.filter.policyHolderName = value;
        draftState.filter.page = 0;
      }),
    );
  };

  const vehicleTaskInsuranceTypes: InsuranceType[] = [
    'MotorVehicleLiability',
    'MotorVehicleCollisionCoverage',
    'MotorVehiclePartialCoverage',
    'MotorVehicleFullCoverage',
    'MotorVehicleLegalProtection',
  ];

  const [insuranceTypes, setInsuranceTypes] = useState<
    InsuranceType[] | undefined
  >(undefined);

  useEffect(() => {
    if (combinedState.defaultFilter.insuranceType && !editFilter) {
      const flatDefaultFilterInsuranceType =
        combinedState.defaultFilter.insuranceType?.join(',');
      const flatInsuranceTypes = insuranceTypes?.join(',');
      if (flatDefaultFilterInsuranceType !== flatInsuranceTypes) {
        setInsuranceTypes(combinedState.defaultFilter.insuranceType);
      }
    } else {
      if (!combinedState.filter.insuranceType) {
        setInsuranceTypes(undefined);
      }
    }
  }, [insuranceTypes, setCombinedState, combinedState, editFilter]);

  const navigate = useNavigate();
  const navigateTo = (url: string): void => {
    navigate(url);
  };

  const [vehicleTaskKinds, setVehicleTaskKinds] = useState<
    VehicleTaskKind[] | undefined
  >(undefined);

  useEffect(() => {
    if (combinedState.defaultFilter.vehicleTaskKinds) {
      const flatDefaultFiltervehicleTaskKinds =
        combinedState.defaultFilter.vehicleTaskKinds?.join(',');
      const flatvehicleTaskKinds = vehicleTaskKinds?.join(',');
      if (flatDefaultFiltervehicleTaskKinds !== flatvehicleTaskKinds) {
        setVehicleTaskKinds(combinedState.defaultFilter.vehicleTaskKinds);
      }
    } else {
      if (!combinedState.filter.vehicleTaskKinds) {
        setVehicleTaskKinds(undefined);
      }
    }
  }, [vehicleTaskKinds, setCombinedState, combinedState]);

  const getVehicleTaskKinds = () => {
    return combinedState.defaultFilter.assigneeUserId?.length === 1 &&
      combinedState.defaultFilter.assigneeUserId[0] ===
        '00000000-0000-0000-0000-000000000000'
      ? Globals.VehicleTaskKinds.filter((x) => x !== 'VehicleInspectionTask')
      : Globals.VehicleTaskKinds;
  };

  return (
    <div className="p-5 mb-4">
      <div className="columns is-vcentered">
        <BalHeading
          space="none"
          className="column has-text-black pt-5 pb-0"
          level="h1"
        >
          {combinedState.label}
        </BalHeading>
        <BalButton
          color="primary-light"
          size="small"
          square
          outlined
          disabled={downloadingExportTaskFile}
          onClick={async () => {
            setDownloadingExportTaskFile(true);
            getBaseTasksExport(
              token,
              combinedState.filter as BaseTaskFilterCriteria,
              taskType,
            ).then((fileResponse) => {
              setDownloadingExportTaskFile(false);
              if (fileResponse.status === 'success') {
                const a = document.createElement('a');
                a.href = fileResponse.value;
                a.download = `${TaskTypes[taskType]}ExportFile.xlsx`;
                a.click();
              } else if (fileResponse.status === 'error') {
                toast(ErrorToast(fileResponse.errorValue));
              }
            });
          }}
        >
          <BalIcon svg={balIconDownload} size="small" />
        </BalButton>
        <BalButton
          className="ml-2"
          color="primary-light"
          size="small"
          square
          outlined
          onClick={async (event) => {
            if (!showFilters && event.detail == 1) {
              const element = document.getElementById('filters');
              element?.classList.add('filters-menu-out-194px');
              element?.classList.remove('filters-menu-194px');
              setTimeout(async () => {
                setShowFilters(true);
                await postShowFiltersUserTableSettings(
                  true,
                  TaskTypes[taskType].toString() as UserTableTypes,
                  user,
                  setUser,
                  token,
                );
              }, 350);
            } else if (event.detail == 1) {
              setShowFilters(false);
              await postShowFiltersUserTableSettings(
                false,
                TaskTypes[taskType].toString() as UserTableTypes,
                user,
                setUser,
                token,
              );
            }
          }}
        >
          <Icon iconPath={filterIcon} />
        </BalButton>
        <WithCapabilities
          requiredCapabilities={creatorsAppCapabilityNames ?? []}
          passWithCapabilitiesPropsToChildren
        >
          <DisabableButton
            className="ml-4"
            color="primary"
            icon="plus"
            onClick={async () => {
              if (taskType === TaskTypes.VehicleTask) {
                const modal = await balModalController.create({
                  component: VehicleTaskWizardSelect,
                  componentProps: { navigateTo },
                  cssClass: 'center-modal',
                });
                return modal.present();
              } else {
                navigateTo(createWizardRoute.create());
              }
            }}
          >
            {t('general.buttons.createTask')}
          </DisabableButton>
        </WithCapabilities>
      </div>
      {showFilters && (
        <div
          className="filters-menu-194px columns is-gapless is-multiline pr-4"
          id="filters"
        >
          <div className="column is-10">
            <div className="grid-auto-fit">
              <DateRangePicker
                disabled={
                  (!!combinedState.defaultFilter.createdAtFrom ||
                    !!combinedState.defaultFilter.createdAtTo) &&
                  !editFilter
                }
                title={t('taskOverview.createdAt')}
                fromValue={combinedState.filter.createdAtFrom}
                toValue={combinedState.filter.createdAtTo}
                setBothDates={(
                  from: string | undefined,
                  to: string | undefined,
                ) => {
                  setCombinedState(
                    produce(combinedState, (draftState) => {
                      if (combinedState.tasks.status === 'success') {
                        draftState.tasks.status = 'loading-with-value';
                      }
                      draftState.filter.createdAtFrom = from;
                      draftState.filter.createdAtTo = to;
                      draftState.filter.page = 0;
                    }),
                  );
                }}
                onFromChange={(e: CustomEvent<null | string | undefined>) => {
                  if (e.detail || e.detail === '') {
                    const value = e.detail;
                    setCombinedState(
                      produce(combinedState, (draftState) => {
                        if (combinedState.tasks.status === 'success') {
                          draftState.tasks.status = 'loading-with-value';
                        }
                        draftState.filter.createdAtFrom = value;
                        draftState.filter.page = 0;
                      }),
                    );
                  }
                }}
                onToChange={(e: CustomEvent<null | string | undefined>) => {
                  if (e.detail || e.detail === '') {
                    const value = e.detail;
                    setCombinedState(
                      produce(combinedState, (draftState) => {
                        if (combinedState.tasks.status === 'success') {
                          draftState.tasks.status = 'loading-with-value';
                        }
                        draftState.filter.createdAtTo = value;
                        draftState.filter.page = 0;
                      }),
                    );
                  }
                }}
              />
              <DateRangePicker
                disabled={
                  (!!combinedState.defaultFilter.claimDateFrom ||
                    !!combinedState.defaultFilter.claimDateTo) &&
                  !editFilter
                }
                title={
                  taskType == TaskTypes.AccidentInspectionTask ||
                  taskType == TaskTypes.VehicleTask
                    ? t('taskOverview.accidentDate')
                    : t('overlay.date')
                }
                fromValue={combinedState.filter.claimDateFrom}
                toValue={combinedState.filter.claimDateTo}
                setBothDates={(
                  from: string | undefined,
                  to: string | undefined,
                ) => {
                  setCombinedState(
                    produce(combinedState, (draftState) => {
                      if (combinedState.tasks.status === 'success') {
                        draftState.tasks.status = 'loading-with-value';
                      }
                      draftState.filter.claimDateFrom = from;
                      draftState.filter.claimDateTo = to;
                      draftState.filter.page = 0;
                    }),
                  );
                }}
                onFromChange={(e: CustomEvent<null | string | undefined>) => {
                  if (e.detail || e.detail === '') {
                    const value = e.detail;
                    setCombinedState(
                      produce(combinedState, (draftState) => {
                        if (combinedState.tasks.status === 'success') {
                          draftState.tasks.status = 'loading-with-value';
                        }
                        draftState.filter.claimDateFrom = value;
                        draftState.filter.page = 0;
                      }),
                    );
                  }
                }}
                onToChange={(e: CustomEvent<null | string | undefined>) => {
                  if (e.detail || e.detail === '') {
                    const value = e.detail;
                    setCombinedState(
                      produce(combinedState, (draftState) => {
                        if (combinedState.tasks.status === 'success') {
                          draftState.tasks.status = 'loading-with-value';
                        }
                        draftState.filter.claimDateTo = value;
                        draftState.filter.page = 0;
                      }),
                    );
                  }
                }}
              />
              <BalTextField
                disabled={
                  !!combinedState.defaultFilter.claimNumber && !editFilter
                }
                className="base-task-search-form-fields"
                titleClassName="mb-1px"
                mask="claim-number"
                title={capitalize(t('general.claim.claimNumber'))}
                placeholder={claimNumberPlaceHolder}
                maxLength={14}
                value={timedSearchFields.claimNumber}
                onInput={(e: CustomEvent<string | undefined>) => {
                  clearTimeout(timer);
                  const newTimer = setTimeout(() => {
                    onClaimNumberSearchDelay(e.detail ?? '');
                  }, 500);
                  setTimer(newTimer);

                  setTimedSearchField({
                    ...timedSearchFields,
                    claimNumber: e.detail,
                  });
                }}
              />
              <BalTextField
                disabled={
                  !!combinedState.defaultFilter.policyNumber && !editFilter
                }
                className="base-task-search-form-fields"
                titleClassName="mb-1px disable-line-break"
                title={capitalize(t('claimDetail.policyNumber'))}
                maxLength={14}
                placeholder={capitalize(t('claimDetail.policyNumber'))}
                value={timedSearchFields.policyNumber}
                onInput={(e: CustomEvent<string | undefined>) => {
                  clearTimeout(timer);
                  const newTimer = setTimeout(() => {
                    onPolicyNumberSearchDelay(e.detail ?? '');
                  }, 500);
                  setTimer(newTimer);

                  setTimedSearchField({
                    ...timedSearchFields,
                    policyNumber: e.detail,
                  });
                }}
              />
              {allBranches.length > 1 && (
                <BalField className="base-task-search-form-fields">
                  <BalFieldLabel className="mb-1px">
                    {t('general.branch')}
                  </BalFieldLabel>
                  <BalFieldControl>
                    <BalTypeAheadField
                      blocker={
                        !!combinedState.defaultFilter.branch && !editFilter
                      }
                      disabled={
                        !!combinedState.defaultFilter.branch && !editFilter
                      }
                      className="is-full-width"
                      placeholder={t('general.branch')}
                      value={head(combinedState.filter.branch)}
                      result={allBranches}
                      valueFormatter={(value) => {
                        return t(`branch.${value}`);
                      }}
                      keyFormatter={(value) => value?.toString()}
                      onChange={(choice) => {
                        setCombinedState(
                          produce(combinedState, (draftState) => {
                            if (combinedState.tasks.status === 'success') {
                              draftState.tasks.status = 'loading-with-value';
                            }
                            draftState.filter.branch =
                              choice === null ? undefined : [choice];
                            draftState.filter.page = 0;
                          }),
                        );
                      }}
                    />
                  </BalFieldControl>
                </BalField>
              )}
              {creators?.status === 'success' && (
                <BalField className="base-task-search-form-fields">
                  <BalFieldLabel className="mb-1px">
                    {capitalize(t('general.claim.creator'))}
                  </BalFieldLabel>
                  <BalFieldControl>
                    <BalMultipleTypeAheadField
                      disabled={
                        ((combinedState.defaultFilter.creatorUserId &&
                          combinedState.defaultFilter.creatorUserId?.length >
                            0 &&
                          currentMenuItemIsCustom()) ||
                          (!!combinedState.defaultFilter
                            .assigneeOrCreatorUserId &&
                            !currentMenuItemIsCustom())) &&
                        !editFilter
                      }
                      blocker={
                        ((combinedState.defaultFilter.creatorUserId &&
                          combinedState.defaultFilter.creatorUserId?.length >
                            0 &&
                          currentMenuItemIsCustom()) ||
                          (!!combinedState.defaultFilter
                            .assigneeOrCreatorUserId &&
                            !currentMenuItemIsCustom())) &&
                        !editFilter
                      }
                      className="is-full-width"
                      placeholder={capitalize(t('general.claim.creator'))}
                      result={creators.localValue}
                      value={creators.localValue.filter(
                        (x) =>
                          combinedState.filter.creatorUserId?.includes(x.id) ||
                          (combinedState.defaultFilter.creatorUserId?.includes(
                            x.id,
                          ) &&
                            !editFilter) ||
                          x.id ===
                            combinedState.defaultFilter.assigneeOrCreatorUserId,
                      )}
                      valueFormatter={(value) => getDisplayNameFromUser(value)}
                      keyFormatter={(value) => value.id}
                      onChange={(choice) => {
                        setCombinedState(
                          produce(combinedState, (draftState) => {
                            if (combinedState.tasks.status === 'success') {
                              draftState.tasks.status = 'loading-with-value';
                            }
                            draftState.filter.creatorUserId = choice?.map(
                              (x) => x.id,
                            );
                            draftState.filter.page = 0;
                          }),
                        );
                      }}
                    />
                  </BalFieldControl>
                </BalField>
              )}
              {inspectors?.status === 'success' && (
                <BalField className="base-task-search-form-fields">
                  <BalFieldLabel className="mb-1px">
                    {capitalize(getAssigneePerTypeTranslated(taskType))}
                  </BalFieldLabel>
                  <BalFieldControl>
                    <BalMultipleTypeAheadField
                      disabled={
                        ((combinedState.defaultFilter.assigneeUserId &&
                          combinedState.defaultFilter.assigneeUserId?.length >
                            0) ||
                          !!combinedState.defaultFilter
                            .assigneeOrCreatorUserId) &&
                        !editFilter
                      }
                      blocker={
                        ((combinedState.defaultFilter.assigneeUserId &&
                          combinedState.defaultFilter.assigneeUserId?.length >
                            0) ||
                          !!combinedState.defaultFilter
                            .assigneeOrCreatorUserId) &&
                        !editFilter
                      }
                      className="is-full-width"
                      placeholder={capitalize(
                        getAssigneePerTypeTranslated(taskType),
                      )}
                      result={searchInspectors}
                      value={searchInspectors.filter(
                        (x) =>
                          combinedState.filter.assigneeUserId?.includes(x.id) ||
                          (combinedState.defaultFilter.assigneeUserId?.includes(
                            x.id,
                          ) &&
                            !editFilter) ||
                          x.id ===
                            combinedState.defaultFilter.assigneeOrCreatorUserId,
                      )}
                      valueFormatter={(value) => {
                        if (value.id === Globals.UnassignedUserDto.id) {
                          return value.firstName;
                        } else {
                          return getDisplayNameFromUser(value);
                        }
                      }}
                      keyFormatter={(value) => value?.id}
                      onChange={(choice) => {
                        setCombinedState(
                          produce(combinedState, (draftState) => {
                            if (combinedState.tasks.status === 'success') {
                              draftState.tasks.status = 'loading-with-value';
                            }
                            draftState.filter.assigneeUserId = choice?.map(
                              (x) => x.id,
                            );
                            draftState.filter.page = 0;
                          }),
                        );
                      }}
                    />
                  </BalFieldControl>
                </BalField>
              )}
              <BalTextField
                className="base-task-search-form-fields"
                disabled={!!combinedState.defaultFilter.location && !editFilter}
                value={timedSearchFields.location}
                title={t('general.zipCodeCity')}
                placeholder={t('general.zipCodeCity')}
                onInput={(e: CustomEvent<string | undefined>) => {
                  clearTimeout(timer);
                  const newTimer = setTimeout(() => {
                    onLocationSearchDelay(e.detail ?? '');
                  }, 500);
                  setTimer(newTimer);

                  setTimedSearchField({
                    ...timedSearchFields,
                    location: e.detail,
                  });
                }}
              />
              <BalTextField
                className="base-task-search-form-fields"
                disabled={
                  !!combinedState.defaultFilter.streetAndNumber && !editFilter
                }
                value={timedSearchFields.streetAndNumber}
                title={t('general.streetAndNumber')}
                placeholder={t('general.streetAndNumber')}
                onInput={(e: CustomEvent<string | undefined>) => {
                  clearTimeout(timer);
                  const newTimer = setTimeout(() => {
                    onStreetAndNumberSearchDelay(e.detail ?? '');
                  }, 500);
                  setTimer(newTimer);

                  setTimedSearchField({
                    ...timedSearchFields,
                    streetAndNumber: e.detail,
                  });
                }}
              />
              <BalTextField
                disabled={
                  !!combinedState.defaultFilter.policyHolderName && !editFilter
                }
                className="base-task-search-form-fields"
                titleClassName="mb-1px"
                title={capitalize(t('general.claim.policyHolder'))}
                placeholder={capitalize(t('general.claim.policyHolder'))}
                value={timedSearchFields.policyHolderName}
                onInput={(e: CustomEvent<string | undefined>) => {
                  clearTimeout(timer);
                  const newTimer = setTimeout(() => {
                    onPolicyHolderNameSearchDelay(e.detail ?? '');
                  }, 500);
                  setTimer(newTimer);

                  setTimedSearchField({
                    ...timedSearchFields,
                    policyHolderName: e.detail,
                  });
                }}
              />
              {taskType === TaskTypes.VehicleTask && (
                <BalField className="base-task-search-form-fields">
                  <BalFieldLabel className="mb-1px">
                    {capitalize(t('claimDetail.insuranceType'))}
                  </BalFieldLabel>
                  <BalFieldControl>
                    <BalMultipleTypeAheadField
                      disabled={
                        !!combinedState.defaultFilter.insuranceType &&
                        combinedState.defaultFilter.insuranceType?.length > 0 &&
                        !editFilter
                      }
                      blocker={
                        !!combinedState.defaultFilter.insuranceType &&
                        combinedState.defaultFilter.insuranceType?.length > 0 &&
                        !editFilter
                      }
                      className="is-full-width"
                      placeholder={capitalize(t('claimDetail.insuranceType'))}
                      result={vehicleTaskInsuranceTypes}
                      value={insuranceTypes}
                      valueFormatter={(value) => {
                        return t(`insuranceType.${value}`);
                      }}
                      keyFormatter={(value) => value?.toString()}
                      onChange={(choice) => {
                        setInsuranceTypes(choice);
                        setCombinedState(
                          produce(combinedState, (draftState) => {
                            if (combinedState.tasks.status === 'success') {
                              draftState.tasks.status = 'loading-with-value';
                            }
                            draftState.filter.insuranceType = choice;
                            draftState.filter.page = 0;
                          }),
                        );
                      }}
                    />
                  </BalFieldControl>
                </BalField>
              )}
              {groupedStatusFilterList.status === 'success' &&
                groupedStatusFilterList.value.length > 0 && (
                  <BalField className="base-task-search-form-fields">
                    <BalFieldLabel className="mb-1px">
                      {capitalize(t('general.claim.status'))}
                    </BalFieldLabel>
                    <BalFieldControl>
                      <BalMultipleTypeAheadField
                        disabled={
                          combinedState.defaultFilter.status &&
                          combinedState.defaultFilter.status?.length > 0 &&
                          !editFilter
                        }
                        blocker={
                          combinedState.defaultFilter.status &&
                          combinedState.defaultFilter.status?.length > 0 &&
                          !editFilter
                        }
                        className="is-full-width"
                        placeholder={capitalize(t('general.claim.status'))}
                        result={allStates}
                        value={allStates.filter((x) =>
                          combinedState.filter.status?.some(
                            (val) =>
                              val === x ||
                              (x === 'InProgress' && val.includes(x)),
                          ),
                        )}
                        valueFormatter={(value) => {
                          return t(`baseTaskStatus.${value}`);
                        }}
                        keyFormatter={(value) => value?.toString()}
                        onChange={(choice) => {
                          const baseTaskStatusses =
                            convertFromGroupedStatusFilterList(choice);
                          setCombinedState(
                            produce(combinedState, (draftState) => {
                              if (combinedState.tasks.status === 'success') {
                                draftState.tasks.status = 'loading-with-value';
                              }
                              draftState.filter.status =
                                baseTaskStatusses === undefined ||
                                baseTaskStatusses.length === 0
                                  ? undefined
                                  : baseTaskStatusses;
                              draftState.filter.page = 0;
                            }),
                          );
                        }}
                      />
                    </BalFieldControl>
                  </BalField>
                )}
              {taskType === TaskTypes.VehicleTask && (
                <>
                  <div className="base-task-search-form-fields">
                    <BalField>
                      <BalFieldLabel className="mb-1px">
                        {capitalize(t('vehicleTask.kind'))}
                      </BalFieldLabel>
                      <BalFieldControl>
                        <BalMultipleTypeAheadField
                          disabled={
                            combinedState.defaultFilter.vehicleTaskKinds &&
                            combinedState.defaultFilter.vehicleTaskKinds
                              ?.length > 0 &&
                            !editFilter
                          }
                          blocker={
                            combinedState.defaultFilter.vehicleTaskKinds &&
                            combinedState.defaultFilter.vehicleTaskKinds
                              ?.length > 0 &&
                            !editFilter
                          }
                          className="is-full-width"
                          placeholder={capitalize(t('vehicleTask.kind'))}
                          result={getVehicleTaskKinds()}
                          value={combinedState.filter.vehicleTaskKinds}
                          valueFormatter={(value) => {
                            return t(`vehicleTask.VehicleTaskKinds.${value}`);
                          }}
                          keyFormatter={(value) => value?.toString()}
                          onChange={(choice) => {
                            setCombinedState(
                              produce(combinedState, (draftState) => {
                                if (combinedState.tasks.status === 'success') {
                                  draftState.tasks.status =
                                    'loading-with-value';
                                }
                                draftState.filter.vehicleTaskKinds = choice;
                                draftState.filter.page = 0;
                              }),
                            );
                          }}
                        />
                      </BalFieldControl>
                    </BalField>
                  </div>
                  <div className="base-task-search-form-fields">
                    <BalField>
                      <BalFieldLabel className="mb-1px">
                        {t('vehicleTask.hail')}
                      </BalFieldLabel>
                      <BalFieldControl>
                        <BalTypeAheadField
                          disabled={
                            (
                              combinedState.defaultFilter as VehicleTaskFilterCriteriaDto
                            ).hail !== undefined && !editFilter
                          }
                          blocker={
                            (
                              combinedState.defaultFilter as VehicleTaskFilterCriteriaDto
                            ).hail !== undefined && !editFilter
                          }
                          placeholder={t('vehicleTask.hail')}
                          value={
                            (
                              combinedState.filter as VehicleTaskFilterCriteriaDto
                            ).hail
                          }
                          result={[true, false]}
                          valueFormatter={(value) => {
                            return t(`tristate.${value}`);
                          }}
                          keyFormatter={(value) => value?.toString()}
                          onChange={(choice) => {
                            setCombinedState(
                              produce(combinedState, (draftState) => {
                                if (combinedState.tasks.status === 'success') {
                                  draftState.tasks.status =
                                    'loading-with-value';
                                }

                                (
                                  draftState.filter as VehicleTaskFilterCriteriaDto
                                ).hail = choice;
                                draftState.filter.page = 0;
                              }),
                            );
                          }}
                        />
                      </BalFieldControl>
                    </BalField>
                  </div>
                </>
              )}
              {taskType === TaskTypes.PropertyInspectionTask && (
                <>
                  <BalField className="base-task-search-form-fields">
                    <BalFieldLabel className="mb-1px">
                      {t('inspectionTask.cause')}
                    </BalFieldLabel>
                    <BalFieldControl>
                      <BalMultipleTypeAheadField
                        blocker={
                          !!combinedState.defaultFilter
                            .propertyInspectionTaskCauses && !editFilter
                        }
                        disabled={
                          !!combinedState.defaultFilter
                            .propertyInspectionTaskCauses && !editFilter
                        }
                        className="is-full-width"
                        placeholder={t('inspectionTask.cause')}
                        value={
                          combinedState.filter.propertyInspectionTaskCauses
                        }
                        result={Globals.propertyInspectionTaskCauses}
                        valueFormatter={(value) => {
                          return t(
                            `inspectionTask.causes.${
                              value as PropertyInspectionTaskCause
                            }`,
                          );
                        }}
                        keyFormatter={(value) => value?.toString()}
                        onChange={(choice) => {
                          setCombinedState(
                            produce(combinedState, (draftState) => {
                              if (combinedState.tasks.status === 'success') {
                                draftState.tasks.status = 'loading-with-value';
                              }
                              draftState.filter.propertyInspectionTaskCauses =
                                choice === null
                                  ? undefined
                                  : (choice as PropertyInspectionTaskCause[]);
                              draftState.filter.page = 0;
                            }),
                          );
                        }}
                      />
                    </BalFieldControl>
                  </BalField>
                  <DateRangePicker
                    disabled={
                      (!!(
                        combinedState.defaultFilter as PropertyInspectionTaskFilterCriteriaDto
                      ).scheduledInspectionDateFrom ||
                        !!(
                          combinedState.defaultFilter as PropertyInspectionTaskFilterCriteriaDto
                        ).scheduledInspectionDateTo) &&
                      !editFilter
                    }
                    title={t('inspectionTask.scheduledInspectionDate')}
                    fromValue={convertUtcToFormat({
                      date: (
                        combinedState.filter as PropertyInspectionTaskFilterCriteriaDto
                      ).scheduledInspectionDateFrom,
                      format: 'yyyy-MM-dd',
                    })}
                    toValue={convertUtcToFormat({
                      date: (
                        combinedState.filter as PropertyInspectionTaskFilterCriteriaDto
                      ).scheduledInspectionDateTo,
                      format: 'yyyy-MM-dd',
                    })}
                    setBothDates={(
                      from: string | undefined,
                      to: string | undefined,
                    ) => {
                      const fromWithHour = from
                        ? new Date(`${from} 00:00:00`)
                        : undefined;
                      const toWithHour = to
                        ? new Date(`${to} 23:59:59`)
                        : undefined;
                      setCombinedState(
                        produce(combinedState, (draftState) => {
                          if (combinedState.tasks.status === 'success') {
                            draftState.tasks.status = 'loading-with-value';
                          }
                          (
                            draftState.filter as PropertyInspectionTaskFilterCriteriaDto
                          ).scheduledInspectionDateFrom =
                            fromWithHour?.toISOString() ?? '';
                          (
                            draftState.filter as PropertyInspectionTaskFilterCriteriaDto
                          ).scheduledInspectionDateTo =
                            toWithHour?.toISOString() ?? '';
                          draftState.filter.page = 0;
                        }),
                      );
                    }}
                    onFromChange={(
                      e: CustomEvent<null | string | undefined>,
                    ) => {
                      if (e.detail || e.detail === '') {
                        const value = e.detail;
                        const fromWithHour = new Date(`${value} 00:00:00`);
                        setCombinedState(
                          produce(combinedState, (draftState) => {
                            if (combinedState.tasks.status === 'success') {
                              draftState.tasks.status = 'loading-with-value';
                            }
                            (
                              draftState.filter as PropertyInspectionTaskFilterCriteriaDto
                            ).scheduledInspectionDateFrom =
                              fromWithHour.toISOString();
                            draftState.filter.page = 0;
                          }),
                        );
                      }
                    }}
                    onToChange={(e: CustomEvent<null | string | undefined>) => {
                      if (e.detail || e.detail === '') {
                        const value = e.detail;
                        const toWithHour = new Date(`${value} 23:59:59`);
                        setCombinedState(
                          produce(combinedState, (draftState) => {
                            if (combinedState.tasks.status === 'success') {
                              draftState.tasks.status = 'loading-with-value';
                            }
                            (
                              draftState.filter as PropertyInspectionTaskFilterCriteriaDto
                            ).scheduledInspectionDateTo =
                              toWithHour.toISOString();
                            draftState.filter.page = 0;
                          }),
                        );
                      }
                    }}
                  />
                  <BalField className="base-task-search-form-fields">
                    <BalFieldLabel className="mb-1px">
                      {t(
                        'inspectionTask.inspection.unfinishedInspectionReport',
                      )}
                    </BalFieldLabel>
                    <BalFieldControl>
                      <BalTypeAheadField
                        disabled={
                          (
                            combinedState.defaultFilter as PropertyInspectionTaskFilterCriteriaDto
                          ).unfinishedInspectionsOnly !== undefined &&
                          !editFilter
                        }
                        blocker={
                          (
                            combinedState.defaultFilter as PropertyInspectionTaskFilterCriteriaDto
                          ).unfinishedInspectionsOnly !== undefined &&
                          !editFilter
                        }
                        placeholder={t(
                          'inspectionTask.inspection.unfinishedInspectionReport',
                        )}
                        value={unfinishedInspectionReport}
                        result={[true, false]}
                        valueFormatter={(value) => {
                          return t(`tristate.${value}`);
                        }}
                        keyFormatter={(value) => value?.toString()}
                        onChange={(choice) => {
                          setCombinedState(
                            produce(combinedState, (draftState) => {
                              if (combinedState.tasks.status === 'success') {
                                draftState.tasks.status = 'loading-with-value';
                              }

                              (
                                draftState.filter as PropertyInspectionTaskFilterCriteriaDto
                              ).unfinishedInspectionsOnly = choice;
                            }),
                          );
                        }}
                      />
                    </BalFieldControl>
                  </BalField>
                </>
              )}
            </div>
          </div>
          <div className="position-absolute is-flex is-flex-direction-column filter-options mr-2">
            <div className="is-flex is-flex-direction-column">
              <BalButton
                className="is-full-width"
                size="small"
                onClick={(event) => {
                  if (event.detail == 1) {
                    setCombinedState(
                      produce(combinedState, (draftState) => {
                        if (combinedState.tasks.status === 'success') {
                          draftState.tasks.status = 'loading-with-value';
                        }
                        draftState.filter.page = 0;
                        draftState.filter = combinedState.defaultFilter;
                      }),
                    );
                  }
                }}
              >
                {t('general.resetFilter')}
              </BalButton>
              <BalButton
                className="my-4 is-full-width"
                size="small"
                color="primary"
                onClick={() => {
                  openFilterSaveDialog();
                }}
              >
                {t('general.buttons.createCustomFilter')}
              </BalButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
