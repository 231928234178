import {
  BalButton,
  BalButtonGroup,
  BalHeading,
  BalModalBody,
  BalModalHeader,
  BalSelect,
  BalSelectOption,
} from '@baloise/design-system-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { balModalController } from '../../controller/controllers';
import { AppCapabilityNames } from '../../types/resource-models';
import { WithCapabilities } from '../authorization/with-capabilities';
import { CancelButton } from '../ui';

type EditMultiSelectionModalProps<T> = {
  label: string;
  value: string[];
  selectionValues: T[];
  tFunction: (key: T) => string;
  placeholder: string;
  className?: string;
  requiredCapabilities: AppCapabilityNames;
  onSave(value: string[]): void;
  valueFormatter: (value: T) => string;
  keyFormatter: (value: T) => string;
};

function EditMultiSelectionModalContent<T>(
  props: EditMultiSelectionModalProps<T>,
): JSX.Element {
  const { t } = useTranslation();
  const [selectionValues, setSelectionValues] = useState<string[]>(props.value);

  return (
    <div className={props.className}>
      <BalModalHeader>
        <BalHeading className="pb-5" space="none" level="h3">
          {props.label}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalSelect
          multiple
          className="column mr-4 is-narrow"
          value={selectionValues}
          placeholder={props.placeholder}
          onBalChange={(e) => {
            if (e.detail) {
              setSelectionValues(e.detail as string[]);
            }
          }}
        >
          {/* https://github.com/baloise-incubator/design-system/issues/1090 */}
          <div className="bal-select__inner">
            {props.selectionValues.map((selection) => {
              const value = props.valueFormatter(selection);
              return (
                <BalSelectOption
                  key={props.keyFormatter(selection)}
                  value={value}
                  label={props.tFunction(selection)}
                >
                  {props.tFunction(selection)}
                </BalSelectOption>
              );
            })}
          </div>
        </BalSelect>
        <BalButtonGroup position="right">
          <CancelButton />
          <BalButton
            color="info"
            onClick={(event) => {
              event.detail == 1 && props.onSave(selectionValues);
            }}
          >
            {t('general.buttons.save')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </div>
  );
}

export function EditMultiSelectionModal<T>(
  props: EditMultiSelectionModalProps<T>,
): JSX.Element {
  const openModal = async () => {
    const modal = await balModalController.create({
      component: EditMultiSelectionModalContent,
      componentProps: props,
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  return (
    <WithCapabilities requiredCapabilities={[props.requiredCapabilities]}>
      <BalButton
        square
        outlined
        size="small"
        color="primary-light"
        icon="edit"
        onClick={(event) => event.detail == 1 && openModal()}
      />
    </WithCapabilities>
  );
}
