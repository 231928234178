import {
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import produce from 'immer';
import { InvestigationTaskJournalCombinedState } from '../data/hooks';
import { InvestigationTask, ISortingState } from '../../../types/types';

import '../../../styles/general.scss';
import { getAllGridOptions } from './journal-search-form-table-grid-options';
import { ResultRenderer } from '../../../components/ui';
import { BalPagination } from '@baloise/design-system-components-react';
import { InvestigationTaskJournalListDto } from '../../../types/resource-models';
import { deleteInvestigationTaskJournal } from '../data/requests';
import { useToken } from '../../../hooks';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../components/toast-notification';
import JournalAddButton from './toolbar/journal-add-button';
import { isJournalAndExpansesDisabled } from '../../../utils';

const JournalSearchFormTable = ({
  task,
  combinedState,
  setCombinedState,
  onGridReady,
  onJournalSelected,
}: {
  task: InvestigationTask;
  combinedState: InvestigationTaskJournalCombinedState;
  setCombinedState: React.Dispatch<
    React.SetStateAction<InvestigationTaskJournalCombinedState>
  >;
  onJournalSelected: (journalId?: string) => Promise<void>;
  onGridReady?: (params: GridReadyEvent) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (combinedState.journals.status === 'success') {
      setTotalPages(combinedState.journals.value.totalPages);
    }
  }, [combinedState.journals, setTotalPages]);

  // Go to last page if the current is greater than existents.
  useEffect(() => {
    if (combinedState.journals.status === 'success') {
      if (totalPages > 0 && totalPages <= combinedState.filter.page) {
        setCombinedState(
          produce(combinedState, (draft) => {
            if (combinedState.journals.status === 'success') {
              draft.journals.status = 'loading';
            }
            draft.filter.page = totalPages - 1;
          }),
        );
      }
    }
  }, [totalPages, combinedState, setCombinedState]);

  const [selectedJournalId, setSelectedJournalId] = useState<
    string | undefined
  >(undefined);

  const [sorting, setSorting] = useState<ISortingState | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const onGridReadyLocal = (params: GridReadyEvent) => {
    setColumnApi(params.columnApi);
    setGridApi(params.api);
    if (sorting) {
      const gridApi = params.api;
      const columnsApi = params.columnApi;
      if (combinedState.filter.orderField) {
        const colDef = gridApi
          .getColumnDefs()
          ?.find((x) => (x as ColDef).field == combinedState.filter.orderField);
        if (colDef) {
          columnsApi.applyColumnState({
            state: [
              {
                colId: (colDef as ColDef).colId ?? colDef.headerName ?? '',
                sort: combinedState.filter.orderBy,
              },
            ],
          });
        }
      }
    }

    if (onGridReady) {
      onGridReady(params);
    }

    // Select row.
    if (selectedJournalId) {
      const selectedNode = params.api
        .getRenderedNodes()
        .find(
          (n) =>
            (n.data as InvestigationTaskJournalListDto).id ===
            selectedJournalId,
        );
      selectedNode?.setSelected(true);
    }
  };

  const getSorting = (columnApi: ColumnApi): ISortingState | undefined => {
    const columns = columnApi.getAllDisplayedColumns();
    const sortingColumn = columns.find((c) => c.getSort());
    if (sortingColumn) {
      const colDef = sortingColumn.getColDef();
      return {
        field: colDef.field ?? '',
        direction: sortingColumn.getSort() ?? 'asc',
      };
    } else {
      return undefined;
    }
  };

  const investigationTaskId = combinedState.filter.investigationTaskId;

  const token = useToken();
  const [selectedJournal, setSelectedJournal] = useState<
    InvestigationTaskJournalListDto | undefined
  >(undefined);
  const [rowChanged, setRowChanged] = useState<boolean>(false);

  const refreshJournal = (journalId?: string) => {
    onJournalSelected(journalId);
    setSelectedJournalId(journalId);
    setRowChanged(true);
  };

  useEffect(() => {
    if (rowChanged) {
      setRowChanged(false);
      setCombinedState(
        produce(combinedState, (draft) => {
          if (combinedState.journals.status === 'success') {
            draft.journals.status = 'loading';
          }
        }),
      );
    }
  }, [rowChanged, combinedState, setCombinedState, setRowChanged]);

  const onDeleteJournal = async (journal: InvestigationTaskJournalListDto) => {
    const response = await deleteInvestigationTaskJournal(journal.id, token);
    if (response.status === 'error') {
      toast(ErrorToast(response.errorValue));
    } else {
      refreshJournal();
    }
  };

  const [gridOptions, setGridOptions] = useState(
    getAllGridOptions(
      t,
      {
        currentJournal: selectedJournal,
        onEditJournal: refreshJournal,
        readonly: isJournalAndExpansesDisabled(task.status),
        onDeleteJournal: onDeleteJournal,
      },
      {
        currentJournal: selectedJournal,
        onDeleteJournal: onDeleteJournal,
        disabled: isJournalAndExpansesDisabled(task.status),
      },
    ),
  );

  useEffect(() => {
    if (gridOptions) {
      gridOptions.onRowClicked = (params) => {
        setSelectedJournal(params.data);
      };
    }
  }, [gridOptions]);

  useEffect(() => {
    if (task) {
      const gridOptionsNew = getAllGridOptions(
        t,
        {
          currentJournal: selectedJournal,
          onEditJournal: refreshJournal,
          readonly: isJournalAndExpansesDisabled(task.status),
          onDeleteJournal: onDeleteJournal,
        },
        {
          currentJournal: selectedJournal,
          onDeleteJournal: onDeleteJournal,
          disabled: isJournalAndExpansesDisabled(task.status),
        },
      );
      if (gridOptionsNew.columnDefs && gridApi) {
        gridApi.setColumnDefs(gridOptionsNew.columnDefs);
      }
      setGridOptions(gridOptionsNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <>
      {combinedState.journals.status == 'success' && (
        <ResultRenderer
          result={combinedState.journals}
          defaultValue={{ totalPages: 1, totalItems: 0, entities: [] }}
          loaded={(tasksResponse) => {
            return (
              <>
                {investigationTaskId && (
                  <div className="is-flex is-flex-direction-column">
                    <div className="flex-float-right pr-3">
                      <JournalAddButton
                        task={task}
                        onAddJournal={refreshJournal}
                        disabled={isJournalAndExpansesDisabled(task.status)}
                      />
                    </div>
                  </div>
                )}
                <div className="task-table-detail-paginated">
                  <AgGridReact
                    suppressColumnMoveAnimation={true}
                    suppressCellFocus={true}
                    rowSelection={'single'}
                    onGridReady={onGridReadyLocal}
                    rowData={tasksResponse.entities}
                    rowHeight={40}
                    gridOptions={gridOptions}
                    onSelectionChanged={async (
                      event: SelectionChangedEvent,
                    ) => {
                      const api = event.api;
                      const selectedNodes = api.getSelectedNodes();
                      let selectedJournalId = undefined;
                      if (selectedNodes.length > 0) {
                        const rowNode: RowNode = selectedNodes[0];
                        selectedJournalId = (
                          rowNode.data as InvestigationTaskJournalListDto
                        ).id;
                        setSelectedJournalId(selectedJournalId);
                        await onJournalSelected(selectedJournalId);
                      }
                    }}
                    onSortChanged={(event: SortChangedEvent) => {
                      if (sorting) {
                        setSorting(undefined);
                        return;
                      }
                      // get order column and direction.
                      if (event.columnApi) {
                        const currentSorting = getSorting(event.columnApi);
                        setSorting(currentSorting);

                        // Avoid unfocus header problems in aggrid when changing filter.
                        const focused = document.activeElement;
                        if (focused) {
                          (focused as HTMLInputElement).blur();
                        }

                        setCombinedState(
                          produce(combinedState, (draft) => {
                            if (combinedState.journals.status === 'success') {
                              draft.journals.status = 'loading';
                            }
                            if (currentSorting) {
                              draft.filter.orderField = currentSorting?.field;
                              draft.filter.orderBy = currentSorting?.direction;
                            } else {
                              draft.filter.orderField =
                                draft.defaultFilter.orderField;
                              draft.filter.orderBy =
                                draft.defaultFilter.orderBy;
                            }
                            draft.filter.page = 0;
                          }),
                        );
                      }
                    }}
                  />
                  <div className="mx-3 py-3">
                    <BalPagination
                      value={(combinedState.filter.page ?? 0) + 1}
                      totalPages={totalPages}
                      onBalChange={(e) => {
                        e.cancelBubble = true;
                        if (columnApi) {
                          const currentSorting = getSorting(columnApi);
                          setSorting(currentSorting);
                        }
                        setCombinedState(
                          produce(combinedState, (draft) => {
                            if (combinedState.journals.status === 'success') {
                              draft.journals.status = 'loading';
                            }
                            draft.filter.page = e.detail - 1;
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            );
          }}
        />
      )}
      {combinedState.journals.status == 'error' && (
        <span>{t('error.requestError.connectionError')}</span>
      )}
    </>
  );
};

export default JournalSearchFormTable;
