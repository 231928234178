import { BalIcon } from '@baloise/design-system-components-react';
import { ReactComponent as Circle } from '../../../assets/icons/full-circle.svg';
import { ReactComponent as AttachmentAdd } from '../../../assets/icons/attachment-add.svg';
import { ReactComponent as AttachmentDelete } from '../../../assets/icons/attachment-delete.svg';
import { ReactComponent as ContactDelete } from '../../../assets/icons/contact-delete.svg';
import TextProtocolModal from '../../../components/input/text-protocol-modal';
import i18n from '../../../i18n/config';
import {
  concat,
  getAssigneePerTypeTranslated,
  getBaseTaskStatusColor,
  getTaskTypeFromEntityName,
} from '../../../utils';
import { ProtocolMessage } from './entity-protocol';
import {
  BaseTaskChangeDto,
  BaseTaskStatus,
  TaskChangeType,
} from '../../../types/resource-models';
import {
  balIconCheck,
  balIconAccount,
  balIconTrash,
  balIconConsultant,
  balIconCall,
  balIconPlus,
  balIconCopy,
  balIconEdit,
} from '@baloise/design-system-icons';

const getPropertyName = (propertyName: string): string => {
  const t = i18n.t;
  const exists = i18n.exists;
  // Try upper and lowercase.
  const lowerPropertyName = `${propertyName[0].toLowerCase()}${propertyName.slice(
    1,
  )}`;

  if (exists(`taskHistory.${lowerPropertyName}`)) {
    return t(`taskHistory.${lowerPropertyName}`);
  }

  if (exists(`taskHistory.${propertyName}`)) {
    return t(`taskHistory.${propertyName}`);
  }

  if (exists(`general.${propertyName}`)) {
    return t(`general.${propertyName}`);
  }

  if (exists(`general.${lowerPropertyName}`)) {
    return t(`general.${lowerPropertyName}`);
  }

  const propertyParts = propertyName.split('.');
  switch (propertyParts[0]) {
    case 'PolicyNumber':
    case 'ClaimNumber':
      return t(`general.claim.${lowerPropertyName}`);
    case 'InvestigationTaskType':
      return t('investigationTask.type');
    case 'InvestigationTaskCompletionCode':
    case 'TaskAssessment':
    case 'EstimatedSavings':
    case 'InvestigationSavings':
    case 'CheatingParty':
      return t(`investigationTask.${lowerPropertyName}`);
    case 'AddressString':
      return t('general.city');
    case 'InsuranceType':
      return t('claimDetail.insuranceType');
    case 'CreatorId':
      return t('claimDetail.taskCreator');
    case 'MajorEventNumber':
      return t('claimDetail.majorEvent');
    case 'Language':
      return t('language.reportLabel');
    case 'ScheduledInspectionDate':
      return t('inspectionTask.scheduledInspectionDate');
    case 'hisEntryMeasure':
    case 'criminalChargesMeasure':
    case 'civilClaimMeasure':
    case 'regressMeasure':
    case 'dejaVuEntryMeasure':
    case 'artLossRegisterEntryMeasure':
    case 'privatOrCicilConstitutionMeasure':
    case 'policyHolderServicingMeasure':
      return `${t(`investigationMeasure.${propertyParts[0]}`)} ${t(
        `investigationMeasure.${propertyParts[1]}`,
      )}`;
    case 'CreditCheckService':
    case 'AuthoritiesClarificationsService':
    case 'InsuranceSurveyService':
    case 'CarClaimInfoQueryService':
    case 'HisQueryService':
    case 'MohawkQueryService':
      return t(`investigationService.${lowerPropertyName}`);
    case 'CreditCheckServiceCompletionCode':
    case 'InsuranceSurveyServiceCompletionCode':
    case 'CarClaimInfoQueryServiceCompletionCode':
    case 'HisQueryServiceCompletionCode':
    case 'MohawkQueryServiceCompletionCode':
    case 'OsintServiceCompletionCode':
    case 'ConsultingServiceCompletionCode':
    case 'ContactWithAuthoritiesServiceCompletionCode':
    case 'hisRegistrationServiceCompletionCode':
    case 'ArtLossServiceCompletionCode':
      return t(`investigationTaskServiceCompletionCode.${lowerPropertyName}`);
    default:
      return propertyName ?? '';
  }
};

const getProtocolModalLabel = (
  changes: BaseTaskChangeDto[],
  propertyName?: string,
) => {
  const t = i18n.t;
  if (changes.length === 1) {
    return concat([
      propertyName ? getPropertyName(propertyName) : '',
      t('taskHistory.changedTo'),
    ]);
  } else {
    return t('taskHistory.multipleChanges');
  }
};

export const getBaseTaskMessage = (
  change: BaseTaskChangeDto,
  changes: BaseTaskChangeDto[],
): ProtocolMessage | undefined => {
  const t = i18n.t;

  const getPropertyValue = (
    propertyName: string | undefined,
    value?: string,
    changeType?: TaskChangeType,
  ): string => {
    if (value === '' || value === undefined) {
      return `: ${t('tristate.')}`;
    }

    let translatedPropertyValue = value;
    const trueFalseValues = ['true', 'True', 'false', 'False'];
    if (trueFalseValues.includes(value)) {
      translatedPropertyValue = t(`tristate.${value}`);
    }
    if (changeType) {
      if (changeType === 'Policy') {
        if (propertyName === 'Compensation') {
          translatedPropertyValue = t(`compensationType.${value}`);
        }
      }

      if (changeType === 'None') {
        const lowerPropertyName = propertyName
          ? `${propertyName[0].toLowerCase()}${propertyName.slice(1)}`
          : '';
        switch (propertyName) {
          case 'AssigneeGarageCraftsmanContactMethod':
            translatedPropertyValue = t(
              `policyHolderContactMethodType.${value}`,
            );
            break;
          case 'InvestigationTaskCompletionCode':
          case 'Branch':
          case 'TaskAssessment':
          case 'InsuranceType':
          case 'EstimatedSavings':
            translatedPropertyValue = t(`${lowerPropertyName}.${value}`);
            break;
          case 'InvestigationTaskType':
            translatedPropertyValue = t(
              `investigationTask.investigationTaskType.${value}`,
            );
            break;
          case 'CheatingParty':
            translatedPropertyValue = t(
              `investigationTask.cheatingPartyType.${value}`,
            );
            break;
          case 'Language':
            translatedPropertyValue = t(`language.languages.${value}`);
            break;
          case 'ScheduledInspectionDate':
            translatedPropertyValue = `${value.substring(
              0,
              value.length - 3,
            )} (UTC)`;
            break;
          default:
            break;
        }
      }
    }

    return propertyName
      ? `: ${translatedPropertyValue}`
      : translatedPropertyValue;
  };

  switch (change.changeType) {
    case 'Created':
      return {
        message: t('taskHistory.Created'),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconCheck} color="success" />
          </div>
        ),
      };
    case 'State':
      const status: BaseTaskStatus | undefined = change.newValue as
        | BaseTaskStatus
        | undefined;
      const color = status ? `is-${getBaseTaskStatusColor(status)}` : '';
      return {
        message: concat([
          t('taskHistory.State'),
          `«${t(`baseTaskStatus.${change.newValue}`)}»`,
        ]),
        icon: (
          <div className={`icon-container ${color} pr-3`}>
            <Circle />
          </div>
        ),
      };
    case 'Assignee':
      const taskType = getTaskTypeFromEntityName(change.entityName);
      return {
        message: concat([
          t('taskHistory.Assignee', {
            assignee: getAssigneePerTypeTranslated(taskType),
          }),
          change.newValue,
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'PrimaryContact':
      return {
        message: concat([t('taskHistory.PrimaryContact'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconConsultant} />
          </div>
        ),
      };
    case 'AddedNewContact':
      return {
        message: concat([t('taskHistory.AddedNewContact'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconCall} />
          </div>
        ),
      };
    case 'RemoveContact':
      return {
        message: concat([t('taskHistory.RemoveContact'), change.newValue]),
        icon: (
          <div className="icon-container is-info pr-2">
            <ContactDelete />
          </div>
        ),
      };
    case 'EditContact':
      return {
        message: concat([t('taskHistory.EditContact'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconCopy} />
          </div>
        ),
      };
    case 'AddedNewExpense':
      return {
        message: concat([t('taskHistory.AddedNewExpense'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconPlus} />
          </div>
        ),
      };
    case 'RemoveExpense':
      return {
        message: concat([t('taskHistory.RemoveExpense'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconTrash} />
          </div>
        ),
      };
    case 'EditExpense':
      return {
        message: concat([t('taskHistory.EditExpense'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconCopy} />
          </div>
        ),
      };
    case 'AddedInvestigationJournal':
    case 'AddedPropertyInspectionTaskJournal':
      return {
        message: concat([
          t('taskHistory.AddedInvestigationJournal'),
          change.newValue,
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconPlus} />
          </div>
        ),
      };
    case 'DeletedInvestigationJournal':
    case 'DeletedPropertyInspectionTaskJournal':
      return {
        message: concat([
          t('taskHistory.DeletedInvestigationJournal'),
          change.newValue,
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconTrash} />
          </div>
        ),
      };
    case 'AddedInsuranceSurvey':
      return {
        message: concat([
          t('taskHistory.AddedInsuranceSurvey'),
          change.newValue,
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconPlus} />
          </div>
        ),
      };
    case 'DeletedInsuranceSurvey':
      return {
        message: concat([
          t('taskHistory.DeletedInsuranceSurvey'),
          change.newValue,
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconTrash} />
          </div>
        ),
      };
    case 'AttachmentAdded':
      return {
        message: concat([t('taskHistory.AttachmentAdded'), change.newValue]),
        icon: (
          <div className="icon-container is-info pl-2">
            <AttachmentAdd />
          </div>
        ),
      };
    case 'AttachmentRemoved':
      return {
        message: concat([t('taskHistory.AttachmentRemoved'), change.newValue]),
        icon: (
          <div className="icon-container is-info pl-2">
            <AttachmentDelete />
          </div>
        ),
      };
    case 'What': {
      let translatedPropertyName = '';
      switch (change.entityName) {
        case 'PropertyInspectionTask':
          translatedPropertyName = t('claimDetail.claimDescription');
          break;
        case 'AccidentInspectionTask':
          translatedPropertyName = t(
            'accidentInspectionTask.claimInformation.cardTitle',
          );
          break;
        case 'InvestigationTask':
          translatedPropertyName = t('investigationTask.suspicion');
          break;
        case 'VehicleTask':
          translatedPropertyName = t(
            'vehicleTask.expertiseOrderReport.whatHappened',
          );
          break;
        default:
          translatedPropertyName = t('taskHistory.What');
          break;
      }
      const translatedChange = {
        ...change,
        propertyName: translatedPropertyName,
      };
      changes.push(translatedChange);
      return {
        message: concat([
          translatedPropertyName,
          t('taskHistory.changedTo'),
          change.newValue,
        ]),
        change: translatedChange,
        icon: (
          <div className="icon-container">
            <BalIcon
              svg={balIconEdit}
              onClick={(event) => {
                event.detail == 1 &&
                  TextProtocolModal({
                    label: getProtocolModalLabel(changes, change.propertyName),
                    changes: changes,
                  });
              }}
              className="icon-clickable"
            />
          </div>
        ),
      };
    }
    case 'Remarks': {
      const translatedPropertyName = t('taskHistory.Remarks');
      const translatedChange = {
        ...change,
        propertyName: translatedPropertyName,
      };
      changes.push(translatedChange);
      return {
        message: concat([
          translatedPropertyName,
          t('taskHistory.changedTo'),
          change.newValue,
        ]),
        change: translatedChange,
        icon: (
          <div className="icon-container">
            <BalIcon
              svg={balIconEdit}
              onClick={(event) => {
                event.detail == 1 &&
                  TextProtocolModal({
                    label: getProtocolModalLabel(changes, change.propertyName),
                    changes: changes,
                  });
              }}
              className="icon-clickable"
            />
          </div>
        ),
      };
    }
    case 'QuickNotes': {
      const translatedPropertyName = t('taskHistory.QuickNotes');
      const translatedChange = {
        ...change,
        propertyName: translatedPropertyName,
      };
      changes.push(translatedChange);
      const title = concat([
        translatedPropertyName,
        t('taskHistory.changedTo'),
      ]);
      const message = concat([
        translatedPropertyName,
        t('taskHistory.changedTo'),
        change.newValue,
      ]);
      return {
        message,
        change: translatedChange,
        icon: (
          <div className="icon-container">
            <BalIcon
              svg={balIconEdit}
              onClick={(event) => {
                event.detail == 1 &&
                  TextProtocolModal({
                    label: title,
                    changes: changes,
                  });
              }}
              className="icon-clickable"
            />
          </div>
        ),
      };
    }
    case 'InternalNote': {
      const translatedPropertyName = t('taskHistory.InternalNote');
      const translatedChange = {
        ...change,
        propertyName: translatedPropertyName,
      };
      changes.push(translatedChange);
      return {
        message: concat([
          translatedPropertyName,
          t('taskHistory.changedTo'),
          change.newValue,
        ]),
        change: translatedChange,
        icon: (
          <div className="icon-container">
            <BalIcon
              svg={balIconEdit}
              onClick={(event) => {
                event.detail == 1 &&
                  TextProtocolModal({
                    label: getProtocolModalLabel(changes, change.propertyName),
                    changes: changes,
                  });
              }}
              className="icon-clickable"
            />
          </div>
        ),
      };
    }
    case 'OriginExpert':
      return {
        message: concat([t('taskHistory.OriginExpert'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'Recommendation':
      if (change.entityName === 'VehicleTask') {
        return {
          message: concat([t('taskHistory.recommendation'), change.newValue]),
          icon: (
            <div className="icon-container">
              <BalIcon svg={balIconAccount} />
            </div>
          ),
        };
      } else {
        const translatedPropertyName = t('taskHistory.recommendation');
        const translatedChange = {
          ...change,
          propertyName: translatedPropertyName,
        };
        changes.push(translatedChange);
        return {
          message: concat([
            translatedPropertyName,
            t('taskHistory.changedTo'),
            change.newValue,
          ]),
          change: translatedChange,
          icon: (
            <div className="icon-container">
              <BalIcon
                svg={balIconEdit}
                onClick={(event) => {
                  event.detail == 1 &&
                    TextProtocolModal({
                      label: getProtocolModalLabel(
                        changes,
                        change.propertyName,
                      ),
                      changes: changes,
                    });
                }}
                className="icon-clickable"
              />
            </div>
          ),
        };
      }
    case 'Summary':
      const translatedPropertyName = getPropertyName(change.propertyName ?? '');
      const translatedChange = {
        ...change,
        propertyName: translatedPropertyName,
      };
      changes.push(translatedChange);
      return {
        message: concat([
          translatedPropertyName,
          t('taskHistory.changedTo'),
          change.newValue,
        ]),
        change: translatedChange,
        icon: (
          <div className="icon-container">
            <BalIcon
              svg={balIconEdit}
              onClick={(event) => {
                event.detail == 1 &&
                  TextProtocolModal({
                    label: getProtocolModalLabel(changes, change.propertyName),
                    changes: changes,
                  });
              }}
              className="icon-clickable"
            />
          </div>
        ),
      };
    case 'RecommendationCustom':
      return {
        message: concat([t('taskHistory.recommendation'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'AffectedVehicle':
      return {
        message: concat([t('vehicleTask.affectedVehicle'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'WhiplashClarificationRearEnd':
      return {
        message: concat([
          t('taskHistory.WhiplashClarificationRearEnd'),
          t(`tristate.${change.newValue}`),
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'WhiplashClarificationFrontal':
      return {
        message: concat([
          t('taskHistory.WhiplashClarificationFrontal'),
          t(`tristate.${change.newValue}`),
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'Coverage':
      return {
        message: concat([t('taskHistory.cover'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'Regress':
      return {
        message: concat([
          t('taskHistory.regress'),
          t(`vehicleRegress.${change.newValue}`),
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'Liability':
      return {
        message: concat([t('taskHistory.liability'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'ReplacementVehicleInsured':
      return {
        message: concat([
          t('taskHistory.replacementCar'),
          t(`tristate.${change.newValue}`),
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'DossierPrint':
      return {
        message: concat([
          t('taskHistory.printDossier'),
          t(`tristate.${change.newValue}`),
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'AffectedId':
      return {
        message: t('taskHistory.affectedPerson'),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'DamageCoordinatesAdded':
      return {
        message: t('taskHistory.damageCoordinatesAdded'),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'DamageCoordinatesDeleted':
      return {
        message: t('taskHistory.damageCoordinatesDeleted'),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'AssigneeGarage':
      return {
        message: concat([t('taskHistory.garageChanged'), change.newValue]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'Deleted':
      return {
        message: t('taskHistory.Deleted'),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconTrash} />
          </div>
        ),
      };
    case 'Policy':
      return {
        message: concat([
          t('taskHistory.Policy'),
          `${getPropertyName(change.propertyName ?? '')} ${getPropertyValue(
            change.propertyName,
            change.newValue,
            change.changeType,
          )}`,
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'Visitation':
      return {
        message: concat([
          t('taskHistory.Visitation'),
          `${getPropertyName(change.propertyName ?? '')} ${getPropertyValue(
            change.propertyName,
            change.newValue,
            change.changeType,
          )}`,
        ]),
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconAccount} />
          </div>
        ),
      };
    case 'None':
      return {
        message: `${getPropertyName(
          change.propertyName ?? '',
        )}${getPropertyValue(
          change.propertyName,
          change.newValue,
          change.changeType,
        )}`,
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconCopy} />
          </div>
        ),
      };
    case 'ContactFromInvestigationTaskMeasureAdded':
      return {
        message: `${getPropertyName(change.propertyName ?? '')} ${t(
          'investigationMeasure.selected',
        )} ${getPropertyValue(
          change.propertyName,
          change.newValue,
          change.changeType,
        )}`,
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconCopy} />
          </div>
        ),
      };
    case 'ContactFromInvestigationTaskMeasureDeleted':
      return {
        message: `${getPropertyName(change.propertyName ?? '')} ${t(
          'investigationMeasure.unselected',
        )}${getPropertyValue(
          change.propertyName,
          change.oldValue,
          change.changeType,
        )}`,
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconCopy} />
          </div>
        ),
      };
    default: {
      return {
        message: `${getPropertyName(
          change.propertyName ?? '',
        )}${getPropertyValue(change.propertyName, change.newValue)}`,
        icon: (
          <div className="icon-container">
            <BalIcon svg={balIconCopy} />
          </div>
        ),
      };
    }
  }
};
