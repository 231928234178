import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import { Craftsman } from '../../types/types';
import '../../styles/map.scss';
import { RequestResult } from '../../data';
import {
  mapProps,
  mapUrl,
  MarkerClusterGroups,
} from '../../features/network-partners';

const AddMapProperties = ({
  setPartner,
}: {
  setPartner: React.Dispatch<React.SetStateAction<RequestResult<Craftsman>>>;
}) => {
  useMapEvents({
    click: () => {
      const element = document.getElementById('overlay');
      element?.classList.add('animated-overlay-out');
      setPartner({ status: 'initial' });
    },
  });
  return null;
};

const NetworkMap = ({
  partners,
  setPartner,
}: {
  partners: Craftsman[];
  setPartner: React.Dispatch<React.SetStateAction<RequestResult<Craftsman>>>;
}): JSX.Element => {
  return (
    <MapContainer {...mapProps}>
      <TileLayer url={mapUrl} maxNativeZoom={19} maxZoom={25} />
      <MarkerClusterGroups partners={partners} setPartner={setPartner} />
      <AddMapProperties setPartner={setPartner} />
    </MapContainer>
  );
};

export default NetworkMap;
