import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BalTabItem, BalTabs } from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useToken, useCallbackPrompt } from '../hooks';
import { Utilities } from '../utils/utilities';
import { APIRoutes, EBRoutes } from '../router/router';
import { WithCapabilities } from '../components/authorization';
import {
  ErrorToast,
  ToastNotification,
} from '../components/toast-notification';
import {
  getPropertyInspectionTask,
  patchPropertyInspectionTask,
  PropertyInspectionTaskOverlay,
  usePropertyInspectionTask,
} from '../features/property-inspection-task';
import { BlockNavigation } from '../components/navigation';
import {
  ClaimNumberInputCard,
  CompleteTaskCards,
  TaskContactsCards,
  TaskInformationCards,
} from '../features/base-task';
import { TaskWizardButtons } from '../components/ui';
import produce from 'immer';
import { RequestResult } from '../data/fetch/result';
import { PropertyInspectionTask, TabType, TaskTypes } from '../types/types';
import { getTask, postTask } from '../features/base-task/data/requests';
import { apiUrl } from '../data/fetch/base';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BaseTaskListDto } from '../types/resource-models';
import { balModalController } from '../controller/controllers';
import { BaseTaskManualCreationModalForm } from '../features/base-task/components/base-task-manual-creation-modal-form';
import { RequestError } from '../data';
import { BadRequestError } from '../data/fetch/errors';

const checkTabType = (tab: string): boolean => {
  switch (tab) {
    case 'claimNumber':
      return true;
    case 'contactInformation':
      return true;
    case 'claimInformation':
      return true;
    case 'completeClaim':
      return true;
    case 'none':
      return true;
    default:
      return false;
  }
};

const PropertyInspectionTaskWizardView = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const claimNumberQueryParam = searchParams.get('claimNumber');
  const taskIdQueryParam = searchParams.get('taskId');
  const [inspectionTask, setInspectionTask] = usePropertyInspectionTask();
  const [tab, setTab] = useState<TabType>('claimNumber');
  const { t } = useTranslation();
  const refTabs = useRef<HTMLBalTabsElement>(null);
  const refTabClaimNumber = useRef<HTMLBalTabItemElement>(null);
  const refTabContactInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabClaimInformation = useRef<HTMLBalTabItemElement>(null);
  const refTabCompleteClaim = useRef<HTMLBalTabItemElement>(null);
  const token = useToken();

  const createOrFetchTask = (
    claimNumber: string,
    setBaseTask: (value: RequestResult<PropertyInspectionTask>) => void,
    token: string,
  ) => {
    setBaseTask({ status: 'loading' });
    postTask<PropertyInspectionTask>(
      { claimNumber: claimNumber },
      token,
      `${apiUrl}${APIRoutes.POST_PROPERTY_INSPECTION_TASK.create({})}`,
    ).then((taskResponse) => {
      if (taskResponse.status === 'success') {
        onCreateTaskSuccess(taskResponse);
      } else if (taskResponse.status === 'error') {
        if (
          (taskResponse.errorValue as RequestError).errorType ===
            'bad-request-error' &&
          (taskResponse.errorValue as BadRequestError).errorKey ===
            'CloneClaimClosedError'
        ) {
          toast(ErrorToast(taskResponse.errorValue));
          onCreateTaskCancel();
        } else {
          openManualCreationModal(claimNumber);
        }
      }
    });
  };

  const openManualCreationModal = async (claimNumber: string) => {
    const modal = await balModalController.create({
      component: BaseTaskManualCreationModalForm,
      modalWidth: 750,
      componentProps: {
        claimNumber,
        taskType: TaskTypes.PropertyInspectionTask,
        onCreateTaskSuccess,
        onCancel: onCreateTaskCancel,
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
      isClosable: false,
    });
    return modal.present();
  };

  const onCreateTaskSuccess = (
    response: RequestResult<PropertyInspectionTask>,
  ) => {
    setInspectionTask(response);
    if (refTabContactInformation) {
      refTabContactInformation.current
        ?.getOptions()
        .then((options) => refTabs.current?.select(options));
    }
    setTab('contactInformation');
  };

  const onCreateTaskCancel = () => {
    setInspectionTask({
      status: 'initial',
    });
    setTab('claimNumber');
  };

  const patchTask = (
    tab: TabType,
    refNextTab: RefObject<HTMLBalTabItemElement>,
  ) => {
    setInspectionTask({ status: 'loading' });
    if (inspectionTask.status === 'success') {
      patchPropertyInspectionTask(
        inspectionTask.value,
        inspectionTask.localValue,
        token,
      ).then((inspectionTaskResponse) => {
        setInspectionTask(inspectionTaskResponse);
        if (inspectionTaskResponse.status === 'success') {
          refNextTab.current
            ?.getOptions()
            .then((options) => refTabs.current?.select(options));
          setTab(tab);
        } else if (inspectionTaskResponse.status === 'error') {
          toast(ErrorToast(inspectionTaskResponse.errorValue));
        }
      });
    }
  };

  const createOrFetchTaskCallback = useCallback(
    (claimNumber: string) => {
      if (createOrFetchTask && token) {
        createOrFetchTask(claimNumber, setInspectionTask, token);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setInspectionTask, token],
  );

  useEffect(() => {
    if (claimNumberQueryParam) {
      createOrFetchTaskCallback(claimNumberQueryParam);
    } else if (taskIdQueryParam) {
      setInspectionTask({ status: 'loading' });
      getTask<PropertyInspectionTask>(
        token,
        taskIdQueryParam,
        TaskTypes.PropertyInspectionTask,
      ).then((taskResponse) => {
        setInspectionTask(taskResponse);
        setTab('contactInformation');
      });
    }
  }, [
    claimNumberQueryParam,
    createOrFetchTaskCallback,
    setInspectionTask,
    taskIdQueryParam,
    token,
  ]);

  const onSearchAction = (claimNumber: string) => {
    createOrFetchTask(claimNumber, setInspectionTask, token);
  };

  const onEditTask = async (baseTaskid: string) => {
    setInspectionTask({ status: 'loading' });
    const taskResponse = await getTask<PropertyInspectionTask>(
      token,
      baseTaskid,
      TaskTypes.PropertyInspectionTask,
    );
    setInspectionTask(taskResponse);
  };
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    tab !== 'none' &&
      tab !== 'claimNumber' &&
      inspectionTask.status === 'success' &&
      inspectionTask.value.status === 'New',
  );
  //unblock navigation state

  const navigate = useNavigate();
  useEffect(() => {
    if (inspectionTask.status === 'success') {
      if (inspectionTask.value.status !== 'New') {
        toast(
          ToastNotification({
            message: t('general.taskNotInNewState'),
            color: 'warning',
          }),
        );
        navigate(
          EBRoutes.PROPERTY_INSPECTION_TASK_DETAIL.create({
            taskId: inspectionTask.value.id,
          }),
        );
      }
    }
    // when adding navigate the naviation block is not working anymore.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionTask, t]);
  return (
    <WithCapabilities
      requiredCapabilities={['CreatePropertyInspectionTask']}
      showErrorToast
    >
      {inspectionTask.status === 'success' && (
        <>
          <PropertyInspectionTaskOverlay
            task={inspectionTask.value as unknown as BaseTaskListDto}
            tab={tab}
            getFullTask={getPropertyInspectionTask}
            hideLinkButtons
          />
          <BlockNavigation
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            taskType={TaskTypes.PropertyInspectionTask}
            taskId={inspectionTask.value.id}
          />
        </>
      )}
      <div className="columns wizard is-gapless is-full-height scroll-bar-minimal-width-adjust">
        <div className="column is-11 is-flex wizard-max-width-1280px mr-3">
          <BalTabs
            interface="o-steps"
            ref={refTabs}
            value={tab}
            id="task-tabs"
            onBalChange={(e) => {
              if (checkTabType(e.detail)) setTab(e.detail as TabType);
            }}
          >
            <BalTabItem
              value="claimNumber"
              label={t('general.claim.claimNumber')}
              ref={refTabClaimNumber}
              done={inspectionTask.status === 'success'}
              disabled={tab && tab !== 'claimNumber'}
            >
              <ClaimNumberInputCard
                task={inspectionTask}
                setTask={setInspectionTask}
                refTabs={refTabs}
                refTabContactInformation={refTabContactInformation}
                refNextTab={refTabContactInformation}
                setTab={() => setTab('contactInformation')}
                title={t('inspectionTask.claimNumber.cardTitle')}
                text={t('inspectionTask.claimNumber.cardText')}
                pattern={/^[1345679]\d\d{8}[[0-9X]{1}$/}
                taskButtons={
                  <TaskWizardButtons refTabs={refTabs} hasBack={false} />
                }
                placeholder={Utilities.maskClaimNumber}
                onSearchAction={onSearchAction}
                onEditTask={onEditTask}
                onCreateNew={(task: RequestResult<PropertyInspectionTask>) => {
                  if (task.status === 'success') {
                    patchPropertyInspectionTask(
                      task.value,
                      produce(task.value, (draftState) => {
                        if (task.value.status === 'New') {
                          draftState.deletedAt = new Date().toISOString();
                        } else {
                          draftState.status = 'Closed';
                        }
                      }),
                      token,
                    ).then((result) => {
                      if (result.status === 'success') {
                        onSearchAction(task.value.claim.claimNumber);
                      } else if (result.status === 'error') {
                        toast(ErrorToast(result.errorValue));
                      }
                    });
                  }
                }}
                taskType={TaskTypes.PropertyInspectionTask}
              />
            </BalTabItem>
            <BalTabItem
              value="contactInformation"
              label={t('general.contact.contactInformation')}
              ref={refTabContactInformation}
              done={inspectionTask.status === 'success'}
              className="pl-4"
            >
              <TaskContactsCards
                task={inspectionTask}
                setTask={setInspectionTask}
                patchTask={patchPropertyInspectionTask}
                taskType={TaskTypes.PropertyInspectionTask}
              />
              <TaskWizardButtons
                refTabs={refTabs}
                hasBack={false}
                onClickNext={() =>
                  patchTask('claimInformation', refTabClaimInformation)
                }
              />
            </BalTabItem>
            <BalTabItem
              value="claimInformation"
              label={t('general.claim.claimInformation')}
              ref={refTabClaimInformation}
              done={
                inspectionTask.status === 'success' &&
                inspectionTask.value.what.length > 0
              }
            >
              <TaskInformationCards
                taskProps={{
                  task: inspectionTask,
                  setTask: setInspectionTask,
                  type: TaskTypes.PropertyInspectionTask,
                }}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabContactInformation}
                    setPrevTab={() => setTab('contactInformation')}
                  />
                }
                patchTask={() =>
                  patchTask('completeClaim', refTabCompleteClaim)
                }
                title={t('inspectionTask.claimInformation.cardTitle')}
              />
            </BalTabItem>
            <BalTabItem
              value="completeClaim"
              label={t('general.completeClaim')}
              ref={refTabCompleteClaim}
            >
              <CompleteTaskCards
                task={inspectionTask}
                setTask={setInspectionTask}
                taskType={TaskTypes.PropertyInspectionTask}
                patchTask={patchPropertyInspectionTask}
                nextLocation={'PROPERTY_INSPECTION_TASK'}
                taskButtons={
                  <TaskWizardButtons
                    refTabs={refTabs}
                    refPrevTab={refTabClaimInformation}
                    setPrevTab={() => setTab('claimInformation')}
                    nextText={t('inspectionTask.completeClaim.submitButton')}
                  />
                }
                title={t('inspectionTask.completeClaim.cardTitle')}
                text={t('inspectionTask.completeClaim.cardText')}
                hasADOS
                inspectorAppCapabilitiy={['EditPropertyInspectionTask']}
                setTab={setTab as Dispatch<SetStateAction<string>>}
              />
            </BalTabItem>
          </BalTabs>
        </div>
      </div>
    </WithCapabilities>
  );
};

export default PropertyInspectionTaskWizardView;
