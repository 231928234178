import '../../../../styles/dropdown-button.scss';
import { useTranslation } from 'react-i18next';
import { RequestResult } from '../../../../data';
import { PropertyInspectionTask } from '../../../../types/types';
import {
  BaseTaskStatus,
  CraftsmanListDto,
  PropertyInspectionTaskStatus,
} from '../../../../types/resource-models';
import { useToken } from '../../../../hooks';
import produce from 'immer';
import { patchPropertyInspectionTask } from '../../data/requests';
import { BaseTaskInProgressButton } from '../../../base-task';
import { NetworkPartnerSelector } from '../../../network-partners/components';
import { useCallback, useEffect, useState } from 'react';
import { EditDateTimeModalContent } from '../../../../components/input/edit-datetime-modal';
import { balModalController } from '../../../../controller/controllers';

type Status = {
  title: string;
  state: BaseTaskStatus;
  onClick?: () => void;
};

type PropertyInspectionTaskInProgressButtonProps = {
  task: PropertyInspectionTask;
  onChangeSuccess?: (task: RequestResult<PropertyInspectionTask>) => void;
  text?: string;
};

export const PropertyInspectionTaskInProgressButton = ({
  task,
  onChangeSuccess,
  text,
}: PropertyInspectionTaskInProgressButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();
  const openInProgressLocalModal = async () => {
    const modal = await balModalController.create({
      component: EditDateTimeModalContent,
      componentProps: {
        isRequired: false,
        isUtc: true,
        label: t('inspectionTask.scheduledInspectionDate'),
        onSave: (value: string) => {
          onChangeState('InProgressLocal', value);
          balModalController.dismiss();
        },
      },
      cssClass: 'center-modal',
      backdropDismiss: false,
    });
    return modal.present();
  };
  const states: Status[] = [
    {
      state: 'InProgressLocal',
      title: t('baseTaskStatus.InProgressLocal'),
      onClick: openInProgressLocalModal,
    },
    {
      state: 'InProgressVideo',
      title: t('baseTaskStatus.InProgressVideo'),
    },
    {
      state: 'InProgressOffice',
      title: t('baseTaskStatus.InProgressOffice'),
    },
    {
      state: 'InProgressWithPartner',
      title: t('baseTaskStatus.InProgressWithPartner'),
    },
  ];

  const craftsmanSelectorTitle = t('networkPartners.selectorTitle');

  const [settingState, setSettingState] = useState<BaseTaskStatus | undefined>(
    undefined,
  );

  const onChangeCallback = useCallback(
    (task: RequestResult<PropertyInspectionTask>) => {
      if (onChangeSuccess) {
        onChangeSuccess(task);
      }
    },
    [onChangeSuccess],
  );

  useEffect(() => {
    if (token && settingState === 'InProgressWithPartner') {
      const openCraftsmanSelectorModal = async () => {
        const modal = await balModalController.create({
          component: NetworkPartnerSelector,
          modalWidth: 1024,
          componentProps: {
            onSelectedSuccess: onCraftsmanSelected,
            title: craftsmanSelectorTitle,
            craftsmanFilter: {
              propertyCraftsmanOnly: true,
              isExternalPartner: true,
            },
          },
          cssClass: 'center-modal',
          backdropDismiss: false,
        });
        modal.onDidDismiss().then(() => {
          setSettingState(undefined);
        });
        modal.present();
      };

      const changeState = async (
        state: BaseTaskStatus,
        assigneeExternalId: string | undefined,
      ) => {
        const result = await patchPropertyInspectionTask(
          task,
          produce(task, (draftState) => {
            draftState.status = state as PropertyInspectionTaskStatus;
            draftState.assigneeExternalId = assigneeExternalId;
          }),
          token,
        );

        if (result.status === 'success' && onChangeCallback) {
          onChangeCallback(result);
        }
      };

      const onCraftsmanSelected = async (craftsman: CraftsmanListDto) => {
        if (settingState) {
          await changeState(settingState, craftsman?.company?.id);
          setSettingState(undefined);
        }
      };

      openCraftsmanSelectorModal();
    }
  }, [settingState, onChangeCallback, task, token, craftsmanSelectorTitle]);

  const onChangeState = async (
    state: BaseTaskStatus,
    scheduledInspectionDate?: string,
  ) => {
    if (state === 'InProgressWithPartner') {
      setSettingState(state); // To store the state selected to be applied when selected craftsman -> https://dev.to/shareef/react-usestate-hook-is-asynchronous-1hia
    } else {
      const result = await patchPropertyInspectionTask(
        task,
        produce(task, (draftState) => {
          draftState.status = state as PropertyInspectionTaskStatus;
          if (scheduledInspectionDate) {
            draftState.scheduledInspectionDate = scheduledInspectionDate;
          }
        }),
        token,
      );

      if (result.status === 'success' && onChangeSuccess) {
        onChangeSuccess(result);
      }
    }
  };

  return (
    <BaseTaskInProgressButton
      states={states}
      onChangeState={onChangeState}
      text={text}
    />
  );
};
