import {
  BalButton,
  BalModalHeader,
  BalHeading,
  BalModalBody,
  BalText,
  BalButtonGroup,
} from '@baloise/design-system-components-react';
import { useTranslation } from 'react-i18next';
import { BaseTask, TaskTypes } from '../../../../types/types';
import { concat } from '../../../../utils';
import { balModalController } from '../../../../controller/controllers';
import { RequestResult } from '../../../../data';
import { AppCapabilityNames } from '../../../../types/resource-models';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../../components/toast-notification';
import { deleteBaseTask } from '../../data/requests';
import { useToken } from '../../../../hooks';

export type BaseTaskDeleteProps<BaseTaskType extends BaseTask> = {
  task: BaseTaskType;
  taskType: TaskTypes;
  onChangeSuccess: (task: RequestResult<BaseTaskType>) => void;
  requiredCapabilities: AppCapabilityNames[];
  className?: string;
};

export const BaseTaskDelete = <BaseTaskType extends BaseTask>({
  task,
  taskType,
  onChangeSuccess,
}: BaseTaskDeleteProps<BaseTaskType>): JSX.Element => {
  const { t } = useTranslation();
  const token = useToken();

  return (
    <>
      <BalModalHeader>
        <BalHeading level="h3">
          {concat([
            t('claimDetail.claim'),
            task.claim.claimNumber,
            t('general.buttons.delete').toLowerCase(),
          ])}
        </BalHeading>
      </BalModalHeader>
      <BalModalBody>
        <BalText>{t('taskDelete.cardText')}</BalText>
        <BalButtonGroup position="right" className="mt-5">
          <BalButton
            elementType="button"
            color="primary-light"
            onClick={(event) =>
              event.detail == 1 && balModalController.dismiss()
            }
          >
            {t('general.buttons.cancel')}
          </BalButton>

          <BalButton
            color="danger"
            onClick={async (event) => {
              if (event.detail == 1) {
                const result = await deleteBaseTask<BaseTaskType>(
                  token,
                  task.id,
                  taskType,
                );
                if (result.status === 'success') {
                  onChangeSuccess(result);
                  balModalController.dismiss();
                } else {
                  if (result.status === 'error') {
                    toast(ErrorToast(result.errorValue));
                  }
                }
              }
            }}
          >
            {t('general.buttons.delete')}
          </BalButton>
        </BalButtonGroup>
      </BalModalBody>
    </>
  );
};
