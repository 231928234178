import { default as ReactAvatar } from 'react-avatar';
import { UserDto } from '../../types/resource-models';

type AvatarProps = {
  user: UserDto | undefined;
  className?: string;
  size?: string;
  userName?: string;
};

export const Avatar = ({
  user,
  className,
  size,
  userName,
}: AvatarProps): JSX.Element => {
  // To get the real photo of the user: https://gitlab.com/earlybyte/baloise/bat/-/snippets/2404439
  return (
    <ReactAvatar
      className={className}
      name={user?.displayName ?? userName}
      size={size}
      round
    />
  );
};
