import countries from 'i18n-iso-countries';
import german from 'i18n-iso-countries/langs/de.json';
import italian from 'i18n-iso-countries/langs/it.json';
import french from 'i18n-iso-countries/langs/fr.json';
import english from 'i18n-iso-countries/langs/en.json';
import { BalTypeAheadField } from './bal-type-ahead-field';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { FormValidation } from '../../types/types';

export const CountryList = ({
  value,
  onChange,
  controllerName,
  requiredPlaceholder,
  methods,
  label,
  formValidation,
}: {
  value: string | undefined;
  onChange: (choice: string) => void;
  controllerName: string;
  requiredPlaceholder?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  methods: UseFormReturn<FieldValues, any>;
  label?: string;
  formValidation?: FormValidation;
}): JSX.Element => {
  countries.registerLocale(german);
  countries.registerLocale(italian);
  countries.registerLocale(english);
  countries.registerLocale(french);
  const { t, i18n } = useTranslation();
  const avConuntryNames = Object.values(
    countries.getNames(i18n.language),
  ).sort();
  const availables: string[] = [];
  avConuntryNames.forEach((c) => {
    availables.push(countries.getAlpha2Code(c, i18n.language));
  });

  formValidation = formValidation ?? {
    register: methods.control.register('country', {
      required: t('error.mandatoryField'),
      value: value,
    }),
    formState: methods.formState,
  };

  return (
    <Controller
      name={controllerName}
      control={methods.control}
      defaultValue={value}
      render={({ field }) => (
        <BalTypeAheadField
          defaultValue="CH"
          className="is-full-width p-0 column is-full"
          id="country"
          placeholder={
            requiredPlaceholder
              ? `${t('general.country')} *`
              : t('general.country')
          }
          label={label}
          result={availables}
          valueFormatter={(value) => countries.getName(value, i18n.language)}
          keyFormatter={(value) => value}
          value={value}
          onChange={(choice) => {
            onChange(choice);
            field.onChange(choice);
          }}
          formValidation={formValidation}
        />
      )}
    />
  );
};
